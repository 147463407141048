/**
 * Wallet mode that let's user easily identify which
 * QR modes are availble. Also has a convenience function
 * to parse from VNAPI Configuration Admin SPA
 */
const WalletMode = {
  QR_CODE_ONLY: "WALLET_MODE_QR_CODE",
  QR_SCANNER_ONLY: "WALLET_MODE_QR_SCANNER",
  BOTH: "WALLET_MODE_BOTH",
  getMode: function(data) {
    if (data.length === 0) {
      return this.BOTH
    }
    if (data.includes('qrScanner') && data.includes('qrCode')) {
      return this.BOTH
    }
    if (data.includes('qrScanner') && !data.includes('qrCode')) {
      return this.QR_SCANNER_ONLY
    }
    if (data.includes('qrCode') && !data.includes('qrScanner')) {
      return this.QR_CODE_ONLY
    }
  }
}

export default WalletMode
