import { useRef, useEffect } from 'react'

// custom hook - can be used to store previous value of props or state
const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export default usePrevious
