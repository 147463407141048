import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

import { getUser } from '../../VNUser/Selectors'
import { getBraintreeClientToken } from '../../VNWebAccount/Selectors'
import { getWebSDKMode, getWebSDKPlatform, getWebSDKVersion, getWebSDKDataReceive, getWebSDKIsExternalPaymentProcessor } from '../../VNWebSDK/Selectors'
import { retrieveBraintreeClientToken } from '../../VNWebAccount/ActionCreators'
import { VNButton } from '../../VNComponents/VNButton'
import { fetchUserPaymentMethods } from '../../VNUser/ActionCreators'

import WebSDKPlatform from '../../VNEnums/WebSDKPlatform'
import Money from '../../utils/money'
import { SHOW_APPLE_PAY } from '../../constants'
import semver from 'semver'
import usePrevious from '../../VNHooks/usePrevious'
const dropin = require('braintree-web-drop-in')

const useStyles = makeStyles(theme => ({
  root: {

  },
}))

/**
 * Braintree drop-in
 * Can be used to vault cards separatly from payments or with payments
 * @param {Boolean} useAddCardButton - This is used when you want to vault cards but not make purchases
 * @param {int} orderTotalInCents - The total of the order...in cents
 * @param {function} callbackOnActive - Called when braintree is initialized and setup
 *
 * @exposed {function} requestPaymentMethod - You can call this from a ref to request a nonce from braintree
 */
export const VNBraintree = forwardRef(({useAddCardButton, orderTotalInCents, callbackOnActive, paymentsInCart}, ref) => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    // retrieve a nonce and vault the card
    requestPaymentMethod() {
      return braintreeDropinInstance.requestPaymentMethod()
    }

  }))

  // #region SELECTORS
  const user = useSelector(state => getUser(state))
  const braintreeClientToken = useSelector(state => getBraintreeClientToken(state))

  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKPlatform = useSelector(state => getWebSDKPlatform(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const webSDKData = useSelector(state => getWebSDKDataReceive(state))
  const webSDKIsExternalPaymentProcessor = useSelector(state => getWebSDKIsExternalPaymentProcessor(state))

  // #endregion

  // #region LOCAL STATE

  // is braintree initialized or not
  const [braintreeInitialized, setBraintreeInitialized] = useState(false)

  // is braintree configured or not
  const [braintreeConfigured, setBraintreeConfigured] = useState(false)

  // the braintree instance
  const [braintreeDropinInstance, setBraintreeDropinInstance] = useState(null)

  // do we need to show the add a card button or not
  const [showAddCardButton, setShowAddCardButton] = useState(false)

  // has the delete button been found on the DOM that is within the braintree dropin.
  const [deleteCardButtonFound, setDeleteCardButtonFound] = useState(null)

  // used to trigger drop in re-rendering
  const [dropInContainerKey, setDropInContainerKey] = useState(1)

  // Is order native iOS, then signal for default payment method through SDK
  const orderIsNativeIOS = (
    webSDKMode &&
    webSDKPlatform === WebSDKPlatform.IOS &&
    (webSDKVersion ? semver.gte(webSDKVersion, '2.0.1') : false)
  )

  // Is order native Android, then signal for default payment method through SDK
  const orderIsNativeAndroid = (
    webSDKMode &&
    webSDKPlatform === WebSDKPlatform.ANDROID &&
    webSDKIsExternalPaymentProcessor &&
    (webSDKVersion ? semver.gte(webSDKVersion, '2.0.4') : false)
  )

  //#endregion

  // This is only used by the mutation observer system that is attached to braintree div
  // this is looking for mutations for 'card' which if it is visible, we need to show our add a card button
  const mutationObserver = (mutationsList, observer) => {
    for(let mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        let chooseAnotherWayToPay = mutation.target.querySelectorAll("*[data-braintree-id='card'")
        if (chooseAnotherWayToPay.length > 0) {
          let displayValue = window.getComputedStyle(chooseAnotherWayToPay[0], null).getPropertyValue('display')

          if (displayValue === 'none') {
            setShowAddCardButton(false)
          } else {
            setShowAddCardButton(true)
          }
        }
      }
    }
  }

  /**
   * Calculates the actual total amount to charge
   * 
   * @param {array} payments - array of object - { payment_type: 'vn_bank', amount_in_cents: 1000 }
   * @returns {string} - actual total amount to charge in dollars
   */
   const calculateActualAmountToCharge = (payments) => {
    let total = orderTotalInCents

    // If split payements, calculate for remaining total
    if (payments) {
      let amountCovered = 0
      for (const payment of payments) {
        amountCovered += payment.amount_in_cents
      }

      total = total - amountCovered
    }

    return Money.centsToFloat(total)
  }

  const actualTotalAmount = calculateActualAmountToCharge(paymentsInCart)
  const prevActualTotalAmount = usePrevious(actualTotalAmount)

  //#region EFFECTS

  /**
   * used to set the onclick method for when the delete card buton is clicked from within Braintree
   */
  useEffect(() => {

    if (deleteCardButtonFound) {

      /**
       * Called when the delete button is clicked for a braintree payment method
       */
      const onDeleteCard = () => {

        // make sure we have a braintree instance
        if (braintreeDropinInstance) {

          // need to listen for all the incoming HTTP requests
          var origOpen = XMLHttpRequest.prototype.open

          XMLHttpRequest.prototype.open = function() {

            this.addEventListener('load', function() {

              // specifically want to find the response for the payment methods from braintree themselves
              if (  this.readyState === 4 &&
                    this.status === 200 &&
                    this.responseURL &&
                    this.responseURL.includes('https://api.sandbox.braintreegateway.com/merchants') &&
                    this.responseURL.includes('client_api/v1/payment_methods')) {

                      // once we found that callback, then we want to tell our backend to update this users payment methods
                      // with what is on Braintree
                      dispatch(fetchUserPaymentMethods())
                }
              })
              origOpen.apply(this, arguments)
          }
        }
      }

      // set the onclick handler
      deleteCardButtonFound.onclick = onDeleteCard
    }
  }, [deleteCardButtonFound, braintreeDropinInstance, dispatch])

  // on instantiation, go fetch the braintree token
  useEffect(() => {
    if (!user.isEmpty() && !braintreeClientToken) {
      dispatch(retrieveBraintreeClientToken())
    }

  }, [user, braintreeClientToken, webSDKData, dispatch])

  // ussed to configure the braintree drop in
  useEffect(() => {
    if (!braintreeInitialized && braintreeClientToken) {
      setBraintreeInitialized(true)

      // setup the braintree configuration
      let braintreeConfig = {
        authorization: braintreeClientToken,
        container: '#dropin-container',
        vaultManager: true,
        card: {
          vault: {
            vaultCard: true
          }
        }
      }

      // Do you want to use Apple Pay or not
      if (SHOW_APPLE_PAY) {
        console.log(`CURRENCY: ${t('CURRENCY')}`)

        braintreeConfig.applePay = {
          displayName: t('ORDER_NEXT'),
          currencyCode: t('CURRENCY'),
          paymentRequest: {
            currencyCode: t('CURRENCY'),
            total: {
              label: t('ORDER_NEXT'),
              amount: actualTotalAmount || '-1.00' // negative will throw an applepay error
            }
          }
        }
      }

      // create the dropin
      dropin.create(braintreeConfig).then(function (dropinInstance) {
        setBraintreeConfigured(true)
        setBraintreeDropinInstance(dropinInstance)

        if (callbackOnActive) {
          callbackOnActive()
        }

        // only use the mutation system if we are only vaulting cards and not doing payments
        if (useAddCardButton) {
          // we setup a mutation observer so we know when we want to show our add a card button or not
          let observer = new MutationObserver(mutationObserver)
          observer.observe(document.getElementById('dropin-container'), { attributes: true, childList: true, subtree: true})

          // need to find the delete confirmation button to hook onto that
          const deleteButton = document.querySelectorAll("*[data-braintree-id='delete-confirmation__yes'")

          // if we found the delete button, then we need to tell our local state that we have it and set it to that node
          if (deleteButton.length > 0) {
            setDeleteCardButtonFound(deleteButton[0])
          }

          // need to default show the add a card button since no mutations will run on the first iteration
          // if no card is vaulted
          setShowAddCardButton(true)
        }
      }).catch(function (err) {
        // DON't Handle any errors that might've occurred when creating Drop-in - it has always fixed itself
      })
    }

    if (braintreeInitialized && braintreeClientToken && prevActualTotalAmount !== actualTotalAmount) {
      // the actual total amount to charge has changed, we need to
      // 1. change the key of the dropin container to trigger re-rendering
      setDropInContainerKey(dropInContainerKey + 1)
      // 2. initialize a new drop in and it will pre-auth with the new amount
      setBraintreeInitialized(false)
      setBraintreeConfigured(false)
    }
  }, [braintreeInitialized, braintreeClientToken, callbackOnActive, orderTotalInCents, t, useAddCardButton]) // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  // Show the Add a Card Button
  const displayButton = () => {
    if (showAddCardButton || orderIsNativeAndroid || orderIsNativeIOS) {
      return (
        <Box>
          <VNButton
            text={t('ADD_A_CARD')}
            disabled={!(braintreeConfigured || orderIsNativeAndroid || orderIsNativeIOS)}
            onClick={onSaveCard} />
        </Box>
      )
    }
  }

  // called when the user wants to save a card - Add a Card
  const onSaveCard = () => {
    if (braintreeDropinInstance) {
      // get the payment nonce from braintree
      braintreeDropinInstance.requestPaymentMethod().then(() => {
        // go notify the user service to update their braintree credit cards on the backend
        dispatch(fetchUserPaymentMethods())
      })
    }
  }

  return (
    <Box className={classes.root}>
      <div id="dropin-container" key={dropInContainerKey}></div>
      {displayButton()}
    </Box>
  )
})
