/**
 * Is the auth provider disabled for VNSDK mode, returns true if it is, false otherwise.
 * @param {Boolean} webSDKMode - Is this in VNSDK mode or not
 * @param {Array} authProvidersDisabledForSDK - What auth providers are disabled for VNSDK
 * @param {String} provider The string provider provided by VNConfigurations - vnsdkauth_disabled
 */
export const isAuthProviderDisabledForVNSDK = (webSDKMode, authProvidersDisabledForSDK, provider) => {
  if (webSDKMode && authProvidersDisabledForSDK.length > 0) {
    for (let i = 0; i < authProvidersDisabledForSDK.length; i++) {
      const disabledProvider = authProvidersDisabledForSDK[i]
      if (provider === disabledProvider) {
        return true
      }
    }
  }
  return false
}

/**
 * Get what auth providers are enabled for web and VNSDK
 * @param {Boolean} webSDKMode - Is this in VNSDK mode or not
 * @param {Array} authProvidersDisabledForSDK - What auth providers are disabled for VNSDK
 * @param {String} authProviders All auth providers enabled for web
 */
export const getAuthProvidersAvailableForVNSDK = (webSDKMode, authProvidersDisabledForSDK, authProviders) => {
  let authProvidersAvailable = []

  authProviders.forEach(provider => {
    if (!isAuthProviderDisabledForVNSDK(webSDKMode, authProvidersDisabledForSDK, provider)) {
      authProvidersAvailable.push(provider)
    }  
  })

  return authProvidersAvailable
}
