import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SearchIcon from '@material-ui/icons/Search'
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import { Typography } from '@material-ui/core'

import { getUser } from '../../VNUser/Selectors'
import { VNSettingsList } from '../components/VNSettingsList'
import { history } from '../../App/store/configureStore'
import {
  getCopyright,
  getAuthProviders,
  getProviderIssuer,
  getProviderClientId,
  getProviderVisualPresets,
  getAuthProvidersDisabledForSDK,
  getProviderRedirectUri,
  getSeatGeekTeamSlug,
} from '../../VNConfigurations/Selectors'
import { UserInfo } from '../../VNWallet/components/UserInfo'
import { VNMenuList } from '../components/VNMenuList'
import { getWebSDKNavMode, getWebSDKMode} from '../../VNWebSDK/Selectors'
import WebSDKNavMode from '../../VNEnums/WebSDKNavMode'
import NavigationBar from '../../components/NavigationBar'
import { VNWallet } from '../../VNWallet/containers/VNWallet'
import { getAuthProvidersAvailableForVNSDK } from '../../VNWallet/Utils'
import { setCachedRoute } from '../../VNUser/ActionCreators'
import VNDivider from '../../VNComponents/VNDivider'
import { getMenu, getMenuItemSearchText } from '../../VNMenu/Selectors'
import { setMenuItemSearchText } from '../../VNMenu/ActionCreators'
import { getIsMenuAvailable } from '../../VNMenu/Utils'
import { VNSearchBar } from '../../VNComponents/VNSearchBar'
import { generateSeatGeekLoginLink } from '../../VNUtil/AuthProviderLoginLinkHelper'

import clsx from "clsx"
import { useTranslation } from 'react-i18next'
import moment from 'moment'

/**
 * Determine if we should show search icon
 * We only show search icon on menu page
 * That means when pathNames is [..., 'menu', 'menuUuid'] or [..., 'menu', 'menuUuid', '']
 * 
 * @param {Boolean} menuIsAvailable - Indicates if the menu is available or not
 * @param {Object} menu - A menu object
 * @param {Array} pathNames - An array of strings created by location.pathname.split('/')
 * @returns A boolean value
 */
const shouldShowSearchIcon = (menuIsAvailable, menuId, pathNames) => {
  if (menuIsAvailable && menuId) {
    if (['menu', 'preorder'].includes(pathNames[pathNames.length - 2]) && pathNames[pathNames.length - 1] === menuId) {
      return true
    }
    if (['menu', 'preorder'].includes(pathNames[pathNames.length - 3]) && pathNames[pathNames.length - 2] === menuId && pathNames[pathNames.length - 1] === '') {
      return true
    }
  }

  return false
}

// Default drawer width
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'none',
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    display: 'flex',
    color: '#333',
    backgroundColor: '#fff',
  },
  toolbarOpen: {
    justifyContent: 'flex-end'
  },
  toolbarClosed: {
    justifyContent: 'space-between'
  },
  topRightIcons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  dividerCustom: {
    backgroundColor: '#d3d3d3'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  bottomLinks: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    backgroundColor: theme.custom.colors.backgroundGrey,
  }
}));

export const VNWebDrawer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  // LOCAL STATE

  // controls the drawer open or close state
  const [open, setOpen] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const copyright = useSelector(state => getCopyright(state))
  const webSDKNavMode = useSelector(state => getWebSDKNavMode(state))
  const authProviders = useSelector(state => getAuthProviders(state))
  const ticketmasterIssuer = useSelector(state => getProviderIssuer(state, 'ticketmaster'))
  const ticketmasterClientId = useSelector(state => getProviderClientId(state, 'ticketmaster'))
  const ticketmasterVisualPresets = useSelector(state => getProviderVisualPresets(state, 'ticketmaster'))
  const ticketmasterRedirectUri = useSelector(state => getProviderRedirectUri(state, 'ticketmaster'))
  const authProvidersDisabledForSDK = useSelector(state => getAuthProvidersDisabledForSDK(state))
  const searchText = useSelector(state => getMenuItemSearchText(state))
  const seatgeekIssuer = useSelector(state => getProviderIssuer(state, 'seatgeek'))
  const seatgeekClientId = useSelector(state => getProviderClientId(state, 'seatgeek'))
  const seatGeekTeamSlug = useSelector(state => getSeatGeekTeamSlug(state))
  const seatGeekTeamSlugInScope = seatGeekTeamSlug?.replace('_', '-')

  // not able to get menuId from useParams() since this component is not wrapped by a Route component
  // need to get menuId from current url
  let menuId
  const pathNames = location?.pathname?.split('/') ?? []
  const idx = pathNames.findIndex(name => ['menu', 'preorder'].includes(name))
  if (idx > -1) {
    menuId = pathNames[idx + 1]
  }
  const menu = useSelector(state => getMenu(state, menuId))
  const menuName = menu?.menu?.name || ''
  const menuIsAvailable = getIsMenuAvailable(menu?.menu)
  const showSearchIcon = shouldShowSearchIcon(menuIsAvailable, menuId, pathNames)

  // check if this is in web SDK Mode or not
  const webSDKMode = useSelector(state => getWebSDKMode(state))

  // USE EFFECT

  useEffect(() => {
    setOpen(false)
    setShowSearchBar(false)
    dispatch(setMenuItemSearchText(''))
  }, [location])

  // HANDLES

  // called when we want to open the drawer
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // called when we want to close the drawer
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // when the user settings icon is clicked
  const handleUserSettingsClicked = () => {
    history.push('/profile/settings')
  }

  /**
   * Show or hide search bar
   * @param {Boolean} show - A boolean value to show or hide search bar
   */
  const toggleSearchBar = (show) => {
    setShowSearchBar(show)
    dispatch(setMenuItemSearchText(''))
  }

  const handleSearchTextChange = (e) => {
    dispatch(setMenuItemSearchText(e.target.value))
  }

  const displayTopLeftIcon = () => {

    if (webSDKNavMode === WebSDKNavMode.WEB) {
      return(
        <IconButton
        color="inherit"
        onClick={handleDrawerOpen}
        edge="start"
        aria-label={t('NAV_MENU')}
        className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
      )
    }

    if (webSDKNavMode === WebSDKNavMode.NO_HAMBURGER) {
      switch (location.pathname) {
        case '/':
          if (location.state && location.state.fromRoute === VNWallet.name) {
            return(
              <NavigationBar.TopBack text={t('BACK_LOWERCASE')} onClick={history.goBack} />
            )
          }
          break
        case '/wallet':
          break
        default:
          return(
            <NavigationBar.TopBack text={t('BACK_LOWERCASE')} onClick={history.goBack} />
          )
      }
    }
  }

  /**
   * generate teh login link and navigate to the appropriate location
   * @param {String} auth - The auth name that comes from VNConfigurations - authproviders_providers key
   */
  const getLoginLink = (auth) => {
    if (auth === 'ticketmaster') {
      window.location.href = `${ticketmasterIssuer}?lang=en-us&scope=openid profile email archtics&client_id=${ticketmasterClientId}&integratorId=venuenext&placementId=homepage&visualPresets=${ticketmasterVisualPresets}&redirect_uri=${encodeURI(ticketmasterRedirectUri)}&state=venuenext`
    } else if (auth === 'sms_signup') {
      history.push('/profile/signup')
    } else if (auth === 'sms_signin') {
      history.push('/profile/login')
    } else if (auth === 'axs') {
      history.push('/profile/axs-login')
    } else if (auth === 'seatgeek') {
      window.location.href = generateSeatGeekLoginLink(seatgeekIssuer, seatGeekTeamSlugInScope, seatgeekClientId, seatGeekTeamSlug)
    } else {
      return null
    }
  }

  /**
   * Depending on some rules, display the login button at the top right
   * @param {Array} providers - The providers that are going to be displayed
   */
  const displayLoginButton = (providers) => {

    // if there are no auth providers
    if (!providers) {
      return null
    }

    // if there are no auth providers
    if (providers && providers.length === 0) {
      return null
    }

    // if there are more then one auth providers
    if (providers && providers.length > 1) {
      return (
        <Typography
          onClick={() => {
            dispatch(setCachedRoute(history.location.pathname))
            history.push('/wallet')
          }}
          style={theme.typography.button}
        >
          {t('LOG_IN')}
        </Typography>
      )
    }

    // if there is exactly one auth provider
    if (providers && providers.length === 1) {
      return (
        <Typography onClick={() => {
          dispatch(setCachedRoute(history.location.pathname))
          getLoginLink(providers[0])
        }} style={theme.typography.button}>
          {t('LOG_IN')}
        </Typography>
      )
    }
  }

  // Displays the icon in the top right corner of the AppBar to open the settings
  // of a user, only shows up when profile screens are loaded else Log In button
  // is shown
  const displayUserSettingsIcon = () => {
    if (!user.isEmpty() && user.get('provider') !== 'vn_anonymous' && webSDKNavMode === WebSDKNavMode.WEB) {
      return(
        <IconButton
          color="inherit"
          onClick={handleUserSettingsClicked}
          edge="end"
          aria-label={t('PROFILE')}
          className={classes.userSettings}
        >
          <AccountCircleIcon />
        </IconButton>
      )
    }

    if (webSDKNavMode === WebSDKNavMode.WEB) {
      if (webSDKMode) {
        const VNSDKAuthProvidersAvailable = getAuthProvidersAvailableForVNSDK(webSDKMode, authProvidersDisabledForSDK, authProviders)
        return(displayLoginButton(VNSDKAuthProvidersAvailable))
      }

      return(displayLoginButton(authProviders))
    }
  }

  const displaySearchIcon = () => {
    if (showSearchIcon) {
      return (
        <IconButton
          color="inherit"
          onClick={() => toggleSearchBar(true)}
          aria-label={t('SEARCH')}
        >
          <SearchIcon />
        </IconButton>
      )
    }

    return null
  }

  const displayTopRightIcon = () => {
    return (
      <div className={classes.topRightIcons}>
        {displaySearchIcon()}
        {displayUserSettingsIcon()}
      </div>
    )
  }

  const displayToolbar = () => {
    if (showSearchBar) {
      return (
        <VNSearchBar
          textFieldProps={{
            fullWidth: true,
            autoFocus: true,
            id: 'appbar-menu-search-text-field',
            placeholder: `Search ${menuName}`,
            variant: 'standard',
            InputProps: { disableUnderline: true },
            value: searchText,
            onChange: handleSearchTextChange
          }}
          onCancelButtonClick={() => toggleSearchBar(false)}
        />
      )
    }

    return (
      <Toolbar className={clsx(classes.toolbar, open && classes.toolbarOpen, !open && classes.toolbarClosed)}>
        {displayTopLeftIcon()}
        {displayTopRightIcon()}
      </Toolbar>
    )
  }

  const displayAppBar = () => {
    if (history.location.pathname === '/wallet') {
      return null
    }
    //need override the CSS using classes in VNDivider case
    return (
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        {displayToolbar()}
        <VNDivider classes={{root: classes.dividerCustom}} />
      </AppBar>
    )
  }

  return (
    <Fragment>
      {displayAppBar()}
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        variant="temporary"
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box pl={3} pb={3}>
          <UserInfo onSelection={handleDrawerClose} hasSignOut={true} />
        </Box>
        <VNDivider />
        <VNMenuList onSelection={handleDrawerClose} />
        <VNDivider />
        <Box pl={3} pt={3} className={classes.bottomLinks}>
          <VNSettingsList onSelection={handleDrawerClose} />
          <Box>
            <Box pb={2}>
              <Typography style={theme.typography.subtitle1}>© {moment().year()} {copyright}</Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  )
}
