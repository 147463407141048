/**
 * Generate an ISO 8601 formatted timestamp with time zone offset
 * Example: 2024-02-01T16:09:00.706-05:00
 * @returns {String} A timestamp
 */
export const generateTimestampWithTimeZoneOffset = () => {
  // Get current date and time
  const currentDate = new Date();

  // Get time zone offset in minutes
  const timezoneOffset = currentDate.getTimezoneOffset();

  // Calculate the offset in milliseconds
  const offsetInMilliseconds = timezoneOffset * 60 * 1000;

  // Adjust the current date and time based on the offset
  const adjustedDate = new Date(currentDate.getTime() - offsetInMilliseconds);

  // Generate ISO 8601 formatted timestamp
  const isoTimestamp = adjustedDate.toISOString();

  // Append the time zone offset
  const timezoneOffsetString = (timezoneOffset > 0 ? '-' : '+') +
    Math.abs(Math.floor(timezoneOffset / 60)).toString().padStart(2, '0') +
    ':' +
    (Math.abs(timezoneOffset % 60)).toString().padStart(2, '0');

  const timestampWithTimeZone = isoTimestamp.slice(0, -1) + timezoneOffsetString;

  return timestampWithTimeZone;
}
