import { createSelector } from 'reselect'
import get from 'lodash/get'

export const getItems = state => state.menuItem

const getItem = (state, id) => state.menuItem[id] || {}

export const makeGetItem = () => (
  createSelector([getItem], (item) => item)
)

export const makeGetExperienceItem = () => createSelector(
  [getItem, (state) => get(state, 'filter.eventFilter', undefined)],
  (item, eventFilter) => {
    if (eventFilter === undefined) {
      const variant = get(item, 'variants[0]', {})

      return {
        ...item,
        price: variant.price,
        onHandQuantity: variant.onHandQuantity,
        eventUuid: variant.eventUuid,
        purchaseLimit: variant.purchaseLimit,
      }
    }

    const variant = item.variants.find(variant => variant.eventUuid === eventFilter)

    return {
      ...item,
      price: variant.price,
      onHandQuantity: variant.onHandQuantity,
      eventUuid: variant.eventUuid,
      purchaseLimit: variant.purchaseLimit,
    }
  }
)

const getItemModifierGroups = createSelector(
  [getItem], (item) => get(item, 'modifierGroups', [])
)

export const makeGetItemModifierGroups = () => {
  return createSelector([getItemModifierGroups], (modifierGroups) => modifierGroups)
}
