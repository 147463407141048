/* eslint-disable max-len */
/* eslint-disable space-in-parens */
/* eslint-disable no-empty */
/* eslint-disable valid-jsdoc */
const VNi4go = require('venuenext-i4go-web')
const pidgeon = require('../pidgeon/index')
const { jwtDecode } = require('../utils/jwt')
const PIDGEON_KEYS = require('../pidgeon/keys')
const langaugeUtils = require('../languages/utils')
const components = require('../components/index')
const vault = require('../vault/index')
const vaultComponents = require('../vault/components')
const { status, cardUI, mode } = require('../constants')
const DEBUG_MSG = 'VenueNext - i4go Web Drop In - '
const { makeLoader, removeLoader } = require('../components/loaders')
const wallet = require('../wallet/index')
const { vnReplaceChildren } = require('../utils/dom')

// a check to see if the drop in has been initialized before or not before.
let hasBeenInitialized = false

// the root div that everything attaches to
let root

// the vault root div that the vault attaches to
let vaultRoot

// the wallet root div that the wallet attaches to
let walletRoot

// the configuration that was passed in during create
let configuration

// what mode are we in
let currentMode = mode.SELECT

let currentStatus = status.LOADING

let decodedToken

let vaultedCards

// the language that is set, default is en-us
let language = 'en-us'

const documentStyles = () => {
  let style = document.createElement('style')
  style.innerHTML = '@keyframes flickerAnimation { \
    0%   { opacity:1; } \
    50%  { opacity:0; } \
    100% { opacity:1; } \
  } \
  @-o-keyframes flickerAnimation{ \
    0%   { opacity:1; } \
    50%  { opacity:0; } \
    100% { opacity:1; } \
  } \
  @-moz-keyframes flickerAnimation{ \
    0%   { opacity:1; } \
    50%  { opacity:0; } \
    100% { opacity:1; } \
  } \
  @-webkit-keyframes flickerAnimation{ \
    0%   { opacity:1; } \
    50%  { opacity:0; } \
    100% { opacity:1; } \
  } \
  .animate-flicker { \
     -webkit-animation: flickerAnimation 1s infinite; \
     -moz-animation: flickerAnimation 1s infinite; \
     -o-animation: flickerAnimation 1s infinite; \
      animation: flickerAnimation 1s infinite; \
  }'
  document.head.appendChild(style)
}

const applePayStyles = () => {
  let style = document.createElement('style')
  style.innerHTML = 'body { --apple-pay-button-width: 100%; --apple-pay-button-height: 48px; }'
  document.head.appendChild(style)
}

const displayVault = (pDisplay) => {
  if (pDisplay) {
    vaultRoot.style.display = 'block'
  } else {
    vaultRoot.style.display = 'none'
  }
}

const displayWallet = (pDisplay) => {
  if (pDisplay) {
    walletRoot.style.display = 'block'
  } else {
    walletRoot.style.display = 'none'
  }
}

/**
 * Remove a locally vaulted credit card from memory.
 * @param {String} id - The uniqueId of a vaulted credit card
 */
const removeLocalVaultedCard = (id) => {
  for (let i = 0; i < vaultedCards.length; i++) {
    if (vaultedCards[i].uniqueId === id) {
      vaultedCards.splice(i, 1)
      break
    }
  }
}

/**
 * Update a locally vaulted credit card from memory to be default.
 * @param {String} id - The uniqueId of a vaulted credit card
 */
const updateDefaultLocalVaultedCard = (id) => {
  for (let i = 0; i < vaultedCards.length; i++) {

    // find the previous default card and set it to false, no longer default
    if (vaultedCards[i].default) {
      vaultedCards[i].default = false
    }

    // when we find the new card, set is as default
    if (vaultedCards[i].uniqueId === id) {
      vaultedCards[i].default = true
    }
  }
}

const checkStatus = () => {
  if (currentStatus === status.LOADING) {

  } else if (currentStatus === status.PROCESSING) {

  } else if (currentStatus === status.COMPLETE) {

  } else if (currentStatus === status.READY) {

  }
}

const paint = () => {
  vnReplaceChildren(vaultRoot, vaultComponents.display(vaultedCards, currentStatus, currentMode, language))
}

const initializeSecureFields = () => {

  // create the configuration that we'll use to pass to initiailize the VNi4go library
  const clientConfig = {
    i4goServer: decodedToken.i4goServer,
    accessBlock: decodedToken.accessBlock,
    secureFieldsServer: decodedToken.secureFieldsServer,
    fields: ['number', 'exp', 'cvv'],
    vault: configuration.vault,
    debug: configuration.debug
  }

  // initialize the web
  VNi4go.client.initialize(clientConfig)
}

const setup = () => {

  VNi4go.bus.initialize(configuration.debug)

  VNi4go.bus.receive(VNi4go.events.SECURE_FIELD_FOCUS, (payload) => {
    console.log('***** VNi4go.bus.receive - SECURE_FIELD_FOCUS *****')
    console.log(payload)
    pidgeon.send(PIDGEON_KEYS.SECURE_FIELD_FOCUS, payload)
  }, 'VN_WEB_DROP_IN')

  VNi4go.bus.receive(VNi4go.events.VALIDATION_ERROR, (payload) => {
    console.log('***** VNi4go.bus.receive - VALIDATION_ERROR *****')
    console.log(payload)
    if (payload.error) {
      pidgeon.send(PIDGEON_KEYS.SECURE_FIELD_NOT_VALID, payload)

      // if the integrator has setup to receive event notifications
      if (configuration.events) {
        configuration.events({ event: PIDGEON_KEYS.SECURE_FIELD_NOT_VALID, ...payload })
      }
    }
  }, 'VN_WEB_DROP_IN')

  VNi4go.bus.receive(VNi4go.events.CARD_TYPE_DETERMINATION, (payload) => {
    console.log('***** VNi4go.bus.receive - CARD_TYPE_DETERMINATION *****')
    console.log(payload)
    pidgeon.send(PIDGEON_KEYS.CARD_TYPE_DETERMINATION, payload)
  }, 'VN_WEB_DROP_IN')

  VNi4go.bus.receive(VNi4go.events.FRAME_INITIALIZED, (payload) => {
    console.log('***** VNi4go.bus.receive - FRAME_INITIALIZED *****')
    console.log(payload)
    displayVault(true)
    displayWallet(true)
    removeLoader()

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.VNI4GO_READY })
    }
  }, 'VN_WEB_DROP_IN')

  pidgeon.addReceiver(PIDGEON_KEYS.WALLET_PAYMENT, (payload) => {
    console.log('***** PIDGEON_KEYS.WALLET_PAYMENT *****')
    console.log(payload)

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.WALLET_PAYMENT, ...payload })
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.WALLET_CHANGE, (payload) => {
    console.log('***** PIDGEON_KEYS.WALLET_CHANGE *****')
    console.log(payload)

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.WALLET_CHANGE, ...payload })
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.START_WALLET_PROCESS, (payload) => {
    console.log('***** PIDGEON_KEYS.START_WALLET_PROCESS *****')
    console.log(payload)

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.START_WALLET_PROCESS, ...payload })
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.VAULT_RETRIEVED_ALL_CARDS, (payload) => {
    console.log('***** PIDGEON_KEYS.VAULT_RETRIEVED_ALL_CARDS *****')
    console.log(payload)
    vaultedCards = payload.cards
    vaultComponents.overrideCardUIState(payload.cards.length > 0 ? cardUI.DEFAULT : cardUI.ADD_CARD)
    paint()
    initializeSecureFields()
    displayVault(true)
    displayWallet(true)
    removeLoader()

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.VAULT_RETRIEVED_ALL_CARDS, ...payload })
    }

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.VNI4GO_READY })
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.VAULT_CARD_VAULTED, (payload) => {
    console.log('***** PIDGEON_KEYS.VAULT_CARD_VAULTED *****')
    console.log(payload)
    // need to refetch vaulted cards
    vault.getVaultedCardsByUser(configuration.token, decodedToken.vaultServer)

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.VAULT_CARD_VAULTED, ...payload })
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.VAULT_CARD_DELETED, (payload) => {

    console.log('***** PIDGEON_KEYS.VAULT_CARD_DELETED *****')

    // need to remove it from the local memory
    removeLocalVaultedCard(payload.card.uniqueId)

    // repaint the screen so the card no longer shows up
    paint()

    // if there are no more vaulted cards, we are going to be showing the credit card input fields
    // therefore we need to initialize the secure fields
    if (vaultedCards.length <= 0) {
      initializeSecureFields()
      vaultComponents.overrideCardUIState(cardUI.ADD_CARD)
    }

    // need to refetch vaulted cards
    vault.getVaultedCardsByUser(configuration.token, decodedToken.vaultServer)

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.CARD_UPDATE, ...payload })
    }
  })

  // listen for all card event updates
  pidgeon.addReceiver(PIDGEON_KEYS.CARD_UPDATE, (payload) => {

    console.log('***** PIDGEON_KEYS.CARD_UPDATE *****')
    console.log(payload)

    if (payload.status === 'DELETED') {
      if (configuration.vault) {
        vault.deleteCard(payload.id, configuration.token, decodedToken.vaultServer)
      }
    } else if (payload.status === 'SELECTED') {
      if (configuration.vault) {
        updateDefaultLocalVaultedCard(payload.id)
        vault.updateCard({uniqueId: payload.id, default: true}, configuration.token, decodedToken.vaultServer)
      }
    }

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.CARD_UPDATE, ...payload })
    }
  })

  // listen for when a card has been updated on the server
  pidgeon.addReceiver(PIDGEON_KEYS.VAULT_CARD_UPDATED, (payload) => {
    console.log('***** PIDGEON_KEYS.VAULT_CARD_UPDATED *****')
    console.log(payload)

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.VAULT_CARD_UPDATED, ...payload })
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.CHANGE_CARD_UI, (payload) => {

    console.log('***** PIDGEON_KEYS.CHANGE_CARD_UI *****')
    console.log(payload)

    // only handle the client init for the library if we are showing the secure fields
    if (payload.status === cardUI.ADD_CARD) {
      initializeSecureFields()
    }

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.CHANGE_CARD_UI, ...payload })
    }
  })

  // setup pidgeon messenger for status changes
  pidgeon.addReceiver(PIDGEON_KEYS.CHANGE_STATUS, (payload) => {

    console.log('***** PIDGEON_KEYS.CHANGE_STATUS *****')
    console.log(payload)

    currentMode = payload.mode

    // if the integrator has setup to receive event notifications
    if (configuration.events) {
      configuration.events({ event: PIDGEON_KEYS.CHANGE_STATUS, ...payload })
    }

    paint()
  })
}

const attachFonts = () => {
  const googleFonts = components.createElement('link')
  googleFonts.rel = 'preconnect'
  googleFonts.href = 'https://fonts.gstatic.com'

  const googleFontOpenSans = components.createElement('link')
  googleFontOpenSans.ref = 'stylesheet'
  googleFontOpenSans.href = 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap'

  document.head.appendChild(googleFonts)
  document.head.appendChild(googleFontOpenSans)
}

/**
 * The intial call to the drop in to set it all up.
 * @param {Object} config - The config object that we use to initizlize the drop in.
 */
const create = (config) => {

  // store off the configurations
  configuration = config

  // make sure we have the configuration
  if (!configuration) {
    console.log(`${DEBUG_MSG} Need Configuration`)
    return
  }

  // log out the initial debug statement
  if (configuration.debug) {
    console.log(`${DEBUG_MSG} CREATE`)
  }

  // make sure we have a container to attach this to
  if (!configuration.container) {
    if (configuration.debug) {
      console.log(`${DEBUG_MSG} Need Container to attach the drop in to.`)
    }
    return
  }

  // make sure we can decode the token
  decodedToken = jwtDecode(configuration.token)

  if (!decodedToken) {
    if (configuration.debug) {
      console.log(`${DEBUG_MSG} Token provided is not valid.`)
    }
    return
  }

  // find the root injection site
  const rootInjectionSite = document.getElementById(configuration.container)

  // if we cannot find the injection site, error out
  if (!rootInjectionSite) {
    if (configuration.debug) {
      console.log(`${DEBUG_MSG} Container element not found.`)
    }
    return
  }

  // set the language file
  if (configuration.language) {
    if (langaugeUtils.checkLanguageSupport(configuration.language)) {
      language = configuration.language
    } else {
      if (configuration.debug) {
        console.log(`${DEBUG_MSG} We currently do not support that language, defaulting to en-us.`)
      }
    }
  }

  if (!hasBeenInitialized) {
    documentStyles()

    applePayStyles()

    // attach our fonts
    attachFonts()

    // setup
    setup()
  }

  // create the root element that we will attach everything to
  root = components.createElement('div', 'venuenext-i4go-drop-in-root', {
    margin: '24px'
  })

  // setup the wallet if there is any configured
  if (configuration.wallet) {
    walletRoot = components.createElement('div', 'venuenext-i4go-drop-in-wallet-root', {'padding-bottom': '16px'})

    wallet.initialize(walletRoot, language, decodedToken, configuration.wallet.sdkMode, configuration.wallet.applePay, configuration.wallet.googlePay, configuration.wallet.preValidation, hasBeenInitialized)

    displayWallet(false)
    root.appendChild(walletRoot)
  }

  if (configuration.wallet.sdkMode && !configuration.wallet.sdkMode.enabled && configuration.wallet.sdkMode.isApplePayOnly) {
    displayWallet(true)
    // attach the root component to the outside container
    rootInjectionSite.appendChild(root)
    return
  }

  vaultRoot = components.createElement('div', 'venuenext-i4go-drop-in-vault-root')

  // initially hide it until we are ready to display it
  displayVault(false)

  root.appendChild(vaultRoot)

  root.prepend(makeLoader())

  if (configuration.edit) {
    currentMode = mode.EDIT
  }

  checkStatus()

  // attach the root component to the outside container
  rootInjectionSite.appendChild(root)

  paint()

  // do we need to check for vaulted cards
  if (configuration.vault) {
    vault.getVaultedCardsByUser(configuration.token, decodedToken.vaultServer)
  }

  // check to see if the dropin has been initialized before or not
  hasBeenInitialized = true

}

/**
 * start the payment tokenization
 */
const startPaymentTokenization = () => {
  return new Promise((resolve, reject) => {

    console.log(`CardUIStatus: ${vaultComponents.getCardUIStatus()}`)

    // if the user is not trying to add a card, just return the default card
    if (configuration.vault && vaultComponents.getCardUIStatus() === cardUI.DEFAULT) {
      for (let i = 0; i < vaultedCards.length; i++) {
        if (vaultedCards[i].default) {
          resolve(vaultedCards[i])
        }
      }
    } else if (vaultComponents.getCardUIStatus() === cardUI.ADD_CARD) {
      VNi4go.client.startPaymentTokenization((payload) => {
        // check to see if there is an error on the payload
        if (!payload.error) {
          // NO ERROR, DO SOMETHING
          if (configuration.debug) {
            console.log('SUCCESS - LETS DO SOMETHING!!')
            console.log(payload)
          }

          // do we need to check for vaulted cards
          if (configuration.vault) {
            vault.vaultCard(payload, configuration.token, decodedToken.vaultServer).then(() => {
              resolve(payload)
            }).catch(() => {
              // even if we couldn't vault the card, we received the card back from Shift4 so process it
              resolve(payload)
            })
          } else {
            resolve(payload)
          }
        } else {
          // ERROR OCCURRED, DO SOMETHING ELSE
          if (configuration.debug) {
            console.log('ERROR HAS OCCURRED WITH THE PAYMENT TOKENIZATION')
            console.log(payload)
          }
          reject(payload)
        }
      })
    }
  })
}

module.exports = {
  create: create,
  startPaymentTokenization: startPaymentTokenization
};
