import * as ActionTypes from './ActionTypes'
import { ApiLoadingStatus } from '../VNApiLoadingSystem/types/ApiLoadingStatus'

import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'loading': Map({
      'retrieveAllWallet': { status: ApiLoadingStatus.UNKNOWN },
      'retrieveAllOrderHistory': { status: ApiLoadingStatus.UNKNOWN },
      'updateUser': { status: ApiLoadingStatus.UNKNOWN },
      'createOrGetUser': { status: ApiLoadingStatus.UNKNOWN },
      'fetchMenuDetails': { status: ApiLoadingStatus.UNKNOWN },
      'fetchStandAndMenuFromMenuItem': { status: ApiLoadingStatus.UNKNOWN },
      'getTicketAffiliations': { status: ApiLoadingStatus.UNKNOWN },
    })
  })
}

const reducer = (state = getInitialState(), action) => {
  // Gate this for reducer calls that have a apiStatusObj
  // so that only API endpoint calls get routed here
  if (action.type === ActionTypes.VNAPILOADINGSYSTEM_SET_STATUS) {
    return state.setIn(['loading', action.apiStatusObj.type], action.apiStatusObj)
  }
  return state
}

export default reducer
