import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from "@material-ui/core/Box"
import { getWebSDKMode } from '../../VNWebSDK/Selectors'
import { VNTabSystem } from '../../VNComponents/VNTabSystem'
import { getStandsOrder } from '../../VNConfigurations/Selectors'
import { VNWallet } from '../../VNWallet/containers/VNWallet'
import { getWebSDKProductType } from '../../VNWebSDK/Selectors'
import { getAvailableProductCategories } from '../Selectors'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { setServiceTypeSelected, setProductTypeSelected } from '../../VNQueryParams/ActionCreators'
import { getProductTypeSelected } from '../../VNQueryParams/Selectors'
import { history } from '../../App/store/configureStore'
import Skeleton from 'react-loading-skeleton'
import { setRvcFilters, setQueryParamRvcFilters } from '../../VNFilter/ActionCreators'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: "block",
    zIndex: 100,
    position: "sticky",
    top: props => props.webSDKMode ? 0 : 56
  },
  skeleton: {
    display: 'flex',
    marginTop: props => props.webSDKMode ? 0 : 20,
    columnGap: '20px',
    '& > span': {
      flex: 1
    }
  }
}))

/**
 * Displays the revenue center tab system
 */
export const VNRCTabSystem = (props) => {

  const classes = useStyles(props)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const location = useLocation()

  // SELECTORS

  // check if this is in web SDK Mode or not
  const webSDKMode = useSelector(state => getWebSDKMode(state))

  // get the stands order from the VNConfig
  const standsOrder = useSelector(state => getStandsOrder(state))

  //
  const productTypeSelected = useSelector(state => getProductTypeSelected(state))

  // what the SDK product type is
  const webSDKProductType = useSelector(state => getWebSDKProductType(state))

  // what categories of products are available based off all the stands that are available?
  /// Food & Drink, Merchandise, and Experiences
  const availableProductCategories = useSelector(state => getAvailableProductCategories(state, t))

  // LOCAL STATE

  // used to set if the stands order has been used to set the UI from the VNConfig System
  const [tabOptionsSetByVNConfig, setTabOptionsSetByVNConfig] = useState(false)

  // // the updated tab options order as defined by VNAPI Configurations
  const [internalTabOptions, setInternalTabOptions] = useState(availableProductCategories)

  // what the internal index is for the tab system that is calculated in this file
  // used to figure it out based off of query params
  const [internalDefaultIndex, setInternalDefaultIndex] = useState(0)

  // used to reset RvC filter
  const resetFilterObj = {
    noWaitTimes: false,
    serviceTypes: [],
    locations: [],
    categories: []
  }

  // sets the order of the tab from the VNAPI Configuration System
  useEffect(() => {

    if (standsOrder.length > 0 && !tabOptionsSetByVNConfig && availableProductCategories) {

      setTabOptionsSetByVNConfig(true)

      if (availableProductCategories.length > 0) {

        const newStandsOrder = standsOrder.reduce((acc, item) => {

          for (let i = 0; i < availableProductCategories.length; i++) {
            if (item === (availableProductCategories[i].id).toLowerCase()) {
              acc.push(availableProductCategories[i])
              break
            }
          }
          return acc
        }, [])

        if (availableProductCategories.length === 1) {
          dispatch(setProductTypeSelected(availableProductCategories[0].id))
        }

        setInternalTabOptions(newStandsOrder)
      }
    }
  }, [standsOrder, availableProductCategories, tabOptionsSetByVNConfig])

  // pased off the product type that was selected elsewhere in redux, set that has the selected option
  useEffect(() => {
    if (internalTabOptions && internalTabOptions.length > 1) {
      const index = internalTabOptions.findIndex((option) => { return option.id === productTypeSelected })
      if (index >= 0) {
        setInternalDefaultIndex(index)
      } else {
        setInternalDefaultIndex(0)
      }
    }
  }, [internalTabOptions, productTypeSelected])

  /**
   * function that is called from onSelection from the VNTabSystem
   * @param {String} val - What product type has been selected
   */
  const tabSystemOnSelection = (val) => {

    // clear the service type that has been selected since we are changing product types
    dispatch(setServiceTypeSelected('All'))
    dispatch(setProductTypeSelected(val))

    // also clear RvC filter on tab selection
    dispatch(setRvcFilters(resetFilterObj))
    dispatch(setQueryParamRvcFilters(resetFilterObj))

    // make sure to update the history so we can go back when we dive into a menu
    history.replace(`/?productType=${val}`)
  }

  /**
   * Dispatch the overall tab system depending on the rules
   */
  const displayTabSystem = () => {
    if (internalTabOptions === undefined) {
      return(
        <Box className={classes.skeleton}>
          <Skeleton height='30px' />
          <Skeleton height='30px' />
          <Skeleton height='30px' />
        </Box>
      )
    } else if (internalTabOptions.length === 1) {
      return 
    }

    if (!webSDKMode) {
      return(
        <VNTabSystem
          defaultIndex={internalDefaultIndex}
          options={internalTabOptions}
          onSelection={tabSystemOnSelection}
          productTypeFilter={productTypeSelected}
        />
      )
    }

    if (webSDKMode && webSDKProductType === 'All' && !isEmpty(internalTabOptions)) {
      return(
        <VNTabSystem
          defaultIndex={internalDefaultIndex}
          options={internalTabOptions}
          onSelection={tabSystemOnSelection}
          productTypeFilter={productTypeSelected}
          />
        )
    }

    if (location.state && location.state.fromRoute === VNWallet.name) {
      return(
        <VNTabSystem
          defaultIndex={internalDefaultIndex}
          options={internalTabOptions}
          onSelection={tabSystemOnSelection}
          productTypeFilter={productTypeSelected}
        />
      )
    }
  }

  return (
    <Box className={classes.root}>
      {displayTabSystem()}
    </Box>
  )
}
