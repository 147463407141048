import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { VNSingleChoice } from '../components/VNSingleChoice'
import { VNMultiChoice, VNMultiChoiceRequiredEnum } from '../components/VNMultiChoice'
import VNImageFallback from '../../VNComponents/VNImageFallback'
import Skeleton from '@material-ui/lab/Skeleton'
import Money from '../../utils/money'
import ModifierGroupValidator from '../../validators/ModifierGroupValidator'
import { useTranslation } from 'react-i18next'
import { groupBy, isEmpty, isEqual } from 'lodash'
import { variantsAreSamePrice, variantsForItem } from '../../utils/menuVariantHelper'
import VNDivider from '../../VNComponents/VNDivider'
import VNQuantitySelector from '../components/VNQuantitySelector'
import { getCanAddToCart } from '../../selectors/cart'
import { updateCanAddToCart } from '../../actions/cart'

const useStyles = makeStyles({
  image: {
    width: '100%',
    height: 'auto',
  },
  counter: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    height: 58,
    flex: 1,
    borderRadius: 29,
  },
  buttonLeft: {
    marginRight: 10,
  },
  buttonRight: {
    marginLeft: 10
  },
  cartButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fafafa',
    width: '100%',
    padding: '16px 8px 16px 8px',
  }
});

const makeRequiredChoicesMapping = (allModifierGroups) => {
  const mapping = {}

  if (allModifierGroups.length) {
    for (const group of allModifierGroups) {
      if (group.required) {
        mapping[group.uuid] = false
      }
    }
  }

  return mapping
}

/**
 * Replacement section inside ItemModal for menu item details, modifiers, and variants
 * Functions exposed to parent via ref:
 * validate - Exposed through forwardRef, available via <REF>current.validate(). Returns
 * a validation object which indicates of all modifiers/variants are filled and valid
 * as well as info for each modifier/variant selected.
 */
export const VNMenuItemDetails = forwardRef((props, ref) => {
  const classes = useStyles();

  const dispatch = useDispatch()

  // The Location ID and the item ID
  const { itemId } = useParams()

  useImperativeHandle(ref, () => ({
    validate() {
      return onAddToCartFromParent()
    }
  }))

  // menu item Map (not immutable)
  const selectedMenuItem = VNMenuItemDetailsMunger(props.item, props.isMerchandise)

  // LOCAL STATE

  // has the selected menu item been fetched from the API or not
  const [menuItemFetched, setMenuItemFetched] = useState(false)

  // used to track if all required choices have met its minimum requirement
  const [requiredChoicesMapping, setRequiredChoicesMapping] = useState(makeRequiredChoicesMapping(selectedMenuItem.modifiers.allModifiersInOrder))

  // SELECTORS
  const canAddToCart = useSelector(state => getCanAddToCart(state))

  // EFFECTS

  // fetches the location details from the API
  useEffect(() => {
    if (!menuItemFetched) {
      setMenuItemFetched(true)
    }
  }, [menuItemFetched, itemId])

  useEffect(() => {
    // check if all values in requiredChoicesMapping are `true`
    const allowToAdd = isEmpty(requiredChoicesMapping) || isEqual([...new Set(Object.values(requiredChoicesMapping))], [true])

    if (allowToAdd !== canAddToCart) {
      dispatch(updateCanAddToCart(allowToAdd))
    }
  }, [dispatch, requiredChoicesMapping, canAddToCart])

  // HANDLES

  /**
   * Called when changes occur in a choice
   * Used to update requiredChoicesMapping to see if all required modifier selections are satisfied
   * @param {object} groupInfo - { uuid: string, minIsMet: boolean }
   */
  const onChoiceChange = (groupInfo) => {
    if (typeof requiredChoicesMapping[groupInfo.uuid] === 'boolean') {
      setRequiredChoicesMapping({
        ...requiredChoicesMapping,
        [groupInfo.uuid]: groupInfo.minIsMet
      })
    }
  }

  // called when the user wants to add to cart
  const onAddToCartFromParent = () => {

    var falseCount = 0
    var options = []

    // loop through all the single choices and get the user selections
    usedSingleChoiceRefs.forEach((ref, index) => {
      const { choice, status } = ref.getChoice()
      if (choice) {
        options.push({
          ...selectedMenuItem.modifiers.singleChoice[index].options[choice],
          // modifierGroupUuid is used to identify which modifier group this modifier belongs to
          // primarily used in VNSingleChoice and VNMultiChoice
          modifierGroupUuid: selectedMenuItem.modifiers.singleChoice[index].uuid,
          isVariantChoice: selectedMenuItem.modifiers.singleChoice[index].isVariantChoice
        })
      }
      falseCount = !status ? falseCount + 1 : falseCount
    })

    // loop through all the multi choices and get the user selections
    usedMultiChoiceRefs.forEach((ref, index) => {
      const { choices, status } = ref.getChoices()
      if (choices) {
        choices.forEach((c) => {
          options.push({
            ...selectedMenuItem.modifiers.multiChoice[index].options.find(o => o.uuid === c),
            // modifierGroupUuid is used to identify which modifier group this modifier belongs to
            modifierGroupUuid: selectedMenuItem.modifiers.multiChoice[index].uuid
          })
        })
      }
      falseCount = !status ? falseCount + 1 : falseCount
    })

    usedQuantitySelectorGroupRefs.forEach((ref, index) => {
      const { choices, status } = ref.getChoices()

      if (choices) {
        choices.forEach((c) => {
          options.push({
            ...selectedMenuItem.modifiers.quantitySelectorGroups[index].options.find(o => o.uuid === c.uuid),
            // modifierGroupUuid is used to identify which modifier group this modifier belongs to
            modifierGroupUuid: selectedMenuItem.modifiers.quantitySelectorGroups[index].uuid,
            quantity: c.quantity
          })
        })
      }

      if (!status) {
        falseCount++
      }
    })

    return {
      isValid: falseCount > 0 ? false : true,
      options: options
    }
  }

  // REFS
  const modifierChoicesRef = useRef([])

  // As we dynamically create modifier choices use the refs from the modifierChoicesRef
  const usedSingleChoiceRefs = []
  const usedMultiChoiceRefs = []
  const usedQuantitySelectorGroupRefs = []

  // LOCAL FUNCTIONS

  const getGroupedSelectedModifiers = () => {
    const { item, itemModifierRelationshipKey, itemModifierIndices } = props

    if (!item.isVariant && item.modifiers && itemModifierRelationshipKey) {
      // Use itemModifierIndices[0] because 0 will always be the parent reference
      return groupBy(props.item.modifiers[itemModifierIndices[0]], modifier => modifier.parentGroupId)
    }

    // if it is a variant item, item.id will be the selected variant menuItemUuid
    if (item.isVariant && item.variants && item.id) {
      const selectedVariant = item.variants.find(v => v.menuItemUuid === item.id)
      return {
        [selectedVariant.parentGroupId]: [selectedVariant]
      }
    }

    return null
  }

  // display the top image
  const displayImage = () => {
    if (selectedMenuItem && selectedMenuItem.image && !props.hideImage) {
      return(
        <VNImageFallback src={selectedMenuItem.image} alt={selectedMenuItem.name} className={classes.image} />
      )
    }

    if (selectedMenuItem) return

    return (<Skeleton animation="wave" variant="rect" height={140} />)
  }

  // display the name
  const displayName = () => {
    if (selectedMenuItem && selectedMenuItem.name) {
      return selectedMenuItem.name
    }

    return (<Skeleton animation="wave" />)
  }

  // display the details
  const displayDetails = () => {
    if (selectedMenuItem && !selectedMenuItem.details) {
      return
    }
    if (selectedMenuItem && selectedMenuItem.details) {
      return selectedMenuItem.details
    }
    return (<Skeleton animation="wave" />)
  }

  // display the cost
  const displayCost = () => {
    if (selectedMenuItem && selectedMenuItem.cost) {
      return `${selectedMenuItem.cost}`
    }
    return (<Skeleton animation="wave" />)
  }

  /**
   * Display modifier groups in their original order from
   * selectedMenuItem.modifiers.allModifiersInOrder (an array)
   */
  const displayItemModifierGroups = () => {

    const selectedModifiers = getGroupedSelectedModifiers()

    return selectedMenuItem.modifiers.allModifiersInOrder.map((group, index) => {

      if (group.isVariantChoice || group.isSingleChoice) {

        usedSingleChoiceRefs.push(modifierChoicesRef.current[index])

        return (
          <Box pt={3} key={group.groupId || group.uuid || index}>
            <VNSingleChoice
              ref={ref => modifierChoicesRef.current[index] = ref}
              required={group.required}
              title={group.title}
              onChange={onChoiceChange}
              options={group.options}
              selectedItems={selectedModifiers ? selectedModifiers[group.groupId] : null}
              modifierGroupUuid={group.uuid}
              isVariantChoice={group.isVariantChoice}
              defaults={group.defaults}
            />
            <VNDivider />
          </Box>
        )
      } else if (group.isMultiChoice) {

        usedMultiChoiceRefs.push(modifierChoicesRef.current[index])

        return (
          <Box pt={3} key={group.uuid}>
            <VNMultiChoice
              ref={ref => modifierChoicesRef.current[index] = ref}
              required={group.required === true ? VNMultiChoiceRequiredEnum.REQUIRED : VNMultiChoiceRequiredEnum.OPTIONAL}
              title={group.title}
              subTitle={group.subTitle}
              onChange={onChoiceChange}
              max={group.max}
              min={group.min}
              options={group.options}
              secondaryPriceThresholdCount={group.secondaryPriceThresholdCount}
              selectedItems={selectedModifiers ? selectedModifiers[group.groupId] : null}
              modifierGroupUuid={group.uuid}
              defaults={group.defaults}
            />
            <VNDivider />
          </Box>
        )
      } else if (group.isQuantitySelectorGroup) {

        usedQuantitySelectorGroupRefs.push(modifierChoicesRef.current[index])

        return (
          <Box pt={3} key={group.uuid}>
            <VNQuantitySelector
              ref={ref => modifierChoicesRef.current[index] = ref}
              required={group.required}
              title={group.title}
              subTitle={group.subTitle}
              onChange={onChoiceChange}
              max={group.max}
              min={group.min}
              options={group.options}
              selectedItems={selectedModifiers?.[group.groupId] ?? []}
              modifierGroupUuid={group.uuid}
              secondaryPriceThresholdCount={group.secondaryPriceThresholdCount}
              defaults={group.defaults}
            />
            <VNDivider />
          </Box>
        )
      }

      return null
    })
  }

  // add hairline in case of no modifers
  const displayHairline = () => {
    if (!selectedMenuItem.modifiers.allModifiersInOrder.length) {
      return (
        <Box pt={3} key={0}>
          <VNDivider />
        </Box>
      )
    }
  }

  return (
    <Box>
      {displayImage()}
      <Box p={3}>
        <Typography variant="h1">
          {displayName()}
        </Typography>
        <Box pt={1}>
          <Typography variant="subtitle1">
            {displayDetails()}
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography variant="button">
            {displayCost()}
          </Typography>
        </Box>
        {displayItemModifierGroups()}
        {displayHairline()}
      </Box>
    </Box>
  );
})

/**
 * Pass in old menu item state (v1) to convert into new menu item state format (v2)
 * @param {object} data
 */
const VNMenuItemDetailsMunger = (data, isMerchandise) => {

  const { t } = useTranslation()

  const getChangeInCostSymbol = (cost) => {
    if (cost === 0 ) {
      return ""
    }
    return cost > 0 ? "+" : "-"
  }

  const getCost = (item) => {
    if (!isMerchandise) {
      return Money.centsToDollars(data.defaultPriceInCents, false, true)
    }

    const showPrice = variantsAreSamePrice(item)

    return showPrice && item.defaultPriceInCents > 0 ? Money.centsToDollars(item.defaultPriceInCents, false, true) + "/each" : Money.centsToDollars(item.defaultPriceInCents, false, true)
  }

  const getMultiChoiceSubtitle = (min, max) => {
    const validator = new ModifierGroupValidator(min, max)
    const labels = {
      required: `(${t('REQUIRED_LOWERCASE')})`,
      specificRequired: `(${min} ${t('REQUIRED_LOWERCASE')})`,
      minRequired: `${t('PICK_AT_LEAST')} ${min} (${t('REQUIRED_LOWERCASE')})`,
      minMaxRequired: `${t('PICK_UP_TO')} ${max} (${min} ${t('REQUIRED_LOWERCASE')})`,
      maxOptional: `${t('PICK_UP_TO')} ${max} (${t('OPTIONAL')})`,
      optional: '',
      default: '',
    }

    const text = labels[validator.validationType] || labels.default

    return isEmpty(text) ? null : text
  }

  const mungeItemOptions = (options) => {

    var temp = options.map((item) => {

      const newItem = {
        uuid: item.uuid,
        value: item.name,
        change: getChangeInCostSymbol(item.defaultPriceInCents),
        thresholdChange: getChangeInCostSymbol(item.variants[0].priceAfterThresholdInCents),
        orderMinCount: item.orderMinCount,
        orderMaxAmount: item.orderMaxAmount
      }

      newItem.modifier = ''
      newItem.thresholdModifier = ''

      // if the value doesn't cost anything, we don't want to show the price.
      if (item.defaultPriceInCents > 0) {
        newItem.modifier = Money.centsToDollars(item.defaultPriceInCents, true, true)
      }

      if (item.variants[0].priceAfterThresholdInCents > 0 ) {
        newItem.thresholdModifier = Money.centsToDollars(item.variants[0].priceAfterThresholdInCents, true, true)
      }

      return newItem
    })

    return temp
  }

  /**
   * Whether a group is a single choice or not
   * @param {object} group - A modifier group that belongs to an item
   * @returns {boolean}
   */
  const isSingleChoice = (group) => {
    if (group.max === 1) {
      return true
    }

    if (!group.max) {
      return group.modifierPropertiesContainer.items.some(item => item.orderMaxAmount === 1)
    }

    return false
  }

  /**
   * Whether a group is a multiple choice or not
   * @param {object} group - A modifier group that belongs to an item
   * @returns {boolean}
   */
  const isMultiChoice = (group) => {
    if (!group.max) {
      return group.modifierPropertiesContainer.items.some(item => !item.orderMaxAmount)
    }

    if (group.max > 1) {
      return group.modifierPropertiesContainer.items.some(item => item.orderMaxAmount === 1)
    }

    return false
  }

  /**
   * Whether a group is a quantity selector group or not
   * @param {object} group - A modifier group that belongs to an item
   * @returns {boolean}
   */
  const isQuantitySelectorGroup = (group) => {
    if (!group.max) {
      return group.modifierPropertiesContainer.items.every(item => item.orderMaxAmount && item.orderMaxAmount > 1)
    }

    if (group.max > 1) {
      return group.modifierPropertiesContainer.items.every(item => item.orderMaxAmount && item.orderMaxAmount > 1)
          || group.modifierPropertiesContainer.items.every(item => !item.orderMaxAmount)
    }
  }

  // Get variants of menu item (when stand is merchandise)
  const getSingleChoicesVariant = () => {
    if (data.variantGroupName) {
      const variants = variantsForItem(data)
      const showPrice = !variantsAreSamePrice(data)

      return {
        uuid: data.uuid,
        isVariantChoice: true,
        title: data.variantGroupName,
        required: true,
        options: variants.map((variant) => ({
          uuid: variant.menuItemUuid,
          value: variant.name,
          change: showPrice && variant.priceInCents > 0 ? "+" : null,
          modifier: showPrice && variant.priceInCents > 0 ? Money.centsToDollars(variant.priceInCents, false, true) : null,
        }))
      }
    }
  }

  // Get modifier groups that are single choice
  const getSingleChoice = (group) => {
    return {
      isSingleChoice: true,
      title: group.name,
      required: group.requiresSelection,
      options: mungeItemOptions(group.modifierPropertiesContainer.items),
      uuid: group.uuid,
      groupId: group.modifierPropertiesContainer.groupId,
      defaults: group.modifierPropertiesContainer.defaults
    }
  }

  // Get modifier groups that are multi choice
  const getMultiChoice = (group) => {
    return {
      isMultiChoice: true,
      title: group.name,
      subTitle: getMultiChoiceSubtitle(group.min, group.max),
      required: group.requiresSelection,
      options: mungeItemOptions(group.modifierPropertiesContainer.items),
      min: group.min,
      max: group.max,
      secondaryPriceThresholdCount: group.modifierPropertiesContainer.secondaryPriceThresholdCount,
      uuid: group.uuid,
      groupId: group.modifierPropertiesContainer.groupId,
      defaults: group.modifierPropertiesContainer.defaults
    }
  }

  // Get modifier groups that are quantity selector groups
  const getQuantitySelectorGroup = (group) => {
    return {
      isQuantitySelectorGroup: true,
      title: group.name,
      subTitle: getMultiChoiceSubtitle(group.min, group.max),
      required: group.requiresSelection,
      options: mungeItemOptions(group.modifierPropertiesContainer.items),
      min: group.min,
      max: group.max,
      secondaryPriceThresholdCount: group.modifierPropertiesContainer.secondaryPriceThresholdCount,
      uuid: group.uuid,
      groupId: group.modifierPropertiesContainer.groupId,
      defaults: group.modifierPropertiesContainer.defaults
    }
  }

  /**
   * Transform data.modifierGroups into different pieces of data
   * @returns {object} - {
   *   singleChoice: Array - contains all single choice groups
   *   multiChoice: Array - contains all multiple choice groups
   *   quantitySelectorGroups: Array - contains all quantity selector groups
   *   allModifiersInOrder: Array - contains all groups and is used to display in their original order
   * }
   */
  const getModifiers = () => {
    const allModifiersInOrder = []
    const singleChoices = []
    const multiChoices = []
    const quantitySelectorGroups = []

    if (isMerchandise) {
      const variantGroup = getSingleChoicesVariant()
      if (variantGroup) {
        allModifiersInOrder.push(variantGroup)
        singleChoices.push(variantGroup)
      }
    }

    // sortOrder could be `null`
    const sortedGroups = [...data.modifierGroups].sort((a, b) => (a.modifierPropertiesContainer.sortOrder || 0) - (b.modifierPropertiesContainer.sortOrder || 0))

    for (const group of sortedGroups) {
      if (isSingleChoice(group)) {
        const g = getSingleChoice(group)
        allModifiersInOrder.push(g)
        singleChoices.push(g)

      } else if (isMultiChoice(group)) {
        const g = getMultiChoice(group)
        allModifiersInOrder.push(g)
        multiChoices.push(g)

      } else if (isQuantitySelectorGroup(group)) {
        const g = getQuantitySelectorGroup(group)
        allModifiersInOrder.push(g)
        quantitySelectorGroups.push(g)
      }
    }

    return {
      singleChoice: singleChoices,
      multiChoice: multiChoices,
      quantitySelectorGroups: quantitySelectorGroups,
      allModifiersInOrder: allModifiersInOrder
    }
  }
  let description = data.description
  if ( description == null || description.length === 0 ){
    description =  data.marketingDescription
  }
  return {
    name: data.variantGroupDisplayName ? data.variantGroupDisplayName : data.name,
    image: data.images ? data.images.detail : null,
    details: description,
    cost: getCost(data),
    modifiers: getModifiers()
  }
}

export const MemoizedVNMenuItemDetails = React.memo(VNMenuItemDetails)
