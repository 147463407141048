import React from 'react'
import usePaymentMapping from './usePaymentMapping'

function withPaymentMappingHook(Component) {
  return function WrappedComponent(props) {
    const paymentMapping = usePaymentMapping(null, null, props.isFree, props.totalInCents)
    return <Component {...props} paymentMapping={paymentMapping} />
  }
}

export default withPaymentMappingHook
