import React from 'react';
import loading from '../images/loading.svg';

/**
 * Used a a full screen loading circle
 */
export const VNLoader = ({ inline }) => {

  const style = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  }

  const inlineStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '180px',
    width: '100%',
    backgroundColor: 'white'
  }

  return (
    <div style={inline ? inlineStyle : style}>
      <img src={loading} alt="loading"/>
    </div>
  );
  
}
