import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import semver from 'semver'
import { Typography } from '@material-ui/core'
import { getGiftCardTransferDialogOpen } from '../Selectors'
import { setOpenGiftCardTransferDialog } from '../ActionCreators'
import { sendSDKModalOpen } from '../../VNWebSDK/bridgeCalls/VNWebSDKDataSend'
import { getWebSDKVersion } from '../../VNWebSDK/Selectors'
import { getWalletTextGiftCard, getWalletTextGiftCardDisclaimer } from '../../VNConfigurations/Selectors'
import { VNTextInput } from '../../VNComponents/VNTextInput'
import { VNButton } from '../../VNComponents/VNButton'
import { transferGiftCardBalanceToVirtualCurrency } from '../../VNWallet/ActionCreators'
import { apiGetGiftCardBalance } from '../../VNWallet/Api'
import { enableBackdropSystem, disableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import Money from '../../utils/money'
import { setSnackbarSystemDataAlertError, setSnackbarSystemDataAlertInfo } from '../../VNSnackbarSystem/ActionCreators'
import { VNFullScreenCloseButton } from '../../VNComponents/VNFullScreenCloseButton'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  middle: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cancelIcon: {
    height: 40,
    width: 40,
    color: theme.custom.colors.black,
  },
  funds: {
    color: '#2253FF'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
});

/**
 * Transfer gift card money to virtual currency
 */
export const VNGiftCardTransfer = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [showConfirm, setShowConfirm] = useState(false)
  const [balance, setBalance] = useState(0)
  const [cardNumber, setCardNumber] = useState(0)
  const [pin, setPin] = useState(0)

  // REFS
  const giftCardNumberRef = useRef()
  const giftCardPinRef = useRef()

  // SELECTORS
  const open = useSelector(state => getGiftCardTransferDialogOpen(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const customizedWalletTextGiftCard = useSelector(state => getWalletTextGiftCard(state))
  const customizedWalletTextGiftCardDisclaimer = useSelector(state => getWalletTextGiftCardDisclaimer(state))
  
  // HANDLES
  const onClose = (event) => {
    dispatch(setOpenGiftCardTransferDialog(false))
  }

  useEffect(() => {
    if (!open) {
      setShowConfirm(false)
    }
    document.title = t('LOAD_GIFT_CARD')
  }, [open])

  useEffect(() => {
    if (webSDKVersion && semver.gte(webSDKVersion, '2.0.4')) {
      sendSDKModalOpen({ 'giftcards': open ? 'yes' : 'no' })
    }
  }, [open, webSDKVersion])

  /**
   * Gets either the customized text if it exists or the default one.
   * @returns Returns the text for Gift Cards
   */
   const getLoadGiftCardText = () => {
    return customizedWalletTextGiftCard ? customizedWalletTextGiftCard : t('LOAD_GIFT_CARD')
  }

  const getGiftCardDisclaimerText = () => {
    return customizedWalletTextGiftCardDisclaimer ? customizedWalletTextGiftCardDisclaimer : t('GIFT_CARD_DISCLAIMER_DEFAULT')
  }

  const getButtonTitle = () => {
    if (showConfirm) {
      return t('CONFIRM')
    } else {
      return customizedWalletTextGiftCard ? customizedWalletTextGiftCard : t('LOAD_GIFT_CARD')
    }
  }

  const textValidation = (val) => {
    if (val.length <= 0) {
      return false
    }
    return true
  }

  const onButtonClick = () => {
    if (showConfirm) {
      dispatch(transferGiftCardBalanceToVirtualCurrency('GIVEX', cardNumber, 'en', pin, balance, t))
    } else {
      if (giftCardNumberRef.current.validate() && giftCardPinRef.current.validate()) {
        
        dispatch(enableBackdropSystem())

        setCardNumber(giftCardNumberRef.current.getInput())
        setPin(giftCardPinRef.current.getInput())

        apiGetGiftCardBalance('GIVEX', giftCardNumberRef.current.getInput(), 'en', giftCardPinRef.current.getInput()).then(response => {
          dispatch(disableBackdropSystem())

          // only continue if the balance is greater then 0
          if (response.data && response.data.balance > 0) {
            setBalance(response.data.balance)
            setShowConfirm(true)
          } else {
            // no funds
            dispatch(setSnackbarSystemDataAlertInfo(t('GIFT_CARD_NO_FUNDS')))
          }

        }).catch(error => {
          dispatch(disableBackdropSystem())
          if (typeof error?.response?.data?.error === 'string') {
            console.log('Error: ' + error.response.data.error)
            dispatch(setSnackbarSystemDataAlertError(error.response.data.error))
          }
        })
      }
    }
  }
  
  const displayBody = () => {
    if (showConfirm) {
      return (
        <Box pt={5}>
          <Box>
            <Typography variant='h3'>{t('GIFT_CARD_BALANCE')}</Typography>
          </Box>
          <Box pt={2}>
            <Typography variant='h6' className={classes.funds}>{Money.centsToDollars(balance, true, true)}</Typography>
          </Box>
          <Box mt={5}>
            <Typography variant='h3'>{t('DISCLAIMER')}</Typography>
          </Box>
          <Box pt={1}>
            <Typography variant='body1'>{getGiftCardDisclaimerText()}</Typography>
          </Box>
        </Box>
      )
    } else {
      return (
        <Box pt={5}>
          <VNTextInput
                ref={giftCardNumberRef}
                required={true}
                label={t('GIFT_CARD_NUMBER')}
                validation={textValidation}
                type="tel"
                labelWidth={150} />
          <Box mt={3}>
            <VNTextInput
                  ref={giftCardPinRef}
                  required={false}
                  label={t('PIN')}
                  type="tel"
                  labelWidth={30} />
          </Box>
        </Box>
      )
    }
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Box className={classes.root}>
        <Box pt={3} pr={3} pl={3} className={classes.top}>
          <Box pt={1.5}>
            <Typography variant='h1'>{getLoadGiftCardText()}</Typography>
          </Box>
          <VNFullScreenCloseButton onClose={onClose} className={classes.cancelIcon}/>
        </Box>
        <Box pr={3} pl={3} className={classes.middle}>
          <Box pl={3} pr={3}>
              {displayBody()}
          </Box>
          <Box pb={3}>
            <VNButton text={getButtonTitle()} onClick={onButtonClick} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
