import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  button: {
    height: 58,
    flex: 1,
    borderRadius: 6,
  },
  label: theme.typography.h5,
}));

/**
 *
 * A default VNButton that is set to our VN Style and design.
 * @props
 *
 * Required
 * text: String - The button text
 *
 * Optional
 * onClick: func - Called when the button is clicked
 * fullWidth: Bool - Default true - Set the button to be full width or not
 * variant: String - Default 'contained' - Options are 'contained', 'text', 'outlined'
 * color: String - Default 'primary' - Options are 'default', 'inherit', 'primary', 'secondary'
 *
 */
export const VNButton = (props) => {

  const classes = useStyles()

  return (
    <Button
      classes={{
        label: clsx(props.label ? props.label : classes.label)
      }}
      disabled={props.disabled ? props.disabled : false}
      onClick={props.onClick ? props.onClick : () => {}}
      fullWidth={props.fullWidth ? props.fullWidth : true}
      variant={props.variant ? props.variant : 'contained'}
      color={props.color ? props.color : 'secondary'}
      className={classes.button}>{props.text}</Button>
  )
}
