import React, { useState, useEffect } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const VNTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.custom.colors.borderGrey}`,
  },
  indicator: {
    backgroundColor: theme.custom.colors.black,
    height: 6
  }
}))(Tabs)

const VNTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.96,
    '&:hover': {
      color: theme.custom.colors.black,
    },
    '&:selected': {
      color: theme.custom.colors.black,
    },
    '&:focus': {
      color: theme.custom.colors.black,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

/**
 * A full width tab system that grows or shrinks based off the number of options passed into it
 * @param {object} props
 * @param {int} props.defaultIndex - The default selection of the tab system
 * @param {array} props.options - An array of Objects that contain label and id
 * @param {function} props.onSelection - Called and passed the ID of the selected value from the tab system
 *
 */
export const VNTabSystem = (props) => {

  // the value of the selected tab system
  const [value, setValue] = useState(props.defaultIndex ? props.defaultIndex : 0)

  useEffect(() => {
    if (Number.isInteger(props.defaultIndex) && props.defaultIndex > -1) {
      setValue(props.defaultIndex)
    }

    if (props.productTypeFilter !== undefined && Array.isArray(props.options)) {
      let index = props.options.findIndex((option) => { return option.id === props.productTypeFilter })
      setValue(index)
    }
  }, [props])

  // HANDLES

  /**
   * Called when the user selects a tab option
   * @param {object} event - The event that was triggered from the tab selection
   * @param {int} newValue - The int that was selected from the tab list
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (props.onSelection) {
      props.onSelection(props.options[newValue].id)
    }
  };

  const displayTabs = () => {
    if (props.options) {
      return props.options.map(tab => (
        <VNTab label={tab.label} key={tab.id} />
      ))
    }
  }

  return (
    <VNTabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
    >
      {displayTabs()}
    </VNTabs>
  )
}
