import { maxBy } from 'lodash'
import { arraysEqual } from '../VNUtil/ArrrayHelper'

export const getLoyaltyUser = (state) => {
  return state.VNWallet.get('loyalty_user')
}

export const getVirtualCurrencyActivity = (state) => {
  return state.VNWallet.get('virtual_currency_activity')
}

export const getAwardsRules = (state) => {
  return state.VNWallet.get('awards_rules')
}

export const VendPaymentTypes = {
  VN_BANK: 'vn_bank',
  // Virtual currerncy was initially implemented on Magic only, which is why virtual currency
  // is this value most of the time
  MAGIC_MONEY: 'magic_money',
  CREDIT_CARD: 'credit_card',
  APPLE_PAY: 'apple_pay',
  SHIFT4: 'shift4',
  MOST: 'most'
}

/**
 * Get the nonces to apply to a QR Code generator
 * @param {object} state - The state
 * @param {string} paymentTypes - Can pass as singular string value of VendPaymentTypes.MOST or a string of what you don't want the nonces to include
 */
 export const getDiscountChargeNonces = (state, paymentTypes) => {
  const nonces = state.VNWallet.get('nonces')

  if (!nonces) {
    return null
  }

  if (paymentTypes === VendPaymentTypes.MOST) {
    return maxBy(nonces.toArray(), item => { return item.payment_types.length })
  } else {

    // this will find the nonce array that has the MOST elements that does NOT include the given paymentTypes string
    let tempNonces = []
    const noncesArray = nonces.toArray()

    if (paymentTypes === VendPaymentTypes.VN_BANK || paymentTypes === VendPaymentTypes.MAGIC_MONEY) {
      noncesArray.forEach(element => {
        if (!element.payment_types.includes(VendPaymentTypes.VN_BANK) && !element.payment_types.includes(VendPaymentTypes.MAGIC_MONEY)) {
          tempNonces.push(element)
        }
      })
    } else {
      noncesArray.forEach(element => {
        if (!element.payment_types.includes(paymentTypes)) {
          tempNonces.push(element)
        }
      })
    }

    return maxBy(tempNonces, item => { return item.payment_types.length })
  }
}

/**
 * Get the nonce that only has the given paymentType
 * @param {object} state - The state
 * @param {string} paymentType - The single payment type nonce to look for
 */
export const getSingleTypeDiscountChargeNonce = (state, paymentType) => {
  const nonces = state.VNWallet.get('nonces')

  if (!nonces) {
    return null
  }

  if (paymentType === VendPaymentTypes.VN_BANK || paymentType === VendPaymentTypes.MAGIC_MONEY) {
    const nonceArray = nonces.toArray()

    return nonceArray.find( item =>
      arraysEqual(item.payment_types, [VendPaymentTypes.VN_BANK]) ||
      arraysEqual(item.payment_types, [VendPaymentTypes.MAGIC_MONEY])
    )
  }

  return nonces.toArray().find( item => item.payment_types === [paymentType])
}

/**
 * Get the nonce that only has the given paymentTypes
 * @param {object} state - The state
 * @param {array} paymentType - The array of payment types to look for nonce
 * @returns {object | null}
 */
export const getNonceByPaymentTypes = (state, paymentTypes) => {
  const nonces = state.VNWallet.get('nonces')

  if (!nonces || !paymentTypes?.length) {
    return null
  }

  const typesToMatch = [...paymentTypes].sort()

  const nonce = nonces.find(nonce => {
    return arraysEqual(typesToMatch, [...nonce.payment_types].sort())
  })

  return nonce
}

/**
 * Get the jwt for exchange service
 * @param {object} state - The state
 */
export const getExchangeServiceJwt = (state) => {
  return state.VNWallet.get('exchangeServiceJwt')
}

/**
 * Get the order total that is fethed after a QR Code SCAN
 * @param {object} state - The state
 */
export const getOrderTotal = (state) => {
  return state.VNWallet.get('order')
}

/**
 * Get the transfer email. This email parameter is currently only set from a
 * Virtual Currency transfer email sent to the transfer recipient, which directly
 * navigates the user to VNUserSignUp and gets reset upon detecting that the
 * transfer shows up in the VNVirtualCurrencyActivity page.
 * @param {object} state
 */
export const getVirtualCurrencyEmailAccept = (state) => {
  return state.VNWallet.get('transfer_email')
}

/**
 * Get the user's virtual currency promotions
 * @param {object} state - The state
 */
export const getVirtualCurrencyPromotions = (state) => {
  return state.VNWallet.get('virtual_currency_promotions')
}

export const getLastTimeQRCodeGenerated = (state) => {
  return state.VNWallet.get('lastTimeQRCodeGenerated')
}
