import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'openBadgeDialog': false,
    'openExchangeServiceDialog': false,
    'openPaymentUpdatesDialog': false,
    'openGiftCardTransferDialog': false,
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNWALLET_OPEN_BADGE_DIALOG:
      return state.set('openBadgeDialog', action.dialog)
    case ActionTypes.VNWALLET_OPEN_EXCHANGE_SERVICE_DIALOG:
      return state.set('openExchangeServiceDialog', action.dialog)
    case ActionTypes.VNWALLET_OPEN_PAYMENT_UPDATES_DIALOG:
      return state.set('openPaymentUpdatesDialog', action.dialog)
    case ActionTypes.VNWALLET_OPEN_GIFT_CARD_TRANSFER_DIALOG:
      return state.set('openGiftCardTransferDialog', action.dialog)
    default:
      return state
  }
}

export default reducer
