import React, { useState, Fragment } from 'react'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { VNDialog } from '../../VNComponents/VNDialog'

import { useTranslation } from 'react-i18next'

import './OrderSummaryItem.scss'

const OrderSummaryItem = ({ label, value, loading, disclaimerContent, ...props }) => {

  const { t } = useTranslation()

  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false)

  const className = classNames('order-summary-item', props.className)

  const displayServiceFeeDisclaimer = () => {
    if (disclaimerContent) {
      return (
        <Fragment>
          <InfoOutlinedIcon onClick={() => setIsDisclaimerModalOpen(true)} />
          <VNDialog
            open={isDisclaimerModalOpen}
            subTitle={disclaimerContent}
            save={t('OK')}
            onSave={() => setIsDisclaimerModalOpen(false)}
          />
        </Fragment>
      )
    }
  }

  return (
    <div {...props} className={className}>
      <span className="label">{label}{displayServiceFeeDisclaimer()}</span>
      <span className="value">{loading ? <Skeleton width='50px' /> : value}</span>
    </div>
  )
}

export default OrderSummaryItem
