import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

import VNDivider from '../../VNComponents/VNDivider'
import { VNSwitch } from '../../VNComponents/VNSwitch'

import { updateUser } from '../../VNUser/ActionCreators'
import { retrieveLoyaltyUser } from '../ActionCreators'

import { getUser } from '../../VNUser/Selectors'
import { getLoyaltyUser } from '../Selectors'
import { getVirtualCurrencyEnabled, getVirtualCurrencyName } from '../../VNConfigurations/Selectors'

import { useTranslation } from 'react-i18next'
import Money from '../../utils/money'

export const VNVirtualCurrencyEdit = () => {

  const dispatch = useDispatch()

  const { t } = useTranslation()

  // LOCAL STATE

  const [loyaltyApiRequested, setLoyaltyApiRequested] = useState(false)

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const virtualCurrencyName = useSelector(state => getVirtualCurrencyName(state))
  const isVirtualCurrencyEnabled = useSelector(state => getVirtualCurrencyEnabled(state))
  const loyaltyUser = useSelector(state => getLoyaltyUser(state))

  // USE EFFECTS

  /**
   * fetch the loyalty user from the Loyalty Service
   */
  useEffect(() => {
    if (!user.isEmpty() && user.get('provider') !== 'vn_anonymous' && !loyaltyApiRequested) {
      setLoyaltyApiRequested(true)
      dispatch(retrieveLoyaltyUser())
    }
  }, [user, loyaltyApiRequested, dispatch])

  const saveVirtualCurrencyPreference = (val) => {
    dispatch(updateUser({'useVirtualCurrencyFirst': val}, t))
  }

  const renderSubText = () => {
    if (user.get('useVirtualCurrencyFirst')) {
      return `${virtualCurrencyName} ${t('WILL_BE_APPLIED')}`
    }
    return `${t('TURN_ON_TO_PAY')} ${virtualCurrencyName}`
  }

   /**
   * Display virtual currency is you have a loyalty account
   */
  const displayVirtualCurrency = () => {
    if (isVirtualCurrencyEnabled && loyaltyUser.has('balance')) {
      return (
        <Box pb={3} pt={3}>
          <Box pb={4}>
            <Typography variant="h1">{virtualCurrencyName}</Typography>
            <VNSwitch action={saveVirtualCurrencyPreference}
                      title={Money.centsToDollars(loyaltyUser.get('balance'), true, false)}
                      checked={user.get('useVirtualCurrencyFirst') ? true : false}
                      subText={renderSubText()} />
          </Box>
          <VNDivider />
        </Box>
      )
    }
    return null
  }

  return (displayVirtualCurrency())
}
