import { createSelector } from 'reselect'
import pick from 'lodash/pick'
import get from 'lodash/get'
import moment from 'moment-timezone'

const getOrder = (state, id) => state.order[id] || {}
export const getOrderLineItem = (state) => state.order.orderLineItem || {}

export const makeGetOrder = () => (
  createSelector([getOrder], (order) => order)
)

const orderRequestStatus = (state) => state.order || {}

const getOrderLineItems = createSelector(
  [getOrder, getOrderLineItem],
  (order, lineItems) => {
    const items = get(order, 'relationships.lineItems.data', [])
    return pick(lineItems, items.map(item => item.id))
  }
)

export const makeGetOrderLineItems = () => {
  return createSelector([getOrderLineItems], (items) => items)
}

const getOrderRequestStatus = createSelector(
  [orderRequestStatus, getOrder],
  (orderState, order) => {
    const orderNumber = get(order, 'attributes.orderNumber')
    const isFetching = orderState.isFetching || false
    const hasError = !!order.error
    const waitingForOrderNumber = !orderNumber && !isFetching && !hasError && !!order
    const hourAgo = moment.now() - 36000

    return {
      isFetching: orderState.isFetching || false,
      lastFetched: orderState.lastFetched || hourAgo,
      minimumWait: 10000, // milliseconds
      shouldPoll: waitingForOrderNumber,
    }
  }
)

export const makeGetOrderRequestStatus = () => {
  return createSelector([getOrderRequestStatus], (status) => status)
}
