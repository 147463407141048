import axios from 'axios'
import * as CONFIG from '../App/configurations/Configs'
import {
  mockUpdateUser,
  mockApiGetUserAffiliations,
  mockApiLogoutUser,
  mockApiGetStadiumUser,
  mockApiGetUserPaymentMethods,
  mockApiRefreshUsersAffiliations,
  mockApiGetCardAffiliations
} from './Mock'
import { API_ENDPOINT, ORG_NAME, BASE_USER_URL, INSTANCE_NAME, BASE_URL } from '../constants'
import { generateTimestampWithTimeZoneOffset } from './Utils'

/**
 * @param {string} code Code provided from oauth provider - can also be a user UUID
 * @param {string} provider The provider you are requesting a token against - Current Options: 'ticketmaster', 'vn_anonymous', 'vn_sms_user', 'vn_external_user'
 * @param {object} [user] A user object that you want to create alongside generating the token
 * @param {String} bearerToken A bearer token that comes from the 3rd party integrator tokens in VNAPI
 * @description
 * retrieve a token for a user
 */
export const apiGetToken = (code, provider, user, bearerToken) => {

  let data = {
    provider: provider,
    instance: INSTANCE_NAME,
    currentTimestamp: generateTimestampWithTimeZoneOffset(),
  }

  // only add the code if we need it
  if (code) {
    data.code = code
  }

  if (user) {
    for (let [key, value] of Object.entries(user)) {
      data[key] = value
    }
  }

  let headers = {
    "Content-Type": "application/json; charset=utf-8"
  }

  if (bearerToken) {
    headers.Authorization = "Bearer " + bearerToken
  }

  return axios.post(`${API_ENDPOINT}/users/token`,
    data, {
      headers: headers
    }
  )
}

/**
 * @param {string} method How do you want to fetch the user: id, email, phone
 * @param {string} param The parameter to pass for the supplied method param above
 * @description
 * get user to see if it exists
 */
export const apiGetUser = (token, method, param) => {

  if (method === 'email' || method === 'phone') {
    param = encodeURIComponent(param)
  }

  return axios.get(`${API_ENDPOINT}/users?${method}=${param}`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * Update user information
 * @param {string} token - JWT to send in Authorization header
 * @param {object} data - JSON payload data
 */
export const apiUpdateUser = (token, data) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiUpdateUser) {
    return mockUpdateUser()
  } else {
    return axios.put(`${API_ENDPOINT}/users?id=${data.userID}`,
      data,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * Get the detailed affiliations of the users, these contain more details then what is just in the token
 * These are the affiliations that are part of VNAPI and NOT on the JWT.
 * This will NOT pull PCH affiliations for an example. This call is strictly used to get detailed information
 * about the affiliations that aren't part of the raw affiliation data, like colors and rank.
 * These affiliations are only used for display purposes and NOT used on orders, you need to use
 * apiRefreshUsersAffiliations to get them attached to the JWT.
 * @param {String} token - The JWT token of the user
 */
export const apiGetVNAPIUserAffiliations = (token) => {
  return axios.get(`${API_ENDPOINT}/users/affiliations`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * Look up user affiliation names from Stadium
 * @param {String} token - The JWT token of the user
 */
export const apiGetStadiumUserAffiliations = (token) => {
  return axios.get(`${BASE_URL}/users/assigned_affiliations`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * Get the detailed affiliations of the users
 * @param {String} token - The JWT token of the user
 * @param {String} provider - The provider name of the user
 * @returns A detailed affiliations array
 */
export const apiGetUserAffiliations = async (token, provider) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiGetUserAffiliations) {
    return mockApiRefreshUsersAffiliations()
  } else {

    let response

    try {
      response = await apiGetVNAPIUserAffiliations(token)
    } catch (e) {
      console.log(e.response?.data)
    }

    // if this user does not have detailed affiliations returned from VNAPI
    // attempt to look for user affiliations from Stadium
    if (!response?.data?.length && provider && provider !== 'vn_anonymous') {

      let stadiumUserAffiliationsResponse

      try {
        stadiumUserAffiliationsResponse = await apiGetStadiumUserAffiliations(token)
      } catch (e) {
        console.log(e.response?.data)
      }

      const assignedAffiliationNames = stadiumUserAffiliationsResponse?.data?.affiliations || []

      if (assignedAffiliationNames.length) {
        // look up detailed affiliation data by multiple names
        const names = assignedAffiliationNames.join('|')
        const filter = {
          name: names
        }

        let vnapiAffiliationsResponse

        try {
          vnapiAffiliationsResponse = await apiGetAffiliationsWithFilter(token, provider, filter)
        } catch (e) {
          console.log(e.response?.data)
        }

        const detailedAffiliations = vnapiAffiliationsResponse?.data || []

        return detailedAffiliations
      }
    }

    return response?.data || []
  }
}

/**
 * Refreshes the affiliations that are on a users account with the account provider
 * @param {String} token - The JWT token of the user
 * @returns
 */
export const apiRefreshUsersAffiliations = (token, shift4Token) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiRefreshUsersAffiliations) {
    return mockApiGetUserAffiliations()
  } else {

    let data = {
      instance: INSTANCE_NAME,
      timezoneOffset: (new Date()).getTimezoneOffset(),
      shift4Token: shift4Token
    }

    return axios.post(`${API_ENDPOINT}/users/affiliations/refresh`, data,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

export const apiGetCardAffiliations = (token, cardBin) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiGetCardAffiliations) {
    return mockApiGetCardAffiliations()
  } else {
    return axios.get(`${BASE_USER_URL}/vnapi/bin_affiliations/${cardBin}?organization_name=${ORG_NAME}`, {
      headers: {
        "Authorization": "Bearer " + token,
        "Accept": 'application/json'
      }
    })
  }
}

/**
 * Used to log the user out on the server, this is in particularly used for Ticketmaster
 * @param {string} token - JWT to send in Authorization header
 */
export const apiLogoutUser = (token) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiLogoutUser) {
    return mockApiLogoutUser()
  } else {
    return axios.post(`${API_ENDPOINT}/users/logout`, { instance: INSTANCE_NAME }, {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  }
}

export const apiGetStadiumUser = (id, token) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiGetStadiumUser) {
    return mockApiGetStadiumUser()
  } else {
    return axios.get(`${BASE_USER_URL}/vnapi/users/${id}?organization_name=${ORG_NAME}`, {
      headers: {
        "Authorization": "Bearer " + token,
        "Accept": 'application/json'
      }
    })
  }
}

export const apiGetUserPaymentMethods = (id, token) => {
  if (CONFIG.DATA.MOCK.VN_USER_apiGetUserPaymentMethods) {
    return mockApiGetUserPaymentMethods()
  } else {
    return axios.get(`${BASE_USER_URL}/vnapi/users/${id}/payment_methods?organization_name=${ORG_NAME}`, {
      headers: {
        "Authorization": "Bearer " + token,
        "Accept": 'application/json'
      }
    })
  }
}

/**
 * Look up detailed affiliation data from VN configurations
 * @param {String} token - The JWT token of the user
 * @param {String} provider - The provider of the user
 * @param {String} affName - The name of the affiliation to look up
 */
export const apiGetAffiliationsByName = (token, provider, affName) => {
  return axios.get(`${API_ENDPOINT}/affiliations?provider=${provider}&name=${encodeURIComponent(affName)}`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * Look up detailed affiliations data from VN configurations
 * @param {String} token - The JWT token of the user
 * @param {String} provider - The provider of the user
 * @param {Object} filter - The filter for affilitions data. Example - { name: "Platinum|AF PSL Holder", rank: "5|6", external_id: "51|52" }
 */
export const apiGetAffiliationsWithFilter = (token, provider, filter) => {

  let url = `${API_ENDPOINT}/affiliations?provider=${provider}`

  if (filter && Object.keys(filter).length) {
    url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`
  }

  return axios.get(url,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * Contact VNAPI to check for affiliations on tickets
 * @param {String} jwt - The user JWT
 * @param {String} provider - The provider
 */
export const apiGetTicketAffiliations = (jwt, provider) => {

  const data = {
    provider,
    instance: INSTANCE_NAME,
    currentTimestamp: generateTimestampWithTimeZoneOffset()
  }

  const axiosConfig = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }

  return axios.post(`${API_ENDPOINT}/exchange/affiliations/transfer`, data, axiosConfig)
}
