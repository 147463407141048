import * as ActionTypes from './ActionTypes'

export const setApiLoadingStatus = (actionType, apiStatusObj) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNAPILOADINGSYSTEMCACHED_SET_STATUS,
      apiStatusObj: {
        type: actionType,
        ...apiStatusObj
      }
    })
  }
}
