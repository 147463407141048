const stylesUtils = require('../styles/utils')
const components = require('../components/index')

const LOADER_ID = 'VN-LOADER-ROOT'

const makeLoaderBar = (count) => {

  const div = document.createElement('div')
  const style = {
    width: '100%',
    height: '48px',
    'border-radius': '6px',
    background: 'rgba(234, 242, 255',
    'margin-bottom': '16px',
    animationDelay: `${count}s`
  }

  stylesUtils.attachStyles(div, style)
  div.classList.add('animate-flicker')
  return div
}

const makeLoader = () => {
  const div = components.createElement('div', LOADER_ID)
  const bar1 = makeLoaderBar(0.75)
  const bar2 = makeLoaderBar(0.5)
  const bar3 = makeLoaderBar(0.25)
  const bar4 = makeLoaderBar(0)
  div.prepend(bar1)
  div.prepend(bar2)
  div.prepend(bar3)
  div.prepend(bar4)
  return div
}

const removeLoader = () => {
  const div = document.getElementById(LOADER_ID)
  if (div) {
    div.parentElement.removeChild(div)
  }
}

module.exports = {
  makeLoader: makeLoader,
  removeLoader: removeLoader
}
