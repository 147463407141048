import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import Remote from '../remote'
import { setLoading, setFailed, setSucceeded } from '../actions/remote'
import { getCartMenuItems } from '../selectors/cart'
import { orderMapper } from '../mappers/order'
import { experienceOrderMapper } from '../mappers/experiences/order'
import { getUser } from '../VNUser/Selectors'
import { setAnonymousExperienceOrderHistory } from '../VNUser/ActionCreators'

import {
  createExperienceOrderSucceeded,
  createExperienceOrderFailed,
  CREATE_EXPERIENCE_ORDER_REQUESTED,
  createOrderSucceeded,
  createOrderFailed,
  CREATE_ORDER_REQUESTED,
  updateOrderSucceeded,
  updateOrderFailed,
  UPDATE_ORDER_REQUESTED,
  getExperienceOrderSucceeded,
  getExperienceOrderFailed,
  GET_EXPERIENCE_ORDER_REQUESTED,
  getOrderSucceeded,
  getOrderFailed,
  GET_ORDER_REQUESTED,
  updateOrderMetadataSucceeded,
  updateOrderMetadataFailed,
  UPDATE_ORDER_METADATA_REQUESTED,
} from '../actions/order'

export function* createExperienceOrder(params) {
  const items = yield select(state => getCartMenuItems(state))
  const orderParams = experienceOrderMapper({ items, ...params.orderParams })

  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    yield put({ type: 'VNBACKDROPSYSTEM_SET', enabled: true })
    const result = yield call(Remote.createExperienceOrder, orderParams, token)
    yield put({ type: 'VNBACKDROPSYSTEM_SET', enabled: false })
    yield put(createExperienceOrderSucceeded(result))
    if (user.get('provider') === 'vn_anonymous') {
      yield put(setAnonymousExperienceOrderHistory(user, result))
    }
  } catch (err) {
    yield put({ type: 'VNBACKDROPSYSTEM_SET', enabled: false })
    yield put(createExperienceOrderFailed(err))
  }
}

export function* createOrder(params) {
  const items = yield select(state => getCartMenuItems(state))
  const menuId = yield select(state => state.cart.menuId)
  const orderParams = orderMapper({ items, menuId, ...params.orderParams })

  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    yield put({ type: 'VNBACKDROPSYSTEM_SET', enabled: true })
    const result = yield call(Remote.createOrder, orderParams, token)
    yield put({ type: 'VNBACKDROPSYSTEM_SET', enabled: false })
    yield put(createOrderSucceeded(result))
  } catch (err) {
    yield put({ type: 'VNBACKDROPSYSTEM_SET', enabled: false })
    yield put(createOrderFailed(err))
  }
}

export function* updateOrder(params) {
  const endpoint = Remote.endpoints.updateOrder
  yield put(setLoading(endpoint))

  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.updateOrder, params, token)

    yield all([
      put(updateOrderSucceeded(result)),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put(updateOrderFailed(params, err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* getExperienceOrder(orderId) {
  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.getExperienceOrder, orderId, token)
    yield put(getExperienceOrderSucceeded(result))
  } catch (err) {
    yield put(getExperienceOrderFailed(orderId, err))
  }
}

export function* getOrder(orderId) {
  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.getOrder, orderId, token)
    yield put(getOrderSucceeded(result))
  } catch (err) {
    yield put(getOrderFailed(orderId, err))
  }
}

export function* updateOrderMetadata(params) {
  const endpoint = Remote.endpoints.updateOrderMetadata
  yield put(setLoading(endpoint))
  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.updateOrderMetadata, params, token)

    yield all([
      put(updateOrderMetadataSucceeded(result)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(updateOrderMetadataFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchCreateExperienceOrder() {
  yield takeLatest(CREATE_EXPERIENCE_ORDER_REQUESTED, createExperienceOrder)
}

export function* watchCreateOrder() {
  yield takeLatest(CREATE_ORDER_REQUESTED, createOrder)
}

export function* watchGetOrder() {
  yield takeLatest(GET_ORDER_REQUESTED, getOrder)
}

export function* watchGetExperienceOrder() {
  yield takeLatest(GET_EXPERIENCE_ORDER_REQUESTED, getExperienceOrder)
}

export function* watchUpdateOrder() {
  yield takeLatest(UPDATE_ORDER_REQUESTED, updateOrder)
}

export function* watchUpdateOrderMetadata() {
  yield takeLatest(UPDATE_ORDER_METADATA_REQUESTED, updateOrderMetadata)
}
