import { useSelector } from 'react-redux'
import { getVirtualCurrencyEnabled } from '../../VNConfigurations/Selectors'
import { getUseVirtualCurrencyFirst } from '../../VNUser/Selectors'
import { getLoyaltyUser } from '../../VNWallet/Selectors'

function usePaymentMapping(braintreePayload, shift4Payload, isFree, orderTotalInCents) {
  const useVirtualCurrencyFirst = useSelector(state => getUseVirtualCurrencyFirst(state))
  const isVirtualCurrencyEnabled = useSelector(state => getVirtualCurrencyEnabled(state))
  const loyaltyUser = useSelector(state => getLoyaltyUser(state))

  let payments = []
  let tempRemainingOrderTotalInCents = orderTotalInCents
  const vcBalance = loyaltyUser.get('balance')
  const autoReplenish = loyaltyUser.get('auto_replenish')

  if (isFree) {
    // need set the payment type to cash in case of total amount is 0
    payments.push({
      amount_in_cents: 0,
      payment_type: 'cash'
    })
  } else if (isVirtualCurrencyEnabled && useVirtualCurrencyFirst && autoReplenish) {

    // if the user has auto replenish on in their loyalty account, we charge the entire order to VNBank, regardless of their balance.
    payments.push({
      payment_type: 'vn_bank',
      amount_in_cents: orderTotalInCents
    })
  } else {
    // do we want to use VC
    if (isVirtualCurrencyEnabled && useVirtualCurrencyFirst && vcBalance > 0) {

      if ((orderTotalInCents - vcBalance) > 0) {
        payments.push({
          payment_type: 'vn_bank',
          amount_in_cents: vcBalance
        })
        tempRemainingOrderTotalInCents = orderTotalInCents - vcBalance
      } else {
        payments.push({
          payment_type: 'vn_bank',
          amount_in_cents: orderTotalInCents
        })
        tempRemainingOrderTotalInCents = 0
      }
    }

    if (tempRemainingOrderTotalInCents > 0 && braintreePayload) {
      payments.push({
        payment_type: braintreePayload.type === 'CreditCard' ? 'credit_card' : 'apple_pay',
        amount_in_cents: tempRemainingOrderTotalInCents
      })
    } else if (tempRemainingOrderTotalInCents > 0 && shift4Payload) {
      payments.push({
        payment_type: 'shift4_token',
        amount_in_cents: tempRemainingOrderTotalInCents,
        token: shift4Payload.uniqueId
      })
    }
  }

  return payments
}

export default usePaymentMapping
