import React from 'react'
import Button from './Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './AlertDialog.scss'

export const defaultAlertDialogState = {
  onConfirm: null,
  onDismiss: null,
  show: false,
  textConfirm: null,
  textDismiss: null,
  textMain: null,
}

export class AlertDialog extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      inputValue: '',
    }
  }

  handleChange(event) {
    if (this.props.input.validation(event.target.value)) {
      this.setState({
        inputValue: event.target.value
      })
    } 
  }

  handleBlur(event) {
    const newVal = this.props.input.format(event.target.value)
    this.setState({
      inputValue: newVal
    })
  }

  onFocus(event) {
    const newVal = this.props.input.onFocus(event.target.value)
    this.setState({
      inputValue: newVal
    })
  }

  render() {
    const { onConfirm, onDismiss, textConfirm, textDismiss, textMain, input, t } = this.props

    const pTextConfirm = textConfirm ? textConfirm : t('OK')
    const pTextDismiss = textDismiss ? textDismiss : t('DISMISS')
    const pTextMain = textMain ? textMain : t('ARE_YOU_SURE')

    const checkInputDisplay = () => {
      if (input) {
        return(
          <FormControl id="input-form-control" variant="filled">
            <InputLabel htmlFor="filled-adornment-amount">{input.label}</InputLabel>
            <FilledInput
              id="filled-adornment-amount"
              autoFocus
              value={this.state.inputValue}
              onChange={this.handleChange.bind(this)}
              onBlur={this.handleBlur.bind(this)}
              onFocus={this.onFocus.bind(this)}
              startAdornment={<InputAdornment position="start">{input.startAdornment}</InputAdornment>}
            />
          </FormControl>
        )
      }
    }

    const preConfirmEvent = () => {
      if (input) {
        onConfirm(this.state.inputValue)
      } else {
        onConfirm()
      }
    }

    return (
      <span>
        <Dialog open={true} onClose={onDismiss} >
          <DialogContent>
            <DialogContentText>{pTextMain}</DialogContentText>
          </DialogContent>
          {checkInputDisplay()}
          <DialogActions>
            <Button onClick={onDismiss}>{pTextDismiss}</Button>
            <Button onClick={preConfirmEvent}>{pTextConfirm}</Button>
          </DialogActions>
        </Dialog>
      </span>
    )
  }
}

AlertDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  textConfirm: PropTypes.string,
  textDismiss: PropTypes.string,
  textMain: PropTypes.string,
  inputObj: PropTypes.object,
}

export default withTranslation()(AlertDialog)
