export const MENU_REQUESTED = 'MENU_REQUESTED'
export const MENU_SUCCEEDED = 'MENU_SUCCEEDED'
export const MENU_FAILED = 'MENU_FAILED'
export const STAND_FROM_MENU_REQUESTED = 'STAND_FROM_MENU_REQUESTED'
export const STAND_FROM_MENU_SUCCEEDED = 'STAND_FROM_MENU_SUCCEEDED'

export const loadMenu = (menuId) => ({
  type: MENU_REQUESTED,
  menuId,
})

export const loadMenuSucceeded = (payload) => ({
  type: MENU_SUCCEEDED,
  payload,
})

export const loadMenuFailed = (payload) => ({
  type: MENU_FAILED,
  payload,
})

export const loadStandFromMenu = (menuId) => ({
  type: STAND_FROM_MENU_REQUESTED,
  menuId,
})

export const loadStandFromMenuSucceeded = (payload) => ({
  type: STAND_FROM_MENU_SUCCEEDED,
  payload
})
