import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box, IconButton } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'

import { ItemModifierTracker } from '../../containers/Cart'

import { VNSelectWithDialog } from '../../VNComponents/VNSelectWithDialog'
import VNDivider from '../../VNComponents/VNDivider'
import getItemMaxQuantity from '../../VNUtil/GetItemMaxQuantity'

import { getOtherAlcoholicItemQuantityInCart, getCartItem } from '../../selectors/cart'

import { history } from '../../App/store/configureStore'
import Money from '../../utils/money'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 5
  },
  closeButton: {
    padding: 0
  },
  headingText: {
    marginBottom: '0 !important'
  },
  modifierText: {
    marginBottom: 15
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginRight: 10
    }
  }
}))

/**
 *
 * @param {object} props
 * @param {object} props.item - Item as it is in state.cart
 * @param {array} props.itemModifierIndices - Array of indices that point to the actual modifiers in state.cart.item.modifiers
 * @param {string} props.itemModifierRelationshipKey - <item_id>.<hash_of_array_to_string_of_modifiers>
 * @param {int} props.quantity - Quantity of this item with this particular set of modifiers
 * @param {float} props.price - Total price of this item with all modifiers and quantity taken into accoount
 * @param {bool} props.quickpay - Is quickpay
 * @param {function} props.onRemove - Callback when item needs to be removed
 * @param {function} props.onQuantityEdit - Callback when items needs to have its quantity edited
 * @returns
 */
export const VNCartListItem = ({
  item, itemModifierIndices, itemModifierRelationshipKey,
  standId, menuId, modifiers,
  quantity, price, quickpay,
  onRemove, onQuantityEdit,
  menu, isRichCheckoutQR, isMerchandise,
  ...props
}) => {

  const classes = useStyles()

  const cartItem = useSelector(state => getCartItem(state, item.id))
  const otherAlcoholicItemQuantity = useSelector(state => getOtherAlcoholicItemQuantityInCart(state, item.id))

  const getMaxQuantity = () => getItemMaxQuantity(item, cartItem?.quantity, menu.alcoholLimit, otherAlcoholicItemQuantity)

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(item, itemModifierIndices)
    }
  }

  const handleQuantitySelected = (quantity) => {
    if (onQuantityEdit) {
      onQuantityEdit(quantity)
    }
  }

  const handleEditSelected = () => {
    history.replace(`/cart/${standId}/menu/${menuId}/item/${item.id}`, {
      editItem: item,
      editItemModifierIndices: itemModifierIndices,
      editItemRelationshipKey: itemModifierRelationshipKey
    })
  }

  const renderModifierLabels = () => {
    let modifierLabels = []

    if (!isEmpty(modifiers)) {
      new ItemModifierTracker(item, modifiers).iterate((mod, priceIncrement) => {
        let displayName = mod.name

        let price = Money.dollarsToCents(mod.price)
        if (!price) {
          price = priceIncrement
        }

        if (price > 0) {
          const displayPrice = Money.centsToDollars(price, false, true)
          displayName += ` +${displayPrice}`
        }

        modifierLabels.push(displayName)
      })
    }

    return <Typography className={classes.modifierText} variant='subtitle1'>{modifierLabels.join(', ')}</Typography>
  }

  const qtySelectOptions = []
  const qtyAllowedToOrder = getMaxQuantity()
  const maxQtyToSelect = quantity > qtyAllowedToOrder ? quantity : qtyAllowedToOrder
  for (let i = (item.orderMinCount || 1); i <= maxQtyToSelect; i++) {
    qtySelectOptions.push(i)
  }

  const showEditButton = !isEmpty(modifiers) && !isRichCheckoutQR && !isMerchandise

  return (
    <Box pt={2} pb={2}>
      <Box className={classes.headingContainer}>
        <Typography
          variant='h3'
          classes={{ root: classes.headingText }}>
            {item.name}
        </Typography>
        {!isRichCheckoutQR &&
          <IconButton
            classes={{ root: classes.closeButton }}
            onClick={handleOnRemove}
            aria-label={"remove item"}>
              <Cancel />
          </IconButton>
        }
      </Box>
      {renderModifierLabels()}
      <Box pb={3} className={classes.priceContainer}>
        <Typography variant='h2'>{price}</Typography>
        <VNSelectWithDialog
          default={quantity}
          options={qtySelectOptions}
          onSelection={handleQuantitySelected}
          nonInteractive={isRichCheckoutQR || qtySelectOptions.length < 2}
        />
        {showEditButton &&
          <VNSelectWithDialog
            default={'Edit'}
            onSelection={handleEditSelected} />
        }
      </Box>
      <VNDivider />
    </Box>
  )
}

export default VNCartListItem
