import ItemSetting from '../VNEnums/ItemSetting'

/**
 * Calculate the max quantity allowed for the item
 * @param {object} item - The item
 * @param {number} cartItemQuantity - The quantity of the same item in cart
 * @param {number} menuAlcoholLimit - (optional) - The alcohol limit on the menu
 * @param {number} alcoholicItemQuantity - (optional) - The total quantity of alcoholic items in cart
 * @returns {number}
 */
const getItemMaxQuantity = (item, cartItemQuantity, menuAlcoholLimit, alcoholicItemQuantity) => {
  let itemOrderMaxAmount = item.orderMaxAmount

  // For experience item, we need to use onHandQuantity as its maxQuantity if
  // 1. itemOrderMaxAmount is NOT an integer OR
  // 2. itemOrderMaxAmount is an integer but it is larger than the onHandQuantity
  if (item.isExperience) {
    const itemVariant = item.variants.find(variant => variant.menuItemUuid === item.id)
    if (!Number.isInteger(itemOrderMaxAmount) || itemOrderMaxAmount > itemVariant.onHandQuantity) {
      itemOrderMaxAmount = itemVariant.onHandQuantity
    }
  }

  let maxQuantity = 0

  if (item.isAlcohol && menuAlcoholLimit) {
    maxQuantity = menuAlcoholLimit - alcoholicItemQuantity
  } else {
    maxQuantity = Number.isInteger(itemOrderMaxAmount) ? itemOrderMaxAmount : ItemSetting.DEFAULT_MAX_AMOUNT
  }

  maxQuantity -= (cartItemQuantity || 0)

  if (maxQuantity && Number.isInteger(itemOrderMaxAmount) && maxQuantity > itemOrderMaxAmount) {
    // limit max quantity based on item level configuration
    maxQuantity = itemOrderMaxAmount
  }

  if (Number.isInteger(item.orderMaxAmount) && Number.isInteger(item.orderMinCount)) {
    if (item.orderMaxAmount - item.orderMinCount < 0) {
      // misconfigured min/max values
      maxQuantity = 0
    }
  }

  if (maxQuantity < 0) {
    maxQuantity = 0
  }

  return maxQuantity
}

export default getItemMaxQuantity
