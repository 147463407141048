import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  setWebSDKMode,
  setWebSDKPlatform,
  setWebSDKVersion,
  setWebSDKBundleId,
  setWebSDKNavMode,
  setInitialPathname,
  setWebSDKIsExternalPaymentProcessor,
  setWebSDKBackgroundColor,
  setWebSDKAccentColor,
  setSectionRowSeat,
  setWebSDKDataReceive
} from '../ActionCreators'
import { createOrGetUser, logoutUser } from '../../VNUser/ActionCreators'
import { setWebSDKProductType } from '../../VNWebSDK/ActionCreators'
import { clearCart } from '../../actions/cart'
import { clearOrderTotal } from '../../actions/orderTotal'
import { fetchStandFromMenu } from '../../VNMenu/ActionCreators'
import { updateUserLocationConfirmed, updateUserAgeVerification } from '../../actions/user'
import useRevenueCenters from '../../VNHooks/useRevenueCenters'

import { getUser } from '../../VNUser/Selectors'
import { getWebSDKMode } from '../Selectors'
import { getStands, getMenusKeyedByStandId } from '../../VNRevenueCenters/Selectors'

import WebSDKPlatform from '../../VNEnums/WebSDKPlatform'
import WebSDKNavMode from '../../VNEnums/WebSDKNavMode'
import VNWebSDKAnalytics from '../../VNWebSDK/reporting/VNWebSDKAnalytics'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { history } from '../../App/store/configureStore'
import { setProductTypeFilter, setServiceTypeFilter, setEventFilter } from '../../actions/filter'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const VNWebSDKDeeplinkBridge = () => {

  useRevenueCenters()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const store = useStore()

  const classes = useStyles()

  const user = useSelector(state => getUser(state))

  const stands = useSelector(state => getStands(state, true))

  const webSDKMode = useSelector(state => getWebSDKMode(state))

  const menusKeyedByStandId = useSelector(state => getMenusKeyedByStandId(state))

  //#region LOCAL STATE
  const [isRefreshingUser, setIsRefreshingUser] = useState(false)
  const [needsReroute, setNeedsReroute] = useState(false)
  const [cachedPayload, setCachedPayload] = useState({})
  const [isFetchingStandFromMenu, setIsFetchingStandFromMenu] = useState(false)

  // Stands need to be retrieved before routing to any page because of the foundational
  // aspect of stands in OrderNext. The below are the explicitly defined routes which do not
  // need stands and it is safe to navigate to them
  const routesAllowedWithoutStands = [
    '/profile/settings',
    '/wallet?showBadge=1',
    '/wallet'
  ]
  //#endregion

  //#region USE EFFECTS
  // initialize the WEBSDK Bridge on mount
  useEffect(() => {

    // set state for
    dispatch(setWebSDKMode(true))

    // setting default nav mode to no_hamburger for the time being
    // until this gets more flushed out
    dispatch(setWebSDKNavMode(WebSDKNavMode.NO_HAMBURGER))

    // clear cart confirmed so that if the user deep links directly from menu to menu
    // this info is not ached
    dispatch(updateUserLocationConfirmed(false))
    dispatch(clearCart())
    dispatch(clearOrderTotal())
    dispatch(updateUserAgeVerification(false))
    // Clear web SDK data as to not automatically trigger
    // a scan via other useEffect after coming back to this page
    dispatch(setWebSDKDataReceive({}))

    window.VENUENEXT_WEBSDK = {
      DEEPLINK_BRIDGE: deepLinkBridge
    }

  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const menuId = cachedPayload.menu_id

    if (isRefreshingUser && !user.isEmpty() && webSDKMode) {
      if (!stands.isEmpty()) {
        setIsRefreshingUser(false)
        // If user is deeplinking to a menu, we need to make sure that it's an actual
        // menu and part of an actual stand before redirecting
        if (menuId) {
          if (standForMenu(menuId)) {
            reroute(cachedPayload)
          } else {
            setNeedsReroute(true)
            dispatch(fetchStandFromMenu(menuId))
          }
        } else {
          reroute(cachedPayload)
        }
      } else if (routesAllowedWithoutStands.includes(cachedPayload.route)) {
        reroute(cachedPayload)
      }
    } else if (!isRefreshingUser && needsReroute && webSDKMode && !user.isEmpty()) {
      if (!stands.isEmpty()) {
        // Set timeout hack is needed specifically for Android. When setting webSDKMode
        // and then navigating away too fast via window.location, the next react page
        // rehydrates the store and does not persist the correct value, which causes
        // the navigation to show when it is intended to hide.
        // Bug report filed with redux team: https://github.com/rt2zz/redux-persist/issues/1252
        if (menuId) {
          // If user is deeplinking to a menu, we need to make sure that it's an actual
          // menu and part of an actual stand before redirecting
          if (standForMenu(menuId)) {
            setNeedsReroute(false)
            setTimeout(() => {
              reroute(cachedPayload)
            }, 100)
          } else if (!isFetchingStandFromMenu) {
            setIsFetchingStandFromMenu(true)
            dispatch(fetchStandFromMenu(menuId))
          }
        } else {
          setTimeout(() => {
            reroute(cachedPayload)
          }, 100)
        }
      } else if (routesAllowedWithoutStands.includes(cachedPayload.route)) {
        reroute(cachedPayload)
      }
    } else if (webSDKMode && !user.isEmpty() && routesAllowedWithoutStands.includes(cachedPayload.route)) {
      // if route is in routesAllowedWithoutStands reroute to the cached payload.
      // Sometimes the other if statements will not trigger causing an infinite loop.
      reroute(cachedPayload)
    }
  }, [user, webSDKMode, needsReroute, stands, menusKeyedByStandId])
  //#endregion

  const standForMenu = menuUuid => {
    const menusKeyedByStandIdObj = menusKeyedByStandId.toObject()
    let keys = Object.keys(menusKeyedByStandIdObj)

    for (var i = 0; i < keys.length; i++) {
      let menu = menusKeyedByStandIdObj[keys[i]]?.find(m => m.uuid === menuUuid)
      if (menu) {
        return keys[i]
      }
    }
  }

  // called when WebSDK wants to make calls into react, this is open to the public
  // base64Parameters : String - This is the parameters being passed from native that is base64 encoded
  const deepLinkBridge = (base64Parameters) => {

    // decode and parse the payload back to a JSON obj
    var decodedParams = window.atob(base64Parameters)
    var payload = JSON.parse(decodedParams)

    setCachedPayload(payload)

    // Payload Structure - everything is optional and will NOT be there if its not needed in the call
    /*
      {
          event_id: '',
          menu_id: '',
          product_type: 'Food | Merchandise | Experience',
          service_type: 'Delivery | Pickup',
          variant_id : '',
          item_id: '',
          user: {id: '', email: '', first_name: '', last_name: '', provider: ''},
          order_history: 'orders',
      }
    */

    dispatch(setWebSDKBackgroundColor(payload.bg_color))
    dispatch(setWebSDKAccentColor(payload.accent_color))
    dispatch(setWebSDKIsExternalPaymentProcessor(payload.is_external_payment_processor))
    dispatch(setWebSDKPlatform(WebSDKPlatform.getPlatform(payload.platform)))
    dispatch(setWebSDKVersion(payload.sdk_version))
    dispatch(setWebSDKBundleId(payload.sdk_bundle_id))
    dispatch(setSectionRowSeat(payload.section_row_seat))

    if (user.isEmpty()) {
      if (payload.user) {
        // if the integration is attempting to pass in a user and the user is NOT loaded from state
        setIsRefreshingUser(true)
        dispatch(createOrGetUser('', payload.user.provider ? payload.user.provider : 'vn_external_user', payload.user, t, payload.bearer_token))
      } else {
        // go ahead and route and create anonymous user
        setNeedsReroute(true)
        dispatch(createOrGetUser('', 'vn_anonymous', null, t))
      }
    } else {
      if (payload.user) {
        // If the user changes from native, need to logout existing user and createOrGetUser.
        // Most likely users will not be switching a vn_external_user, this scenario will
        // probably come up in the event they've viewed OrderingWeb anonymously, but now
        // need to switch to an authorized external user
        if ((payload.user.id && !payload.user.id.includes(user.get('userID'))) || payload.user.tmAccessToken) {
          dispatch(logoutUser())
          setIsRefreshingUser(true)
          dispatch(createOrGetUser('', payload.user.provider ? payload.user.provider : 'vn_external_user', payload.user, t, payload.bearer_token))
        } else {
          // There is a rehydrated user and the user passed in via bridge matches
          // Nothing else to do so proceed to route
          setNeedsReroute(true)
          VNWebSDKAnalytics.reportUser(user.get('userID'))
        }
      } else {
        // A rehydrated user exists, but no user is passed in. This will be reached
        // usually when an anonymous user has visited OrderingWeb and is anonymous,
        // and visits again and is still anonymous
        setNeedsReroute(true)
        if (user.get('provider') !== 'vn_anonymous') {
          dispatch(logoutUser())
          setIsRefreshingUser(true)
          dispatch(createOrGetUser('', 'vn_anonymous', null, t))
        } else {
          VNWebSDKAnalytics.reportUser(user.get('userID'))
        }
      }
    }
  }

  const replaceRoute = (pathName) => {
    dispatch(setInitialPathname(pathName.split('?')[0]))
    history.replace(pathName, {
      isSDKDeeplink: true
    })
  }

  const reroute = payload => {
    if (payload.route) {
      const route = payload.route
      replaceRoute(route)

      // Clear cart and order total in case user has these cached
      // from a previous viewing, which is unwanted behavior
      if (route === '/wallet/scanner') {
        dispatch(clearCart())
        dispatch(clearOrderTotal())
      }
      return
    }

    dispatch(setWebSDKProductType(payload.product_type))

    // Showing Stand Type and Service Type
    if (payload.product_type && payload.service_type) {
      dispatch(setProductTypeFilter(payload.product_type))
      if (payload.service_type === 'Pickup') {
        dispatch(setServiceTypeFilter('Concessions'))
      } else {
        dispatch(setServiceTypeFilter(payload.service_type))
      }
      replaceRoute('/')

      return
    }

    // Details of a specific experience with an event pre-populated
    if (payload.product_type === 'Experience' && payload.menu_id && payload.item_id && payload.event_id) {
      dispatch(setEventFilter(payload.event_id))
      replaceRoute(`/${standForMenu(payload.menu_id)}/experiences/${payload.menu_id}/item/${payload.item_id}?eventFilter=${payload.event_id}`)
      return
    }

    // Details of a specific experience with no event pre-populated
    if (payload.product_type === 'Experience' && payload.menu_id && payload.item_id) {
      replaceRoute(`/${standForMenu(payload.menu_id)}/experiences/${payload.menu_id}/item/${payload.item_id}`)
      return
    }

    // Experience category with an event pre-populated
    if (payload.product_type === 'Experience' && payload.menu_id && payload.event_id) {
      dispatch(setEventFilter(payload.event_id))
      replaceRoute(`/${standForMenu(payload.menu_id)}/experiences/${payload.menu_id}?eventFilter=${payload.event_id}`)
      return
    }

    // Experience category with no event pre-populated
    if (payload.product_type === 'Experience' && payload.menu_id) {
      replaceRoute(`/${standForMenu(payload.menu_id)}/experiences/${payload.menu_id}`)
      return
    }

    // Showing Service Type
    if (payload.service_type) {
      dispatch(setServiceTypeFilter(payload.service_type))
      replaceRoute(`/?serviceType=${payload.service_type}`)
      return
    }

    // show a specific stand, menu, and item
    if (payload.product_type && payload.menu_id && payload.item_id) {
      replaceRoute(`/${standForMenu(payload.menu_id)}/menu/${payload.menu_id}/item/${payload.item_id}`)
      return
    }

    // Showing specific menu from a specific stand
    if (payload.product_type && payload.menu_id) {
      replaceRoute(`/${standForMenu(payload.menu_id)}/menu/${payload.menu_id}`)
      return
    }

    if (payload.menu_id) {
      replaceRoute(`/${standForMenu(payload.menu_id)}/menu/${payload.menu_id}`)
      return
    }

    // Showing Stand Types
    if (payload.product_type) {
      if (payload.product_type === 'All') {
        dispatch(setProductTypeFilter('Food'))
        replaceRoute(`/?productType=Food`)
        return
      }

      dispatch(setProductTypeFilter(payload.product_type))
      replaceRoute(`/?productType=${payload.product_type}`)
      return
    }

    if (payload.menu_id) {
      replaceRoute(`/${standForMenu(payload.menu_id)}/menu/${payload.menu_id}`)
      return
    }

    // Navigate to a specific users order history
    if (payload.order_history) {
      replaceRoute(`/profile/${payload.order_history}`)
      return
    }
  }

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
