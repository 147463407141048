import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'productTypeSelected': 'Food',
    'serviceTypeSelected': 'All'
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNQUERYPARAMS_SET_PRODUCT_TYPE_SELECTED:
      return state.set('productTypeSelected', action.productType)
    case ActionTypes.VNQUERYPARAMS_SET_SERVICE_TYPE_SELECTED:
      return state.set('serviceTypeSelected', action.serviceType)
    default:
      return state
  }
}

export default reducer
