export const LOAD_SECTIONS_REQUESTED = 'LOAD_SECTIONS_REQUESTED'
export const LOAD_SECTIONS_SUCCEEDED = 'LOAD_SECTIONS_SUCCEEDED'
export const LOAD_SECTIONS_FAILED = 'LOAD_SECTIONS_FAILED'

export const LOAD_ROWS_REQUESTED = 'LOAD_ROWS_REQUESTED'
export const LOAD_ROWS_SUCCEEDED = 'LOAD_ROWS_SUCCEEDED'
export const LOAD_ROWS_FAILED = 'LOAD_ROWS_FAILED'

export const LOAD_SEATS_REQUESTED = 'LOAD_SEATS_REQUESTED'
export const LOAD_SEATS_SUCCEEDED = 'LOAD_SEATS_SUCCEEDED'
export const LOAD_SEATS_FAILED = 'LOAD_SEATS_FAILED'

export const LOAD_MENUS_FOR_SEAT_REQUESTED = 'LOAD_MENUS_FOR_SEAT_REQUESTED'
export const LOAD_MENUS_FOR_SEAT_SUCCEEDED = 'LOAD_MENUS_FOR_SEAT_SUCCEEDED'
export const LOAD_MENUS_FOR_SEAT_FAILED = 'LOAD_MENUS_FOR_SEAT_FAILED'

export const loadSections = ({ standId }) => ({
  type: LOAD_SECTIONS_REQUESTED,
  payload: {
    standId,
  }
})

export const loadSectionsSucceeded = (payload) => ({
  type: LOAD_SECTIONS_SUCCEEDED,
  payload,
})

export const loadSectionsFailed = (payload) => ({
  type: LOAD_SECTIONS_FAILED,
  payload,
})

export const loadRows = ({ standId, section, aisleIds }) => ({
  type: LOAD_ROWS_REQUESTED,
  payload: {
    standId,
    section,
    aisleIds,
  }
})

export const loadRowsSucceeded = (payload) => ({
  type: LOAD_ROWS_SUCCEEDED,
  payload,
})

export const loadRowsFailed = (payload) => ({
  type: LOAD_ROWS_FAILED,
  payload,
})

export const loadSeats = ({ standId, section, row, aisleIds }) => ({
  type: LOAD_SEATS_REQUESTED,
  payload: {
    standId,
    section,
    row,
    aisleIds,
  }
})

export const loadSeatsSucceeded = (payload) => ({
  type: LOAD_SEATS_SUCCEEDED,
  payload,
})

export const loadSeatsFailed = (payload) => ({
  type: LOAD_SEATS_FAILED,
  payload,
})

export const loadMenusForSeat = ({ standId, orderLocationUuid }) => ({
  type: LOAD_MENUS_FOR_SEAT_REQUESTED,
  payload: {
    standId,
    orderLocationUuid,
  }
})

export const loadMenusForSeatSucceeded = (payload) => ({
  type: LOAD_MENUS_FOR_SEAT_SUCCEEDED,
  payload,
})

export const loadMenusForSeatFailed = (payload) => ({
  type: LOAD_MENUS_FOR_SEAT_FAILED,
  payload,
})
