import React, { Fragment, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  iconButton: {
    padding: 10,
  },
  inputLabelTheme: theme.typography.h4,
}));

/**
 *
 * A custom rounded text input
 *
 * @props
 *
 * Optional
 * - default "" - what is the initial name we will populate in the select
 * - label String - The label name you wish to display
 * - labelWidth int - The width of this label, it is NOT calculated dynamically
 * - endAdornment <ICON> - An end adornment to display
 * - disabled Boolean - whether interaction is disabled or not
 *
 */
export const VNTextInput = forwardRef((props, ref) => {

  const classes = useStyles()

  const [error, setError] = useState(false)

  // the state text input that has been entered
  const [textValue, setTextValue] = useState(props.default ? props.default : '')

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    getInput() {
      return textValue
    },
    clearInput() {
      setTextValue('')
    },
    validate() {
      if (props.validation) {
        if (!props.validation(textValue)) {
          setError(true)
          return false
        } else {
          setError(false)
          return true
        }
      }
      return true
    }

  }))

  useEffect(() => {
    if (props.default) {
      setTextValue(props.default)
    }
  }, [props.default])

  // when the text input has changed
  const handleChange = (event) => {
    setTextValue(event.target.value)
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  }

  // display the end adornment or not
  const displayEndAdornment = () => {
    if (props.endAdornment) {
      return (
        <IconButton type="submit" className={classes.iconButton}>
          {props.endAdornment}
        </IconButton>
      )
    }
  }

  return (
    <Fragment>
      <FormControl fullWidth variant="outlined" required={props.required ? props.required : false} error={error}>
        <InputLabel className={clsx(classes.inputLabel, classes.inputLabelTheme)} htmlFor="outlined-adornment-amount">{props.label}</InputLabel>
        <OutlinedInput
          disabled={props.disabled ? props.disabled : false}
          type={props.type ? props.type : 'text'}
          className={classes.root}
          classes={{notchedOutline: classes.notched, input: clsx(classes.input, classes.inputLabelTheme)}}
          value={textValue}
          onChange={handleChange}
          onClick={props.onClick}
          endAdornment={displayEndAdornment()}
          labelWidth={props.labelWidth}
          autoComplete={props.autoComplete}
        />
      </FormControl>
    </Fragment>
  )
})
