export const mockApiGetLoyaltyUser = () => {
  return new Promise((resolve, reject) => {
    resolve({ data: {
      "user_uuid": "7b892cb1-1035-468c-9f8a-797b12be2560",
      "email": "eddie@venuenext.com",
      "name": "Mr. Herdemian",
      "external_user_id": "12587000",
      "balance": 50
    }})
    // reject({error: 'error'})
  })
}

export const mockApiGetExchangeServiceBalance = () => {
  return new Promise((resolve, reject) => {
    resolve({ data: {
      "balance": 50
    }})
  })
}

export const mockApiGetExchangeServiceGroupData = () => {
  return new Promise((resolve, reject) => {
    resolve({ data: {
      "groups": [
        {
          "id": 10160,
          "refKey": "10160",
          "groupName": "Preseason White Sox at Zephyrs",
          "groupSubtitle": "Sat Mar 25, 2023",
          "accounts": [
            {
              "balance": 500,
              "account": "8N82-2TS7796K",
              "name": "Sec View Box VB313, Row C, Seat 7",
              "loaded": false
            },
            {
              "balance": 1000,
              "account": "8N82-GHYXABEJ",
              "name": "Sec Club Level 229, Row D, Seat 7",
              "loaded": false
            }
          ]
        }
      ]
    }})
  })
}
export const mockApiFetchVirtualCurrencyActivity = () => {
  return new Promise((resolve, reject) => {
    resolve({ data: 
      [{
        "user_uuid": "6285bc70-0547-4bc7-bbd9-394cce5c8e80",
        "account_id": "12587000",
        "sent_at": "2020-07-29T19:53:37Z",
        "amount": 100,
        "balance": 100,
        "comment": "",
        "created_at": "2020-07-29T19:53:37.405Z",
        "updated_at": "2020-07-29T19:53:37.405Z",
        "sent_status": null,
        "transaction_uuid": "642d9430-ed89-4b6a-b71e-a0cb2dd7b551",
        "type": "admin_credit_transaction",
        "hidden": false,
        "display_title": "vnbucks Credit"
      },
      {
        "user_uuid": "6285bc70-0547-4bc7-bbd9-394cce5c8e80",
        "account_id": "12587000",
        "sent_at": "2019-07-29T19:53:37Z",
        "amount": 100,
        "balance": 100,
        "comment": "",
        "created_at": "2019-07-29T19:53:37.405Z",
        "updated_at": "2019-07-29T19:53:37.405Z",
        "sent_status": null,
        "transaction_uuid": "642d9430-ed89-4b6a-b71e-a0cb2dd7b551",
        "type": "admin_credit_transaction",
        "hidden": false,
        "display_title": "Court Cash Credit"
      }]
    })
  })
}

export const mockApiPostVirtualCurrencyTransfer = () => {
  return new Promise((resolve, reject) => {
    resolve({ data:{
        "known_recipient_account": false,
        "state": "active",
        "sending_user": {
          "id": 8919,
          "uuid": "7b892cb1-1035-468c-9f8a-797b12be2560",
          "external_user_id": "12587000",
          "created_at": "2020-07-30T17:34:59.607Z",
          "updated_at": "2020-07-30T17:34:59.607Z",
          "deactivated_at": null,
          "email": "eddie@venuenext.com",
          "organization_name": "cubs",
          "profile_image_url": null,
          "sent_at": null,
          "sent_status": null,
          "first_name": "Mr.",
          "last_name": "Herdemian",
          "old_external_user_id": null,
          "transactions_synced_at": null
        },
        "transfer": {
          "recipient_user_uuid": null,
          "sender_email": "eddie@venuenext.com",
          "sender_user_uuid": "7b892cb1-1035-468c-9f8a-797b12be2560",
          "uuid": "6720ae2b-e57d-47b8-b04b-6ba111657d7f",
          "amount": 50,
          "recipient_email": "eherdemian@gmail.com",
          "sender_transaction_uuid": "244d6a09-e931-42b0-8c51-d8cfbde43e25",
          "comment": "Enjoy the game",
          "state": "active",
          "created_at": "2020-07-30T17:39:14.701Z",
          "updated_at": "2020-07-30T17:39:14.701Z",
          "accepted_at": null,
          "accepted_transaction_uuid": null,
          "canceled_at": null,
          "canceled_transaction_uuid": null
        },
        "sender_transaction": {
          "user_uuid": "7b892cb1-1035-468c-9f8a-797b12be2560",
          "account_id": "12587000",
          "sent_at": "2020-07-30T17:39:14Z",
          "amount": -50,
          "balance": 50,
          "comment": "Enjoy the game ",
          "created_at": "2020-07-30T17:39:14.669Z",
          "updated_at": "2020-07-30T17:39:14.669Z",
          "sent_status": null,
          "transaction_uuid": "244d6a09-e931-42b0-8c51-d8cfbde43e25",
          "type": "sent_transfer_transaction",
          "hidden": false,
          "display_title": "Transfer Sent"
        }
      }
    })
  })
}

export const mockApiFetchAwardsRules = () => {
  return new Promise((resolve, reject) => {
    resolve({ data: 
      [{
        "type": "early_ticket_scan",
        "uuid": "d22038f7-a470-4a28-86b1-7ac00a6d5018",
        "local_start_time": "2020-07-30T01:00:00.000-04:00",
        "local_end_time": "2020-08-29T01:00:00.000-04:00",
        "local_time_zone": "America/New_York",
        "enabled": true,
        "name": "Early Arrival",
        "amount": 500,
        "organization_name": "cubs",
        "description": "Arrive early to earn",
        "image_url": "https://ui-config-layout.s3-us-west-1.amazonaws.com/cubs/dev/loyalty_awards//Screen%20Shot%202020-07-30%20at%201.28.52%20PM.png",
        "push_notification_text": null,
        "push_notification_action_name": null,
        "enable_push_notifications": false,
        "extra_options": {
          "season_ticket_holder_bonus": 0,
          "offset_min": 60,
          "offset_max": 150
        },
        "start_time": "2020-07-30T05:00:00.000Z",
        "end_time": "2020-08-29T05:00:00.000Z"
      }, {
        "type": "purchase_cash_back",
        "uuid": "0efd8670-1b3f-4dc8-b875-dea45a835c4d",
        "local_start_time": "2020-07-30T01:00:00.000-04:00",
        "local_end_time": "2020-08-15T01:00:00.000-04:00",
        "local_time_zone": "America/New_York",
        "enabled": true,
        "name": "Cashback",
        "amount": 500,
        "organization_name": "cubs",
        "description": "Spend $50 and earn 5 Cubbies Back",
        "image_url": null,
        "push_notification_text": null,
        "push_notification_action_name": null,
        "enable_push_notifications": false,
        "extra_options": {
          "award_threshold": 5000
        },
        "start_time": "2020-07-30T05:00:00.000Z",
        "end_time": "2020-08-15T05:00:00.000Z",
        "amount_for_next_award": 2625
      }]
    })
  })
}

export const mockGetDiscountChargeNonces = () => {
  return new Promise((resolve) => {
    resolve({ data: {
      "discount_charge_nonces": [{
        "nonce": "vrzbai63dpwd029h",
        "payment_types": [
          "vn_bank"]
      }, {
        "nonce": "6su1whfx44juz88k",
        "payment_types": [
          "credit_card"]
      }, {
        "nonce": "vw218zjx0jrs4nmg",
        "payment_types": [
          "apple_pay"]
      }, {
        "nonce": "chd02a13cp5mad4d",
        "payment_types": [
          "vn_bank",
          "credit_card"]
      }, {
        "nonce": "hhq2r15dz17k34s2",
        "payment_types": [
          "vn_bank",
          "apple_pay"]
      }, {
        "nonce": "qnvngd8lm9tefcg5",
        "payment_types": [
          "credit_card",
          "apple_pay"]
      }, {
        "nonce": "ya6niqrrhx9s085f",
        "payment_types": [
          "vn_bank",
          "credit_card",
          "apple_pay"]
      }]
    }})
  })
}

export const mockGetDiscountChargeNonceData = () => {
  return new Promise((resolve) => {
    resolve({
      "data": {
        "nonce": "7ecmg5bzgxrtcuxg",
        "user_uuid": "00u1afygiy5Srddp90h9",
        "organization_name": "arena",
        "instance": "arena",
        "charge_used_at": null,
        "expires_at": "2023-04-04T19:23:03.000Z",
        "scanned_at": null,
        "affiliations": [],
        "expired?": true,
        "used?": false,
        "scanned?": false
      }
    })
  })
}

export const mockApiPostCancelVirtualCurrencyTransfer = () => {
  return new Promise((resolve) => {
    resolve({ data: {
      "state": "canceled",
      "transfer": {
        "recipient_user_uuid": null,
        "sender_email": "dannyskim@gmail.com",
        "sender_user_uuid": "a2c56c86-81eb-4338-8197-7df6978d2bd9",
        "uuid": "5256f95a-b0f1-415e-aaae-e3be7f1ddd3f",
        "amount": 100,
        "recipient_email": "daniel.kim@baygullstudios.com",
        "sender_transaction_uuid": "a514f0d3-c041-4d34-b0db-3b65109aea5f",
        "comment": "test",
        "state": "canceled",
        "created_at": "2020-08-21T18:07:38.674Z",
        "updated_at": "2020-08-21T18:10:37.407Z",
        "accepted_at": null,
        "accepted_transaction_uuid": null,
        "canceled_at": "2020-08-21T18:10:37.405Z",
        "canceled_transaction_uuid": "443f41c5-acb3-4901-a400-7a747ca5018b"
      },
      "canceled_transaction": {
        "user_uuid": "a2c56c86-81eb-4338-8197-7df6978d2bd9",
        "account_id": "a2c56c86-81eb-4338-8197-7df6978d2bd9",
        "sent_at": "2020-08-21T18:10:37Z",
        "amount": 100,
        "balance": 1000,
        "comment": "test",
        "created_at": "2020-08-21T18:10:37.401Z",
        "updated_at": "2020-08-21T18:10:37.401Z",
        "sent_status": null,
        "transaction_uuid": "443f41c5-acb3-4901-a400-7a747ca5018b",
        "type": "canceled_transfer_transaction",
        "hidden": false,
        "display_title": "Transfer Canceled"
      }
    }})
  })
}

