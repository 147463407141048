export const REDEEM_USER_ITEM_REQUESTED = 'REDEEM_USER_ITEM_REQUESTED'
export const REDEEM_USER_ITEM_SUCCEEDED = 'REDEEM_USER_ITEM_SUCCEEDED'
export const REDEEM_USER_ITEM_FAILED = 'REDEEM_USER_ITEM_FAILED'

export const RETRIEVE_USER_ITEM_REQUESTED = 'RETRIEVE_USER_ITEM_REQUESTED'
export const RETRIEVE_USER_ITEM_SUCCEEDED = 'RETRIEVE_USER_ITEM_SUCCEEDED'
export const RETRIEVE_USER_ITEM_FAILED = 'RETRIEVE_USER_ITEM_FAILED'

export function redeemUserItem(itemId, orderId) {
  return {
    type: REDEEM_USER_ITEM_REQUESTED,
    itemId,
    orderId,
  }
}

export function redeemUserItemSucceeded(payload, id, orderId) {
  return {
    type: REDEEM_USER_ITEM_SUCCEEDED,
    payload,
    id,
    orderId,
  }
}

export function redeemUserItemFailed(payload, id) {
  return {
    type: REDEEM_USER_ITEM_FAILED,
    payload,
    id,
  }
}

export function retrieveUserItem(id) {
  console.log(1)
  return {
    type: RETRIEVE_USER_ITEM_REQUESTED,
    id
  }
}

export function retrieveUserItemSucceeded(payload, id) {
  return {
    type: RETRIEVE_USER_ITEM_SUCCEEDED,
    payload,
    id
  }
}

export function retrieveUserItemFailed(payload, id) {
  return {
    type: RETRIEVE_USER_ITEM_FAILED,
    payload,
    id
  }
}

