import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import { getBackdropSystemEnabled } from '../Selectors'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.custom.zIndex.VNBackdropSystem,
    color: theme.custom.colors.white
  },
}));

export const VNBackdropSystem = (props) => {

  const classes = useStyles();

  const enabled = useSelector(state => getBackdropSystemEnabled(state))

  return (
    <Backdrop className={classes.backdrop} open={enabled}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
