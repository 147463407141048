
export const getBadgeDialogOpen = (state) => {
  return state.VNDialogs.get('openBadgeDialog')
}

export const getExchangeServiceDialogOpen = (state) => {
  return state.VNDialogs.get('openExchangeServiceDialog')
}

export const getPaymentUpdatesDialogOpen = (state) => {
  return state.VNDialogs.get('openPaymentUpdatesDialog')
}

export const getGiftCardTransferDialogOpen = (state) => {
  return state.VNDialogs.get('openGiftCardTransferDialog')
}