import * as ActionTypes from './ActionTypes'
import * as UserActionTypes from '../VNUser/ActionTypes'
import { getUser } from '../VNUser/Selectors'
import {
  apiGetLoyaltyUser,
  apiFetchAwardsRules,
  apiFetchVirtualCurrencyActivity,
  apiFetchDiscountChargeNonces,
  apiTransferGiftCardBalanceToUser,
  apiPostExchangeServiceTransfer,
  apiPostExchangeServiceTransferMultiple
} from './Api'
import { apiGetUserAffiliations, apiGetUserPaymentMethods } from '../VNUser/Api'
import { enableBackdropSystem, disableBackdropSystem } from '../VNBackdropSystem/ActionCreators'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'
import { setApiLoadingStatus }from '../VNApiLoadingSystem/ActionCreators'
import { getPaymentsProvider, getExchangeServiceBalanceFailureText } from '../VNConfigurations/Selectors'
import { apiPreferredCardHolderAward } from '../VNWallet/Api'
import { setOpenExchangeServiceDialog, setOpenGiftCardTransferDialog } from '../VNDialogs/ActionCreators'
import { setSnackbarSystemDataAlertError, setSnackbarSystemDataAlertInfo } from '../VNSnackbarSystem/ActionCreators'
import { getVirtualCurrencyEnabled } from '../VNConfigurations/Selectors'

// Go fetch a user from the Loyalty Service
export function retrieveLoyaltyUser() {
  return (dispatch, getState) => {
    const state = getState()
    const user = getUser(state)
    const jwt = user.get('jwt')
    const useVirtualCurrencyFirst = user.get('useVirtualCurrencyFirst')
    const useVirtualCurrencyFirstNotSet = typeof useVirtualCurrencyFirst !== 'boolean'
    const isVirtualCurrencyEnabled = getVirtualCurrencyEnabled(state)

    dispatch(setApiLoadingStatus(
      retrieveLoyaltyUser.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiGetLoyaltyUser(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWALLET_LOYALTY_USER,
        loyalty_user: response.data
      })

      // automatically turn on VC toggle for already authenticated user if
      // 1. user's virtual currency balance is greater than zero
      // 2. virtual currency is enabled for this ordernext instance
      // 3. user has never touched VC toggle.
      //    * If VC toggle is already on, we can skip.
      //    * If VC toggle is off, we respect it and can skip here.
      if (response?.data?.balance && response.data.balance > 0 && isVirtualCurrencyEnabled && useVirtualCurrencyFirstNotSet) {
        dispatch({
          type: UserActionTypes.VNUSER_SET_USER,
          user: user.merge({ useVirtualCurrencyFirst: true }).toJSON()
        })
      }

      dispatch(setApiLoadingStatus(
        retrieveLoyaltyUser.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      dispatch({
        type: ActionTypes.VNWALLET_LOYALTY_USER,
        loyalty_user: {}
      })

      dispatch(setApiLoadingStatus(
        retrieveLoyaltyUser.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}

/**
 * confirm money transfer.
 * @param {string} provider the provider i.e. ticketsdotcom
 * @param {string} exchangeServiceJwt jwt obtained from balance call
 * @param {string} account the account needed i.e. ticket number
 * @param {string} venueID the venueId being used specefic to the org
 * @param {Integer} amount the amount thats going to be transferred
 * @param {Obj} t - translation obj
 */
export function exchangeServiceTransfer(provider, exchangeServiceJwt, account, venueID, amount, t) {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    const failureMessage = getExchangeServiceBalanceFailureText(getState(), provider)

    dispatch(setApiLoadingStatus(
      exchangeServiceTransfer.name,
      { status: ApiLoadingStatus.LOADING }
    ))
    
    dispatch(enableBackdropSystem())
    
    apiPostExchangeServiceTransfer(jwt, exchangeServiceJwt, provider, account, venueID, amount).then(response => {
      dispatch(retrieveLoyaltyUser())
      dispatch(disableBackdropSystem())
      dispatch(setOpenExchangeServiceDialog({open: false, provider: undefined}))
      dispatch(setSnackbarSystemDataAlertInfo(t('EXCHANGE_SERVICES_TRANSFER_SUCCESS')))
      dispatch(setApiLoadingStatus(
        exchangeServiceTransfer.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      dispatch(disableBackdropSystem())
      let errMsg = failureMessage
      if (typeof error.response?.data?.error === 'string') {
        errMsg = error.response.data.error
      } else if (typeof error.response?.data?.error?.error_message === 'string') {
        errMsg = error.response.data.error.error_message
      }
      dispatch(setSnackbarSystemDataAlertError(errMsg))
      dispatch(setApiLoadingStatus(
        exchangeServiceTransfer.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}

/**
 * confirm money transfer.
 * @param {string} provider the provider i.e. ticketsdotcom
 * @param {string} assistedLoadJwt jwt sent from groups
 * @param {array} accounts - the accounts due for transfer 
 * @param {string} language
 * @param {Obj} t - translation obj
 * @param {string} errorMessage - error message to use in the snackbar
 */
export function exchangeServiceMultipleTransfer(provider, assistedLoadJwt, accounts, language, t, errorMessage) {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    dispatch(setApiLoadingStatus(
      exchangeServiceTransfer.name,
      { status: ApiLoadingStatus.LOADING }
    ))
    
    dispatch(enableBackdropSystem())
    
    apiPostExchangeServiceTransferMultiple(jwt, provider, assistedLoadJwt, accounts, language).then(response => {
      dispatch(retrieveLoyaltyUser())
      dispatch(disableBackdropSystem())
      dispatch(setOpenExchangeServiceDialog({open: false, provider: undefined}))
      dispatch(setSnackbarSystemDataAlertInfo(t('EXCHANGE_SERVICES_TRANSFER_SUCCESS')))
      dispatch(setApiLoadingStatus(
        exchangeServiceTransfer.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      dispatch(disableBackdropSystem())
      let errMsg = errorMessage
      if (typeof error.response?.data?.error === 'string') {
        errMsg = error.response.data.error
      } else if (typeof error.response?.data?.error?.error_message === 'string') {
        errMsg = error.response.data.error.error_message
      }
      dispatch(setSnackbarSystemDataAlertError(errMsg))
      dispatch(setApiLoadingStatus(
        exchangeServiceTransfer.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}


/**
 * Fetch Award Rules from the Loyalty Service
 */
export function fetchAwardsRules() {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiFetchAwardsRules(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWALLET_AWARDS_RULES,
        awards_rules: response.data
      })
    }).catch(error => {
      dispatch({
        type: ActionTypes.VNWALLET_AWARDS_RULES,
        awards_rules: []
      })
    })
  }
}

/**
 * Call to API to fetch virtual currency activity from Loyalty Service
 */
export function fetchVirtualCurrencyActivity() {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    dispatch(setApiLoadingStatus(
      fetchVirtualCurrencyActivity.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiFetchVirtualCurrencyActivity(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWALLET_VIRTUAL_CURRENCY_ACTIVITY,
        virtual_currency_activity: response.data
      })
      dispatch(setApiLoadingStatus(
        fetchVirtualCurrencyActivity.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      dispatch({
        type: ActionTypes.VNWALLET_VIRTUAL_CURRENCY_ACTIVITY,
        virtual_currency_activity: []
      })
      dispatch(setApiLoadingStatus(
        fetchVirtualCurrencyActivity.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: error.message
        }
      ))
    })
  }
}

/**
 * Call to clear virtual currency activity
 */
export function clearVirtualCurrencyActivity() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_VIRTUAL_CURRENCY_ACTIVITY,
      virtual_currency_activity: []
    })
  }
}

/**
 * Get the nonces for QR Code based scanning
 * @param {Boolean} useBackdropSystem - Whether backdrop system should be used or not
 */
export function fetchDiscountChargeNonces(useBackdropSystem) {
  // TODO: May need to change func parameters to accept
  // necessary parameters to send to POST call header
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    if (useBackdropSystem) {
      dispatch(enableBackdropSystem())
    }

    dispatch(setApiLoadingStatus(
      fetchDiscountChargeNonces.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiFetchDiscountChargeNonces(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWALLET_GET_QR_NONCES,
        nonces: response.data.discount_charge_nonces
      })

      dispatch(setApiLoadingStatus(
        fetchDiscountChargeNonces.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))

      if (useBackdropSystem) {
        dispatch(disableBackdropSystem())
      }
    }).catch(error => {
      if (error && error.response) {
        console.log('Error: ' + error.response)
      }

      dispatch(setApiLoadingStatus(
        fetchDiscountChargeNonces.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: error?.response?.data
        }
      ))

      if (useBackdropSystem) {
        dispatch(disableBackdropSystem())
      }
    })
  }
}

/**
 * Fetch loyalty user, user affiliations, awards rules,
 * and user payment methods all in one call to have blanket
 * loading status for wallet
 */
export function retrieveAllWallet() {
  return async (dispatch, getState) => {
    const state = getState()
    const user = getUser(state)
    const jwt = user.get('jwt')
    const provider = user.get('provider')
    const paymentsProvider = getPaymentsProvider(state)

    dispatch(enableBackdropSystem())

    dispatch(setApiLoadingStatus(
      retrieveAllWallet.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    try {
      // separate api calls into individual try/catch block
      // since all api calls should be run even previous ones failed
      try {
        const loyalty = await apiGetLoyaltyUser(jwt)

        dispatch({
          type: ActionTypes.VNWALLET_LOYALTY_USER,
          loyalty_user: loyalty.data
        })
      } catch (e) {
        console.log('apiGetLoyaltyUser error', e)
      }

      try {
        const affiliations = await apiGetUserAffiliations(jwt, provider)

        dispatch({
          type: UserActionTypes.VNUSER_SET_USER_DETAILED_AFFILIATIONS,
          affiliations: affiliations
        })
      } catch (e) {
        console.log('apiGetUserAffiliations error', e)
      }

      try {
        const awards = await apiFetchAwardsRules(jwt)

        dispatch({
          type: ActionTypes.VNWALLET_AWARDS_RULES,
          awards_rules: awards.data
        })
      } catch (e) {
        console.log('apiFetchAwardsRules error', e)
      }

      // This call returns a 404 if no payment methods are found
      // which is techncally okay in this case to proceed

      // we only care about this old payment methods for braintree, not Shift4
      if (paymentsProvider === 'braintree') {
        const payments = await apiGetUserPaymentMethods(user.get('userID'), jwt).catch(
          e => {
            if (e.response.data.error_message && e.response.status === 404) {
              console.log(e.response.data.error_message)
            }
          }
        )

        if (payments) {
          dispatch({
            type: UserActionTypes.VNUSER_SET_USER_PAYMENT_METHODS,
            payment_methods: payments.data
          })
        }
      }

      dispatch(setApiLoadingStatus(
        retrieveAllWallet.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
      dispatch(disableBackdropSystem())
    } catch (error) {
      dispatch(disableBackdropSystem())
      dispatch(setApiLoadingStatus(
        retrieveAllWallet.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: error.message
        }
      ))
      console.log(error)
    }
  }
}

/**
 * Set the jwt used for api requests in exchange service
 * @param {Object} dialogOptions - True - opens it, False, closes it
 */
export function setExchangeServiceJwt(jwt) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EXCHANGE_SERVICE_SET_JWT,
      exchangeServiceJwt: {jwt: jwt},
    })
  }
}

/**
 * Clear order total from wallet
 */
export function clearOrderTotal() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_GET_ORDER_TOTAL,
      order: {}
    })
  }
}

/**
 * Set the virtual currency transfer email captured by URI Query Param
 * named transferEmail. This email parameter is currently only set from a
 * Virtual Currency transfer email sent to the transfer recipient, which directly
 * navigates the user to the VNUserSignUp and gets reset upon detecting that the
 * transfer shows up in the VNVirtualCurrencyActivity page.
 * @param {string} email
 */
export function setVirtualCurrencyTransferEmailAccept(email) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_SET_VIRTUAL_CURRENCY_TRANSFER_EMAIL_ACCEPT,
      email: email
    })
  }
}

/**
 * Save virtual currency promotions into Redux state
 * @param {array} vcPromotions - array of virtual currency promotions
 * @returns 
 */
export function updateVirtualCurrencyPromotions(vcPromotions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_UPDATE_VIRTUAL_CURRENCY_PROMOTIONS,
      vcPromotions,
    })
  }
}

/**
 * TODO: replace this with webhook
 * Send data to loyalty server for preferred card holder award
 * @param {string} bin - The bin number from payload object from S4Payments SDK on the VAULT_CARD_VAULTED event
 */
export function preferredCardHolderAward(bin) {
  return async (dispatch, getState) => {
    const user = getUser(getState())
    const token = user.get('jwt')
    const userId = user.get('userID')
    const org = user.get('org')

    if (token && userId && org && bin) {
      // 1. Do nothing if it fails
      // 2. We don't need the response for now
      await apiPreferredCardHolderAward(token, userId, org, bin)
    }
  }
}

/**
 * Transfer money from a gift card to the active virtual currency
 * @param {String} provider 
 * @param {String} cardNumber 
 * @param {String} language - The default needs to be 'en' - this is a 2 letter language
 * @param {String} pin - The gift card pin number
 * @param {Int} amount - How much do you want to transfer
 * @param {Obj} t - translation obj
 * @returns 
 */
export function transferGiftCardBalanceToVirtualCurrency(provider, cardNumber, language, pin, amount, t) {
  return async (dispatch, getState) => {
    const user = getUser(getState())
    const userId = user.get('userID')
    
    dispatch(enableBackdropSystem())

    apiTransferGiftCardBalanceToUser(provider, cardNumber, language, pin, amount, userId).then(response => {
      dispatch(retrieveLoyaltyUser())
      dispatch(disableBackdropSystem())
      dispatch(setOpenGiftCardTransferDialog(false))
      dispatch(setSnackbarSystemDataAlertInfo(t('GIFT_CARD_TRANSFER_COMPLETE')))
    }).catch(error => {
      dispatch(disableBackdropSystem())
      dispatch(setOpenGiftCardTransferDialog(false))
      if (error && error.response) {
        console.log('Error: ' + error.response)
        dispatch(setSnackbarSystemDataAlertError(error.response))
      }
    })
  }
}

/**
 * Set QR code generated time into Redux state
 */
export const setLastTimeQRCodeGenerated = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_SET_LAST_TIME_QR_CODE_GENERATED
    })
  }
}
