var ESData = {
  translation: {
    CURRENCY: "EUR",
    CURRENCY_SYMBOL: "€",
    CUSTOM_TIP_AMOUNT: "Introduzca la propina deseada.",
    OK: "OK",
    AMOUNT: "Cantidad",
    creditCard: {
      number: {
        displayText: "Número de Tarjeta",
      },
      expirationDate: {
        displayText: "Fecha de Caducidad",
      },
      cvv: {
        displayText: "Numero de Seguridad",
      },
      postalCode: {
        displayText: "Código Postal",
      },
    },
    LOG_IN: 'Acceder',
    REPORT_AN_ISSUE: 'Reportar un Problema',
    TERMS_OF_SERVICE: 'Términos de Servicio',
    PRIVACY_POLICY: 'Política de Privacidad',
    HOME: 'Inicio',
    MY_ACCOUNT: 'Mi cuenta',
    ORDERS: 'Pedidos',
    SIGN_OUT: 'Cerrar Sesión',
    PASSES: 'Entradas',
    PAYMENTS: 'Pagos',
    MULTIPLIER: 'multiplicador',
    LOADING_PASS: 'Cargando entrada',
    ORDER_NEXT: 'OrderNext',
    APPLE_PAY_FAILED_TO_LOAD: 'Apple Pay no pudo cargarse. Intente usar una tarjeta de crédito.',
    COULD_NOT_COMPLETE_PURCHASE_CARD_ON_FILE: "No pudimos completar su compra con la tarjeta registrada en su cuenta de Apple Pay. Intente usar una tarjeta de crédito.",
    IS_REQUIRED: 'es obligatorio',
    INVALID_VALUE_FOR: 'Valor no válido para',
    REQUIRED: 'Obligatorio',
    PAYMENT_PROCESSOR_CONNECTION_ERROR: "Tenemos problemas para conectarnos a nuestro procesador de pagos.",
    RECIPIENT_NAME: 'Nombre del destinatario',
    NAME_REQUIRED: 'Se requiere el nombre',
    PLACE_ORDER: 'Realizar pedido',
    TIP: 'Propina',
    SERVICE_FEE: 'Tarifa de servicio',
    SERVICE_CHARGES: 'Cargos de servicio',
    DISCOUNT: 'Descuento',
    TAXES: 'Impuestos',
    TOTAL: 'Total',
    DISMISS: 'Descartar',
    ARE_YOU_SURE: 'Está seguro',
    VIEW_CART: 'ver carrito',
    EMAIL_RECEIPT: 'Recibo en su correo clectrónico',
    EMAIL_IS_REQUIRED: 'Email obligatorio',
    CREATING_YOUR_ORDER: 'creando su pedido',
    GETTING_ORDER_INFO: 'obteniendo la información de su pedido',
    CALCULATING_ORDER_TOTAL: 'calculando el total de su pedido',
    THERE_WAS_AN_ERROR: 'Hubo un error',
    GET_ORDER_ERROR: "Si actualizar esta página no ayuda, puede usar el número de pedido en su recibo enviado al correo electrónico para recoger su pedido.",
    GET_ORDER_TOTAL_ERROR: 'Esto puede ser temporal. Puede intentar actualizar la página antes de realizar su pedido.',
    BRAINTREE_LOAD_ERROR: "Tenemos problemas para conectarnos al procesador de pagos. Si el problema persiste después de actualizar la página, no podremos procesar su pedido. Hemos sido notificados de este problema y le pedimos disculpas por las molestias.",
    DATE: 'fecha',
    EVENTS_IN: 'eventos en',
    BACK: 'Atrás',
    BACK_LOWERCASE: 'atrás',
    SELECT_AN_EVENT: 'seleccione un evento',
    ALL_EVENTS: 'todos los eventos',
    REDEEM_SOON: 'CANJEAR PRONTO',
    REDEEM_NOW: 'CANJEAR AHORA',
    REDEEMED: 'CANJEADO',
    EXPIRED: 'CADUCADO',
    REDEEMING: 'CANJEANDO',
    INFO: 'información',
    EACH: 'unidad',
    ONLY: 'solamente',
    LEFT: '¡quedan',
    REMOVE: 'eliminar',
    REQUIRED_LOWERCASE: 'obligatorio',
    PICK_AT_LEAST: 'elegir al menos',
    PICK_UP_TO: 'recoger hasta',
    OPTIONAL: 'Opcional',
    QR_CODE: 'Código QR',
    DELIVERY_TO: 'Entregar a',
    PICKUP_AT: 'Recoger en',
    SECTION: 'Sección',
    ROW: 'Fila',
    SEAT: 'Asiento',
    SUITE: 'Palco',
    REMOVE_ALL_ITEMS: 'Esto eliminará todos los artículos de su pedido actual.',
    THIS_WILL_REMOVE: 'Esto eliminará',
    FROM_YOUR_CART: 'de su carrito',
    EVENT_DATE: 'Fecha del evento',
    EVENT: 'Evento',
    ITEM: 'Articulo',
    ITEMS: 'Artículos',
    CART: 'Carro',
    EXPERIENCE: 'experiencia',
    MENU: 'menú',
    CLEAR_CART: 'vaciar carrito',
    ADD_ITEMS: 'agregar artículos',
    NOT_NEEDED_FOR_SUITES: 'No es necesario para palcos',
    LOADING: 'cargando',
    SELECT_YOUR_SECTION_OR_SUITE: 'Seleccione su sección o palco',
    SELECT_YOUR_ROW: 'Seleccione su fila',
    SELECT_YOUR_SEAT: 'Seleccione su asiento',
    SECTION_LOWERCASE: 'sección',
    OR: 'o',
    SUITE_LOWERCASE: 'palco',
    CONFIRM_SEAT: 'Confirmar asiento',
    SAVE_SEAT: 'Guardar asiento',
    VALIDATING_SEAT: 'Validando asiento',
    YOUR_SEAT: 'Su asiento',
    SEAT_NOT_ELIGIBLE_FOR_DELIVERY_FROM_THIS_STAND: 'Asiento no elegible para entrega desde esta ubicación',
    MODIFY: 'Modificar',
    YOU_CAN_EDIT_THESE_UNTIL_YOU_REDEEM: 'Puede editarlos hasta que canjee',
    PLEASE_FILL_IN_THE_ABOVE: 'Por favor complete lo anterior.',
    PLEASE_ADD_YOUR_SEAT: 'Por favor agregue su asiento.',
    EDIT_THESE_AFTER_PLACING_ORDER: 'Puede editarlos después de realizar su pedido.',
    ENTER_YOUR: 'Introduzca su',
    DONE: 'Terminado',
    NEXT: 'Siguiente',
    WHEN_YOU_ARE_READY_TO_REDEEM: "Cuando esté listo para canjear, pulso el botón canjear ahora y luego presente el",
    CONFIRMATION_SCREEN: 'pantalla de confirmación',
    SECOND_CONFIRMATION_SCREEN: 'segunda confirmación',
    HOW_DO_I_REDEEM: '¿Cómo canjeo?',
    HOW_DO_I_REDEEM_CAPS: '¿Cómo canjeo?',
    EDIT: 'editar',
    YOU_CAN_ONLY_ORDER_FOR_ONE_EVENT: 'Solo puede reservar un evento a la vez',
    MAX_QUANTITY_REACHED: 'cantidad máxima alcanzada',
    ORDER_NOW: 'pedir ahora',
    EMAIL_MY_RECEIPT: 'Enviar mi recibo por correo electrónico',
    ORDER: 'Compra',
    YOU_ARE_ABOUT_TO_REDEEM: "Está a punto de canjear su experiencia y tendrá que presentar la confirmación. Esto no se puede deshacer, así que asegúrese de estar listo.",
    AT: 'a',
    ON: 'en',
    BETWEEN: 'entre',
    AND: 'y',
    WHAT_HAPPENS_NEXT: 'QUÉ SUCEDE DESPUÉS',
    KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS: 'Esté atento a más instrucciones sobre su compra.',
    THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM: 'Esta experiencia estará disponible para canjear',
    THIS_EXPERIENCE_EXPIRED_ON: 'Esta experiencia expiró el',
    THIS_EXPERIENCE_WAS_REDEEMED_ON: 'Esta experiencia fue canjeada el',
    ORDER_NUMBER: 'Número de pedido',
    ORDER_DETAILS: 'Detalles del pedido',
    PLACED_ON: 'Colocado en',
    RECEIPT: 'Recibo',
    SUBTOTAL: 'Total parcial',
    NOTHING_FOR_SALE: "No hay nada para esta fecha",
    PLEASE_TRY_ANOTHER_EVENT: 'Intente con otro evento o vuelva a consultar pronto para ver si hay algo disponible para este.',
    ONLY_CAPS_START: 'Solamente',
    OF_THIS_ITEM_PER_ORDER_ALLOWED: 'de este artículo permitido por pedido',
    SELECT_ONE: 'seleccione uno',
    CONFIRM: 'CONFIRMAR',
    ADD_TO_CART: 'añadir al carrito',
    MUST_BE_21: 'Debe ser mayor de 18 años con una identificación válida para pedir alcohol',
    LOCATION_NOT_CURRENTLY_ACCEPTING_ORDERS: 'Esta ubicación no acepta pedidos actualmente',
    SENDING: 'ENVIANDO',
    EMAIL_SENT: 'EMAIL ENVIADO',
    FAILED_TRY_AGAIN: 'FALLIDO: POR FAVOR INTÉNTELO OTRA VEZ',
    KIOSK_ORDER: 'Pedido de quiosco',
    SCAN_THIS_CODE: 'Escanee este código cuando recoja su pedido en',
    PRESENT_THIS_PAGE: 'Presente esta página cuando recoja su pedido en',
    PLEASE_PICKUP_AT: 'Please pickup at',
    SHOW_THIS_SCREEN_TO_RECEIVE_YOUR_ORDER: 'Muestra esta pantalla para recibir tu pedido.',
    HANG_TIGHT: "Espere - estamos revisando su pedido hasta que estemos seguros de que ha llegado a la cocina",
    ONCE_IT_DOES: "Una vez que lo haga, verá un código QR aquí que puede usar para recoger su pedido",
    WE_HOPE_YOU_ENJOYED_YOUR_ORDER: 'Esperamos que haya disfrutado su pedido, ¡Gracias de nuevo por probar OrderNext!',
    SEND: 'ENVIAR',
    MOBILE_ORDERING_MENUS: 'Menús para pedidos con el móvil',
    ALMOST_DONE: 'casi terminado',
    WE_NEED_SOME_MORE_INFO: 'necesitamos más información',
    ONE_LAST_THING: 'una última cosa',
    EXPERIENCES: 'Experiencias',
    FOOD_AND_DRINKS: 'Comida y bebidas',
    MERCHANDISE: 'Mercancías',
    OPEN_ORDER: 'Pedido abierto',
    PREPARING_YOUR_ORDER: 'Preparando su pedido',
    FREE: 'GRATIS',
    SIGNUP_IS_EASY: 'Sign up is easy!',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number',
    MESSAGE_RATES_MAY_APPLY: 'Message and data rates may apply',
    PLEASE_ENTER_THE_CODE: 'Please enter the code sent to ',
    CONFIRMATION_CODE: 'Confirmation Code',
    RESEND_CODE: 'RESEND CODE',
    ENTER_YOUR_PHONE_NUMBER: 'Enter your phone number',
    SIGN_UP: 'Sign Up',
    NO_PHONE_USER_EXISTS: 'This number does not exist.',
    INVALID_PHONE: 'Please enter a valid number.',
    INVALID_CODE: 'Please enter a valid code.',
    NETWORK_ERROR: 'VenueNext is experiencing an issue, please try again.',
    GENERIC_ERROR: 'Something went wrong, please go back and try again.',
    NEED_NEW_CODE: 'Code is no longer valid, please request a new one.',
    NEW_CODE_REQUESTED: 'A new code has been requested.',
    TEXT_ME_WHEN_READY: "Text me order status",
    TEXT_ORDER_CONFIRM: 'We will text you with order updates.',
    SMS_NOTIFICATIONS: 'Text me order updates:',
    MY_INFORMATION: 'My Information',
    USER_UPDATE_SUCCESS: 'User info saved.',
    ORDER_AGAIN: 'Order Again',
    ALL: 'All',
    PICKUP: 'Pickup',
    DELIVERY: 'Delivery',
    ENTER_PROMO_CODE: 'Enter a Promo Code',
    PROMO_CODE: 'Promo Code',
    APPLY: 'Apply',
    SMS_ORDER_NOTIFICATIONS: 'Thank you for submitting your phone number for updates!',
    SHOP: 'Shop',
    SCAN_AND_PAY: 'Scan & Pay',
    SEND_COURT_CASH: 'Send Cout Cash',
    COURT_CASH_ACTIVITY: 'Court Cash Activity',
    BENEFITS_AND_REWARDS: 'Benefits & Rewards',
    MY_INFO: 'My Info',
    MY_BADGE: 'My Badge',
    MY_WALLET: 'My Wallet',
    SAVE: 'Save',
    PLEASE_CORRECT: 'Please correct the form fields above.',
    CENTER_QR_CODE: 'Center the QR code or barcode to scan',
    PRESENT_QR_CODE: 'Present this QR code to pay with',
    PRESENT_QR_CODE_TO_APPLY_DISCOUNTS: 'Apply eligible discounts and pay with CASH_TYPE, where accepted',
    UPDATE: 'UPDATE',
    PRESENT_THIS_BADGE: 'Present this screen where accepted',
    ACTIVITY: 'Activity',
    SEND_TRANSFER: 'Send',
    TRANSFER: 'Transfer',
    NO_EXCHANGE_SERVICE: 'No tickets for the next $DAYS days with loaded value',
    AVAILABLE_LOAD: 'Tickets for upcoming event available to load on',
    RECIPIENT_EMAIL: 'Recipient Email',
    ENTER_AMOUNT: 'Enter Amount',
    ELIGIBLE_TRANSFER: 'eligible to be transferred. Expiring ',
    CANNOT_TRANSFER: 'may not be transferred.',
    SEND_NOW: 'Send Now',
    WAYS_TO_EARN: 'Ways to Earn',
    LEARN_MORE: 'LEARN MORE',
    SEND_A_NOTE: 'Send a Note',
    CLICK_TO_SCAN: 'Tap here to Scan',
    USE_MY: 'Use my',
    FOR_PURCHASES: 'for purchases',
    CREDIT_CARDS: 'Credit Cards',
    CREDIT_CARD: 'Credit Card',
    ADD_A_CARD: 'Add a Card',
    PAYMENT: 'Payment',
    REMAINING_BALANCE: 'The remaining balance of',
    MUST_BE_PAID_BY_CC: 'must be paid by credit card.',
    SORRY: "We're sorry",
    NOT_ENABLED_FOR_PRODUCT_TYPE: 'is not enabled for this purchase.',
    PROMO_CODE_REMOVED: 'Promo code removed',
    PROMO_CODE_ADDED: 'Promo code applied',
    CANCEL_TRANSFER: 'Cancel Transfer',
    ABOUT_TO_CANCEL: 'You are about to cancel a scheduled transfer to',
    YES: 'YES',
    NO: 'NO',
    TRANSFER_PROCESSING: 'Your transfer is processing.',
    PICKUP_YOUR_ORDER: 'Pickup your order at',
    SCAN: 'Scan',
    COMPLETED: 'Completed',
    OPEN_ORDERS: 'Open Orders',
    REDEEMABLE: 'Redeemable',
    LOGIN_TO_ACCEPT: 'Log in to accept your transfer',
    SIGNUP_TO_ACCEPT: 'Sign up to accept your transfer',
    TRANSFER_ACCEPTED: 'Transfer accepted!',
    SPEND: 'Spend',
    MORE_TO_EARN: 'more to earn',
    IN: 'in',
    LOCATION_EXCLUSIVITY: 'Location Exclusivity',
    CC_EXCLUSIVITY: 'Preferred Cardholder',
    MEMBER_EXCLUSIVITY: 'Member Exclusive',
    NO_OPEN_ORDERS: 'You currently have no open orders.',
    NO_COMPLETED_ORDERS: 'You currently have no completed orders.',
    NO_VC_ACTIVITY: 'You currently have no virtual currency activity',
    CLAIM_NOW: 'Claim Now',
    PLEASE_ADD_A_PAYMENT_METHOD: 'Please add a payment method',
    ADD_CREDIT_CARD: 'Add a credit card to use Scan & Pay',
    ADD_A_CREDIT_CARD: 'Add a Credit Card',
    ADD_CARD: 'ADD CARD',
    ENHANCE_EXPERIENCE: 'Enhance your experience',
    MAYBE_LATER: 'MAYBE LATER',
    CAMERA_PERMISSION_REQUIRED: 'Enable camera permissions in phone settings',
    TAX: 'Tax',
    DELIVERY_FEE: 'Delivery Fee',
    ORDER_NO_LONGER_EXISTS: 'That order no longer exists.',
    FOR: 'for',
    YOU_NEED_CC: 'You need to add a credit card to use',
    YOU_NEED_LOGIN: 'Please login to apply eligible discounts and pay with',
    THE_QR_CODE: 'the QR Code',
    QR_CODE_PAY: 'QR Code Pay',
    SELF_CHECKOUT: 'Self Checkout',
    ITEM_NO_CARRY: 'That item is not recognized',
    PLEASE_SHOW_THIS_SCREEN: 'Please show this screen at',
    TO_CONFIRM_YOUR_ORDER: 'to confirm your order.',
    SELECT_A_SHOP: 'Select a Shop',
    UNABLE_CAMERA: 'Unable to initiate the camera',
    MIN_WAIT: 'min wait',
    MINUTE_WAIT: 'minute wait',
    MIN: 'min',
    AFFILIATIONS_UPDATED: 'Affiliations Updated!',
    AWARDED_ON: 'Awarded on',
    NAV_MENU: 'Navigation Menu',
    PROFILE: 'Profile',
    SAVE_CARD: 'Save Card',
    SCROLL_DOWN_PAYMENT: 'Scroll down to enter payment details ',
    COMPLETE_YOUR_ORDER: 'and complete your order',
    SCAN_THIS_BARCODE: 'Scan this barcode to redeem.',
    SCAN_THIS_QRCODE: 'Scan this QR code to redeem.',
    COMPLETE_PAYMENT_BELOW: 'Complete your payment below ',
    AND_THEN_PICKUP: 'and then pickup your order at',
    EVENT_SELECTION: 'Event Selection',
    EVENTS: 'Events',
    MONTH_1: 'January',
    MONTH_2: 'February',
    MONTH_3: 'March',
    MONTH_4: 'April',
    MONTH_5: 'May',
    MONTH_6: 'June',
    MONTH_7: 'July',
    MONTH_8: 'August',
    MONTH_9: 'September',
    MONTH_10: 'October',
    MONTH_11: 'November',
    MONTH_12: 'December',
    FILTER: 'Filter',
    RESET: 'Reset',
    NO_WAIT_TIMES: 'No Wait Times',
    SERVICE_TYPES: 'Service Types',
    LOCATION: 'Location',
    CATEGORIES: 'Categories',
    DELIVERY_LOCATION: 'Delivery Location',
    CURRENTLY_NOT: 'Currently not accepting orders.',
    PLEASE_CHECK: 'Please check back later.',
    PLEASE_SCAN_AN_ITEM_IN: 'Please scan an item in ',
    SELECT_A_PICKUP_TIME: 'Select a pickup time',
    PLEASE_SELECT_A_PICKUP_TIME: 'Please select a pickup time',
    EXCHANGE_SERVICES_TRANSFER_DISCLAIMER: 'Ticket funds may only be loaded to one account. Do not load tickets that will be transferred',
    EXCHANGE_SERVICES_FUTURE_EVENTS_DISCLAIMER: 'Loaded ticket funds for future events will deposit on the date of the event',
    EXCHANGE_SERVICES_EXPERATION_WARNING: 'Any unused loaded ticket funds will expire after the event date',
    LOAD_GIFT_CARD: 'Load Gift Card',
    GIFT_CARD_NO_FUNDS: 'No funds available.',
    GIFT_CARD_TRANSFER_COMPLETE: 'Load Gift Card successful.',
    DISCLAIMER: 'Disclaimer',
    GIFT_CARD_BALANCE: 'Gift Card Balance',
    PIN: 'Pin',
    GIFT_CARD_NUMBER: 'Gift Card Number',
    GIFT_CARD_DISCLAIMER_DEFAULT: 'All funds will be withdrawn.',
    SOMETHING_WENT_WRONG: 'Oops something went wrong',
    RELOAD: 'RELOAD',
    FBMENUS: 'F&B Menus',
    SHOP_MERCH: 'Shop Merchandise',
    MARKETPLACE: 'Marketplace',
    MARKET_ITEMS: 'Market Items',
    MERCHANDISE_ITEMS: 'Merchandise Items',
    WALLET: 'Wallet',
    MY_ORDERS: 'My Orders',
    MY_PAYMENTS: 'My Payments',
    QR_PAY: 'QR Pay',
    BALANCE_HISTORY: 'Balance History',
    ITEMS_ARE_CURRENTLY_NOT_AVAILABLE: 'Items are currently not available',
    SEND_VIRTUAL_CURRENCY: 'Send Virtual Currency',
    SEARCH: 'Search',
    CANCEL: 'Cancel',
    TRY_AGAIN: 'TRY AGAIN',
    GO_BACK: 'GO BACK',
    QR_CODE_UNAVAILABLE: 'QR Code Unavailable',
    QR_CODE_LOAD_AGAIN: 'Your QR Code could not be loaded. Please try again.',
    UNKNOWN_ERROR: 'Unknown error',
    OOPS_NOT_RECOGNIZED: 'Oops, this item was not recognized',
    PURCHASE: 'Purchase',
    CREDIT: 'Credit',
    DEBIT: 'Debit',
    CANCEL_BEFORE: 'Cancel before',
    PREORDER: 'Preorder',
    CARD_ON_FILE_MAY_BE_REQUIRED: 'A card on file may be required at some locations',
    UPDATE_PAYMENT: 'UPDATE PAYMENT',
    AFFILIATIONS_NOT_REFRESHABLE: 'Sorry, profile information could not be updated.  Please try again in',
    AFFILIATIONS_PROFILE_NOT_REFRESHABLE: 'There was an error retrieving profile information.  Please try again in',
    MINUTES: 'minutes',
    PICK_UP_YOUR_ORDER_AT: 'Pick up your order at',
    REFRESH_BADGE_INFORMATION: 'Refresh Badge Information',
    LAST_UPDATED: 'Last updated',
    APPLYING_DISCOUNTS: 'Applying Eligible Discounts',
    SAVED_PAYMENTS: 'Saved Payments',
    SAVED_CARD: 'Saved Card',
    PAY_NOW: 'Pay Now',
    S4PAYMENTS_TRANSACTION_ERROR: 'Payment could not be completed, try again or try another card.',
    SINGLE_USE_QR_CODE: 'This single use QR code',
    LAST_GENERATED: 'Last generated',
    QR_CODE_UPDATED: 'QR code updated',
    FAILED_TO_UPDATE_QR_CODE: 'Sorry there was an issue updating the QR code. Please try again later',
    ACCOUNT_CANNOT_BE_EMPTY: 'Account cannot be empty',
    TICKETS_NOT_LOADED: "Some tickets could not be loaded. Please try again",
    SELECT_ONE_TICKET: "One ticket must be selected.",
    YOUR_NEXT_EVENT: "Your Next Event",
    LOADED: "LOADED AT",
    TODAY_AT: "Today At",
    SEARCH_TICKET_NUMBER: "Search by Ticket Number",
    ADMIN_USE: "For Admin Use Only",
    THERE_WAS_AN_ISSUE_SIGNING_IN: "There was an issue signing in. Please try again.",
    AUTHORIZATION_DECLINED: 'Authorization Declined',
    CARD_NOT_PRE_AUTHORIZED: 'Your card on file could not be pre-authorized.',
    MANAGE_CARDS: 'MANAGE CARDS',
    VIA: 'via'
  }
}

export default ESData
