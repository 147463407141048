import useResetCart from "../useResetCart"
import useRevenueCenters from "../useRevenueCenters"

const VNHooks = () => {
  useResetCart()
  useRevenueCenters()

  return null
}

export default VNHooks
