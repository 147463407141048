import {
  ADD_ITEM_TO_CART,
  EDIT_QUANTITY_IN_CART, SET_ACTIVE_MENU,
  CLEAR_CART, REMOVE_ITEM_FROM_CART, REMOVE_EXPERIENCE_ITEM_FROM_CART,
  CLEAR_ORDER_NOW, UPDATE_METADATA,
  UPDATE_TIP_AMOUNT,
  EDIT_VARIANT_QUANTITY_IN_CART,
  EDIT_EXPERIENCE_QUANTITY_IN_CART,
  CAN_ADD_TO_CART
} from '../actions/cart'
import compact from 'lodash/compact'
import fill from 'lodash/fill'
import get from 'lodash/get'
import omit from 'lodash/omit'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import cloneDeep from 'lodash/cloneDeep'

const defaultState = {}

const stringHashCode = str => {
  let hash = 0
  for (let i = 0; i < str.length; ++i)
    hash = Math.imul(31, hash) + str.charCodeAt(i)

  return hash | 0
}

/**
 * Creates a dictionary that holds modifier indices in state.cart.item.modifiers
 * ie
 * cart: {
 *  item: {
 *   4ed28b55-88fb-471f-afa1-d2387ea040d7: {
 *     ...,
 *     modifiers: [
 *       [{menuItemUuid: e6703d30-1040-4f50-a2e3-ad7efe350e1f, quantity: 1}]
 *     ]
 *   }
 * },
 * itemModifierRelationships: {
 *   4ed28b55-88fb-471f-afa1-d2387ea040d7.stringHashCode: [0]
 *  }
 * }
 * @param {object} newState - State to apply in reducer for this action
 * @returns
 */
const itemModifierTracker = (newState) => {
  var newItemModifierRelationships = {}

  // Build itemModifierTracker for UI so that we can leave state that is submitted
  // to stadium alone
  keys(newState.item).forEach(itemKey => {
    const mods = get(newState, `item.${itemKey}.modifiers`, [])
    const isExperience = get(newState, `item.${itemKey}.productType`) === 'Experience'
    const isMerchandise = get(newState, `item.${itemKey}.productType`) === 'Merchandise'
    const saleItemId = get(newState, `item.${itemKey}.sale.itemId`)
    const experienceQuantity = get(newState, `item.${itemKey}.quantity`)

    if (isExperience) {
      mods.forEach((modifierArray, i) => {
        const itemModifierRelationshipKey = `${itemKey}.${stringHashCode(JSON.stringify(modifierArray))}.${saleItemId}`
        newItemModifierRelationships[itemModifierRelationshipKey] = experienceQuantity
      })
    } else if (isMerchandise) {
      mods.forEach((modifierArray, i) => {
        // Merchandise will ony ever have a single choice variant, so we can use 0 index
        // to grab the menu item uuid
        let modifierKey = modifierArray[0] ? modifierArray[0].menuItemUuid : itemKey
        const itemModifierRelationshipKey = `${itemKey}.${modifierKey}`
        if (itemModifierRelationshipKey in newItemModifierRelationships) {
          newItemModifierRelationships[itemModifierRelationshipKey].push(i)
        } else {
          newItemModifierRelationships[itemModifierRelationshipKey] = [i]
        }
      })
    } else {
      mods.forEach((modifierArray, i) => {
        const itemModifierRelationshipKey = `${itemKey}.${stringHashCode(JSON.stringify(modifierArray))}`
        if (itemModifierRelationshipKey in newItemModifierRelationships) {
          newItemModifierRelationships[itemModifierRelationshipKey].push(i)
        } else {
          newItemModifierRelationships[itemModifierRelationshipKey] = [i]
        }
      })
    }
  })

  return newItemModifierRelationships
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_ITEM_TO_CART: {
      const { quantity, modifiers } = action.payload
      const currentItem = get(state, `item.${action.payload.id}`, {})
      const currentQuantity = get(currentItem, 'quantity', 0)
      const currentModifiers = get(currentItem, 'modifiers', [])
      const quantityToAdd = quantity - currentQuantity
      const mergedModifiers =  fill(Array(quantityToAdd), isEmpty(modifiers) ? [] : modifiers)
      const metadata = get(currentItem, 'metadata', {})

      let newState = {
        ...state,
        orderNow: action.payload.orderNow,
        item: {
          ...state.item,
          [action.payload.id]: {
            ...action.payload,
            modifiers: [...currentModifiers, ...mergedModifiers],
            metadata: { ...metadata },
            sale: action.payload.sale
          },
        }
      }

      newState.itemModifierRelationships = itemModifierTracker(newState)

      return newState
    }

    case EDIT_QUANTITY_IN_CART: {
      let newState = {...state}
      const currentModifiersKey = `item.${action.payload.id}.modifiers`
      let modifierIndices = action.payload.modifierIndices
      let modifiers = get(newState, `${currentModifiersKey}[${modifierIndices[0]}]`)
      let currentModifiers = get(newState, currentModifiersKey, [])
      let newModifiers = action.payload.modifiers
      const quantityKey = `item.${action.payload.id}.quantity`
      let quantity = get(newState, quantityKey)
      let newQuantity = action.payload.quantity

      // Remove all modifiers that match the item and set of modifiers
      currentModifiers.forEach((mod, index) => {
        if (JSON.stringify(mod) === JSON.stringify(modifiers)) {
          currentModifiers[index] = null
          --quantity
        }
      })

      currentModifiers = compact(currentModifiers)

      // Re-add modifiers back and increase quantity
      // TODO: This is a bit of a brute force methodology by removing and then re-adding,
      // consider refactoring this to something more elegant. Have spent too much time on this feature
      // so moving on for now.
      for(let i=1; i <= newQuantity; i++) {
        currentModifiers.push(newModifiers ? newModifiers : modifiers)
        quantity++
      }

      set(newState, currentModifiersKey, currentModifiers)
      set(newState, quantityKey, quantity)

      return {
        ...newState,
        item: {
          ...state.item,
          [action.payload.id]: {
            ...state.item[action.payload.id],
            quantity: quantity,
            modifiers: currentModifiers
          }
        },
        itemModifierRelationships: {
          ...itemModifierTracker(newState)
        }
      }
    }

    case EDIT_VARIANT_QUANTITY_IN_CART: {
      const { itemId, variantId, quantity } = action.payload

      const newState = cloneDeep(state)

      let itemToUpdate

      // find the item that needs to be updated
      for (const itemUuid of Object.keys(newState.item)) {
        if (itemUuid === itemId) {
          // if item.modifiers contains an object that
          // 1. menuItemUuid is equal to variantId
          // 2. isVariantChoice is true
          // then we know this is the item that we need to update
          const variantModifier = newState.item[itemUuid].modifiers.find(mod => mod?.[0]?.menuItemUuid === variantId && mod[0].isVariantChoice)

          if (variantModifier) {
            itemToUpdate = newState.item[itemUuid]
            break
          }
        }
      }

      // if we find the item to update, then we update it's quantity (a number) and modifiers (an array)
      // we also need to update itemModifierRelationships based on newState
      if (itemToUpdate) {
        itemToUpdate.quantity = quantity
        itemToUpdate.modifiers = Array(quantity).fill(itemToUpdate.modifiers[0])

        newState.itemModifierRelationships = { ...itemModifierTracker(newState) }
      }

      return newState
    }

    case EDIT_EXPERIENCE_QUANTITY_IN_CART: {
      const currentItem = get(state, `item.${action.payload.item.uuid}`)
      const item = action.payload.item

      const newState = {
        ...state,
        item: {
          ...state.item,
          [item.uuid] : {
            ...currentItem,
            quantity: action.payload.quantity
          }
        }
      }

      return {
        ...newState,
        itemModifierRelationships: {
          ...itemModifierTracker(newState)
        }
      }
    }
    case SET_ACTIVE_MENU:
      console.log('set active menu')
      return {
        ...state,
        ...action.payload,
      }

    case CLEAR_CART:
      return {
        menuId: state.menuId,
        standId: state.standId,
        orderNow: false,
      }

    case CLEAR_ORDER_NOW:
      return {
        ...state,
        orderNow: false,
      }

    case REMOVE_ITEM_FROM_CART: {
      const itemKey = `item.${action.payload.id}`
      const modifiersKey = itemKey + '.modifiers'
      const { modifiersIndex, variantId } = action.payload
      let newState = {...state}

      // no modifiers, not a variant
      if (modifiersIndex < 0 && !variantId) newState = omit(newState, [itemKey])

      if (Array.isArray(modifiersIndex)) {
        let omitArray = []
        modifiersIndex.forEach(modIndex => {
          omitArray.push(modifiersKey + `[${modIndex}]`)

          const quantityKey = itemKey + '.quantity'
          const newQuantity = get(newState, quantityKey) - 1
          set(newState, quantityKey, newQuantity)
        })


        newState = omit(newState, omitArray)
        const modifiers = compact(get(newState, modifiersKey))
        newState = set(newState, modifiersKey, modifiers)
      }

      /**
       * When removing an item with modifiers and their is only 1 of that item
       * in the cart, it shows as quantity `0` in the state. This is a fail safe
       * in the event an items quantity is ever `0` to just remove it.
       */
      if (get(newState, `${itemKey}.quantity`, 1) < 1) {
        newState = omit(newState, [itemKey])
      }

      return {
        ...newState,
        itemModifierRelationships: {
          ...itemModifierTracker(newState)
        }
      }
    }

    case REMOVE_EXPERIENCE_ITEM_FROM_CART: {
      let newState = {...state}
      delete newState.item[action.payload.id]
      return {
        ...newState,
        item: {
          ...newState.item
        },
        itemModifierRelationships: {
          ...itemModifierTracker(newState)
        }
      }
    }

    case UPDATE_METADATA:
      const itemId = action.payload.itemId
      const newMetadata = action.payload.metadata

      let newerState = { ...state }
      let item = newerState.item[itemId]

      item.metadata = { ...item.metadata, ...newMetadata }

      // omitting and re-setting to ensure the cart selector is refreshed afterwards
      newerState = omit(newerState, `item.${itemId}`)
      newerState = set(newerState, `item.${itemId}`, item)

      return {
        ...newerState,
      }

    case UPDATE_TIP_AMOUNT:
    case CAN_ADD_TO_CART:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default reducer
