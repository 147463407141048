export const VNWEBSDK_SET_SDKMODE = 'VNWEBSDK_SET_SDKMODE'
export const VNWEBSDK_SET_PLATFORM = 'VNWEBSDK_SET_PLATFORM'
export const VNWEBSDK_SET_VERSION = 'VNWEBSDK_SET_VERSION'
export const VNWEBSDK_SET_BUNDLE_ID = 'VNWEBSDK_SET_BUNDLE_ID'
export const VNWEBSDK_SET_DATA_RECIEVE = 'VNWEBSDK_SET_DATA_RECIEVE'
export const VNWEBSDK_SET_NAV_MODE = 'VNWEBSDK_SET_NAV_MODE'
export const VNWEBSDK_SET_INITIAL_PATHNAME = 'VNWEBSDK_SET_INITIAL_PATHNAME'
export const VNWEBSDK_SET_IS_EXTERNAL_PAYMENT_PROCESSOR = 'VNWEBSDK_SET_IS_EXTERNAL_PAYMENT_PROCESSOR'
export const VNWEBSDK_SET_PRODUCT_TYPE = 'VNWEBSDK_SET_PRODUCT_TYPE'
export const VNWEBSDK_SET_BG_COLOR = 'VNWEBSDK_SET_BG_COLOR'
export const VNWEBSDK_SET_ACCENT_COLOR = 'VNWEBSDK_SET_ACCENT_COLOR'
export const VNWEBSDK_SET_SECTIONROWSEAT = 'VNWEBSDK_SET_SECTIONROWSEAT'
