import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import Remote from '../../remote'
import { setLoading, setFailed, setSucceeded } from '../../actions/remote'
import { getExperienceOrder } from '../../actions/order'
import { retrieveUserItem } from '../../actions/experiences/userItem'
import { getUser } from '../../VNUser/Selectors'

import {
  REDEEM_USER_ITEM_REQUESTED,
  REDEEM_USER_ITEM_SUCCEEDED,
  redeemUserItemSucceeded,
  redeemUserItemFailed,
  retrieveUserItemSucceeded,
  retrieveUserItemFailed,
  RETRIEVE_USER_ITEM_REQUESTED
} from '../../actions/experiences/userItem'

export function* redeemUserItem(params) {
  const endpoint = Remote.endpoints.redeemUserItem
  yield put(setLoading(endpoint))
  const user = yield select(getUser);
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.redeemUserItem, params, token)

    yield all([
      put(redeemUserItemSucceeded(result, params.itemId, params.orderId)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(redeemUserItemFailed(err, params.itemId)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* refreshUserItem(params) {
  const endpoint = Remote.endpoints.refreshUserItem
  yield put(setLoading(endpoint))
  const user = yield select(getUser)
  const token = user.get('jwt')

  try {
    const result = yield call (Remote.refreshUserItem, params, token)

    yield all([
      put(retrieveUserItemSucceeded(result, params.id)),
      put(setSucceeded(endpoint))
    ])
  } catch (err) {
    yield all([
      put(retrieveUserItemFailed(err, params.id)),
      put(setFailed(endpoint, err))
    ])
  }
}

export function* watchRedeemUserItem() {
  yield takeLatest(REDEEM_USER_ITEM_REQUESTED, redeemUserItem)
}

export function* watchRetrieveUserItem() {
  yield takeLatest(RETRIEVE_USER_ITEM_REQUESTED, refreshUserItem)
}

export function* redeemUserItemAndGetOrder(params) {
  // If there is no orderId, that means this an awarded user item experience,
  // so the call is slightly different
  if (params.orderId) {
    yield put(getExperienceOrder(params.orderId))
  } else {
    yield put(retrieveUserItem(params.id))
  }
}

export function* watchRedeemUserItemAndGetOrder() {
  yield takeLatest(REDEEM_USER_ITEM_SUCCEEDED, redeemUserItemAndGetOrder)
}
