/**
 * Web SDK Native platform or web
 */
const WebSDKAnalyticsEvent = {
  TRACK_USER: 'TRACK_USER',
  TRACK_USER_AFFILIATIONS: 'TRACK_USER_AFFILIATIONS',
  TRACK_RVC_SELECTION: 'TRACK_RVC_SELECTION',
  TRACK_MENU_ITEM_SELECTION: 'TRACK_MENU_ITEM_SELECTION',
  BEGIN_CHECKOUT: 'BEGIN_CHECKOUT',
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  COMPLETED_PURCHASE: 'COMPLETED_PURCHASE',
  CHECKOUT_PROGRESS: 'CHECKOUT_PROGRESS',
  getEvent: function(event) {
    switch(event) {
      case 'TRACK_USER':
        return this.TRACK_USER
      case 'TRACK_USER_AFFILIATIONS':
        return this.TRACK_USER_AFFILIATIONS
      case 'TRACK_RVC_SELECTION':
        return this.TRACK_RVC_SELECTION
      case 'TRACK_MENU_ITEM_SELECTION':
        return this.TRACK_MENU_ITEM_SELECTION
      case 'BEGIN_CHECKOUT':
        return this.BEGIN_CHECKOUT
      case 'ADD_ITEM_TO_CART': 
        return this.ADD_ITEM_TO_CART
      case 'REMOVE_ITEM_FROM_CART':
        return this.REMOVE_ITEM_FROM_CART
      case 'COMPLETED_PURCHASE':
        return this.COMPLETED_PURCHASE
      case 'CHECKOUT_PROGRES':
        return this.CHECKOUT_PROGRESS
      default:
        return null
    }
  }
}

export default WebSDKAnalyticsEvent
