export const DATA = {
  'MOCK': {
    'VN_LOCATIONS_apiGetLocations': true,
    'VN_LOCATIONS_apiGetLocationDetails': true,
    'VN_MENU_apiGetMenuItemDetails': true,
    'VN_ORDER_apiGetLocationDetails': true,
    'VN_ORDER_apiPostOrder': true,
    'VN_WEBACCOUNT_apiGetOrderHistory': false,
    'VN_WEBACCOUNT_apiGetExperienceOrderHistory': false,
    'VN_WEBACCOUNT_apiGetAwardedTransferredExperiencesHistory': false,
    'VN_WEBACCOUNT_apiGetBraintreeClientToken': false,
    'VN_WEBACCOUNT_apiGetShift4ServerAndAccessBlock': false,
    'VN_WEBACCOUNT_apiGetVerificationCode': false,
    'VN_WEBACCOUNT_apiRequestSMSOrderUpdates': false,
    'VN_WEBACCOUNT_apiPosti4GoApplePayLink': false,
    'VN_WALLET_apiGetLoyaltyUser': false,
    'VN_USER_apiUpdateUser': false,
    'VN_SCANANDPAY_apiGetDiscountChargeNonces': false,
    'VN_USER_apiGetUserAffiliations': false,
    'VN_WALLET_apiFetchVirtualCurrencyActivity': false,
    'VN_WALLET_apiPostVirtualCurrencyTransfer': false,
    'VN_WALLET_apiPostCancelVirtualCurrencyTransfer': false,
    'VN_WALLET_apiFetchAwardRules': false,
    'VN_USER_apiLogoutUser': false,
    'VN_USER_apiGetStadiumUser': false,
    'VN_USER_apiGetUserPaymentMethods': false,
    'VN_HOME_apiPlaceOrder': false,
    'VN_USER_apiRefreshUsersAffiliations': false,
    'VN_USER_apiGetCardAffiliations': false,
    'VN_REVENUE_CENTERS_apiFetchAllRevenueCenters': false
  },
}
