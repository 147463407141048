import * as ActionTypes from './ActionTypes'
import { apiFetchAllRevenueCenters } from './Api'
import { getUser } from '../VNUser/Selectors'
import { setApiLoadingStatus } from '../VNApiLoadingSystem/ActionCreators'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'

/**
 * Get all the revenue centers that are available
 */
export const fetchAllRevenueCenters = () => {
  return async (dispatch, getState) => {

    const user = getUser(getState())
    const jwt = user.get('jwt')

    try {

      dispatch(setApiLoadingStatus(
        apiFetchAllRevenueCenters.name,
        { status: ApiLoadingStatus.LOADING }
      ))

      const revenueCenters = await apiFetchAllRevenueCenters(jwt)

      dispatch({
        type: ActionTypes.VNREVENUECENTERS_SET_MENUS,
        val: revenueCenters.data.menus
      })

      dispatch({
        type: ActionTypes.VNREVENUECENTERS_SET_STANDS,
        val: revenueCenters.data.stands
      })

      // need to convert a copy of the stands to a keyed map as well as an array like it is given
      let keyedStands = {}

      revenueCenters.data.stands.forEach(element => {
        keyedStands[element.uuid] = element
      })

      // need to convert a copy of the stands to a keyed map as well as an array like it is given
      let keyedMenus = {}

      revenueCenters.data.menus.forEach(element => {
        if (keyedMenus[element.stand_uuid]) {
          keyedMenus[element.stand_uuid].push(element)
        } else {
          keyedMenus[element.stand_uuid] = [element]
        }
      })

      dispatch({
        type: ActionTypes.VNREVENUECENTERS_SET_MENUS_BY_STANDS,
        val: keyedMenus
      })

      dispatch({
        type: ActionTypes.VNREVENUECENTERS_SET_STANDS_BY_ID,
        val: keyedStands
      })

      if (revenueCenters.data.sales_events) {
        let keyedSalesEvents = {}

        revenueCenters.data.sales_events.forEach(element => {
          keyedSalesEvents[element.id] = element
        })

        dispatch({
          type: ActionTypes.VNREVENUECENTERS_SET_SALES_EVENTS,
          val: keyedSalesEvents
        })
      }

      dispatch(setApiLoadingStatus(
        apiFetchAllRevenueCenters.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))

    } catch (error) {

      dispatch(setApiLoadingStatus(
        apiFetchAllRevenueCenters.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: error.message
        }
      ))

    }
  }
}
