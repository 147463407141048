import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'menus': Map({}),
    'cart': Map({
      'menuId': null,
      'items': {}
    }),
    'ageConfirmed': false,
    'experienceEventFilter': undefined,
    'searchText': '',
    'crossVenueMenusById': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNMENU_SET_MENU:
      return state.setIn(['menus', action.menu.menu.uuid], action.menu)
    case ActionTypes.VNMENU_SET_AGE_CONFIRMED:
      return state.set('ageConfirmed', action.confirmed)
    case ActionTypes.VNMENU_ADD_CART_ITEMS:
      return state.setIn(['cart', 'items'], action.items)
    case ActionTypes.VNMENU_SET_CART_MENU_ID:
      return state.setIn(['cart', 'menuId'], action.menuId)
    case ActionTypes.VNMENU_CART_RESET:
      return state.set('cart', getInitialState().get('cart'))
    case ActionTypes.VNMENU_SET_EXPERIENCE_EVENT_FILTER:
      return state.set('experienceEventFilter', action.filter)
    case ActionTypes.VNMENU_SET_ITEM_SEARCH_TEXT:
      return state.set('searchText', action.searchText)
    case ActionTypes.VNMENU_SET_CROSS_VENUE_MENU:
      return state.setIn(['crossVenueMenusById', action.data.menu.uuid], action.data)
    default:
      return state
  }
}

export default reducer
