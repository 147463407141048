import React, { forwardRef, Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core'
import { ReactComponent as TicketSearch } from '../../assets/icons/ticket-search.svg';
import { ReactComponent as FutureEventsSVG } from '../../assets/icons/future-event.svg';

import { VNMultiChoice, VNMultiChoiceRequiredEnum } from '../../VNMenu/components/VNMultiChoice'
import { getUser } from '../../VNUser/Selectors'
import { apiGetExchangeServiceGroupData } from '../Api'
import { disableBackdropSystem, enableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import { setSnackbarSystemDataAlertError } from '../../VNSnackbarSystem/ActionCreators'
import Money from '../../utils/money'
import { useTranslation } from 'react-i18next'
import { getExchangeServiceGroupDaysToQuery, getExchangeServiceTimeOffset } from '../../VNConfigurations/Selectors';
import { parseJWT } from '../../App/Utils';
import { setExchangeServiceJwt } from '../ActionCreators';

const useStyles = makeStyles(theme => ({
  claim: {
    border: 'solid rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderRadius: 10
  }
}))

/** 
 * props
 * provider: ie ticketsdotcom, used for api call.
 * loadResults: sends results of api call to get groupData to parent.
 * onChange: used for api call in parent and load ticket button data.
 */
export const ExchangeServiceAssistedLoad = forwardRef((props, ref) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  let allOptions = {}

  const user = useSelector(state => getUser(state))
  const timeOffset = useSelector(state => getExchangeServiceTimeOffset(state, props.provider))
  const groupDaysToQuery = useSelector(state => getExchangeServiceGroupDaysToQuery(state, props.provider))

  //#region SELECTORS
  const [ groupData, setGroupData ] = useState({})
  const [ canClaim, setCanClaim] = useState(true)
  const [ eventStart, setEventStart ] = useState(undefined)
  //#endregion

  //#region HANDLERS
  const onChange = (data) => {
    let choices = []
    let availableDate = undefined
    
    if(data.selectedChoices.length > 0) {
      data.selectedChoices.forEach(element => {
        choices.push(allOptions[element])
      })
      availableDate = groupData.groupSubtitle
    }
    props.onChange({
      selectedChoices: choices,
      availableDate: availableDate
    })
  }

  // gets all event data for assisted load
  useEffect(() => {
    const jwt = user.get('jwt')
    dispatch(enableBackdropSystem())

    apiGetExchangeServiceGroupData(jwt, props.provider).then(response => {
      dispatch(disableBackdropSystem())
      dispatch(setExchangeServiceJwt(response.data))
      let group = parseJWT(response.data).groups[0]

      if (group) {
        setGroupData(group)

        let eventDate = new Date(group.accessControlStartDate)

        //get event date and subtract the time offset from it. if current time is less than that we shouldnt be able to claim any tickets
        eventDate.setMinutes(eventDate.getMinutes() - timeOffset[0])
        setEventStart(eventDate)

        if (Date.now() < eventDate.valueOf()) {
          setCanClaim(false)
        }
      }

      props.loadedResults(true)
    }).catch(error => {
      let errMsg = t('UNKNOWN_ERROR')
      if (typeof error.response?.data?.error === 'string') {
        errMsg = error.response.data.error
      }
      dispatch(setSnackbarSystemDataAlertError(errMsg))
      dispatch(disableBackdropSystem())
      setCanClaim(true)
      props.loadedResults(false)
    })
  }, [user, dispatch])

  const sameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
  }
  //#endregion

  //#region RENDERERS
  const displayOptions = () => {
    // returns multiple vnmutlichoice, if there is more than one groups/events.
    if (Object.keys(groupData).length === 0) {
      return (
        <Box>
          <Box
            pt={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TicketSearch/>
          </Box>
          <Box pt={5}>
            <Typography align='center' style={theme.typography.subtitle1}>{t('NO_EXCHANGE_SERVICE').replace('$DAYS', groupDaysToQuery)}</Typography>
          </Box>
        </Box>
      )
    }

    let eventName = groupData.groupName
    let date

    if (groupData.groupSubtitle) {
      if (sameDay(new Date(groupData.groupSubtitle), new Date())) {
        date = `${t('TODAY_AT')} ${new Date(groupData.groupSubtitle).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}`
      } else {
        if (!canClaim) {
          date = ''
        } else {
          date = `${new Date(groupData.groupSubtitle).toLocaleDateString()}, ${new Date(groupData.groupSubtitle).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}`
        }
      }
    }

    let multiChoiceOptions = [] // used for display

    groupData?.accounts?.forEach(element => {
      let option = {
        uuid: element.account,
        balance: element.balance,
      }
      allOptions[element.account] = option

      option.disabled = element.loadedAt ? true : false
      option.modifier = Money.centsToDollars(element.balance, true, true)
      option.change = ''
      option.value = element.name
      option.disabledText = element.loadedAt ? `${t('LOADED')} ${new Date(element.loadedAt).toLocaleString([], {hour: '2-digit', minute:'2-digit'})}` : null

      multiChoiceOptions.push(option)
    });

    return (
      <Box pt={1} key={`Assisted Load`}>
        <VNMultiChoice
          disableAll = {!canClaim}
          options = {multiChoiceOptions}
          required = {VNMultiChoiceRequiredEnum.NONE}
          selectAll = {true}
          subTitle = {date}
          title = {eventName}
          key={`Assisted Load MultiChoice`}
          onChange = {onChange}
          onSelectAllChange = {onChange}
          checkDisabled = {true}
        />
      </Box>
    )
  }

  const displayClaim = () => {
    if (canClaim || !eventStart) {
      return <></>
    }

    const weekDayName = eventStart.toLocaleDateString('default', { weekday: 'long' })
    const monthNameShort = eventStart.toLocaleString('default', { month: 'short' })
    const dateNumber = eventStart.getDate()
    const eventTime = eventStart.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    return (
      <Box
        display={'flex'}
        className={classes.claim}
        p={1}
      >
        <FutureEventsSVG/>
        <Box pl={1}>
          <Typography variant='subtitle1'>{`${t('AVAILABLE_LOAD')} ${weekDayName}, ${monthNameShort} ${dateNumber} ${t('AT')} ${eventTime}`}</Typography>
        </Box>
      </Box>
    )
  }
  //#endregion
  return (
    <Box>
      {displayClaim()}
      {displayOptions()}
    </Box>
  )
})
