/**
 * Exclusivity type that defines what exclusivity group
 * an Experience may belong to
 */
const ExclusivityType = {
  ALL: 'all',
  PREFERRED_CC: 'preferred_credit_card',
  GEO: 'geo_exclusive',
  TIERED: 'tiered_exclusive',
  /**
   * Get the type by string or int
   * @param {string or int} type 
   */
  getType: function(type) {
    switch(type) {
      case 'tiered_exclusive':
        return this.TIERED
      case 'preferred_credit_card':
        return this.PREFERRED_CC
      case 'geo_exclusive':
        return this.GEO
      case 'all':
      default:
        return this.ALL
    }
  },
  /**
   * Get priority of ExclusivityType. Used in the event
   * a sale has multiple exclusivities and need to determine
   * which one to use via heirarchy.
   */
  getPriority: function(type) {
    switch(type) {
      case this.TIERED:
        return 3
      case this.PREFERRED_CC:
        return 2
      case this.GEO:
        return 1
      case this.ALL:
      default:
        return 0
    }
  }
}

export default ExclusivityType
