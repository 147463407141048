import {
  LOAD_SECTIONS_SUCCEEDED,
  LOAD_ROWS_SUCCEEDED,
  LOAD_SEATS_SUCCEEDED,
  LOAD_SECTIONS_REQUESTED,
  LOAD_ROWS_REQUESTED,
  LOAD_SEATS_REQUESTED,
  LOAD_SECTIONS_FAILED,
  LOAD_ROWS_FAILED,
  LOAD_SEATS_FAILED,
} from '../actions/seat'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_SECTIONS_REQUESTED:
      return {
        ...state,
        availableSectionsLoading: true,
      }

    case LOAD_ROWS_REQUESTED:
      return {
        ...state,
        availableRowsLoading: true,
      }

    case LOAD_SEATS_REQUESTED:
      return {
        ...state,
        availableSeatsLoading: true,
      }

    case LOAD_SECTIONS_SUCCEEDED:
      return {
        ...state,
        availableSections: action.payload,
        availableSectionsLoading: false,
      }

    case LOAD_ROWS_SUCCEEDED:
      return {
        ...state,
        availableRows: action.payload,
        availableRowsLoading: false,
      }

    case LOAD_SEATS_SUCCEEDED:
      return {
        ...state,
        availableSeats: action.payload,
        availableSeatsLoading: false,
      }

    case LOAD_SECTIONS_FAILED:
      return {
        ...state,
        availableSectionsLoading: false,
      }

    case LOAD_ROWS_FAILED:
      return {
        ...state,
        availableRowsLoading: false,
      }

    case LOAD_SEATS_FAILED:
      return {
        ...state,
        availableSeatsLoading: false,
      }

    default:
      return state
  }
}

export default reducer
