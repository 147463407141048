export const VNWALLET_LOYALTY_USER = 'VNWALLET_LOYALTY_USER'
export const VNWALLET_VIRTUAL_CURRENCY_ACTIVITY = 'VNWALLET_VIRTUAL_CURRENCY_ACTIVITY'
export const VNWALLET_CLEAR_VIRTUAL_CURRENCY_ACTIVITY = 'VNWALLET_CLEAR_VIRTUAL_CURRENCY_ACTIVITY'
export const VNWALLET_AWARDS_RULES = 'VNWALLET_AWARDS_RULES'
export const VNWALLET_GET_QR_NONCES = 'VNWALLET_GET_QR_NONCES'
export const VNWALLET_GET_ORDER_TOTAL = 'VNWALLET_GET_ORDER_TOTAL'
export const VNWALLET_SET_VIRTUAL_CURRENCY_TRANSFER_EMAIL_ACCEPT = 'VNWALLET_SET_VIRTUAL_CURRENCY_TRANSFER_EMAIL_ACCEPT'
export const VNWALLET_GET_ALL_WALLET = 'VNWALLET_GET_ALL_WALLET'
export const VNWALLET_UPDATE_VIRTUAL_CURRENCY_PROMOTIONS = 'VNWALLET_UPDATE_VIRTUAL_CURRENCY_PROMOTIONS'
export const VNWALLET_SET_LAST_TIME_QR_CODE_GENERATED = 'VNWALLET_SET_LAST_TIME_QR_CODE_GENERATED'
export const EXCHANGE_SERVICE_SET_JWT = 'EXCHANGE_SERVICE_SET_JWT'