import VNWebSDKAnalytics from '../../VNWebSDK/reporting/VNWebSDKAnalytics'

import { CREATE_ORDER_SUCCEEDED } from "../../actions/order"
import { ORDER_TOTAL_SUCCEEDED } from "../../actions/orderTotal"
import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART } from "../../actions/cart"
import { VNUSER_SET_USER } from '../../VNUser/ActionTypes'
import { getCartMenuItems } from '../../selectors/cart'
import VNOrderState from '../../VNWebAccount/types/VNOrderState'
import { VNORDERS_SEND_ORDER_DETAILS_TO_SDK_ANALYTICS } from '../../VNOrders/ActionTypes'

import {} from '../../VNMenu/Selectors'

import get from 'lodash/get'
import head from 'lodash/head'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import humps from 'humps'

const VNWebSDKAnalyticsMiddleware = store => next => action => {
  if (action.type === ADD_ITEM_TO_CART) {
    if (action.payload?.productType === 'Experience') {

    } else {
      const s = store.getState()

      const menuId = get(s, 'cart.menuId', '')
      const menu = s.VNMenu.get('menus').get(menuId)

      const itemId = get(action, 'payload.id', '')
      const item = menu.items.find(i => i.id === itemId)
      const name = get(item, 'name', '')
      const category = get(item, 'category', '')
      const variant = get(action.payload, 'variant', '')
      const price = get(item, 'default_price_in_cents', '')
      const quantity = get(action, 'payload.quantity', '')

      if (!item) {
        let result = next(action)
        return result
      }

      VNWebSDKAnalytics.reportAddItemToCart(itemId, name, category, variant, price, quantity)
    }
  }

  if (action.type === REMOVE_ITEM_FROM_CART) {
    if (get(action, 'payload.productType') === 'Experience') {

    } else {
      const s = store.getState()

      const menuId = get(s, 'cart.menuId', '')
      const menu = s.VNMenu.get('menus').get(menuId)

      const itemId = get(action, 'payload.id', '')
      const item = menu.items.find(i => i.id === itemId)
      const name = get(item, 'name', '')
      const category = get(item, 'category', '')
      const variant = action?.payload?.variantId ?? ''
      const price = get(item, 'default_price_in_cents', '')
      const quantity = get(store.getState(), `cart.item["${itemId}"].quantity`, '')

      VNWebSDKAnalytics.reportRemoveItemFromCart(itemId, name, category, variant, price, quantity)
    }
  }

  if (action.type === VNUSER_SET_USER) {
    const id = get(action, 'user.userID')
    const email = get(action, 'user.email')
    const name = get(action, 'user.name')

    if (!isEmpty(id)) {
      VNWebSDKAnalytics.reportUser(id, email, name)
    }

    // all affiliations are now part of the user
    VNWebSDKAnalytics.reportUserAffiliations(get(action, 'user.affiliations'))
  }

  // Enhanced Ecommerce Funnel Part 3.1, viewing the cart for checkout
  if (action.type === ORDER_TOTAL_SUCCEEDED) {
    const items = getCartMenuItems(store.getState())

    if (isEmpty(items)) {
      let result = next(action)
      return result
    }
  }

  // Enhanced Ecommerce Funnel Part 3.3, completing the purchase
  if ([CREATE_ORDER_SUCCEEDED, VNORDERS_SEND_ORDER_DETAILS_TO_SDK_ANALYTICS].includes(action.type)) {

    let order
    if (action.type === CREATE_ORDER_SUCCEEDED) {
      order = head(values(action.payload.order))
    } else if (action.type === VNORDERS_SEND_ORDER_DETAILS_TO_SDK_ANALYTICS) {
      order = humps.camelizeKeys(action.payload)
    }

    const orderId = order.id
    const quantity = Object.values(action.payload.orderLineItem).length
    const discount = order.attributes.discountAmountInCents
    const tips = order.attributes.tip
    const tax = order.attributes.tax
    const total = order.attributes.total
    const paymentTypes = order.attributes.creditCardType
    const state = order.attributes.state
    const name = order.attributes.userName
    const email = order.attributes.email

    switch(state) {
      case VNOrderState.AUTHORIZATION_FAILED:
      case VNOrderState.SETTLEMENT_FAILED:
      case VNOrderState.SUBMISSION_FAILED:
      case VNOrderState.PAYMENT_FAILED:
        break
      default: {
        VNWebSDKAnalytics.reportCompletedPurchase(
          orderId,
          quantity,
          discount,
          tips,
          tax,
          total,
          paymentTypes,
          name,
          email
        )
      }
    }

    VNWebSDKAnalytics.reportCheckoutProgress(orderId, state)

    if (!order) {
      let result = next(action)
      return result
    }
  }

  let result = next(action)
  return result
}

export default VNWebSDKAnalyticsMiddleware
