export const mockOrderCompleted = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        "data": {
          "id": "317a70c2-7a59-4924-a761-2b3fac97f36d",
          "type": "order",
          "attributes": {
            "order_number": "905034",
            "success": true,
            "created_at": "2021-06-21T21:10:37Z",
            "updated_at": "2021-06-21T21:10:43Z",
            "subtotal": "5.00",
            "device_uuid": "17325114",
            "discount_amount_in_cents": "0",
            "total": "5.00",
            "tip": "0.00",
            "tax": "0.00",
            "delivery_fee": "0.00",
            "service_charge": "0.00",
            "totals_with_payment_types": [],
            "stand_name": "Express Pickup Test",
            "stand_uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
            "state": "completed",
            "state_display_name": "Completed",
            "state_description": "Your order is in completed state",
            "usage_type": "pickup",
            "cancellable": false,
            "email": "edward@venuenext.com",
            "last_four": null,
            "credit_card_type": null,
            "user_notes": null,
            "payment_authorized_at": "2021-06-21T21:10:40Z",
            "payment_settled_at": "2021-06-21T21:10:43Z",
            "payment_voided_at": null,
            "payment_refunded_at": null,
            "stand_menu_uuid": "cde84c5d-6b0a-4d1b-a446-6c910076f8bd",
            "order_location": null,
            "uses_qr_code": false,
            "receipt_url": "https://arena-dev.ordernext.com/orders/317a70c2-7a59-4924-a761-2b3fac97f36d",
            "fulfillment_date": "2021-06-21",
            "fulfillment_slot": null,
            "user_attributes": {},
            "user_name": "Eddie Herdemian",
            "order_type": "day_of_event",
            "payment_info": [{
              "uuid": "ab39fe84-d43f-419c-ac0e-aed3b1913925",
              "amount_in_cents": 500,
              "customer_payment_id": null,
              "payment_type": "shift4_token",
              "short_description": null,
              "tip": 0,
              "user_attributes": {}
            }],
            "promotions": [],
            "source_device_uuid": "17325114",
            "use_timer_receipt": false,
            "tax_rate": "0.1"
          },
          "relationships": {
            "line_items": {
              "data": [{
                "id": "6bb13369-8452-48cd-add5-bc0b266f0891",
                "type": "line_item"
              }]
            }
          }
        },
        "included": [{
          "id": "6bb13369-8452-48cd-add5-bc0b266f0891",
          "type": "order_line_item",
          "attributes": {
            "uuid": "1d0a4fe2-b356-4ade-85a8-5cf14a6e5d84",
            "menu_item_uuid": "bef1fdef-3650-460c-b642-5a028cd7eee5",
            "tax_amount_in_cents": 0,
            "total_amount_in_cents": 500,
            "name": "Hot Diggity Dog",
            "parent_line_item_uuid": null,
            "quantity": 1,
            "is_alcohol": false,
            "payment_type": null,
            "product_sku": "VN001",
            "modifiers": [
              []
            ],
            "special_type": null,
            "expires_at": null
          }
        }]
      }
    })
  })
}
