import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'

class Title {
  static siteName = undefined

  static generate = (...strings) => {
    const divider = " | "
    const allStrings = [Title.siteName, ...strings]
    const filteredStrings = allStrings.filter(string => isString(string) && !isEmpty(string))
    const title = filteredStrings.join(divider)

    return title
  }
}

export default Title
