var ESData = {
  translation: {
    CURRENCY: "EUR",
    CURRENCY_SYMBOL: "€",
    CUSTOM_TIP_AMOUNT: "Bitte geben Sie einen individuellen Trinkgeldbetrag ein.",
    OK: "OK",
    AMOUNT: "Betrag",
    creditCard: {
      number: {
        displayText: "Kartennummer",
      },
      expirationDate: {
        displayText: "Verfallsdatum",
      },
      cvv: {
        displayText: "Sicherheitsnummer",
      },
      postalCode: {
        displayText: "Postleitzahl",
      },
    },
    LOG_IN: 'Anmelden',
    REPORT_AN_ISSUE: 'Ein Problem melden',
    TERMS_OF_SERVICE: 'Nutzungsbedingungen',
    PRIVACY_POLICY: 'Datenschutzerklärung',
    HOME: 'Startseite',
    MY_ACCOUNT: 'Mein Konto',
    ORDERS: 'Bestellungen',
    SIGN_OUT: 'Abmeldung',
    PASSES: 'Pässe',
    PAYMENTS: 'Zahlungen',
    MULTIPLIER: 'Multiplikator',
    LOADING_PASS: 'Ladepass',
    ORDER_NEXT: 'Nächste Bestellung',
    APPLE_PAY_FAILED_TO_LOAD: 'Apple Pay konnte nicht geladen werden. Bitte versuchen Sie es mit einer Kreditkarte.',
    COULD_NOT_COMPLETE_PURCHASE_CARD_ON_FILE: "Wir konnten Ihren Einkauf nicht mit der Karte abschließen, die in Ihrem Apple Pay-Account gespeichert ist. Bitte versuchen Sie es mit einer Kreditkarte.",
    IS_REQUIRED: 'ist erforderlich',
    INVALID_VALUE_FOR: 'Ungültiger Wert für',
    REQUIRED: 'Erforderlich',
    PAYMENT_PROCESSOR_CONNECTION_ERROR: "Wir haben Probleme mit der Verbindung zu unserem Zahlungsprozessor.",
    RECIPIENT_NAME: 'Name des Empfängers',
    NAME_REQUIRED: 'Name ist erforderlich',
    PLACE_ORDER: 'Bestellung aufgeben',
    TIP: 'Trinkgeld',
    SERVICE_FEE: 'Service-Gebühr',
    SERVICE_CHARGES: 'Bedienungsgeld',
    DISCOUNT: 'Rabatt',
    TAXES: 'Steuern',
    TOTAL: 'Gesamt',
    DISMISS: 'Entfernen',
    ARE_YOU_SURE: 'Sind Sie sicher?',
    VIEW_CART: 'Warenkorb anzeigen',
    EMAIL_RECEIPT: 'E-Mail-Empfang',
    EMAIL_IS_REQUIRED: 'E-Mail ist erforderlich',
    CREATING_YOUR_ORDER: 'wir erstellen Ihre Bestellung',
    GETTING_ORDER_INFO: 'Informationen zu Ihrer Bestellung erhalten',
    CALCULATING_ORDER_TOTAL: 'Berechnung Ihrer Bestellsumme',
    THERE_WAS_AN_ERROR: 'Es gab einen Fehler',
    GET_ORDER_ERROR: "Wenn die Aktualisierung dieser Seite nicht hilft, können Sie die Bestellnummer in Ihrer E-Mail-Bestätigung verwenden, um Ihre Bestellung abzuholen.",
    GET_ORDER_TOTAL_ERROR: 'Dies könnte vorübergehend sein. Sie können versuchen, die Seite zu aktualisieren, bevor Sie Ihre Bestellung aufgeben.',
    BRAINTREE_LOAD_ERROR: "Wir haben Probleme mit der Verbindung zum Zahlungsprozessor. Wenn das Problem nach dem Aktualisieren der Seite weiterhin besteht, können wir Ihre Bestellung nicht bearbeiten. Wir wurden über dieses Problem informiert und entschuldigen uns für die Unannehmlichkeiten.",
    DATE: 'Datum',
    EVENTS_IN: 'Ereignisse in',
    BACK: 'Zurück',
    BACK_LOWERCASE: 'zurück',
    SELECT_AN_EVENT: 'Wählen Sie ein Ereignis aus',
    ALL_EVENTS: 'alle Ereignisse',
    REDEEM_SOON: 'BALD EINLÖSEN',
    REDEEM_NOW: 'JETZT EINLÖSEN',
    REDEEMED: 'EINGELÖST',
    EXPIRED: 'ABGELAUFEN',
    REDEEMING: 'EINLÖSUNG',
    INFO: 'Infos',
    EACH: 'jeweils',
    ONLY: 'nur',
    LEFT: 'links!',
    REMOVE: 'entfernen',
    REQUIRED_LOWERCASE: 'erforderlich',
    PICK_AT_LEAST: 'wählen Sie mindestens',
    PICK_UP_TO: 'abholen bis',
    OPTIONAL: 'optional',
    QR_CODE: 'QR-Code',
    DELIVERY_TO: 'Lieferung an',
    PICKUP_AT: 'Abholung bei',
    SECTION: 'Bereich',
    ROW: 'Reihe',
    SEAT: 'Sitzplatz',
    SUITE: 'Suite',
    REMOVE_ALL_ITEMS: 'Dadurch werden alle Artikel aus Ihrer aktuellen Bestellung entfernt.',
    THIS_WILL_REMOVE: 'Dies wird entfernt',
    FROM_YOUR_CART: 'aus Ihrem Warenkorb',
    EVENT_DATE: 'Datum der Veranstaltung',
    EVENT: 'Veranstaltung',
    ITEM: 'Artikel',
    ITEMS: 'Artikel',
    CART: 'Warenkorb',
    EXPERIENCE: 'erleben',
    MENU: 'Menü',
    CLEAR_CART: 'Warenkorb leeren',
    ADD_ITEMS: 'Artikel hinzufügen',
    NOT_NEEDED_FOR_SUITES: 'Nicht erforderlich für Suiten',
    LOADING: 'wird geladen',
    SELECT_YOUR_SECTION_OR_SUITE: 'Wählen Sie Ihren Bereich oder Ihre Suite aus',
    SELECT_YOUR_ROW: 'Wählen Sie Ihre Reihe aus',
    SELECT_YOUR_SEAT: 'Wählen Sie Ihren Sitzplatz aus',
    SECTION_LOWERCASE: 'Bereich',
    OR: 'oder',
    SUITE_LOWERCASE: 'Suite',
    CONFIRM_SEAT: 'Sitzplatz bestätigen',
    SAVE_SEAT: 'Sitzplatz speichern',
    VALIDATING_SEAT: 'Validierung des Sitzes',
    YOUR_SEAT: 'Ihr Sitzplatz',
    SEAT_NOT_ELIGIBLE_FOR_DELIVERY_FROM_THIS_STAND: 'Der Sitzplatz kann nicht von diesem Stand aus beliefert werden.',
    MODIFY: 'ändern',
    YOU_CAN_EDIT_THESE_UNTIL_YOU_REDEEM: 'Sie können das bis zur Einlösung bearbeiten',
    PLEASE_FILL_IN_THE_ABOVE: 'Bitte füllen Sie das obige Formular aus.',
    PLEASE_ADD_YOUR_SEAT: 'Bitte fügen Sie Ihren Sitzplatz hinzu.',
    EDIT_THESE_AFTER_PLACING_ORDER: 'Sie können dies nach Ihrer Bestellung bearbeiten.',
    ENTER_YOUR: 'Geben Sie Ihre',
    DONE: 'ERLEDIGT',
    NEXT: 'NÄCHSTE',
    WHEN_YOU_ARE_READY_TO_REDEEM: "Wenn Sie zum Einlösen bereit sind, tippen Sie auf die Schaltfläche Jetzt einlösen und zeigen Sie dann die",
    CONFIRMATION_SCREEN: 'Bestätigungsbildschirm',
    SECOND_CONFIRMATION_SCREEN: 'zweite Bestätigung',
    HOW_DO_I_REDEEM: 'Wie kann ich einlösen',
    HOW_DO_I_REDEEM_CAPS: 'WIE KANN ICH EINLÖSEN',
    EDIT: 'bearbeiten',
    YOU_CAN_ONLY_ORDER_FOR_ONE_EVENT: 'Sie können jeweils nur für eine Veranstaltung bestellen',
    MAX_QUANTITY_REACHED: 'maximale Menge erreicht',
    ORDER_NOW: 'jetzt bestellen',
    EMAIL_MY_RECEIPT: 'Meine Quittung per E-Mail senden',
    ORDER: 'Bestellung',
    YOU_ARE_ABOUT_TO_REDEEM: "Sie sind im Begriff, Ihre Erfahrung einzulösen und müssen die Bestätigung vorlegen. Dies kann nicht rückgängig gemacht werden, also stellen Sie sicher, dass Sie bereit sind.",
    AT: 'unter',
    ON: 'auf',
    BETWEEN: 'zwischen',
    AND: 'und',
    WHAT_HAPPENS_NEXT: 'WAS PASSIERT ALS NÄCHSTES',
    KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS: 'Halten Sie Ausschau nach weiteren Anweisungen zu Ihrem Kauf.',
    THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM: 'Diese Erfahrung können Sie einlösen',
    THIS_EXPERIENCE_EXPIRED_ON: 'Diese Erfahrung ist abgelaufen am ',
    THIS_EXPERIENCE_WAS_REDEEMED_ON: 'Diese Erfahrung wurde eingelöst am',
    ORDER_NUMBER: 'Bestellnummer',
    ORDER_DETAILS: 'Details zur Bestellung',
    PLACED_ON: 'Platziert auf',
    RECEIPT: 'Quittung',
    SUBTOTAL: 'Zwischensumme',
    NOTHING_FOR_SALE: "Für dieses Datum ist nichts verfügbar",
    PLEASE_TRY_ANOTHER_EVENT: 'Bitte versuchen Sie es mit einer anderen Veranstaltung, oder schauen Sie bald wieder vorbei, um zu sehen, ob für diese Veranstaltung etwas verfügbar ist.',
    ONLY_CAPS_START: 'Nur',
    OF_THIS_ITEM_PER_ORDER_ALLOWED: 'dieses Artikels pro Bestellung erlaubt',
    SELECT_ONE: 'Wählen Sie eine',
    CONFIRM: 'BESTÄTIGEN',
    ADD_TO_CART: 'zum Warenkorb hinzufügen',
    MUST_BE_21: 'Muss über 21 Jahre alt sein und eine gültige ID haben, um Alkohol zu bestellen',
    NO_ITEMS_AVAILABLE_IN_THIS_CATEGORY: 'Keine Artikel in dieser Kategorie verfügbar',
    LOCATION_NOT_CURRENTLY_ACCEPTING_ORDERS: 'Dieser Standort nimmt derzeit keine Bestellungen an.',
    SENDING: 'SENDEN',
    EMAIL_SENT: 'EMAIL GESENDET',
    FAILED_TRY_AGAIN: 'FEHLGESCHLAGEN: BITTE VERSUCHEN SIE ES SPÄTER NOCH EINMAL.',
    KIOSK_ORDER: 'Kiosk-Bestellung',
    SCAN_THIS_CODE: 'Scannen Sie diesen Code ein, wenn Sie Ihre Bestellung abholen bei',
    PRESENT_THIS_PAGE: 'Präsentieren Sie diese Seite, wenn Sie Ihre Bestellung abholen bei',
    SHOW_THIS_SCREEN_TO_RECEIVE_YOUR_ORDER: 'Zeigen Sie diesen Bildschirm, um Ihre Bestellung zu erhalten.',
    HANG_TIGHT: "Bleiben Sie dran - wir überprüfen Ihre Bestellung, bis wir sicher sind, dass sie in der Küche angekommen ist.",
    ONCE_IT_DOES: "Sobald dies geschehen ist, sehen Sie hier einen QR-Code, den Sie zur Abholung Ihrer Bestellung verwenden können.",
    WE_HOPE_YOU_ENJOYED_YOUR_ORDER: 'Wir hoffen, dass Ihnen Ihre Bestellung gefallen hat, danke nochmals für den Versuch von OrderNext!',
    SEND: 'Senden',
    MOBILE_ORDERING_MENUS: 'Mobile Bestellmenüs',
    ALMOST_DONE: 'fast fertig',
    WE_NEED_SOME_MORE_INFO: 'wir brauchen mehr Informationen',
    ONE_LAST_THING: 'eine letzte Sache',
    EXPERIENCES: 'Erfahrungen',
    FOOD_AND_DRINKS: 'Essen und Getränke',
    MERCHANDISE: 'Handelswaren',
    OPEN_ORDER: 'Offene Bestellung',
    PREPARING_YOUR_ORDER: 'Vorbereitung Ihrer Bestellung',
    FREE: 'KOSTENLOS',
    PLEASE_PICKUP_AT: 'Bitte abholen bei',
    SIGNUP_IS_EASY: 'Die Anmeldung ist ganz einfach!',
    FIRST_NAME: 'Vorname',
    LAST_NAME: 'Nachname',
    EMAIL_ADDRESS: 'E-Mail-Addresse',
    PHONE_NUMBER: 'Telefonnummer',
    MESSAGE_RATES_MAY_APPLY: 'SMS- und Datentarife können anfallen',
    PLEASE_ENTER_THE_CODE: 'Bitte geben Sie den Code ein, der gesendet wurde an',
    CONFIRMATION_CODE: 'Bestätigungscode',
    RESEND_CODE: 'CODE ERNEUT SENDEN',
    ENTER_YOUR_PHONE_NUMBER: 'Tragen Sie Ihre Telefonnummer ein',
    SIGN_UP: 'Anmeldung',
    NO_PHONE_USER_EXISTS: 'Diese Nummer existiert nicht.',
    PHONE_USER_EXISTS: 'account already exists with this mobile number. Please login instead.',
    INVALID_PHONE: 'Bitte geben Sie eine gültige Nummer ein.',
    INVALID_CODE: 'Bitte tragen Sie einen korrekten Code ein.',
    INVALID_SIGNUP_FORM: 'Bitte geben Sie gültige Daten ein, um fortzufahren.',
    NETWORK_ERROR: 'Bei VenueNext tritt ein Problem auf. Bitte versuchen Sie es erneut.',
    GENERIC_ERROR: 'Etwas ging schief, bitte gehen Sie zurück und versuchen Sie es erneut.',
    NEED_NEW_CODE: 'Der Code ist nicht mehr gültig, bitte fordern Sie einen Neuen an.',
    NEW_CODE_REQUESTED: 'Ein neuer Code wurde angefordert.',
    TEXT_ME_WHEN_READY: 'Schreiben Sie mir den Bestellstatus',
    TEXT_ORDER_CONFIRM: 'Wir werden Ihnen eine SMS schicken mit der Aktualisierung zu Ihrer Bestellung.',
    SMS_NOTIFICATIONS: 'Schicken Sie mir Bestellaktualisierungen:',
    MY_INFORMATION: 'Meine Informationen',
    USER_UPDATE_SUCCESS: 'Benutzerinformationen gespeichert.',
    ORDER_AGAIN: 'Erneut bestellen',
    ALL: 'Alle',
    PICKUP: 'Abholen',
    DELIVERY: 'Lieferung',
    SMS_ORDER_NOTIFICATIONS: 'Vielen Dank für die Übermittlung Ihrer Telefonnummer für Aktualisierungen!',
    ENTER_PROMO_CODE: 'Geben Sie einen Gutschein-Code ein',
    PROMO_CODE: 'Gutschein-Code',
    APPLY: 'Anwenden',
    SHOP: 'Geschäft',
    SCAN_AND_PAY: 'Scan & Pay',
    VIRTUAL_CURRENCY_ACTIVITY: 'Aktivität',
    BENEFITS_AND_REWARDS: 'Vorteile und Belohnungen',
    MY_INFO: 'Meine Informationen',
    MY_BADGE: 'Mein Abzeichen',
    MY_WALLET: 'Mein Geldbeutel',
    SAVE: 'Speichern',
    PLEASE_CORRECT: 'Bitte korrigieren Sie die obigen Formularfelder.',
    CENTER_QR_CODE: 'Center QR-Code zum Bezahlen mit',
    PRESENT_QR_CODE: 'Präsentieren Sie diesen QR-Code zum Bezahlen mit',
    UPDATE: 'AKTUALISIEREN',
    PRESENT_THIS_BADGE: 'Präsentieren Sie diesen Bildschirm, wo Sie es akzeptieren',
    ACTIVITY: 'Aktivität',
    SEND_TRANSFER: 'Senden',
    TRANSFER: 'Überweisung',
    NO_EXCHANGE_SERVICE: 'No tickets for the next $DAYS days with loaded value',
    AVAILABLE_LOAD: 'Tickets for upcoming event available to load on',
    RECIPIENT_EMAIL: 'Empfänger E-Mail',
    ENTER_AMOUNT: 'Menge eingeben',
    ELIGIBLE_TRANSFER: 'eligible to be transferred. Expiring ',
    CANNOT_TRANSFER: 'may not be transferred.',
    SEND_NOW: 'Send Now',
    WAYS_TO_EARN: 'Möglichkeiten zu verdienen',
    LEARN_MORE: 'ERFAHREN SIE MEHR',
    SEND_A_NOTE: 'Senden Sie eine Anmerkung',
    CLICK_TO_SCAN: 'Tippen Sie hier, um zu Scannen',
    USE_MY: 'Benutze mein',
    FOR_PURCHASES: 'für Einkäufe',
    CREDIT_CARDS: 'Kreditkarten',
    CREDIT_CARD: 'Kreditkarte',
    ADD_A_CARD: 'Füge eine Karte hinzu',
    PAYMENT: 'Zahlung',
    REMAINING_BALANCE: 'Der Restbetrag von',
    MUST_BE_PAID_BY_CC: 'muss mit Kreditkarte bezahlt werden.',
    SORRY: 'Es tut uns leid',
    NOT_ENABLED_FOR_PRODUCT_TYPE: 'ist für diesen Kauf nicht aktiviert.',
    PROMO_CODE_REMOVED: 'Gutschein-Code entfernt',
    PROMO_CODE_ADDED: 'Gutschein-Code angewendet',
    CANCEL_TRANSFER: 'Übertragung abbrechen',
    ABOUT_TO_CANCEL: 'Sie sind dabei, eine geplante Übertragung abzubrechen an',
    YES: 'JA',
    NO: 'NEIN',
    TRANSFER_PROCESSING: 'Ihre Überweisung wird bearbeitet.',
    PICKUP_YOUR_ORDER: 'Abholung Ihrer Bestellung bei',
    SCAN: 'Scan',
    COMPLETED: 'Abgeschlossen',
    OPEN_ORDERS: 'offene Bestellungen',
    REDEEMABLE: 'Einlösbar',
    LOGIN_TO_ACCEPT: 'Melden Sie sich an, um Ihre Überweisung zu akzeptieren',
    SIGNUP_TO_ACCEPT: 'Melden Sie sich an, um Ihre Überweisung zu akzeptieren',
    TRANSFER_ACCEPTED: 'Überweisung akzeptiert!',
    SPEND: 'Verbringen',
    MORE_TO_EARN: 'mehr zu verdienen',
    IN: 'im',
    LOCATION_EXCLUSIVITY: 'Lage Ausschließlichkeit',
    CC_EXCLUSIVITY: 'bevorzugte Karteninhaber',
    MEMBER_EXCLUSIVITY: 'Exklusiv',
    NO_OPEN_ORDERS: 'Sie haben zur Zeit keine offenen Bestellungen',
    NO_COMPLETED_ORDERS: 'Sie haben noch keine abgeschlossenen Bestellungen.',
    NO_VC_ACTIVITY: 'Sie haben noch keine virtuelle Währungs-Aktivität',
    CLAIM_NOW: 'Jetzt beanspruchen',
    PLEASE_ADD_A_PAYMENT_METHOD: 'Bitte fügen Sie eine Zahlungsmethode hinzu',
    ADD_CREDIT_CARD: 'Fügen Sie eine Kreditkarte hinzu, um Scan & Pay zu verwenden',
    ADD_CARD: 'KARTE HINZUFÜGEN',
    ENHANCE_EXPERIENCE: 'Verbessern Sie Ihre Erfahrung',
    MAYBE_LATER: 'VIELLEICHT SPÄTER',
    CAMERA_PERMISSION_REQUIRED: 'Enable camera permissions in phone settings',
    TURN_ON_TO_PAY: 'Einschalten, um zu bezahlen mit',
    WILL_BE_APPLIED: 'wird auf diese Käufe angewendet',
    PLEASE_SHOW_THIS_SCREEN: 'Please show this screen at',
    TO_CONFIRM_YOUR_ORDER: 'to confirm your order.',
    SELECT_A_SHOP: 'Select a Shop',
    UNABLE_CAMERA: 'Unable to initiate the camera',
    MIN_WAIT: 'min wait',
    MINUTE_WAIT: 'minute wait',
    MIN: 'min',
    AFFILIATIONS_UPDATED: 'Affiliations Updated!',
    AWARDED_ON: 'Awarded on',
    NAV_MENU: 'Navigation Menu',
    PROFILE: 'Profile',
    SAVE_CARD: 'Save Card',
    SCROLL_DOWN_PAYMENT: 'Scroll down to enter payment details ',
    COMPLETE_YOUR_ORDER: 'and complete your order',
    SCAN_THIS_BARCODE: 'Scan this barcode to redeem.',
    SCAN_THIS_QRCODE: 'Scan this QR code to redeem.',
    COMPLETE_PAYMENT_BELOW: 'Complete your payment below ',
    AND_THEN_PICKUP: 'and then pickup your order at',
    EVENT_SELECTION: 'Event Selection',
    EVENTS: 'Events',
    MONTH_1: 'January',
    MONTH_2: 'February',
    MONTH_3: 'March',
    MONTH_4: 'April',
    MONTH_5: 'May',
    MONTH_6: 'June',
    MONTH_7: 'July',
    MONTH_8: 'August',
    MONTH_9: 'September',
    MONTH_10: 'October',
    MONTH_11: 'November',
    MONTH_12: 'December',
    FILTER: 'Filter',
    RESET: 'Reset',
    NO_WAIT_TIMES: 'No Wait Times',
    SERVICE_TYPES: 'Service Types',
    LOCATION: 'Location',
    CATEGORIES: 'Categories',
    DELIVERY_LOCATION: 'Delivery Location',
    CURRENTLY_NOT: 'Currently not accepting orders.',
    PLEASE_CHECK: 'Please check back later.',
    PLEASE_SCAN_AN_ITEM_IN: 'Please scan an item in ',
    SELECT_A_PICKUP_TIME: 'Select a pickup time',
    PLEASE_SELECT_A_PICKUP_TIME: 'Please select a pickup time',
    EXCHANGE_SERVICES_TRANSFER_DISCLAIMER: 'Ticket funds may only be loaded to one account. Do not load tickets that will be transferred',
    EXCHANGE_SERVICES_FUTURE_EVENTS_DISCLAIMER: 'Loaded ticket funds for future events will deposit on the date of the event',
    EXCHANGE_SERVICES_EXPERATION_WARNING: 'Any unused loaded ticket funds will expire after the event date',
    LOAD_GIFT_CARD: 'Load Gift Card',
    GIFT_CARD_NO_FUNDS: 'No funds available.',
    GIFT_CARD_TRANSFER_COMPLETE: 'Load Gift Card successful.',
    DISCLAIMER: 'Disclaimer',
    GIFT_CARD_BALANCE: 'Gift Card Balance',
    PIN: 'Pin',
    GIFT_CARD_NUMBER: 'Gift Card Number',
    GIFT_CARD_DISCLAIMER_DEFAULT: 'All funds will be withdrawn.',
    SOMETHING_WENT_WRONG: 'Oops something went wrong',
    RELOAD: 'RELOAD',
    FBMENUS: 'F&B Menus',
    SHOP_MERCH: 'Shop Merchandise',
    MARKETPLACE: 'Marketplace',
    MARKET_ITEMS: 'Market Items',
    MERCHANDISE_ITEMS: 'Merchandise Items',
    WALLET: 'Wallet',
    MY_ORDERS: 'My Orders',
    MY_PAYMENTS: 'My Payments',
    QR_PAY: 'QR Pay',
    BALANCE_HISTORY: 'Balance History',
    ITEMS_ARE_CURRENTLY_NOT_AVAILABLE: 'Items are currently not available',
    SEND_VIRTUAL_CURRENCY: 'Send Virtual Currency',
    SEARCH: 'Search',
    CANCEL: 'Cancel',
    TRY_AGAIN: 'TRY AGAIN',
    GO_BACK: 'GO BACK',
    QR_CODE_UNAVAILABLE: 'QR Code Unavailable',
    QR_CODE_LOAD_AGAIN: 'Your QR Code could not be loaded. Please try again.',
    UNKNOWN_ERROR: 'Unknown error',
    OOPS_NOT_RECOGNIZED: 'Oops, this item was not recognized',
    PURCHASE: 'Purchase',
    CREDIT: 'Credit',
    DEBIT: 'Debit',
    CANCEL_BEFORE: 'Cancel before',
    PREORDER: 'Preorder',
    CARD_ON_FILE_MAY_BE_REQUIRED: 'A card on file may be required at some locations',
    UPDATE_PAYMENT: 'UPDATE PAYMENT',
    AFFILIATIONS_NOT_REFRESHABLE: 'Sorry, profile information could not be updated.  Please try again in',
    AFFILIATIONS_PROFILE_NOT_REFRESHABLE: 'There was an error retrieving profile information.  Please try again in',
    MINUTES: 'minutes',
    PICK_UP_YOUR_ORDER_AT: 'Pick up your order at',
    REFRESH_BADGE_INFORMATION: 'Refresh Badge Information',
    LAST_UPDATED: 'Last updated',
    APPLYING_DISCOUNTS: 'Applying Eligible Discounts',
    SAVED_PAYMENTS: 'Saved Payments',
    SAVED_CARD: 'Saved Card',
    PAY_NOW: 'Pay Now',
    S4PAYMENTS_TRANSACTION_ERROR: 'Payment could not be completed, try again or try another card.',
    SINGLE_USE_QR_CODE: 'This single use QR code',
    LAST_GENERATED: 'Last generated',
    QR_CODE_UPDATED: 'QR code updated',
    FAILED_TO_UPDATE_QR_CODE: 'Sorry there was an issue updating the QR code. Please try again later',
    AN: 'An',
    ACCOUNT_CANNOT_BE_EMPTY: 'Account cannot be empty',
    TICKETS_NOT_LOADED: "Some tickets could not be loaded. Please try again",
    SELECT_ONE_TICKET: "One ticket must be selected.",
    YOUR_NEXT_EVENT: "Your Next Event",
    LOADED: "LOADED AT",
    TODAY_AT: "Today At",
    PRESENT_QR_CODE_TO_APPLY_DISCOUNTS: 'Apply eligible discounts and pay with CASH_TYPE, where accepted',
    SEARCH_TICKET_NUMBER: "Search by Ticket Number",
    ADMIN_USE: "For Admin Use Only",
    THERE_WAS_AN_ISSUE_SIGNING_IN: "There was an issue signing in. Please try again.",
    AUTHORIZATION_DECLINED: 'Authorization Declined',
    CARD_NOT_PRE_AUTHORIZED: 'Your card on file could not be pre-authorized.',
    MANAGE_CARDS: 'MANAGE CARDS',
    VIA: 'via'
  }
}
export default ESData
