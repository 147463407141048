import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VNSnackbar } from '../components/VNSnackbar'
import { getSnackbarSystemData } from '../Selectors'
import { resetSnackbarSystemData } from '../ActionCreators'

export const VNSnackbarSystem = (props) => {

  const dispatch = useDispatch()

  const snackbarData = useSelector(state => getSnackbarSystemData(state))

  return (
    <VNSnackbar   cssProps={snackbarData.has('cssProps') ? snackbarData.get('cssProps') : null} 
                  autoHideDuration={snackbarData.get('autoHideDuration') !== undefined ? snackbarData.get('autoHideDuration') : undefined}
                  alert={snackbarData.has('alert') ? snackbarData.get('alert') : null} 
                  action={snackbarData.get('action') ? snackbarData.get('action') : null} 
                  message={snackbarData.get('message')} 
                  open={snackbarData.has('open') ? snackbarData.get('open') : false} 
                  onHandleClose={() => {dispatch(resetSnackbarSystemData())}} />
  )
}
