import React, { useEffect, useSelector } from 'react'
import { useDispatch } from 'react-redux'

import Box from "@material-ui/core/Box"

import { setWebSDKDataReceive } from '../ActionCreators'
import VNDataBridgeReceiveType from '../bridgeCalls/VNWebSDKDataReceive'

import { setOpenBadgeDialog, setOpenPaymentUpdatesDialog } from '../../VNDialogs/ActionCreators'

export const VNWebSDKDataBridge = (props) => {

  // HOOKS
  const dispatch = useDispatch()

  // SELECTORS
  // const badgeOpen = useSelector(state => getBadgeDialogOpen(state))

  // initialize the WEBSDK Bridge on mount
  useEffect(() => {

    window.VENUENEXT_WEBSDK = {
      DATA_BRIDGE: dataBridgeReceive
    }

  }, [props.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  // LOCAL FUNCS

  // called when WebSDK wants to make calls into react, this is open to the public
  // base64Parameters : String - This is the parameters being passed from native that is base64 encoded
  const dataBridgeReceive = (base64Parameters) => {

    // decode and parse the payload back to a JSON obj
    var decodedParams = window.atob(base64Parameters)
    var payload = JSON.parse(decodedParams)

    // Only on this bridge data method do we not set the receive data because this action
    // does not need to store data. This simply signals OrderNext to close either the badge
    // or payments dialogs, which is detected natively when tracking SDK_MODAL_OPEN send events
    if (payload.method && payload.method === VNDataBridgeReceiveType.SDK_GO_BACK) {
      dispatch(setOpenBadgeDialog(false))
      dispatch(setOpenPaymentUpdatesDialog(false))
      return
    }

    dispatch(setWebSDKDataReceive(payload))
  }

  return (
    <Box {...props}>
      {props.children}
    </Box>
  )
}
