import humps from  'humps'
import { orderTotalMapper } from './orderTotal'

export function orderMapper({
  items, menuId, paymentMethodNonce, payments,
  name, email, orderLocation, orderLocationUuid,
  tipAmountInCents, uuid, fulfillmentDate,
  fulfillmentSlot, sourceDeviceUuid, orderNumber,
   affiliations, bundleId, standUuid, preorderSaleEvent }) {
  const orderObject = {
    ...orderTotalMapper(items, menuId, null, preorderSaleEvent),
    uuid: uuid,
    order_number: orderNumber,
    email: email,
    user_name: name,
    seat: orderLocation,
    orderLocationUuid,
    paymentMethodNonce,
    payments,
    tip_amount_in_cents: tipAmountInCents,
    webOrder: true,
    stand_uuid: standUuid,
    ...(fulfillmentDate && fulfillmentSlot ? {
      fulfillmentDate: fulfillmentDate,
      fulfillmentSlot: fulfillmentSlot
    } : {}),
    ...sourceDeviceUuid ? { sourceDeviceUuid : sourceDeviceUuid } : {},
    ...affiliations ? { affiliations : affiliations } : {},
    ...bundleId ? { bundleId: bundleId} : {}
  }

  return humps.decamelizeKeys(orderObject)
}

export default orderMapper
