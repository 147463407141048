import React from 'react'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import './Section.scss'

const Section = ({ name, loading, children, ...props }) => {
  const className = classNames('section', props.className)

  return (
    <div {...props} className={className}>
      {loading && <h3><Skeleton width='40%' /></h3>}
      {!loading && name && <h3>{name}</h3>}
      {children}
    </div>
  )
}

export default Section
