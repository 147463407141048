export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION'
export const UPDATE_USER_LOCATION_CONFIRMED = 'UPDATE_USER_LOCATION_CONFIRMED'
export const UPDATE_USER_AGE_VERIFICATION = 'UPDATE_USER_AGE_VERIFICATION'

export const updateUserLocation = ({ section, sectionIsSuite, row, seat, orderLocationUuid, menuId }) => ({
  type: UPDATE_USER_LOCATION,
  payload: {
    section,
    sectionIsSuite,
    row,
    seat,
    orderLocationUuid,
    menuId,
  }
})

export const updateUserLocationConfirmed = (confirmed) => ({
  type: UPDATE_USER_LOCATION_CONFIRMED,
  payload: { confirmed },
})

export const updateUserAgeVerification = (verified) => ({
  type: UPDATE_USER_AGE_VERIFICATION,
  payload: { verified },
})
