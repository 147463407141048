import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Cancel from '@material-ui/icons/Cancel'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Typography
} from '@material-ui/core'

import { VNModal } from '../../VNComponents/VNModal'
import { VNBottomStickyButton } from '../components/VNBottomStickyButton'

import {
  loadSections, loadRows,
  loadSeats, loadMenusForSeat,
  loadSectionsSucceeded, loadRowsSucceeded,
  loadSeatsSucceeded
} from '../../actions/seat'
import { updateUserLocation, updateUserLocationConfirmed } from '../../actions/user'
import { setSectionRowSeat } from '../../VNWebSDK/ActionCreators'

import { getUserLocation, getIsSeatValid } from '../../selectors/user'
import {
  getAvailableSections, getAvailableRows,
  getAvailableSeats, getAvailableSectionsLoading,
  getAvailableRowsLoading, getAvailableSeatsLoading
} from '../../selectors/seat'
import { getSectionRowSeat } from '../../VNWebSDK/Selectors'

import { useStateWithCompletion } from '../../VNUtil/hooks/useStateWithCompletion'
import { history } from '../../App/store/configureStore'
import { get, isEmpty } from 'lodash'

import { useTranslation } from 'react-i18next'
import isArray from 'lodash/isArray'

const useStyles = makeStyles(theme => ({
  root: {

  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 30
  },
  closeButton: {
    padding: 0
  },
  selectContainer: {
    marginBottom: 15
  },
  formControl: {
    width: '100%',
    marginBottom: 15
  }
}))

export const VNSeatPicker = props => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const theme = useTheme()

  const { standId, menuId } = props

  //#region SELECTORS
  const userLocation = useSelector(state => getUserLocation(state))
  const availableSections = useSelector(state => getAvailableSections(state))
  const availableRows = useSelector(state => getAvailableRows(state))
  const availableSeats = useSelector(state => getAvailableSeats(state))
  const sectionsLoading = useSelector(state => getAvailableSectionsLoading(state))
  const rowsLoading = useSelector(state => getAvailableRowsLoading(state))
  const seatsLoading = useSelector(state => getAvailableSeatsLoading(state))
  const isSeatValid = useSelector(state => getIsSeatValid(state))
  const webSDKSectionRowSeat = useSelector(state => getSectionRowSeat(state))
  //#endregion

  //#region REFS
  //#endregion REFS

  //#region LOCAL STATE
  const [shouldDisableLoading, setShouldDisableLoading] = useState(!!get(userLocation, 'section', ''))
  const [section, setSection] = useStateWithCompletion('')
  const [sectionIsSuite, setSectionIsSuite] = useState(false)
  const [row, setRow] = useStateWithCompletion('')
  const [seat, setSeat] = useStateWithCompletion('')
  const [orderLocationUuid, setOrderLocationUuid] = useState(userLocation.orderLocationUuid)
  const [shouldSetSeatUuid, setShouldSetSeatUuid] = useState(false)
  const [prefilledSection, setPrefilledSection] = useState(false)
  const [prefilledRow, setPrefilledRow] = useState(false)
  const [prefilledSeat, setPrefilledSeat] = useState(false)
  const [doNotAutoUpdateRowAndSeat, setDoNotAutoUpdateRowAndSeat] = useState(false)

  const userHasLocation = !!orderLocationUuid
  const redirectTo = props?.location?.state?.redirect
  const onCloseModal = props.onCloseModal
  //#endregion

  //#region USE EFFECTS
  useEffect(() => {
    if (userHasLocation) {
      dispatch(loadMenusForSeat({ standId, orderLocationUuid }))
    }

    dispatch(loadSections({ standId }))
    fetchOrderLocationUuid()

    return () => {
      dispatch(loadSectionsSucceeded([]))
      dispatch(loadRowsSucceeded([]))
      dispatch(loadSeatsSucceeded([]))
    }
  }, [])

  useEffect(() => {
    if (sectionIsSuite && availableSections.length > 0 && !orderLocationUuid && section) {
      updateSection()
    }
  }, [availableSections, sectionIsSuite, orderLocationUuid, seatsLoading])

  useEffect(() => {
    if (shouldSetSeatUuid && !seatsLoading) {
      if (orderLocationUuid) {
        setShouldSetSeatUuid(false)
        dispatch(loadMenusForSeat({ standId, orderLocationUuid }))
      }
    }
  }, [shouldSetSeatUuid, seatsLoading])

  // automatically update section, row and seat from existing userLocation
  // this also handle section, row and seat obtained from query parameters
  useEffect(() => {
    if (!isEmpty(availableSections) && isEmpty(section)) {
      updateSection(userLocation.section)
    }

    if (!doNotAutoUpdateRowAndSeat && !isEmpty(availableRows) && isEmpty(row) && availableRows.includes(userLocation.row)) {
      updateRow(userLocation.row)
    }

    if (!doNotAutoUpdateRowAndSeat && !isEmpty(availableSeats) && isEmpty(seat) && availableSeats.find(s => s.seat === userLocation.seat)) {
      updateSeat(userLocation.seat)
    }
  }, [availableSections, availableRows, availableSeats])
  //#endregion

  //#region FUNCTIONS
  const fetchOrderLocationUuid = () => {
    if (section && row && seat && !userHasLocation) {
      const testSection = availableSections.find(s => s['name'] === section) || {}
      const aisleIds = testSection.aisle_ids || []

      dispatch(loadSections({ standId }))
      dispatch(loadRows({ standId, section, aisleIds }))
      dispatch(loadSeats({ standId, section, row, aisleIds }))
      setShouldSetSeatUuid(true)
    }
  }

  const updateSection = (value, updatedByUser) => {
    let _section = null

    _section = availableSections.find(s => s.name === value)

    if (_section?.is_suite) {
      setOrderLocationUuid(_section.location_uuid)
      setSectionIsSuite(true)
    } else {
      setOrderLocationUuid('')
      setSectionIsSuite(false)
    }

    if (_section !== undefined) {

      // if user has chosen an existing section, don't auto update row and seat
      if (updatedByUser && !doNotAutoUpdateRowAndSeat) {
        setDoNotAutoUpdateRowAndSeat(true)
      }

      setSection(value, () => {
        const testSection = availableSections.find(s => s.name === value) || {}
        const aisleIds = testSection.aisle_ids || []

        setRow('')
        setSeat('')

        if (!_section?.is_suite) {
          dispatch(loadRows({ standId, section: value, aisleIds }))
        }
      })
    }
  }

  const updateRow = (value) => {
    if (!availableRows?.includes(value)) {
      return
    }

    const testSection = availableSections.find(s => s['name'] === section) || {}
    const aisleIds = testSection.aisle_ids || []

    setSeat('')

    setRow(value, () => {
      dispatch(loadSeats({ standId, section, row: value, aisleIds}))
    })
  }

  const updateSeat = (value) => {
    const seatUuid = availableSeats.find(seat => seat.seat === value)?.uuid
    setOrderLocationUuid(seatUuid)

    setSeat(value)
  }

  const getFormData = () => {
    const suiteText = t('NOT_NEEDED_FOR_SUITES')

    let sectionEmptyLabel = sectionsLoading ? `${t('LOADING')}...` : t('SELECT_YOUR_SECTION_OR_SUITE')
    if (shouldDisableLoading && section) {
      sectionEmptyLabel = t('SELECT_YOUR_SECTION_OR_SUITE')
    }

    let rowEmptyLabel = rowsLoading ? `${t('LOADING')}...` : t('SELECT_YOUR_ROW')
    if (shouldDisableLoading && row) {
      rowEmptyLabel = t('SELECT_YOUR_ROW')
    }

    let seatEmptyLabel = seatsLoading ? `${t('LOADING')}...` : t('SELECT_YOUR_SEAT')
    if (shouldDisableLoading && seat) {
      seatEmptyLabel = t('SELECT_YOUR_SEAT')
    }

    return [
      {
        label: sectionEmptyLabel,
        field: 'section',
        options: availableSections,
        disabled: sectionsLoading,
      },
      {
        label: sectionIsSuite ? suiteText : rowEmptyLabel,
        field: 'row',
        options: availableRows,
        disabled: isEmpty(section) || rowsLoading || sectionIsSuite,
      },
      {
        label: sectionIsSuite ? suiteText : seatEmptyLabel,
        field: 'seat',
        options: availableSeats,
        disabled: isEmpty(row) || seatsLoading || sectionIsSuite,
      },
    ]
  }

  const isFormValid = () => {
    const fieldsEntered = sectionIsSuite || (!isEmpty(section) && !isEmpty(row) && !isEmpty(seat))
    const notLoading = !sectionsLoading && !rowsLoading && !seatsLoading

    return fieldsEntered && notLoading
  }
  //#endregion

  //#region HANDLERS
  const handleCloseModal = () => {
    const backRoute = props.backRoute

    if (backRoute) {
      history.replace(backRoute)
      return
    }

    if (onCloseModal) {
      onCloseModal()
      return
    }

    history.replace(`/${standId}/menu/${menuId}`)
  }

  const handleOnSave = () => {

    dispatch(setSectionRowSeat(null))
    dispatch(updateUserLocation({ section, sectionIsSuite, row, seat, orderLocationUuid, menuId }))
    dispatch(updateUserLocationConfirmed(true))

    if (onCloseModal) {
      onCloseModal()
      return
    }

    if (redirectTo) {
      history.replace(redirectTo)
    } else {
      history.replace(`/${standId}/menu/${menuId}`)
    }
  }
  //#endregion

  //#region RENDERERS
  const displayCloseButton = () => {
    if(props?.location?.removeCloseButton) {
      return
    }
    return (
      <IconButton
        classes={{ root: classes.closeButton }}
        aria-label={"close"}
        onClick={handleCloseModal}
      >
        <Cancel />
      </IconButton>
    )
  }

  const displayHeader = () => {
    return (
      <Fragment>
        <Box className={classes.headingContainer}>
          <Typography variant="h1">{t('DELIVERY_LOCATION')}</Typography>
          {displayCloseButton()}
        </Box>
      </Fragment>
    )
  }

  const displayOptionRow = (option) => {
    const value = option.name || option.seat || option
    const key = option.uuid || option.name || option

    return <option key={key} value={value}>{value}</option>
  }

  const displayFormRow = ({ label, field, options = [], disabled }, index) => {
    let safeOptions = options || []

    if (!isArray(options)) safeOptions = []

    const fieldValue = () => {
      if (field === 'section') {
        return section
      }
      if (field === 'row') {
        return row
      }
      if (field === 'seat') {
        return seat
      }
    }

    const handleOnSelect = () => {
      if (field === 'section') {
        return updateSection
      }
      if (field === 'row') {
        return updateRow
      }
      if (field === 'seat') {
        return updateSeat
      }
    }

    // Automatically fill out section/row/seat data from SDK data passed through
    // with verification
    // NOTE: To have this work without hiccups, have removed state.seat from redux-persist,
    // currently can't see a need to keep that data cached.
    if (!webSDKSectionRowSeat.isEmpty()) {
      if (field === 'section' && !sectionsLoading) {
        const validSection = availableSections.find(s => s.name === webSDKSectionRowSeat.get('section'))
        if (validSection && !prefilledSection) {
          setPrefilledSection(true)
          updateSection(validSection.name)
        }
      }
      if (field === 'row' && !rowsLoading && !isEmpty(section)) {
        const validRow = availableRows.find(r => r === webSDKSectionRowSeat.get('row'))
        if (validRow && !prefilledRow) {
          setPrefilledRow(true)
          updateRow(validRow)
        }
      }
      if (field === 'seat' && !seatsLoading && !isEmpty(row)) {
        const validSeat = availableSeats.find(s => s.seat === webSDKSectionRowSeat.get('seat'))
        if (validSeat && !prefilledSeat) {
          setPrefilledSeat(true)
          updateSeat(validSeat.seat)
        }
      }
    }

    return (
      <FormControl variant='outlined' className={classes.formControl} key={`SeatPickerSelect-${index}`}>
        <InputLabel>{label}</InputLabel>
        <Select
          native
          label={label}
          value={fieldValue()}
          displayEmpty
          disabled={disabled}
          onChange={(event) => { handleOnSelect()(event.target.value, true)}}
        >
          {<option disabled value={''}>{''}</option>}
          {safeOptions.map(item => displayOptionRow(item))}
        </Select>
      </FormControl>
    )
  }
  //#endregion

  return (
    <VNModal
      onCloseModal={handleCloseModal}
      shouldNotCloseOnOverlayClick={props?.location?.removeCloseButton}
    >
      {displayHeader()}
      <Box className={classes.selectContainer}>
        {getFormData().map(displayFormRow)}
      </Box>
      <VNBottomStickyButton
        text={t('SAVE')}
        disabled={!isFormValid() || isSeatValid === 'waiting'}
        onClick={handleOnSave}/>
    </VNModal>
  )
}
