import humps from  'humps'

export default class TaxRules {

  /**
   * lineItems : an array of line item objects with all the accoutrements of a line item as given
   *     by the VenueNext ordering system
   * defaultName : the default display name for a tax
   * returns : a defined, non-null object of tax rule objects indexed by the display name
   */
  static allItemTaxRules = (lineItems, defaultName) => {
    if (!lineItems?.length) {
      return {}
    }

    const allItemTaxRules = {}
    for (const item of lineItems) {
      const taxRules = item.attributes.taxRules || item.attributes.tax_rules

      if (!taxRules?.length) {
        continue
      }

      // TODO(mkramerl): This is ridiculous.
      for (const itemTax of taxRules) {
        const itemTaxRule = humps.camelizeKeys(itemTax)
        const taxName = itemTaxRule.displayName || defaultName
        const currentTax = allItemTaxRules[taxName]

        // From a customer perspective, the uniqueness of a tax rule is driven by its display
        // metadata. The only thing that needs to change should be the amount.
        if (!!allItemTaxRules[taxName]) {
          allItemTaxRules[taxName].taxAmountInCents += itemTaxRule.taxAmountInCents || 0
        } else {
          allItemTaxRules[taxName] = itemTaxRule
        }
      }
    }

    return allItemTaxRules
  }
}
