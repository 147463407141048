import * as ActionTypes from './ActionTypes'
import { List, Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'cached_info': Map({}),
    'shift4_token': null
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNOTP_SET_CACHED_PAYMENT_INFO:
      return state.set('cached_info', Map(action.blob))
    case ActionTypes.VNOTP_SET_SHIFT4_TOKENIZATION:
      return state.set('shift4_token', action.shift4.token)
    default:
      return state
  }
}

export default reducer
