import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import {
  makeStyles, Chip,
  Box, Typography,
  useTheme
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginRight: '8px',
      marginBottom: '8px'
    }
  },
  unselected: {
    borderRadius: '5px',
    border: '1px solid transparent',
    backgroundColor: theme.custom.colors.filterChipBackgroundGrey,
    '&:focus, &:hover, &:active': {
      backgroundColor: theme.custom.colors.filterChipBackgroundGrey
    },
    '& > .MuiChip-label > p': {
      color: theme.custom.colors.black
    }
  },
  selected: {
    border: '1px solid ' + theme.custom.colors.filterActiveBlue,
    borderRadius: '5px',
    backgroundColor: theme.custom.colors.filterActiveBackgroundBlue,
    '&:focus, &:hover, &:active': {
      backgroundColor: theme.custom.colors.filterActiveBackgroundBlue
    },
    '& > .MuiChip-label > p': {
      color: theme.custom.colors.filterActiveBlue
    }
  }
}))

const VNChipGroup = forwardRef(({ value, defaultOptions, options, type, radio, deselectRadio, ...props}, ref) => {

  const classes = useStyles()
  const theme = useTheme()

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    getSelectedValues() {
      return selectedValues
    },

    reset() {
      setSelectedValues([])
    }

  }))

  const getSanitizedDefaultOptions = () => {
    let sanitizedOptions = []
    const optionsInLowerCase = options.map(o => o.toLowerCase())

    defaultOptions.forEach(defaultOption => {
      let index = optionsInLowerCase.findIndex(o => o === defaultOption.toLowerCase())
      if (index !== -1) {
        sanitizedOptions.push(options[index])
      }
    })

    return sanitizedOptions
  }

  //#region REFS
  //#endregion

  //#region SELECTORS
  //#endregion

  //#region LOCAL STATE
  const [selectedValues, setSelectedValues] = useState(defaultOptions ? getSanitizedDefaultOptions() : [])
  //#endregion

  //#region USE EFFECTS
  //#endregion

  //#region FUNCTIONS
  //#endregion

  //#region HANDLERS
  const handleClick = (clickedValue) => {
    if (radio) {
      if (deselectRadio) {
        if (clickedValue === selectedValues[0]) {
          setSelectedValues([])
          return
        }
      }
      setSelectedValues([clickedValue])
      return
    }

    if (selectedValues.find((e) => e === clickedValue )) {
      const index = selectedValues.findIndex(e => e === clickedValue)
      let arr = [...selectedValues]
      arr.splice(index, 1)
      setSelectedValues(arr)
    } else {
      setSelectedValues([...selectedValues, clickedValue])
    }
  }
  //#endregion

  //#region RENDERERS
  //#endregion

  return (
    <Box className={classes.root}>
      {options && options.map((option, i) => (
        <Chip
          key={i}
          classes={{
            root: selectedValues.find(e => e === option) ?  classes.selected : classes.unselected
          }}
          label={
            <Typography>{option}</Typography>
          }
          clickable
          onClick={() => handleClick(option)}
        />
      ))}
    </Box>
  )
})

export default VNChipGroup
