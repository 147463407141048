import * as ActionTypes from './ActionTypes'
import { apiGetPaymentToken, apiGetCachedPaymentInfo } from './Api'
import Money from '../utils/money'

/**
 * Retrieve cached info from Order Next for Order total, Order Data, as well
 * as all relevant cached info to complete this order in VNOTP
 * @param {string} url - URL passed in from cached info
 * @returns
 */
export const retrieveCachedPaymentInfo = (url) => {
  return (dispatch) => {
    apiGetCachedPaymentInfo(url).then(response => {
      console.log(JSON.parse(response.data.blob))
      dispatch({
        type: ActionTypes.VNOTP_SET_CACHED_PAYMENT_INFO,
        blob: JSON.parse(response.data.blob)
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

/**
 * Retrieve Shift4 Payment token with the relevant stand, total, currency code,
 * user token and org.
 * @param {object} cachedInfo - Cached info from OrderNext
 * @returns
 */
export const retrieveShift4PaymentToken = (cachedInfo) => {
  const c = cachedInfo
  const orderUuid = c.get('orderId')
  const total = Money.centsToFloat(c.get('total'))
  return (dispatch) => {
    apiGetPaymentToken(c.get('standId'), orderUuid, total, c.get('currencyCode'), c.get('usertoken'), c.get('org')).then(response => {
      dispatch({
        type: ActionTypes.VNOTP_SET_SHIFT4_TOKENIZATION,
        shift4: {
          token: response.data.token
        }
      })
    }).catch(error => {
      console.log(error)
    })
  }
}
