const ajax = options => {

  return new Promise((resolve, reject) => {

    let xhr = new XMLHttpRequest()

    xhr.open(options.type, options.url, true)

    for (const i in options.headers) {
      if (Object.prototype.hasOwnProperty.call(options.headers, i)) {
        xhr.setRequestHeader(i, options.headers[i])
      }
    }

    xhr.onerror = function () {
      reject({
        response: xhr.response,
        status: xhr.status
      })
    };

    xhr.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        resolve({
          response: xhr.response,
          status: xhr.status
        })
      }
    }

    if (options.isFormData) {
      let urlEncodedData = ''
      let urlEncodedDataPairs = []

      for (const name in options.data) {
        if (Object.prototype.hasOwnProperty.call(options.data, name)) {
          urlEncodedDataPairs.push(`${encodeURIComponent(name)}=${encodeURIComponent(options.data[name])}`)
        }
      }

      urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+')

      xhr.send(urlEncodedData)

    } else {
      xhr.send(JSON.stringify(options.data))
    }
  })
}

module.exports = ajax
