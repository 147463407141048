const jwtDecode = (token) => {
  const base64Data = token.split('.')[1]
  const decodedValue = atob(base64Data)
  const json = JSON.parse(decodedValue)
  return json
}

module.exports = {
  jwtDecode: jwtDecode
}
