/**
 * Takes any string and returns the first letter of each word capitalized
 * @param {String} val - The string you want to title case
 */
export const toTitleCase = (val) => {
  return val.replace(/(^\w{1})|(\s+\w{1})/g, match => match.toUpperCase());
}

/**
 * Given a string, it will tell you if its a 0 representation or not
 * @param {String} s - String to test
 */
export const isItZero = (s) => {
  if (s && (s === '0' || s === '0.00' )) {
    return true
  }
  return false
}

/**
 * 
 * @param {*} str 
 * @returns 
 */
export const hexToBase64 = (str) => {
  return btoa(str.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join(""))
}

/**
 * 
 * @param {*} str 
 * @returns 
 */
export const base64ToHex = (str) => {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += (hex.length === 2 ? hex : '0' + hex);
  }
  return result.toUpperCase();
}