/* eslint-disable valid-jsdoc */

const LANGUAGES = require('./index')

/**
 * Check to see if we support the language passed in.
 * @param {String} lang - The langauge to check to see if we support
 */
const checkLanguageSupport = (lang) => {
  switch (lang) {
    case 'en-us':
      return true
    default:
      return false
  }
}

/**
 * Get the appropriate language file from the language that was set.
 */
const getLanguageFile = (languageToCheck) => {
  if (languageToCheck === 'en-us') {
    return LANGUAGES.EN_US
  }
}

module.exports = {
  checkLanguageSupport: checkLanguageSupport,
  getLanguageFile: getLanguageFile
}
