/* eslint-disable no-undef */
/* eslint-disable max-len */
const components = require('../components/index')
const ajax = require('../utils/ajax')
const pidgeon = require('../pidgeon/index')
const PIDGEON_KEYS = require('../pidgeon/keys')
const svgs = require('../svgs/index')
const langaugeUtils = require('../languages/utils')
const styles = require('../styles/index')

let decodedToken
let session
let applePayOptions
let language
let preValidation

const attachApplePayScript = () => {
  const applePayScript = components.createElement('script')
  applePayScript.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
  document.head.appendChild(applePayScript)
}

const getApplePaySession = (validationURL) => {
  console.log('Apple Pay - getApplePaySession')
  console.log(`Validation URL: ${validationURL}`)
  return new Promise((resolve, reject) => {
    ajax({
      type: 'POST',
      url: `${decodedToken.i4mServer}/index.cfm?fuseaction=ws.applePaySession`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      isFormData: true,
      data: {
        validationURL: validationURL,
        i4go_accessBlock: decodedToken.accessBlock
      }
    }).then((responseObj) => {

      // i4go has responsed, lets see what they have to say

      console.log('Apple Pay - getApplePaySession - RESPONSE')
      console.log(responseObj)

      // extract the response to send only what we want
      if (responseObj.status === 200) {

        const tempResponse = JSON.parse(responseObj.response)

        console.log('*** TEMP RESPONSE ***')
        console.log(tempResponse)

        resolve(tempResponse)

      } else {
        reject(responseObj.status)
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

const postApplePayToken = (payment) => {
  console.log('Apple Pay - postApplePayToken')
  console.log(payment)
  return new Promise((resolve, reject) => {
    console.log(payment)
    ajax({
      type: 'POST',
      url: `${decodedToken.i4goServer}/index.cfm?fuseaction=api.jsonPostCardEntry`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      isFormData: true,
      data: {
        i4go_accessBlock: decodedToken.accessBlock,
        i4go_applePayToken: JSON.stringify(payment)
      }
    }).then((responseObj) => {

      console.log(responseObj)

      // i4go has responsed, lets see what they have to say
      let response

      // extract the response to send only what we want
      if (responseObj.status === 200) {

        const tempResponse = JSON.parse(responseObj.response)

        console.log('*** TEMP RESPONSE ***')
        console.log(tempResponse)
        console.log(tempResponse.i4go_responsetext)
        resolve(tempResponse)
      } else {
        reject(responseObj.status)
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

const createBaseCard = () => {

  const style = {
    'border-radius': '6px',
    'height': '48px',
    'display': 'flex',
    'align-items': 'center',
    'background-color': styles.COLORS.lightGrey,
    'border': `1px solid ${styles.COLORS.buttonBorderGrey}`
  }

  return components.createElement('div', null, style)
}

const createSDKModeApplePayButton = () => {
  const card = createBaseCard()

  const icon = svgs.applePayIcon()
  icon.style.padding = '14px'
  icon.style.position = 'absolute'

  const typography = components.createTypography(null, langaugeUtils.getLanguageFile(language).apple_pay, { 'flex-grow': 1, 'text-align': 'center '}, 'subtitle2')

  card.appendChild(icon)
  card.appendChild(typography)

  return card
}

const initialize = (pApplePayOptions, pDecodedToken, pSdkMode, pLanguage, pPreValidation, hasBeenInitialized) => {

  applePayOptions = pApplePayOptions
  decodedToken = pDecodedToken
  sdkMode = pSdkMode
  language = pLanguage
  preValidation = pPreValidation

  if (!hasBeenInitialized) {
    attachApplePayScript()
  }

  const root = components.createElement('div', 'venuenext-i4go-drop-in-wallet-apple-pay')
  let button

  if (sdkMode && sdkMode.enabled) {

    button = createSDKModeApplePayButton()

    button.addEventListener('click', () => {
      console.log('createSDKModeApplePayButton Clicked')
      if (sdkMode.event) {
        sdkMode.event()
      }
    })

  } else {

    button = components.createElement('apple-pay-button', null, {
      '-webkit-appearance': '-apple-pay-button',
      width: '100%'
    }, {
      buttonstyle: 'black',
      type: 'buy',
      locale: 'en'
    })

    button.addEventListener('click', () => {

      if (preValidation && !preValidation()) {
        console.log('Apple Pay - Pre Validation Failed. Will not continue.')
        return
      }

      pidgeon.send(PIDGEON_KEYS.START_WALLET_PROCESS, {
        'wallet': 'apple-pay'
      })

      let request = {
        countryCode: applePayOptions.countryCode,
        currencyCode: applePayOptions.currencyCode ? applePayOptions.currencyCode : 'USD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'],
        total: { label: applePayOptions.label, amount: applePayOptions.amount }
      }

      console.log('REQUEST FOR APPLE PAY')
      console.log(request)

      if (window.ApplePaySession) {
        console.log('Found Apple Pay Session Object')
      }

      if (ApplePaySession.supportsVersion(3)) {
        console.log('Apple Pay Session supports version 3')
      }

      if (ApplePaySession.canMakePayments()) {
        console.log('Apple Pay Session can make payments')
      }

      if (window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()) {
        session = new ApplePaySession(3, request)
        session.begin()

        console.log('Apple Pay - Start Session')

        session.onvalidatemerchant = function (event) {
          console.log('Appla Pay - onvalidatemerchant')
          const validationURL = event.validationURL
          getApplePaySession(validationURL).then((response) => {
            console.log('getApplePaySession - PROMISE THEN')
            console.log(response)
            session.completeMerchantValidation(response)
            session.onpaymentauthorized = (authEvent) => {
              console.log('getApplePaySession - onpaymentauthorized')
              console.log(authEvent)
              // ready to send this to Shift4
              postApplePayToken(authEvent.payment).then((applePayTokenResponse) => {
                console.log('postApplePayToken - PROMISE THEN')
                console.log(applePayTokenResponse)
                session.completePayment(session.STATUS_SUCCESS)
                pidgeon.send(PIDGEON_KEYS.WALLET_PAYMENT, {
                  'wallet': 'apple-pay',
                  'token': applePayTokenResponse
                })
              }).catch((e) => {
                session.completePayment(session.STATUS_FAILURE)
                pidgeon.send(PIDGEON_KEYS.WALLET_PAYMENT, {
                  'wallet': 'apple-pay',
                  'token': applePayTokenResponse
                })
              })
            }
          })
        }

        session.oncancel = function () {
          console.log('Apple Pay - Session Cancel')
          pidgeon.send(PIDGEON_KEYS.WALLET_CHANGE, {
            'wallet': 'apple-pay',
            'session': 'canceled'
          })
        }

      } else {
        console.log('Apple Pay Session is not supported')
        pidgeon.send(PIDGEON_KEYS.WALLET_CHANGE, {
          'wallet': 'apple-pay',
          'session': 'not_supported'
        })
      }
    })
  }

  root.appendChild(button)

  return root
}

module.exports = {
  initialize: initialize
}
