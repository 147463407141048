export const mockApiFetchAllRevenueCenters = () => {
  return new Promise((resolve) => {
    resolve({ data:
      {
        "stands": [{
          "uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
          "venue": {
            "display_name": "Shift4 Stadium",
            "gps": {
              "lat": "37.4053",
              "long": "-121.97619",
              "distance_threshold": "100000"
            },
            "organization_name": "arena",
            "uuid": "1c7a17e6-141a-4591-a59b-10c8f58d5584",
            "address": null,
            "service_charge_legal": "Please note that all food and beverage items are subject to an 20% service charge plus applicable 10% tax. This service charge is a tip/gratuity and is distributed to service employees. Additional payment for tips or gratuity for service, if any, is voluntary and at your discretion."
          },
          "name": "Express Pickup Test",
          "section": null,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "product_type": "Food",
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }, {
            "name": "POS",
            "display_name": "POS"
          }, {
            "name": "Kiosk",
            "display_name": "Kiosk"
          }, {
            "name": "QuickPay",
            "display_name": "Quick Pay"
          }],
          "organization_sort_order": null,
          "venue_sort_order": null,
          "alcohol_warning_message": null,
          "locale": "en-US",
          "description": "TEST DESCRIPTION",
          "member_affiliations": {
            "season_ticket_holder": []
          },
          "filter_data": {
            "location": ["Section 100", "Left Field", "Section 101", "Section 102"],
            "cuisine": ["American", "Beer"]
          },
          "allow_cash_payments_on_pos": true,
          "custom_payment_types": [],
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-6369f382-4e0d-45ec-921d-f5a90338fe5f.png",
            "list": null
          }
        }, {
          "uuid": "74d5b469-d3ce-4901-b562-75f94642875c",
          "venue": {
            "display_name": "Shift4 Stadium",
            "gps": {
              "lat": "37.4053",
              "long": "-121.97619",
              "distance_threshold": "100000"
            },
            "organization_name": "arena",
            "uuid": "1c7a17e6-141a-4591-a59b-10c8f58d5584",
            "address": null,
            "service_charge_legal": "Please note that all food and beverage items are subject to an 20% service charge plus applicable 10% tax. This service charge is a tip/gratuity and is distributed to service employees. Additional payment for tips or gratuity for service, if any, is voluntary and at your discretion."
          },
          "name": "Frank's Franks",
          "section": null,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "product_type": "Food",
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }, {
            "name": "Delivery",
            "display_name": "In-Seat Delivery"
          }, {
            "name": "POS",
            "display_name": "POS"
          }, {
            "name": "Kiosk",
            "display_name": "Kiosk"
          }],
          "organization_sort_order": null,
          "venue_sort_order": null,
          "alcohol_warning_message": null,
          "locale": "en-US",
          "description": "TEST DESCRIPTION",
          "member_affiliations": {
            "season_ticket_holder": []
          },
          "filter_data": {
            "location": ["Section 100"],
            "cuisine": ["Yummy"]
          },
          "allow_cash_payments_on_pos": true,
          "custom_payment_types": [],
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-74d5b469-d3ce-4901-b562-75f94642875c.png",
            "list": null
          }
        }],
        "menus": [{
          "uuid": "cde84c5d-6b0a-4d1b-a446-6c910076f8bd",
          "name": "Express Pickup Test - Express Pickup",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Skip the line and pre-order your meal, at no added cost.  We'll prepare it fresh once you arrive at the stand.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "Concessions",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "Express Pickup",
          "stand_name": "Express Pickup Test",
          "stand_uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 26,
          "product_type": "Food",
          "preorder": true,
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-6369f382-4e0d-45ec-921d-f5a90338fe5f.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.07",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-cde84c5d-6b0a-4d1b-a446-6c910076f8bd.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "6fe32186-25c7-4ff7-9a5f-ce198408f325",
          "name": "Express Pickup Test - POS (S4)",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Order your meal from any of our handheld or pantry POS systems.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "POS",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "POS",
          "stand_name": "Express Pickup Test",
          "stand_uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 0,
          "product_type": "Food",
          "service_types": [{
            "name": "POS",
            "display_name": "POS"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-6369f382-4e0d-45ec-921d-f5a90338fe5f.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.07",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-6fe32186-25c7-4ff7-9a5f-ce198408f325.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "3f7689e6-db6f-4e4d-b58c-5fda4d630c37",
          "name": "Express Pickup Test - Express Pickup",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Scan a QR code and skip the line.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "QuickPay",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "Quick Pay",
          "stand_name": "Express Pickup Test",
          "stand_uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 0,
          "product_type": "Food",
          "service_types": [{
            "name": "QuickPay",
            "display_name": "Quick Pay"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-6369f382-4e0d-45ec-921d-f5a90338fe5f.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.07",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-3f7689e6-db6f-4e4d-b58c-5fda4d630c37.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "f7b90448-8a6e-47e7-8a52-e0f36515c1f2",
          "name": "POS - Mobile DOE",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Skip the line and pre-order your meal, at no added cost.  We'll prepare it fresh once you arrive at the stand.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "Concessions",
          "short_description": null,
          "long_description": "HELLO EDDIE attempt 2",
          "usage_type": "pickup",
          "service_type_display_name": "Express Pickup",
          "stand_name": "Express Pickup Test",
          "stand_uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
          "order_ahead": false,
          "type": "EventMenu",
          "sales_event_type_name": null,
          "subtype": "mobile_day_of_event",
          "wait_time_in_minutes": 26,
          "product_type": "Food",
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-6369f382-4e0d-45ec-921d-f5a90338fe5f.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.07",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-f7b90448-8a6e-47e7-8a52-e0f36515c1f2.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "7fa7ac64-96ed-4ecd-ad22-ee6d8cf83e25",
          "name": "Bubba Burger - Express Pickup",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Skip the line and pre-order your meal, at no added cost.  We'll prepare it fresh once you arrive at the stand.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": true,
          "service_type": "Concessions",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "Express Pickup",
          "stand_name": "Frank's Franks",
          "stand_uuid": "74d5b469-d3ce-4901-b562-75f94642875c",
          "order_ahead": true,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 15,
          "product_type": "Food",
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-74d5b469-d3ce-4901-b562-75f94642875c.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.1",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-7fa7ac64-96ed-4ecd-ad22-ee6d8cf83e25.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "cf881aae-7865-491e-96ad-4750e279929e",
          "name": "Bubba Burger",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Order the basics and get it delivered right to your seat!",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "Delivery",
          "short_description": null,
          "long_description": null,
          "usage_type": "delivery",
          "service_type_display_name": "In-Seat Delivery",
          "stand_name": "Frank's Franks",
          "stand_uuid": "74d5b469-d3ce-4901-b562-75f94642875c",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 0,
          "product_type": "Food",
          "service_types": [{
            "name": "Delivery",
            "display_name": "In-Seat Delivery"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-74d5b469-d3ce-4901-b562-75f94642875c.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.1",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-cf881aae-7865-491e-96ad-4750e279929e.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "c81ff5c4-01ec-463c-b5a0-82737d976a8e",
          "name": "Frank's Franks POS",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Order your meal from any of our handheld or pantry POS systems.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "POS",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "POS",
          "stand_name": "Frank's Franks",
          "stand_uuid": "74d5b469-d3ce-4901-b562-75f94642875c",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 0,
          "product_type": "Food",
          "service_types": [{
            "name": "POS",
            "display_name": "POS"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-74d5b469-d3ce-4901-b562-75f94642875c.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.1",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-c81ff5c4-01ec-463c-b5a0-82737d976a8e.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "654ba586-da41-454b-90d4-12f55e4ec7b0",
          "name": "Franks Franks Kiosk",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Order your meal from any of our conveniently located kiosks.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "Kiosk",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "Kiosk",
          "stand_name": "Frank's Franks",
          "stand_uuid": "74d5b469-d3ce-4901-b562-75f94642875c",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 0,
          "product_type": "Food",
          "service_types": [{
            "name": "Kiosk",
            "display_name": "Kiosk"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-74d5b469-d3ce-4901-b562-75f94642875c.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.1",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-654ba586-da41-454b-90d4-12f55e4ec7b0.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }, {
          "uuid": "f7b90448-8a6e-47e7-8a52-e0f36515c1f2",
          "name": "POS - Mobile DOE",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Skip the line and pre-order your meal, at no added cost.  We'll prepare it fresh once you arrive at the stand.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": true,
          "service_type": "Concessions",
          "short_description": null,
          "long_description": "HELLO EDDIE attempt 2",
          "usage_type": "pickup",
          "service_type_display_name": "Express Pickup",
          "stand_name": "Frank's Franks",
          "stand_uuid": "74d5b469-d3ce-4901-b562-75f94642875c",
          "order_ahead": false,
          "type": "EventMenu",
          "sales_event_type_name": null,
          "subtype": "mobile_day_of_event",
          "wait_time_in_minutes": 15,
          "product_type": "Food",
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-74d5b469-d3ce-4901-b562-75f94642875c.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.1",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-f7b90448-8a6e-47e7-8a52-e0f36515c1f2.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": [{
            "tip_percentage": "0.15",
            "tip_display": "15%",
            "tip_selected": true,
            "tip_custom": false
          }, {
            "tip_percentage": "0.2",
            "tip_display": "20%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": "0.25",
            "tip_display": "25%",
            "tip_selected": false,
            "tip_custom": false
          }, {
            "tip_percentage": null,
            "tip_display": "Other",
            "tip_selected": false,
            "tip_custom": true
          }, {
            "tip_percentage": "0.0",
            "tip_display": "No Tip",
            "tip_selected": false,
            "tip_custom": false
          }]
        }]
      }
    })
  })
}
