import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Divider,
  Button,
  Typography
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { useTranslation } from 'react-i18next'
import semver from 'semver'

import { setOpenExchangeServiceDialog } from '../../VNDialogs/ActionCreators'
import { 
  getExchangeServiceTitle,
  getExchangeServiceBalanceTitle,
  getExchangeServiceInputFieldAccount,
  getExchangeServiceInputFieldAccountSubtext,
  getExchangeServiceInputFieldPasscode,
  getExchangeServiceConfirmValueButtonText,
  getExchangeServiceBalanceFailureText,
  getExchangeServiceTimeOffset,
  getExchangeServiceDisableTicketNumberSearch,
 } from '../../VNConfigurations/Selectors'
import { getExchangeServiceDialogOpen } from '../../VNDialogs/Selectors'
import { exchangeServiceMultipleTransfer, exchangeServiceTransfer, setExchangeServiceJwt } from '../ActionCreators'
import { getVenueUuid } from '../../VNRevenueCenters/Selectors'
import { setSnackbarSystemDataAlertError } from '../../VNSnackbarSystem/ActionCreators'
import { VNTextInput } from '../../VNComponents/VNTextInput'
import { VNButton } from '../../VNComponents/VNButton'
import { apiLoadExchangeServiceBalance } from '../Api'
import { getUser } from '../../VNUser/Selectors'
import Money from '../../utils/money'
import { ReactComponent as NoTransferSVG } from '../../assets/icons/no-transfer.svg';
import { ReactComponent as FutureEventsSVG } from '../../assets/icons/future-event.svg';
import { ReactComponent as ExperationWarningSVG } from '../../assets/icons/experation-warning.svg';
import { getWebSDKMode, getWebSDKVersion } from '../../VNWebSDK/Selectors'
import { sendSDKModalOpen } from '../../VNWebSDK/bridgeCalls/VNWebSDKDataSend'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'
import { enableBackdropSystem, disableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import { ExchangeServiceAssistedLoad } from '../components/ExchangeServiceAssistedLoad'
import { VNButtonSideData } from '../../VNComponents/VNButtonSideData'
import { getExchangeServiceJwt } from '../Selectors'
import { parseJWT } from '../../App/Utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  middle: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cancelIcon: {
    height: 40,
    width: 40,
    color: theme.custom.colors.black,
  },
  funds: {
    color: '#2253FF'
  },
  outlinedButton: {
    height: 58,
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const VNExchangeService = props => {

  const classes = useStyles()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  // loadedBalance is tracking whether the exchange service has fetched and received a balance for the supplied account.
  const [ loadedBalance, setLoadedBalance ] = useState(false)
  const [ account, setAccount ] = useState(undefined)
  const [ balance, setBalance ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const [ manualEntry, setManualEntry ] = useState(false)
  const [ selectedAccounts, setSelectedAccounts ] = useState([])
  const [ availableDate, setAvailableDate] = useState(undefined)
  const [ assistedLoadEmpty, setAssistedLoadEmpty ] = useState(false)
  const [ canConfirm, setCanConfirm ] = useState(true)

  //#region SELECTORS
  const user = useSelector(state => getUser(state))
  const dialogOptions = useSelector(state => getExchangeServiceDialogOpen(state))
  
  const loadFailureText = useSelector(state => getExchangeServiceBalanceFailureText(state, props.provider))
  const exchangeServiceTitle = useSelector(state => getExchangeServiceTitle(state, props.provider))
  const exchangeServiceBalanceTitle = useSelector(state => getExchangeServiceBalanceTitle(state, props.provider))
  const exchangeServiceInputFieldAccount = useSelector(state => getExchangeServiceInputFieldAccount(state, props.provider))
  const exchangeServiceInputFieldAccountSubtext = useSelector(state => getExchangeServiceInputFieldAccountSubtext(state, props.provider))
  const exchangeServiceInputFieldPasscode = useSelector(state => getExchangeServiceInputFieldPasscode(state, props.provider))
  const exchangeServiceConfirmValueButtonText = useSelector(state => getExchangeServiceConfirmValueButtonText(state, props.provider))
  const timeOffset = useSelector(state => getExchangeServiceTimeOffset(state, props.provider))
  const disableTicketNumberSearch = useSelector(state => getExchangeServiceDisableTicketNumberSearch(state, props.provider))
  const venueID = useSelector(state => getVenueUuid(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const transferApiStatus = useSelector(state => getLoadingSystemStatus(state, exchangeServiceTransfer.name))
  const exchangeServiceJwt = useSelector(state => getExchangeServiceJwt(state))

  //#endregion

  //#region REFS
  const accountRef = useRef()
  const passcodeRef = useRef()
  const assistedLoadRef = useRef()
  //#endregion REFS

  //#region HANDLERS
  const handleCloseModal = () => {
    dispatch(setOpenExchangeServiceDialog({open: false, provider: undefined}))
  }

  useEffect(() => {
    if (dialogOptions?.open) {
      setLoadedBalance(false)
      setManualEntry(false)
      setManualEntry(false)
      setSelectedAccounts([])
    }
  }, [dialogOptions])

  useEffect(() => {
    if (webSDKVersion && semver.gte(webSDKVersion, '2.0.4')) {
      const modalOpenKey = {}
      modalOpenKey[`exchange_service_${dialogOptions.provider}`] = dialogOptions.open ? 'yes' : 'no'
      sendSDKModalOpen(modalOpenKey)
    }
    document.title = exchangeServiceTitle
  }, [dialogOptions, webSDKVersion])

  const displayError = (error) => {
    let errMsg = loadFailureText
    if (typeof error.response?.data?.error === 'string') {
      errMsg = error.response.data.error
    }
    setLoadedBalance(false)
    dispatch(setSnackbarSystemDataAlertError(errMsg))

    dispatch(disableBackdropSystem())
    setLoading(false)
  }

  // there is no api call for balance so it must be manually calculated for the ticket balance.
  const loadAssistedBalance = () => {
    let assistedBalance = getSelectedAccountsBalance()

    if (selectedAccounts.length === 0) {
      dispatch(setSnackbarSystemDataAlertError(t(`SELECT_ONE_TICKET`)))
    } else {
      setBalance(assistedBalance)
      setLoadedBalance(true)
    }
  }

  const loadBalance = () => {
    setLoading(true)

    const account = accountRef.current.getInput()

    if (!account || !account.trim()) {
      dispatch(setSnackbarSystemDataAlertError(t('ACCOUNT_CANNOT_BE_EMPTY')))
      setLoading(false)
      return
    }

    const trimmedAccount = account.trim()
    setAccount(trimmedAccount)

    const jwt = user.get('jwt')
    dispatch(enableBackdropSystem())
  
    apiLoadExchangeServiceBalance(jwt, props.provider, trimmedAccount, venueID).then(response => {

      setBalance(response.data.balance)

      let accessControlDate

      if (response.data.signedEventPayload) {
        // for TDC only
        dispatch(setExchangeServiceJwt(response.data.signedEventPayload))

        const eventPayload = parseJWT(response.data.signedEventPayload)

        accessControlDate = new Date(eventPayload.accessControlStartDate)

      } else {
        // for TM (and maybe other providers?)
        accessControlDate = new Date(response.data.accessControlStartDate)
      }

      accessControlDate.setMinutes(accessControlDate.getMinutes() - timeOffset[0])

      if (Date.now() > accessControlDate.valueOf()) {
        setCanConfirm(true)
      } else {
        setCanConfirm(false)
      }

      setAvailableDate(accessControlDate)
      setLoadedBalance(true)
      dispatch(disableBackdropSystem())
      setLoading(false)

    }).catch(error => {
      displayError(error)
    })
  }

  const transferAccounts = () => {
    if (manualEntry) {
      dispatch(exchangeServiceTransfer(props.provider, exchangeServiceJwt.get('jwt'), account, venueID, balance, t))
    } else {
      if (selectedAccounts.length === 0) {
        dispatch(setSnackbarSystemDataAlertError(t(`SELECT_ONE_TICKET`)))
        return 
      }

      let formattedAccounts = []
      selectedAccounts.forEach(element => {
        formattedAccounts.push({
          account: element.uuid
        })

        if (element?.amount) {
          formattedAccounts.amount = element.amount
        }

        if (element?.passcode) {
          formattedAccounts.passcode = element.passcode
        }
      });

      dispatch(exchangeServiceMultipleTransfer(props.provider, exchangeServiceJwt.get('jwt'), formattedAccounts, 'en', t, t('TICKETS_NOT_LOADED')))
    }
  }

  const onChange = (data) => {
    setSelectedAccounts(data.selectedChoices)
  }

  const getSelectedAccountsBalance = () => {
    let selectedBalance = 0
    selectedAccounts.forEach(element => {
      selectedBalance = selectedBalance + element.balance
    });

    return selectedBalance
  }

  const assistedloadResults = (loadedData) => {
    setAssistedLoadEmpty(!loadedData)
  }

  //#endregion

  //#region RENDERERS
  const displayHeader = () => {
    return (
      <Box pt={3} pr={3} pl={3} className={classes.top}>
        <Box pt={1.5}>
          <Typography variant='h1'>{exchangeServiceTitle}</Typography>
        </Box>
        <HighlightOffRoundedIcon className={classes.cancelIcon} onClick={handleCloseModal} />
      </Box>
    )
  }

  const displayBody = () => {
    if (loadedBalance) {
      return displayBalance()
    }
    if (manualEntry) {
      return displayInputs()
    }
    return displayAssistedLoad()
  }

  const displayTicketNumberSearchButton = () => {
    if (disableTicketNumberSearch?.toLowerCase() === 'true') {
      return <></>
    }

    return (
      <Box paddingTop={3}>
        <Button
          className={classes.outlinedButton}
          disabled={loading}
          onClick={() => setManualEntry(true)}
          variant={assistedLoadEmpty ? 'contained' : 'outlined'}
          color={'secondary'}
          fullWidth={true}
        >
          {`${t("SEARCH_TICKET_NUMBER")}`}
        </Button>
      </Box>
    )
  }

  const displayLoadButton = () => {
    if(assistedLoadEmpty) {
      return <></>
    }
    return (
      <Box paddingTop={2}>
        <VNButtonSideData
          count={selectedAccounts.length}
          amount={Money.centsToDollars(getSelectedAccountsBalance(), true, false)}
          onClick={loadAssistedBalance}
          disabled={selectedAccounts.length === 0}
          isAvailable={true} 
          title={exchangeServiceTitle}
        />
      </Box>
    )
  }

  const displayAssistedLoad = () => {
    return (
      <Box pr={3} pl={3} className={classes.middle}>
        <Box>
          <Box pt={5}>
            <ExchangeServiceAssistedLoad provider={props.provider} loadedResults={assistedloadResults} ref={assistedLoadRef} onChange={onChange}/>
          </Box>
        </Box>
        <Box pb={3}>
          <Divider />
          {displayTicketNumberSearchButton()}
          {displayLoadButton()}
        </Box>
      </Box>
    )
  }

  const displayInputs = () => {
    return (
      <Box pr={3} pl={3} className={classes.middle}>
        <Box>
          <Box pt={5}>
            {exchangeServiceInputFieldAccount && 
              <VNTextInput 
                ref={accountRef}
                label={exchangeServiceInputFieldAccount} 
                labelWidth={125} 
                type="text"
              />
            }
            <Box paddingTop={1}>
              <Typography variant="subtitle1">{exchangeServiceInputFieldAccountSubtext}</Typography>
            </Box>
            {exchangeServiceInputFieldPasscode && 
              <VNTextInput 
                ref={passcodeRef} 
                label={exchangeServiceInputFieldPasscode} 
                labelWidth={150} 
                type="text"
              />
            }
          </Box>
        </Box>
        <Box pb={3}>
          <Divider />
          <Box paddingTop={3}>
            <VNButton text={exchangeServiceTitle} onClick={loadBalance} disabled={loading} />
          </Box>
        </Box>
      </Box>
    )
  }

  const displayConfirm = () => {
    if(canConfirm) {
      return (
        <Box pb={3}>
          <Divider />
          <Box paddingTop={3}>
            <VNButton
              text={exchangeServiceConfirmValueButtonText}
              onClick={transferAccounts}
              disabled={transferApiStatus.status === ApiLoadingStatus.LOADING}
            />
          </Box>
        </Box>
      )
    }
  }

  const displayWarnings = () => {
    if(canConfirm) {
      return (
        <>
          <Box pt={5} pr={3} className={classes.top}>
            <Box pr={3}>
              <NoTransferSVG/>
            </Box>
            <Typography variant="subtitle1">{t('EXCHANGE_SERVICES_TRANSFER_DISCLAIMER')}</Typography>
          </Box>
          <Box pt={5} pr={3} className={classes.top}>
            <Box pr={3}>
              <ExperationWarningSVG/>
            </Box>
            <Typography variant="subtitle1">{t('EXCHANGE_SERVICES_EXPERATION_WARNING')}</Typography>
          </Box>
        </>
      )
    } else {
      const weekDayName = new Date(availableDate)?.toLocaleDateString('default', { weekday: 'long' })
      const monthNameShort = new Date(availableDate)?.toLocaleString('default', { month: 'short' })
      const dateNumber = new Date(availableDate)?.getDate()
      const eventTime = new Date(availableDate)?.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      return (
        <Box pt={5} pr={3} className={classes.top}>
          <Box pr={2}>
            <FutureEventsSVG/>
          </Box>
          <Box pl={1}>
            <Typography variant='subtitle1'>{`${t('AVAILABLE_LOAD')} ${weekDayName}, ${monthNameShort} ${dateNumber} ${t('AT')} ${eventTime}`}</Typography>
          </Box>
        </Box>
      )
    }
  }

  const displayBalance = () => {
    return (
      <Box pr={3} pl={3} className={classes.middle}>
        <Box>
          <Box pt={5}>
            {manualEntry && <Typography variant="h3">{exchangeServiceBalanceTitle}</Typography>}
            <Typography variant="h1" color='secondary'>{Money.centsToDollars(balance, true, false)}</Typography>
          </Box>
          {displayWarnings()}
        </Box>
        {displayConfirm()}
      </Box>
    )
  }

  const displayModal = () => {
    
    let isOpen = dialogOptions.open === undefined ? false : dialogOptions.open
    return  (
      <Dialog fullScreen open={isOpen} TransitionComponent={Transition}>
        <Box className={classes.root}>
          {displayHeader()}
          {displayBody()}
        </Box>
      </Dialog>
    )
  }
  //#endregion
  return (
    displayModal()
  )
}
