import { isEmpty } from 'lodash'
/**
 * Get a menu for a stand based off the service type
 * @param {Object} menusByStandId - Map of menus by stand id they belong to
 * @param {String} standUuid - The stand id
 * @param {String} serviceType - What service type are we looking for? Concessions, Delivery, etc
 */
export const getMenuByServiceType = (menusByStandId, standUuid, serviceType) => {
  const standMenus = menusByStandId?.get(standUuid)

  let response

  if (standMenus) {
    // Try to find a preorder menu first
    response = standMenus.find(menu => menu.service_type === serviceType && menu.type === 'SingleStandMenu' && menu.preorder)

    if (response === undefined) {
      response = standMenus.find(menu => menu.service_type === serviceType && menu.type === 'SingleStandMenu')
    }

    // If response is null, this is likely a scenario where it is a Suites Mobile Ordering
    // menu and they only have EventMenu available for the type, which in this case we are
    // now supporting
    if (response === undefined) {
      standMenus.forEach(menu => {
        if (menu.service_type === serviceType) {
          response = menu
          return
        }
      })
    }
  }

  return response
}

/**
 * Get wait time string for a menu used for display
 * @param {String} productType - Product type which is either Food, Merchandise, or Experiences
 * @param {Int} pickupWaitTime - Wait time in minutes
 * @param {Int} deliveryWaitTime - Wait time in minutes
 * @param {Object} t - useTranslation object
 * @returns
 */
export const getWaitTimeStringFromMenu = (productType, pickupWaitTime, deliveryWaitTime, t) => {
  if (productType === 'Experience') {
    return null
  }

  const filtered =  [pickupWaitTime, deliveryWaitTime].filter(waitTime =>
    waitTime !== undefined && waitTime > 0
  )

  if (isEmpty(filtered)) {
    return null
  }

  filtered.sort((a, b) => { return a-b })

  if (filtered.length > 1) {
    if (filtered[0] === filtered[1]) {
      return `${filtered[0]} ${t('MIN_WAIT')}`
    }
    return `${filtered[0]}-${filtered[1]} ${t('MIN')}`
  }

  return `${filtered[0]} ${t('MIN_WAIT')}`
}

/**
 * Need to convert a copy of the menu items to a keyed map as well as an array like it is given
 * @param {object} menuData - Menu data fetched by api call
 * @returns {object} - Full menu with newly populated fields
 */
export const makeVNMenu = (menuData) => {
  const fullMenu = menuData
  const keyedItems = {}
  const categoriesWithItems = []

  menuData.items.forEach(item => {
    keyedItems[item.uuid] = item

    if (!item.category) {
      return
    }

    item.item_category_uuids.forEach(itemCategoryUuid => {
      const index = menuData.categories.findIndex(c => c.item_category_uuid === itemCategoryUuid)

      if (index === -1) {
        return
      }

      const categoryWithItems = categoriesWithItems.find(c => c.item_category_uuid === itemCategoryUuid)

      if (categoryWithItems) {
        categoryWithItems.items.push(item)
        return
      }

      categoriesWithItems.push({
        category_name: menuData.categories[index].name,
        item_category_uuid: menuData.categories[index].item_category_uuid,
        // use its original index as its sort_order
        sort_order: index,
        items: [item]
      })
    })
  })

  // sort categories by sort_order
  fullMenu.categories_with_items = categoriesWithItems.sort((a, b) => a.sort_order - b.sort_order)
  fullMenu.keyed_items = keyedItems

  return fullMenu
}
