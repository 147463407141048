import React, { Fragment } from 'react'
import Box from "@material-ui/core/Box"
import { useDispatch, useSelector } from 'react-redux'
import { VNTextInputWithButton } from '../../VNComponents/VNTextInputWithButton'
import { useTranslation } from 'react-i18next'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { useTheme } from '@material-ui/core/styles'
import { getPromoCodes } from '../Selectors'
import { addPromoCode, removePromoCode } from '../ActionCreators'
import VNDivider from '../../VNComponents/VNDivider'

export const VNPromoCodes = props => {

  const theme = useTheme()

  // SELECTORS
  const promoCodeList = useSelector(state => getPromoCodes(state))

  const { t } = useTranslation()

  const dispatch = useDispatch()

  /**
   * we only allow one of each promo string
   * @param {string} val - The promo code to check to see if it exists
   * @returns true if the code exists, false otherwise
   */
  const promoCodeExists = (val) => {
    if (val.length === 0) return true

    return promoCodeList.includes(val)
  }

  /**
   * Called when the button is pressed to apply the promo code
   * @param {string} inputVal - The string that was typed into the promo field
   */
  const onTextInputClick = (inputVal) => {
    if (!promoCodeExists(inputVal)) {
      dispatch(addPromoCode(inputVal))
      props.onApply()
    }
  }

  /**
   * Called when the cancel icon is pressed to remove a promo code from the list
   * @param {string} inputVal  - The string that was clicked to be removed
   */
  const onRemovePromoClick = (inputVal) => {
    dispatch(removePromoCode(inputVal))
  }

  /**
   * Display the promo list if any have been entered
   */
  const displayPromoList = () => {
    if (!promoCodeList.isEmpty()) {
      const items = promoCodeList.map(item => (
        <Fragment key={item}>
          <ListItem>
            <ListItemText disableTypography style={theme.typography.h2} primary={item} />
            <ListItemSecondaryAction onClick={() => { onRemovePromoClick(item) }}>
              <IconButton edge="end" aria-label="delete">
                <CancelIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <VNDivider />
        </Fragment>
      ))

      return (
        <List>
          {items}
        </List>
      )
    }
  }

  return (
    <Box>
      {displayPromoList()}
      <Box pt={2}>
        <VNTextInputWithButton
          label={t('PROMO_CODE')}
          labelWidth={105}
          placeholder={t('ENTER_PROMO_CODE')}
          onClick={onTextInputClick}
          resetInputOnClick
          buttonText={t('APPLY')}
        />
      </Box>
    </Box>
  )
}
