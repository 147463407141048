import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import classNames from 'classnames'

export class Modal extends Component {
  constructor(props) {
    super(props)

    this.modalRef = React.createRef()
  }

  state = {
    showModal: true,
  }

  componentDidMount() {
    disableBodyScroll(this.modalRef.current)
  }

  componentWillMount() {
    // Only need to run this in running code, do not need in jest
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#root')
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  closeModal = () => {
    enableBodyScroll(this.modalRef.current)

    this.setState({ showModal: false }, () => {
      setTimeout(this.props.onCloseModal, 350)
    })
  }

  render() {
    const { showModal } = this.state
    const { className, isOpen } = this.props

    return (
      <ReactModal
        isOpen={isOpen ? isOpen : showModal}
        shouldCloseOnOverlayClick
        className={{
          base: classNames('modal', className),
          afterOpen: 'modal-after-open',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay-after-open',
          beforeClose: 'modal-overlay-before-close',
        }}
        closeTimeoutMS={350}
        onRequestClose={this.closeModal}
        ref={this.modalRef}
      >
        {this.props.children}
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
}

export default Modal
