import { STANDS_SUCCEEDED } from '../actions/stand'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case STANDS_SUCCEEDED:
    return {
      ...action.payload.modifierPropertiesContainer,
    }

    default:
      return state
  }
}

export default reducer
