import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'
import moment from 'moment-timezone'

const getInitialState = () => {
  return Map({
    'user': Map({}),
    'user_loaded': false,
    'stadium_user': Map({}),
    'payment_methods': Map({}),
    'anon_experience_order_history': Map({}),
    'payment_reminder_view': false,
    'anonymous_user_data': Map({}),
    'cached_route': null,
    'lastTimeAffiliationsRefreshed': null,
    'credit_card_affiliations': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNUSER_SET_USER:
      return state.set('user', Map(action.user))
    case ActionTypes.VNUSER_SET_USER_DETAILED_AFFILIATIONS:
      return state.setIn(['user', 'detailed_affiliations'], action.affiliations)
    case ActionTypes.VNUSER_SET_STADIUM_USER:
      return state.set('stadium_user', Map(action.stadium_user))
    case ActionTypes.VNUSER_SET_USER_PAYMENT_METHODS:
      return state.set('payment_methods', Map(action.payment_methods))
    case ActionTypes.VNUSER_SET_VIEWED_PAYMENT_REMINDER:
      return state.set('payment_reminder_view', action.reminder)
    case ActionTypes.VNUSER_SET_ANON_EXPERIENCE_ORDER_HISTORY:
      const userId = action.user.get('userID')
      const anonOrders = state.getIn(['anon_experience_order_history', userId])

      let buffer = {}

      if (anonOrders && !anonOrders.isEmpty()) {
        anonOrders.map((value, key) => { buffer[key] = value })
      }

      action.order.line_items.forEach(item => {
        const countItem = anonOrders ? anonOrders.get(item.item_id) : null
        buffer[item.item_id] = countItem ? countItem + item.quantity : item.quantity
      })

      return state.setIn(['anon_experience_order_history', userId], Map(buffer))
    case ActionTypes.VNUSER_SET_ANONYMOUS_USER_DATA:
      return state.set('anonymous_user_data', Map(action.data))
    case ActionTypes.VNUSER_SET_CACHED_ROUTE:
      return state.set('cached_route', action.route)
    case ActionTypes.VNUSER_SET_AFFILIATIONS_REFRESHED:
      return state.set('lastTimeAffiliationsRefreshed', moment())
    case ActionTypes.VNUSER_RESET_AFFILIATIONS_REFRESHED:
      return state.set('lastTimeAffiliationsRefreshed', null)
    case ActionTypes.VNUSER_SET_CREDIT_CARD_AFFILIATIONS:
      return state.setIn(['credit_card_affiliations', action.cardBin], action.affiliations)
    case ActionTypes.VNUSER_SET_USER_LOADED:
      return state.set('user_loaded', action.loaded)
    case ActionTypes.VNUSER_SET_IS_NEW_LOGIN:
      return state.set('isNewLogin', action.isNewLogin)
    default:
      return state
  }
}

export default reducer
