import { call, put, select, takeLatest } from 'redux-saga/effects'
import Remote from '../remote'
import { getUser } from '../VNUser/Selectors'

import * as actions from '../actions/seat'

export function* fetchSections(params) {
  const user = yield select(getUser);
  const token = user.get('jwt')
  try {
    const result = yield call(Remote.getSectionsForStand, params.payload, token)
    yield put(actions.loadSectionsSucceeded(result))
  } catch (err) {
    yield put(actions.loadSectionsFailed(err))
  }
}

export function* watchFetchSections() {
  yield takeLatest(actions.LOAD_SECTIONS_REQUESTED, fetchSections)
}

export function* fetchRows(params) {
  const user = yield select(getUser);
  const token = user.get('jwt')
  try {
    const result = yield call(Remote.getRowsForSection, params.payload, token)
    yield put(actions.loadRowsSucceeded(result))
  } catch (err) {
    yield put(actions.loadRowsFailed(err))
  }
}

export function* watchFetchRows() {
  yield takeLatest(actions.LOAD_ROWS_REQUESTED, fetchRows)
}

export function* fetchSeats(params) {
  const user = yield select(getUser);
  const token = user.get('jwt')
  try {
    const result = yield call(Remote.getSeatsForRow, params.payload, token)
    yield put(actions.loadSeatsSucceeded(result))
  } catch (err) {
    yield put(actions.loadSeatsFailed(err))
  }
}

export function* watchFetchSeats() {
  yield takeLatest(actions.LOAD_SEATS_REQUESTED, fetchSeats)
}

export function* fetchMenusForSeat(params) {
  const user = yield select(getUser);
  const token = user.get('jwt')
  try {
    const result = yield call(Remote.getMenusForSeatLocation, params.payload, token)
    const menuId = yield select(state => state.cart.menuId)
    const valid = result.map(menu => menu.stand_menu_uuid).includes(menuId)

    yield put(actions.loadMenusForSeatSucceeded({ valid }))
  } catch (err) {
    yield put(actions.loadMenusForSeatFailed(err))
  }
}

export function* watchFetchMenusForSeat() {
  yield takeLatest(actions.LOAD_MENUS_FOR_SEAT_REQUESTED, fetchMenusForSeat)
}
