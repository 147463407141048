import React, { useState } from 'react'
import { history } from '../../App/store/configureStore'
import { useSelector } from 'react-redux'
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import List from "@material-ui/core/List"
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { getUser } from '../../VNUser/Selectors'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { VNDialog } from '../../VNComponents/VNDialog'

const useStyles = makeStyles(theme => ({
  icon: {
    height: 16,
    width: 16,
  },
  itemBox: {
    display: 'flex',
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
  }
}))

/**
 * Shows the menu links that appear in the drawer for all users, some rules depend on authenticated users or not
 * @param onSelection - func - if you want a callback when one of the list items has been clicked
 */
export const VNMenuList = (props) => {

  const [cartDisclaimer, setCartDisclaimer] = useState(false)
  // SELECTORS
  const user = useSelector(state => getUser(state))
  
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  // called when any list item has been clicked
  const handleAnySelection = (event) => {
    if (event) {
      event.preventDefault()
    }    

    if (event.currentTarget.getAttribute('data-link') === '/' && document.location.pathname.includes('cart')) {
      setCartDisclaimer(true)
      return
    }

    if (props.onSelection) {
      props.onSelection()
    }

    if (event.currentTarget.getAttribute('data-link')) {
      history.push(event.currentTarget.getAttribute('data-link'))
    }
  } 

  const displayList = () => {

    let response = []

    // all users get the shop button
    response.push(makeListItem(t('SHOP'), <ShoppingCartIcon className={classes.icon} />, '/'))

    // if the user is an authenticated user
    if (!user.isEmpty() && user.get('provider') !== 'vn_anonymous') {
      response.push(makeListItem(t('MY_WALLET'), <AccountCircleIcon className={classes.icon} />, '/wallet'))
      
      // add new authenticated links here
    }

    return response
  }

  /**
   * Create a new list item to display in the drawer
   * @param {String} text - The text to display as the item
   * @param {Component} icon - The Icon component to display next to the word
   * @param {String} link - The link to navigate within the application to
   */
  const makeListItem = (text, icon, link) => {
    return (
      <ListItem button key={text} onClick={handleAnySelection} data-link={link}>
        <Box className={classes.itemBox} pt={2} data-link={link}>
          <Box pr={1}>
            {icon}
          </Box>
          <ListItemText className={classes.listItemText} disableTypography style={theme.custom.subtitle3} primary={text} />
        </Box>
      </ListItem>
    )
  }

  return (
    <Box pb={1}>
      <VNDialog
        open={cartDisclaimer}
        subTitle={t('REMOVE_ALL_ITEMS')}
        save={t('OK')}
        cancel={t('DISMISS')}
        onSave={() => history.push('/')}
        onClose={()=>setCartDisclaimer(false)}
      />
      <List>
        {displayList()}
      </List> 
    </Box>
  )
}
