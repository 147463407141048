import React, { useState, forwardRef, useImperativeHandle, Fragment, useEffect, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import { useTranslation } from 'react-i18next'
import VNDivider from '../../VNComponents/VNDivider'
import { VNCounter } from '../../VNComponents/VNCounter'

const useStyles = makeStyles(theme => ({
  root: {},
  titleBox: {
    display: 'flex'
  },
  chip: {
    height: 20,
    marginLeft: 10,
    marginTop: 3,
  },
  divider: {
    width: 50,
    marginTop: 17,
  },
  formControl: {
    width: '100%',
  },
  selectorGroup: {
    marginTop: 17,
  },
  modifierDisplayBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  modifierDisplay: {
    '&:last-child': {
      marginLeft: 4
    }
  },
  rowBox: {
    height: 42,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  selectorBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    minWidth: 'unset'
  },
  icon: {
    fontSize: 16
  },
  quantity: {
    padding: '2px 8px',
    fontWeight: 700
  }
}));

// GLOBAL FUNCTIONS

const getGroupTotalQuantity = (choices) => {
  let totalQuantity = 0
  for (const c of choices) {
    totalQuantity += c.quantity
  }
  return totalQuantity
}

const checkGroupMaxReached = (choices, max) => max && getGroupTotalQuantity(choices) === max

const checkGroupMinReached = (choices, min) => getGroupTotalQuantity(choices) >= min

// END GLOBAL FUNCTIONS

const VNQuantitySelector = forwardRef((props, ref) => {

  const classes = useStyles()

  const theme = useTheme()

  const { t } = useTranslation()

  // LOCAL STATE

  const [choices, setChoices] = useState(props.selectedItems.filter(item => item.modifierGroupUuid === props.modifierGroupUuid))
  const choicesRef = useRef(choices)

  const [groupMaxReached, setGroupMaxReached] = useState(checkGroupMaxReached(choices, props.max))

  const [loading, setLoading] = useState(false)

  const [chipColor, setChipColor] = useState(props.required ? 'primary' : 'default')

  // handle default modifiers
  useEffect(() => {
    if (props.options && props.defaults?.length){
      let defaultOptions = props.options.filter(c => props.defaults.includes(c.uuid))
      let newChoices = [...choices]
      if ( defaultOptions && defaultOptions.length > 0 ){
        for (let i=0; i<defaultOptions.length; i++){
          newChoices = [...newChoices, { uuid: defaultOptions[i].uuid, quantity: 1 }]  
        }  
      }
      if (newChoices.length > 0){
        setChoices(newChoices)
        if (props.onChange) {
          props.onChange({
            uuid: props.modifierGroupUuid,
            minIsMet: checkGroupMinReached(newChoices, props.min)
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    choicesRef.current = choices
  }, [choices])

  // HANDLES

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    /**
     * this returns all selected choices
     * Choice - { uuid: string, quantity: number }
     * @returns {object} - { choices: Array<Choice>, status: boolean }
     */
    getChoices() {
      const currentChoices = choicesRef.current

      if (props.required) {
        if (!checkGroupMinReached(currentChoices, props.min)) {
          setChipColor('secondary')
          return {
            'choices': undefined,
            'status': false
          }
        } else {
          setChipColor('primary')
        }
      }

      return {
        choices: [...currentChoices],
        status: true
      }
    }
  }))

  /**
   * Called when remove/add button is pressed
   * @param {string} change - 'decrement' | 'increment'
   * @param {object} option - the current option - { uuid: string, quantity: number }
   */
  const handleClick = (change, option) => {
    if (loading) {
      return
    }

    setLoading(true)

    const choiceToUpdate = choices.find(c => c.uuid === option.uuid)

    let newChoices = []

    if (!choiceToUpdate) {
      newChoices = [...choices, { uuid: option.uuid, quantity: 1 }]
    } else {
      newChoices = choices.filter(c => c.uuid !== option.uuid)
      const newQuantity = choiceToUpdate.quantity + (change === 'increment' ? 1 : -1)

      if (newQuantity > 0) {
        choiceToUpdate.quantity = newQuantity
        newChoices.push(choiceToUpdate)
      }
    }

    setChoices(newChoices)

    if (checkGroupMaxReached(newChoices, props.max)) {
      setGroupMaxReached(true)
    } else {
      setGroupMaxReached(false)
    }

    // used to inform parent component the current status of this choice
    if (props.onChange) {
      props.onChange({
        uuid: props.modifierGroupUuid,
        minIsMet: checkGroupMinReached(newChoices, props.min)
      })
    }

    setLoading(false)
  }

  // LOCAL FUNCTIONS

  /**
   * Check if the remove/add button should be disabled
   * @param {string} change - 'decrement' | 'increment'
   * @param {object} option - the current option - { uuid: string, quantity: number }
   * @returns {boolean}
   */
  const isDisabled = (change, option) => {
    if (props.disabled || loading) {
      return true
    }

    let quantity = choices.find(c => c.uuid === option.uuid)?.quantity ?? 0
    if (choices.length === 0 && option.quantity){
      quantity = 1

    }
    if (change === 'decrement') {
      
      if (quantity === 0) {
        return true
      }
    } else if (change === 'increment') {
      if ((option.orderMaxAmount && option.orderMaxAmount === quantity) || groupMaxReached) {
        return true
      }
    }

    return false
  }

  /**
   * Display modifier text on the far left for each quantity selector
   * @param {object} option - An option object from a modifier group
   */
  const displayModifier = (option) => {
    const { secondaryPriceThresholdCount } = props

    let modifierDisplay = (
      <Typography className={classes.modifierDisplay} style={theme.custom.button3}>
        {option.value}
      </Typography>
    )

    const shouldShowSecondaryPrice = secondaryPriceThresholdCount && choices.length >= secondaryPriceThresholdCount
    let modifierPriceInfo = option.change + option.modifier

    if (shouldShowSecondaryPrice) {
      let index = choices.indexOf(option.uuid)
      if (index === -1 || index >= secondaryPriceThresholdCount) {
        modifierPriceInfo = option.thresholdChange + option.thresholdModifier
      }
    }

    if (option.modifier || option.thresholdModifier) {
      modifierDisplay = (
        <Fragment>
          {modifierDisplay}
          <Typography className={classes.modifierDisplay} style={theme.custom.button3}>
            {modifierPriceInfo}
          </Typography>
        </Fragment>
      )
    }

    return (
      <Box className={classes.modifierDisplayBox}>
        {modifierDisplay}
      </Box>
    )
  }

  /**
   * Display quantity selector
   * @param {object} option - An option object from a modifier group
   */
  const displaySelector = (option) => {
    let quantity = choices.find(c => c.uuid === option.uuid)?.quantity ?? 0
    let defaultChoice = props.options.find(c => props.defaults?.includes(option.uuid))
    if (choices.length === 0 && defaultChoice !=null ){
      //this quantity is default
      quantity = 1
      option.quantity = quantity
    }
    const decrementDisabled = isDisabled('decrement', option)
    const incrementDisabled = isDisabled('increment', option)

    return (
      <VNCounter
        default={quantity}
        min={0}
        decrementDisabled={decrementDisabled}
        incrementDisabled={incrementDisabled}
        onDecrementChange={() => handleClick('decrement', option)}
        onIncrementChange={() => handleClick('increment', option)}
        rootClasses={classes.selectorBox}
        buttonClasses={classes.button}
        iconClasses={classes.icon}
        counterClasses={classes.quantity}
        counterStyles={{}}
      />
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h2">
          {props.title}
        </Typography>
        <Chip label={props.required ? t('REQUIRED') : t('OPTIONAL')} color={chipColor} className={classes.chip} />
      </Box>
      {!!props.subTitle &&
        <Box pt={1}>
          <Typography style={theme.custom.overline2}>
            {props.subTitle}
          </Typography>
        </Box>
      }
      <VNDivider className={classes.divider} />
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup className={classes.selectorGroup}>
          {props.options.map((option) => (
            <Box className={classes.rowBox} key={option.uuid}>
              {displayModifier(option)}
              {displaySelector(option)}
            </Box>
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  )
})

export default VNQuantitySelector
