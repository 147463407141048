const vnReplaceChildren = (parentElement, replacingElement) => {
  while (parentElement.firstChild) {
    parentElement.removeChild(parentElement.lastChild)
  }

  parentElement.appendChild(replacingElement)
}

module.exports = {
  vnReplaceChildren: vnReplaceChildren
}
