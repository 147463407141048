import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { getUser, getUserJWT } from '../../VNUser/Selectors'
import { getShift4Token } from '../../VNWebAccount/Selectors'
import { getWebSDKMode, getWebSDKPlatform, getWebSDKVersion, getWebSDKDataReceive } from '../../VNWebSDK/Selectors'
import { retrieveShift4PaymentToken, resetShift4PaymentToken } from '../../VNWebAccount/ActionCreators'
import WebSDKPlatform from '../../VNEnums/WebSDKPlatform'
import Money from '../../utils/money'
import semver from 'semver'
import { apiPosti4GoApplePayLink } from '../../VNWebAccount/Api'
import { getOrderTotal } from '../../selectors/orderTotal'
import { sendSDKIOSRequesti4GoApplePay } from '../../VNWebSDK/bridgeCalls/VNWebSDKDataSend'
import { ORG_NAME } from '../../constants'
import * as VNWebAccountActionTypes from '../../VNWebAccount/ActionTypes'
import usePrevious from '../../VNHooks/usePrevious'
import Shift4 from '@shift4/shift4-js'
import { ORG_ENV } from '../../constants'
import { getS4PaymentsRegion, getS4PaymentsAppId, getSettingsFullOrgName } from '../../VNConfigurations/Selectors'

const VNi4go = require('venuenext-i4go-web-drop-in')

let shift4
let components
let paymentComponent

const useStyles = makeStyles(theme => ({
  root: {

  },
}))

export const VNShift4 = forwardRef(({orderTotalInCents, eventsCallback, preValidation, orderId, standId, standName, applePayCallback, displayApplePay = true, paymentsInCart, items, setShift4Loaded}, ref) => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const shift4Ref = useRef() 

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    getTokenization() {
      console.log('START PAYMENT TOKENIZATION')
      return VNi4go.dropin.startPaymentTokenization()
    },

    getAuthorization(user) {
      console.log('START PAYMENT AUTHORIZATION')

      let shoppingCart = []
      let productDescriptors = []

      for (const [key, value] of Object.entries(items)) {
        shoppingCart.push({
          quantity: value.quantity.toString(),
          price: value.defaultPriceInCents / 100,
          type: value.productType,
          description: value.name,
          sku: key
        })

        productDescriptors.push(value.name)
      }

      const configuration = {
        transaction: {
          invoice: Math.floor(1000000000 + Math.random() * 9000000000).toString(),
          purchaseCard: {
            customerReference: user.email.slice(0, 25), // uuid is too long, this is the best we can do; has to be less than 25 characters
            productDescriptors: productDescriptors
          }
        },
        customer: {
          emailAddress: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        },
        shoppingCart: shoppingCart,
        amount: {
          total: parseFloat(actualTotalAmount)
        }
      }

      console.log(configuration)
      return shift4.authorize(paymentComponent, configuration)
    }

  }))

  // #region SELECTORS
  const user = useSelector(state => getUser(state))
  const userJWT = useSelector(state => getUserJWT(state))

  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKPlatform = useSelector(state => getWebSDKPlatform(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const webSDKData = useSelector(state => getWebSDKDataReceive(state))
  const shift4Token = useSelector(state => getShift4Token(state))
  const latestOrderTotal = useSelector(state => getOrderTotal(state)).latest
  const s4PaymentsRegion = useSelector(state => getS4PaymentsRegion(state))
  const s4PaymentsAppId = useSelector(state => getS4PaymentsAppId(state))
  const configsSettingFullOrgName = useSelector(state => getSettingsFullOrgName(state))
  
  const [dropInInitialized, setDropInInitialized] = useState(false)
  const [dropInContainerKey, setDropInContainerKey] = useState(1)
  

  // Is order native iOS, then signal for default payment method through SDK
  const orderIsNativeIOS = (
    webSDKMode &&
    webSDKPlatform === WebSDKPlatform.IOS &&
    (webSDKVersion ? semver.gte(webSDKVersion, '2.0.1') : false)
  )

  // #endregion

  /**
   * Calculates the actual total amount to charge
   * 
   * @param {array} payments - array of object - { payment_type: 'vn_bank', amount_in_cents: 1000 }
   * @returns {string} - actual total amount to charge in dollars
   */
   const calculateActualAmountToCharge = (payments) => {
    let total = orderTotalInCents

    // If split payements, calculate for remaining total
    if (payments) {
      let amountCovered = 0
      for (const payment of payments) {
        amountCovered += payment.amount_in_cents
      }

      total = total - amountCovered
    }

    return Money.centsToFloat(total)
  }

  const actualTotalAmount = calculateActualAmountToCharge(paymentsInCart)
  const prevActualTotalAmount = usePrevious(actualTotalAmount)

  useEffect(() => {
    return () => {
      dispatch({
        type: VNWebAccountActionTypes.VNWEBACCOUNT_SET_SHIFT4_SERVER_AND_ACCESS_BLOCK,
        shift4: {
          token: null
        }
      })
    }
  }, [])

  useEffect(() => {
    if (!dropInInitialized && shift4Token) {

      setDropInInitialized(true)

      // temp code, way to prove that i4go drop-in works securely
      // window.addEventListener('message', (messageEvent) => {
      //   console.log(messageEvent)
      // }, false)

      if (s4PaymentsRegion === 'EU') {
        shift4 = new Shift4(s4PaymentsAppId, { 
          locale: 'en-us', 
          debug: true, 
          // development: { mode: 'uat' }
          // development: { mode: ORG_ENV}
        })
  
        components = shift4.components({})
  
        paymentComponent = components.initialize('payments', {
          token: shift4Token,
          vault: true,
          currencyCode: 'EUR',
          services: ['3ds'],
          events: (payload) => {
            console.log('INSIDE INTEGRATORS EVENTS')
            console.log(payload)
            eventsCallback(payload)
          }
        })
  
        paymentComponent.mount('dropin-container')
      } else {

        let config = {
          token: shift4Token,
          container: 'dropin-container',
          vault: true,
          debug: true,
          tokenizeButton: false,
          edit: true,
          wallet: {
            preValidation: () => {
              return preValidation()
            },
            sdkMode: {
              isApplePayOnly: false,
              enabled: orderIsNativeIOS,
              event: () => {
                let cb = applePayCallback()
                if (cb !== undefined) {
                  console.log(cb)
                  applePayPressed(cb)
                }
              }
            }
          },
          events: (payload) => {
            console.log('Integrator Events')
            console.log(payload)
            eventsCallback(payload)
          }
        }

        if (displayApplePay) {
          config.wallet.applePay = {
            currencyCode: t('CURRENCY'),
            countryCode: 'US',
            label: configsSettingFullOrgName ? `${configsSettingFullOrgName} ${t('VIA')} ${t('ORDER_NEXT')}` : t('ORDER_NEXT'),
            amount: actualTotalAmount || '-1.00' // negative will throw an applepay error
          }
        }

        VNi4go.dropin.create(config)
      }
    }

  }, [dropInInitialized, shift4Token, orderTotalInCents, preValidation, t, eventsCallback, paymentsInCart])

  // check if we should reload drop in and make a new preauth call for a new total amount
  // this is only needed if Apple Pay is enabled
  useEffect(() => {
    if (prevActualTotalAmount !== actualTotalAmount && displayApplePay && dropInInitialized && shift4Token) {
      // the actual total amount to charge has changed, we need to
      // 1. reset shift4 token
      dispatch(resetShift4PaymentToken())
      // 2. change the key of the drop in container to guarantee reloading
      setDropInContainerKey(dropInContainerKey + 1)
      // 3. reset flag
      setDropInInitialized(false)
      // 4. set shift4Loaded to 'false' in VNPaymentSystem to disable Order Now button while reloading
      setShift4Loaded(false)
    }
  }, [displayApplePay, prevActualTotalAmount, actualTotalAmount, dropInInitialized, shift4Token])

  // need to retrieve the Shift4 payment token from the preauthorization.
  // order id and order total is required for Shift4 to process Apple Pay transactions.
  useEffect(() => {
    if (displayApplePay) {
      if (!user.isEmpty() && shift4Token === null && orderId && orderTotalInCents) {
        dispatch(retrieveShift4PaymentToken(orderId, Money.centsToFloat(orderTotalInCents), t('CURRENCY'), s4PaymentsRegion))
      }
    } else {
      if (!user.isEmpty() && shift4Token === null) {
        dispatch(retrieveShift4PaymentToken(null, null, t('CURRENCY'), s4PaymentsRegion))
      }
    }
  }, [user, shift4Token, orderId, webSDKData, orderTotalInCents, dispatch, t])

  // #region LOCAL STATE

  const applePayPressed = (data) => {
    let total

    // If split payements, calculate for OTP
    if (data.orderParams.payments) {
      let amountCovered = 0
      data.orderParams.payments.forEach(payment => {
        amountCovered += payment.amount_in_cents
      })

      total = data.orderTotalInCents - amountCovered
    } else {
      total = Money.centsToFloat(orderTotalInCents)
    }

    apiPosti4GoApplePayLink(
      latestOrderTotal,
      data.orderParams,
      orderId,
      total,
      t('CURRENCY'),
      standId,
      standName,
      userJWT,
      ORG_NAME).then(response => {
        sendSDKIOSRequesti4GoApplePay(response.data.id)
    }).catch(error => {})
  }

  return (
    <Box className={classes.root}>
      <div id='dropin-container' key={dropInContainerKey}></div>
    </Box>
  )
})
