import * as ActionTypes from './ActionTypes'
import { Map, List } from 'immutable'

const getInitialState = () => {
  return Map({
    'revenueCenters': Map({}),
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNSTAND_TRUNCATED_STAND_FROM_MENU:
      return state.set('revenueCenters', Map(action.stand))
    default:
      return state
  }
}

export default reducer
