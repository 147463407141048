import values from 'lodash/values'
import numeral from 'numeral'

class ExperienceAnalytics {
  // Enhanced Ecommerce Funnel Part 2, adding an item to the cart
  // Google Tag Manager Ecommerce Event add item to cart
  // https://developers.google.com/tag-manager/enhanced-ecommerce#add
  static generateAddToCartEvent = (item, quantity) => {
    window.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'add': {
          'products': [{
            'id': item.globalItemId,
            'name': item.name,
            'price': numeral(item.price).value(),
            'quantity': quantity,
            'variant': `${item.eventName} - ${item.eventDate}`,
          }]
        }
      }
    })
  }

  // Experience Orders
  // Enhanced Ecommerce Funnel Part 3.1, viewing the cart for checkout
  // Google Tag Manager Ecommerce Event successful cart load
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkoutstep
  static generateOrderCheckoutEvent = (items) => {
    const products = values(items).map((item) => {
      const variant = item.variant

      return {
        'id': variant.globalItemId,
        'name': variant.name,
        'price': numeral(variant.price).value(),
        'quantity': item.quantity,
        'variant': `${variant.eventName} - ${variant.eventDate}`,
      }
    })

    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 1
          },
          products
        }
      }
    })
  }

  // Experience Orders
  // Enhanced Ecommerce Funnel Part 3.3, completing the purchase
  // Google Tag Manager Event successful order creation
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
  static generateOrderSuccessEvent = (order, cartItems) => {
    const standName = values(cartItems)[0].variant.categoryName

    const products = order.line_items.map((lineItem) => {
      const quantity = lineItem.quantity
      const cartItem = values(cartItems).find((item) => {
        return item.variant.menuItemUuid === lineItem.item_id
      })
      const variant = cartItem.variant

      return {
        'name': variant.name,
        'id': variant.globalItemId,
        'category': 'Marketplace',
        'brand': variant.categoryName,
        'price': numeral(lineItem.total).value(),
        'quantity': quantity,
        'variant': `${variant.eventName} - ${variant.eventDate}`,
      }
    })

    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'affiliation': `${standName} - Marketplace`,
            'id': order.id,
            'revenue': numeral(order.total).value(),
          },
          products
        }
      }
    })

    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 3
          }
        }
      }
    })
  }

}

export default ExperienceAnalytics
