import React, { useEffect } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Divider, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import List from "@material-ui/core/List"
import { Tune } from '@material-ui/icons'

import { VNRCTabSystem } from '../components/VNRCTabSystem'
import { VNSelectInput } from '../../VNComponents/VNSelectInput'
import { VNDetailedListItem } from '../../VNComponents/VNDetailedListItem'
import { VNIconButton } from '../../VNComponents/VNIconButton'

import { setServiceTypeSelected } from '../../VNQueryParams/ActionCreators'
import { setCartMenuId } from '../../VNMenu/ActionCreators'
import { setEventFilter } from '../../actions/filter'
import { clearCart } from '../../actions/cart'
import { clearLatestOrder, clearOrderError } from '../../actions/order'
import { clearOrderTotal } from '../../actions/orderTotal'
import { resetLastOrderTotalUuidAndMenuUuid } from '../../VNOrderTotal/ActionCreators'

import { getProductTypeSelected, getServiceTypeSelected } from '../../VNQueryParams/Selectors'
import { getStandsByProductType, getMenusKeyedByStandId, getFilteredStands } from '../Selectors'
import { getRvcFilterEmpty } from '../../VNFilter/Selectors'
import { getWebSDKMode } from '../../VNWebSDK/Selectors'
import {
  getDoesStandsHaveWaitTimes, getAvailableServiceTypesFromStands,
  getAvailableLocationsFromStands, getAvailableCategoriesFromStands
} from '../../VNRevenueCenters/Selectors'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import { getStandsOrder } from '../../VNConfigurations/Selectors'


import VNWebSDKAnalytics from '../../VNWebSDK/reporting/VNWebSDKAnalytics'
import { history } from '../../App/store/configureStore'
import { PRIVACY_POLICY_URL } from '../../constants'
import { getMenuByServiceType, getWaitTimeStringFromMenu } from '../../VNUtil/VNMenuHelper'
import { apiFetchAllRevenueCenters } from '../../VNRevenueCenters/Api'
import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'

import { get } from 'lodash'
import clsx from 'clsx'
import Skeleton from 'react-loading-skeleton'

const useStyles = makeStyles(theme => ({
  root: {

  },
  filters: {
    display: 'flex',
    paddingTop: '16px',
    paddingLeft: '16px'
  },
  filterIconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid ' + theme.custom.colors.lightGrey,
    borderRadius: '6px',
    paddingLeft: '7px',
    paddingRight: '7px',
    marginLeft: '10px',
    backgroundColor: theme.custom.colors.filterBackgroundGrey,
    color: theme.custom.colors.black
  },
  filterIconBtnActive: {
    backgroundColor: theme.custom.colors.filterActiveBackgroundBlue,
    color: theme.custom.colors.filterActiveBlue,
    borderColor: theme.custom.colors.filterActiveBlue
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '30px',
    marginTop: '10px',
    '& > span:first-of-type': {
      flex: 1
    },
    '& > span:nth-of-type(2)': {
      flex: 2
    },
    '& > span:nth-of-type(3)': {
      flex: 0.5
    }
  },
  notAccepting: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '60px'
  },
  donut: {
    marginBottom: '20px',
    fontSize: '40px'
  },
  currentlyNot: {
    marginBottom: '15px'
  }
}))

/**
 *
 */
export const VNRevenueCenters = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const theme = useTheme()

  const { t } = useTranslation()

  //#region SELECTORS
  const serviceTypeSelected = useSelector(state => getServiceTypeSelected(state))
  const productTypeSelected = useSelector(state => getProductTypeSelected(state))
  const productStands = useSelector(state => getStandsByProductType(state, productTypeSelected, serviceTypeSelected))
  const menusByStandId = useSelector(state => getMenusKeyedByStandId(state))
  const isRvcFilterEmpty = useSelector(state => getRvcFilterEmpty(state))
  const filteredStands = useSelector(state => getFilteredStands(state, productTypeSelected, serviceTypeSelected, t))
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const doesStandsHaveWaitTimes = useSelector(state => getDoesStandsHaveWaitTimes(state, productTypeSelected, t))
  const availableServiceTypesFromAllStands = useSelector(state => getAvailableServiceTypesFromStands(state, productTypeSelected))
  const availableLocations = useSelector(state => getAvailableLocationsFromStands(state, productTypeSelected))
  const availableCategories = useSelector(state => getAvailableCategoriesFromStands(state, productTypeSelected))
  const fetchAllRevenueCentersApiStatus = useSelector(state => getLoadingSystemStatus(state, apiFetchAllRevenueCenters.name))
  const standsOrder = useSelector(state => getStandsOrder(state))
  //#endregion

  //#region LOCAL STATE
  //#endregion

  //#region USE EFFECTS
  useEffect(() => {
    // make sure to scroll to the top
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // switch the title
    if (productTypeSelected === 'Food'){
      document.title = t('FBMENUS')
    } else if (productTypeSelected === 'Merchandise'){
      document.title = t('SHOP_MERCH')
    } else if (productTypeSelected === 'Experience'){
      document.title = t('MARKETPLACE')
    }
  }, [productTypeSelected])

  // clear event filter
  useEffect(() => {
    dispatch(setEventFilter(undefined))
    dispatch(clearCart())
    dispatch(clearLatestOrder())
    dispatch(clearOrderError())
    dispatch(clearOrderTotal())
    dispatch(resetLastOrderTotalUuidAndMenuUuid())
  }, [])

  //#endregion

  //#region LOCAL FUNCTIONS
  // translate service type locally on my end
  /**
   * Tells you if the menu is available or not.
   * @param {Obj} menu - The menu object
   * @returns {Boolean} - Returns true is the menu is available, false otherwise
   */
  const isMenuAvailable = (menu) => {
    if (menu && menu.hasOwnProperty('is_available')) {
      if ((!menu.is_available || (!menu.order_ahead && !menu.order_now))) {
        return false
      }
    }

    if (menu === undefined) {
      return false
    }

    return true
  }

  /**
   * Given a stand, get their service offerings as a string
   * @param {Obj} stand - The stand object
   * @param {Obj} menus - This is a keyed dictionary of menus that are keyed by the service offering (Concessions, Delivery, etc)
   */
  const getStandServiceOfferings = (stand, menus) => {

    let response = {
      list: [],
      display: null
    }

    if (stand.service_types) {

      const serviceTypesAvailable = []

      stand.service_types.forEach((serviceType) => {
        if (serviceType.name === 'Concessions' && menus[serviceType.name]) {
          serviceTypesAvailable.push(t('PICKUP'))
        } else if (serviceType.name === 'Delivery' && menus[serviceType.name]) {
          serviceTypesAvailable.push(t('DELIVERY'))
        }
      })

      if (serviceTypesAvailable.length > 1) {
        serviceTypesAvailable.sort()
        response.display = `${serviceTypesAvailable[0]} & ${serviceTypesAvailable[1]}`
        response.list.push(serviceTypesAvailable[0])
        response.list.push(serviceTypesAvailable[1])
      } else {
        response.display = serviceTypesAvailable[0]
        response.list.push(serviceTypesAvailable[0])
      }
    }

    return response
  }

  const getStandsList = () => {
    if (productTypeSelected === 'Experience') {
      return productStands
    }

    return isRvcFilterEmpty ? productStands : filteredStands
  }

  const shouldShowRvcFilter = () => {
    return doesStandsHaveWaitTimes ||
      availableServiceTypesFromAllStands.length > 1 ||
      availableLocations.length > 0 ||
      availableCategories.length > 0
  }

  //if only one tab then no need to show table label and tab
  const isShowTab = () => {
    if (standsOrder && standsOrder.length === 1){
      return false
    }
    return true
  }
  //#endregion

  //#region HANDLERS
  /**
   * When a user clicks or touches a stand, this is called.
   * @param {Obj} stand - The stand obj
   * @param {String} menuId - The UUID of the menu for this stand that was selected
   */
  const handleStandClick = (stand, menuId) => {
    const menusInStand = menusByStandId.get(stand.uuid)
    const targetMenu = menusInStand.find(menu => menu.uuid === menuId)

    VNWebSDKAnalytics.reportRvcSelection(stand.uuid, stand.name, stand.product_type)

    // determine what the path is for the URL based off the service type of the stand
    const path = stand.service_type === "Experience" ? "experiences" : "menu"

    dispatch(setCartMenuId(menuId))

    if (targetMenu.preorder) {
      history.push(`/${stand.uuid}/preorder/${menuId}`)
      return
    }

    history.push(`/${stand.uuid}/${path}/${menuId}`)
  }

  /**
   *
   * @param {String} val
   */
  const onHandleServiceTypeSelected = (val) => {
    if (val === "Pickup") {
      dispatch(setServiceTypeSelected('Concessions'))
    } else {
      dispatch(setServiceTypeSelected(val))
    }
  }

  const handleRevenueCenterFilterClicked = () => {
    history.push('/filter')
  }
  //#endregion

  //#region RENDERERS
  const displaySkeletonListItem = (key) => {
    return (
      <Box pt={2} key={key}>
        <Skeleton width='100%' height='200px' />
        <Box className={classes.skeleton}>
          <Skeleton height='20px' />
          <span />
          <Skeleton height='20px' />
        </Box>
        <Box>
          <Divider />
        </Box>
      </Box>
    )
  }
  /**
   *
   * @param {*} pStands
   */
  const displayList = (pStands) => {

    if (fetchAllRevenueCentersApiStatus.status !== ApiLoadingStatus.SUCCEEDED) {
      return [0,1,2].map(index => {
        return displaySkeletonListItem(index)
      })
    } else if(fetchAllRevenueCentersApiStatus.status === ApiLoadingStatus.SUCCEEDED && pStands.length === 0) {
      return(
        <Box className={classes.notAccepting}>
          <Typography className={classes.donut} variant="h6">🍩</Typography>
          <Typography className={classes.currentlyNot} variant="h4">{t('CURRENTLY_NOT')}</Typography>
          <Typography variant="subtitle3">{t('PLEASE_CHECK')}</Typography>
        </Box>
      )
    }

    // this pStands is already filtered by the serviceType that has been selected
    return pStands.map((stand) => {

      const productType = get(stand, 'product_type', undefined)
      const concessionsMenu = getMenuByServiceType(menusByStandId, stand.uuid, "Concessions")
      const deliveryMenu = getMenuByServiceType(menusByStandId, stand.uuid, "Delivery")

      let targetMenu

      // check to see if the concessions menu or the delivery menu is available for ordering
      let isAvailable = isMenuAvailable(concessionsMenu) || isMenuAvailable(deliveryMenu)

      targetMenu = concessionsMenu?.uuid !== undefined ? concessionsMenu : deliveryMenu

      const serviceOfferings = getStandServiceOfferings(stand, { "Concessions": concessionsMenu, "Delivery": deliveryMenu })

      if (productTypeSelected === "Experience") {
        targetMenu = getMenuByServiceType(menusByStandId, stand.uuid, "Experience")
        isAvailable = isMenuAvailable(targetMenu)
      }

      // image for display
      let image = stand.images ? stand.images.detail : targetMenu.images.detail

      return (
        <Box key={stand.uuid} pt={2}>
          <VNDetailedListItem
            title={stand.name}
            titleVariant="h1"
            subTitle={stand.description}
            isAvailable={isAvailable}
            disabled={!isAvailable}
            waitTime={getWaitTimeStringFromMenu(productType, concessionsMenu?.wait_time_in_minutes, deliveryMenu?.wait_time_in_minutes, t)}
            bottomModifierText={serviceOfferings.display}
            largeImage={image}
            largeImageAlt={t('LOCATION_IMAGE_ALT')}
            secondaryBottomModifierText={t('CLOSED')}
            click={() => handleStandClick(stand, targetMenu?.uuid)}
          />
          <Box pt={2}>
            <Divider />
          </Box>
        </Box>
      )
    })
  }

  /**
   *
   */
  const displaySelectorServiceType = () => {

    if (productTypeSelected !== 'Experience') {

      let servicesAvailableForFilter = availableServiceTypesFromAllStands

      if (servicesAvailableForFilter.length > 1) {
        servicesAvailableForFilter.push(t('ALL'))
        servicesAvailableForFilter.sort()
      }

      let defaultValue = t('ALL')
      if (serviceTypeSelected === "Concessions") {
        defaultValue = t('PICKUP')
      } else if (serviceTypeSelected === "Delivery") {
        defaultValue = t('DELIVERY')
      } else if (serviceTypeSelected === "Preorder") {
        defaultValue = t('PREORDER')
      }

      if (servicesAvailableForFilter.length > 1) {
        return(
          <Box>
            <VNSelectInput
              variant="outlined"
              default={defaultValue}
              title={t('SERVICE_TYPES')}
              onSelection={onHandleServiceTypeSelected}
              inputs={servicesAvailableForFilter} />
          </Box>
        )
      }

      if (fetchAllRevenueCentersApiStatus.status !== ApiLoadingStatus.SUCCEEDED) {
        return <Skeleton width='120px' height='51px' />
      }
    }

    return null
  }

  const displayRevenueCenterFilter = () => {
    if (productTypeSelected !== 'Experience' && shouldShowRvcFilter()) {
      return (
        <VNIconButton
          classes={{
            root: isRvcFilterEmpty ? classes.filterIconBtn : clsx(classes.filterIconBtn, classes.filterIconBtnActive)
          }}
          icon={<Tune fontSize="large" />}
          click={handleRevenueCenterFilterClicked}
        />
      )
    }
  }

  const displayPrivacyPolicy = () => {
    if (PRIVACY_POLICY_URL) {
      return (
        <Box pt={2} pb={3}>
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
            <Typography variant="subtitle1">{t('PRIVACY_POLICY')}</Typography>
          </a>
        </Box>
      )
    }
  }
  //#endregion

  return (
    <Box className={classes.root}>
      {isShowTab() && <VNRCTabSystem webSDKMode={webSDKMode} />}
      <Box className={classes.filters}>
        {displaySelectorServiceType()}
        {displayRevenueCenterFilter()}
      </Box>
      <Box pl={2} pr={2}>
        <List>
          {displayList(getStandsList())}
        </List>
        {displayPrivacyPolicy()}
      </Box>
    </Box>
  )
}
