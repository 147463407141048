
/**
 * Loading state of API calls to be used in apiStatusObj
 */
export const ApiLoadingStatus = {
  UNKNOWN: 'UNKNOWN',
  LOADING: 'LOADING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
}

export default ApiLoadingStatus
