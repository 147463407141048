import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../../reducers'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import watchAll from '../../sagas'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import analyticsMiddleware from '../../middleware/analyticsMiddleware'
import VNWebSDKAnalyticsMiddleware from '../../VNWebSDK/middleware/VNWebSDKAnalyticsMiddleware'
import Immutable from 'immutable'
import immutableTransform from 'redux-persist-transform-immutable'
import { createBrowserHistory } from 'history'
import initialState from './initialState'
import { ORG_NAME, INSTANCE_NAME, LOCAL_STORAGE_BUSTER_VERSION } from '../../constants'
import semver from 'semver'

export const history = createBrowserHistory()

const org = process.env.REACT_APP_ORG || 'default'

let localStorageBusterVersion = localStorage.getItem(`${ORG_NAME}_${INSTANCE_NAME}_version`)

console.log(`Local Storage Buster Version: ${localStorageBusterVersion} - Buster Version: ${LOCAL_STORAGE_BUSTER_VERSION}`)

if (!localStorageBusterVersion || !semver.valid(localStorageBusterVersion) || semver.lt(localStorageBusterVersion, LOCAL_STORAGE_BUSTER_VERSION)) {
  console.log('Busting your local storage')
  localStorage.clear()
  localStorage.setItem(`${ORG_NAME}_${INSTANCE_NAME}_version`, LOCAL_STORAGE_BUSTER_VERSION)
}

const persistConfig = {
  transforms: [immutableTransform()],
  key: `${org}:root`,
  storage,
  blacklist: ['remote', 'filter', 'VNWebAccount', 'VNSnackbarSystem', 'VNBackdropSystem', 'VNApiLoadingSystem', 'VNDialogs', 'VNOTP', 'seat', 'VNStand', 'VNFilter'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools(
  {
    trace: true,
    traceLimit: 25,
    serialize: {
      immutable: Immutable
  }
})

const middleware = composeEnhancers(
  applyMiddleware(
    sagaMiddleware,
    thunk,
    analyticsMiddleware,
    VNWebSDKAnalyticsMiddleware
  ),
)

export const store = createStore(
  persistedReducer,
  initialState,
  middleware,
)

export const persistor = persistStore(store)

sagaMiddleware.run(watchAll)

