import * as ActionTypes from './ActionTypes'
import { Map, List } from 'immutable'

const getInitialState = () => {
  return Map({
    'promo_codes': List([]),
    'last_entered_promo_code': '',
    'stand_availability': Map({}),
    'orders': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNORDERS_SET_PROMO_CODES:
      return state.set('promo_codes', List(action.codes))
    case ActionTypes.VNORDERS_SET_LAST_PROMO_CODE:
      return state.set('last_entered_promo_code', action.code)
    case ActionTypes.VNORDERS_SET_STAND_AVAILABILITY:
      return state.set('stand_availability', Map(action.stand_availability))
    case ActionTypes.VNORDERS_SET_ORDER:
      return state.setIn(['orders', action.order.data.id], action.order)
    default:
      return state
  }
}

export default reducer
