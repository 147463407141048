import React, { useEffect, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Skeleton from 'react-loading-skeleton'

import { MAKE_QR_SCAN_ORDER } from '../../actions/order'
import { addItemToCart, setActiveMenu, clearCart } from '../../actions/cart'
import { setWebSDKDataReceive } from '../../VNWebSDK/ActionCreators'
import { clearOrderTotal } from '../../actions/orderTotal'
import { fetchAllRevenueCenters } from '../../VNRevenueCenters/ActionCreators'
import { apiFetchAllRevenueCenters } from '../../VNRevenueCenters/Api'
import { fetchOrderTotal } from '../../VNOrderTotal/ActionCreators'
import { resetLastOrderTotalUuidAndMenuUuid } from '../../VNOrderTotal/ActionCreators'

import { getUser } from '../../VNUser/Selectors'
import { getStands } from '../../VNRevenueCenters/Selectors'
import { getOrderTotal } from '../../selectors/orderTotal'
import { getWebSDKPlatform, getWebSDKDataReceive } from '../../VNWebSDK/Selectors'

import WebSDKPlatform from '../../VNEnums/WebSDKPlatform'
import { history } from '../../App/store/configureStore'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import humps from 'humps'
import { fetchMenuDetails } from '../../VNMenu/ActionCreators'
import { getItemsInMenu } from '../../VNMenu/Selectors'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import { ApiLoadingStatus } from '../../VNApiLoadingSystem/types/ApiLoadingStatus'
import { setSnackbarSystemData } from '../../VNSnackbarSystem/ActionCreators'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0 16px'
  },
  skeleton1: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  }
}))

/**
 * Used when user scans a QR Code directly from their camera scanner
 * and lands here directly, example path on arena-dev:
 * //vn/order/pay?t=6000&p=AUw1gPLXZk3KqVIqqLOdV+qrKmH/aYVK4K92KpTt6V8ULFeSyQ4aTh2Prx5VMMFVAwABTDCCEx8OTa6WgteXwc9TTQAB&n=106002
 */
export const VNRichCheckoutCameraOrder = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const theme = useTheme()

  const { t } = useTranslation()

  //#region LOCAL STATE
  // If we should navigate to cart after state is updated
  const [gotoCart, setGotoCart] = useState(false)
  // Order total to check against so we can determine when to proceed to cart
  const [cachedOrderTotal, setCachedOrderTotal] = useState({})

  const [scannedMenuUuid, setScannedMenuUuid] = useState()
  //#endregion
  
  //#region SELECTORS
  const user = useSelector(state => getUser(state))
  const userJWT = user.get('jwt')
  const orderTotal = useSelector(state => getOrderTotal(state))
  const stands = useSelector(state => getStands(state))

  // Old redux state menu items, list of items for the active menu
  const menuItems = useSelector(state => getItemsInMenu(state, scannedMenuUuid))

  const fetchAllRevenueCentersApiStatus = useSelector(state => getLoadingSystemStatus(state, apiFetchAllRevenueCenters.name))
  const fetchOrderTotalStatus = useSelector(state => getLoadingSystemStatus(state, fetchOrderTotal.name))
  const menuLoadingStatus = useSelector(state => getLoadingSystemStatus(state, fetchMenuDetails.name))

  const isStandsLoading = fetchAllRevenueCentersApiStatus.status === ApiLoadingStatus.LOADING
  const hasOrderTotalsSucceeded = fetchOrderTotalStatus.status === ApiLoadingStatus.SUCCEEDED
  const hasMenuLoadingSucceeded = menuLoadingStatus.status === ApiLoadingStatus.SUCCEEDED

  const showErrorMessage = fetchAllRevenueCentersApiStatus.status === ApiLoadingStatus.FAILED
    || fetchOrderTotalStatus.status === ApiLoadingStatus.FAILED
    || menuLoadingStatus.status === ApiLoadingStatus.FAILED

  const webSDKPlatform = useSelector(state => getWebSDKPlatform(state))
  const webSDKData = useSelector(state => getWebSDKDataReceive(state))
  //#endregion

  // Split and join necessary steps because + are stripped
  const qrParam = queryString.parse(window.location.search).p.split(' ').join('+')
  const qrOrderNumber = queryString.parse(window.location.search).n

  //#region USE EFFECTS
  useEffect(() => {
    dispatch(clearCart())
    dispatch(clearOrderTotal())
    dispatch(resetLastOrderTotalUuidAndMenuUuid())
  }, [])

  // fetch stands
  useEffect(() => {
    if (!user.isEmpty() && userJWT && isEmpty(stands)) {
      dispatch(fetchAllRevenueCenters())
    }
  }, [dispatch, stands, user, userJWT])

  // fetch order total
  useEffect(() => {
    if (!isEmpty(stands) && !isStandsLoading && userJWT) {
      if (qrParam) {
        dispatch(fetchOrderTotal(qrParam, qrOrderNumber, true))
      }
    }
  }, [dispatch, stands, isStandsLoading, userJWT, qrParam, qrOrderNumber])

  // fetch menu details so that menuItems would exist
  // so that items can be populated correctly in cart
  useEffect(() => {
    if (orderTotal && !isEmpty(orderTotal.latest) && !gotoCart && !scannedMenuUuid) {
      const standMenuUuid = orderTotal.latest.attributes.standMenuUuid
      if (standMenuUuid) {
        setScannedMenuUuid(standMenuUuid)
        dispatch(fetchMenuDetails(standMenuUuid))
      }
    }
  }, [dispatch, orderTotal, gotoCart, scannedMenuUuid])

  // check if everything is ready to run proceedToCartOnScan
  useEffect(() => {
    if (orderTotal && !isEmpty(orderTotal.latest) && !gotoCart && hasOrderTotalsSucceeded && hasMenuLoadingSucceeded) {
      proceedToCartOnScan(orderTotal)
    }
  }, [orderTotal, gotoCart, hasOrderTotalsSucceeded, hasMenuLoadingSucceeded])

  useEffect(() => {
    // If order total is populated after scan, proceed to cart
    if (!isEmpty(menuItems) && gotoCart) {
      // fromRoute prop is used in Cart to ensure clearing of cart
      // when pressing back navigation
      history.replace('/cart', {
        fromRoute: VNRichCheckoutCameraOrder.name,
        isRichCheckoutQR: true
      })
    }
  }, [menuItems, gotoCart, cachedOrderTotal])

  useEffect(() => {
    if (showErrorMessage) {
      dispatch(setSnackbarSystemData({
        action: {
          title: t('RELOAD'),
          onClick: () => window.location.reload()
        },
        autoHideDuration: 10000,
        message: t('SOMETHING_WENT_WRONG'),
        open: true,
      }))
    }
  }, [dispatch, t, showErrorMessage])
  //#endregion

  //#region METHODS
  const proceedToCartOnScan = (order) => {
    const orderData = order.latest
    const lineItems = orderData.relationships.lineItems.data
    const included = order.latestLineItem

    let receiptOrderLineItem = {}

    // Need to iterate through items and create orderLineItem in state
    // to conform with existing Cart/OrderSummary code
    lineItems.forEach((lineItem) => {
      included.forEach((include) => {
        if (lineItem.id === include.id) {
          receiptOrderLineItem[lineItem.id] = include
        }
      })
    })

    // dispatch directly as no action or saga needs to happen
    dispatch({
      type: MAKE_QR_SCAN_ORDER,
      payload: {
        order: order,
        orderLineItem: receiptOrderLineItem
      }
    })

    // Need to run through all includes and transform into expected data format.
    // Data from API when looping through assumes that items with a parentLineItemUuid === null
    // IS the parent item, and any subsequent items before hitting another null are modifiers of that item
    let acc = included.reduce((accumulator, cur) => {
      // If multiples of same item
      let matchingItem
      if (!cur.attributes.parentLineItemUuid) {
        matchingItem = accumulator.filter(item => item.menuItemUuid === cur.attributes.menuItemUuid)
        // Oddly, the cart reducer is expecting items that are of the same menuItemUuid to have incrementing
        // quantities. So if there were 3 'hot dogs', the first would have a quantity of 1, then the second would have a
        // quantity of 2, etc.
        accumulator.push({
          id: cur.attributes.menuItemUuid,
          menuItemUuid: cur.attributes.menuItemUuid,
          quantity: matchingItem ? matchingItem.length + 1 : 1,
          modifiers: []
        })
      } else {
        accumulator[accumulator.length-1].modifiers.push({
          quantity: 1,
          menuItemUuid: cur.attributes.menuItemUuid
        })
      }
      return accumulator
    }, [])

    dispatch(setActiveMenu(
      orderData.attributes.standMenuUuid,
      orderData.attributes.standUuid
    ))

    humps.camelizeKeys(acc).forEach(item => {
      dispatch(addItemToCart(
        item.menuItemUuid,
        item.quantity,
        item.modifiers,
        true
      ))
    })

    setCachedOrderTotal(orderTotal)

    setGotoCart(true)

    if (webSDKPlatform === WebSDKPlatform.IOS) {
      // Clear as to prevent looping
      dispatch(setWebSDKDataReceive({}))
    }
  }
  //#endregion

  return (
    <Box className={classes.root}>
      <Box pt={2} pb={1}>
        <Skeleton />
      </Box>
      <Box pb={2}>
        <Skeleton />
        <Skeleton />
      </Box>
      <Box pb={2}>
        <Skeleton width='60%' height='50px' />
      </Box>
      <Divider />
      <Box pt={2} pb={1}>
        <Skeleton height='10px' />
        <Skeleton height='10px' />
        <Skeleton height='10px' />
      </Box>
      <Box pb={2}>
        <Skeleton height='10px' width='35%' />
      </Box>
      <Divider />
      <Box pt={2} pb={1}>
        <Skeleton height='10px' />
        <Skeleton height='10px' />
        <Skeleton height='10px' />
      </Box>
      <Box pb={2}>
        <Skeleton height='10px' width='35%' />
      </Box>
      <Divider />
      <Box pt={2} pb={2}>
        <Skeleton width='100%' height='50px' />
      </Box>
      <Box className={classes.skeleton1} pb={2}>
        <Box>
          <Skeleton width='44vw' height='50px' />
        </Box>
        <Box>
          <Skeleton width='44vw' height='50px' />
        </Box>
      </Box>
      <Box pb={2}>
        <Skeleton width='100%' height='50px' />
      </Box>
      <Box pb={2}>
        <Skeleton width='100%' height='50px' />
      </Box>
    </Box>
  )
}

// Override Function.name because build minification mangles function names
// and some functions aren't retaining their names properly which affects
// code in other places, namely Cart.js
Object.defineProperty(VNRichCheckoutCameraOrder, 'name', { value: 'VNRichCheckoutCameraOrder' })
