import WebSDKAnalyticsEvent from '../../VNEnums/WebSDKAnalyticsEvent'
import { sendAnalyticsEvent } from '../../VNWebSDK/bridgeCalls/VNWebSDKDataSend'

class VNWebSDKAnalytics {
  static reportUser = (id, email, name) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.TRACK_USER,
      id: id,
      email: email,
      name: name
    })
  }

  static reportUserAffiliations = (affiliations) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.TRACK_USER_AFFILIATIONS,
      affiliations: affiliations ? affiliations : []
    })
  }

  static reportRvcSelection = (id, name, category) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.TRACK_RVC_SELECTION,
      id: id,
      name: name,
      category: category ? category: ""
    })
  }

  static reportMenuItemSelection = (id, name, category, variant, price) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.TRACK_MENU_ITEM_SELECTION,
      id: id,
      name: name,
      category: category ? category : "",
      variant: variant ? variant : "",
      price: price
    })
  }

  static reportBeginCheckout = () => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.BEGIN_CHECKOUT
    })
  }

  static reportAddItemToCart = (id, name, category, variant, price, quantity) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.ADD_ITEM_TO_CART,
      id: id,
      name: name,
      category: category ? category : "",
      variant: variant ? variant : "",
      price: price,
      quantity: quantity
    })
  }

  static reportRemoveItemFromCart = (id, name, category, variant, price, quantity) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.REMOVE_ITEM_FROM_CART,
      id: id,
      name: name,
      category: category ? category : "",
      variant: variant ? variant : "",
      price: price,
      quantity: quantity
    })
  }

  static reportCompletedPurchase = (id, quantity, discount, tips, tax, total, paymentTypes, name, email) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.COMPLETED_PURCHASE,
      id: id,
      quantity: quantity,
      discount: discount,
      tips: tips,
      tax: tax,
      total: total,
      paymentTypes: paymentTypes,
      name: name,
      email: email
    })
  }

  static reportCheckoutProgress = (id, state) => {
    sendAnalyticsEvent({
      event: WebSDKAnalyticsEvent.CHECKOUT_PROGRESS,
      id: id,
      state: state
    })
  }
}

export default VNWebSDKAnalytics
