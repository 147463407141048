import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import classNames from 'classnames'

class GenericCounter extends Component {
  state = {
    counter: null,
    timestamp: '',
  }

  static defaultProps = {
    counterDidFinish: () => {},
  }

  componentDidMount() {
    this.startCounting()
  }

  componentWillUnmount() {
    this.stopCounting()
  }

  startCounting = () => {
    clearInterval(this.state.counter)

    this.setState({
      counter: setInterval(this.updateCounter, 1000),
    })
  }

  stopCounting = () => {
    const { counterDidFinish } = this.props
    clearInterval(this.state.counter)
    this.setState({ counter: null }, counterDidFinish)
  }

  updateCounter = () => {
    const { startTime, seconds } = this.props
    const now = moment()
    const end = moment(startTime).add(seconds, 'seconds')
    const difference = moment.duration(now.diff(end));
    const elapsedTime = Math.floor(difference.asSeconds())

    const timestamp = moment().startOf('day').add(Math.abs(elapsedTime), 'seconds').format('mm:ss')

    if (elapsedTime > 0) {
      this.stopCounting()
      return
    }

    this.setState({ timestamp })
  }

  render() {
    const { className, renderCallback } = this.props
    const { timestamp } = this.state

    if (!renderCallback) return null

    return (
      <div className={classNames('generic-counter', className)}>
        {renderCallback(timestamp)}
      </div>
    )
  }
}

GenericCounter.propTypes = {
  renderCallback: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
  seconds: PropTypes.number.isRequired,
  counterDidFinish: PropTypes.func,
}

export default GenericCounter
