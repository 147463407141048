/* eslint-disable max-len */
const applePay = require('./applePay')
const googlePay = require('./googlePay')

// the root component to attach to
let root

const initialize = (walletRoot, language, decodedToken, pSdkMode, applePayOptions, googlePayOptions, preValidation, hasBeenInitialized) => {

  root = walletRoot

  if (applePayOptions) {
    root.appendChild(applePay.initialize(applePayOptions, decodedToken, pSdkMode, language, preValidation, hasBeenInitialized))
  }

  if (googlePayOptions) {
    root.appendChild(googlePay.initialize(googlePayOptions, decodedToken, pSdkMode, language, preValidation, hasBeenInitialized))
  }
}

module.exports = {
  initialize: initialize
}
