import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'lastOrderTotalAdditionalInfo': Map({}),
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNORDERTOTAL_SET_LAST_UUID_AND_MENU_ID:
      if (action.lastUuid && action.lastMenuUuid) {
        const newInfo = Map({
          'lastUuid': action.lastUuid,
          'lastMenuUuid': action.lastMenuUuid,
        })
        return state.set('lastOrderTotalAdditionalInfo', newInfo)
      }
      return state
    case ActionTypes.VNORDERTOTAL_RESET_LAST_UUID_AND_MENU_ID:
      return state.set('lastOrderTotalAdditionalInfo', Map({}))
    default:
      return state
  }
}

export default reducer
