import React, { Fragment } from 'react'
import { useTheme, makeStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import Box from '@material-ui/core/Box'
import ListItemText from "@material-ui/core/ListItemText"
import clsx from 'clsx'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  root: {

  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  outerBox: {
    width: "100%"
  },
  innerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%",
    overflowWrap: 'anywhere', // Chrome, Firefox
    wordBreak: 'break-all' // Safari
  },
  chip: {
    height: 20,
  },
  notAvailable: {
    opacity: 0.65,
  },
  listItemText: {
    wordBreak: 'break-word'
  },
  bottomModifier: {
    display: 'inline-grid',
    width: "100%",
    gridTemplateColumns: 'max-content auto auto',
    '& div:nth-child(2)': {
      '&::before': {
        content: '"|"',
        position: 'absolute',
        marginLeft: -14
      },
      marginLeft: 20,
    },
    '& div:nth-child(3)': {
      justifySelf: 'flex-end'
    }
  },
  leftArea: props => ({
    display: 'flex',
    justifyContent: 'center',
    flex: props.leftAreaRatio || props.leftAreaRatio === 0 ? props.leftAreaRatio : 1.5
  }),
  centerArea: props => ({
    display: 'flex',
    flex: getCenterAreaFlexRatio(props),
    '& > div': {
      width: '100%'
    }
  }),
  rightArea: props => ({
    display: 'flex',
    flex: props.rightAreaRatio || props.rightAreaRatio === 0 ? props.rightAreaRatio : 2
  })
}));

const getCenterAreaFlexRatio = (props) => {
  let totalRatio = 10
  if (props.leftAreaRatio || props.leftAreaRatio === 0) {
    totalRatio -= props.leftAreaRatio ? props.leftAreaRatio : 1.5
  }
  if (props.rightAreaRatio || props.rightAreaRatio === 0) {
    totalRatio -= props.rightAreaRatio ? props.rightAreaRatio : 2
  }
  return totalRatio
}

/**
 * Meant to serve as a replacement for VNDetailedListItem as too many customizations
 * were being added to it. Chip modifier, Title, Subtitle, Bottom Left Text and Bottom
 * Link Text are all included in this class, with two areas being available for custom content:
 * leftArea and rightArea. The leftArea and rightArea accept commponents, and you can set their
 * widths via leftAreaRatio and rightAreaRatio, which are percentage based off of a scale to 10.
 * The center area with the default content will take up the rest of the width of the cell.
 *
 * There is also a top area that takes up the entire width of the cell, as well as a bottom area
 * that matches the width of the center area.
 * @param {string} title - The title for the center area
 * @param {string} titleTextVariant - The useTheme based name of the generated theme class to apply instead of default, which is theme.typography.h1
 * @param {subTitle} subTitle - The subtitle for the center area
 * @param {string} bottomModifierText - Modifer text at the bottom left of the item
 * @param {string} bottomLinkText - Modifier text at the bottom and to the right of bottomModiferText
 * @param {Func} onBottomLinkTextClick - (REQUIRED IF bottomLinkText SET) - Called if the user clicks the bottomLinkText
 * @param {Func} click - Called if the user clicks the list item
 * @param {component} leftArea - The left area component content
 * @param {float} leftAreaRatio - The width ratio of the left area, percentage float based to 10
 * @param {component} rightArea - The right area component content
 * @param {float} rightAreaRatio - The width ratio of the right area, percentage float based to 10
 * @param {component} topArea - The top area component content
 * @param {component} bottomArea - The bottom area component content
 */
export const VNDetailedListItemArea = (props) => {

  const classes = useStyles(props)

  const theme = useTheme()

  const displayTopArea = () => {
    return (
      <Fragment>
        {props.topArea}
      </Fragment>
    )
  }

  const displayLeftArea = () => {
    return (
      <div className={clsx(classes.leftArea, props.leftAreaClass)}>
        {props.leftArea}
      </div>
    )
  }

  const displayCenterArea = () => {
    return (
      <div className={clsx(classes.centerArea, classes.centerAreaClass)}>
        <Box>
          {
            props.title &&
            <ListItemText
              className={clsx(classes.listItemText, props.customTitleStyle)}
              primary={props.title}
              disableTypography
              style={props.titleTextVariant ? props.titleTextVariant : theme.typography.h1}
            />
          }
          {
            props.subTitle &&
            <ListItemText
              className={classes.listItemText}
              primary={props.subTitle}
              primaryTypographyProps={{variant: "subtitle1"}}
            />
          }
          {
            props.eventDate &&
            <ListItemText
              className={classes.listItemText}
              primary={props.eventDate}
              primaryTypographyProps={{variant: "subtitle1"}}
            />
          }
          {
            props.salesEventText &&
            <ListItemText
              className={classes.listItemText}
              primary={props.salesEventText}
              primaryTypographyProps={{variant: "subtitle1"}}
            />
          }
          {
            props.expiration &&
            <ListItemText
              className={classes.listItemText}
              primary={`Expires: ${props.expiration}`}
              primaryTypographyProps={{variant: "subtitle1"}}
              style={{ fontStyle: 'italic' }}
            />
          }
          {
            props.note &&
            <ListItemText
              className={classes.listItemText}
              primary={`Note: ${props.note}`}
              primaryTypographyProps={{variant: "subtitle1"}}
              style={{ fontStyle: 'italic' }}
            />
          }
          <Box className={classes.bottomModifier}>
            {displayBottomModifierText()}
            {displayBottomLinkText()}
          </Box>
          <Box>
            {displayCenterBottomArea()}
          </Box>
        </Box>
      </div>
    )
  }

  const displayCenterBottomArea = () => {
    return (
      <Fragment>
        {props.bottomArea}
      </Fragment>
    )
  }

  const displayRightArea = () => {
    return (
      <div className={clsx(classes.rightArea, props.rightAreaClass)}>
        {props.rightArea}
      </div>
    )
  }

  const displayBottomModifierText = () => {
    if (props.bottomModifierText) {
      return (
        <Box pt={1}>
          <ListItemText
            primary={props.bottomModifierText}
            disableTypography
            style={props.bottomModifierTextVariant ? props.bottomModifierTextVariant : theme.typography.overline}
          />
        </Box>
      )
    }
  }

  const displayBottomLinkText = () => {
    if (props.bottomLinkText) {
      return (
        <Box pt={1}>
          <Link
            onClick={(e)=>{
              // Stop propagation to have Link that is clickable inside
              // of a parent
              e.stopPropagation()
              if (props.onBottomLinkTextClick) {
                props.onBottomLinkTextClick()
              }
            }}
          >
            <ListItemText
              primary={props.bottomLinkText}
              primaryTypographyProps={{variant: "overline"}}
            />
          </Link>
        </Box>
      )
    }
  }

  //#region HANDLERS
  // called when the user clicks the list item
  const handleClick = () => {
    if (props.click) {
      props.click()
    }
  }
  //#endregion

  /**
   * set the is available class on a list item, which sets the opacity down, does NOT disable it
   */
  const getIsAvailableClass = () => {
    if ('isAvailable' in props && props.isAvailable === false) {
      return classes.notAvailable
    }
  }

  return (
    <ListItem
      button
      key={props.text}
      onClick={handleClick}
      className={clsx(classes.listItem, getIsAvailableClass())}
    >
      <Box className={classes.outerBox} pt={1} pb={1}>
        {displayTopArea()}
        <Box className={classes.innerBox}>
          {displayLeftArea()}
          {displayCenterArea()}
          {displayRightArea()}
        </Box>
      </Box>
    </ListItem>
  )
}
