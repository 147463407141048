export const mockRequestSMSOrderUpdates = () => {
  return new Promise((resolve, reject) => {
    resolve({ data: {}})
    // reject({error: 'error'})
  })
}

export const mockGetApiVerificationCode = () => {
  return new Promise((resolve, reject) => {
    // resolve({ data: {}})
    reject({error: 'error'})
  })
}

export const mockGetApiBraintreeClientToken = () => {
  return new Promise((resolve) => {
    resolve({ data: {}})
  })
}

export const mockGetApiShift4ServerAndAccessBlock = () => {
  return new Promise((resolve) => {
    resolve({ data: {
      server: 'https://i4go-payment.shift4test.com',
      access_block: 'A0000,8DD3D28AC0813D6ADEECE5F9C78D9CAB26729AAE428F1DCECD138A61146D04C6DC9DC2649A67546D6A388DB81F2578E22C5EF74D99D376E7CCF5A05B9CC9B081783944956746F7C7F87229F311B3F406A8296BC4E4B4DEDC7D27A8E681A8C36625F002229EA9ACCE5F8D6E8A4794728AE3081C3BECF2DB7E08438957EA8E47EA5ACB6FF90108F6D8062DC972BFFF9E15CD882C26E207A3A05FC254A8FDE7742F36813A1C4744014D35D4FD2CE8B9A512F24DB8646F80FF9C2550AD101F44B6E922F8A8694C698565615F9F18860F2754E620D51B099A89ADFCAD4BBE34CC29059D5221798CB25C101241E6BEBE46BD46B0A3507CFC1C7DDD59F6574810801575'
    }})
  })
}

export const mockGetApiOrderHistory = () => {
  return new Promise((resolve) => {
    resolve({ data: {
      data: [
        {
          "id": "b65ef5fa-4274-40ef-85ff-3c6b5b3901f1",
          "type": "order",
          "attributes": {
            "order_number": "0142",
            "success": true,
            "created_at": "2020-05-29T04:38:30Z",
            "updated_at": "2020-05-29T04:38:31Z",
            "subtotal": "5.00",
            "discount_amount_in_cents": "0",
            "total": "5.00",
            "tip": "0.00",
            "tax": "0.00",
            "delivery_fee": "0.00",
            "service_charge": "0.00",
            "totals_with_payment_types": [],
            "stand_name": "F&B Test",
            "stand_uuid": "e271f93e-0e3b-4c18-8b45-89c05a76b1b5",
            "state": "completed",
            "state_display_name": "Completed",
            "state_description": "Your order is in completed state",
            "usage_type": "pickup",
            "cancellable": false,
            "email": "eddie@venuenext.com",
            "last_four": null,
            "credit_card_type": null,
            "user_notes": null,
            "payment_authorized_at": null,
            "payment_settled_at": null,
            "payment_voided_at": null,
            "payment_refunded_at": null,
            "stand_menu_uuid": "55559869-3e16-4acd-87e5-a35f7e49a8de",
            "order_location": null,
            "uses_qr_code": false,
            "receipt_url": "https://arena-dev.ordernext.com/orders/b65ef5fa-4274-40ef-85ff-3c6b5b3901f1",
            "fulfillment_date": "2020-05-29",
            "fulfillment_slot": null,
            "user_attributes": {},
            "user_name": "Jeff",
            "order_type": "day_of_event",
            "payment_info": [],
            "tax_rate": "0.0"
          },
          "relationships": {
            "line_items": {
              "data": [
                {
                  "id": "3da7189c-cccd-468b-80da-101985c4cd68",
                  "type": "line_item"
                }
              ]
            }
          }
        },
    ]}})
  })
}

export const mockGetApiExperienceApiOrderHistory = () => {
  return new Promise((resolve) => {
    resolve({ data: [
        {
          "id": "31d2f13f-d7d5-4609-90e1-6c65fd3913a1",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "user_email": "eddie@venuenext.com",
          "completed_at": "2020-09-22T15:21:42.000Z",
          "canceled_at": null,
          "item_names": ["Halftime Magicgram (2 events - no scan)"],
          "created_at": "2020-09-22T15:21:41.278Z",
          "quantity": 1,
          "total": "10.5",
          "order_number": "4327",
          "refunded_at": null,
          "state": "completed",
          "user_refundable": false,
          "product_type": "Experience",
          "line_items": [{
            "id": "1987b3d4-f4c5-4259-ad8d-f04e690c1545",
            "quantity": 1,
            "total": "10.5",
            "name": "Halftime Magicgram (2 events - no scan)",
            "price": "10.5",
            "event_uuid": "53c61080-3f10-4ec8-a881-0968efc225f9",
            "item_id": "e02534f0-8d99-48e0-821c-6538b504d5c0",
            "expires_at": "2020-12-05T23:02:00.000Z"
          }],
          "merchant": {
            "id": "072b9bad-1c7d-4642-a6da-59ab553c9260",
            "name": "Merchant",
            "merchant_account_id": "VN_Test",
            "default_merchant": true,
            "organization_name": "orlando_magic",
            "venue_uuid": "6a23420c-0181-401b-c016-e7a242d42930"
          },
          "user_items": [{
            "id": "5692945b-5d6c-49e5-9ff7-f8656f9589aa",
            "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
            "phone_number": null,
            "email": null,
            "used_at": null,
            "redemption_code": "iS7wUqs31WoNnuBizCqBTMFc",
            "state": "purchased",
            "created_at": "2020-09-22T15:22:04.786Z",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "order_id": "c190c493-5415-41e6-b703-0eddf6338d4c",
            "redeemable": true,
            "refundable": false,
            "active_transfer_uuid": null,
            "metadata": [],
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "event_date": "2020-10-26"
          }],
          "items": [{
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 37,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "purchase_limit": null,
            "global_item": {
              "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
              "name": "Locker Room Visit (1 STH event + 2 ALL events)",
              "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
              "item_type": "venue_experience",
              "item_count": 8,
              "email_ops": [],
              "redemption_type": "countdown_timer",
              "short_description": "Get to know the team.",
              "organization_name": "orlando_magic",
              "archived_at": null,
              "countdown_seconds": 8
            }
          }]
        }, {
          "id": "c190c493-5415-41e6-b703-0eddf6338d4c",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "user_email": "eddie@venuenext.com",
          "completed_at": "2020-09-22T15:22:05.000Z",
          "canceled_at": null,
          "item_names": ["Locker Room Visit (1 STH event + 2 ALL events)"],
          "created_at": "2020-09-22T15:22:04.798Z",
          "quantity": 1,
          "total": "915.0",
          "order_number": "4328",
          "refunded_at": null,
          "state": "redeemed",
          "user_refundable": false,
          "product_type": "Experience",
          "line_items": [{
            "id": "5800a369-def0-4287-8efb-7e353937425a",
            "quantity": 1,
            "total": "915.0",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "price": "915.0",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "expires_at": "2020-11-29T23:32:00.000Z"
          }],
          "merchant": {
            "id": "072b9bad-1c7d-4642-a6da-59ab553c9260",
            "name": "Merchant",
            "merchant_account_id": "VN_Test",
            "default_merchant": true,
            "organization_name": "orlando_magic",
            "venue_uuid": "6a23420c-0181-401b-c016-e7a242d42930"
          },
          "user_items": [{
            "id": "5692945b-5d6c-49e5-9ff7-f8656f9589aa",
            "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
            "phone_number": null,
            "email": null,
            "used_at": null,
            "redemption_code": "iS7wUqs31WoNnuBizCqBTMFc",
            "state": "purchased",
            "created_at": "2020-09-22T15:22:04.786Z",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "order_id": "c190c493-5415-41e6-b703-0eddf6338d4c",
            "redeemable": true,
            "refundable": false,
            "active_transfer_uuid": null,
            "metadata": [],
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "event_date": "2020-10-26"
          }],
          "items": [{
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 37,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "purchase_limit": null,
            "global_item": {
              "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
              "name": "Locker Room Visit (1 STH event + 2 ALL events)",
              "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
              "item_type": "venue_experience",
              "item_count": 8,
              "email_ops": [],
              "redemption_type": "countdown_timer",
              "short_description": "Get to know the team.",
              "organization_name": "orlando_magic",
              "archived_at": null,
              "countdown_seconds": 8
            }
          }]
        }, {
          "id": "03955afc-20a7-43d2-85a6-40e90a532365",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "user_email": "eddie@venuenext.com",
          "completed_at": "2020-09-22T15:22:20.000Z",
          "canceled_at": null,
          "item_names": ["Locker Room Visit (1 STH event + 2 ALL events)", "Locker Room Visit (1 STH event + 2 ALL events)", "Locker Room Visit (1 STH event + 2 ALL events)", "Locker Room Visit (1 STH event + 2 ALL events)"],
          "created_at": "2020-09-22T15:22:19.374Z",
          "quantity": 4,
          "total": "3660.0",
          "order_number": "4329",
          "refunded_at": null,
          "state": "partially_redeemed",
          "user_refundable": false,
          "product_type": "Experience",
          "line_items": [{
            "id": "9c08a5fa-5fef-4b54-a250-14d677a71ea1",
            "quantity": 1,
            "total": "915.0",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "price": "915.0",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "expires_at": "2020-11-29T23:32:00.000Z"
          }, {
            "id": "5d52b238-de02-4ef7-81a9-ffbc1746bea8",
            "quantity": 1,
            "total": "915.0",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "price": "915.0",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "expires_at": "2020-11-29T23:32:00.000Z"
          }, {
            "id": "07962a09-ecde-4a4c-a4e8-3e28113fcfaf",
            "quantity": 1,
            "total": "915.0",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "price": "915.0",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "expires_at": "2020-11-29T23:32:00.000Z"
          }, {
            "id": "87817154-c626-4dd2-af96-8da02b28e738",
            "quantity": 1,
            "total": "915.0",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "price": "915.0",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "expires_at": "2020-11-29T23:32:00.000Z"
          }],
          "merchant": {
            "id": "072b9bad-1c7d-4642-a6da-59ab553c9260",
            "name": "Merchant",
            "merchant_account_id": "VN_Test",
            "default_merchant": true,
            "organization_name": "orlando_magic",
            "venue_uuid": "6a23420c-0181-401b-c016-e7a242d42930"
          },
          "user_items": [{
            "id": "5692945b-5d6c-49e5-9ff7-f8656f9589aa",
            "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
            "phone_number": null,
            "email": null,
            "used_at": null,
            "redemption_code": "iS7wUqs31WoNnuBizCqBTMFc",
            "state": "purchased",
            "created_at": "2020-09-22T15:22:04.786Z",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "order_id": "c190c493-5415-41e6-b703-0eddf6338d4c",
            "redeemable": true,
            "refundable": false,
            "active_transfer_uuid": null,
            "metadata": [],
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "event_date": "2020-10-26"
          }],
          "items": [{
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 37,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "purchase_limit": null,
            "global_item": {
              "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
              "name": "Locker Room Visit (1 STH event + 2 ALL events)",
              "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
              "item_type": "venue_experience",
              "item_count": 8,
              "email_ops": [],
              "redemption_type": "countdown_timer",
              "short_description": "Get to know the team.",
              "organization_name": "orlando_magic",
              "archived_at": null,
              "countdown_seconds": 8
            }
          }]
        }, {
          "id": "c4ee9761-7675-4fe3-aac6-d2b36332559a",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "user_email": "eddie@venuenext.com",
          "completed_at": "2020-09-22T15:27:06.000Z",
          "canceled_at": null,
          "item_names": ["Halftime Magicgram (2 events - no scan)"],
          "created_at": "2020-09-22T15:27:05.887Z",
          "quantity": 1,
          "total": "10.5",
          "order_number": "4331",
          "refunded_at": null,
          "state": "completed",
          "user_refundable": false,
          "product_type": "Experience",
          "line_items": [{
            "id": "dabc0792-f064-4346-af07-037e20c12972",
            "quantity": 1,
            "total": "10.5",
            "name": "Halftime Magicgram (2 events - no scan)",
            "price": "10.5",
            "event_uuid": "53c61080-3f10-4ec8-a881-0968efc225f9",
            "item_id": "e02534f0-8d99-48e0-821c-6538b504d5c0",
            "expires_at": "2020-12-05T23:02:00.000Z"
          }],
          "merchant": {
            "id": "072b9bad-1c7d-4642-a6da-59ab553c9260",
            "name": "Merchant",
            "merchant_account_id": "VN_Test",
            "default_merchant": true,
            "organization_name": "orlando_magic",
            "venue_uuid": "6a23420c-0181-401b-c016-e7a242d42930"
          },
          "user_items": [{
            "id": "5692945b-5d6c-49e5-9ff7-f8656f9589aa",
            "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
            "phone_number": null,
            "email": null,
            "used_at": null,
            "redemption_code": "iS7wUqs31WoNnuBizCqBTMFc",
            "state": "purchased",
            "created_at": "2020-09-22T15:22:04.786Z",
            "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "order_id": "c190c493-5415-41e6-b703-0eddf6338d4c",
            "redeemable": true,
            "refundable": false,
            "active_transfer_uuid": null,
            "metadata": [],
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "event_date": "2020-10-26"
          }],
          "items": [{
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 37,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "purchase_limit": null,
            "global_item": {
              "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
              "name": "Locker Room Visit (1 STH event + 2 ALL events)",
              "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
              "item_type": "venue_experience",
              "item_count": 8,
              "email_ops": [],
              "redemption_type": "countdown_timer",
              "short_description": "Get to know the team.",
              "organization_name": "orlando_magic",
              "archived_at": null,
              "countdown_seconds": 8
            }
          }]
        }
      ]
    })
  })
}

export const mockGetApiAwardedTransferredExperiences = () => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "5692945b-5d6c-49e5-9ff7-f8656f9589aa",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": "2020-09-22T15:29:50.000Z",
          "redemption_code": "iS7wUqs31WoNnuBizCqBTMFc",
          "state": "purchased",
          "created_at": "2020-09-22T15:22:04.786Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": "c190c493-5415-41e6-b703-0eddf6338d4c",
          "redeemable": false,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "cea41606-1860-4a53-995f-29be9c389897",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": "2020-09-22T15:22:28.000Z",
          "redemption_code": "nKCdzUpN6xWxgmpohHwS193Z",
          "state": "purchased",
          "created_at": "2020-09-22T15:22:19.345Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": "03955afc-20a7-43d2-85a6-40e90a532365",
          "redeemable": false,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "292517f4-3a38-48b1-9e78-c3c58091501f",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": null,
          "redemption_code": "4PhgUycuGRoX2rGZwdm966zU",
          "state": "purchased",
          "created_at": "2020-09-22T15:22:19.349Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": "03955afc-20a7-43d2-85a6-40e90a532365",
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "7e1e5cf6-3ff1-468e-bf96-9486e686bd4e",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": null,
          "redemption_code": "8eZni5SN6vt5Shjs8YukZRjs",
          "state": "purchased",
          "created_at": "2020-09-22T15:22:19.353Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": "03955afc-20a7-43d2-85a6-40e90a532365",
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "7ccb9ccb-476b-4cdc-be1a-505004f2caab",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": "2020-09-22T15:22:23.000Z",
          "redemption_code": "hv4kdoDZ6JBTRr77N6wVFJTL",
          "state": "purchased",
          "created_at": "2020-09-22T15:22:19.362Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": "03955afc-20a7-43d2-85a6-40e90a532365",
          "redeemable": false,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "491f125a-bc81-479a-b2af-c67fe0b868fd",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": "edward@venuenext.com",
          "used_at": null,
          "redemption_code": "RwZj9kefFwNE6xvRubcRntL5",
          "state": "transfer_accepted",
          "created_at": "2020-09-22T15:24:30.574Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": null,
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "e8b45d3f-1687-4a22-be1c-1da1e32986c6",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": null,
          "redemption_code": "SJEjNdVa2qR2wZDFy9SZuCLY",
          "state": "awarded",
          "created_at": "2020-09-22T15:30:04.578Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": null,
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "3ca1ec80-ff80-4478-972b-7f7bb6482481",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "description": "Meet at the main elevator 2 hours before tip-off, and we'll take you on a tour while the guys get ready.",
            "item_type": "venue_experience",
            "item_count": 8,
            "email_ops": [],
            "redemption_type": "countdown_timer",
            "short_description": "Get to know the team.",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 8
          },
          "id": "6fcbdf41-12ed-4756-8468-4e3e27368da9",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": null,
          "redemption_code": "ERWBWMRNGqudZnr69H1eSz9d",
          "state": "awarded",
          "created_at": "2020-09-22T15:30:06.069Z",
          "item_id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
          "order_id": null,
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [],
          "name": "Locker Room Visit (1 STH event + 2 ALL events)",
          "event_date": "2020-10-26",
          "item": {
            "id": "2e4dde7f-f01f-4bf3-86b2-1d8e17b8f3a1",
            "event_uuid": "ccd06805-0e02-44ea-be11-34892b6ed19f",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-11-29T23:32:00.000Z",
            "inventory_count": 41,
            "redemption_count": 38,
            "item_type": "venue_experience",
            "name": "Locker Room Visit (1 STH event + 2 ALL events)",
            "available_at": "2019-05-26T22:32:15.000Z",
            "redemption_starts_at": "2020-04-30T22:32:00.000Z",
            "total_inventory_created": 124,
            "hidden_at": "2020-12-01T23:32:00.000Z",
            "redemption_type": "countdown_timer",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "0ea2002f-c822-45e7-9814-31b81fa51f80",
              "url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.jpeg",
              "webp_url": "firesale/d257c56a-2c54-4e11-9923-c006dbea2672-detail.webp",
              "image_type": "detail",
              "description": null
            }, {
              "id": "27fa6fac-50dc-4e8c-93fe-574b66b2f46e",
              "url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.jpeg",
              "webp_url": "firesale/3ca1ec80-ff80-4478-972b-7f7bb6482481-instruction.webp",
              "image_type": "instruction",
              "description": "Go behind-the-scenes at Amway Center! Get an exclusive tour of the player locker rooms and see where your favorite Orlando Magic players prepare for tip-off. Meet at Amway Center employee entrance located at the corner of South Street and Hughey. Arrival time 5:00pm. Tour will begin promptly at 5:15pm"
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "2b368b95-1411-4401-a145-479cfc88a807",
              "price": "915.0",
              "starts_at": "2019-05-26T22:36:00.000Z",
              "ends_at": "2020-11-27T23:36:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "05f37d77-114e-4053-9394-5d79250203d5",
            "name": "Halftime Magicgram (2 events - no scan)",
            "description": "Forget texting and tweeting! Make your message count with a Magic Gram shown on Magic TV, one of the largest video boards in the NBA. From birthday wishes and wedding proposals to shout-outs and acknowledgements, Magic Grams are the best (and biggest!) way to say you care.\r\n\r\nThis purchase does not admit entry into the Amway Center.  The fan must have a valid ticket to the game to enjoy this experience. ",
            "item_type": "venue_experience",
            "item_count": 11,
            "email_ops": [],
            "redemption_type": "admin",
            "short_description": "",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 90
          },
          "id": "54ea31ff-37e5-4f6d-9a76-dd884d256ffa",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": null,
          "redemption_code": "ft4xLoYsPoHwGEzTBaXmBaUz",
          "state": "purchased",
          "created_at": "2020-09-22T15:21:41.255Z",
          "item_id": "e02534f0-8d99-48e0-821c-6538b504d5c0",
          "order_id": "31d2f13f-d7d5-4609-90e1-6c65fd3913a1",
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [{
            "id": "3a8c81b2-0492-4d43-a9f7-8f7eec7871ed",
            "name": "Birthday",
            "description": null,
            "data_type": "age",
            "restriction": {
              "operator": "\u003e",
              "error_message": "must be an adult",
              "max_value": null,
              "value": "2019-09-22",
              "min_value": null
            },
            "value": "2004-09-22"
          }, {
            "id": "dd7aba80-a0d8-4009-b19b-e8313f791803",
            "name": "Message",
            "description": null,
            "data_type": "string",
            "restriction": null,
            "value": "HAppy birthday!"
          }],
          "name": "Halftime Magicgram (2 events - no scan)",
          "event_date": "2020-10-21",
          "item": {
            "id": "e02534f0-8d99-48e0-821c-6538b504d5c0",
            "event_uuid": "53c61080-3f10-4ec8-a881-0968efc225f9",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-12-05T23:02:00.000Z",
            "inventory_count": 28,
            "redemption_count": 2,
            "item_type": "venue_experience",
            "name": "Halftime Magicgram (2 events - no scan)",
            "available_at": "2019-09-24T22:02:33.000Z",
            "redemption_starts_at": "2020-08-30T13:02:00.000Z",
            "total_inventory_created": 111,
            "hidden_at": "2020-12-07T23:02:00.000Z",
            "redemption_type": "admin",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "b467ed54-0366-4521-af5f-108754ac7498",
              "url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-detail.jpeg",
              "webp_url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-detail.webp",
              "image_type": "detail",
              "description": ""
            }, {
              "id": "2603dd73-fe41-4671-949e-6d734e5d905a",
              "url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-instruction.jpeg",
              "webp_url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-instruction.webp",
              "image_type": "instruction",
              "description": "Please be in your seats at halftime to see your message after the halftime act performs."
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "7add6b65-e377-4cdb-87f8-cba73b649064",
              "price": "10.5",
              "starts_at": "2019-09-23T22:02:00.000Z",
              "ends_at": "2020-12-05T23:02:00.000Z",
              "group": "all"
            }]
          }
        }, {
          "global_item": {
            "id": "05f37d77-114e-4053-9394-5d79250203d5",
            "name": "Halftime Magicgram (2 events - no scan)",
            "description": "Forget texting and tweeting! Make your message count with a Magic Gram shown on Magic TV, one of the largest video boards in the NBA. From birthday wishes and wedding proposals to shout-outs and acknowledgements, Magic Grams are the best (and biggest!) way to say you care.\r\n\r\nThis purchase does not admit entry into the Amway Center.  The fan must have a valid ticket to the game to enjoy this experience. ",
            "item_type": "venue_experience",
            "item_count": 11,
            "email_ops": [],
            "redemption_type": "admin",
            "short_description": "",
            "organization_name": "orlando_magic",
            "archived_at": null,
            "countdown_seconds": 90
          },
          "id": "736ca8c9-f181-4f93-8bd5-a7584b587f1f",
          "user_uuid": "af06388f-08eb-43da-8ea8-9ce649412f5b",
          "phone_number": null,
          "email": null,
          "used_at": null,
          "redemption_code": "fjabU6Ey6UZCxhgqyqqPoRjp",
          "state": "purchased",
          "created_at": "2020-09-22T15:27:05.850Z",
          "item_id": "e02534f0-8d99-48e0-821c-6538b504d5c0",
          "order_id": "c4ee9761-7675-4fe3-aac6-d2b36332559a",
          "redeemable": true,
          "refundable": false,
          "active_transfer_uuid": null,
          "metadata": [{
            "id": "3a8c81b2-0492-4d43-a9f7-8f7eec7871ed",
            "name": "Birthday",
            "description": null,
            "data_type": "age",
            "restriction": {
              "operator": "\u003e",
              "error_message": "must be an adult",
              "max_value": null,
              "value": "2019-09-22",
              "min_value": null
            },
            "value": "2005-09-22"
          }, {
            "id": "dd7aba80-a0d8-4009-b19b-e8313f791803",
            "name": "Message",
            "description": null,
            "data_type": "string",
            "restriction": null,
            "value": "Teat"
          }],
          "name": "Halftime Magicgram (2 events - no scan)",
          "event_date": "2020-10-21",
          "item": {
            "id": "e02534f0-8d99-48e0-821c-6538b504d5c0",
            "event_uuid": "53c61080-3f10-4ec8-a881-0968efc225f9",
            "value": "0.0",
            "value_type": null,
            "expires_at": "2020-12-05T23:02:00.000Z",
            "inventory_count": 28,
            "redemption_count": 2,
            "item_type": "venue_experience",
            "name": "Halftime Magicgram (2 events - no scan)",
            "available_at": "2019-09-24T22:02:33.000Z",
            "redemption_starts_at": "2020-08-30T13:02:00.000Z",
            "total_inventory_created": 111,
            "hidden_at": "2020-12-07T23:02:00.000Z",
            "redemption_type": "admin",
            "transferable": true,
            "refundable": false,
            "archived_at": null,
            "purchasable": true,
            "groups": [],
            "images": [{
              "id": "b467ed54-0366-4521-af5f-108754ac7498",
              "url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-detail.jpeg",
              "webp_url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-detail.webp",
              "image_type": "detail",
              "description": ""
            }, {
              "id": "2603dd73-fe41-4671-949e-6d734e5d905a",
              "url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-instruction.jpeg",
              "webp_url": "firesale/05f37d77-114e-4053-9394-5d79250203d5-instruction.webp",
              "image_type": "instruction",
              "description": "Please be in your seats at halftime to see your message after the halftime act performs."
            }],
            "purchase_limit": null,
            "sales": [{
              "id": "7add6b65-e377-4cdb-87f8-cba73b649064",
              "price": "10.5",
              "starts_at": "2019-09-23T22:02:00.000Z",
              "ends_at": "2020-12-05T23:02:00.000Z",
              "group": "all"
            }]
          }
        }
      ]
    })
  })
}

export const mockPostApiRequesti4GoApplePayLink = () => {
  return new Promise((resolve) => {
    resolve({ data: {}})
  })
}
