import { HIDE_MERCH_STANDS } from '../constants.js'

import { getRvcFilterObj } from '../VNFilter/Selectors'
import { getMenuByServiceType, getWaitTimeStringFromMenu } from '../VNUtil/VNMenuHelper'
import { getProductTypeLabels } from '../VNConfigurations/Selectors'
import { getByKey } from '../VNConfigurations/Utils'

import { keys, get, isEmpty } from 'lodash'
import moment from 'moment'

/**
 * Get the venue display name for this project as a whole
 * @param {*} state - The state
 */
export const getVenueDisplayName = (state) => {
  const stands = state.VNRevenueCenters.get('stands')
  if (stands && stands.size > 0) {
    return stands.first().venue.display_name
  }

  return ''
}

export const getMenusKeyedByStandId = (state) => {
  return state.VNRevenueCenters.get('menus_by_stands')
}

/**
 * Get the first stand's UUID in state
 * @param {*} state
 * @returns {String}
 */
export const getFirstStandId = (state) => {
  return state.VNRevenueCenters.get('stands').first()?.uuid
}

export const getStands = (state) => {
  return state.VNRevenueCenters.get('stands')
}

/**
 * Not every stand that we have has a matching menu for that service type, this returns true/false if there is one or not.
 * @param {*} menus - The menu array that we have to search from, probably from state.VNRevenueCenters.get('revenue_centers').menus
 * @param {*} standUuid - The stand UUID
 * @param {*} serviceType - The service type from the server, Delivery or Concessions
 */
const doesStandHaveMenuForServiceType = (menus, standUuid, serviceType) => {
  if (menus) {
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i]
      if (menu.stand_uuid === standUuid && menu.service_type === serviceType) {
        return true
      }
    }
  }

  return false
}

/**
 * Get all the food and drink stands that are type of Concessions and Delivery
 * @param {Obj} state
 * @param {String} productType - Experience, Merchandise, or Food
 * @param {String} serviceType - What service types do you want to retrieve? - All, Concessions, Delivery
 */
export const getStandsByProductType = (state, productType, serviceType) => {
  const stands = getStands(state)
  const menusKeyedByStandId = getMenusKeyedByStandId(state)

  let response = []

  for (let stand of stands.values()) {

    let displayStand = false

    if (stand.product_type !== productType) {
      continue
    }

    let filterByServiceType = 'All'
    if (productType !== "Experience" && serviceType !== 'All') {
      filterByServiceType = serviceType
    }

    for (let i = 0; i < stand.service_types.length; i++) {
      const element = stand.service_types[i]

      if (element.name === "Concessions") {
        if (['All', 'Concessions', 'Preorder'].includes(filterByServiceType)) {
          displayStand = doesStandHaveMenuForServiceType(menusKeyedByStandId.get(stand.uuid), stand.uuid, element.name)
          if (displayStand) {
            break
          }
        }
      } else if (element.name === "Delivery") {
        if (['All', 'Delivery', 'Preorder'].includes(filterByServiceType)) {
          displayStand = doesStandHaveMenuForServiceType(menusKeyedByStandId.get(stand.uuid), stand.uuid, element.name)
          if (displayStand) {
            break
          }
        }
      } else if (element.name === "Experience") {
        displayStand = doesStandHaveMenuForServiceType(menusKeyedByStandId.get(stand.uuid), stand.uuid, element.name)
        if (displayStand) {
          break
        }
      } else if (element.name === "POS") {
        if (filterByServiceType === 'All') {
          let concessionsFlag = doesStandHaveMenuForServiceType(menusKeyedByStandId.get(stand.uuid), stand.uuid, 'Concessions')
          let deliveryFlag = doesStandHaveMenuForServiceType(menusKeyedByStandId.get(stand.uuid), stand.uuid, 'Delivery')
          displayStand = concessionsFlag || deliveryFlag
          if (displayStand) {
            break
          }
        }
      }
    }

    if (displayStand) {
      response.push(stand)
    }
  }

  return response
}

/**
 *
 * @param {*} state
 * @param {*} standUuid
 */
export const getStandById = (state, standUuid) => {
  // Merge VNStand in case we are deeplinking to a menu that doesn't have a stand in VNRevenueCenters returned.
  // This will typically happen when we want VISIBILITY = FALSE to a stand but still want to access the menu
  return state.VNStand.get('revenueCenters')
    .merge(state.VNRevenueCenters.get('cross_venue_stands_by_id'))
    .merge(state.VNRevenueCenters.get('stands_by_id'))
    .get(standUuid)
}

/**
 * what categories of products are available based off all the stands that are available?
 * @param {*} state
 * @param {*} t - translation
 */
export const getAvailableProductCategories = (state, t) => {

  const stands = state.VNRevenueCenters.get('stands')

  if (stands && stands.size > 0) {

    let productTypes = []

    let foundMerchItem = false
    let foundFoodItem = false
    let foundExperiencesItem = false

    const customLabels = {}
    const productTypeLabels = getProductTypeLabels(state)
    for (const productType of productTypeLabels) {
      customLabels[productType.toUpperCase()] = getByKey(state, 'product_type_label_' + productType)
    }

    for (let stand of stands.values()) {

      // check to see if there is one that has merchandise
      if (  !foundMerchItem &&
            ((stand.product_type && stand.product_type === 'Merchandise') || (stand.productType && stand.productType === 'Merchandise'))
            && !HIDE_MERCH_STANDS) {

              foundMerchItem = true

              productTypes.push({
                id: 'Merchandise',
                label: customLabels['MERCHANDISE'] || t('MERCHANDISE')
              })
      }

      // check to see if there is one that has experiences
      if (  !foundExperiencesItem &&
        ((stand.product_type && stand.product_type === 'Experience') || (stand.productType && stand.productType === 'Experience'))) {

          foundExperiencesItem = true

          productTypes.push({
            id: 'Experience',
            label: customLabels['EXPERIENCES'] || t('EXPERIENCES')
          })
      }

      // they aren't named consistently from the backend...
      if (  !foundFoodItem && ((stand.product_type && stand.product_type === 'Food') ||
            (stand.productType && stand.productType === 'Food')) &&
            (stand.service_types || stand.serviceTypes)) {

        const serviceTypes = stand.service_types ? stand.service_types : stand.serviceTypes

        // did we find at least one Food service type that has a non Quick Pay
        // using regular for loops so I can break out faster
        for (let j = 0; j < serviceTypes.length; j++) {

          const serviceType = serviceTypes[j].name

          if (serviceType === 'Delivery' ||
              serviceType === 'Concessions' ||
              serviceType === 'OrderAhead' ||
              serviceType === 'POS' ||
              serviceType === 'Kiosk') {

                foundFoodItem = true

                productTypes.push({
                  id: 'Food',
                  label: customLabels['FOOD_AND_DRINKS'] || t('FOOD_AND_DRINKS')
                })
                break
          }
        }
      }

      if (foundFoodItem && foundMerchItem && foundExperiencesItem) {
        break
      }

    }

    return productTypes
  }
}

/**
 * Get the list of service types available from all stands, limited to Concessions
 * and Delivery
 * @param {Object} state - The state
 * @param {String} productType - Experience, Merchandise, or Food
 * @returns
 */
export const getAvailableServiceTypesFromStands = (state, productType) => {
  const stands = getStands(state).toArray().filter(s => s.product_type === productType)
  const menusKeyedByStandId = getMenusKeyedByStandId(state)
  const preorderSalesEvents = getPreorderSalesEvents(state)

  const concessionsStands = stands.filter(s => {
    return s.service_types.filter(st => st.name === 'Concessions').length >= 1
  })

  const deliveryStands = stands.filter(s => {
    return s.service_types.filter(st => st.name === 'Delivery').length >= 1
  })

  const pickupStands = stands.filter(s => {
    const menus = menusKeyedByStandId.get(s.uuid)
    return menus?.filter(m => m.preorder === true).length >= 1
  })

  let response = []
  if (concessionsStands.length >=1) {
    response.push('Pickup')
  }

  if (deliveryStands.length >= 1) {
    response.push('Delivery')
  }

  if (pickupStands.length >= 1 && !isEmpty(preorderSalesEvents)) {
    response.push('Preorder')
  }

  return response
}

/**
 * Get the available service types that are found in the menus that
 * belong to the standId
 * @param {Object} state - The state
 * @param {String} standId - The UUID of the stand to get service types from
 * @returns
 */
export const getAvailableServiceTypesFromStand = (state, standId) => {
  const menusInStand = getMenusKeyedByStandId(state).get(standId)

  const services = keys(menusInStand).map(index => {
    const menuInStand = menusInStand[index]
    return {
      menuId: get(menuInStand, 'uuid'),
      usageType: get(menuInStand, 'usage_type'),
      serviceType: get(menuInStand, 'service_type'),
      preorder: get(menuInStand, 'preorder')
    }
  })

  const filteredServices = services.reduce((accumulator, item) => {
    if (['Concessions', 'Delivery'].includes(item.serviceType)) {
      if (!accumulator.find(e => e.serviceType === item.serviceType)) {
        accumulator.push(item)
      }
    }
    return accumulator
  }, [])

  return filteredServices
}

/**
 * Get an array of locations from all stands of a particular product type
 * @param {Object} state - The state
 * @param {String} productType - The product type, either Food or Merchandise
 */
export const getAvailableLocationsFromStands = (state, productType) => {
  const stands = getStandsByProductType(state, productType, 'All')

  return stands.reduce((accumulator, stand) => {
    const locations = stand.filter_data?.location

    const itemsToAdd = locations?.filter(e => {
      return accumulator.indexOf(e) === -1
    })

    return [
      ...accumulator,
      ...itemsToAdd ? itemsToAdd : []
    ]
  }, [])
}

/**
 * Get an arrray of categories from all stands of a particular product type
 * @param {Object} state - The state
 * @param {String} productType - The product type, either Food or Merchandise
 * @returns
 */
export const getAvailableCategoriesFromStands = (state, productType) => {
  const stands = getStandsByProductType(state, productType, 'All')

  return stands.reduce((accumulator, stand) => {
    const categories = stand.filter_data?.cuisine

    const itemsToAdd = categories?.filter(e => {
      return accumulator.indexOf(e) === -1
    })

    return [
      ...accumulator,
      ...itemsToAdd ? itemsToAdd : []
    ]
  }, [])
}

/**
 * Check to see if there are any wait times in a stand
 * @param {Object} state - The state
 * @param {String} productType - Experience, Merchandise, or Food
 * @param {Object} t - useTranslation object
 * @returns
 */
export const getDoesStandsHaveWaitTimes = (state, productType, t) => {
  const stands = getStands(state).toArray().filter(s => s.product_type === productType)
  const menusByStandId = getMenusKeyedByStandId(state)

  let waitTimeCount = 0
  stands.forEach(s => {
    const concessionsMenu = getMenuByServiceType(menusByStandId, s.uuid, 'Concessions', true)
    const deliveryMenu = getMenuByServiceType(menusByStandId, s.uuid, 'Delivery', true)

    const waitTimeString = getWaitTimeStringFromMenu(productType, concessionsMenu?.wait_time_in_minutes, deliveryMenu?.wait_time_in_minutes, t)

    if (!isEmpty(waitTimeString)) {
      waitTimeCount++
    }
  })

  return waitTimeCount > 0
}

/**
 * Get stands that have been filtered by filters selected by the user or that have been
 * detected from URL query parameters
 * @param {Object} state - The state
 * @param {String} productType - Product type is Food, Merchandise or Experiences
 * @param {String} serviceType - Service type is either Concessions or Delivery
 * @param {Object} t - useTranslation object
 * @returns
 */
export const getFilteredStands = (state, productType, serviceType, t) => {
  const stands = getStandsByProductType(state, productType, serviceType)
  const menusByStandId = getMenusKeyedByStandId(state)
  const filterObj = getRvcFilterObj(state)

  const serviceTypes = ['Concessions', 'Delivery', 'Preorder']

  // TODO: we are not maintaining serviceTypes in rvcFilterObj/queryParamRvcFilterObj
  // the only place we maintain serviceType is in VNQueryParams -> serviceTypeSelected
  // if that gets changed in the future we need to update here as well

  return stands.filter(e => {

    const concessionsMenu = getMenuByServiceType(menusByStandId, e.uuid, 'Concessions')
    const deliveryMenu = getMenuByServiceType(menusByStandId, e.uuid, 'Delivery')

    if (filterObj.get('noWaitTimes')) {
      const waitTimeString = getWaitTimeStringFromMenu(productType, concessionsMenu?.wait_time_in_minutes, deliveryMenu?.wait_time_in_minutes, t)

      // If there is no wait time, waitTimeString will be null
      if (waitTimeString) {
        return false
      }
    }

    if (serviceTypes.includes(serviceType)) {
      if (serviceType === 'Concessions' && isEmpty(concessionsMenu)) {
        return false
      }
      if (serviceType === 'Delivery' && isEmpty(deliveryMenu)) {
        return false
      }
      if (serviceType === 'Preorder') {
        if (!concessionsMenu?.preorder && !deliveryMenu?.preorder) {
          return false
        }
      }
    }

    const locations = filterObj.get('locations') ?? []
    if (locations.length) {
      if (!e.filter_data?.location?.length) {
        return false
      }
      const standLocations = e.filter_data.location.map(l => l.toLowerCase())
      const filterLocations = locations.map(l => l.toLowerCase())
      if (!standLocations.some(l => filterLocations.includes(l))) {
        return false
      }
    }

    const categories = filterObj.get('categories') ?? []
    if (categories.length) {
      if (!e.filter_data?.cuisine?.length) {
        return false
      }
      const standCategories = e.filter_data.cuisine.map(c => c.toLowerCase())
      const filterCategories = categories.map(c => c.toLowerCase())
      if (!standCategories.some(c => filterCategories.includes(c))) {
        return false
      }
    }

    return true
  })
}

/**
 * Get venue uuid from stands
 * We can just get it from the first stand since all stands will have the same venue uuid
 * @param {object} state - The state
 * @returns {string} - Venue uuid
 */
export const getVenueUuid = (state) => {
  const stands = state.VNRevenueCenters.get('stands')
  if (stands && stands.size > 0) {
    return stands.first().venue.uuid
  }

  return ''
}

export const getPreorderSalesEvents = (state) => {
  return state.VNRevenueCenters.get('sales_events').toObject()
}
