import React from 'react'
import QRCode from 'qrcode.react'
import Skeleton from 'react-loading-skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(_theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  justifyStart: {
    justifyContent: 'start',
  },
  qrCodeContainer: {
    padding: '10px 10px 6px 10px',
    backgroundColor: '#ffffff'
  }
}));

/**
 * Create a QR code
 * @param {string} text - The QR code text
 * @param {number} size - The size of the QR code
 * @param {boolean} loading - Whether to show Skeleton for loading
 */
export const VNQRCode = (props) => {

  const { loading, text, justifyStart, size = 215 } = props

  const classes = useStyles()

  const { t } = useTranslation()

  const renderCode = () => {
    if (loading) {
      return (
        <Skeleton width='215px' height='215px' />
      )
    }

    if (text) {
      return (
        <Box className={classes.qrCodeContainer}>
          <QRCode value={text} size={size} alt={t('QR_CODE')} />
        </Box>
      )
    }
  }

  return (
    <div className={clsx(classes.root, justifyStart ? classes.justifyStart : '')}>
      {renderCode()}
    </div>
  )
}
