import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'rvcFilterObj': Map({}),
    'queryParamRvcFilterObj': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNFILTER_SET_RVC_FILTERS:
      return state.set('rvcFilterObj', Map(action.rvcFilterObj))
    case ActionTypes.VNFILTER_SET_QUERY_PARAM_RVC_FILTERS:
      return state.set('queryParamRvcFilterObj', Map(action.rvcFilterObj))
    default:
      return state
  }
}

export default reducer
