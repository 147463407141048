import { INSTANCE_NAME } from '../constants'

import { getHighestRankedAffiliation } from '../VNUser/Selectors'

import ExclusivityType from '../VNEnums/ExclusivityType'

import { values, get, some, keys, isEmpty, maxBy } from 'lodash'

import humps from 'humps'

/**
 * Get the details of a specific menu that is our redux state.
 * @param {*} state - The state
 * @param {*} menuId - The menu ID
 */
export const getMenu = (state, menuId) => {
  if (menuId) {
    return state.VNMenu.get('menus').merge(state.VNMenu.get('crossVenueMenusById')).get(menuId)
  }
  return null
}

/**
 * Get the details of a specific item inside the menu
 * @param {Obj} state - The state
 * @param {String} menuId - The UUID of the menu
 * @param {String} itemId - the UUID of the item inside the menu
 */
export const getItemInMenu = (state, menuId, itemId) => {
  if (menuId && itemId) {
    const menu = getMenu(state, menuId)
    if (menu) {
      if (menu.keyed_items[itemId]) {
        return humps.camelizeKeys(menu.keyed_items[itemId])
      }
    }
  }
  return null
}

/**
 * Get the menu items that belong to a particular menu
 * @param {Object} state - The state
 * @param {String} menuId - The UUID of the menu
 */
export const getItemsInMenu = (state, menuId) => {
  const menu = getMenu(state, menuId)
  if (menu) {
    return humps.camelizeKeys(menu.items.concat(menu.service_fee_items))
  }

  return null
}

/**
 *
 * @param {*} state
 */
export const getAgeConfirmed = (state) => {
  return state.VNMenu.get('ageConfirmed')
}

/**
 *
 * @param {*} state
 */
export const getCart = (state) => {
  return state.VNMenu.get('cart')
}

/**
 * If cart.item is empty, then cart is empty
 * NOTE: This will need to be changed once cart is moved over to newer state
 * @param {Object} state - The state
 * @returns Boolean
 */
export const getIsCartEmpty = (state) => {
  return keys(get(state, 'cart.item', {})).length === 0
}

export const getExperienceEventFilter = (state) => {
  return state.VNMenu.get('experienceEventFilter')
}

/**
 * Get the filtered experience items for a menu based off of the
 * event filter chosen and business rules
 * @param {Object} state - The state
 * @param {String} menuId - The UUID of the menu
 */
 export const getFilteredExperienceItems = (state, menuId) => {
  const itemsInMenu = getItemsInMenu(state, menuId)
  const eventFilter = get(state, 'filter.eventFilter', undefined)

  if (eventFilter === undefined) {
    const result = values(itemsInMenu).reduce((accumulator, experience) => {
      const variant = get(experience, 'variants[0]', {})

      return {
        ...accumulator,
        [experience.uuid]: {
          ...experience,
          price: variant.price,
          onHandQuantity: variant.onHandQuantity,
          eventUuid: variant.eventUuid,
          purchaseLimit: variant.purchaseLimit,
        },
      }
    }, {})

    return result
  }

  const filteredExperiences = values(itemsInMenu).filter((itemInMenu) => {
    const menuItemEvents = get(itemInMenu, 'variants', [])

    return some(menuItemEvents, (item) => {
      return item.eventUuid === eventFilter
      // Leaving previous methodology commented as to ease possible future debugging cases
      // as we roll out exclusivity inclusion in experiences
      // return item.saleId && item.eventUuid === eventFilter
    })
  })

  const result = filteredExperiences.reduce((accumulator, experience) => {
    const variant = experience.variants.find(variant => variant.eventUuid === eventFilter)

    return {
      ...accumulator,
      [experience.uuid]: {
        ...experience,
        price: variant.price,
        onHandQuantity: variant.onHandQuantity,
        eventUuid: variant.eventUuid,
        purchaseLimit: variant.purchaseLimit,
      },
    }
  }, {})

  return result
}

/**
 * Filter experiences to show ordernext instance basis. Experiences
 * have variants in which they have event uuids attached to them. Go through
 * each experience and check their variants, if their variants have at least 1
 * event that has an event.instance name that is either empty or matches the
 * instance, then show (empty means not configured to be instance based).
 * @param {object} menuItems - Experience menu items
 * @param {object} events - Events available (not instance based list)
 * @returns
 */
export const getInstanceBasedItemsInMenu = (state, menuId, events) => {
  const menuItems = getFilteredExperienceItems(state, menuId)

  return keys(menuItems).reduce( (accumulator, menuItemId) => {
    const item = menuItems[menuItemId]
    const variants = item.variants
    let includeCount = 0

    // Go through variants, if more than 1 variant has the same instance name
    // that means we show the experience
    variants.forEach(variant => {
      const instance = events[variant?.eventUuid]?.instance
      if (instance === INSTANCE_NAME || isEmpty(instance)) {
        includeCount++
      }
    })

    // Only return experiences with an include count greater than 0
    return {
      ...accumulator,
      ...includeCount > 0 ? { [menuItemId] : item } : {}
    }
  }, {})
}

/**
 * Get the events that are attached to a particular menu
 * @param {Object} state - The state
 * @param {String} menuId - The UUID of the menu
 * @returns
 */
export const getInstanceBasedEventsInMenu = (state, menuId) => {
  const menu = getMenu(state, menuId)
  let events = menu?.events

  return keys(events).reduce((accumulator, event) => {
    const item = events[event]
    return {
      ...accumulator,
      ...(item.instance === INSTANCE_NAME || isEmpty(item.instance)) ? { [event]: item } : {}
    }
  }, {})
}

export const getExperienceItemInMenu = (state, menuId, itemId) => {
  const item = getItemInMenu(state, menuId, itemId)
  const eventFilter = get(state, 'filter.eventFilter', undefined)

  if (eventFilter === undefined) {
    const variant = get(item, 'variants[0]', {})

    return {
      ...item,
      price: variant.price,
      onHandQuantity: variant.onHandQuantity,
      eventUuid: variant.eventUuid,
      purchaseLimit: variant.purchaseLimit,
    }
  }

  const variant = item.variants.find(variant => variant.eventUuid === eventFilter)

  return {
    ...item,
    price: variant.price,
    onHandQuantity: variant.onHandQuantity,
    eventUuid: variant.eventUuid,
    purchaseLimit: variant.purchaseLimit,
  }
}

/**
 * Get an event from a particular menu
 * @param {Object} state - The state
 * @param {String} menuId - The UUID of the menu
 * @param {String} eventId - The UUID of the event
 * @returns
 */
export const getEvent = (state, menuId, eventId) => {
  const menu = getMenu(state, menuId)
  return menu?.events?.[eventId]
}

/**
 * Get unfiltered events in menu
 * @param {Object} state - The state
 * @param {String} menuId - The UUID of the menu
 * @returns
 */
export const getEventsInMenu = (state, menuId) => {
  const menu = getMenu(state, menuId)
  return menu?.events
}

/**
 * Get the user selected filtered event
 * @param {Object} state - The state
 * @returns
 */
export const getEventFilter = (state) => {
  return state.filter.eventFilter
}

/**
 * Get the variant of the event that is selected by the user
 * @param {Object} state - The state
 * @param {Object} exp - The experience item
 * @param {Object} event - The event selected by user that belongs to the exp item
 * @returns
 */
export const getEventVariantFromItem = (state, exp, event) => {
  if (!isEmpty(event)) {
    return get(exp, 'variants').find(v => {
      return event.id === v.eventUuid
    })
  }

  return undefined
}

/**
 * Get the events that belong to a specific experience item
 * @param {Object} state - The state
 * @param {String} itemId - The UUID of the event item
 * @returns
 */
export const getEventsInExperienceItem = (state, menuId, itemId) => {
  const events = getEventsInMenu(state, menuId)
  const item = getExperienceItemInMenu(state, menuId, itemId)
  // saleId is present when there's a sale for the "All group"
  // will be null if the variant only has season ticket holder or other exclusive group sales
  const variantsWithSales = get(item, 'variants', [])
  // Leaving previous methodology commented as to ease possible future debugging cases
  // as we roll out exclusivity inclusion in experiences
  // const variantsWithSales = get(item, 'variants', []).filter((variant) => variant.saleId)
  const experienceEvents = variantsWithSales.map((variant) => events[variant.eventUuid])

  return experienceEvents.reduce((accumulator, event) => {
    return {
      ...accumulator,
      [event.id]: event,
    }
  }, {})
}

/**
 * Get exclusivity types from the event
 * @param {Object} state - The state
 * @param {Object} variant - The event variant in which to get exclusivity types from
 * @returns
 */
export const getExclusivityTypes = (state, variant) => {
  if (variant) {
    return variant.sales.map(s => {
      return {
        type: ExclusivityType.getType(s.group),
        sale: s
      }
    })
  }

  return undefined
}

/**
 * Get the exclusivity object from variant type and exclusivity types available
 * @param {Object} state - The state
 * @param {Object} variant - The event variant in which to get exlusivity types from
 * @param {Array} exclusivityTypes - Exclusivity types available
 * @returns
 */
export const getExclusivityObject = (state, exclusivityTypes) => {
  const eventFromFilter = getEventFilter(state)
  const highestRankedAffiliation = getHighestRankedAffiliation(state)

  let returnValue = {}

  if (!isEmpty(eventFromFilter)) {
    // Get the highest priority exclusivity
    if (highestRankedAffiliation) {
      // Get first match
      returnValue = exclusivityTypes.find(et => {
        if (et.sale?.metadata?.tiers) {
          return et.sale.metadata.tiers.some(tier => tier.toLowerCase() === highestRankedAffiliation.name.toLowerCase())
        }
        return false
      })

      // Search was null for matching affiliation/tier, grab highest
      // priority and display, user cannot proceed in this event
      if (!returnValue) {
        returnValue = maxBy(exclusivityTypes, et => {
          return ExclusivityType.getPriority(et.type)
        })
      }
    } else {
      returnValue = maxBy(exclusivityTypes, et => {
        return ExclusivityType.getPriority(et.type)
      })
    }
  }

  return returnValue
}

export const getServiceFeeItems = (state, menuId) => {
  const menu = getMenu(state, menuId)

  return get(menu, 'service_fee_items', [])
}

export const getMenuItemSearchText = (state) => {
  return state.VNMenu.get('searchText')
}
