import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { VNCircleButton } from '../../VNComponents/VNCircleButton'
import { VNFullScreenCloseButton } from '../../VNComponents/VNFullScreenCloseButton'
import { enableBackdropSystem, disableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import { retrieveLoyaltyUser } from '../../VNWallet/ActionCreators'
import { hasTimeElapsed, timeLeft } from '../../VNUtil/VNTimeHelper'
import { refreshUsersAffiliations } from '../../VNUser/ActionCreators'
import { setSnackbarSystemDataAlertInfo } from '../../VNSnackbarSystem/ActionCreators'
import { getUser, getLastTimeAffiliationsRefreshed } from '../../VNUser/Selectors'
import { getBadgeDialogOpen } from '../Selectors'
import { setOpenBadgeDialog } from '../ActionCreators'
import { sendSDKModalOpen } from '../../VNWebSDK/bridgeCalls/VNWebSDKDataSend'
import { getWebSDKVersion } from '../../VNWebSDK/Selectors'
import { getVirtualCurrencyEnabled, getVirtualCurrencyName, getBadgeColors, getRefreshAffiliationsTimer } from '../../VNConfigurations/Selectors'
import { getLoyaltyUser } from '../../VNWallet/Selectors'
import Money from '../../utils/money'
import semver from 'semver'
import moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelIcon: {
    height: 40,
    width: 40,
    color: theme.custom.colors.white,
  },
  bottomText: {
    color: theme.custom.colors.white,
    opacity: 0.7,
    letterSpacing: '-0.04px',
    fontWeight: 400
  },
  refreshAffiliationsBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    // must be higher than z-index of 'touch'
    zIndex: 110
  },
  refreshAffiliationsText: {
    color: theme.custom.colors.white,
    letterSpacing: '-0.04px',
    fontSize: '14px',
    fontWeight: 700,
    // must be higher than z-index of 'touch'
    zIndex: 110
  },
  touch: {
    position: "absolute",
    height: window.screen.height - 100,
    width: '100%',
    top: 100,
    left: 0,
    zIndex: 100,
  },
  tier: {
    color: theme.custom.colors.white
  },
  userID: {
    lineHeight: '24px'
  },
  qrCode: {
    '& > div img': {
      width: '35%'
    }
  },
  circleButton: {
    width: 20,
    backgroundColor: '#FFFFFF !important'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 *
 */
export const VNBadge = ({highestRankedAffiliation}) => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const open = useSelector(state => getBadgeDialogOpen(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const virtualCurrencyName = useSelector(state => getVirtualCurrencyName(state))
  const isVirtualCurrencyEnabled = useSelector(state => getVirtualCurrencyEnabled(state))
  const badgeColors = useSelector(state => getBadgeColors(state))
  const loyaltyUser = useSelector(state => getLoyaltyUser(state))
  const lastTimeUserAffiliationsRefreshed = useSelector(state => getLastTimeAffiliationsRefreshed(state))
  const refreshUsersAffiliationsTime = useSelector(state => getRefreshAffiliationsTimer(state))

  // const affiliationsLoaded = lastTimeUserAffiliationsRefreshed && !hasTimeElapsed(lastTimeUserAffiliationsRefreshed, refreshUsersAffiliationsTime, 'minutes')
  const affiliationsLoaded = !!lastTimeUserAffiliationsRefreshed

  /**
   * Get the primary color from either the ranked affiliations, badge colors, or a default color.
   * @returns {String} Returns hex color
   */
   const getPrimaryColor = () => {
    if (highestRankedAffiliation) {
      return highestRankedAffiliation.primary_color
    } else if (badgeColors.primary_color && affiliationsLoaded) {
      return badgeColors.primary_color
    } else {
      return '#686868'
    }
  }

  /**
   * Get the secondary color from either the ranked affiliations, badge colors, or a default color.
   * @returns {String} Returns hex color
   */
  const getSecondaryColor = () => {
    if (highestRankedAffiliation) {
      return highestRankedAffiliation.secondary_color
    } else if (badgeColors.secondary_color && affiliationsLoaded) {
      return badgeColors.secondary_color
    } else {
      return '#3D3D3D'
    }
  }

  // LOCAL STATE
  const [backgroundColor, setBackgroundColor] = useState(getPrimaryColor())

  // HANDLES
  const onSwitchBackgroundColor = () => {
    if (backgroundColor === getPrimaryColor()) {
      setBackgroundColor(getSecondaryColor())
    } else {
      setBackgroundColor(getPrimaryColor())
    }

  }

  const onClose = (event) => {
    dispatch(setOpenBadgeDialog(false))
  }

  // USE EFFECTS

  /**
   * There is a chance that the highestRankedAffiliation color isn't get on the first useState call
   * We need to check on the open to ensure we set it
   */
  useEffect(() => {
    setBackgroundColor(getPrimaryColor())
  }, [highestRankedAffiliation])

  useEffect(() => {
    if (webSDKVersion && semver.gte(webSDKVersion, '2.0.4')) {
      sendSDKModalOpen({ 'badge': open ? 'yes' : 'no' })
    }
    document.title = t('MY_BADGE')
  }, [open, webSDKVersion])

  /**
   * It should fetch loyalty user and user affiliations
   */
  const onRefreshButtonClicked = () => {

    dispatch(enableBackdropSystem())

    dispatch(retrieveLoyaltyUser())

    // check current timestamp epoch, if its been more than X minutes, refresh user affiliations
    if (hasTimeElapsed(lastTimeUserAffiliationsRefreshed, refreshUsersAffiliationsTime, 'minutes')) {
      dispatch(refreshUsersAffiliations(t))
    } else {
      setTimeout(() => {
        dispatch(disableBackdropSystem())
        // use 1 as the lowest number for minute
        const timeRemaining = timeLeft(lastTimeUserAffiliationsRefreshed, refreshUsersAffiliationsTime, 'minutes') || 1
        dispatch(setSnackbarSystemDataAlertInfo(t('AFFILIATIONS_NOT_REFRESHABLE') + ' ' + timeRemaining + ' ' + t('MINUTES')))
      }, 1000)
    }
  }

  /**
   * Display the affiliation name
   * @returns {React Component} Returns a React component
   */
  const displayAffiliationName = () => {
    if (highestRankedAffiliation) {
      return (
        <Box>
          <Typography className={classes.tier} variant='h6'>{highestRankedAffiliation.name}</Typography>
        </Box>
      )
    }
  }

  /**
   * Displays the virtual currency if it is enabled and the user has a real balance.
   * @returns {React Component} Returns a React component
   */
  const displayVirtualCurrency = () => {
    if (isVirtualCurrencyEnabled && loyaltyUser.has('balance')) {
      return (
        <Box pt={2}>
          <Typography className={classes.userID} variant='h5'>{Money.centsToDollars(loyaltyUser.get('balance'), true, false)}  • {virtualCurrencyName}</Typography>
        </Box>
      )
    }
  }

  const displayRefreshAffiliationsButton = () => {
    return (
      <Box pl={1} className={classes.refreshAffiliationsBox}>
        <VNCircleButton
          className={classes.circleButton}
          icon={<RefreshIcon style={{'fontSize': '18px'}} />}
        />
      </Box>
    )
  }

  const displayRefreshAffiliations = () => {
    let text = t('REFRESH_BADGE_INFORMATION')

    if (lastTimeUserAffiliationsRefreshed) {
      const refreshedTime = moment(lastTimeUserAffiliationsRefreshed)
      text = `${t('LAST_UPDATED')} ${refreshedTime.format('MM/DD/YY')} ${t('AT')} ${refreshedTime.format('h:mma')}`
    }

    return (
      <Box pt={1} className={classes.refreshAffiliationsBox} onClick={onRefreshButtonClicked}>
        <Typography className={classes.refreshAffiliationsText}>{text}</Typography>
        {displayRefreshAffiliationsButton()}
      </Box>
    )
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Box className={classes.root} style={{ backgroundColor }}>
        <Box pt={3} pr={3} pl={3} className={classes.top}>
          <Box pt={1.5}>
            <Typography variant='h5'>{t('MY_BADGE')}</Typography>
          </Box>
          <VNFullScreenCloseButton onClose={onClose} className={classes.cancelIcon}/>
        </Box>
        <Box pl={3}>
          {displayAffiliationName()}
          <Box pt={3}>
            <Typography variant='h5'>{user.get('name')}</Typography>
          </Box>
          <Box pt={2}>
            <Typography className={classes.userID} variant='h5'>{user.get('axsAccountNumber') ? user.get('axsAccountNumber') : user.get('userID')}</Typography>
          </Box>
          {displayVirtualCurrency()}
        </Box>
        <Box pl={3} pb={5}>
          <Typography variant='h2' className={classes.bottomText}>{t('PRESENT_THIS_BADGE')}</Typography>
          {displayRefreshAffiliations()}
        </Box>
      </Box>
      <div className={classes.touch} onClick={onSwitchBackgroundColor}></div>
    </Dialog>
  )
}
