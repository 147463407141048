import { createTheme } from '@material-ui/core/styles'

var rootTheme = {
  fontFamily: [
    "OpenSans",
    "Open Sans"
  ],
  colors: {
    black: '#333333',
    borderGrey: '#e8e8e8', // used as borders
    grey: '#666666',
    accent: '#794bc4', // VN Default - Also set as the default in the VNConfiguration system in the Reducer
    red: '#e21e5c',
    white: '#ffffff',
    orange: '#FD853A',
    backgroundGrey: '#F2F2F2', // used as background grey
    lightGrey: '#dddddd',
    disabledGrey: '#999999',
    filterBackgroundGrey: '#FCFCFA',
    filterActiveBlue: '#136EF6',
    filterActiveBackgroundBlue: 'rgba(34, 83, 255, 0.1)',
    filterChipBackgroundGrey: '#EBEDEF'
  },
  zIndex: {
    VNBackdropSystem: 2000
  }
}

/**
 * Grab the default theme used for all styling of the app
 * @param {String} accentColor - The accent color you want to use - this is usually set via the VNAPI Configurations System (accent_color)
 */
export const defaultTheme = (accentColor) => {
  rootTheme.colors.accent = accentColor
  return getTheme()
}

const getTheme = () => createTheme({
  palette: {
    primary: {
      main: rootTheme.colors.black
    },
    secondary: {
      main: rootTheme.colors.accent
    },
    error: {
      main: rootTheme.colors.red
    },
    disabled: {
      main: rootTheme.colors.disabledGrey
    }
  },
  custom: {
    colors: rootTheme.colors,
    zIndex: rootTheme.zIndex,
    h7: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 20,
      color: rootTheme.colors.black
    },
    h8: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 35,
      fontWeight: 700,
      lineHeight: '47.5px',
      color: rootTheme.colors.black
    },
    masterButton: {
      height: 37,
      borderRadius: 6
    },
    button2: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '13.5px',
      letterSpacing: '-0.09px',
      color: rootTheme.colors.white
    },
    button3: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '-0.09px',
      color: rootTheme.colors.black
    },
    button4: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      color: rootTheme.colors.accent
    },
    subtitle3: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 12,
      color: rootTheme.colors.black
    },
    subtitle3AccentColor: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 12,
      color: rootTheme.colors.accent
    },
    subtitle4: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 14,
      color: rootTheme.colors.grey
    },
    caption2: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 10,
      lineHeight: '24px',
      letterSpacing: '-0.06px',
      fontWeight: 600,
      color: rootTheme.colors.white
    },
    overline2: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 10,
      fontWeight: 600,
      letterSpacing: '0.99px',
      textTransform: 'uppercase',
      color: rootTheme.colors.grey,
    }
  },
  typography: {
    fontFamily: rootTheme.fontFamily,
    button: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 16,
      lineHeight: '18px',
      fontWeight: 600,
      textTransform: 'normal',
      color: rootTheme.colors.accent
    },
    h1: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px',
      color: rootTheme.colors.black
    },
    h2: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'normal',
      color: rootTheme.colors.black
    },
    h3: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      color: rootTheme.colors.black
    },
    h4: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 18,
      lineHeight: '19.5px',
      color: rootTheme.colors.black
    },
    h5: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 0.68,
      color: rootTheme.colors.white
    },
    h6: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '34px',
      color: rootTheme.colors.black
    },
    subtitle1: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 12,
      lineHeight: '17.5px',
      color: rootTheme.colors.grey
    },
    subtitle2: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 14,
      lineHeight: '13.5px',
      color: rootTheme.colors.black
    },
    body1: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 12,
      lineHeight: '18.5px',
      color: rootTheme.colors.grey
    },
    caption: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '-0.09px',
      color: rootTheme.colors.red,
    },
    overline: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 10,
      fontWeight: 600,
      letterSpacing: '0.99px',
      textTransform: 'uppercase',
      color: rootTheme.colors.accent,
    },
    body2: {
      fontFamily: rootTheme.fontFamily,
      fontSize: 10,
      lineHeight: '14px'
    }
  }
})
