const ajax = require('../utils/ajax')
const pidgeon = require('../pidgeon/index')
const PIDGEON_KEYS = require('../pidgeon/keys')

const getVaultedCardsByUser = (token, vaultServer) => {
  ajax({
    type: 'GET',
    url: `${vaultServer}/vault/cards`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then((responseObj) => {
    pidgeon.send(PIDGEON_KEYS.VAULT_RETRIEVED_ALL_CARDS, {'cards': JSON.parse(responseObj.response) })
  }).catch((e) => {
    console.log(e)
  })
}

const getConvertedShift4CardType = (cardType) => {
  switch (cardType) {
    case 'VS':
      return 'visa'
    case 'AX':
      return 'amex'
    case 'NS':
      return 'discover'
    case 'MC':
      return 'mastercard'
    default:
  }
}

const vaultCard = (shift4Card, token, vaultServer) => {

  return new Promise((resolve, reject) => {

    // convert shift4 card obj into what we need for VenueNext
    const splitExp = shift4Card.expiration.split('/')

    const cardObj = {
      cardType: getConvertedShift4CardType(shift4Card.cardType),
      uToken: shift4Card.token,
      uniqueId: shift4Card.uniqueId,
      expMonth: splitExp[0],
      expYear: splitExp[1],
      lastFour: shift4Card.lastFour,
      default: true,
      reference: shift4Card.reference,
      bin: shift4Card.bin
    }

    ajax({
      type: 'POST',
      url: `${vaultServer}/vault/card`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      },
      data: cardObj
    }).then((responseObj) => {
      pidgeon.send(PIDGEON_KEYS.VAULT_CARD_VAULTED, {'card': JSON.parse(responseObj.response) })
      resolve(JSON.parse(responseObj.response))
    }).catch((e) => {
      console.log(e)
      reject(e)
    })
  })
}

const updateCard = (data, token, vaultServer) => {

  ajax({
    type: 'PUT',
    url: `${vaultServer}/vault/card`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data: data
  }).then((responseObj) => {
    pidgeon.send(PIDGEON_KEYS.VAULT_CARD_UPDATED, {'card': JSON.parse(responseObj.response) })
  }).catch((e) => {
    console.log(e)
  })
}

const deleteCard = (cardId, token, vaultServer) => {
  ajax({
    type: 'DELETE',
    url: `${vaultServer}/vault/card?uniqueId=${cardId}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  }).then((responseObj) => {
    pidgeon.send(PIDGEON_KEYS.VAULT_CARD_DELETED, {'card': JSON.parse(responseObj.response) })
  }).catch((e) => {
    console.log(e)
  })
}

module.exports = {
  getVaultedCardsByUser: getVaultedCardsByUser,
  vaultCard: vaultCard,
  deleteCard: deleteCard,
  updateCard: updateCard
};
