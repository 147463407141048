import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getConfiguration } from '../ActionCreators'
import { ORG_ENV, ORG_NAME } from '../../constants'

export const VNConfigurations = () => {

  const dispatch = useDispatch()

  const [configurationFetched, setConfigurationFetched] = useState(false)

  useEffect(() => {
    if (!configurationFetched) {
      setConfigurationFetched(true)
      dispatch(getConfiguration(ORG_NAME, ORG_ENV, 'public', 'runtime'))
    }
  }, [configurationFetched, dispatch])

  return (
    <span></span>
  )
}