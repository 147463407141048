import * as ActionTypes from './ActionTypes'
import { getLastOrderTotalUuid, getLastOrderTotaMenulUuid } from './Selectors'
import { isEmpty } from 'lodash'
import humps from 'humps'
import { validate as uuidValidate } from 'uuid'
import normalize from 'json-api-normalizer'

import { enableBackdropSystem, disableBackdropSystem } from '../VNBackdropSystem/ActionCreators'
import { setApiLoadingStatus } from '../VNApiLoadingSystem/ActionCreators'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'
import { getCartMenuItems } from '../selectors/cart'
import { getLatestOrderTotal } from '../selectors/orderTotal'
import { getUser } from '../VNUser/Selectors'
import { getPromoCodes } from '../VNOrders/Selectors'
import { getPaymentsProvider } from '../VNConfigurations/Selectors'
import { orderTotalMapper } from '../mappers/orderTotal'
import { getVirtualCurrencyPromotions } from '../VNWallet/Selectors'
import { getUserCardAffiliations } from '../VNUser/Selectors'
import { apiFetchOrderTotal } from '../VNOrderTotal/Api'
import { orderTotalSucceeded, orderTotalFailed } from '../actions/orderTotal'

/**
 * Fetch the order total, typically utilized in conjunction with QR Code scan
 * @param {string} qrCode - (optional) - The QR Code that contains the order data
 * @param {string} orderNumber - (optional) - The order number
 * @param {boolean} isRichCheckoutQR - (optional) - Indicates if it is a rich checkout
 * @param {boolean} isRefresh - (optional) - Set to true to get a new order uuid
 */
export const fetchOrderTotal = (qrCode = '', orderNumber = '', isRichCheckoutQR = false, isRefresh = false) => {
  return async (dispatch, getState) => {
    dispatch(enableBackdropSystem())

    dispatch(setApiLoadingStatus(
      fetchOrderTotal.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    const state = getState()
    const cartItems = getCartMenuItems(state)
    const menuId = state.cart.menuId

    // If isRefresh, existing attrs should be null so that we request a new order uuid
    const existingOrderTotal = isRefresh ? null : getLatestOrderTotal(state)

    const user = getUser(state)
    const token = user.get('jwt')
    const paymentsProvider = getPaymentsProvider(state)

    const lastOrderTotalUuid = getLastOrderTotalUuid(state)
    const lastOrderTotalMenuUuid = getLastOrderTotaMenulUuid(state)

    // Map items. If Rich checkout order total exists, use the params needed
    const orderTotalParams = orderTotalMapper(cartItems, menuId, existingOrderTotal, null, lastOrderTotalUuid, lastOrderTotalMenuUuid)

    // These are passed in individually primarily from the scanner, only set these
    // if there is no rich checkout because we don't want to override them.
    if (isEmpty(existingOrderTotal)) {
      if (qrCode) {
        orderTotalParams.qr_code = qrCode
      }
      if (orderNumber) {
        orderTotalParams.order_number = orderNumber
      }
    } else if (isRichCheckoutQR) {
      const existingOrderNumber = existingOrderTotal?.attributes?.orderNumber
      const promotions = humps.decamelizeKeys(existingOrderTotal?.attributes?.promotions)

      if (Array.isArray(promotions) && promotions.length) {
        orderTotalParams.promotions = promotions.map(promo => ({ uuid: promo.uuid }))
      }
      if (existingOrderNumber) {
        orderTotalParams.order_number = existingOrderNumber
      }
    }

    // add promo codes to the order total call
    const promoCodes = getPromoCodes(state)

    if (!promoCodes.isEmpty()) {
      if (!orderTotalParams.promotions) {
        orderTotalParams.promotions = []
      }
      for (let code of promoCodes.values()) {
        orderTotalParams.promotions.push({ promo_code: code })
      }
    }

    // add virtual currency promotions
    const vcPromotions = getVirtualCurrencyPromotions(state)

    if (!vcPromotions.isEmpty()) {
      if (!orderTotalParams.promotions) {
        orderTotalParams.promotions = []
      }
      for (const promotion of vcPromotions.values()) {
        if (promotion.id && uuidValidate(promotion.id)) {
          orderTotalParams.promotions.push({ uuid: promotion.id })
        }
      }
    }

    // we only want to use affiliations on the order total, in the query params if its from braintree
    // all the new affiliations, including PCH, is on the JWT only.
    if (paymentsProvider === 'braintree') {
      const cardAffiliations = getUserCardAffiliations(state)
  
      if (!isEmpty(cardAffiliations)) {
        orderTotalParams.affiliations = cardAffiliations
      }
    }

    try {
      const response = await apiFetchOrderTotal(orderTotalParams, token)

      const parsedData = normalize(response.data)

      dispatch(orderTotalSucceeded(parsedData))

      dispatch(setApiLoadingStatus(
        fetchOrderTotal.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))

    } catch (err) {
      dispatch(orderTotalFailed(err))

      dispatch(setApiLoadingStatus(
        fetchOrderTotal.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    }

    dispatch(disableBackdropSystem())
  }
}

export const setLastOrderTotalUuidAndMenuUuid = (lastUuid, lastMenuUuid) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNORDERTOTAL_SET_LAST_UUID_AND_MENU_ID,
      lastUuid,
      lastMenuUuid,
    })
  }
}

export const resetLastOrderTotalUuidAndMenuUuid = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNORDERTOTAL_RESET_LAST_UUID_AND_MENU_ID
    })
  }
}
