import * as ActionTypes from './ActionTypes'
import { Map, List } from 'immutable'
import moment from 'moment-timezone'

const getInitialState = () => {
  return Map({
    'loyalty_user': Map({}),
    'virtual_currency_activity': List([]),
    'awards_rules': List([]),
    'nonces': List([]),
    'order': Map({}),
    'transfer_email': '',
    'virtual_currency_promotions': List([]),
    'lastTimeQRCodeGenerated': null,
    'exchangeServiceJwt': ''
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNWALLET_LOYALTY_USER:
      return state.set('loyalty_user', Map(action.loyalty_user))
    case ActionTypes.VNWALLET_VIRTUAL_CURRENCY_ACTIVITY:
      return state.set('virtual_currency_activity', List(action.virtual_currency_activity))
    case ActionTypes.VNWALLET_AWARDS_RULES:
      return state.set('awards_rules', List(action.awards_rules))
    case ActionTypes.VNWALLET_GET_QR_NONCES:
      return state.set('nonces', List(action.nonces))
    case ActionTypes.VNWALLET_GET_ORDER_TOTAL:
      return state.set('order', Map(action.order))
    case ActionTypes.EXCHANGE_SERVICE_SET_JWT:
      return state.set('exchangeServiceJwt', Map(action.exchangeServiceJwt))
    case ActionTypes.VNWALLET_SET_VIRTUAL_CURRENCY_TRANSFER_EMAIL_ACCEPT:
      return state.set('transfer_email', action.email)
    case ActionTypes.VNWALLET_UPDATE_VIRTUAL_CURRENCY_PROMOTIONS:
      return state.set('virtual_currency_promotions', List(action.vcPromotions))
    case ActionTypes.VNWALLET_SET_LAST_TIME_QR_CODE_GENERATED:
      return state.set('lastTimeQRCodeGenerated', moment())
    default:
      return state
  }
}

export default reducer
