import { call, put, takeLatest, all, select } from 'redux-saga/effects'

import Remote from '../remote'
import { setLoading, setFailed, setSucceeded } from '../actions/remote'
import { loadMenuSucceeded, loadMenuFailed, loadStandFromMenuSucceeded, MENU_REQUESTED, STAND_FROM_MENU_REQUESTED } from '../actions/menu'

import { VNSTAND_TRUNCATED_STAND_FROM_MENU } from '../VNStand/ActionTypes'

import { getUser } from '../VNUser/Selectors'

import humps from 'humps'

export function* fetchMenu(params) {
  const endpoint = Remote.endpoints.getMenu
  yield put(setLoading(endpoint))
  const user = yield select(getUser)
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.getMenu, params, token)

    yield all([
      put(loadMenuSucceeded(result)),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put(loadMenuFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* fetchStandFromMenu(params) {
  const endpoint = Remote.endpoints.getMenu
  yield put(setLoading(endpoint))

  const user = yield select(getUser)
  const token = user.get('jwt')

  try {
    const result = yield call(Remote.getMenu, params, token)

    const standFromMenu = {
      uuid: result.menu.stand_uuid,
      name: result.menu.stand_name,
      section: null,
      serviceTypes: result.menu.service_types,
      images: result.menu.images,
      productType: result.menu.product_type
    }

    yield all([
      put(loadMenuSucceeded(result)),
      put(loadStandFromMenuSucceeded(result)),
      put({ type: VNSTAND_TRUNCATED_STAND_FROM_MENU, stand: [humps.camelizeKeys(standFromMenu)] }),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put(loadMenuFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchFetchMenu() {
  yield takeLatest(MENU_REQUESTED, fetchMenu)
}

export function* watchFetchStandFromMenu() {
  yield takeLatest(STAND_FROM_MENU_REQUESTED, fetchStandFromMenu)
}


