import { INSTANCE_NAME, ORG_ENV } from '../constants'

/**
 * retrieve the configuration from the global VNAPI
 * @param {*} state
 */
export const getConfiguration = (state) => {
  return state.VNConfigurations.get('configuration')
}

/**
 * Get the configuration key for the keys in the VNConfiguration response
 */
export const getConfigKey = () => {
  return `${ORG_ENV}_public_runtime_${INSTANCE_NAME}`
}

/**
 * Checks to see if a key exists in an instance level VNConfiguration object
 * If it doesn't, it'll return null, otherwise it'll return the value
 * @param {Obj} config - The full configuration in redux, this includes ALL VNConfigurations
 * @param {String} key - What key you are looking for
 */
export const checkForInstanceLevelConfigurationKey = (config, key) => {
  const configKey = getConfigKey()
  if (config.has(configKey) && config.get(configKey)[key]) {
    return config.get(configKey)[key]
  }

  return null
}

/**
 * Get value by the given key. This will look for the instance level first, and if one is not found, it'll default to the org level value.
 * @param {*} state - Global State
 * @param {String} key - What key you are looking for
 */
export const getByKey = (state, key) => {
  const config = getConfiguration(state)

  const instanceValue = checkForInstanceLevelConfigurationKey(config, key)

  if (instanceValue) {
    return instanceValue
  }

  return config.get(`${ORG_ENV}_public_runtime`)[key]
}

/**
 * This function gets a boolean by the key. This will look for the instance level first, and if one is not found, it'll default to the org level value.
 * @param {*} state - Global State
 * @param {String} key - What key you are looking for
 * @returns {boolean} true if the string is true or yes
 */
export const getByKey_Boolean = (state, key) => {
  const val = getByKey(state, key)
  if(val && (val === 'true' || val === 'yes') ){
    return true
  }
  return false
}

/**
 * Get an array by the given key. This will look for the instance level first, and if one is not found, it'll default to the org level value.
 * @param {*} state - Global State
 * @param {String} key - What key you are looking for
 */
export const getByKey_Array = (state, key) => {
  const config = getConfiguration(state)

  const instanceValue = checkForInstanceLevelConfigurationKey(config, key)

  if (instanceValue) {
    return instanceValue.split('|')
  }

  const array = config.get(`${ORG_ENV}_public_runtime`)[key]

  if (array) {
    return array.split('|')
  }

  return []
}

/**
 * Get a boolean if the given key exists or not. This will look for the instance level first, and if one is not found, it'll default to the org level value.
 * @param {*} state - Global State
 * @param {String} key - What key you are looking for
 */
export const getByKey_Exists = (state, key) => {
  const config = getConfiguration(state)

  const instanceValue = checkForInstanceLevelConfigurationKey(config, key)

  if (instanceValue) {
    return true
  }

  if (config.get(`${ORG_ENV}_public_runtime`)[key]) {
    return true
  }

  return false
}
