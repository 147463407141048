import { v4 as uuidv4 } from 'uuid'

export default class DataMapper {

  static objectsById = (objects = []) => {
    return objects.reduce((result, object) => {

      // its possible that the category is null, which are objects
      // we want to remove from our data results.
      if (object.category && object.category === null) {
        return {
          ...result
        }
      }

      if (object.uuid || object.id) {

        const tempObj = this.objectWithGroupId(object)

        if (typeof tempObj === 'object' && tempObj !== null && tempObj !== undefined) {
          return {
            ...result,
            [object.uuid || object.id]: tempObj
          }
        }

        return {
          ...result
        }

      }

      return {
        ...result
      }

    }, {})
  }

  /**
   * For menu item objects, need to add a group id so that modifier
   * items can map back to their modifierGroup so that we can
   * count how many items of a particular group have been added to the cart.
   * Primarily used to calculate the correct prices once modifierGroup threshold
   * counts have been surpassed.
   * @param {object} object - The object
   */
  static objectWithGroupId = (object) => {
    const modifierGroups = object.modifierGroups
    if (modifierGroups) {
      modifierGroups.forEach(group => {
        const groupId = uuidv4()
        const props = group.modifierPropertiesContainer
        props.groupId = groupId

        props.items.forEach(item => {
          item.parentGroupId = groupId
        })
      })
    }

    return object
  }

}
