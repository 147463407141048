import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    paddingTop: 8,
},
}));

/**
 *
 * A switch or toggle input field that also contains a title and sub text to go along with it
 * @param {String} title - The main text that goes to the left of the switch
 * @param {String} subText - The text that goes below on a second line
 * @param {Boolean} checked - If the switch should be default set to on or off (default is false)
 * @param {Function} action - A function that is called when the
 *
 * @export {Function} getSwitchState - retrieve the switch state
 */
export const VNSwitch = forwardRef((props, ref) => {

  const classes = useStyles()

  const theme = useTheme()

  const [switchState, setSwitchState] = useState(props.checked ? props.checked : false)

  const handleChange = (event) => {
    setSwitchState(event.target.checked)

    if (props.action) {
      props.action(event.target.checked)
    }
  };

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    // retrieve the switch state
    getSwitchState() {
      return switchState
    },

    reset() {
      setSwitchState(false)
    }

  }))

  const displaySubText = () => {
    if (props.subText) {
      return (
        <Box>
          <Typography variant="subtitle1">{props.subText}</Typography>
        </Box>
      )
    }
  }

  return (
    <Box>
      <Box component="span" className={props?.classes?.root ? props.classes.root : classes.root}>
        <Typography
          style={props?.classes?.title ? null : theme.custom.h7}
          className={props?.classes?.title ? props.classes.title : classes.title}>
            {props.title}
        </Typography>
        <Switch
          checked={switchState}
          onChange={handleChange}
          color="secondary"
        />
      </Box>
      {displaySubText()}
    </Box>
  )
})
