import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'enabled': false,
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNBACKDROPSYSTEM_SET:
      return state.set('enabled', action.enabled)
    default:
      return state
  }
}

export default reducer