export const VNUSER_SET_USER = 'VNUSER_SET_USER'
export const VNUSER_SET_USER_LOADED = 'VNUSER_SET_USER_LOADED'
export const VNUSER_SET_USER_DETAILED_AFFILIATIONS = 'VNUSER_SET_USER_DETAILED_AFFILIATIONS'
export const VNUSER_SET_STADIUM_USER = 'VNUSER_SET_STADIUM_USER'
export const VNUSER_SET_USER_PAYMENT_METHODS = 'VNUSER_SET_USER_PAYMENT_METHODS'
export const VNUSER_SET_ANON_EXPERIENCE_ORDER_HISTORY = 'VNUSER_SET_ANON_EXPERIENCE_ORDER_HISTORY'
export const VNUSER_SET_VIEWED_PAYMENT_REMINDER = 'VNUSER_SET_VIEWED_PAYMENT_REMINDER'
export const VNUSER_SET_ANONYMOUS_USER_DATA = 'VNUSER_SET_ANONYMOUS_USER_DATA'
export const VNUSER_SET_CACHED_ROUTE = 'VNUSER_SET_CACHED_ROUTE'
export const VNUSER_SET_AFFILIATIONS_REFRESHED = 'VNUSER_SET_AFFILIATIONS_REFRESHED'
export const VNUSER_RESET_AFFILIATIONS_REFRESHED = 'VNUSER_RESET_AFFILIATIONS_REFRESHED'
export const VNUSER_SET_CREDIT_CARD_AFFILIATIONS = 'VNUSER_SET_CREDIT_CARD_AFFILIATIONS'
export const VNUSER_SET_IS_NEW_LOGIN = 'VNUSER_SET_IS_NEW_LOGIN'
