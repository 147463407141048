import axios from 'axios'
import * as CONFIG from '../App/configurations/Configs'
import {
  mockGetApiOrderHistory,
  mockGetApiBraintreeClientToken,
  mockGetApiVerificationCode,
  mockRequestSMSOrderUpdates,
  mockGetApiExperienceApiOrderHistory,
  mockGetApiAwardedTransferredExperiences,
  mockGetApiShift4ServerAndAccessBlock,
  mockPostApiRequesti4GoApplePayLink
} from './Mock'
import { API_ENDPOINT, BASE_URL, ORG_NAME, INSTANCE_NAME } from '../constants'

/**
 * fetch the verification code for a sms user signup or login
 * @param {string} token - The JWT token
 * @param {*} type
 * @param {*} phone_number
 */
export const apiGetVerificationCode = (token, type, phone_number) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiGetVerificationCode) {
    return mockGetApiVerificationCode()
  } else {
    return axios.get(`${API_ENDPOINT}/users/validate?type=${type}&phone_number=${phone_number}&instance=${INSTANCE_NAME}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * fetch seats for a particular user
 * @param {string} token - The JWT token
 */
export const apiGetSeats = (token) => {
  return axios.get(`${API_ENDPOINT}/users/seats?instance=${INSTANCE_NAME}`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * fetch a pass for a particular user
 * @param {string} token - The JWT token
 */
export const apiGetSeatPass = (token) => {
  return axios.get(`${API_ENDPOINT}/users/pass?instance=${INSTANCE_NAME}`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * fetch a users order history given their token
 * @param {string} token - The JWT token
 */
export const apiGetOrderHistory = (token) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiGetOrderHistory) {
    return mockGetApiOrderHistory()
  } else {
    return axios.get(`${BASE_URL}/orders?organization_name=${ORG_NAME}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * Fetch a user's experience order history given their token
 * @param {string} token - The JWT token
 */
export const apiGetExperienceOrderHistory = (token) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiGetExperienceOrderHistory) {
    return mockGetApiExperienceApiOrderHistory()
  } else {
    return axios.get(`${BASE_URL}/orders?product_type=Experience&organization_name=${ORG_NAME}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * Fetch a user's awarded and transferred experiences given their token
 * @param {string} token - The JWT token
 */
export const apiGetAwardedTransferredExperiences = (token) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiGetAwardedTransferredExperiencesHistory) {
    return mockGetApiAwardedTransferredExperiences()
  } else {
    return axios.get(`${BASE_URL}/firesale/user_items?organization_name=${ORG_NAME}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * fetch a users braintree token
 * @param {string} token - The JWT token
 */
export const apiGetBraintreeClientToken = (token) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiGetBraintreeClientToken) {
    return mockGetApiBraintreeClientToken()
  } else {
    return axios.get(`${BASE_URL}/payment_methods/payment_token/${ORG_NAME}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

export const apiGetPaymentToken = (stand, orderNumber, amount, currencyCode, token, region) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiGetShift4ServerAndAccessBlock) {
    return mockGetApiShift4ServerAndAccessBlock()
  } else {

    let queryString = `stand=${stand}`

    if (orderNumber) {
      queryString += `&orderNumber=${orderNumber}`
    }

    if (amount) {
      queryString += `&amount=${amount}`
    }

    if (currencyCode) {
      queryString += `&currencyCode=${currencyCode}`
    }

    if (region && region === 'EU') {
      queryString += `&region=${region}`
    }

    return axios.get(`${API_ENDPOINT}/shift4/preauthorize?${queryString}`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  }
}

/**
 * Retrieves a Shift4 Payment Token without the preuathorization step. You CANNOT use this token to make a payment,
 * only to retrieve data from Shift4 VNAPI securely.
 * @param {String} token - A VNAPI token
 * @returns A Shift4 Payments JWT
 */
export const apiGetShift4PaymentToken = (token) => {
  return axios.get(`${API_ENDPOINT}/shift4/user/token`,
  {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
}

/**
 *
 * @param {string} orderId -
 * @param {string} phone -
 * @param {string} token - The JWT token
 */
export const apiRequestSMSOrderUpdates = (orderId, phone, token) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiRequestSMSOrderUpdates) {
    return mockRequestSMSOrderUpdates()
  } else {
    return axios.patch(`${BASE_URL}/orders/${orderId}?organization_name=${ORG_NAME}`,
      {
        user_phone: phone
      },
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * Request a payment URL that expires for completing i4Go Apple Pay request.
 * @param {object} orderTotal - Latest order total to store
 * @param {object} orderData - Place order data generated via cart
 * @param {string} orderId - UUID of order
 * @param {float} total - Total to store
 * @param {string} currencyCode - Currency code to use for preauthorization
 * @param {string} standId - UUID of stand
 * @param {string} standName - Name of Stand
 * @param {string} token - User JWT
 * @returns
 */
export const apiPosti4GoApplePayLink = (orderTotal, orderData, orderId, total, currencyCode, standId, standName, token, org) => {
  if (CONFIG.DATA.MOCK.VN_WEBACCOUNT_apiPosti4GoApplePayLink) {
    return mockPostApiRequesti4GoApplePayLink()
  } else {
    return axios.post(`${API_ENDPOINT}/otp/set`, {
      blob: {
        orderTotal: orderTotal,
        orderData: orderData,
        orderId: orderId,
        total: total,
        currencyCode: currencyCode,
        standName: standName,
        standId: standId,
        usertoken: token,
        org: org
      }
    }, {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  }
}

/**
 * Get all the vaulted cards for a specific user. The token is what identifies the user.
 * @param {string} token - The Shift4 Token from preauthorize
 * @param {string} vaultServer - The VNAPI Vault Server
 * @returns 
 */
export const apiGetVaultedCardsByUser = (token, vaultServer) => {
  return axios.get(`${vaultServer}/vault/cards`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
