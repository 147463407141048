import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTranslation } from 'react-i18next'
import VNDivider from '../../VNComponents/VNDivider'

const useStyles = makeStyles(theme => ({
  chip: {
    height: 20,
    marginLeft: 10,
    marginTop: 3,
  },
  formControl: {
    width: '100%',
  },
  titleBox: {
    display: 'flex'
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  headerRowBox: {
    display: 'flex',
  },
  headerTypography: {
    marginTop: 8
  },
  headerSubtitle: {
    marginLeft: 32
  },
  divider: {
    width: 50,
    marginTop: 17,
  },
  modifierText: {
    paddingTop: 9
  },
  radioGroup: {
    marginTop: 17,
  },
  formControlLabel: theme.custom.button3,
  disabledColor: theme.palette.text.disabled
}));

// the enum for required. Displays required or opional bubble
export const VNMultiChoiceRequiredEnum = {
  REQUIRED: "REQUIRED",
  OPTIONAL: "OPTIONAL",
  NONE: "NONE"
}

 /**
 * Multi choice component for modifiers in VNMenuItemDetails
 *
 * Exposed Functoins
 * Accessible through ref option. Example: <REF>.current.getChoice()
 * getChoice - Accessible through the ref option. See function notes below on details
 * @param {object} ref - a reference to this component, used to call specific functions on this component from the parent
 * @param {enum} required - an enum: REQUIRED, OPTIONAL, or NONE | displays required or opional bubble
 * @param {string} title - The title of the section
 * @param {string} subTitle - The subtitle of the section
 * @param {function} onChange - A function that is called on each selection
 * @param {Int} min - he minimum selections, need to use this with required
 * @param {Int} max - The max selections possible to be selected
 * @param {Array} options - The choices
 * @param {boolean} selectAll - If the select all button shows up with the title | title must be used
 * @param {boolean} checkDisabled add a checkmark for disabled options.
 * @param {boolean} disableAll disables all of the choices
 */
export const VNMultiChoice = forwardRef((props, ref) => {

  const classes = useStyles()

  const theme = useTheme()

  const { t } = useTranslation()

  // This is the chip color for it being required or optional
  const [chipColor, setChipColor] = useState(props.required ? 'primary' : 'default')

  // the checkbox state if its checked or not
  const [choices, setChoices] = useState([])
  const [isAllDisabled, setIsAllDisabled] = useState(true)
  const choicesRef = useRef(choices);
  // contains all the active options. If an option is disabled from the prop.options it will not be added here.
  const [activeOptions, setActiveOptions] = useState([])

  // the max choices you can check
  // if max is 0 or null, that means there is no max limit on group level (as of 3/7/2022)
  const maxChoices = props.max

  // the minimum choices you can check (used for required status)
  const minChoices = props.min ? props.min : 0

  useEffect(() => {
    if (props.selectedItems) {
      setSelectedItems()
    }
  }, [props.selectedItems])

  // handle default modifiers
  useEffect(() => {
    if (props.options && props.defaults?.length){
      let defaultOptions = props.options.filter(c => props.defaults.includes(c.uuid))
      let newChoices = [...choices]
      if ( defaultOptions && defaultOptions.length > 0 ){
        for (let i=0; i<defaultOptions.length; i++){
          newChoices = [...newChoices, defaultOptions[i].uuid]  
        }  
      }
      if (newChoices.length > 0){
        setChoices(newChoices)
        if (props.onChange) {
          for(let i=0;i<newChoices.length;i++){
            props.onChange({
              uuid: props.modifierGroupUuid,
              minIsMet: newChoices.length >= minChoices,
              index: choices.indexOf(newChoices[i].uuid),
              selectedChoices: newChoices
            })
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    let activeOptionsArray = []
    props.options.forEach(element => {
      if (!element?.disabled) {
        activeOptionsArray = [...activeOptionsArray, element]
      }
    });
    setActiveOptions(activeOptionsArray)
  }, [props.options])

  useEffect(() => {
    choicesRef.current = choices;
  }, [choices]);

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({
    // this returns all choices that are set to true, selected choices
    // @return { choices : [], status: bool }
    getChoices() {
      const currentChoices = choicesRef.current;
      if (props.required) {
        if (currentChoices.length < minChoices) {
          setChipColor('secondary')
          return {
            'choices': undefined,
            'status': false
          }
        } else {
          setChipColor('primary')
        }
      }

      return {
        'choices': [...currentChoices],
        'status': true
      }
    }
  }));

  const checkAllDisabled = () => {
    props.options.forEach((option) => {
      if (!option?.disabled) {
        return false
      }
    })
    return true
  }

  const setSelectedItems = () => {
    const { options, selectedItems } = props
    const selectedChoices = []

    options.forEach(option => {
      if (selectedItems.find(item => item.uuid === option.uuid && item.modifierGroupUuid === props.modifierGroupUuid)) {
        selectedChoices.push(option.uuid)
      }
    })

    setChoices(selectedChoices)
  }

  // called on every checkbox attempt
  const handleChange = (event) => {
    const name = event.target.name
    const index = choices.indexOf(name)

    let newChoices = [...choices]

    if (newChoices.includes(name)) {
      newChoices = newChoices.filter(uuid => uuid !== name)
    } else {
      // only be able to add if there is no max limit or its less than the max allowed
      if (!maxChoices || newChoices.length < maxChoices) {
        newChoices.push(name)
      }
    }

    setChoices(newChoices)

    // used to inform parent component the current status of this choice
    if (props.onChange) {
      props.onChange({
        uuid: props.modifierGroupUuid,
        minIsMet: newChoices.length >= minChoices,
        index: index,
        selectedChoices: newChoices
      })
    }
  }

  // retrieve the required or optional text
  const isRequired = () => {
    if (props.displayRequired === VNMultiChoiceRequiredEnum.REQUIRED) {
      return t('REQUIRED')
    }

    return t('OPTIONAL')
  }

  // the modifier is the text on the far right for each checkbox
  const displayModifier = (option) => {
    const { secondaryPriceThresholdCount } = props

    const shouldShowSecondaryPrice = secondaryPriceThresholdCount && choices.length >= secondaryPriceThresholdCount
    let modifierText = option.change + option.modifier

    if (shouldShowSecondaryPrice) {
      let index = choices.indexOf(option.uuid)
      if (index === -1 || index >= secondaryPriceThresholdCount) {
        modifierText = option.thresholdChange + option.thresholdModifier
      }
    }

    if (option.modifier || option.thresholdModifier) {
      return (
        <Typography variant='h2' style={{height: '100%'}} className={classes.modifierText}>
          {modifierText}
        </Typography>
      )
    }
  }

  // display optional, required, or none
  const displayRequired = () => {
    if (props?.displayRequired === VNMultiChoiceRequiredEnum.REQUIRED || props?.displayRequired === VNMultiChoiceRequiredEnum.OPTIONAL) {
      return <Box className={classes.titleBox}>
          {displayTitle()}
          <Chip label={isRequired()} color={chipColor} className={classes.chip}/>
        </Box>
    }
    return <Box className={classes.titleBox}>
      {displayTitle()}
    </Box>
  }

  // sets choices to none if data-indeterminate and if everything is selected
  // set choices to all choices if nothing is selected 
  const handleSelectAllChange = (event) => {
    let newChoices = []

    if (event.target.getAttribute('data-indeterminate') === 'true') {
      setChoices(newChoices)
    } else {
      if (activeOptions.length === choices.length) {
        setChoices(newChoices)
      } else {
        activeOptions.forEach((option) => {
          const name = option.uuid.toString()
          newChoices.push(name)
        })
  
        setChoices(newChoices)
      }
    }

    // used to inform parent component the current status of this choice
    if (props.onSelectAllChange) {
      props.onSelectAllChange({
        selectedChoices: newChoices
      })
    }
  }

  // make sure display for select all checkmark is blue and not greyed out
  const selectAllChecked = () => {
    if(props?.disableAll) {
      return false
    }
    if (choices.length > 0 && choices.length !== activeOptions.length) {
      return true
    }
    if (choices.length === activeOptions.length) {
      return true
    }
    return false
  }

  const checkboxCheck = (option) => {
    if (props.checkDisabled && option.disabled) {
      return true
    }

    if(props?.disableAll) {
      return false
    }

    return choices.indexOf(option.uuid.toString()) !== -1
  }

  const displayTitleText = () => {
    return (
      <Box style={{display: 'block'}}>
        <Typography className={classes.modifierText} variant="h3" color='primary'>{props.title}</Typography>
        {props.subTitle && 
          <Typography style={theme.custom.overline2}>
            {props.subTitle}
          </Typography>
        }
      </Box>
    )
  }

  // display title differently if selectAll is active
  const displayTitle = () => {
    if (props.selectAll) {
      return (
        <Box className={classes.headerRowBox} style={{marginBottom: 0}}>
          <FormControlLabel style={{marginRight: 0, marginBottom: 0}} control={
            <Checkbox checked={selectAllChecked()} onChange={handleSelectAllChange} disabled={props?.disableAll}
            indeterminate={choices.length > 0 && choices.length !== activeOptions.length}
            />
          } />
          {displayTitleText()}
        </Box>
      )
    }

    return displayTitleText()
  }

  return (
    <Box>
      {displayRequired()}
      <VNDivider className={classes.divider} />
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup className={classes.radioGroup}>
          {props.options.map((option, index) => (
            <Box pb={3} key={index}>
              <Box className={classes.rowBox}>
                <FormControlLabel classes={{label: classes.formControlLabel}} control={
                  <Checkbox checked={checkboxCheck(option)} onChange={handleChange} name={option.uuid.toString()} disabled={option?.disabled || props?.disableAll} />
                } label={
                  <Typography variant='h2'>{option?.value}</Typography>
                } />
                {displayModifier(option)}
              </Box>
              <Typography className={`${classes.disabledColor} ${classes.headerSubtitle}`}>{option?.disabledText}</Typography>
            </Box>
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  )
})
