import React from 'react'
import {SvgIcon} from '@material-ui/core'
import { ReactComponent as QrCodeIconSVG } from './qr-code.svg'

const QRCodeIcon = (props) => (
  <SvgIcon {...props}>
    <QrCodeIconSVG />
  </SvgIcon>
);

QRCodeIcon.displayName = 'QRCodeIcon'
QRCodeIcon.muiName = 'SvgIcon'

export default QRCodeIcon
