import * as ActionTypes from './ActionTypes'

/**
 * If OrderNext should be in WebSDKMode or not.
 * Primarily used to signal in code to request data
 * or send actions to the JS Bridge.
 * @param {boolean} mode - Whether or not in SDK Mode
 */
export function setWebSDKMode(mode) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_SDKMODE,
      mode: mode,
    })
  }
}

/**
 * Set the platform that is currently being viewed from
 * @param {WebSDKPlatform} platform - Platform being viewed on
 */
export function setWebSDKPlatform(platform) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_PLATFORM,
      platform: platform
    })
  }
}

/**
 * Semantic version string of native SDK being utilized
 * @param {string} version
 */
export function setWebSDKVersion(version) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_VERSION,
      version: version
    })
  }
}

export function setWebSDKBundleId(bundleId) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_BUNDLE_ID,
      bundleId: bundleId
    })
  }
}

/**
 * Called to set receiving method name and accompanying data
 * from Native to JS via the bridge.
 * @param {object} data
 */
export function setWebSDKDataReceive(data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_DATA_RECIEVE,
      latestReceive: data
    })
  }
}

/**
 * Navigation modes for OrderNext to be configured in.
 * Some integrators may want different navigation behavior
 * and this is where we would signal it
 * @param {WebSDKNavMode} navMode - Nav mode to set
 */
export function setWebSDKNavMode(navMode) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_NAV_MODE,
      navMode: navMode
    })
  }
}

/**
 * Initial pathname navigated to from SDK. Used to determine
 * what is the 'root' page that you can't go back from anymore.
 * @param {string} pathName - Initial path of URL from SDK
 */
export function setInitialPathname(pathName) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_INITIAL_PATHNAME,
      pathName: pathName
    })
  }
}

/**
 * Set from the SDK when an external payment processor wants to handle payment requests and
 * delivering a nonce. Currently only SeatGeek utilizes this functionality.
 * @param {boolean} isExternalPaymentProcessor - Whether or not an external payment processor is
 * is to handle payment requests.
 */
export function setWebSDKIsExternalPaymentProcessor(isExternalPaymentProcessor) {
  return (dispatch) => {
    // Need to use yes or no as true/false or "true"/"false" get's stripped out by iOS
    // serialization
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_IS_EXTERNAL_PAYMENT_PROCESSOR,
      isExternalPaymentProcessor: (isExternalPaymentProcessor === 'yes' || isExternalPaymentProcessor === 'true') ? true : false
    })
  }
}

/**
 * Set from the SDK. In the case of All, we want to show the Stands product type tab
 * selection, if others, than hide.
 * @param {string} productType - Food | Merchandise | Experience | All
 */
export function setWebSDKProductType(productType) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_PRODUCT_TYPE,
      productType: productType
    })
  }
}

/**
 * Set from the SDK. When background color is provided, set the app container
 * background color just for the wallet in App.js as of now.
 * @param {string} hexColor - Hex color of string
 * @returns
 */
export function setWebSDKBackgroundColor(hexColor) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_BG_COLOR,
      bgColor: hexColor
    })
  }
}

/**
 * Set from the SDK. When accent color is provided, set the app container
 * background color just for the wallet in App.js as of now.
 * @param {string} hexColor - Hex color of string
 * @returns
 */
export function setWebSDKAccentColor(hexColor) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_ACCENT_COLOR,
      accentColor: hexColor
    })
  }
}

/**
 * Set from the SDK to pre-fill a user's seat. Integrator's should have section/row/seat
 * data so these should usually be valid.
 * @param {object} sectionRowSeat - User seat information
 * @param {string} sectionRowSeat.section - User prefilled section
 * @param {string} sectionRowSeat.row - User prefilled row
 * @param {string} sectionRowSeat.seat - User prefilled seat
 * @returns
 */
export function setSectionRowSeat(sectionRowSeat) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBSDK_SET_SECTIONROWSEAT,
      sectionRowSeat
    })
  }
}

