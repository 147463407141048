import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, ListItemText, Chip } from '@material-ui/core'

import { VNDetailedListItemArea } from '../../VNComponents/VNDetailedListItemArea'

//#region STYLES
const useStyles = makeStyles(theme => ({
  root: {},
  chip: {
    height: 20
  },
  rightArea: {
    width: '100%',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    marginLeft: 5
  },
}))
//#endregion

/**
 * List item for Food and Beverage or Merchandise in VNUserOrders.
 * Used for both Open and Completed Orders
 * @param {object} props - Props from parent
 */
export const VNUserOrdersListItem = (props) => {

  const classes = useStyles()

  //#region RENDERERS
  const renderTopArea = () => {
    if (props.topArea) {
      return (
        <Box pb={1}>
          <Chip label={props.topArea} color={props.chipColor ? props.chipColor : 'primary'} className={classes.chip}></Chip>
        </Box>
      )
    }
  }

  const renderRightArea = () => {
    return (
      <Box className={classes.rightArea}>
        <ListItemText
          primary={props.rightArea}
          primaryTypographyProps={{variant: "h2"}}
        />
      </Box>
    )
  }
  //#endregion

  return (
    <Fragment>
      <VNDetailedListItemArea
        title={props.title}
        subTitle={props.subTitle}
        leftAreaRatio={0}
        topArea={renderTopArea()}
        rightArea={renderRightArea()}
        bottomModifierText={props.bottomModifierText}
        bottomLinkText={props.bottomLinkText}
        onBottomLinkTextClick={props.onBottomLinkTextClick}
        click={props.click}
        eventDate={props.eventDate}
        salesEventText={props.salesEventText}
      />
    </Fragment>
  )
}
