import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchAllRevenueCenters } from '../VNRevenueCenters/ActionCreators'

import { getUser } from '../VNUser/Selectors'
import { getStands } from '../VNRevenueCenters/Selectors'

const useRevenueCenters = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => getUser(state))

  const [fetchAttempted, setFetchAttempted] = useState(false)

  useEffect(() => {
    if (!user.isEmpty() && !fetchAttempted) {
      setFetchAttempted(true)
      dispatch(fetchAllRevenueCenters())
    }
  }, [user])
}

export default useRevenueCenters
