import * as ActionTypes from './ActionTypes'
import { apiGetConfiguration } from './Api'

// THUNK
// get configuration
export function getConfiguration(org, env, scope, time) {
  return (dispatch) => {
    apiGetConfiguration(org, env, scope, time).then(response => {
      dispatch({
        type: ActionTypes.VN_SET_CONFIGURATION,
        configuration: response.data
      })
    }).catch(error => {
      console.log(error)
    })
  }
}
