import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { VNRoundedTextInput } from '../../VNComponents/VNRoundedTextInput'
import { useTranslation } from 'react-i18next'
import { getUser } from '../../VNUser/Selectors'
import { emailValidation } from '../../VNUtil/InputValidation'
import { getAnonymousUserData } from '../../VNUser/Selectors'

const useStyles = makeStyles(theme => ({
  root: {

  },
  smallInputField: {
    display: 'flex',
    flex: 1,
    '&:first-of-type': {
      marginRight: 10,
    },
    '&:nth-of-type(2)': {
      marginLeft: 10,
    }
  },
  topInputFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const CartInputFields = forwardRef((props, ref) => {

  const classes = useStyles();

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    validate() {
      return onValidateFields()
    }
  }))

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const anonymousUserData = useSelector(state => getAnonymousUserData(state))

  const { t } = useTranslation()

  // REFS
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const emailAddressRef = useRef()

  // HANDLES

  const hideFirstAndLastName = props.isExperience || props.isRichCheckoutQR || props.isSelfCheckout || props.isQuickPay

  // called when the next button is clicked
  const onValidateFields = () => {

    // run validation code on each input type
    const firstNameValidate = hideFirstAndLastName ? null : firstNameRef.current.validate()
    const lastNameValidate = hideFirstAndLastName ? null : lastNameRef.current.validate()
    const emailAddressValidate = emailAddressRef.current.validate()

    const formData = { user: {} }

    if (hideFirstAndLastName) {
      if (emailAddressValidate) {
        formData.isValidated = true
        formData.user.email = emailAddressRef.current.getInput()
      }
      else {
        formData.isValidated = false
      }
    }
    else {
      // check to see if they all validate
      if (  firstNameValidate &&
            lastNameValidate &&
            emailAddressValidate)
      {
        // create the user object
        formData.isValidated = true
        formData.user.first_name = firstNameRef.current.getInput()
        formData.user.last_name = lastNameRef.current.getInput()
        formData.user.email = emailAddressRef.current.getInput()
        formData.user.name = `${formData.user.first_name} ${formData.user.last_name}`
      }
      else {
        formData.isValidated = false
      }
    }

    return formData
  }

  // METHODS

  // a basic validation check to see there is text present
  const textValidation = (val) => {

    if (val.length <= 0 ) {
      return false
    }

    return true
  }

  const getPlaceholderFirstName = () => {
    if (!user.isEmpty()) {
      if (user.get('provider') === 'vn_anonymous' && !anonymousUserData.isEmpty()) {
        return anonymousUserData.get('first')
      }

      if (user.has('name')) {
        return user.get('name').split(" ")[0]
      }
    }

    return ''
  }

  const getPlaceholderLastName = () => {
    if (!user.isEmpty()) {
      if (user.get('provider') === 'vn_anonymous' && !anonymousUserData.isEmpty()) {
        return anonymousUserData.get('last')
      }

      if (user.has('name')) {
        return user.get('name').split(" ")[1]
      }
    }

    return ''
  }

  const getPlaceholderEmail = () => {
    if (!user.isEmpty()) {
      if (user.get('provider') === 'vn_anonymous' && !anonymousUserData.isEmpty()) {
        return anonymousUserData.get('email')
      }

      if (user.has('email')) {
        return user.get('email')
      }
    }

    return ''
  }

  const displayNameFields = () => {
    if (!hideFirstAndLastName) {
      return (
        <Box pt={3} pr={3} pl={3} className={classes.topInputFields}>
          <div className={classes.smallInputField}>
            <VNRoundedTextInput
              name={"firstName"}
              ref={firstNameRef}
              label={t('FIRST_NAME')}
              labelWidth={105}
              required
              default={getPlaceholderFirstName()}
              validation={textValidation} />
          </div>
          <div className={classes.smallInputField}>
            <VNRoundedTextInput
              name={"lastName"}
              ref={lastNameRef}
              label={t('LAST_NAME')}
              labelWidth={105}
              required
              default={getPlaceholderLastName()}
              validation={textValidation} />
          </div>
        </Box>
      )
    }
  }

  return (
    <Box>
      {displayNameFields()}
      <Box pt={3} pr={3} pl={3}>
        <VNRoundedTextInput
          name={"email"}
          ref={emailAddressRef}
          label={t('EMAIL_ADDRESS')}
          labelWidth={130}
          default={getPlaceholderEmail()}
          type="email"
          required
          validation={emailValidation} />
      </Box>
    </Box>
  )
})
