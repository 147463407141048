import React, { Fragment, useRef } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

import VNDivider from '../../VNComponents/VNDivider'
import { VNSwitch } from '../../VNComponents/VNSwitch'
import VNChipGroup from '../../VNComponents/VNChipGroup'
import { VNBottomStickyButton } from '../../VNMenu/components/VNBottomStickyButton'

import { setRvcFilters, setQueryParamRvcFilters } from '../../VNFilter/ActionCreators'
import { setServiceTypeSelected } from '../../VNQueryParams/ActionCreators'

import {
  getAvailableLocationsFromStands, getAvailableCategoriesFromStands,
  getAvailableServiceTypesFromStands, getDoesStandsHaveWaitTimes
} from '../Selectors'
import { getRvcFilterObj } from '../../VNFilter/Selectors'
import { getProductTypeSelected, getServiceTypeSelected } from '../../VNQueryParams/Selectors'

import { history } from '../../App/store/configureStore'

import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'column'
  },
  sectionBox: {
    marginTop: '15px',
    marginBottom: '25px'
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    fontWeight: 400,
    fontSize: '16px'
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '-12px'
  },
  switchTitle: {
    fontWeight: 400,
    fontSize: '16px',
    color: theme.custom.colors.black,
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}))

/**
 *
 */
export const VNRevenueCenterFilter = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const theme = useTheme()

  const { t } = useTranslation()

  //#region REFS
  const noWaitTimesSwitchRef = useRef()
  const serviceTypesChipGroupRef = useRef()
  const locationsChipGroupRef = useRef()
  const categoriesChipGroupRef = useRef()
  //#endregion

  //#region SELECTORS
  const productTypeSelected = useSelector(state => getProductTypeSelected(state))
  const serviceTypeSelected = useSelector(state => getServiceTypeSelected(state))
  const doesStandsHaveWaitTimes = useSelector(state => getDoesStandsHaveWaitTimes(state, productTypeSelected, t))
  const availableServiceTypesFromAllStands = useSelector(state => getAvailableServiceTypesFromStands(state, productTypeSelected))
  const availableLocations = useSelector(state => getAvailableLocationsFromStands(state, productTypeSelected))
  const availableCategories = useSelector(state => getAvailableCategoriesFromStands(state, productTypeSelected))

  const rvcFilterObj = useSelector(state => getRvcFilterObj(state))
  //#endregion

  //#region LOCAL STATE
  const resetFilterObj = {
    noWaitTimes: false,
    serviceTypes: [],
    locations: [],
    categories: []
  }
  //#endregion

  //#region USE EFFECTS
  //#endregion

  //#region FUNCTIONS
  const getTranslatedSelectedServiceType = () => {
    if (serviceTypeSelected === 'Concessions') {
      return 'Pickup'
    }

    return serviceTypeSelected
  }
  //#endregion

  //#region HANDLERS
  const handleResetClick = () => {
    noWaitTimesSwitchRef.current?.reset()
    serviceTypesChipGroupRef.current?.reset()
    locationsChipGroupRef.current?.reset()
    categoriesChipGroupRef.current?.reset()

    dispatch(setRvcFilters(resetFilterObj))
    dispatch(setQueryParamRvcFilters(resetFilterObj))
  }

  const handleDoneButtonClick = () => {
    const noWaitTimesValue = noWaitTimesSwitchRef?.current?.getSwitchState()
    const serviceTypeFilteredValues = serviceTypesChipGroupRef?.current?.getSelectedValues()
    const locationFilteredValues = locationsChipGroupRef?.current?.getSelectedValues()
    const categoriesFilteredValues = categoriesChipGroupRef?.current?.getSelectedValues()

    // Reset saved state for query param filters because user coming to filter page
    // means we want to override any existing entries brought in from the URL Query Params
    dispatch(setQueryParamRvcFilters(resetFilterObj))

    dispatch(setRvcFilters({
      noWaitTimes: noWaitTimesValue,
      locations: locationFilteredValues,
      categories: categoriesFilteredValues
    }))

    const serviceTypeValue = serviceTypeFilteredValues?.[0]

    if (serviceTypeValue === 'Pickup') {
      dispatch(setServiceTypeSelected('Concessions'))
    } else if (serviceTypeValue === undefined) {
      dispatch(setServiceTypeSelected('All'))
    } else {
      dispatch(setServiceTypeSelected(serviceTypeValue))
    }

    history.replace('/')
  }
  //#endregion

  //#region RENDERERS
  const displayHeader = () => {
    return (
      <Fragment>
        <Box>
          <Box className={clsx(classes.headerBox, classes.sectionBox)}>
            <Typography variant='h1'>
              {t('FILTER')}
            </Typography>
            <Typography
              className={classes.headerText}
              onClick={handleResetClick}>
                {t('RESET')}
            </Typography>
          </Box>
          {doesStandsHaveWaitTimes &&
          <Box mb={'15px'}>
            <VNSwitch
              ref={noWaitTimesSwitchRef}
              title={t('NO_WAIT_TIMES')}
              checked={rvcFilterObj.get('noWaitTimes')}
              classes={{
                root: classes.switch,
                title: classes.switchTitle
              }}
            />
          </Box>
          }
        </Box>
        <VNDivider />
      </Fragment>
    )
  }

  const displayServiceTypes = () => {
    if (availableServiceTypesFromAllStands.length > 1) {
      return (
        <Fragment>
          <Box className={classes.sectionBox}>
            <Typography variant='h2'>
              {t('SERVICE_TYPES')}
            </Typography>
            <Box mt='15px'>
              <VNChipGroup
                ref={serviceTypesChipGroupRef}
                radio
                deselectRadio
                defaultOptions={[getTranslatedSelectedServiceType()]}
                options={availableServiceTypesFromAllStands}
              />
            </Box>
          </Box>
          <VNDivider />
        </Fragment>
      )
    }

    return null
  }

  const displayLocation = () => {
    if (!isEmpty(availableLocations)) {
      return (
        <Fragment>
          <Box className={classes.sectionBox}>
            <Typography variant='h2'>
              {t('LOCATION')}
            </Typography>
            <Box mt='15px'>
              <VNChipGroup
                ref={locationsChipGroupRef}
                defaultOptions={rvcFilterObj.get('locations')}
                options={availableLocations.sort()}/>
            </Box>
          </Box>
          <VNDivider />
        </Fragment>
      )
    }

    return null
  }

  const displayCategories = () => {
    if (!isEmpty(availableCategories)) {
      return (
        <Fragment>
          <Box className={classes.sectionBox}>
            <Typography variant='h2'>
              {t('CATEGORIES')}
            </Typography>
            <Box mt='15px'>
              <VNChipGroup
                ref={categoriesChipGroupRef}
                defaultOptions={rvcFilterObj.get('categories')}
                options={availableCategories.sort()}/>
            </Box>
          </Box>
          <VNDivider />
        </Fragment>
      )
    }

    return null
  }
  //#endregion

  return (
    <Box className={classes.root}>
      {displayHeader()}
      {displayServiceTypes()}
      {displayLocation()}
      {displayCategories()}
      <VNBottomStickyButton text={t('DONE')} onClick={handleDoneButtonClick} />
    </Box>
  )
}

