import { useEffect } from 'react'

const useScript = url => {

  // check if this script is already appended
  const allScriptTags = document.getElementsByTagName('script')
  let scriptIsAppended = false

  for (const script of allScriptTags) {
    if (script.src === url) {
      scriptIsAppended = true
      break
    }
  }

  useEffect(() => {

    if (!scriptIsAppended) {
      const script = document.createElement('script')

      script.src = url
      script.async = true

      document.body.appendChild(script)
    }

  }, [url, scriptIsAppended]);
};

export default useScript
