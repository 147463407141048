import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { withTranslation } from 'react-i18next'

import './ServiceTypes.scss'

export class ServiceTypesText extends React.Component {

  messagePrefix = () => {
    const { serviceType, view, loading, t } = this.props
    const key = `${serviceType}-${view}`

    switch(key) {
      case 'delivery-cart':
        return <span className='message-prefix'>{loading ? <Skeleton width='50px' /> : `${t('DELIVERY_TO')} ` }</span>
      case 'pickup-cart':
        return <span className='message-prefix'>{loading ? <Skeleton width='50px' /> : `${t('PICKUP_AT')} `}</span>
      default:
        return null
    }
  }

  messageDetails = () => {
    const { serviceType, view, loading, t } = this.props
    const key = `${serviceType}-${view}`

    switch(key) {
      case 'delivery-cart':
        const { userLocation } = this.props
        const { section, sectionIsSuite, row, seat } = userLocation

        let displayText = ''
        if (loading) {
          displayText = <Skeleton width='50px' />
        } else {
          if (!sectionIsSuite) {
            displayText = `${t('SECTION')} ${section}, ${t('ROW')} ${row}, ${t('SEAT')} ${seat}`
          } else {
            displayText = section.match(/suite/i) ? section : `${t('SUITE')} ${section}`
          }
        }

        return <span className='bold'>{displayText}</span>
      case 'pickup-cart':
        const { standName } = this.props
        return <span className='stand-name-display'>{loading ? <Skeleton width='50px' /> : standName}</span>
      default:
        return null
    }
  }

  userName = () => {
    const { loading, userName } = this.props

    if (loading) return null
    if (userName) return <span className='user-name'>{`for ${userName}`}</span>
    return null
  }

  render() {
    return (
      <div className='service-type-text'>
        {this.messagePrefix()}
        {this.messageDetails()}
        {this.userName()}
      </div>
    )
  }
}

export default withTranslation()(ServiceTypesText)
