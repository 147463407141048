import * as ActionTypes from './ActionTypes'
import { Map, List } from 'immutable'

const getInitialState = () => {
  return Map({
    'seats': List([]),
    'pass': Map({}),
    'order_history': Map({}),
    'experience_order_history': List([]),
    'braintree_token': null,
    'shift4_token': null,
    'shift4_tokenization': Map({}),
    'vaulted_cards': List([])
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNWEBACCOUNT_SET_SEATS:
      return state.set('seats', List(action.seats))
    case ActionTypes.VNWEBACCOUNT_SET_ORDER_HISTORY:
      return state.set('order_history', Map(action.orders))
    case ActionTypes.VNWEBACCOUNT_SET_EXPERIENCE_ORDER_HISTORY:
      return state.set('experience_order_history', List(action.orders))
    case ActionTypes.VNWEBACCOUNT_SET_PASS:
      return state.set('pass', Map(action.pass))
    case ActionTypes.VNWEBACCOUNT_SET_BRAINTREE_CLIENT_TOKEN:
      return state.set('braintree_token', action.token)
    case ActionTypes.VNWEBACCOUNT_SET_SHIFT4_SERVER_AND_ACCESS_BLOCK:
      return state.set('shift4_token', action.shift4.token)
    case ActionTypes.VNWEBACCOUNT_RESET_SHIFT4_SERVER_AND_ACCESS_BLOCK:
      return state.set('shift4_token', null)
    case ActionTypes.VNWEBACCOUNT_SET_SHIFT4_TOKENIZATION:
      return state.set('shift4_tokenization', Map(action.tokenization))
    case ActionTypes.VNWEBACCOUNT_SET_VAULTED_CARDS:
      return state.set('vaulted_cards', List(action.vaulted_cards))
    default:
      return state
  }
}

export default reducer
