const VALIDIDATION_TYPE = {
  required: 'required',
  specificRequired: 'specificRequired',
  minRequired: 'minRequired',
  minMaxRequired: 'minMaxRequired',
  maxOptional: 'maxOptional',
  optional: 'optional',
  unknown: 'unknown',
}

class ModifierGroupValidator {
  constructor(min, max) {
    this.min = min
    this.max = max

    const validationTypeResolver = [
      (min === 1 && max === 1) ? VALIDIDATION_TYPE.required : false,
      (min > 0 && max > 0 && min === max) ? VALIDIDATION_TYPE.specificRequired : false,
      (min > 0 && (max === 0 || max === null)) ? VALIDIDATION_TYPE.minRequired : false,
      ((min === 0 || min === null) && max > 0) ? VALIDIDATION_TYPE.maxOptional : false,
      (min > 0 && max > 1) ? VALIDIDATION_TYPE.minMaxRequired : false,
      (min === null && max === null) ? VALIDIDATION_TYPE.optional : false,
      VALIDIDATION_TYPE.unknown,
    ]

    this.validationType = validationTypeResolver.find(resolver => resolver !== false)
  }

  isValid(itemsSelected = 0) {
    const { min, max, validationType } = this

    switch (validationType) {
      case VALIDIDATION_TYPE.required:
        return itemsSelected === 1

      case VALIDIDATION_TYPE.specificRequired:
        return itemsSelected === min

      case VALIDIDATION_TYPE.minRequired:
        return itemsSelected >= min

      case VALIDIDATION_TYPE.maxOptional:
        return itemsSelected <= max

      case VALIDIDATION_TYPE.minMaxRequired:
        return itemsSelected >= min && itemsSelected <= max

      case VALIDIDATION_TYPE.optional:
        return true

      default:
        return false
    }
  }
}

ModifierGroupValidator.types = VALIDIDATION_TYPE

export default ModifierGroupValidator
