import * as ActionTypes from './ActionTypes'
import { getUser } from '../VNUser/Selectors'
import { getVaultDefaultStand } from '../VNConfigurations/Selectors'
import {
  apiGetSeats,
  apiGetSeatPass,
  apiGetOrderHistory,
  apiGetExperienceOrderHistory,
  apiGetAwardedTransferredExperiences,
  apiGetBraintreeClientToken,
  apiGetVerificationCode,
  apiGetPaymentToken,
  apiGetVaultedCardsByUser,
  apiGetShift4PaymentToken
} from './Api'
import {
  setSnackbarSystemDataAlertError,
  setSnackbarSystemDataAlertInfo
} from '../VNSnackbarSystem/ActionCreators'
import { setApiLoadingStatus } from '../VNApiLoadingSystem/ActionCreators'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'
import { getFirstStandId } from '../VNRevenueCenters/Selectors'
import jwtDecode from '../VNUtil/JWTDecode'
import { enableBackdropSystem, disableBackdropSystem } from '../VNBackdropSystem/ActionCreators'

// Go fetch a users seats
export function retrieveSeats() {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiGetSeats(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_SEATS,
        seats: response.data.seats
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

// Go fetch a users pass for their seats
export function retrievePass() {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiGetSeatPass(jwt).then(response => {
      console.log(response.data)
      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_PASS,
        pass: response.data
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

// get the order history for the signin user
export const retrieveOrderHistory = () => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiGetOrderHistory(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_ORDER_HISTORY,
        orders: {
          data: response.data.data,
          included: response.data.included
        }
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

// Retrieve experience order history o nly. Currently
// utilized to find past purchases to restrict purchase
// limits on experiences.
export const retrieveExperienceOrderHistory = () => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    dispatch(setApiLoadingStatus(
      retrieveExperienceOrderHistory.name,
      { status: ApiLoadingStatus.LOADING }
    ))
    apiGetExperienceOrderHistory(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_EXPERIENCE_ORDER_HISTORY,
        orders: response.data
      })
      dispatch(setApiLoadingStatus(
        retrieveExperienceOrderHistory.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      console.log(error)
      setApiLoadingStatus(
        retrieveExperienceOrderHistory.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: error.message
        }
      )
    })
  }
}

export const retrieveAllOrderHistory = () => {
  return async (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    try {
      dispatch(setApiLoadingStatus(
        retrieveAllOrderHistory.name,
        { status: ApiLoadingStatus.LOADING }
      ))

      const allOrders = []
      let included = []

      try {
        const fnbOrders = await apiGetOrderHistory(jwt)
        allOrders.push(...fnbOrders.data.data)
        included = fnbOrders.data.included
      } catch (e) {
        console.log(e)
      }

      try {
        const expOrders = await apiGetExperienceOrderHistory(jwt)
        allOrders.push(...expOrders.data)
      } catch (e) {
        console.log(e)
      }

      try {
        const awardedExpOrders = await apiGetAwardedTransferredExperiences(jwt)
        allOrders.push(...awardedExpOrders.data)
      } catch (e) {
        console.log(e)
      }

      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_ORDER_HISTORY,
        orders: {
          data: allOrders,
          included: included
        }
      })
      dispatch(setApiLoadingStatus(
        retrieveAllOrderHistory.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    } catch(error) {
      setApiLoadingStatus(
        retrieveAllOrderHistory.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: error.message
        }
      )
      console.log(error)
    }
  }
}

// get the braintree client token from the signin user
export const retrieveBraintreeClientToken = () => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiGetBraintreeClientToken(jwt).then(response => {
      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_BRAINTREE_CLIENT_TOKEN,
        token: response.data.braintree_client_token
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

export const retrieveShift4PaymentToken = (orderNumber, amount, currencyCode, region) => {
  return (dispatch, getState) => {
    
    // see if we have an override for which stand uuid to use for the vault
    let standId = getVaultDefaultStand(getState())

    // if we have no stand ID from the configs, we need to fallback
    if (!standId) {
      // In the event of a fresh run, there may not be a stand id populated in the cart
      // so we just grab the first stand id in redux to allow for preauthorize
      standId = getState().cart.standId || getFirstStandId(getState())
    }

    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiGetPaymentToken(standId, orderNumber, amount, currencyCode, jwt, region).then(response => {
      dispatch({
        type: ActionTypes.VNWEBACCOUNT_SET_SHIFT4_SERVER_AND_ACCESS_BLOCK,
        shift4: {
          token: response.data.token
        }
      })
    }).catch(error => {
      console.log(error)
      if (typeof error.response?.data?.error === 'string') {
        dispatch(setSnackbarSystemDataAlertError(error.response.data.error))
      } else if (typeof error.response?.data?.error?.message === 'string') {
        dispatch(setSnackbarSystemDataAlertError(error.response.data.error.message))
      }
    })
  }
}

export const resetShift4PaymentToken = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBACCOUNT_RESET_SHIFT4_SERVER_AND_ACCESS_BLOCK
    })
  }
}

export const setShift4Tokenization = (data) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWEBACCOUNT_SET_SHIFT4_TOKENIZATION,
      tokenization: data
    })
  }
}

// call into our sms verification system to request a code to be sent to the user
// type: String - 'sms'
// phone_number: String -
// t: Func - useTranslator from hooks
export const retrieveVerificationCode = (type, phone_number, t) => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiGetVerificationCode(jwt, type, phone_number).then(() => {
      dispatch(setSnackbarSystemDataAlertInfo(t('NEW_CODE_REQUESTED')))
    }).catch(() => {
      dispatch(setSnackbarSystemDataAlertError(t('NETWORK_ERROR')))
    })
  }
}

/**
 * Get vaulted cards for a user, this fuction contains two api calls
 * 1. Get Shift4 Token from user/token
 * 2. Get user vaulted cards by using Shift4 token from 1
 * @returns 
 */
export const retrieveVaultedCardsByUser = () => {
  return async (dispatch, getState) => {

    const user = getUser(getState())
    const jwt = user.get('jwt')

    dispatch(enableBackdropSystem())

    try {
      dispatch(setApiLoadingStatus(
        retrieveVaultedCardsByUser.name,
        { status: ApiLoadingStatus.LOADING }
      ))

      const tokenResponse = await apiGetShift4PaymentToken(jwt)
      const shift4Token = tokenResponse.data.token

      if (shift4Token) {
        const decodedToken = jwtDecode(shift4Token)

        if (decodedToken.vaultServer) {
          const cardsResponse = await apiGetVaultedCardsByUser(shift4Token, decodedToken.vaultServer)

          dispatch({
            type: ActionTypes.VNWEBACCOUNT_SET_VAULTED_CARDS,
            vaulted_cards: cardsResponse.data
          })

          dispatch(setApiLoadingStatus(
            retrieveVaultedCardsByUser.name,
            { status: ApiLoadingStatus.SUCCEEDED }
          ))
        }
      } else {
        dispatch(setApiLoadingStatus(
          retrieveVaultedCardsByUser.name,
          {
            status: ApiLoadingStatus.FAILED,
            error: 'No Shift4 token'
          }
        ))
      }

    } catch (err) {
      dispatch(setApiLoadingStatus(
        retrieveVaultedCardsByUser.name,
        {
          status: ApiLoadingStatus.FAILED,
          error: err.message
        }
      ))
      console.log(err)
    }

    dispatch(disableBackdropSystem())
  }
}
