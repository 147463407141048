import * as ActionTypes from './ActionTypes'
import { retrieveLoyaltyUser } from '../VNWallet/ActionCreators'
/**
 * Open the Dialog (popup) that shows the Badge of the user
 * @param {Boolean} open - True - opens it, False, closes it
 */
export function setOpenBadgeDialog(open) {
  return (dispatch) => {

    dispatch(retrieveLoyaltyUser())

    dispatch({
      type: ActionTypes.VNWALLET_OPEN_BADGE_DIALOG,
      dialog: open
    })
  }
}

/**
 * Open the Dialog (popup) that shows an Exchange Service
 * @param {Object} dialogOptions - True - opens it, False, closes it
 */
export function setOpenExchangeServiceDialog(dialogOptions) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_OPEN_EXCHANGE_SERVICE_DIALOG,
      dialog: {open: dialogOptions.open, provider: dialogOptions.provider},
    })
  }
}

/**
 * Open the Dialog (popup) that shows all the payment options for a user
 * @param {Boolean} open - True - opens it, False, closes it
 */
export function setOpenPaymentUpdatesDialog(open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_OPEN_PAYMENT_UPDATES_DIALOG,
      dialog: open
    })
  }
}

/**
 * Open the Dialog (popup) that shows gift card transfer
 * @param {Boolean} open - True - opens it, False, closes it
 */
 export function setOpenGiftCardTransferDialog(open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNWALLET_OPEN_GIFT_CARD_TRANSFER_DIALOG,
      dialog: open
    })
  }
}
