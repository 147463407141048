import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import loading from '../../images/loading.svg'

import { createOrGetUser } from '../../VNUser/ActionCreators'
import { history } from '../../App/store/configureStore'
import { getUser, getCachedRoute } from '../../VNUser/Selectors'
import { getVirtualCurrencyEmailAccept } from '../../VNWallet/Selectors'
import { setCachedRoute } from '../../VNUser/ActionCreators'
import { setApiLoadingStatus } from '../../VNApiLoadingSystem/ActionCreators'
import { retrieveAllWallet  } from '../../VNWallet/ActionCreators'

import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

const queryString = require('query-string')

export const VNTMCallback = () => {

  const { t } = useTranslation()

  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => getUser(state))
  const cachedRoute = useSelector(state => getCachedRoute(state))
  /**
  * Check if there is an existing transfer email. This email parameter is currently only set from a
  * Virtual Currency transfer email sent to the transfer recipient, which directly
  * navigates the user to the VNUserSignUp and gets reset upon detecting that the
  * transfer shows up in the VNVirtualCurrencyActivity page.
  */
  const transferEmail = useSelector(state => getVirtualCurrencyEmailAccept(state))

  const [createOrGetUserDispatched, setCreateOrGetUserDispatched] = useState(false)

  // used to parse the code from ticketmaster
  useEffect(() => {
    // parse the query string back from ticketmaster
    // make a call into the VN API to create or get the user
    if (user.isEmpty() || user.get('provider') === 'vn_anonymous') {
      const parsed = queryString.parse(location.search);
      if (parsed) {
        if (parsed.code && !createOrGetUserDispatched) {
          setCreateOrGetUserDispatched(true)
          console.log(parsed.code)
          dispatch(createOrGetUser(parsed.code, 'ticketmaster', null, t)) // THUNK
          // dispatch(actionCreateTMUser(parsed.code)) // SAGA
        }
      }
    }

    if ( !user.isEmpty() && user.get('provider') === 'ticketmaster' ) {
      if (isEmpty(transferEmail)) {
        if (cachedRoute) {
          dispatch(setApiLoadingStatus(retrieveAllWallet.name, { status: ApiLoadingStatus.UNKNOWN }))
          history.push(cachedRoute, { fromRoute: VNTMCallback.name })
          dispatch(setCachedRoute(null))
        } else {
          history.push('/wallet')
        }
      } else {
        history.push('/wallet/virtual-currency/activity')
      }
    }
  }, [user])

  const style = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  }

  return (
    <div style={style}>
      <img src={loading} alt="loading"/>
    </div>
  );

}

// Override Function.name because build minification mangles function names
// and some functions aren't retaining their names properly which affects
// code in other places, namely Cart.js
Object.defineProperty(VNTMCallback, 'name', { value: 'VNTMCallback' })
