import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useStateWithCompletion } from '../VNUtil/hooks/useStateWithCompletion'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import ReactModal from 'react-modal'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 9999
  }
}))

export const VNModal = forwardRef((props, ref) => {

  const classes = useStyles()

  const theme = useTheme()

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    close() {
      handleCloseModal()
    }
  }))

  //#region SELECTORS
  //#endregion

  //#region REFS
  const modalRef = useRef()
  //#endregion REFS

  //#region LOCAL STATE
  const [showModal, setShowModalWithCompletion] = useStateWithCompletion(true)
  const isOpen = props.isOpen
  //#endregion

  //#region USE EFFECTS
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#root')
    }

    // Leaving previous code in here. For iOS Safari
    // need to clear body scroll locks for this modal to scroll.
    // Old ItemModal.js was polling the page and setting things manually
    // which is a ridiculous hack to just get this to work.
    // disableBodyScroll(modalRef.current)
    clearAllBodyScrollLocks()

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])
  //#endregion

  //#region HANDLERS
  const handleCloseModal = () => {
    if(!props.shouldNotCloseOnOverlayClick) {
      enableBodyScroll(modalRef.current)
  
      setShowModalWithCompletion(false, () => {
        setTimeout(props.onCloseModal, 350)
      })
    }
  }
  //#endregion

  //#region RENDERERS
  //#endregion

  return (
    <ReactModal
      isOpen={isOpen ? isOpen : showModal}
      shouldCloseOnOverlayClick
      closeTimeoutMS={350}
      onRequestClose={handleCloseModal}
      className={classes.root}
      ref={modalRef}
      className={{
        base: classNames('modal', props.className, classes.root),
        afterOpen: 'modal-after-open',
        beforeClose: 'modal-before-close'
      }}
      overlayClassName={{
        base: classNames('modal-overlay', classes.root),
        afterOpen: 'modal-overlay-after-open',
        beforeClose: 'modal-overlay-before-close'
      }}>
        {props.children}
    </ReactModal>
  )
})
