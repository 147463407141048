import EN_US from './en-US'
import EN_GB from './en-GB'
import ES_ES from './es-ES'
import EN_IE from './en-IE'
import i18n from './i18n'

i18n.fallbacks = true;
i18n.translations = { 'en-US': EN_US, 'en-GB': EN_GB, 'es-ES': ES_ES, 'en-IE': EN_IE };

const TRANSLATE = (s,o) => {
  return i18n.t(s,o)
}

export default TRANSLATE
