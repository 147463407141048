import axios from 'axios'
import { BASE_URL, ORG_NAME, VENUE_UUID } from '../constants'

/**
 * Fetch the pickup time slots availability for a given stand and date
 * @param {string} token - The JWT Token
 * @param {string} standId - Stand UUID to fetch
 * @param {string} date - Date of availability to fetch for (YYYY-DD-M)
 */
export const apiFetchStandAvailability = (token, standId, date) => {
  return axios.get(`${BASE_URL}/stands/${standId}/availability?date=${date}`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}

/**
 * Fetch the details about a specific order
 * @param {*} token - The JWT Token
 * @param {*} orderId - The order ID that you want to get details about
 */
export const apiFetchOrder = (token, orderId) => {
  return axios.get(`${BASE_URL}/orders/${orderId}?organization_name=${ORG_NAME}&venue_uuid=${VENUE_UUID}`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}
