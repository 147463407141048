const EN_US = {
  credit_cards: 'Credit Cards',
  card_number: 'Card Number',
  exp_month_year: 'Exp MM/YY',
  cvv_code: 'CVV Code',
  exp_date: 'Exp Date',
  cvv: 'CVV',
  add_a_credit_card: 'Add a Credit Card',
  apple_pay: 'Apple Pay',
  add_a_card: 'Add a Card',
  card_declined: 'That card was declined'
}

module.exports = EN_US
