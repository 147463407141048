import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment-timezone'

import { ExperienceCheckout } from './ExperienceCheckout'

import { updateOrderMetadata } from '../actions/order'

import { makeGetOrder } from '../selectors/order'
import { makeGetRemote } from '../selectors/remote'
import { withTranslation } from 'react-i18next'

import Remote from '../remote'

export class EditMetadata extends Component {
  state = {
    formSubmitted: false,
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props
    const { formSubmitted } = this.state

    if (prevProps.loading && !loading && formSubmitted) {
      this.close()
    }
  }

  onFormCompletion = () => {
    this.setState({
      formSubmitted: true,
    })
  }

  close = () => {
    const { redirectRoute } = this.props
    this.props.history.replace(redirectRoute)
  }

  updateMetadata = (itemId, metadata) => {
    const { updateOrderMetadata, userItem, metadataId } = this.props
    let newMetadata = userItem.metadata.filter((metadata) => metadata.id !== metadataId)
    newMetadata = newMetadata.map((metadata) => ({ name: metadata.name, value: metadata.value }))
    newMetadata.push(metadata)

    updateOrderMetadata(userItem.id, newMetadata)
  }

  render() {
    return <ExperienceCheckout
      {...this.props}
      onFormCompletion={this.onFormCompletion}
      updateMetadata={this.updateMetadata}
      onClose={this.close}
    />
  }
}

function mapStateToProps(state, ownProps) {
  const orderId = get(ownProps, 'match.params.orderId', '')
  const userItemId = get(ownProps, 'match.params.userItemId', '')
  const metadataId = get(ownProps, 'match.params.metadataId', '')

  const getOrder = makeGetOrder()
  const getRemote = makeGetRemote()

  const remote = getRemote(state, Remote.endpoints.updateOrderMetadata)
  const order = getOrder(state, orderId)
  const userItem = get(order, 'userItems', []).find((userItem) => userItem.id === userItemId)
  const itemId = get(userItem, 'itemId', '')
  const metadata = get(userItem, 'metadata', []).find((metadata) => metadata.id === metadataId)
  const redirectRoute = `/experience_orders/${orderId}/${userItemId}`
  const initialValue = metadata.dataType === 'age' ? moment(metadata.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : metadata.value

  return {
    editMode: true,
    initialValue,
    itemId,
    metadataId,
    loading: remote.loading,
    redirectRoute,
    userItem,
    screens: {
      1: [{
        title: ownProps.t('MODIFY'),
        description: ownProps.t('YOU_CAN_EDIT_THESE_UNTIL_YOU_REDEEM'),
        fieldKey: metadata.id,
        fieldLabel: metadata.name,
        fieldType: metadata.dataType,
        maxCharacterCount: 250,
        restriction: metadata.restriction
      }]
    }
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const userItemId = get(ownProps, 'match.params.userItemId', '')

  return {
    updateOrderMetadata: (itemId, metadata) => dispatch(updateOrderMetadata(userItemId, metadata)),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditMetadata))
