import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Router, Route } from 'react-router-dom'
import { VNLoader } from './VNComponents/VNLoader'
import { App } from './containers/App'
import * as serviceWorker from './serviceWorker'
import { store, persistor, history } from './App/store/configureStore'
import './index.scss'
import './App/languages/i18n'
import 'react-app-polyfill/ie11'
import { datadogRum } from '@datadog/browser-rum';
import { ORG_ENV, LOCAL_STORAGE_BUSTER_VERSION } from './constants'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NXWLR3M'
}
TagManager.initialize(tagManagerArgs)

datadogRum.init({
  applicationId: '6b27c488-41c6-4ac9-9721-e56e1e5ce3b9',
  clientToken: 'pub72c432af9cb09e6a3fd5554e5b754cde',
  site: 'datadoghq.com',
  service:'ordering_web',
  sampleRate: 100,
  env: ORG_ENV,
  version: 'v' + LOCAL_STORAGE_BUSTER_VERSION,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackResources: false,
  trackLongTasks: false,
  sessionReplaySampleRate: 0
});

const onBeforeLift = () => {
  console.log('Redux Store Rehydrated...Opening Gate!')
}

const Component = (
  <Provider store={store}>
    <Router history={history}>
      <PersistGate loading={<VNLoader />} persistor={persistor} onBeforeLift={onBeforeLift}>
        <Route path="/" render={(props) => <App {...props} />} />
      </PersistGate>
    </Router>
  </Provider>
)

ReactDOM.render(Component, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
