/**
 * Function to determine whether or not to use a black or white foreground color
 * depending on the background
 * @param {string} hexcolor - Background hexcolor to determine contrast for foreground
 * @returns
 */
export default function getContrastYIQ(hexcolor){
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}
