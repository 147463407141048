import { call, put, takeLatest, all, select } from 'redux-saga/effects'

import Remote from '../remote'
import { delay } from 'redux-saga'
import { setLoading, setFailed, setSucceeded } from '../actions/remote'
import { loadStandsSucceeded, loadStandsFailed, STANDS_REQUESTED } from '../actions/stand'
import { getUser } from '../VNUser/Selectors'
import { VNBACKDROPSYSTEM_SET } from '../VNBackdropSystem/ActionTypes'


export function* fetchWithRetry(token, retryCount) {
  for(let i = 0; i < retryCount; i++ ) {
    try {
      const response = yield call(Remote.getStands, token, retryCount)
      return response
    } catch(err) {
      if (retryCount === 1) {
        throw err
      }
      if( i < retryCount) {
        yield delay(1000)
      }
    }
  }

  throw new Error('Retry Max')
}

export function* fetchStands(params) {
  const endpoint = Remote.endpoints.getStands
  yield put(setLoading(endpoint))

  const user = yield select(getUser);
  const token = user.get('jwt')

  if (params.showSpinner) {
    yield put({ type: VNBACKDROPSYSTEM_SET, enabled: true })
  }

  try {
    const result = yield call(fetchWithRetry, token, params.retryCount)

    yield all([
      put({ type: VNBACKDROPSYSTEM_SET, enabled: false }),
      put(loadStandsSucceeded(result)),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put({ type: VNBACKDROPSYSTEM_SET, enabled: false }),
      put(loadStandsFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchFetchStands() {
  yield takeLatest(STANDS_REQUESTED, fetchStands)
}
