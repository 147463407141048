import axios from 'axios'
import normalize from 'json-api-normalizer'
import humps from 'humps'
import { values, isEmpty } from 'lodash'

import JSONAPI from '../utils/jsonapi'

import { ORG_NAME, VENUE_UUID, BASE_URL } from '../constants'

const instance = axios.create({
  baseURL: BASE_URL
})

function baseRequest({ method = 'get', path = '', body = {}, token = '', ...fields }, normalizeData) {

  let options = {
    method: method,
    url: path,
    data: body,
    ...fields,
    params: { venue_uuid: VENUE_UUID, organization_name: ORG_NAME, ...fields.params },
    headers: {
      "Authorization": "Bearer " + token
    }
  }

  return new Promise((resolve, reject) => {
    instance(`/${path}`, options).then((response) => {
      if (!normalizeData) {
        resolve(response.data)
      } else {
        let copiedResponse = { ...response }

        if (path === 'stands') {
          const events = copiedResponse.data.meta.events
          const jsonapiEvents = JSONAPI.event(values(events))

          copiedResponse.data.included.push(...values(jsonapiEvents.event))

          if (!isEmpty(events)) delete copiedResponse.data.meta.events
        }

        const parsedData = { ...normalize(copiedResponse.data), meta: humps.camelizeKeys(copiedResponse.data.meta) }

        resolve(parsedData)
      }
    }).catch((err) => {
      if ("response" in err && err.response && "data" in err.response) {
        if (err?.response?.data?.errors?.detail) {
          reject(err.response.data.errors.detail)
        } else {
          // Specific handling for 422 Unprocessable Entity errors on createOrder
          // calls, typically when failed
          if (err.response.status === 422) {
            const errData = err.response.data
            // Friendly messages now available via stadium, utilize these to display
            // via snackbar
            if (errData.friendly_message) {
              reject({
                category: errData.error_categories[0],
                friendlyMessage: errData.friendly_message
              })
            } else {
              reject(err.response.data.error_categories[0])
            }
          }
        }
      } else {
        reject(err.toString())
      }
    })
  })
}

const get = (parameters, normalizeData = true) => baseRequest({ method: 'get', ...parameters }, normalizeData)
const post = (parameters, normalizeData = true) => baseRequest({ method: 'post', ...parameters }, normalizeData)
const patch = (parameters, normalizeData = true) => baseRequest({ method: 'patch', ...parameters }, normalizeData)

class Remote {
  static getStands = (token) => get({ path: 'stands', token: token }, false)
  static getMenu = (params, token) => get({ path: `menu/${params.menuId}`, token: token }, false)

  static createOrder = (params, token) => post({
    path: `orders?organization_name=${ORG_NAME}`,
    body: params,
    token: token
  })

  static createExperienceOrder = (params, token) => post({
    path: `orders?organization_name=${ORG_NAME}`,
    body: params,
    token: token
  }, false)

  static updateOrder = (params, token) => patch({
    path: `orders/${params.id}?organization_name=${ORG_NAME}`,
    body: params.orderParams,
    token: token
  })

  static getOrder = (params, token) => get({
    path: `orders/${params.id}?organization_name=${ORG_NAME}`,
    token: token
  })

  static getExperienceOrder = (params, token) => get({
    path: `orders/${params.id}?organization_name=${ORG_NAME}`,
    params: { product_type: "Experience" },
    token: token
  }, false)

  static orderTotal = (params, token) => post({
    path: `orders/total?organization_name=${ORG_NAME}`,
    body: params,
    token: token
  })

  static experienceOrderTotal = (params, token) => post({
    path: `orders/total?organization_name=${ORG_NAME}`,
    body: params,
    token: token
  }, false)

  static getSectionsForStand = (params, token) => get({
    path: `stands/${params.standId}/sections?organization_name=${ORG_NAME}`,
    token: token
  }, false)

  static getRowsForSection = (params, token) => get({
    path: `sections/${params.section}/rows?organization_name=${ORG_NAME}`,
    params: {
      aisle_ids: params.aisleIds
    },
    token: token
  }, false)

  static getSeatsForRow = (params, token) => get({
    path: `sections/${params.section}/rows/${params.row}/seats?organization_name=${ORG_NAME}`,
    params: {
      aisle_ids: params.aisleIds
    },
    token: token
  }, false)

  static getMenusForSeatLocation = (params, token) => get({
    path: `seats/stand_menus?organization_name=${ORG_NAME}`,
    params: {
      order_location_uuid: params.orderLocationUuid,
    },
    token: token
  }, false)

  static redeemUserItem = (params, token) => {
    return post({
      path: `firesale/user_items/${params.itemId}/redeem?organization_name=${ORG_NAME}`,
      token: token
    }, false)
  }

  static refreshUserItem = (params, token) => get({
    path: `firesale/user_items/${params.id}?organization_name=${ORG_NAME}`,
    token: token
  }, false)

  static updateOrderMetadata = (params, token) => {
    return patch({
      path: `firesale/user_items/${params.userItemId}?organization_name=${ORG_NAME}`,
      body: {
        metadata: params.metadata,
      },
      token: token
    })
  }
}

const propertyNames = Object.getOwnPropertyNames(Remote)
const filteredPropertyNames = propertyNames.filter(key => !['length', 'name', 'prototype'].includes(key))
const endpoints = filteredPropertyNames.reduce((accumulator, key) => {
  return {
    ...accumulator,
    [key]: key,
  }
}, {})

Remote.endpoints = endpoints

export default Remote
