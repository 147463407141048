var IEData = {
  translation: {
    CURRENCY: "EUR",
    CURRENCY_SYMBOL: "€",
    CUSTOM_TIP_AMOUNT: "Please enter a custom tip amount.",
    OK: "OK",
    AMOUNT: "Amount",
    creditCard: {
      number: {
        displayText: "Card Number",
      },
      expirationDate: {
        displayText: "Expiry Date",
      },
      cvv: {
        displayText: "Security Number",
      },
      postalCode: {
        displayText: "Postal Code",
      },
    },
    LOG_IN: 'Log In',
    REPORT_AN_ISSUE: 'Report an Issue',
    TERMS_OF_SERVICE: 'Terms of Use',
    PRIVACY_POLICY: 'Privacy Policy',
    HOME: 'Home',
    MY_ACCOUNT: 'My Account',
    ORDERS: 'Orders',
    SIGN_OUT: 'Sign Out',
    PASSES: 'Passes',
    PAYMENTS: 'Payments',
    MULTIPLIER: 'multiplier',
    LOADING_PASS: 'Loading pass',
    ORDER_NEXT: 'OrderNext',
    APPLE_PAY_FAILED_TO_LOAD: 'Apple Pay failed to load. Please try using a credit card.',
    COULD_NOT_COMPLETE_PURCHASE_CARD_ON_FILE: "We couldn't complete your purchase with the card on file in your Apple Pay account. Please try using a credit card.",
    IS_REQUIRED: 'is required',
    INVALID_VALUE_FOR: 'Invalid value for',
    REQUIRED: 'Required',
    PAYMENT_PROCESSOR_CONNECTION_ERROR: "We're having trouble connecting to our payment processor.",
    RECIPIENT_NAME: 'Recipient Name',
    NAME_REQUIRED: 'Name is required',
    PLACE_ORDER: 'Place Order',
    TIP: 'Tip',
    SERVICE_FEE: 'Service Fee',
    SERVICE_CHARGES: 'Service Charges',
    DISCOUNT: 'Discount',
    TAXES: 'Taxes',
    TOTAL: 'Total',
    DISMISS: 'Dismiss',
    ARE_YOU_SURE: 'Are you sure?',
    VIEW_CART: 'view cart',
    EMAIL_RECEIPT: 'Email Receipt',
    EMAIL_IS_REQUIRED: 'Email is required',
    CREATING_YOUR_ORDER: 'creating your order',
    GETTING_ORDER_INFO: 'getting your order information',
    CALCULATING_ORDER_TOTAL: 'calculating your order total',
    THERE_WAS_AN_ERROR: 'There was an error',
    GET_ORDER_ERROR: "If refreshing this page doesn't help, you can use the Order Number in your email receipt to pick up your order.",
    GET_ORDER_TOTAL_ERROR: 'This might be temporary. You can try refreshing the page before placing your order.',
    BRAINTREE_LOAD_ERROR: "We're having trouble connecting to the payment processor. If the problem persists after refreshing the page, we won't be able to process your order. We've been notified of this issue, and we apologize for the inconvenience.",
    DATE: 'date',
    EVENTS_IN: 'events in',
    BACK: 'Back',
    BACK_LOWERCASE: 'back',
    SELECT_AN_EVENT: 'select an event',
    ALL_EVENTS: 'all events',
    REDEEM_SOON: 'REDEEM SOON',
    REDEEM_NOW: 'REDEEM NOW',
    REDEEMED: 'REDEEMED',
    EXPIRED: 'EXPIRED',
    REDEEMING: 'REDEEMING',
    INFO: 'info',
    EACH: 'each',
    ONLY: 'only',
    LEFT: 'left!',
    REMOVE: 'remove',
    REQUIRED_LOWERCASE: 'required',
    PICK_AT_LEAST: 'pick at least',
    PICK_UP_TO: 'pick up to',
    OPTIONAL: 'optional',
    QR_CODE: 'QR Code',
    DELIVERY_TO: 'Delivery to',
    PICKUP_AT: 'Pickup at',
    SECTION: 'Section',
    ROW: 'Row',
    SEAT: 'Seat',
    SUITE: 'Suite',
    REMOVE_ALL_ITEMS: 'This will remove all items from your current order.',
    THIS_WILL_REMOVE: 'This will remove',
    FROM_YOUR_CART: 'from your cart',
    EVENT_DATE: 'Event Date',
    EVENT: 'Event',
    ITEM: 'Item',
    ITEMS: 'Items',
    CART: 'Cart',
    EXPERIENCE: 'experience',
    MENU: 'menu',
    CLEAR_CART: 'clear cart',
    ADD_ITEMS: 'add items',
    NOT_NEEDED_FOR_SUITES: 'Not needed for suites',
    LOADING: 'loading',
    SELECT_YOUR_SECTION_OR_SUITE: 'Select your section or suite',
    SELECT_YOUR_ROW: 'Select your row',
    SELECT_YOUR_SEAT: 'Select your seat',
    SECTION_LOWERCASE: 'section',
    OR: 'or',
    SUITE_LOWERCASE: 'suite',
    CONFIRM_SEAT: 'Confirm Seat',
    SAVE_SEAT: 'Save Seat',
    VALIDATING_SEAT: 'Validating Seat',
    YOUR_SEAT: 'Your Seat',
    SEAT_NOT_ELIGIBLE_FOR_DELIVERY_FROM_THIS_STAND: 'Seat not eligible for delivery from this stand',
    MODIFY: 'Modify',
    YOU_CAN_EDIT_THESE_UNTIL_YOU_REDEEM: 'You can edit these until you redeem',
    PLEASE_FILL_IN_THE_ABOVE: 'Please fill in the above.',
    PLEASE_ADD_YOUR_SEAT: 'Please add your seat.',
    EDIT_THESE_AFTER_PLACING_ORDER: 'You can edit these after placing your order',
    ENTER_YOUR: 'Enter your',
    DONE: 'Done',
    NEXT: 'Next',
    WHEN_YOU_ARE_READY_TO_REDEEM: "When you're ready to redeem, tap the redeem now button and then present the",
    CONFIRMATION_SCREEN: 'confirmation screen',
    SECOND_CONFIRMATION_SCREEN: 'second confirmation',
    HOW_DO_I_REDEEM: 'How do I redeem',
    HOW_DO_I_REDEEM_CAPS: 'HOW DO I REDEEM',
    EDIT: 'edit',
    YOU_CAN_ONLY_ORDER_FOR_ONE_EVENT: 'You can only order for one event at a time',
    MAX_QUANTITY_REACHED: 'max quantity reached',
    ORDER_NOW: 'Order Now',
    EMAIL_MY_RECEIPT: 'Email My Receipt',
    ORDER: 'Order',
    YOU_ARE_ABOUT_TO_REDEEM: "You're about to redeem your experience and will have to present the confirmation. This can’t be undone so make sure you're ready.",
    AT: 'at',
    ON: 'on',
    BETWEEN: 'between',
    AND: 'and',
    WHAT_HAPPENS_NEXT: 'WHAT HAPPENS NEXT',
    KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS: 'Keep a look out for further instructions regarding your purchase.',
    THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM: 'This experience will be available to redeem',
    THIS_EXPERIENCE_EXPIRED_ON: 'This experience expired on',
    THIS_EXPERIENCE_WAS_REDEEMED_ON: 'This experience was redeemed on',
    ORDER_NUMBER: 'Order Number',
    ORDER_DETAILS: 'Order Details',
    PLACED_ON: 'Placed on',
    RECEIPT: 'Receipt',
    SUBTOTAL: 'Subtotal',
    NOTHING_FOR_SALE: "There's nothing on sale for this date",
    PLEASE_TRY_ANOTHER_EVENT: 'Please try another event, or check back soon to see if anything becomes available for this one',
    ONLY_CAPS_START: 'Only',
    OF_THIS_ITEM_PER_ORDER_ALLOWED: 'of this item per order allowed',
    SELECT_ONE: 'select one',
    CONFIRM: 'CONFIRM',
    ADD_TO_CART: 'Add to Cart',
    MUST_BE_21: 'Must be 21+ with vaild ID to order alcohol',
    LOCATION_NOT_CURRENTLY_ACCEPTING_ORDERS: 'This location is not accepting new orders',
    SENDING: 'SENDING',
    EMAIL_SENT: 'EMAIL SENT',
    FAILED_TRY_AGAIN: 'FAILED: PLEASE TRY AGAIN LATER',
    KIOSK_ORDER: 'Kiosk Order',
    SCAN_THIS_CODE: 'Scan this code when you pick up your order at',
    PRESENT_THIS_PAGE: 'Present this page when you pick up your order at',
    PLEASE_PICKUP_AT: 'Please pickup at',
    SHOW_THIS_SCREEN_TO_RECEIVE_YOUR_ORDER: 'Show this screen to confirm your order.',
    HANG_TIGHT: "Hang tight - we're checking on your order until we're sure it has reached the kitchen",
    ONCE_IT_DOES: "Once it does, you'll see a QR Code here that you can use to pick up your order",
    WE_HOPE_YOU_ENJOYED_YOUR_ORDER: 'We hope you enjoyed your order, thanks again for trying OrderNext!',
    SEND: 'SEND',
    MOBILE_ORDERING_MENUS: 'Mobile Ordering Menus - IE',
    ALMOST_DONE: 'almost done',
    WE_NEED_SOME_MORE_INFO: 'we need some more information',
    ONE_LAST_THING: 'one last thing',
    EXPERIENCES: 'Experiences',
    FOOD_AND_DRINKS: 'Food & Drinks',
    MERCHANDISE: 'Merchandise',
    OPEN_ORDER: 'Open Order',
    PREPARING_YOUR_ORDER: 'Preparing your order',
    FREE: 'FREE',
    SIGNUP_IS_EASY: 'Sign up is easy!',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number',
    MESSAGE_RATES_MAY_APPLY: 'Message and data rates may apply',
    PLEASE_ENTER_THE_CODE: 'Please enter the code sent to ',
    CONFIRMATION_CODE: 'Confirmation Code',
    RESEND_CODE: 'RESEND CODE',
    ENTER_YOUR_PHONE_NUMBER: 'Enter your phone number',
    SIGN_UP: 'Sign Up',
    NO_PHONE_USER_EXISTS: 'This number does not exist.',
    INVALID_PHONE: 'Please enter a valid number.',
    INVALID_CODE: 'Please enter a valid code.',
    NETWORK_ERROR: 'VenueNext is experiencing an issue, please try again.',
    GENERIC_ERROR: 'Something went wrong, please go back and try again.',
    NEED_NEW_CODE: 'Code is no longer valid, please request a new one.',
    NEW_CODE_REQUESTED: 'A new code has been requested.',
    TEXT_ME_WHEN_READY: "Text me order status",
    TEXT_ORDER_CONFIRM: 'We will text you with order updates.',
    SMS_NOTIFICATIONS: 'Text me order updates:',
    MY_INFORMATION: 'My Information',
    USER_UPDATE_SUCCESS: 'User info saved.',
    ORDER_AGAIN: 'Order Again',
    ALL: 'All',
    PICKUP: 'Pickup',
    DELIVERY: 'Delivery',
    ENTER_PROMO_CODE: 'Enter a Promo Code',
    PROMO_CODE: 'Promo Code',
    APPLY: 'Apply',
    SMS_ORDER_NOTIFICATIONS: 'Thank you for submitting your phone number for updates!',
    SHOP: 'Shop',
    SCAN_AND_PAY: 'Scan & Pay',
    SEND_COURT_CASH: 'Send Cout Cash',
    COURT_CASH_ACTIVITY: 'Court Cash Activity',
    BENEFITS_AND_REWARDS: 'Benefits & Rewards',
    MY_INFO: 'My Info',
    MY_BADGE: 'My Badge',
    MY_WALLET: 'My Wallet',
    SAVE: 'Save',
    PLEASE_CORRECT: 'Please correct the form fields above.',
    CENTER_QR_CODE: 'Center the QR code or barcode to scan',
    PRESENT_QR_CODE: 'Present this QR code to pay with',
    PRESENT_QR_CODE_TO_APPLY_DISCOUNTS: 'Apply eligible discounts and pay with CASH_TYPE, where accepted',
    UPDATE: 'UPDATE',
    PRESENT_THIS_BADGE: 'Present this screen where accepted',
    ACTIVITY: 'Activity',
    SEND_TRANSFER: 'Send',
    TRANSFER: 'Transfer',
    NO_EXCHANGE_SERVICE: 'No tickets for the next $DAYS days with loaded value',
    AVAILABLE_LOAD: 'Tickets for upcoming event available to load on',
    RECIPIENT_EMAIL: 'Recipient Email',
    ENTER_AMOUNT: 'Enter Amount',
    ELIGIBLE_TRANSFER: 'eligible to be transferred. Expiring ',
    CANNOT_TRANSFER: 'may not be transferred.',
    SEND_NOW: 'Send Now',
    WAYS_TO_EARN: 'Ways to Earn',
    LEARN_MORE: 'LEARN MORE',
    SEND_A_NOTE: 'Send a Note',
    CLICK_TO_SCAN: 'Tap here to Scan',
    USE_MY: 'Use my',
    FOR_PURCHASES: 'for purchases',
    CREDIT_CARDS: 'Credit Cards',
    CREDIT_CARD: 'Credit Card',
    ADD_A_CARD: 'Add a Card',
    PAYMENT: 'Payment',
    REMAINING_BALANCE: 'The remaining balance of',
    MUST_BE_PAID_BY_CC: 'must be paid by credit card.',
    SORRY: "We're sorry",
    NOT_ENABLED_FOR_PRODUCT_TYPE: 'is not enabled for this purchase.',
    PROMO_CODE_REMOVED: 'Promo code removed',
    PROMO_CODE_ADDED: 'Promo code applied',
    CANCEL_TRANSFER: 'Cancel Transfer',
    ABOUT_TO_CANCEL: 'You are about to cancel a scheduled transfer to',
    YES: 'YES',
    NO: 'NO',
    TRANSFER_PROCESSING: 'Your transfer is processing.',
    PICKUP_YOUR_ORDER: 'Pickup your order at',
    SCAN: 'Scan',
    COMPLETED: 'Completed',
    OPEN_ORDERS: 'Open Orders',
    REDEEMABLE: 'Redeemable',
    LOGIN_TO_ACCEPT: 'Log in to accept your transfer',
    SIGNUP_TO_ACCEPT: 'Sign up to accept your transfer',
    TRANSFER_ACCEPTED: 'Transfer accepted!',
    SPEND: 'Spend',
    MORE_TO_EARN: 'more to earn',
    IN: 'in',
    LOCATION_EXCLUSIVITY: 'Location Exclusivity',
    CC_EXCLUSIVITY: 'Preferred Cardholder',
    MEMBER_EXCLUSIVITY: 'Exclusive',
    NO_OPEN_ORDERS: 'You currently have no open orders.',
    NO_COMPLETED_ORDERS: 'You currently have no completed orders.',
    NO_VC_ACTIVITY: 'You currently have no virtual currency activity',
    CLAIM_NOW: 'Claim Now',
    PLEASE_ADD_A_PAYMENT_METHOD: 'Please add a payment method',
    ADD_CREDIT_CARD: 'Add a credit card to use Scan & Pay',
    ADD_A_CREDIT_CARD: 'Add a Credit Card',
    ADD_CARD: 'ADD CARD',
    ENHANCE_EXPERIENCE: 'Enhance your experience',
    MAYBE_LATER: 'MAYBE LATER',
    CAMERA_PERMISSION_REQUIRED: 'Enable camera permissions in phone settings',
    TAX: 'Tax',
    DELIVERY_FEE: 'Delivery Fee',
    ORDER_NO_LONGER_EXISTS: 'That order no longer exists.',
    FOR: 'for',
    YOU_NEED_CC: 'You need to add a credit card to use',
    YOU_NEED_LOGIN: 'Please login to apply eligible discounts and pay with',
    THE_QR_CODE: 'the QR Code',
    QR_CODE_PAY: 'QR Code Pay',
    SELF_CHECKOUT: 'Self Checkout',
    ITEM_NO_CARRY: 'That item is not recognized',
    TURN_ON_TO_PAY: 'Turn on to pay with ',
    WILL_BE_APPLIED: 'will be applied to this purchase',
    PLEASE_SHOW_THIS_SCREEN: 'Please show this screen at',
    TO_CONFIRM_YOUR_ORDER: 'to confirm your order.',
    SELECT_A_SHOP: 'Select a Shop',
    UNABLE_CAMERA: 'Unable to initiate the camera',
    MIN_WAIT: 'min wait',
    MINUTE_WAIT: 'minute wait',
    MIN: 'min',
    AFFILIATIONS_UPDATED: 'Affiliations Updated!',
    AWARDED_ON: 'Awarded on',
    NAV_MENU: 'Navigation Menu',
    PROFILE: 'Profile',
    SAVE_CARD: 'Save Card',
    SCROLL_DOWN_PAYMENT: 'Scroll down to enter payment details ',
    COMPLETE_YOUR_ORDER: 'and complete your order',
    SCAN_THIS_BARCODE: 'Scan this barcode to redeem.',
    SCAN_THIS_QRCODE: 'Scan this QR code to redeem.',
    COMPLETE_PAYMENT_BELOW: 'Complete your payment below ',
    AND_THEN_PICKUP: 'and then pickup your order at',
    EVENT_SELECTION: 'Event Selection',
    EVENTS: 'Events',
    MONTH_1: 'January',
    MONTH_2: 'February',
    MONTH_3: 'March',
    MONTH_4: 'April',
    MONTH_5: 'May',
    MONTH_6: 'June',
    MONTH_7: 'July',
    MONTH_8: 'August',
    MONTH_9: 'September',
    MONTH_10: 'October',
    MONTH_11: 'November',
    MONTH_12: 'December',
    FILTER: 'Filter',
    RESET: 'Reset',
    NO_WAIT_TIMES: 'No Wait Times',
    SERVICE_TYPES: 'Service Types',
    LOCATION: 'Location',
    CATEGORIES: 'Categories',
    DELIVERY_LOCATION: 'Delivery Location',
    CURRENTLY_NOT: 'Currently not accepting orders.',
    PLEASE_CHECK: 'Please check back later.',
    PLEASE_SCAN_AN_ITEM_IN: 'Please scan an item in ',
    SELECT_A_PICKUP_TIME: 'Select a pickup time',
    PLEASE_SELECT_A_PICKUP_TIME: 'Please select a pickup time',
    EXCHANGE_SERVICES_TRANSFER_DISCLAIMER: 'Ticket funds may only be loaded to one account. Do not load tickets that will be transferred',
    EXCHANGE_SERVICES_FUTURE_EVENTS_DISCLAIMER: 'Loaded ticket funds for future events will deposit on the date of the event',
    EXCHANGE_SERVICES_EXPERATION_WARNING: 'Any unused loaded ticket funds will expire after the event date',
    LOAD_GIFT_CARD: 'Load Gift Card',
    GIFT_CARD_NO_FUNDS: 'No funds available.',
    GIFT_CARD_TRANSFER_COMPLETE: 'Load Gift Card successful.',
    DISCLAIMER: 'Disclaimer',
    GIFT_CARD_BALANCE: 'Gift Card Balance',
    PIN: 'Pin',
    GIFT_CARD_NUMBER: 'Gift Card Number',
    GIFT_CARD_DISCLAIMER_DEFAULT: 'All funds will be withdrawn.',
    SOMETHING_WENT_WRONG: 'Oops something went wrong',
    RELOAD: 'RELOAD',
    FBMENUS: 'F&B Menus',
    SHOP_MERCH: 'Shop Merchandise',
    MARKETPLACE: 'Marketplace',
    MARKET_ITEMS: 'Market Items',
    MERCHANDISE_ITEMS: 'Merchandise Items',
    WALLET: 'Wallet',
    MY_ORDERS: 'My Orders',
    MY_PAYMENTS: 'My Payments',
    QR_PAY: 'QR Pay',
    BALANCE_HISTORY: 'Balance History',
    ITEMS_ARE_CURRENTLY_NOT_AVAILABLE: 'Items are currently not available',
    SEND_VIRTUAL_CURRENCY: 'Send Virtual Currency',
    SEARCH: 'Search',
    CANCEL: 'Cancel',
    TRY_AGAIN: 'TRY AGAIN',
    GO_BACK: 'GO BACK',
    QR_CODE_UNAVAILABLE: 'QR Code Unavailable',
    QR_CODE_LOAD_AGAIN: 'Your QR Code could not be loaded. Please try again.',
    UNKNOWN_ERROR: 'Unknown error',
    OOPS_NOT_RECOGNIZED: 'Oops, this item was not recognized',
    PURCHASE: 'Purchase',
    CREDIT: 'Credit',
    DEBIT: 'Debit',
    CANCEL_BEFORE: 'Cancel before',
    PREORDER: 'Preorder',
    CARD_ON_FILE_MAY_BE_REQUIRED: 'A card on file may be required at some locations',
    UPDATE_PAYMENT: 'UPDATE PAYMENT',
    AFFILIATIONS_NOT_REFRESHABLE: 'Sorry, profile information could not be updated.  Please try again in',
    AFFILIATIONS_PROFILE_NOT_REFRESHABLE: 'There was an error retrieving profile information.  Please try again in',
    MINUTES: 'minutes',
    PICK_UP_YOUR_ORDER_AT: 'Pick up your order at',
    REFRESH_BADGE_INFORMATION: 'Refresh Badge Information',
    LAST_UPDATED: 'Last updated',
    APPLYING_DISCOUNTS: 'Applying Eligible Discounts',
    SAVED_PAYMENTS: 'Saved Payments',
    SAVED_CARD: 'Saved Card',
    PAY_NOW: 'Pay Now',
    S4PAYMENTS_TRANSACTION_ERROR: 'Payment could not be completed, try again or try another card.',
    SINGLE_USE_QR_CODE: 'This single use QR code',
    LAST_GENERATED: 'Last generated',
    QR_CODE_UPDATED: 'QR code updated',
    FAILED_TO_UPDATE_QR_CODE: 'Sorry there was an issue updating the QR code. Please try again later',
    ACCOUNT_CANNOT_BE_EMPTY: 'Account cannot be empty',
    TICKETS_NOT_LOADED: "Some tickets could not be loaded. Please try again",
    SELECT_ONE_TICKET: "One ticket must be selected.",
    YOUR_NEXT_EVENT: "Your Next Event",
    LOADED: "LOADED AT",
    TODAY_AT: "Today At",
    SEARCH_TICKET_NUMBER: "Search by Ticket Number",
    ADMIN_USE: "For Admin Use Only",
    THERE_WAS_AN_ISSUE_SIGNING_IN: "There was an issue signing in. Please try again.",
    AUTHORIZATION_DECLINED: 'Authorization Declined',
    CARD_NOT_PRE_AUTHORIZED: 'Your card on file could not be pre-authorized.',
    MANAGE_CARDS: 'MANAGE CARDS',
    VIA: 'via'
  }
}

export default IEData
