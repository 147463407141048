import React from 'react'
import {SvgIcon} from '@material-ui/core'
import { ReactComponent as BarcodeSVG } from './barcode.svg'

const BarcodeIcon = (props) => (
  <SvgIcon {...props}>
    <BarcodeSVG />
  </SvgIcon>
);

BarcodeIcon.displayName = 'BarcodeIcon'
BarcodeIcon.muiName = 'SvgIcon'

export default BarcodeIcon
