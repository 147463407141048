import moment from 'moment-timezone'

/**
 * Check to see if the appropriate amount of time has elapsed
 * @param {moment} momentToCheck - The moment to use at your initial benchmark
 * @param {int} amount - the number of units that have elapsed
 * @param {String} granularity - The unit type you passed in, if none provided, we assume milliseconds
 */
export const hasTimeElapsed = (momentToCheck, amount, granularity) => {

  if (!momentToCheck) {
    return true
  }

  if (!granularity) {
    granularity = 'milliseconds'
  }

  const m = moment(momentToCheck)

  m.add(amount, granularity)

  const response = moment().isSameOrAfter(m)

  return response
}

/**
 * What is the difference between the time and the time left
 * @param {moment} momentToCheck - The moment to use at your initial benchmark
 * @param {int} amount - the number of units that have elapsed
 * @param {String} granularity - The unit type you passed in, if none provided, we assume milliseconds
 */
export const timeLeft = (momentToCheck, amount, granularity) => {

  if (!momentToCheck) {
    return 0
  }

  if (!granularity) {
    granularity = 'milliseconds'
  }

  const m = moment(momentToCheck)
  m.add(amount, granularity)

  const currentTime = new moment()
  const duration = moment.duration(m.diff(currentTime))

  switch(granularity) {
    case 'milliseconds':
      return duration.milliseconds()
    case 'seconds':
      return duration.seconds()
    case 'minutes':
      return duration.minutes()
    case 'hours':
      return duration.hours()
    case 'days':
      return duration.days()
    case 'weeks':
      return duration.weeks()
    case 'years':
      return duration.years()
    default:
      return duration.milliseconds()
  }
}
