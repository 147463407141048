import {  getByKey,
  getByKey_Exists,
  getConfiguration,
  getConfigKey,
  getByKey_Boolean,
  getByKey_Array } from './Utils'

import { ORG_ENV } from '../constants'

// ***** NOTE *****
// Everything in this selector retrieves the instance level configuration value first
// and then if there is nothing there, it'll go grab the ORG level configuration value.

/**
 * do you want to show the phone input withing ordering web or not.
 * This is used for all phone inputs
 * @param {Object} state - The state
 */
export const getShowPhoneInput = (state) => {
  const response = getByKey(state, 'phoneinput_display')

  // if the config doesn't have a explicit true/false statement, we are going to assume
  // we want to show the phone input
  if (response === undefined) {
    return true
  }

  return getByKey_Boolean(state, 'phoneinput_display')
}

/**
 * Get the polling rate in milliseconds that the order summary page should poll at to get updates
 * @param {Object} state - The state
 */
export const getOrderPollingRate = (state) => {
  const pollingRate = getByKey(state, 'orderpolling_rate')
  if (pollingRate) {
    return 1000 * parseInt(pollingRate)
  }
  return 30000 // default polling rate is 30 seconds
}

/**
 * Get the polling rate in milliseconds that the QR Code Pay page should poll at to get updates
 * @param {Object} state 
 * @returns A number of seconds of polling rate for refreshing nonce
 */
export const getNoncePollingRate = (state) => {
  const pollingRate = getByKey(state, 'walletrichcheckout_noncepollingrate')
  if (pollingRate) {
    return 1000 * parseInt(pollingRate)
  }
  return 5000 // default polling rate is 5 seconds
}

/**
* Get the wallet mode (for VNScanAndPay as of this writing)
* Values come down as | delimited string in which qrCode and qrScanner are values
* @param {object} state - The state
*/
export const getWalletMode = (state) => {
  return getByKey_Array(state, 'walletrichcheckout_payment_abilities')
}

/**
* Get the key we are looking for from the query parameters that will create a new user from the VNRedirectUser system
* @param {object} state - The state
*/
export const getRedirectUserUuid = (state) => {
  const redirectUserUUid = getByKey(state, 'redirect_user_uuid')
  if (redirectUserUUid) {
    return redirectUserUUid.replace(/-/g, "_")
  }

  return null
}

/**
* Get the key we are looking for from the query parameters that will create a new user from the VNRedirectUser system
* @param {object} state - The state
*/
export const getRedirectUserEmail = (state) => {
  const redirectUserEmail = getByKey(state, 'redirect_user_email')
  if (redirectUserEmail) {
    return redirectUserEmail.replace(/-/g, "_")
  }

  return null
}

/**
* Get the integration partner we are looking for from the query parameters that will create a new user from the VNRedirectUser system
* @param {object} state - The state
*/
export const getRedirectUserIntegration = (state) => {
  return getByKey(state, 'redirect_integration_partner')
}

/**
* Get the accent color that is used as the brand color
* @param {object} state - The state
*/
export const getAccentColor = (state) => {
  return getByKey(state, 'accentcolor_color')
}

/**
* Get all exchange services
* @param {object} state - The state
*/
export const getExchangeServices = (state) => {
  return getByKey_Array(state, 'exchange_services')
}

/**
* returns the time that the user is allowed to claim exchange service on account.
* @param {object} state - The state
*/
export const getExchangeServiceTimeOffset = (state, provider) => {
  return getByKey_Array(state, `exchange_${provider}_time_offset`)
}

/**
* returns a number of the group days to query.
* @param {object} state - The state
*/
export const getExchangeServiceGroupDaysToQuery = (state, provider) => {
  return getByKey_Array(state, `exchange_${provider}_groupsDaysToQuery`)
}

/**
* Get all echange service title
* @param {object} state - The state
* @param {object} provider - The provider used
*/
export const getExchangeServiceTitle = (state, provider) => {
  return getByKey(state, `exchange_${provider}_title`)
}

/**
* message to display when loading the balance fails
* @param {object} state - The state
* @param {object} provider - The provider used
*/
export const getExchangeServiceBalanceFailureText = (state, provider) => {
  return getByKey(state, `exchange_${provider}_load_failure`)
}

/**
* Get exchange service balance title i.e. Ticket Balance
* @param {object} state - The state
*/
export const getExchangeServiceBalanceTitle = (state, provider) => {
  return getByKey(state, `exchange_${provider}_balance_title`)
}

/**
* Get input text placeholder
* @param {object} state - The state
*/
export const getExchangeServiceInputFieldAccount = (state, provider) => {
  return getByKey(state, `exchange_${provider}_input_field_account`)
}

/**
* disclaimer message below account input
* @param {object} state - The state
*/
export const getExchangeServiceInputFieldAccountSubtext = (state, provider) => {
  return getByKey(state, `exchange_${provider}_input_field_account_subtext`)
}

/**
* input placeholder for passcode
* @param {object} state - The state
*/
export const getExchangeServiceInputFieldPasscode = (state, provider) => {
  return getByKey(state, `exchange_${provider}_input_field_passcode`)
}

/**
* the confirm button text on the confirm page
* @param {object} state - The state
*/
export const getExchangeServiceConfirmValueButtonText = (state, provider) => {
  return getByKey(state, `exchange_${provider}_confirm_value_button_text`)
}

/**
 * Given a provider, get the configuration for ticket number search button
 * @param {Object} state - The state
 * @param {String} provider - The name of the provider
 */
export const getExchangeServiceDisableTicketNumberSearch = (state, provider) => {
  return getByKey(state, `exchange_${provider}_disable_ticket_number_search`)
}

/**
* is virtual currency enabled
* @param {object} state - The state
*/
export const getVirtualCurrencyEnabled = (state) => {
  return getByKey_Exists(state, 'virtualcurrency_enabled')
}

/**
* Retrieves what purchase types virtual currency is enabled for
* @param {object} state - The state
*/
export const getVirtualCurrencyEnabledFor = (state) => {
  return getByKey_Array(state, 'virtualcurrency_enabled')
}

/**
* Retrieves what wallet options we want to disable and not show
* @param {object} state - The state
*/
export const getWalletDisableOptions = (state) => {
  return getByKey_Array(state, 'wallet_disableoptions')
}

/**
* Get the name of the virtual currency set for this organization
* @param {object} state - The state
*/
export const getVirtualCurrencyName = (state) => {
  return getByKey(state, 'virtualcurrency_name')
}

/**
* Get the stands order
* @param {object} state - The state
*/
export const getStandsOrder = (state) => {
  return getByKey_Array(state, 'stands_order')
}

/**
* Get what auth providers are available
* @param {object} state - The state
*/
export const getAuthProviders = (state) => {
  return getByKey_Array(state, 'authproviders_providers')
}

/**
* Get what auth providers are disabled for VNSDK
* @param {object} state - The state
*/
export const getAuthProvidersDisabledForSDK = (state) => {
  return getByKey_Array(state, 'vnsdkauth_disabled')
}

/**
* Get the issuer for ticketmaster
* @param {object} state - The state
* @param {*} provider - Currently only ticketmaster is an option
*/
export const getProviderIssuer = (state, provider) => {
  return getByKey(state, `${provider}_issuer`)
}

/**
* Get the client ID for ticketmaster
* @param {object} state - The state
* @param {*} provider - Currently only ticketmaster is an option
*/
export const getProviderClientId = (state, provider) => {
  return getByKey(state, `${provider}_clientId`)
}

/**
* Get the virual presets variable for ticketmaster
* @param {object} state - The state
* @param {*} provider - Currently only ticketmaster is an option
*/
export const getProviderVisualPresets = (state, provider) => {
  return getByKey(state, `${provider}_visualPresets`)
}

/**
* Get the redirect URI
* @param {object} state - The state
* @param {String} provider - Currently only ticketmaster is an option
*/
export const getProviderRedirectUri = (state, provider) => {
  return getByKey(state, `${provider}_redirectUri`)
}

/**
* Get what settings links are available as an array
* @param {object} state - The state
*/
export const getSettingsLinksAvailable = (state) => {
  return getByKey_Array(state, 'settinglinks_available')
}

/**
* Get the copyright
* @param {object} state - The state
*/
export const getCopyright = (state) => {
  return getByKey(state, 'copyright_copyright')
}

/**
* Checks to see if apple pay is enabled or not for this payments provider
* @param {object} state - The state
*/
export const getEnableApplePay = (state) => {
  return getByKey_Boolean(state, 'payments_applepay')
}

/**
 *
 * @param {*} state
 */
export const getAxsAxsClientId = (state) => {
  return getByKey(state, 'axs_axs_client_id')
}

/**
 *
 * @param {*} state
 */
export const getAxsClientId = (state) => {
  return getByKey(state, 'axs_client_id')
}

/**
 *
 * @param {*} state
 */
export const getAxsClientSecret = (state) => {
  return getByKey(state, 'axs_client_secret')
}

/**
 *
 * @param {*} state
 */
export const getAxsClientDisplayName = (state) => {
  return getByKey(state, 'axs_client_display_name')
}

/**
 *
 * @param {*} state
 */
export const getAxsPrivacyPolicy = (state) => {
  return getByKey(state, 'axs_privacy_policy')
}

/**
 *
 * @param {*} state
 */
export const getAxsTermsAndConditions = (state) => {
  return getByKey(state, 'axs_terms_and_conditions')
}

/**
 * Get the configured countdown time for a receipt
 * If nothing is set on VNConfig, use 90 as the default.
 * @param {*} state
 */
export const getReceiptTime = (state) => {
  const time = getByKey(state, 'receipttimer_time')
  if (time) {
    return parseInt(time)
  }
  return 90
}

/**
* Get the full configuration object for the instance
* @param {object} state - The state
*/
export const getInstanceLevelConfiguration = (state) => {
  const config = getConfiguration(state)
  const configKey = getConfigKey()

  if (config.has(configKey)) {
    return config.get(configKey)
  }

  return null
}

/**
* Get the full configuration object for the org
* @param {object} state - The state
*/
export const getOrgLevelConfiguration = (state) => {
  const config = getConfiguration(state)

  return config.get(`${ORG_ENV}_public_runtime`)
}

/**
 * This fetches all the virtual currency options that we want to hide on the wallet options screen.
 * @param {object} state - The state
 */
export const getVirtualCurrencyWalletHide = (state) => {
  return getByKey_Array(state, 'virtualcurrency_wallet_hide')
}

/**
 * Get who the payment provider is (braintree or shift4)
 * @param {*} state - The state
 * @returns {String} - braintree or shift4
 */
export const getPaymentsProvider = (state) => {
  return getByKey(state, 'payments_provider')
}

/**
* Get the primary and secondary badge colors and use these if the user has no affiliation that has colors
* First element in the array is the primary color, second element is the secondary color.
* @param {object} state - The state
* @returns {object} Returns an object with primary_color and secondary_color options.
*/
export const getBadgeColors = (state) => {
  const colors = getByKey_Array(state, 'badge_colors')
  return {
    primary_color: colors[0],
    secondary_color: colors[1]
  }
}

/**
 * Get customized Scan and Pay text for the wallet screen
 * @param {*} state - The state
 * @returns {String} - The customized Scan & Pay Text
 */
 export const getWalletTextScanAndPay = (state) => {
  return getByKey(state, 'wallettext_scan_and_pay')
}

/**
 * Get customized My Badge text for the wallet screen
 * @param {*} state - The state
 * @returns {String} - The customized My Badge Text
 */
 export const getWalletTextMyBadge = (state) => {
  return getByKey(state, 'wallettext_my_badge')
}

/**
 * Get customized My Badge text for the wallet screen
 * @param {*} state - The state
 * @returns {String} - The customized My Badge Text
 */
 export const getWalletTextGiftCard = (state) => {
  return getByKey(state, 'wallettext_giftcard')
}

/**
 * Get customized Gift Card Disclaimer text for the wallet screen
 * @param {*} state - The state
 * @returns {String} - The customized disclaimer
 */
export const getWalletTextGiftCardDisclaimer = (state) => {
  return getByKey(state, 'wallettext_giftcard_disclaimer')
}

/**
 * Get customized QR Code Pay text for the wallet screen
 * @param {Object} state - The state
 * @returns {String} - The customized QR Code Pay Text
 */
export const getWalletTextQRCodePay = (state) => {
  return getByKey(state, 'wallettext_qr_code_pay')
}

/**
* Get what product types are available for custom labels as an array
* @param {Object} state - The state
* @returns {Array} - An array of product types - [experiences, food_and_drinks, merchandise] - as of 12/14/21
*/
export const getProductTypeLabels = (state) => {
  return getByKey_Array(state, 'product_type_label')
}

/**
 * Get the S4Payments Region
 * @param {Object} state - The state
 * @returns {String} - The region to identify which DropIn to load. Region: EU or US
 */
export const getS4PaymentsRegion = (state) => {
  return getByKey(state, 's4payments_region')
}

/**
 * Get the S4Payments App Id
 * @param {Object} state - The state
 * @returns {String} - What is the S4Payments App ID
 */
 export const getS4PaymentsAppId = (state) => {
  return getByKey(state, 's4payments_app_id')
}

/**
* Get the time between we should refresh affiliations in minutes
* @param {object} state - The state
* @returns {Int} The time in minutes
*/
export const getRefreshAffiliationsTimer = (state) => {
  const timer = getByKey(state, 'affiliations_refreshtimer')

  if (timer) {
    return parseInt(timer)
  }

  // default time if none is set
  return 10
}

/**
 * Get SeatGeek team slug (also known as domain slug)
 * @param {Object} state - The state
 * @returns {String} SeatGeek team slug
 */
export const getSeatGeekTeamSlug = (state) => {
  return getByKey(state, 'seatgeek_team_slug')
}

/**
 * Get richcheckout_allow_promo_code configuration
 * @param {Object} state - The state
 */
export const getRichCheckoutAllowPromoCode = (state) => {
  return getByKey(state, 'richcheckout_allow_promo_code')
}

/**
 * The default stand UUID that has been setup with the Shift4 MID that you expect to be loaded to vault a credit card against.
 * @param {Object} state - The state
 * @returns {String} The stand UUID
 */
export const getVaultDefaultStand = (state) => {
  return getByKey(state, 'vault_default_stand')
}

/**
 * Check if get ticket affiliations by provider is enabled
 * @param {Object} state - The state
 * @param {String} provider - The provider
 * @returns {Boolean}
 */
export const getTicketAffiliationsByProviderEnabled = (state, provider) => {
  return getByKey(state, `${provider}_get_affiliations_from_tickets`) === 'true'
}

/**
 * The full name of the organization
 * @param {Object} state - The state
 * @returns {String} The organization's full name
 */
export const getSettingsFullOrgName = (state) => {
  return getByKey(state, 'settings_fullOrgName')
}