import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Route, Link } from 'react-router-dom'
import { get, keys, isEmpty, isEqual, head, values, split } from 'lodash'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { withTranslation } from 'react-i18next'
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import humps from  'humps'

import { createExperienceOrder, createOrder, clearOrderError } from '../actions/order'
import { clearOrderTotal, experienceOrderTotal, orderTotal, refreshOrderTotal } from '../actions/orderTotal'
import {
  clearCart, clearOrderNow,
  removeItemFromCart, updateGratuity,
  editQuantityItemInCart, removeExperienceItemFromCart,
  editExperienceQuantityItemInCart, editQuantityItemWithVariantsInCart
} from '../actions/cart'
import { setPromoCodes, resetLastPromoCode, fetchStandAvailability, clearStandAvailability } from '../VNOrders/ActionCreators'
import { setSnackbarSystemDataAlertInfo, setSnackbarSystemDataAlertError, resetSnackbarSystemData } from '../VNSnackbarSystem/ActionCreators'
import VNWebSDKAnalytics from '../VNWebSDK/reporting/VNWebSDKAnalytics'
import { clearOrderTotal as clearWalletOrderTotal, updateVirtualCurrencyPromotions, retrieveLoyaltyUser } from '../VNWallet/ActionCreators'
import { setAnonymousUserData } from '../VNUser/ActionCreators'
import { retrieveVaultedCardsByUser } from '../VNWebAccount/ActionCreators'
import { setLastOrderTotalUuidAndMenuUuid } from '../VNOrderTotal/ActionCreators'
import { resetLastOrderTotalUuidAndMenuUuid } from '../VNOrderTotal/ActionCreators'
import * as VNWebAccountActionTypes from '../VNWebAccount/ActionTypes'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'

import { getMenu } from '../VNMenu/Selectors'
import { getStandById, getPreorderSalesEvents } from '../VNRevenueCenters/Selectors'
import { getUserLocation } from '../selectors/user'
import { makeGetRemote } from '../selectors/remote'
import { getEvent } from '../VNMenu/Selectors'
import { getItemModifierRelationships } from '../selectors/cart'
import { getWebSDKMode, getWebSDKNavMode, getWebSDKBundleId } from '../VNWebSDK/Selectors'
import { getCartMenuItems, makeGetCartProperties, makeGetDeliveryFee } from '../selectors/cart'
import { getUser, getUseVirtualCurrencyFirst } from '../VNUser/Selectors'
import { getEventFilter } from '../VNMenu/Selectors'
import { getLoyaltyUser } from '../VNWallet/Selectors'
import { getLoadingSystemStatus } from '../VNApiLoadingSystem/Selectors'
import { getVaultedCards } from '../VNWebAccount/Selectors'
import { getSnackbarSystemData } from '../VNSnackbarSystem/Selectors'
import { getBackdropSystemEnabled } from '../VNBackdropSystem/Selectors'
import { getLatestOrderNumber } from '../selectors/orderTotal'

import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'
import Button from '../components/Button'
import ErrorMessage from '../components/ErrorMessage'
import Item from '../components/Item'
import OrderTotalSection from '../components/cart/OrderTotalSection'
import Section from '../components/Section'
import ServiceTypesText from '../components/ServiceTypes'
import PrivacyPolicy from '../components/PrivacyPolicy'
import OrderGratuitySection from '../components/cart/OrderGratuitySection'
import { VNSelectWithDialog } from '../VNComponents/VNSelectWithDialog'
import { VNPromoCodes } from '../VNOrders/components/VNPromoCodes'
import { getPromoCodes, getLastEnteredPromoCode, getPickupAvailability } from '../VNOrders/Selectors'
import { VNUserOrders } from '../VNWebAccount/containers/VNUserOrders'
import { VNTMCallback } from '../VNWebAccount/containers/VNTMCallback'
import { VNAXSLogin } from '../VNWebAccount/containers/VNAXSLogin'
import { VNRichCheckoutCameraOrder } from '../VNOrders/containers/VNRichCheckoutCameraOrder'
import { VNPaymentSystem } from '../components/cart/VNPaymentSystem'
import ScrollToTop from '../containers/ScrollToTop'
import { VNSeatPicker } from '../VNMenu/containers/VNSeatPicker'

import { orderMapper } from '../mappers/order'
import { experienceOrderMapper } from '../mappers/experiences/order'
import { getWebSDKDataReceive } from '../VNWebSDK/Selectors'
import { setWebSDKDataReceive } from '../VNWebSDK/ActionCreators'
import VNDataBridgeReceiveType from '../VNWebSDK/bridgeCalls/VNWebSDKDataReceive'

import withPaymentMappingHook from '../VNHooks/PaymentMapping/withPaymentMappingHook'
import VNDivider from '../VNComponents/VNDivider'
import VNCartListItem from '../components/cart/VNCartListItem'
import ItemModal from './ItemModal'
import VNSpinner from '../VNComponents/VNSpinner'

import Money from '../utils/money'
import Remote from '../remote'

import './Cart.scss'
import Title from '../utils/titleGenerator'
import { v4 as uuidv4, validate as isUuid } from 'uuid'

import { apiGetVCAccountBalance } from '../VNWallet/Api'
import { getVirtualCurrencyEnabled, getVirtualCurrencyEnabledFor, getRichCheckoutAllowPromoCode } from '../VNConfigurations/Selectors'
import { CartInputFields } from '../components/cart/CartInputFields'
import VNExpandableChip from '../VNComponents/VNExpandableChip'

const scrollIntoView = require('scroll-into-view')

export class Cart extends Component {

  constructor(props) {
    super(props)

    const disableAutoCheckout = props.richCheckoutAllowPromoCode?.toString() === 'true'
    const displaySpinner = (props.location?.state?.isRichCheckoutQR || props.location?.state?.isSelfCheckout) && !disableAutoCheckout

    this.state = {
      alertDialog: defaultAlertDialogState,
      pickupSlot: null,
      isNavigatingBack: false,
      orderNumber: "",
      loadingVCPromotions: props.isVirtualCurrencyEnabled && !props.isExperience && props.user.get('provider') !== 'vn_anonymous' && props.userJWT,
      displaySpinner: displaySpinner,
      paymentSystemIsReady: false,
      autoCheckoutOrderPlaced: false,
      rcSourceDeviceUuid: '',
    }

    this.userInputRef = React.createRef()
    this.paymentSystemRef = React.createRef()
    this.cartBottomRef = React.createRef()
  }

  async componentDidMount() {
    const {
      clearOrderError,
      clearOrderTotal,
      clearCart,
      clearStandAvailability,
      clearSnackbarSystem,
      isExperience,
      standId,
      webSDKMode,
      clearWalletOrderTotal,
      isRichCheckoutQR,
      orderTotal,
      history,
      setWebSDKDataReceive,
      userJWT,
      updateVirtualCurrencyPromotions,
      retrieveVaultedCardsByUser,
      retrieveLoyaltyUser,
      resetLastOrderTotalUuidAndMenuUuid,
    } = this.props
    const { loadingVCPromotions } = this.state

    retrieveVaultedCardsByUser()
    retrieveLoyaltyUser()

    if (!isRichCheckoutQR) {
      clearOrderTotal()
    } else {
      this.setState({
        orderNumber: orderTotal?.latest?.attributes?.orderNumber,
        rcSourceDeviceUuid: orderTotal?.latest?.attributes?.sourceDeviceUuid
      })
    }

    clearOrderError()
    clearWalletOrderTotal()
    clearStandAvailability()
    clearSnackbarSystem()

    // apiGetVCAccountBalance must happen before getOrderTotal
    if (loadingVCPromotions) {
      try {
        const response = await apiGetVCAccountBalance(userJWT)
        updateVirtualCurrencyPromotions(response.data.promotions ?? [])
      } catch (error) {
        console.log(error)
      }

      this.setState({ loadingVCPromotions: false })
    }

    if (!isEmpty(this.props.stand) || isExperience) {
      const { getExperienceOrderTotal, getOrderTotal, fetchStandAvailability } = this.props

      if (!isRichCheckoutQR) {
        isExperience ? getExperienceOrderTotal() : getOrderTotal()
        if (!isExperience) {
          fetchStandAvailability(standId)
        }
      }
    }

    if (webSDKMode) {
      VNWebSDKAnalytics.reportBeginCheckout()
    }

    this.backListener = history.listen((location, action) => {
      if (action === 'POP') {
        if (isRichCheckoutQR) {
          clearCart()
          clearOrderTotal()
          resetLastOrderTotalUuidAndMenuUuid()
          setWebSDKDataReceive({})
        }

        this.backListener()
      }
    })
  }

  componentWillUnmount() {
    this.props.clearOrderTotal()
    this.props.clearWalletOrderTotal()
    this.props.clearOrderError()
    this.props.clearPromoCodes()
    this.props.clearStandAvailability()
    this.props.updateVirtualCurrencyPromotions([])
    this.props.clearUserVaultedCards()
    this.props.clearSnackbarSystem()
    // if there is a successful order - clear last order total info
    // else - store last order total info
    if (this.props.successfulOrderId) {
      this.props.resetLastOrderTotalUuidAndMenuUuid()
    } else {
      this.props.setLastOrderTotalUuidAndMenuUuid(this.props.orderTotal?.latest?.attributes?.uuid, this.props.menuId)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isExperience,
      getExperienceOrderTotal,
      orderTotalPromotions,
      userLocation,
      stand,
      getOrderTotal,
      promoCodes,
      lastEnteredPromoCode,
      setSnackbarSystemDataAlertInfo,
      setSnackbarSystemDataAlertError,
      resetLastPromoCode,
      order,
      t,
      webSDKDataReceive,
      setWebSDKDataReceive,
      refreshOrderTotal,
      isRichCheckoutQR,
      itemQuantity,
      orderTotal,
      retrieveLoyaltyUserApiStatus,
      retrieveVaultedCardsApiStatus,
      vaultedCards,
      isFree,
      snackbarData,
      loading,
      experienceLoading,
      backdropEnabled,
      resetLastOrderTotalUuidAndMenuUuid,
    } = this.props

    const { displaySpinner, paymentSystemIsReady, loadingVCPromotions, autoCheckoutOrderPlaced, rcSourceDeviceUuid } = this.state

    document.title = t('CART')

    // check to see if we added or removed any promo codes, if we did, get a new order total
    if (prevProps.promoCodes.size !== promoCodes.size) {
      const promoCodesRemovedByUser = prevProps.promoCodes.filter(code => !promoCodes.includes(code)).toArray()
      isExperience ? getExperienceOrderTotal() : getOrderTotal(isRichCheckoutQR, { promoCodesRemovedByUser })
    }

    // check to see if the promotion total has changed
    // this is to determine if we when we want to show the snackbar message or not
    if (!isEqual(prevProps.orderTotalPromotions, orderTotalPromotions) &&
        orderTotalPromotions.length > 0 &&
        lastEnteredPromoCode.length > 0) {

      // this is used to determine if we are showing the remove a promo code or not
      let foundMatching = false

      // loop through all the promotions and look for a matching
      for (let i = 0; i < orderTotalPromotions.length; i++) {
        const promo = orderTotalPromotions[i]
        if (lastEnteredPromoCode.toUpperCase() === promo.promoCode) {
          foundMatching = true
          if (promo.applies) {
            setSnackbarSystemDataAlertInfo(t('PROMO_CODE_ADDED'))
          } else {
            setSnackbarSystemDataAlertError(promo.errorMessage)
          }
          break
        }
      }

      // we must have removed a promo code
      if (!foundMatching) {
        setSnackbarSystemDataAlertInfo(t('PROMO_CODE_REMOVED'))
      }

      // reset it so when we refresh the screen, the snackbar doesn't popup
      resetLastPromoCode()
    }

    if (!isEqual(prevProps.stand, stand) && !isRichCheckoutQR) {
      isExperience ? getExperienceOrderTotal() : getOrderTotal()
    }

    if (!isEqual(prevProps.userLocation, userLocation) && !isRichCheckoutQR) {
      getOrderTotal()
    }

    if (order.error) {
      if (displaySpinner) {
        // if there is an error, display checkout screen
        this.setState({ displaySpinner: false })
      }

      const err = order.error

      if (err?.friendlyMessage) {
        setSnackbarSystemDataAlertError(err.friendlyMessage)
        // Force delete error so that components re-render/mount. This is specifically
        // needed for VNPaymentSystem as to refresh the nonce.
        delete order.error
        this.forceUpdate()
        resetLastOrderTotalUuidAndMenuUuid()
        refreshOrderTotal({ source_device_uuid: rcSourceDeviceUuid })
      }
    }

    if (!webSDKDataReceive.isEmpty() && webSDKDataReceive.get('method') === VNDataBridgeReceiveType.IOS_I4GO_APPLE_PAY_RECEIPT_ID) {
      const receiptId = webSDKDataReceive.get('data').rid
      const errorMessage = webSDKDataReceive.get('data').emsg

      if (errorMessage) {
        setSnackbarSystemDataAlertError(errorMessage)
        setWebSDKDataReceive({})
        resetLastOrderTotalUuidAndMenuUuid()
        refreshOrderTotal({ source_device_uuid: rcSourceDeviceUuid })
      } else {
        this.props.clearCart()
        this.props.clearOrderTotal()
        this.props.clearWalletOrderTotal()
        this.props.clearOrderError()
        this.props.clearPromoCodes()
        this.props.clearStandAvailability()
        setWebSDKDataReceive({})
        let isOrderExperience = webSDKDataReceive.get('data').e
        if (isOrderExperience === 1 || isOrderExperience === '1') {
          this.props.history.replace(`/experience_orders/${receiptId}`, { isApplePayViaVNWebSDK: true })
        } else {
          this.props.history.replace(`/orders/${receiptId}`, { isApplePayViaVNWebSDK: true })
        }
      }
    }

    // This is primarily used when a user uses the X on a line item
    // and the items end up being 0 in the cart. When removing all items
    // from clear via clearCart, the alert confirm will take care of clearing the cart
    // otherwise this will cause an infinite loop.
    if (itemQuantity === 0 && prevProps.itemQuantity > 0 && !this.state.isNavigatingBack) {
      this.clearTotalAndGoBack(true)
    }

    // check if we can automatically checkout for Rich Checkout
    const tipSuggestions = orderTotal?.latest?.attributes?.tipSuggestions
    const vaultedCardsRetrieved = retrieveVaultedCardsApiStatus.status === ApiLoadingStatus.SUCCEEDED || retrieveVaultedCardsApiStatus.status === ApiLoadingStatus.FAILED
    const loyaltyUserRetrieved = retrieveLoyaltyUserApiStatus.status === ApiLoadingStatus.SUCCEEDED || retrieveLoyaltyUserApiStatus.status === ApiLoadingStatus.FAILED

    if (displaySpinner && !backdropEnabled && !loadingVCPromotions && !loading && !experienceLoading) {
      if (snackbarData.size || !isEmpty(orderTotal.error) || !isEmpty(order.error)) {
        // there is an error, display checkout screen
        this.setState({ displaySpinner: false })
      } else {
        if (Array.isArray(tipSuggestions) && vaultedCardsRetrieved && loyaltyUserRetrieved) {
          if (tipSuggestions.length) {
            // display checkout screen for gratuity input
            this.setState({ displaySpinner: false })
          } else {
            const coveredByVirtualCurrency = this.orderCoveredByVirtualCurrency()
            const canAutoCheckout = isFree || coveredByVirtualCurrency || vaultedCards?.size
    
            if (canAutoCheckout) {
              if (this.paymentSystemRef.current && paymentSystemIsReady && !autoCheckoutOrderPlaced) {
                this.setState({ autoCheckoutOrderPlaced: true })
                this.paymentSystemRef.current.orderNow()
              }
            } else {
              this.setState({ displaySpinner: false })
            }
          }
        }
      }
    }
  }

  /**
   * Called in VNPaymentSystem
   * @param {Boolean} isReady 
   */
  onPaymentSystemReady = (isReady) => {
    this.setState({ paymentSystemIsReady: isReady })
  }

  /**
   * Does the users VC balance cover the order balance
   * This also takes into account all the rules surrounding VC such as if its enabled for the item and if the user wants to spend it first from their settings.
   */
  orderCoveredByVirtualCurrency = () => {
    const {
      loyaltyUser,
      isVirtualCurrencyEnabled,
      virtualCurrencyEnabledFor,
      useVirtualCurrencyFirst,
      isExperience,
      productType
    } = this.props

    const orderTotalInCents = this.totalInCents()
    const actualProductType = isExperience ? 'experience' : productType
    const autoReplenish = loyaltyUser.get('auto_replenish')

    // if auto replenish is turned on, the order is always covered by virtual curency regardless of balance
    if (isVirtualCurrencyEnabled &&
        virtualCurrencyEnabledFor.includes(actualProductType.toLowerCase()) &&
        useVirtualCurrencyFirst &&
        autoReplenish) {
      return true
    }

    if (isVirtualCurrencyEnabled &&
      virtualCurrencyEnabledFor.includes(actualProductType.toLowerCase()) &&
      useVirtualCurrencyFirst &&
      loyaltyUser.get('balance') >= orderTotalInCents) {
        return true
    }

    return false
  }

  clearCartConfirm = () => {
    const alert = {
      onConfirm: this.clearCart,
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('REMOVE_ALL_ITEMS'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  showCustomTipDialog = () => {
    const { isRichCheckoutQR } = this.props

    const alert = {
      onConfirm: (inputValue) => {

        if (inputValue === "" || isNaN(inputValue)){
          return
        }

        this.props.updateGratuity(Money.dollarsToCents(inputValue), 'CUSTOM', isRichCheckoutQR)

        this.onAlertDialogDismiss()

      },
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('CUSTOM_TIP_AMOUNT'),
      show: true,
      input: {
        format: (val) => {
          return Money.floatToDollars(val)
        },
        validation: (val) => {
          return /^([\d]*)([.]?)([\d]{0,2})?$/.test(val)
        },
        onFocus: (val) => {
          if (val === '0.00') {
            return ''
          }
          return val
        },
        type: 'text',
        label: this.props.t('AMOUNT'),
        startAdornment: this.props.t('CURRENCY_SYMBOL'),
      },
    }

    this.setState({ alertDialog: alert })
  }

  clearCart = () => {
    this.props.clearCart()
    this.props.updateGratuity(0)
    this.clearTotalAndGoBack(true)
  }

  clearTotalAndGoBack = (isBackNav) => {
    const { isExperience, standId, menuId } = this.props

    if (this.props.location.state) {
      this.setState({ isNavigatingBack: true })
      let fromRoute = this.props.location.state.fromRoute
      // This fromRoute prop is used to signal that the previous
      // route was either Cart or VNUserOrders. Since we do not want to
      // retain in these flows, we must clear it
      //save loading time by directly use the namen instead of using import
      if (fromRoute === 'VNScanAndPay' || fromRoute === 'VNScanner') {
        this.props.clearCart()
      }

      // Coming from re-order from VNUserOrders, so go back to the parent
      // menu, happens when Add Items is pressed
      // Same with VNTMCallback and VNAXSLogin
      if ((fromRoute === VNUserOrders.name || fromRoute === VNTMCallback.name || fromRoute === VNAXSLogin.name) && !isBackNav) {
        if (!isExperience) {
          this.props.history.push(`/${standId}/menu/${menuId}`)
        }
      } else if (isBackNav) {
        this.props.history.goBack()
      }

      // If coming from VNRichCheckoutCameraOrder, there is no page in the back stack,
      // so just go to menu
      if (fromRoute === VNRichCheckoutCameraOrder.name) {
        this.props.clearCart()
        this.props.history.push(`/${standId}/menu/${menuId}`)
      }
    } else {
      this.props.history.push(`/${standId}/menu/${menuId}`)
    }

    this.props.clearOrderTotal()
    this.props.clearOrderNow()
    this.props.clearWalletOrderTotal()
  }

  itemDisplay = (item, quantity, modifiers, itemModifierIndices, itemModifierRelationshipKey, isMerchandise) => {
    const { standId, menuId, editQuantityItemInCart, editExperienceQuantityItemInCart, menu, isRichCheckoutQR, editQuantityItemWithVariantsInCart } = this.props

    // Items are only real cart items if they contain the specialType key.
    // specialType can be null, just needs to have the key itself
    if (item?.specialType === undefined || !item) {
      return
    }

    let price = item.defaultPriceInCents * quantity

    if (modifiers) {
      new ItemModifierTracker(item, modifiers).iterate((mod, priceIncrement) => {
        price += priceIncrement * quantity
      })
    }

    let variantModifiers = null
    if (item.isVariant) variantModifiers = [{ name: item.variantName }]

    return (
      <VNCartListItem
        key={itemModifierRelationshipKey}
        item={item}
        menu={menu}
        itemModifierIndices={itemModifierIndices}
        itemModifierRelationshipKey={itemModifierRelationshipKey}
        standId={standId}
        menuId={menuId}
        description={item.marketingDescription || item.description}
        modifiers={variantModifiers || modifiers}
        quantity={quantity}
        price={Money.centsToDollars(price, true, true)}
        isRichCheckoutQR={isRichCheckoutQR}
        isMerchandise={isMerchandise}
        onQuantityEdit={(newQuantity) => {
          if (modifiers) {
            editQuantityItemInCart(item.id, newQuantity, itemModifierIndices, isRichCheckoutQR)
          } else if (variantModifiers) {
            editQuantityItemWithVariantsInCart(item.uuid, item.id, newQuantity)
          } else {
            editExperienceQuantityItemInCart(item, newQuantity)
          }
        }}
        onRemove={(item, itemModifierIndices) => {
          this.removeItemConfirm(item, itemModifierIndices)
        }}
        quickpay={menu.serviceType === 'QuickPay'}
      />
    )
  }

  /**
   * Called from the success method of the VNPaymentSystem
   * @param {*} response - The response from Braintree
   * @param {*} userAttributes - Details about the user from the inputs
   * @param {*} paymentMapping - The final payment array to use for the order params.
   */
  onBraintreeSuccess = (braintTreeNonce, userAttributes, paymentMapping) => {
    const { isExperience } = this.props

    isExperience
      ? this.placeExperienceOrder(braintTreeNonce, paymentMapping, userAttributes)
      : this.placeOrder(braintTreeNonce, paymentMapping, userAttributes)
  }

  /**
   *
   * @param {*} braintTreeNonce
   * @param {*} userAttributes
   * @param {*} paymentMapping
   */
  onVNPaymentSystemSuccess = (braintTreeNonce, userAttributes, paymentMapping) => {
    const { isExperience } = this.props

    isExperience
      ? this.placeExperienceOrder(braintTreeNonce, paymentMapping, userAttributes)
      : this.placeOrder(braintTreeNonce, paymentMapping, userAttributes)
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  orderLocationHash = () => {
    const { userLocation } = this.props
    const { section, row, seat } = userLocation

    if (userLocation.confirmed) {
      return { section: section, row: row, seat: seat }
    }
  }

  onPickupAvailabilityHandler = (value) => {
    this.setState({ pickupSlot: value })
  }

  placeExperienceOrder = (braintTreeNonce, paymentMapping, userAttributes) => {
    const { createExperienceOrder, webSDKBundleId } = this.props

    let orderParams = { email: userAttributes.email, payments: paymentMapping, ...webSDKBundleId ? { bundleId: webSDKBundleId } : {}}

    if (braintTreeNonce) {
      orderParams.paymentMethodNonce = braintTreeNonce
    }

    createExperienceOrder(orderParams)
  }

  getExperienceOrderData = (userAttributes) => {
    const { items, paymentMapping, webSDKBundleId } = this.props

    let params = {
      email: userAttributes.email,
      payments: paymentMapping,
      ...webSDKBundleId ? { bundleId: webSDKBundleId } : {}
    }

    let orderParams = experienceOrderMapper({ items, ...params })

    // This method is primarily used to retrieve experience order data to send to
    // VNOTP, which in this case the nonce is explicitly not required
    delete orderParams.paymentMethodNonce

    return {
      orderParams: orderParams,
      orderTotalInCents: this.totalInCents()
    }
  }

  placeOrder = (braintTreeNonce, paymentMapping, userAttributes) => {
    const { createOrder, tipAmount, orderTotal, menu, setAnonymousUserData, webSDKBundleId, standId, isPreorder, preorderEventFromFilter, latestOrderNumber } = this.props

    const orderId = get(orderTotal, 'latest.attributes.uuid')

    let sourceDeviceUuid = orderTotal.latest.attributes.sourceDeviceUuid ?? null
    let orderNumber = this.state.orderNumber || orderTotal.latest.attributes.orderNumber || latestOrderNumber

    let orderParams = {
      uuid: orderId,
      email: userAttributes.email,
      name: userAttributes.name,
      firstName: userAttributes.first_name,
      lastName: userAttributes.last_name,
      tipAmountInCents: tipAmount,
      payments: paymentMapping,
      standUuid: standId,
      ...(menu.orderAhead ? {
        fulfillmentDate: moment().format('YYYY-MM-D'),
        fulfillmentSlot: this.state.pickupSlot
      } : {}),
      ...orderNumber ? { orderNumber: orderNumber } : {},
      ...sourceDeviceUuid ? { sourceDeviceUuid : sourceDeviceUuid } : {},
      ...webSDKBundleId ? { bundleId: webSDKBundleId } : {}
    }

    if (braintTreeNonce) {
      orderParams.paymentMethodNonce = braintTreeNonce
    }

    // only attach orderLocation and orderLocationUuid
    // if this is a delivery order
    if (menu.serviceType === 'Delivery') {
      const orderLocation = this.orderLocationHash()

      if (orderLocation) {
        orderParams.orderLocation = orderLocation
        orderParams.orderLocationUuid = this.props.userLocation.orderLocationUuid
      }
    }

    // Only attach sales events for preorders here
    if (isPreorder) {
      orderParams.preorderSaleEvent = preorderEventFromFilter
    }

    createOrder(orderParams)

    setAnonymousUserData({
      first: userAttributes.first_name,
      last: userAttributes.last_name,
      email: userAttributes.email
    })
  }

  getPlaceOrderData = (userAttributes) => {
    const { tipAmount, orderTotal, menu, items, menuId, paymentMapping, webSDKBundleId } = this.props

    const orderId = get(orderTotal, 'latest.attributes.uuid')

    let sourceDeviceUuid = orderTotal.latest.attributes.sourceDeviceUuid ?? null
    let orderNumber = orderTotal.latest.attributes.orderNumber ?? null

    let params = {
      uuid: orderId,
      email: userAttributes.email,
      name: userAttributes.name,
      firstName: userAttributes.first_name,
      lastName: userAttributes.last_name,
      orderLocation: this.orderLocationHash(),
      orderLocationUuid: this.props.userLocation.orderLocationUuid,
      payments: paymentMapping,
      tipAmountInCents: tipAmount,
      ...(menu.orderAhead ? {
        fulfillmentDate: moment().format('YYYY-MM-D'),
        fulfillmentSlot: this.state.pickupSlot
      } : {}),
      ...orderNumber ? { orderNumber: orderNumber } : {},
      ...sourceDeviceUuid ? { sourceDeviceUuid : sourceDeviceUuid } : {},
      ...webSDKBundleId ? { bundleId: webSDKBundleId } : {}
    }

    const orderParams = orderMapper({ items, menuId, ...params })

    return {
      orderParams: orderParams,
      orderTotalInCents: this.totalInCents()
    }
  }

  removeItem = (item, modifiersIndex) => {
    const { isExperience, isRichCheckoutQR } = this.props
    const itemId = item.isVariant ? item.variantParentId : item.id

    let selectedVariant = ""
    item?.variants?.forEach(variant => {
      if(item.id === variant.menuItemUuid) {
        selectedVariant = variant.productSku
      }
    });

    if (isExperience) {
      this.props.removeExperienceItemFromCart(item.uuid)
    } else {
      this.props.removeItemFromCart(itemId, modifiersIndex, selectedVariant, isRichCheckoutQR)
    }
    this.onAlertDialogDismiss()
  }

  removeItemConfirm = (item, modifiersIndex) => {
    const name = get(item, 'name')
    let removeText = item.quantity > 1 ? `${item.quantity} ${name}s` : `1 ${name}`
    if (modifiersIndex >= 0) removeText = `1 ${name}`

    const alert = {
      onConfirm: () => this.removeItem(item, modifiersIndex),
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('REMOVE'),
      textMain: `${this.props.t('THIS_WILL_REMOVE')} ${removeText} ${this.props.t('FROM_YOUR_CART')}.`,
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  serviceTypeMessage = () => {
    const { userLocation, isExperience, isRichCheckoutQR, menu } = this.props
    if (isExperience || isRichCheckoutQR || menu.serviceType !== 'Delivery') return

    if (userLocation.confirmed) {
      const route = {
        pathname: '/cart/delivery-location',
        state: { redirect: '/cart' },
      }

      return (
        <>
          <ServiceTypesText serviceType="delivery" view="cart" userLocation={userLocation}/>
          <Link to={route} replace className="change-seat">Change Seat</Link>
        </>
      )
    }
  }

  deliveryFee = () => get(this.props.deliveryFee, 'defaultPriceInCents', 0)
  serviceCharges = () => get(this.props.orderTotal, 'latest.attributes.serviceChargeInCents', 0)
  taxInCents = () => get(this.props.orderTotal, 'latest.attributes.taxAmountInCents', 0)
  tipSuggestions = () => get(this.props.orderTotal, 'latest.attributes.tipSuggestions', [])
  discountAmountInCents = () => get(this.props.orderTotal, 'latest.attributes.discountAmountInCents')

  totalInCents = () => {
    const { isExperience, orderTotal, tipAmount } = this.props
    const totalKey = `latest.${isExperience ? '' : 'attributes.'}totalAmountInCents`

    let total = get(orderTotal, totalKey, 0)

    if (tipAmount) {
      total += tipAmount
    }

    return total
  }

  flatTotal = (includeTax = false) => {
    const { orderTotal } = this.props
    const attrs = get(orderTotal, 'latest.attributes', null)
    let taxBasedTotal = 0

    taxBasedTotal = attrs ? attrs.totalAmountInCents - attrs.discountAmountInCents : 0
    taxBasedTotal = includeTax && attrs ? taxBasedTotal - attrs.taxAmountInCents : taxBasedTotal

    return taxBasedTotal
  }

  renderEventDetails = () => {
    const { event } = this.props
    let eventDate = this.props.t('EVENT_DATE')
    let eventDetails = ''

    if (!isEmpty(event)) {
      eventDate = moment(event.date).format('MMMM Do, YYYY')
      eventDetails = `${event.time}, ${event.name}`
    }

    return (
      <Section className="event-date">
        <span className="label">{this.props.t('EVENT')}</span>
        <div className={classNames('event-picker', { active: get(event, 'date', false ) })}>
          <span className="date">{eventDate}</span>
          <span className="event-details">{eventDetails}</span>
        </div>
      </Section>
    )
  }

  getSpecialTipSuggestion = (suggestions) => {
    const { isFree, tipAmount } = this.props
    const discount = this.discountAmountInCents()
    if (isFree && tipAmount >= 0 && (discount === null || discount >= 0)) {
      return suggestions.filter((item) => item.tipDisplay === 'No Tip' || item.tipDisplay === 'Other')
    }
    return suggestions
  }

  onPromoApply = () => {
    const {  setAnonymousUserData } = this.props
    const userData=this.userInputRef.current.validate()
    if (userData.isValidated){
      setAnonymousUserData (
        {
          first: userData.user.first_name,
          last: userData.user.last_name,
          email: userData.user.email
        }
      )
    }
  }

  onUserInput = () =>{
    if (this.userInputRef.current) {
      return this.userInputRef.current
    }
    return false
  }

  /**
   * Used to check if there is any error in VNPaymentSystem when order button was clicked
   * It is called before the order actually been placed
   * @returns {string} - Error message
   */
  hasError = () => {
    const { t, menu, pickupAvailability } = this.props

    if (menu.orderAhead && !isEmpty(pickupAvailability) && this.state.pickupSlot === null) {
      return t('PLEASE_SELECT_A_PICKUP_TIME')
    }
  }

  scrollToBottom = () => {
    if (!this.cartBottomRef.current) {
      return
    }

    scrollIntoView(this.cartBottomRef.current, {
      align: {
        bottom: 0,
        left: 0,
      }
    })
  }

  render() {
    const {
      isExperience,
      isFree,
      menuId,
      stand,
      standId,
      successfulOrderId,
      productType,
      pickupAvailability,
      itemModifierRelationships,
      t,
      paymentMapping,
      preorderEventFromFilter,
      tipButtonId
    } = this.props
    const { loadingVCPromotions, displaySpinner } = this.state

    const locationState = this.props.location?.state

    const isRichCheckoutQR = locationState?.isRichCheckoutQR
    const isRichOrSelfCheckout = isRichCheckoutQR || locationState?.isSelfCheckout

    if (successfulOrderId) {
      const path = isExperience ? 'experience_orders' : 'orders'
      const urlToVNReceipt = `/${path}/${successfulOrderId}`
      const redirectState = {}
      if (typeof tipButtonId === 'number' && tipButtonId > -1) {
        redirectState.fromCart = true
        redirectState.tipButtonId = tipButtonId
      }
      return (
        <Redirect to={{ pathname: urlToVNReceipt, state: redirectState }} />
      )
    }

    const { alertDialog } = this.state
    const { deliveryFee, items, itemQuantity, order, orderTotal, loading, experienceLoading, menu, orderTotalPromotions } = this.props
    const itemQuantityLabel = `${itemQuantity} ${itemQuantity > 1 ? t('ITEMS') : t('ITEM')}`

    const calculateSubtotalFromLineItems = () => {
      let subtotalInCents = 0

      const items = orderTotal?.latestLineItem ?? []

      for (const item of items) {
        if (!item.attributes.specialType) {
          subtotalInCents += item.attributes.totalAmountInCents

          if (item.attributes.taxAmountInCents) {
            subtotalInCents -= item.attributes.taxAmountInCents
          }

          // item.attributes.discountAmountInCents will be type of string
          const discountAmountInCents = parseInt(item.attributes.discountAmountInCents)
          if (!isNaN(discountAmountInCents) && discountAmountInCents) {
            // discountAmountInCents will be a negative number
            subtotalInCents -= discountAmountInCents
          }
        }
      }

      if (!subtotalInCents && orderTotal?.latest?.totalAmountInCents) {
        // if it is an experience order, orderTotal.latestLineItem will be an empty array
        // we need to use orderTotal.latest.totalAmountInCents as subtotal
        subtotalInCents = orderTotal.latest.totalAmountInCents
      }

      return subtotalInCents
    }

    const displayItems = () => {
      if (loading && isEmpty(items)) {
        return (
          <>
            <Item type="cart" loading />
            <Item type="cart" loading />
            <Item type="cart" loading />
          </>
        )
      }

      const itemsToDisplay = keys(itemModifierRelationships).map((relationship) => {
        const splitArray = split(relationship, ".")
        const itemId = splitArray[0]
        // If splitArray is length of 3, then the item in question is an Experience
        const isExperience = splitArray.length === 3
        const isMerchandise = isUuid(splitArray[1])

        const itemModifierIndices = itemModifierRelationships[relationship]

        const item = isExperience ? items[splitArray[2]] : isMerchandise ? items[splitArray[1]] : items[itemId]

        if (itemId === get(deliveryFee, "id") || !item) {
          return null
        }

        const quantity = isExperience
          ? itemModifierRelationships[relationship]
          : itemModifierIndices.length

        const modifiers = item.modifiers[itemModifierIndices[0]]

        return this.itemDisplay(item, quantity, modifiers, itemModifierIndices, relationship, isMerchandise)
      }).filter(item => item)

      if (!itemsToDisplay.length) {
        return null
      }

      return (
        <Accordion
          id='cart-items-accordion'
          defaultExpanded={!isRichOrSelfCheckout}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='cart-items-content'
            id='cart-items-header'
          >
            <Typography className='cart-items-header-text'>{itemsToDisplay.length} items</Typography>
          </AccordionSummary>
          <AccordionDetails id='cart-items-details' >
            {itemsToDisplay}
          </AccordionDetails>
        </Accordion>
      )
    }

    const displayActionsSection = () => {
      if (isRichOrSelfCheckout) {
        return null
      }

      return (
        <Section className="actions">
          <Button
            onClick={this.clearCartConfirm}
            className={isExperience ? "non-additem-experience": null}
          >
            {t("CLEAR_CART")}
          </Button>
          {!isExperience && (
            <Button onClick={() => this.clearTotalAndGoBack(false)}>
              {t("ADD_ITEMS")}
            </Button>
          )}
        </Section>
      )
    }

    const displayServiceTypeMessageSection = () => {
      const serviceTypeMessage = this.serviceTypeMessage()

      if (serviceTypeMessage) {
        return (
          <Section className="service-type-text">
            {serviceTypeMessage}
          </Section>
        )
      }
    }

    const displayOrderGratuity = () => {
      if (isExperience) return null

      let suggestions = this.tipSuggestions()
      if (suggestions.length > 0) {
        return (
          <OrderGratuitySection
            suggestions={this.getSpecialTipSuggestion(suggestions)}
            tipAmount={this.props.tipAmount}
            tipButtonId={this.props.tipButtonId}
            updateGratuity={this.props.updateGratuity}
            showCustomTipDialog={this.showCustomTipDialog}
            isRichCheckoutQR={isRichCheckoutQR}
          />
        )
      }
    }

    const displayPromoCodes = () => {
      if (!order.error && !isFree && !isExperience) {
        return (
          <Box pr={3} pl={3}>
            <VNPromoCodes onApply={this.onPromoApply}/>
          </Box>
        )
      }
    }

    const displayVNPaymentSystem = () => {
      if (!order.error && !loading && !experienceLoading && !loadingVCPromotions) {
        const orderId = isExperience ? uuidv4() : get(orderTotal, 'latest.attributes.uuid')
        return (
          <VNPaymentSystem
            ref={this.paymentSystemRef}
            productType={isExperience ? 'experience' : productType}
            isFree={isFree}
            isRichCheckoutQR={isRichCheckoutQR}
            isSelfCheckout={locationState?.isSelfCheckout}
            isQuickPay={menu.serviceType === 'QuickPay'}
            onSuccess={this.onVNPaymentSystemSuccess}
            subtotalInCents={this.flatTotal()}
            discountInCents={this.discountAmountInCents()}
            serviceChargeInCents={this.serviceCharges()}
            taxInCents={this.taxInCents()}
            tipInCents={this.props.tipAmount}
            orderTotalInCents={this.totalInCents()}
            orderId={orderId}
            standName={stand.name}
            items={items}
            standId={standId}
            applePayCallback={(user) => {
              const { isExperience } = this.props

              if (isExperience) {
                return this.getExperienceOrderData(user)
              }

              return this.getPlaceOrderData(user)
            }}
            paymentsInCart={paymentMapping}
            onUserInput={this.onUserInput}
            hasError={this.hasError}
            paymentSystemIsReady={this.state.paymentSystemIsReady}
            onPaymentSystemReady={this.onPaymentSystemReady}
            orderCoveredByVirtualCurrency={this.orderCoveredByVirtualCurrency()}
            scrollToBottom={this.scrollToBottom}
          />
        )
      }
    }

    const displayPickupAvailability = () => {
      const { isRichCheckoutQR, isPreorder, preorderEventFromFilter  } = this.props

      const displayPreorderChip = () => {
        if (isPreorder && preorderEventFromFilter) {
          return (
            <Box mb={2} style={{ display: 'flex' }}>
              <VNExpandableChip
                text={'Pre-Order'}
                marginLeft={'0'}
              />
            </Box>
          )
        }
      }

      // there is a chance that orderAhead is turned on but isn't returning anything
      const displaySelectWithDialog = () => {
        if (pickupAvailability.length > 0 && get(menu, 'orderAhead')) {
          return (
            <VNSelectWithDialog
              placeholder={t('SELECT_A_PICKUP_TIME')}
              options={pickupAvailability}
              onSelection={this.onPickupAvailabilityHandler}
              optionPrepend={t('PICKUP_AT')}
            />
          )
        }
      }

      const displayScrollDownComplete = () => {
        if (!isRichCheckoutQR) {
          return (
            <Typography className={classNames('complete-pickup')} variant="subtitle1">{`${t('SCROLL_DOWN_PAYMENT')}\n\n${t('COMPLETE_YOUR_ORDER')}`}</Typography>
          )
        }
      }

      const displayMenuName = () => {
        if (isRichCheckoutQR) {
          return (<Typography variant="h1">{`${t('SCROLL_DOWN_PAYMENT')}\n\n${t('COMPLETE_YOUR_ORDER')}`}</Typography>)
        }

        return (<Typography variant="h1">{get(menu, 'name')}</Typography>)
      }

      const displayEventInfo = () => {
        let startDateTime = moment(preorderEventFromFilter?.start_time)

        if (isPreorder && preorderEventFromFilter) {
          return (
            <Box mt={1}>
              {`${startDateTime.format('LL')} - ${preorderEventFromFilter?.name} ${startDateTime.format('LT')}`}
            </Box>
          )
        }
      }

      return (
        <Fragment>
          {displayScrollDownComplete()}
          {displayPreorderChip()}
          {displayMenuName()}
          {displaySelectWithDialog()}
          {displayEventInfo()}
          <VNDivider />
        </Fragment>
      )
    }

    const displayErrorMessage = () => {
      const { order } = this.props
      const err = order.error

      if (err) {
        // If there is a friendly_message, we want to utilize the snackbar
        // system instead of showing the old ErrorMessage
        if (err.friendlyMessage) {
          return
        }

        return (
          <Section>
            <ErrorMessage
              type="createOrder"
              error={order.error.category}
              onDismissed={() => {
                delete order.error
                this.forceUpdate()
              }}
            />
          </Section>
        )
      }
    }

    const displaySpinnerOverlay = () => {
      if (displaySpinner) {
        return (
          <Box className='cart-spinner-box'>
            <Box className='cart-spinner-text-box'>
              <Typography className='cart-spinner-text'>{t('APPLYING_DISCOUNTS')}</Typography>
              <Typography className='cart-spinner-text'>& {t('SAVED_PAYMENTS')}</Typography>
            </Box>
            <VNSpinner />
          </Box>
        )
      }
    }

    // Makes sure a seat is selected if you are on the cart page and delivery option is chosen.
    const seatSelected = (serviceType) => {
      if (serviceType !== 'Delivery') return null

      const orderLocation = this.orderLocationHash()

      if (orderLocation?.section) return null

      return <Redirect to={{ pathname: '/cart/delivery-location', removeCloseButton: true }} />
    }

    return <>
      {displaySpinnerOverlay()}
      {seatSelected(menu.serviceType)}
      <Box className={classNames("cart", { loading })}>
        <ScrollToTop />
        <Helmet>
          <title>{Title.generate(t("CART"))}</title>
        </Helmet>
        <Section className="pickup-area">
          {displayPickupAvailability()}
        </Section>
        <Section className="items">
          {displayItems()}
          {orderTotalPromotions.map((value, index) => {
            if (!value.applies) return null;
            return (
              <Item
                type="cart"
                loading={loading}
                key={index}
                name={value.name}
                description={value.description}
                quantity={1}
                price={Money.centsToDollars(
                  value.discountAmountInCents,
                  true,
                  true
                )}
              />
            );
          })}
        </Section>

        {displayOrderGratuity()}

        {/* <Section name="people also bought" className="item-recommendation">
          <Item type="recommended" item={recommendedItem} />
        </Section> */}

        <OrderTotalSection
          deliveryFee={this.deliveryFee()}
          error={orderTotal.error}
          finalTotal={this.totalInCents()}
          subtotal={calculateSubtotalFromLineItems()}
          itemQuantityLabel={t('SUBTOTAL')}
          loading={loadingVCPromotions || loading}
          serviceCharges={this.serviceCharges()}
          tax={this.taxInCents()}
          tip={this.props.tipAmount}
          isExperience={isExperience}
          showTip={this.tipSuggestions().length > 0 ? true : false}
          promotions={orderTotalPromotions}
          stand={stand}
          orderTotal={orderTotal}
          preorderEvent={preorderEventFromFilter}
          t={t}
        />

        {displayActionsSection()}

        {displayServiceTypeMessageSection()}

        {isExperience && this.renderEventDetails()}
        {displayPromoCodes()}
        <CartInputFields
          ref={this.userInputRef}
          isExperience={productType === t('EXPERIENCE') ? true : false}
          isRichCheckoutQR={isRichCheckoutQR}
          isSelfCheckout={locationState?.isSelfCheckout}
          isQuickPay={menu.serviceType === 'QuickPay'}
        />
        {displayVNPaymentSystem()}
        {displayErrorMessage()}
        {alertDialog.show && <AlertDialog {...this.state.alertDialog} />}
        <Route
          path="/cart/delivery-location"
          render={(routeProps) => (
            <VNSeatPicker
              {...routeProps}
              standId={standId}
              menuId={menuId}
              onCloseModal={() => this.props.history.replace("/cart")}
            />
          )}
        />
        <Route
          path={`/cart/:standId/menu/:menuId/item/:itemId`}
          render={(routeProps) => (
            <ItemModal
              {...routeProps}
              onRemove={(item) => this.removeItem(item, -1)}
            />
          )}
        />
        <PrivacyPolicy />
        <div ref={this.cartBottomRef} />
      </Box>
    </>
  }
}

function mapStateToProps(state, ownProps) {
  const menuId = get(state, 'cart.menuId', '')
  const standId = get(state, 'cart.standId', '')
  const uuid = get(state, 'cart.uuid', '')

  const pickupAvailability = getPickupAvailability(state, standId, menuId)

  const getRemote = makeGetRemote()
  const getCartProperties = makeGetCartProperties()
  const getDeliveryFee = makeGetDeliveryFee()

  const menu = humps.camelizeKeys(get(getMenu(state, menuId), 'menu', {}))
  const remote = getRemote(state, Remote.endpoints.orderTotal)
  const experienceRemote = getRemote(state, Remote.endpoints.experienceOrderTotal)
  const stand = humps.camelizeKeys(getStandById(state, standId))

  const cartItems = getCartMenuItems(state, menuId)
  const itemModifierRelationships = getItemModifierRelationships(state)
  const event = getEvent(state, menuId, get(head(values(state.cart.item)), 'variant.eventUuid', ''))
  const cartProperties = getCartProperties(state)
  const deliveryFee = getDeliveryFee(state)
  const successfulOrder = get(state, 'order.latest', {})
  const userLocation = getUserLocation(state)
  const latestOrderNumber = getLatestOrderNumber(state)

  // It's possible to get here without having standId updated in cart.
  // If so, let's check the first cart item instead
  const isExperience = (
    get(stand, 'productType') === 'Experience' ||
    ( head(values(cartItems)) || {} )['isExperience']
  )
  const successfulOrderId = isExperience ? successfulOrder.id : keys(successfulOrder)[0]
  const promotions = get(state, 'orderTotal.latest.attributes.promotions', [])

  const webSDKMode = getWebSDKMode(state)
  const webSDKNavMode = getWebSDKNavMode(state)
  const webSDKDataReceive = getWebSDKDataReceive(state)
  const webSDKBundleId = getWebSDKBundleId(state)

  const user = getUser(state)
  const userJWT = user.get('jwt')
  const loyaltyUser = getLoyaltyUser(state)
  const retrieveLoyaltyUserApiStatus = getLoadingSystemStatus(state, retrieveLoyaltyUser.name)
  const retrieveVaultedCardsApiStatus = getLoadingSystemStatus(state, retrieveVaultedCardsByUser.name)
  const vaultedCards = getVaultedCards(state)
  const useVirtualCurrencyFirst = getUseVirtualCurrencyFirst(state)

  const totalInCents = () => {
    const totalKey = `latest.${isExperience ? '' : 'attributes.'}totalAmountInCents`

    let total = get(humps.camelizeKeys(state.orderTotal), totalKey, 0)

    if (state.cart.tipAmount) {
      total += state.cart.tipAmount
    }

    return total
  }

  const isFree = isExperience
    ? get(state, 'orderTotal.latest.totalAmountInCents') === 0
    : get(state, 'orderTotal.latest.attributes.totalAmountInCents') === 0 && totalInCents() <= 0

  const isRichCheckoutQR = ownProps.location?.state?.isRichCheckoutQR
  const isVirtualCurrencyEnabled = getVirtualCurrencyEnabled(state)
  const virtualCurrencyEnabledFor = getVirtualCurrencyEnabledFor(state)
  const richCheckoutAllowPromoCode = getRichCheckoutAllowPromoCode(state)
  const isPreorder = menu?.preorder
  const eventFromFilter = getEventFilter(state)
  const preorderEvents = getPreorderSalesEvents(state)
  const preorderEventFromFilter = get(preorderEvents, eventFromFilter, undefined)

  const snackbarData = getSnackbarSystemData(state)
  const backdropEnabled = getBackdropSystemEnabled(state)

  return {
    deliveryFee,
    event,
    failed: remote.failed,
    isExperience,
    isFree,
    itemQuantity: cartProperties.quantity,
    items: cartItems,
    itemModifierRelationships,
    loading: remote.loading,
    experienceLoading: experienceRemote.loading,
    menu,
    menuId: menuId,
    order: state.order,
    orderTotal: humps.camelizeKeys(state.orderTotal),
    recommendedItem: null,
    stand,
    standId,
    successfulOrderId: successfulOrderId,
    userLocation: userLocation,
    tipAmount: state.cart.tipAmount,
    tipButtonId: state.cart.tipButtonId,
    uuid: uuid,
    productType: get(stand, 'productType'),
    promoCodes: getPromoCodes(state),
    lastEnteredPromoCode: getLastEnteredPromoCode(state),
    orderTotalPromotions: promotions,
    pickupAvailability: pickupAvailability,
    webSDKMode,
    webSDKNavMode,
    webSDKDataReceive,
    webSDKBundleId,
    user,
    userJWT: userJWT,
    loyaltyUser,
    retrieveLoyaltyUserApiStatus,
    retrieveVaultedCardsApiStatus,
    vaultedCards,
    useVirtualCurrencyFirst,
    totalInCents: totalInCents(),
    isVirtualCurrencyEnabled,
    virtualCurrencyEnabledFor,
    isRichCheckoutQR,
    isPreorder,
    preorderEventFromFilter,
    snackbarData,
    backdropEnabled,
    latestOrderNumber,
    richCheckoutAllowPromoCode,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    clearCart: () => dispatch(clearCart()),
    clearOrderNow: () => dispatch(clearOrderNow()),
    clearOrderError: () => dispatch(clearOrderError()),
    clearOrderTotal: () => dispatch(clearOrderTotal()),
    createOrder: (params) => dispatch(createOrder(params)),
    createExperienceOrder: (params) => dispatch(createExperienceOrder(params)),
    getOrderTotal: (isRichCheckout, payload) => dispatch(orderTotal(isRichCheckout, payload)),
    getExperienceOrderTotal: () => dispatch(experienceOrderTotal()),
    removeItemFromCart: (itemId, modifiersIndex, variantId, isRichCheckoutQR) => {
      dispatch(removeItemFromCart(itemId, modifiersIndex, variantId, isRichCheckoutQR))
    },
    removeExperienceItemFromCart: (itemId) => {
      dispatch(removeExperienceItemFromCart(itemId))
    },
    editQuantityItemInCart: (itemId, quantity, modifierIndices, isRichCheckoutQR) => {
      dispatch(editQuantityItemInCart(itemId, quantity, modifierIndices, null, isRichCheckoutQR))
    },
    editQuantityItemWithVariantsInCart: (itemId, variantId, quantity) => {
      dispatch(editQuantityItemWithVariantsInCart(itemId, variantId, quantity))
    },
    editExperienceQuantityItemInCart: (itemId, quantity) => {
      dispatch(editExperienceQuantityItemInCart(itemId, quantity))
    },
    updateGratuity: (tipAmount, tipButtonId, isRichCheckoutQR) => dispatch(updateGratuity(tipAmount, tipButtonId, isRichCheckoutQR)),
    clearPromoCodes: () => dispatch(setPromoCodes([])),
    resetLastPromoCode: () => dispatch(resetLastPromoCode()),
    setSnackbarSystemDataAlertError: (message) => dispatch(setSnackbarSystemDataAlertError(message)),
    setSnackbarSystemDataAlertInfo: (message) => dispatch(setSnackbarSystemDataAlertInfo(message)),
    fetchStandAvailability: (standId, date) => dispatch(fetchStandAvailability(standId, date)),
    clearStandAvailability: () => dispatch(clearStandAvailability()),
    clearWalletOrderTotal: () => dispatch(clearWalletOrderTotal()),
    setAnonymousUserData: (data) => dispatch(setAnonymousUserData(data)),
    setWebSDKDataReceive: (data) => dispatch(setWebSDKDataReceive(data)),
    refreshOrderTotal: (payload) => dispatch(refreshOrderTotal(payload)),
    updateVirtualCurrencyPromotions: (vcPromotions) => dispatch(updateVirtualCurrencyPromotions(vcPromotions)),
    retrieveVaultedCardsByUser: () => dispatch(retrieveVaultedCardsByUser()),
    retrieveLoyaltyUser: () => dispatch(retrieveLoyaltyUser()),
    clearUserVaultedCards: () => dispatch({ type: VNWebAccountActionTypes.VNWEBACCOUNT_SET_VAULTED_CARDS, vaulted_cards: [] }),
    clearSnackbarSystem: () => dispatch(resetSnackbarSystemData()),
    setLastOrderTotalUuidAndMenuUuid: (lastUuid, lastMenuUuid) => dispatch(setLastOrderTotalUuidAndMenuUuid(lastUuid, lastMenuUuid)),
    resetLastOrderTotalUuidAndMenuUuid: () => dispatch(resetLastOrderTotalUuidAndMenuUuid()),
  }
}

// export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Cart))
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withPaymentMappingHook(Cart)))

/**
 * Class object to iterate over an item's modifiers to ensure
 * that we are using the appropriate price given item.secondaryPriceThresholdCount
 * and a child modifier's priceAfterThresholdInCents properties.
 *
 * When iterating over the modifiers sent from an ItemModal selection, modifiers
 * are in a flat array and respect the order in which they were added so that
 * we can map them agaist their parent group accurately.
 *
 * Stadium is also expecting the same behavior, so this sorting and tracking is
 * paramount to getting the appropriate prices calculated in the event a modifier
 * has a different price than the other modifiers in its group.
 */
export class ItemModifierTracker {
  constructor(item, modifiers) {
    this.item = item
    this.modifiers = modifiers
    this.modifierGroupCountTracker = {}
  }

  iterate(onPriceIncrementCallback) {
    this.modifiers.forEach( mod => {
      // Determine if items need to be calculated based off of secondaryPriceThresholdCount
      // on the item itself
      let parentGroupId = mod.parentGroupId
      let modifierGroupCount = this.modifierGroupCountTracker[parentGroupId]
      if (!modifierGroupCount && parentGroupId) {
        // If empty, populate secondaryPriceThresholdCount and count of 1
        this.modifierGroupCountTracker[parentGroupId] = {
          secondaryPriceThresholdCount :
            parseInt(
              this.item.modifierGroups.find(
                mg => mg.modifierPropertiesContainer.groupId === parentGroupId
              ).modifierPropertiesContainer.secondaryPriceThresholdCount
            ),
          count: 1
        }
      } else if (parentGroupId) {
        // Increment count for existing group
        this.modifierGroupCountTracker[parentGroupId].count = modifierGroupCount.count + 1
      }

      let modCount = parentGroupId ? this.modifierGroupCountTracker[mod.parentGroupId].count : null
      let modThresholdCount = parentGroupId ? this.modifierGroupCountTracker[mod.parentGroupId].secondaryPriceThresholdCount : null

      // Lastly, increment price based on secondaryPriceThresholdCount
      if (modThresholdCount) {
        if (modCount <= modThresholdCount) {
          onPriceIncrementCallback(mod, mod.defaultPriceInCents * mod.quantity)
        } else {
          onPriceIncrementCallback(mod, mod.variants[0].priceAfterThresholdInCents * mod.quantity)
        }
      } else {
        onPriceIncrementCallback(mod, mod.defaultPriceInCents * mod.quantity)
      }
    })
  }
}
