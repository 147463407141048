import numeral from 'numeral'
import i18n from '../App/languages/i18n'

export default class Money {

  static convertCurrency = (amount) => {
    const locale = i18n.language
    const currency = i18n.t('CURRENCY')

    const price = new Intl.NumberFormat(`${locale}`,
                        { style: "currency", currency: `${currency}`,
                          minimumFractionDigits: 2 })

    return price.format(amount)
  }

  static floatToDollars = (amount) => {
    return numeral(amount).format('0.00')
  }

  static centsToDollars = (amountInCents, noFree, isFiat) => {
    if (amountInCents === 0) {
      if (!noFree) {
        return i18n.t('FREE')
      }
    }

    const amount = numeral(amountInCents / 100).format('0.00')

    return isFiat ? Money.convertCurrency(amount) : amount
  }

  static centsToFloat = amountInCents => (numeral(amountInCents / 100)).format('0.00')

  static dollarsToCents = amount => (Math.round(parseFloat(amount) * 100))

  static formatDollars = (amount, noFree) => {
    let price = numeral(amount).format('0.00')
    if (price === "0.00") {

      if (!noFree) {
        price = i18n.t('FREE')
      }

      return price
    }

    return Money.convertCurrency(price)
  }
}
