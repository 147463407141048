export const SET_LOADING = 'SET_LOADING'
export const SET_FAILED = 'SET_FAILED'
export const SET_SUCCEEDED = 'SET_SUCCEEDED'

export const setLoading = (key) => ({
  type: SET_LOADING,
  key,
})

export const setFailed = (key, errors) => ({
  type: SET_FAILED,
  key,
  errors,
})

export const setSucceeded = (key, cache = false) => ({
  type: SET_SUCCEEDED,
  key,
  cache,
})
