let receivers = {}

const send = (key, payload) => {
  if (receivers[key]) {
    receivers[key].forEach(receiver => {
      receiver(payload)
    })
  }
}

const addReceiver = (key, receiver) => {
  if (receivers[key]) {
    receivers[key].push(receiver)
  } else {
    receivers[key] = [receiver]
  }
}

module.exports = {
  send: send,
  addReceiver: addReceiver
}
