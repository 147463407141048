import { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { updateUserLocation } from '../actions/user'
import { setProductTypeFilter, setEventFilter } from '../actions/filter'
import { setVirtualCurrencyTransferEmailAccept } from '../VNWallet/ActionCreators'
import { setOpenBadgeDialog, setOpenPaymentUpdatesDialog } from '../VNDialogs/ActionCreators'
import { setQueryParamRvcFilters } from '../VNFilter/ActionCreators'
import { setServiceTypeSelected } from '../VNQueryParams/ActionCreators'

import { isEmpty } from 'lodash'

export class QueryParamsHandler extends Component {
  componentDidMount() {
    this.interceptUserLocation()
    this.interceptStandsFilter()
    this.interceptEventsFilter()
    this.interceptVirtualCurrencyTransferEmail()
    this.interceptShowPayments()
    this.interceptShowBadge()
    this.interceptRvcFilters()
  }

  getQueryParams = () => queryString.parse(this.props.location.search, { arrayFormat: 'comma' })

  // grab the section, row, seat, sectionIsSuite, and orderLocationUuid query param to set in state
  interceptUserLocation() {
    const { updateUserLocation } = this.props
    let { section, row, seat, sectionIsSuite, orderLocationUuid } = this.getQueryParams()

    if (!section && !row && !seat) return

    if (sectionIsSuite && sectionIsSuite === "true") {
      sectionIsSuite = true
    } else if (sectionIsSuite && sectionIsSuite === "false") {
      sectionIsSuite = false
    }

    updateUserLocation({ section, row, seat, sectionIsSuite, orderLocationUuid })
  }

  // grab the eventFilter query param to set in state
  interceptEventsFilter() {
    const { setEventFilter } = this.props
    const { eventFilter } = this.getQueryParams()
    setEventFilter(eventFilter)
  }

  // grab the productType and serviceType query param to set in state
  interceptStandsFilter() {
    const { setProductTypeFilter, setServiceTypeSelected } = this.props
    const { productType, serviceType } = this.getQueryParams()
    const productTypes = ['Experience', 'Food', 'Merchandise']
    const serviceTypes = ['Delivery', 'Pickup']

    if (serviceTypes.includes(serviceType)) {

      // hardcoding pickup to concessions since we want to allow for the word Pickup in the
      // queryParams but the serviceType coming from the API is set to Concessions
      if (serviceType === 'Pickup') {
        setServiceTypeSelected('Concessions')
      } else {
        setServiceTypeSelected(serviceType)
      }
    }

    if (productTypes.includes(productType)) {
      setProductTypeFilter(productType)
    }
  }

  // Grab transfer email for transfer detection when re-routing to virtual currency activity
  interceptVirtualCurrencyTransferEmail() {
    const { setVirtualCurrencyTransferEmailAccept } = this.props
    const { transferEmail } = this.getQueryParams()

    if (!isEmpty(transferEmail)) {
      setVirtualCurrencyTransferEmailAccept(transferEmail)
    }
  }

  interceptShowPayments() {
    const { setShowPayments } = this.props
    const { showPayments } = this.getQueryParams()

    if (showPayments === 1 || showPayments === '1') {
      setShowPayments(true)
    }
  }

  interceptShowBadge() {
    const { setShowBadge } = this.props
    const { showBadge } = this.getQueryParams()

    if (showBadge === 1 || showBadge === '1') {
      setShowBadge(true)
    }
  }

  interceptRvcFilters() {
    // f_nwt - Filter_NoWaitTimes
    // f_st - Filter_ServiceTypes
    // f_l - Filter_Locations
    // f_c - Filter_Categories
    const { f_nwt, f_st, f_l, f_c } = this.getQueryParams()
    const { setQueryParamRvcFilters, setServiceTypeSelected } = this.props
    const serviceTypes = ['Delivery', 'Pickup']

    // currently we only support one service type from query params
    const serviceType = serviceTypes.find(type => type.toLowerCase() === f_st?.toLowerCase())

    setQueryParamRvcFilters({
      noWaitTimes: typeof f_nwt === 'string' && f_nwt.toLowerCase() === 'true',
      locations: Array.isArray(f_l) ? f_l : typeof f_l === 'string' ? [f_l] : [],
      categories: Array.isArray(f_c) ? f_c : typeof f_c === 'string' ? [f_c] : []
    })

    if (serviceType) {
      // hardcoding pickup to concessions since we want to allow for the word Pickup in the
      // queryParams but the serviceType coming from the API is set to Concessions
      setServiceTypeSelected(serviceType === 'Pickup' ? 'Concessions' : serviceType)
    }
  }

  render = () => null
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserLocation: (args) => dispatch(updateUserLocation(args)),
    setProductTypeFilter: (productTypeFilter) => dispatch(setProductTypeFilter(productTypeFilter)),
    setServiceTypeSelected: (serviceTypeFilter) => dispatch(setServiceTypeSelected(serviceTypeFilter)),
    setEventFilter: (eventFilter) => dispatch(setEventFilter(eventFilter)),
    setVirtualCurrencyTransferEmailAccept: (email) => dispatch(setVirtualCurrencyTransferEmailAccept(email)),
    setShowPayments: (show) => dispatch(setOpenPaymentUpdatesDialog(show)),
    setShowBadge: (show) => dispatch(setOpenBadgeDialog(show)),
    setQueryParamRvcFilters: (filterObj) => dispatch(setQueryParamRvcFilters(filterObj))
  }
}

export default connect(undefined, mapDispatchToProps)(QueryParamsHandler)
