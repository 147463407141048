import * as ActionTypes from './ActionTypes'

/**
 * Set the filtered items that have been retrieved and set
 * in the VNRevenueCenterFilter container. Query Params are kept in state separately
 * and then combined when retrieved from state
 * as to not have collision issues with browser navigation
 * @param {Object} filterObj - Filter arrays to set
 * @param {Boolean} filterObj.noWaitTimes - Boolean to only show no wait time rvcs
 * @param {Array} filterObj.serviceTypes - Array of service types that have been selected (Pickup, Delivery)
 * @param {Array} filterObj.locations - Array of locations that have been selected
 * @param {Array} filterObj.categories - Array of categories that have been selected
 */
export const setRvcFilters = (filterObj) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNFILTER_SET_RVC_FILTERS,
      rvcFilterObj: filterObj
    })
  }
}

/**
 * Set the filtered items that have been retrieved from URL Query params.
 * Query Params are kept in state separately and then combined when retrieved from state
 * as to not have collision issues with browser navigation
 * @param {Object} filterObj - Filter arrays to set
 * @param {Boolean} filterObj.noWaitTimes - Boolean to only show no wait time rvcs
 * @param {Array} filterObj.locations - Array of locations that have been selected
 * @param {Array} filterObj.categories - Array of categories that have been selected
 */
export const setQueryParamRvcFilters = (filterObj) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VNFILTER_SET_QUERY_PARAM_RVC_FILTERS,
      rvcFilterObj: filterObj
    })
  }
}
