import axios from 'axios'
import * as CONFIG from '../App/configurations/Configs'
import { mockApiFetchAllRevenueCenters } from './Mock'
import { BASE_URL, ORG_NAME, VENUE_UUID } from '../constants'

/**
 * Fetch all the revenue centers
 * @param {*} token - The JWT Token
 * @param {*} menuId - The menu ID that you want to get details about
 */
export const apiFetchAllRevenueCenters = (token) => {
  if (CONFIG.DATA.MOCK.VN_REVENUE_CENTERS_apiFetchAllRevenueCenters) {
    return mockApiFetchAllRevenueCenters()
  }

  return axios.get(`${BASE_URL}/stands?organization_name=${ORG_NAME}&venue_uuid=${VENUE_UUID}&show_sales_events=true`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}
