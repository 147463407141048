import { useSelector } from 'react-redux'
import { getWebSDKMode, getWebSDKAccentColor, getWebSDKBackgroundColor } from '../../VNWebSDK/Selectors'
import { getAccentColor } from '../../VNConfigurations/Selectors'
import { Box, Typography, useTheme } from '@material-ui/core'
import { getUser } from '../../VNUser/Selectors'
import getContrastYIQ from '../../VNUtil/Color'

/**
 * 
 * @param {Object} highestRankedAffiliation extra display information for the user
 * @param {Boolean} alternateStyle used if virtualCurrencyEnabled is false to alter the styling of the component
 * @returns 
 */
export const VNUserInfo = ({highestRankedAffiliation, alternateStyle}) => {
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKAccentColor = useSelector(state => getWebSDKAccentColor(state))
  const accentColor = useSelector(state => getAccentColor(state))
  const webSDKBgColor = useSelector(state => getWebSDKBackgroundColor(state))
  const user = useSelector(state => getUser(state))
  const theme = useTheme()


  const modeBackgroundColor = () => {
    if (webSDKMode) {
      return webSDKBgColor
    } else if (!webSDKMode) {
      return accentColor
    }

    return 'initial'
  }

  const getModeStyle = () => {
    if(alternateStyle) {
      return {}
    }
    return {
      paddingBottom: 24,
      backgroundColor: modeBackgroundColor()
    }
  }

  const getModeAffiliationStyle = () => {
    if(!alternateStyle) {
      return {
        ...theme.typography.h2,
        color: webSDKMode ? webSDKAccentColor : getContrastYIQ(accentColor)
      }
    }

    return {
      ...theme.typography.h2,
    }
  }

  const getUserTextStyle = () => {
    if(alternateStyle) {
      return {}
    }
    return {
      color: webSDKMode ? webSDKAccentColor : getContrastYIQ(accentColor)
    }
  }

  const displayUser = () => {
    if (!user.isEmpty()) {

      let response = []
      response.push(<Box pl={3} pt={3} key={user.get('userID')}>
                      <Typography variant='h3' style={getUserTextStyle()}>{user.get('name') ? user.get('name') : user.get('email')}</Typography>
                    </Box>)

      // if there are affiliations, display the highest member level
      if (highestRankedAffiliation) {
        response.push(
          <Box pl={3} key={highestRankedAffiliation.name}>
            <Typography style={getModeAffiliationStyle()}>{highestRankedAffiliation.name}</Typography>
          </Box>
        )
      }

      return response
    }
  }

  return (
    <Box style={getModeStyle()}>
      {displayUser()}
    </Box>
  )
}