import { isDecimal } from '../../VNUtil/Number'

import { API_ENDPOINT } from '../../constants'

/**
 * Post message to native bridge. Detect if iOS or Android
 * @param {object} data
 */
const postMessageThroughBridge = (data) => {
  if (window.webkit !== undefined) {
    if (window.webkit.messageHandlers.VN_DATA_BRIDGE) {
      window.webkit.messageHandlers.VN_DATA_BRIDGE.postMessage(data)
    }
  }
  if (window.VN_DATA_BRIDGE !== undefined) {
    window.VN_DATA_BRIDGE.jsBridgePostMessage(JSON.stringify(data, replacer))
  }

   // If Android, convert all values to string as this is a requirement
  // from the Android side
  function replacer(name, val) {
    if (Number.isInteger(val) || isDecimal(val) || typeof val == 'boolean') {
      return val.toString()
    } else {
      return val
    }
  }
}

export const VNDataBridgeSendType = {
  SET_BRIGHTNESS_LEVEL: 'SET_BRIGHTNESS_LEVEL',
  IOS_QR_MODAL: 'IOS_QR_MODAL',
  REQUEST_LOCATION: 'REQUEST_LOCATION',
  ANALYTICS_EVENT: 'ANALYTICS_EVENT',
  SDK_PAYMENT_REQUEST: 'SDK_PAYMENT_REQUEST',
  IOS_TRANSPARENT_WEBVIEW: 'IOS_TRANSPARENT_WEBVIEW',
  IOS_I4GO_APPLE_PAY: 'IOS_I4GO_APPLE_PAY'
}

/**
 * Send brightness level to native bridge, mostly used with
 * QR Code display in Wallet.
 * @param {Int} level - The level between 0 and 100
 */
export const sendBrightnessLevel = (level) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.SET_BRIGHTNESS_LEVEL,
    data: {
      level: level
    }
  })
}

/**
 * If webSDKMode is in iOS, when visiting VNScanAndPay (/wallet/scan-and-pay) tell native
 * webSDK to display QR Modal as utilizing Camera for QR scanning
 * is not allowed in a webview on iOS
 * @param {string} description - Description text to go under QR Scanner in native iOS
 * @param {string} walletMode - The wallet mode to send to iOS QR Modal
 */
export const sendIOSDisplayQRModal = (description, walletMode) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.IOS_QR_MODAL,
    data: {
      description: description,
      walletMode: walletMode
    }
  })
}

/**
 * If in native SDK mode, request location from the native side instead
 */
export const requestNativeLocation = () => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.REQUEST_LOCATION,
    data: {}
  })
}

/**
 * Blanket entry point to send an analytics event to the native wrapper
 * to trigger events via an interface.
 * @param {object} data
 * @param {string} data.event - Event to be tracked
 * @param {object} data.params - Parameters to report
 */
export const sendAnalyticsEvent = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.ANALYTICS_EVENT,
    data: data
  })
}

/**
 * Send SDK payment request for either Android or iOS
 * @param {object} data
 */
export const sendSDKPaymentRequest = (data) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.SDK_PAYMENT_REQUEST,
    data: {
      ...data
    }
  })
}

 /** Signals to SDK that either the VNBadge or VNUserPayments modal has
 * been opened. Allows SDK to track open status to determine navigation bar
 * back functionality
 * @param {object} data
 */
export const sendSDKModalOpen = (data) => {
  postMessageThroughBridge({
    method: 'SDK_MODAL_OPEN',
    data: {
      ...data
    }
  })
}

/**
 * Signals to the iOS SDK that the WKWebView needs too be transparent, which
 * is only really used at the scanner right now.
 * @param {string} description - Description to show in the native scanner view
 * @param {string} isTransparent - Boolean to switch on or off, either 'yes' or 'no'
 */
export const sendSDKIOSTransparentWebview = (description, isTransparent) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.IOS_TRANSPARENT_WEBVIEW,
    data: {
      description: description,
      isTransparent: isTransparent
    }
  })
}

/**
 * Signals to the iOS SDK that an Apple Pay payment request needs to happen via
 * VenueNext Shift4 (i4Go) WKWebView payment SPA
 * @param {string} uuid - UUID of cached payment request
 */
export const sendSDKIOSRequesti4GoApplePay = (uuid) => {
  postMessageThroughBridge({
    method: VNDataBridgeSendType.IOS_I4GO_APPLE_PAY,
    data: {
      url: `${API_ENDPOINT}/otp/retrieve?id=${uuid}`
    }
  })
}
