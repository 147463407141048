import React, {Fragment, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"

import { getOrderHistory, getOpenOrdersFromHistory, getCompletedOrdersFromHistory } from '../Selectors'
import { getUser, getUserJWT } from '../../VNUser/Selectors'
import { retrieveAllOrderHistory } from '../ActionCreators'
import { MAKE_ORDER_RECEIPT, MAKE_ORDER_AGAIN } from '../../actions/order'
import { history } from '../../App/store/configureStore'
import { addItemToCart } from '../../actions/cart'
import { getItems } from '../../selectors/item'
import { loadMenu } from '../../actions/menu'
import { VNUserOrdersListItem } from '../components/VNUserOrdersListItem'
import VNOrderState from '../types/VNOrderState'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'
import { VNSkeletonListItem } from '../../VNComponents/VNSkeletonListItem'
import { toTitleCase } from '../../VNUtil/StringHelpers'
import TimeZone from '../../utils/timezone'
import { setActiveMenu } from '../../actions/cart'
import VNDivider from '../../VNComponents/VNDivider'
import { makeVNMenu } from '../../VNUtil/VNMenuHelper'
import { apiFetchMenu } from '../../VNMenu/Api'
import * as ActionTypes from '../../VNMenu/ActionTypes'
import { enableBackdropSystem, disableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import { setSnackbarSystemDataAlertError } from '../../VNSnackbarSystem/ActionCreators'

import humps from 'humps'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'

import moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  root: {
  },
  sectionDivider: {
    height: 10
  }
}))

export const VNUserOrders = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  // SELECTORS

  // the user from redux
  const user = useSelector(state => getUser(state))
  const userJWT = useSelector(state => getUserJWT(state))

  // the order history from redux
  const orders = useSelector(state => getOrderHistory(state))
  const openOrders = useSelector(state => getOpenOrdersFromHistory(state, t))
  const completedOrders = useSelector(state => getCompletedOrdersFromHistory(state, t))
  const ordersApiStatus = useSelector(state => getLoadingSystemStatus(state, retrieveAllOrderHistory.name))

  // menus from redux for going to cart
  const menuItem = useSelector(state => getItems(state))

  // LOCAL STATE

  // If we should navigate to cart after state is updated
  const [gotoCart, setGotoCart] = useState(false)

  // on instantiation, go fetch your order history
  useEffect(() => {
    if (!user.isEmpty()) {
      dispatch(retrieveAllOrderHistory())
    }
    document.title = t('MY_ORDERS')
    if (!isEmpty(menuItem) && gotoCart) {
      history.push('/cart', {fromRoute: VNUserOrders.name})
    }

  }, [menuItem, dispatch, gotoCart, user])

  /**
   * Combined funcitonality for either handleOrderAgainClick or
   * handleListItemClick. Dispatches to redux to update the appropriate
   * redux items so that functionality in those screens is not broken
   * NOTE: NOT TO BE INCLUDED WHEN CODE TRANSFERS OVER TO V2
   * @param {string} type
   * @param {object} order
   */
  const makeOrderAgainOrReceipt = (type, order) => {
    const lineItems = order.relationships.line_items.data
    const included = orders.get('included')

    let receiptOrderLineItem = {}

    // Need to iterate through items and create orderLineItem in state
    // to conform with existing Cart/OrderSummary code
    lineItems.forEach((lineItem) => {
      included.forEach((include) => {
        if (lineItem.id === include.id) {
          receiptOrderLineItem[lineItem.id] = include
        }
      })
    })

    // dispatch directly as no action or saga needs to happen
    dispatch({
      type: type,
      payload: {
        order: order,
        orderLineItem: receiptOrderLineItem
      }
    })

    let orderAttrs = order.attributes

    dispatch(setActiveMenu(orderAttrs.stand_menu_uuid, orderAttrs.stand_uuid))


    return receiptOrderLineItem
  }

  /**
   * Handle list item clicks to navigate to receipt for order
   * NOTE: NOT TO BE INCLUDED WHEN CODE TRANSFERS OVER TO V2
   * @param {object} order
   */
  const handleListItemClick = (order) => {
    if (order.product_type === 'Experience' || 'active_transfer_uuid' in order) {
      if (order.state === 'awarded') {
        history.push('/experience_orders/' + order.id, {
          navigateTo: '/profile/orders',
          previousScreen: VNUserOrders.name,
          awardedUserItem: order
        })
        return
      }
      history.push('/experience_orders/' + order.id, {navigateTo: '/profile/orders', previousScreen: VNUserOrders.name})
      return
    }

    makeOrderAgainOrReceipt(MAKE_ORDER_RECEIPT, order)

    history.push('/orders/' + order.id, {navigateTo: '/profile/orders', previousScreen: VNUserOrders.name})
  }

  /**
   * Handle list item embedded link click to add old order items to
   * cart and navigate to cart
   * NOTE: NOT TO BE INCLUDED WHEN CODE TRANSFERS OVER TO V2
   * @param {object} order
   */
  const handleOrderAgainClick = async (order) => {
    const lineItems = humps.camelizeKeys(makeOrderAgainOrReceipt(MAKE_ORDER_AGAIN, order))

    let upToDateMenu
    let failedToFetchMenu = false

    dispatch(enableBackdropSystem())

    // run apiFetchMenu here so we don't need to get menu from redux state
    // we can just get menu from api response and use it later
    await apiFetchMenu(userJWT, order.attributes.stand_menu_uuid).then(response => {

      const fullMenu = makeVNMenu(response.data)
      upToDateMenu = fullMenu

      dispatch({
        type: ActionTypes.VNMENU_SET_MENU,
        menu: fullMenu
      })

    }).catch(error => {
      failedToFetchMenu = true
      console.log(error)
    })

    dispatch(disableBackdropSystem())

    if (failedToFetchMenu || !upToDateMenu) {
      dispatch(setSnackbarSystemDataAlertError(t('NETWORK_ERROR')))
      return
    }

    // exclude all fee items (delivery fee, service fee, tax ... etc)
    const purchasableItems = Object.values(lineItems).filter(item => !item.attributes.specialType)

    // allow items that exist in upToDateMenu.items
    const allowedPurchasableItems = purchasableItems.filter(item => upToDateMenu.items.some(availableItem => availableItem.uuid === item.attributes.menuItemUuid))

    // no purchasable item found, show error message, abort
    if (!allowedPurchasableItems.length) {
      dispatch(setSnackbarSystemDataAlertError(t('ITEMS_ARE_CURRENTLY_NOT_AVAILABLE')))
      return
    }

    // unavailable items found
    if (allowedPurchasableItems.length !== purchasableItems.length) {
      const itemsToRemove = purchasableItems.filter(item => !allowedPurchasableItems.includes(item))

      // remove unavailable items from lineItems
      for (const item of itemsToRemove) {
        delete lineItems[humps.camelize(item.id)]
      }
    }

    // lineItems does not contain any purchasable items, show error message, abort
    if (!Object.values(lineItems).some(item => !item.attributes.specialType)) {
      dispatch(setSnackbarSystemDataAlertError(t('ITEMS_ARE_CURRENTLY_NOT_AVAILABLE')))
      return
    }

    // remove virtual item representing the tax amount that is used in stadium api legacy functionality
    for (const [key, value] of Object.entries(lineItems)) {
      if (!value.attributes?.uuid) {
        delete lineItems[key]
      }
    }

    if (userJWT) {
      dispatch(loadMenu(order.attributes.stand_menu_uuid))
    }

    for (let v of Object.values(lineItems)) {
      dispatch(addItemToCart(
        v.attributes.menuItemUuid,
        v.attributes.quantity,
        v.attributes.modifiers[0],
        true
      ))
    }

    setGotoCart(true)
  }

  const displayOpenOrders = () => {
    if (!openOrders.isEmpty() && ordersApiStatus.status === ApiLoadingStatus.SUCCEEDED ) {
      return (
        openOrders.sort((x,y) => {
          let xDate = x.attributes && x.attributes.created_at ? x.attributes.created_at : x.created_at
          let yDate = y.attributes && y.attributes.created_at ? y.attributes.created_at : y.created_at
          return new Date(yDate) - new Date(xDate)
        }).map((order, index) => {
          if (order.type === 'order') {
            return makeFnbMerchListItem(order, index)
          } else if (order.product_type === 'Experience') {
            return makeExperienceListItem(order, index)
          } else if (VNOrderState.isAwardedOrTransferredExperienceOrder(order.state)) {
            return makeAwardedOrTransferredExperienceListItem(order, index)
          }
          return null
        })
      )
    } else if (openOrders.isEmpty() && ordersApiStatus.status === ApiLoadingStatus.LOADING) {
      return (
        <Fragment>
          <VNSkeletonListItem />
          <VNSkeletonListItem />
        </Fragment>
      )
    } else if (openOrders.isEmpty() && ordersApiStatus.status === ApiLoadingStatus.SUCCEEDED) {
      return (
        <Typography variant="subtitle1">
          {t('NO_OPEN_ORDERS')}
        </Typography>
      )
    }
    return (
      <Fragment>
        <VNSkeletonListItem />
        <VNSkeletonListItem />
      </Fragment>
    )
  }

  const displayCompletedOrders = () => {
    if (!completedOrders.isEmpty() && ordersApiStatus.status === ApiLoadingStatus.SUCCEEDED) {
      return (
        completedOrders.sort((x,y) => {
          let xDate = x.attributes && x.attributes.created_at ? x.attributes.created_at : x.created_at
          let yDate = y.attributes && y.attributes.created_at ? y.attributes.created_at : y.created_at
          return new Date(yDate) - new Date(xDate)
        }).map((order, index) => {
          if (order.type === 'order') {
            return makeFnbMerchListItem(order, index)
          } else if (order.product_type === 'Experience') {
            return makeExperienceListItem(order, index)
          } else if (VNOrderState.isAwardedOrTransferredExperienceOrder(order.state)) {
            return makeAwardedOrTransferredExperienceListItem(order, index)
          }
          return null
        })
      )
    } else if (completedOrders.isEmpty() && ordersApiStatus.status === ApiLoadingStatus.LOADING) {
      return (
        <Fragment>
          <VNSkeletonListItem />
          <VNSkeletonListItem />
        </Fragment>
      )
    } else if (completedOrders.isEmpty() && ordersApiStatus.status === ApiLoadingStatus.SUCCEEDED) {
      return (
        <Typography variant="subtitle1">
          {t('NO_COMPLETED_ORDERS')}
        </Typography>
      )
    } else if (ordersApiStatus.error) {
      return null
    }
    return (
      <Fragment>
        <VNSkeletonListItem />
        <VNSkeletonListItem />
      </Fragment>
    )
  }

  const getSalesEventText = (salesEvent) => {
    let text = ''
    if (salesEvent?.start_time) {
      text = moment(salesEvent.start_time).format('MMM D, YYYY h:mm A') + ' - '
    }
    if (salesEvent?.name) {
      text += salesEvent.name
    }
    return text
  }

  const makeFnbMerchListItem = (order, index) => {

    let totalItems = 0

    const included = orders.get('included')
    order.relationships.line_items.data.forEach((lineItem) => {
      included.forEach((include) => {
        // if an item does not have uuid
        // it is a virtual item representing the tax amount that is used in stadium api legacy functionality
        // it should not be counted as an item
        // and only items that don't have special_type should be counted
        if (include.attributes?.uuid && lineItem.id === include.id && !include.attributes.special_type) {
          totalItems += include.attributes.quantity
        }
      })
    })

    // If device_uuid and source_device_uuid are equal, then it is not
    // a rich checkout order, if they aren't equal, then it is a rich checkout order
    // and business logic defines not to allow reorder when it is rich
    const attributes = order.attributes
    const deviceUuid = attributes.device_uuid
    const sourceDeviceUuid = attributes.source_device_uuid

    const userTimeZone = TimeZone.current()
    const createdAt = moment(order.attributes.created_at).tz(userTimeZone).format('dddd, MMMM Do YYYY') + ' - ' + moment(order.attributes.created_at).tz(userTimeZone).format('hh:mma')
    const subTitle = `${attributes?.order_number ? '#' + attributes.order_number + ' - ' : ''}${createdAt}`

    // hide Order Again if
    // 1. it is a rich checkout order
    // 2. it is a Zippin or an Amazon Just Walk Out order
    // 3. it is a QR Pay order
    const isRichCheckout = deviceUuid !== sourceDeviceUuid
    const isZPNOrJWO = attributes?.order_number?.startsWith('ZPN') || attributes?.order_number?.startsWith('JWO')
    const isQRPay = attributes?.payment_info?.some(p => p.payment_type === 'wallet_nonce')
    const hideOrderAgain = isRichCheckout || isZPNOrJWO || isQRPay

    return (
      <Fragment key={index}>
        <VNUserOrdersListItem
          title={order.attributes.stand_name}
          subTitle={subTitle}
          topArea={order.attributes.state_display_name}
          rightArea={`${t('CURRENCY_SYMBOL')}${order.attributes.total}`}
          bottomModifierText={`${totalItems} ${t('ITEMS')}`}
          bottomLinkText={hideOrderAgain ? null : t('ORDER_AGAIN')}
          onBottomLinkTextClick={() => handleOrderAgainClick(order)}
          click={() => handleListItemClick(order)}
          salesEventText={getSalesEventText(attributes.sales_event)}
        />
        <VNDivider />
      </Fragment>
    )
  }

  const makeExperienceListItem = (order, index) => {
    let redemptionState = VNOrderState.getExperienceRedemptionState(order, t)

    const userTimeZone = TimeZone.current()

    const createdAt = moment(order.created_at).tz(userTimeZone).format('dddd, MMMM Do YYYY - h:mm a')
    const subTitle = `${order.order_number ? '#' + order.order_number + ' - ' : ''}${createdAt}`

    // Can only buy one event item per order for now
    const eventDate = order.user_items?.[0]?.event_date ? `${t('EVENT_DATE')} - ${moment(order.user_items[0].event_date).format('MMMM Do, YYYY')}` : ''

    let chipColor = 'primary'

    if (redemptionState) {
      if (redemptionState === t('REDEEM_NOW') || redemptionState.includes(t('REDEEMABLE'))) {
        chipColor = 'secondary'
      }
    }

    return (
      <Fragment key={index}>
        <VNUserOrdersListItem
          title={order.item_names[0]}
          subTitle={`${subTitle}`}
          topArea={redemptionState ? toTitleCase((redemptionState.toLowerCase())) : null}
          chipColor={chipColor}
          rightArea={`${t('CURRENCY_SYMBOL')}${parseFloat(order.line_items[0].total).toFixed(2)}`}
          bottomModifierText={`${order.user_items.length} ${t('ITEMS')}`}
          click={() => handleListItemClick(order)}
          eventDate={eventDate}
          salesEventText={getSalesEventText(order.attributes?.sales_event)}
        />
        <VNDivider />
      </Fragment>
    )
  }

  const makeAwardedOrTransferredExperienceListItem = (order, index) => {
    const redemptionState = VNOrderState.getAwardedTransferredExperienceRedemptionState(order, t)

    const userTimeZone = TimeZone.current()

    const createdAt = moment(order.created_at).tz(userTimeZone).format('dddd, MMMM Do YYYY - h:mm a')

    let chipColor = 'primary'

    if (redemptionState.state === t('REDEEM_NOW')) {
      chipColor = 'secondary'
    }

    return (
      <Fragment key={index}>
        <VNUserOrdersListItem
          title={order.name}
          subTitle={`${createdAt}`}
          topArea={toTitleCase((redemptionState.state.toLowerCase()))}
          chipColor={chipColor}
          rightArea={order.state.toUpperCase()}
          bottomModifierText={`1 ${t('ITEMS')}`}
          click={() => handleListItemClick(order)}
          salesEventText={getSalesEventText(order.attributes?.sales_event)}
        />
        <VNDivider />
      </Fragment>
    )
  }

  return (
    <Box>
      <Box pr={2} pl={2} marginTop={2}>
        <Box>
          <Typography variant="h1">
            {t('OPEN_ORDERS')}
          </Typography>
        </Box>
        <List>
          {displayOpenOrders()}
        </List>
      </Box>
      <VNDivider classes={{root: classes.sectionDivider}}/>
      <Box pr={2} pl={2} marginTop={2}>
        <Box>
          <Typography variant="h1">
            {t('COMPLETED')}
          </Typography>
        </Box>
        <List>
          {displayCompletedOrders()}
        </List>
      </Box>
    </Box>
  )
}

// Override Function.name because build minification mangles function names
// and some functions aren't retaining their names properly which affects
// code in other places, namely Cart.js
Object.defineProperty(VNUserOrders, 'name', { value: 'VNUserOrders' })
