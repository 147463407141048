export const mockUpdateUser = () => {
  return new Promise((resolve) => {
    resolve({ data: {

    }})
  })
}

export const mockApiRefreshUsersAffiliations = () => {
  return new Promise((resolve) => {
    resolve({ data: {}})
  })
}

export const mockApiGetUserAffiliations = () => {
  return new Promise((resolve) => {
    resolve({ data:
      [{
        "provider": "ticketmaster",
        "name": "Bronze",
        "secondary_color": "#e8b923",
        "created": 1600922825557,
        "external_id": "6",
        "primary_color": "#794bc4",
        "rank": "4",
        "daniel": "daniel"
      }]
    })
  })
}

export const mockApiLogoutUser = () => {
  return new Promise((resolve) => {
    resolve({ data: {

    }})
  })
}

export const mockApiGetStadiumUser = () => {
  return new Promise((resolve) => {
    resolve({ data:
      {
        "uuid": "b61b4f94-df7e-4005-ae29-0cc30d9a6e23",
        "name": "DAniel Kim",
        "first_name": null,
        "middle_name": null,
        "last_name": null,
        "phone_number": "+15122931632",
        "email": "dannyskim@gmail.com",
        "dob": null,
        "gender": null,
        "address": null,
        "appetize_user_id": null,
        "profile_image_url": null,
        "preferred_credit_card": true,
        "device_guid": "b61b4f94-df7e-4005-ae29-0cc30d9a6e23"
      }
    })
  })
}

export const mockApiGetUserPaymentMethods = () => {
  return new Promise((resolve) => {
    resolve({data: {}})
  })
}

export const mockApiGetCardAffiliations = () => {
  return new Promise((resolve) => {
    resolve({data: {}})
  })
}
