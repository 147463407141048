import * as ActionTypes from './ActionTypes'

/**
 *
 * @param {String} type
 */
export const setProductTypeSelected = (type) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNQUERYPARAMS_SET_PRODUCT_TYPE_SELECTED,
      productType: type
    })
  }
}

/**
 *
 * @param {String} type
 */
export const setServiceTypeSelected = (type) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNQUERYPARAMS_SET_SERVICE_TYPE_SELECTED,
      serviceType: type
    })
  }
}
