import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { getOrgLevelConfiguration, getInstanceLevelConfiguration, getSettingsLinksAvailable, getAccentColor } from '../../VNConfigurations/Selectors'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { getWebSDKBackgroundColor, getWebSDKMode } from '../../VNWebSDK/Selectors';
import { history } from '../../App/store/configureStore';

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 0
  },
  signout: theme.custom.subtitle3
}))


/**
 * Shows the settings that appear in the drawer for all users
 * @param onSelection - func - if you want a callback when one of the list items has been clicked
 */
export const VNSettingsList = (props) => {

  const classes = useStyles()

  const settingsLinkAvailable = useSelector(state => getSettingsLinksAvailable(state))
  const orgConfiguration = useSelector(state => getOrgLevelConfiguration(state))
  const instanceConfiguration = useSelector(state => getInstanceLevelConfiguration(state))
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKBgColor = useSelector(state => getWebSDKBackgroundColor(state))
  const accentColor = useSelector(state => getAccentColor(state))

  const { t } = useTranslation()

  const theme = useTheme()

  // HANDLES

  const modeBackgroundColor = () => {
    if (webSDKMode && history.location.pathname === '/wallet') {
      return webSDKBgColor
    } else if (!webSDKMode && history.location.pathname === '/wallet') {
      return accentColor
    }

    return 'initial'
  }

  // called when any list item has been clicked
  const handleAnySelection = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (props.onSelection) {
      props.onSelection()
    }

    window.location = event.currentTarget.getAttribute('data-link')
  }

  /**
   * Get the link from either the instance level or org level configurations
   * @param {String} linkName  - The dynamic link name from VNConfigurations
   */
  const getLink = (linkName) => {

    let link = orgConfiguration[linkName]

    if (instanceConfiguration && instanceConfiguration[linkName]) {
      link = instanceConfiguration[linkName]
    }

    return link
  }

  const displayList = () => {
    if (settingsLinkAvailable.length > 0) {
      return (
        <List>
          {settingsLinkAvailable.map(setting => (
            <ListItem className={classes.listItem} button key={setting} onClick={handleAnySelection} data-link={getLink(`settinglinks_available_${setting}`)}>
              <ListItemText disableTypography style={theme.custom.subtitle3} primary={t(setting.toUpperCase())} />
            </ListItem>
          ))}
          {
            props.hasSignout && !webSDKMode &&
            <ListItem className={classes.listItem} button key={'signout'} onClick={props.onSignout}>
              <ListItemText disableTypography className={classes.signout} style={{color: modeBackgroundColor(), fontWeight: 'bold'}} color={modeBackgroundColor()}  primary={t('SIGN_OUT')} />
            </ListItem>
          }
        </List>
      )
    }
    return (<span></span>)
  }

  // these configuration links are pulled from the global configurations API on VNAPI
  // you can enable and disable them via the Admin on VNAPI
  return (displayList())
}
