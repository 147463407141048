import React, {Component} from 'react'
import Section from '../components/Section'
import { PRIVACY_POLICY_URL } from '../constants'
import './PrivacyPolicy.scss'
import { withTranslation } from 'react-i18next'

export class PrivacyPolicy extends Component {

  render() {
    if (!PRIVACY_POLICY_URL) return null

    return(
      <Section className='privacy-policy'>
        <a
          href={PRIVACY_POLICY_URL}
          target='_blank'
          rel="noopener noreferrer"
        >
          {this.props.t('PRIVACY_POLICY')}
        </a>
      </Section>
    )
  }
}

export default withTranslation()(PrivacyPolicy)
