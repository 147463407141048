import { useEffect, useRef, useState } from 'react'

export const useStateWithCompletion = init => {
  const [state, setState] = useState(init)
  const callbackRef = useRef()

  const setStateWithCompletion = (newState, callback) => {
    callbackRef.current = callback
    setState(newState)
  }

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state)
    }
    callbackRef.current = undefined
  }, [state])

  return [state, setStateWithCompletion]
}
