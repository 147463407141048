import { UPDATE_USER_LOCATION, UPDATE_USER_LOCATION_CONFIRMED, UPDATE_USER_AGE_VERIFICATION } from '../actions/user'
import { LOAD_MENUS_FOR_SEAT_REQUESTED, LOAD_MENUS_FOR_SEAT_SUCCEEDED, LOAD_MENUS_FOR_SEAT_FAILED } from '../actions/seat'

const defaultState = {
  location: {
    confirmed: false,
    valid: false,
    ageVerified: false,
  }
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_USER_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          ...action.payload,
        }
      }

    case UPDATE_USER_LOCATION_CONFIRMED:
      return {
        ...state,
        location: {
          ...state.location,
          confirmed: action.payload.confirmed,
        }
      }

    case LOAD_MENUS_FOR_SEAT_REQUESTED:
      return {
        ...state,
        location: {
          ...state.location,
          valid: 'waiting',
        }
      }
    case LOAD_MENUS_FOR_SEAT_FAILED:
      return {
        ...state,
        location: {
          ...state.location,
          valid: false,
        }
      }

    case LOAD_MENUS_FOR_SEAT_SUCCEEDED:
      return {
        ...state,
        location: {
          ...state.location,
          valid: action.payload.valid,
        }
      }

    case UPDATE_USER_AGE_VERIFICATION:
      return {
        ...state,
        location: {
          ...state.location,
          ageVerified: action.payload.verified,
        }
      }

    default:
      return state
  }
}

export default reducer
