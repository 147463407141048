const COLORS = {
  grey: '#666666',
  darkGrey: '#333333',
  lightGrey: '#FCFCFA',
  buttonBorderGrey: '#DDDDDD',
  alert: '#E21E5C',
  blue: '#2253FF'
}

const TYPOGRAPHY = {
  subtitle1: {
    'font-family': 'Open Sans',
    'font-size': '12px',
    'line-height': '0.15px',
    'font-weight': 400,
    'color': COLORS.grey
  },
  subtitle2: {
    'font-family': 'Open Sans',
    'font-size': '16px',
    'line-height': '21.79px',
    'font-weight': 400,
    'color': COLORS.grey
  },
  subtitle3: {
    'font-family': 'Open Sans',
    'font-size': '16px',
    'line-height': '21.79px',
    'font-weight': 700,
    'color': COLORS.darkGrey
  },
  small1: {
    'font-family': 'Open Sans',
    'font-size': '9px',
    'font-weight': 400,
    'color': COLORS.blue
  },
  alert: {
    'font-family': 'Open Sans',
    'font-size': '12px',
    'line-height': '0.15px',
    'font-weight': 400,
    'color': COLORS.alert
  }
}

module.exports = {
  COLORS: COLORS,
  TYPOGRAPHY: TYPOGRAPHY
}
