import axios from 'axios'
import { BASE_URL, ORG_NAME } from '../constants'

/**
 * Fetch the order total, typically utilized in conjunction with QR Code scan
 * @param {object} orderTotalParams - The POST data object
 * @param {string} token - The JWT token
 */
export const apiFetchOrderTotal = (orderTotalParams, token) => {
  return axios.post(`${BASE_URL}/orders/total?organization_name=${ORG_NAME}`, orderTotalParams, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}
