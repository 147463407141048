import * as ActionTypes from './ActionTypes'

// helper function to set alert snackbars - set an Error message
// message: String - Required - The message to display
export function setSnackbarSystemDataAlertError(message) {

  const data = {
    alert: 'error',
    message: message,
    open: true,
  }

  return setSnackbarSystemData(data)

}

// helper function to set alert snackbars - set an Info message
// message: String - Required - The message to display
export function setSnackbarSystemDataAlertInfo(message) {

  const data = {
    alert: 'info',
    message: message,
    open: true,
  }

  return setSnackbarSystemData(data)

}

/**
 * Helper function to set alert snackbars - set a Success message
 * @param {String} message - The message to display
 */
export function setSnackbarSystemDataAlertSuccess(message) {

  const data = {
    alert: 'success',
    message: message,
    open: true,
  }

  return setSnackbarSystemData(data)
}

// Reset the snackbar data to empty
export function resetSnackbarSystemData() {
  return setSnackbarSystemData({})
}

// root snackbar set system
// use this one if you need to set more settings
// data: {} - Required - The data to set to display in the VNSnackbar
export function setSnackbarSystemData(data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNSNACKBAR_SYSTEM_SET_DATA,
      snackbarData: data
    })
  }
}
