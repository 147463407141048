import React, { useState, useEffect } from 'react'

/**
 * HTML <img> replacement that will check if src image exists, else
 * returns null. If retrieving src image fails, also returns null.
 * @param {string} className - Class to apply to <img> element
 * @param {string} src - Source URI of image
 * @param {alt} alt - Alt property of <img>
 */
export const VNImageFallback = (props) => {

  const [shouldShowFallback, setShouldShowFallback] = useState(false)

  useEffect(() => {
    setShouldShowFallback(false)
  }, [props.src])

  if (!shouldShowFallback) {
    return props.src ? (
      <img
        className={props.className}
        src={props.src}
        alt={props.alt}
        onError={() => {setShouldShowFallback(true)}}
      />
    ) : null
  } else {
    return null
  }
}

export default VNImageFallback
