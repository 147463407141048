import * as ActionTypes from './ActionTypes'
import { VNSTAND_TRUNCATED_STAND_FROM_MENU } from '../VNStand/ActionTypes'
import { VNREVENUECENTERS_SET_CROSS_VENUE_STAND } from '../VNRevenueCenters/ActionTypes'

import {
  apiFetchMenu,
  apiFetchMenuAndStandFromItemUuuid,
  apiFetchSelfCheckoutMenusBarcode,
  apiFetchSelfCheckoutMenusStand
} from './Api'

import { setApiLoadingStatus } from '../VNApiLoadingSystem/ActionCreators'

import { ApiLoadingStatus } from '../VNApiLoadingSystem/types/ApiLoadingStatus'

import { getUser } from '../VNUser/Selectors'
import { getCart } from './Selectors'

import { makeVNMenu } from '../VNUtil/VNMenuHelper'

/**
 * Get the menu details about a specific menu and have it save to redux.
 * @param {String} menuId - The menu ID you want to get details about
 */
 export const fetchMenuDetails = (menuId) => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    dispatch(setApiLoadingStatus(
      fetchMenuDetails.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiFetchMenu(jwt, menuId).then(response => {

      const fullMenu = makeVNMenu(response.data)

      dispatch({
        type: ActionTypes.VNMENU_SET_MENU,
        menu: fullMenu
      })

      dispatch(setApiLoadingStatus(
        fetchMenuDetails.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      console.log(error)

      dispatch(setApiLoadingStatus(
        fetchMenuDetails.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}

/**
 * Get the menu details and grab the stripped down stand information and save to
 * VNStand redux state. This is primarily used in the event the user is deep linking directly
 * to a menu that doesn't have a stand returned in VNRevenueCenters. This behavior is exhibited
 * in both Web and SDK deep links.
 * @param {String} menuId - The menu ID you want to get details about
 * @returns
 */
export const fetchStandFromMenu = (menuId) => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    dispatch(setApiLoadingStatus(
      fetchStandFromMenu.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiFetchMenu(jwt, menuId).then(response => {
      const fullMenu = makeVNMenu(response.data)

      const standFromMenu = {
        uuid: fullMenu.menu.stand_uuid,
        name: fullMenu.menu.stand_name,
        section: null,
        service_types: fullMenu.menu.service_types,
        images: fullMenu.menu.images,
        product_type: fullMenu.menu.product_type
      }

      dispatch({
        type: ActionTypes.VNMENU_SET_MENU,
        menu: fullMenu
      })

      dispatch({
        type: VNSTAND_TRUNCATED_STAND_FROM_MENU,
        stand: { [standFromMenu.uuid] : standFromMenu },
        menu: fullMenu.menu
      })

      dispatch(setApiLoadingStatus(
        fetchStandFromMenu.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      console.log(error)

      dispatch(setApiLoadingStatus(
        fetchStandFromMenu.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}

/**
 * Retrieve the menus that are associated with a specific barcode
 * @param {String} barcode - Scanned barcode
 * @param {Function} onSuccess - Anonymous function to call on API success
 * @param {Function} onFailure - Anonymous function to call on API failure
 */
export const fetchSelfCheckoutMenusBarcode = (barcode, venueUuid, onStart, onSuccess, onFailure) => {
  onStart?.()

  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    apiFetchSelfCheckoutMenusBarcode(jwt, barcode, venueUuid).then(response => {
      // api call returns a single menu
      // need to set this menu to VNMenu
      if (response.data.menu?.uuid) {
        const fullMenu = makeVNMenu(response.data)
        dispatch({
          type: ActionTypes.VNMENU_SET_MENU,
          menu: fullMenu
        })
      }

      onSuccess?.(response)
    }).catch(error => {
      console.log(error)
      onFailure?.(error)
    })
  }
}

/**
 * Retreive a self checkout menu from the stand and venue UUIDs.
 * @param {String} standMenuUuid - The UUID of the stand menu
 * @param {String} venueUuid - The UUID of the venue
 * @returns
 */
export const fetchSelfCheckoutMenusStand = (standMenuUuid, venueUuid, onStart, onSuccess, onFailure) => {
  onStart?.()

  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    apiFetchSelfCheckoutMenusStand(jwt, standMenuUuid, venueUuid).then(response => {
      // need to set fetched menu to VNMenu
      // so that it can populate correct cartProperties to show cart information
      // in VNScannerModal
      const fullMenu = makeVNMenu(response.data)
      dispatch({
        type: ActionTypes.VNMENU_SET_MENU,
        menu: fullMenu
      })

      onSuccess?.(response)
    }).catch(error => {
      console.log(error)
      onFailure?.(error)
    })
  }
}

/**
 * Get the full menu and stand data that a particular menu item UUID belongs to. As of now currently only
 * utilized from Rich Checkout cross-venue support within the same ecosystem.
 * @param {String} menuItemUuid - The menu item UUID
 * @returns
 */
export const fetchStandAndMenuFromMenuItem = (menuItemUuid, onStart, onSuccess, onFailure) => {
  onStart?.()

  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    dispatch(setApiLoadingStatus(
      fetchStandAndMenuFromMenuItem.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiFetchMenuAndStandFromItemUuuid(jwt, menuItemUuid).then(response => {

      let keyedStands = {}

      response.data.stand.forEach(element => {
        keyedStands[element.uuid] = element
      })

      dispatch({
        type: VNREVENUECENTERS_SET_CROSS_VENUE_STAND,
        stands: keyedStands
      })

      dispatch({
        type: ActionTypes.VNMENU_SET_CROSS_VENUE_MENU,
        data: response.data
      })

      dispatch(setApiLoadingStatus(
        fetchStandAndMenuFromMenuItem.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))

      onSuccess?.(response.data)
    }).catch(error => {
      console.log(error)

      dispatch(setApiLoadingStatus(
        fetchStandAndMenuFromMenuItem.name,
        { status: ApiLoadingStatus.FAILED }
      ))

      onFailure?.(error)
    })
  }
}

/**
 *
 * @param {*} item
 */
export const setCartItem = (item) => {
  return (dispatch, getState) => {
    const cart = getCart(getState())
    const items = cart.get('items')

    // if there is an existing item in the cart,
    if (items[item.id]) {
      items[item.id].quantity += item.quantity // int
      for (let i = 0; i < item.quantity; i++) {
        items[item.id].modifiers.push(...item.modifiers) // an array of arrays
      }
    } else {
      items[item.id] = {
        id: item.id,
        quantity: item.quantity,
        priceInCents: item.priceInCents,
        modifiers: []
      }

      for (let i = 0; i < item.quantity; i++) {
        items[item.id].modifiers.push(...item.modifiers) // an array of arrays
      }
    }

    dispatch({
      type: ActionTypes.VNMENU_ADD_CART_ITEMS,
      items: items
    })
  }
}

/**
 *
 * @param {*} menuId
 */
export const setCartMenuId = (menuId) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMENU_SET_CART_MENU_ID,
      menuId: menuId
    })
  }
}

/**
 *
 */
export const cartReset = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMENU_CART_RESET
    })
  }
}

/**
 *
 * @param {*} confirmed
 */
export const setAgeConfirmation = (confirmed) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMENU_SET_AGE_CONFIRMED,
      confirmed: confirmed
    })
  }
}

/**
 *  Set the event filter based on user selection from VNFilter
 * @param {String} filter - The UUID of the event that has been chosen by the user
 * @returns
 */
export const setExperienceEventFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMENU_SET_EXPERIENCE_EVENT_FILTER,
      filter: filter
    })
  }
}

export const setMenuItemSearchText = (searchText) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNMENU_SET_ITEM_SEARCH_TEXT,
      searchText: searchText
    })
  }
}
