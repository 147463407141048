import moment from 'moment-timezone'

/**
 * VNOrderState object that relates API defined order states
 * for all Food and Beverage, Merchandise, and Experience Orders.
 * Provides convenience functions to determine if an order state is
 * currently in Open state or Completed state for VNUserOrders route.
 */
const VNOrderState = {
  DEFAULT: '',
  INCOMPELTE: 'incomplete',
  QUEUED: 'queued',
  SUBMITTING: 'submitting',
  SUBMITTED: 'submitted',
  PROCESSING: 'processing',
  BUMPED: 'bumped',
  ASSIGNED: 'assigned'
  ,
  COMPLETION_PENDING: 'completion_pending',
  COMPLETED: 'completed',
  DELIVERY_FAILED: 'delivery_failed',
  SUBMISSION_FAILED: 'submission_failed',
  AUTHORIZATION_FAILED: 'authorization_failed'
  ,
  SETTLEMENT_FAILED: 'settlement_failed',
  CANCELLED: 'cancelled',
  PROBLEM_REPORTED: 'problem_reported',
  DELIVERED_WITHOUT_ALCOHOL: 'delivered_without_alcohol',
  CLOSED_BY_ADMIN: 'closed_by_admin'
  ,
  CLOSED_WITHOUT_ALCOHOL: 'closed_without_alcohol',
  NOT_COLLECTED: 'not_collected',
  REFUNDED: 'refunded',
  PARTIALLY_REFUNDED: 'partially_refunded',
  REFUND_FAILED: 'refund_failed',
  REDEEMED: 'redeemed'
  ,
  PARTIALLY_REDEEMED: 'partially_redeemed',
  PAYMENT_FAILED: 'payment_failed',
  TRANSFER_ACCEPTED: 'transfer_accepted',
  TRANSFER_INITIATED: 'transfer_initiated',
  TRANSFER_COMPLETED: 'transfer_completed',
  AWARDED: 'awarded',
  /**
   * Determine if a Food and Beverage or Merchandise order
   * is Open.
   * @param {string} orderState - state string value from JSON API Object
   */
  isOpenFnbOrMerchOrder: function(orderState) {
    return orderState === this.INCOMPELTE || orderState === this.QUEUED || orderState === this.SUBMITTING
      || orderState === this.SUBMITTED || orderState === this.PROCESSING || orderState === this.BUMPED
      || orderState === this.ASSIGNED
  },
  /**
   * Determine if a Food and Beverage or Merchandise order
   * is Completed.
   * @param {string} orderState - state string value from JSON API Object
   */
  isCompletedFnbOrMerchOrder: function(orderState) {
    return orderState === this.COMPLETION_PENDING || orderState === this.COMPLETED || orderState === this.REFUNDED
      || orderState === this.PARTIALLY_REFUNDED || orderState === this.DELIVERY_FAILED
      || orderState === this.SUBMISSION_FAILED || orderState === this.CLOSED_BY_ADMIN
      || orderState === this.AUTHORIZATION_FAILED || orderState === this.SETTLEMENT_FAILED
      || orderState === this.DELIVERED_WITHOUT_ALCOHOL
      || orderState === this.PROBLEM_REPORTED || orderState === this.REFUND_FAILED
      || orderState === this.NOT_COLLECTED
  },
  /**
   * Determine if an Experience order is Open.
   * @param {string} orderState - state string value from JSON API Object
   */
  isOpenExperienceOrder: function(orderState) {
    return orderState === this.COMPLETED || orderState === this.PARTIALLY_REDEEMED
  },
  /**
   * Determine if an Experience order is Completed.
   * @param {string} orderState - state string value from JSON API Object
   */
  isCompletedExperienceOrder: function(orderState) {
    return orderState === this.REDEEMED || orderState === this.PAYMENT_FAILED || orderState === this.CANCELLED
      || orderState === this.REFUNDED
  },
  /**
   * Determine if an Experience order is an Awarded or Transferred
   * Experience
   * @param {string} orderState - state string value from JSON API Object
   */
  isAwardedOrTransferredExperienceOrder: function(orderState) {
    return orderState === this.TRANSFER_ACCEPTED || orderState === this.TRANSFER_INITIATED
      || orderState === this.TRANSFER_COMPLETED || orderState === this.AWARDED
  },
  /**
   * Get the chip string value for the Redemption State
   * of an experience: Redeem Soon | Redeem Now | Redeemed | Expired
   * @param {object} order - Experience Order object
   * @param {func} t - React useTranslation() object
   */
  getExperienceRedemptionState: function(order, t) {
    const parentItem = order.items[0]
    const userItems = order.user_items

    const isExpired = moment().isAfter(moment(parentItem.expires_at))

    let redeemCount = 0
    let redemptionState = t('REDEEM_SOON')

    for (var i = 0; i < userItems.length; i++ ) {
      let userItem = userItems[i]

      if (userItem.redeemable) {
        redemptionState = t('REDEEM_NOW')
        redeemCount++
      } else {
        redemptionState = t('REDEEM_SOON')
      }

      if (isExpired) {
        redemptionState = t('EXPIRED')
      }

      if (userItem.used_at !== null) {
        redemptionState = t('REDEEMED')
      }

      let redemptionType = parentItem.redemption_type

      if (redemptionType !== 'countdown_timer') {
        if (redemptionType === 'admin' && isExpired) {
          redemptionState = t('EXPIRED')
        } else {
          redemptionState = null
        }
      }
    }

    if ( redeemCount === 0 || redeemCount === 1) {
      return redemptionState
    } else {
      return `${redeemCount} ${t('REDEEMABLE')}`
    }
  },
  /**
   * Get the Redemption State of an experience: Redeem Soon | Redeem Now | Redeemed | Expired
   * Also denote if order is open or not via redemption state
   * @param {object} order -
   * @param {func} t - React useTranslation() object
   */
  getAwardedTransferredExperienceRedemptionState: function(order, t) {
    const parentItem = order.item
    const isExpired = moment().isAfter(moment(parentItem.expires_at))

    let redemptionState = t('REDEEM_SOON')

    if (order.redeemable) {
      redemptionState = t('REDEEM_NOW')
    } else {
      redemptionState = t('REDEEM_SOON')
    }

    if (isExpired) {
      redemptionState = t('EXPIRED')
    }

    if (order.used_at !== null) {
      redemptionState = t('REDEEMED')
    }

    return {
      isOpen: redemptionState === t('REDEEM_SOON') || redemptionState === t('REDEEM_NOW') ? true : false,
      state: redemptionState
    }
  }
}

export default VNOrderState
