import { combineReducers } from 'redux'
import cart from './cart'
import internationalization from './internationalization'
import menu from './menu'
import menuItem from './menuItem'
import modifierGroup from './modifierGroup'
import modifierProperties from './modifierProperties'
import order from './order'
import orderTotal from './orderTotal'
import stand from './stand'
import user from './user'
import userItem from './experiences/userItem'
import seat from './seat'
import meta from './meta'
import remote from './remote'
import filter from './filter'
import event from './event'
import VNWebAccount from '../VNWebAccount/Reducer'
import VNUser from '../VNUser/Reducer'
import VNConfigurations from '../VNConfigurations/Reducer'
import VNWebSDK from '../VNWebSDK/Reducer'
import VNSnackbarSystem from '../VNSnackbarSystem/Reducer'
import VNBackdropSystem from '../VNBackdropSystem/Reducer'
import VNWallet from '../VNWallet/Reducer'
import VNOrders from '../VNOrders/Reducer'
import VNMenu from '../VNMenu/Reducer'
import VNApiLoadingSystem from '../VNApiLoadingSystem/Reducer'
import VNDialogs from '../VNDialogs/Reducer'
import VNApiLoadingSystemCached from '../VNApiLoadingSystemCached/Reducer'
import VNCountdownTimer from '../VNCountdownTimer/Reducer'
import VNRevenueCenters from '../VNRevenueCenters/Reducer'
import VNQueryParams from '../VNQueryParams/Reducer'
import VNOTP from '../VNOTP/Reducer'
import VNStand from '../VNStand/Reducer'
import VNFilter from '../VNFilter/Reducer'
import VNOrderTotal from '../VNOrderTotal/Reducer'

export default combineReducers({
  cart,
  internationalization,
  menu,
  menuItem,
  modifierGroup,
  modifierProperties,
  order,
  orderTotal,
  stand,
  user,
  userItem,
  seat,
  meta,
  remote,
  filter,
  event,
  VNWebAccount,
  VNConfigurations,
  VNUser,
  VNWebSDK,
  VNSnackbarSystem,
  VNBackdropSystem,
  VNWallet,
  VNOrders,
  VNDialogs,
  VNApiLoadingSystemCached,
  VNMenu,
  VNApiLoadingSystem,
  VNCountdownTimer,
  VNRevenueCenters,
  VNQueryParams,
  VNOTP,
  VNStand,
  VNFilter,
  VNOrderTotal,
})
