import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    boxShadow: '0 -5px 25px -15px #666666',
    position: "sticky",
    zIndex: 100,
    bottom: 0,
    background: theme.custom.colors.white,
    marginLeft: '-22px',
    marginRight: '-22px',
    marginTop: 'auto'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    background: theme.custom.colors.accent,
    width: '100%',
    borderRadius: 6,
    height: 41
  },
  buttonText: {
    fontFamily: theme.custom.font,
    fontSize: theme.custom.button2.fontSize,
    fontWeight: theme.custom.button2.fontWeight,
    lineHeight: theme.custom.button2.lineHeight,
    color: theme.custom.button2.color
  },
  disabled: {
    backgroundColor: theme.custom.colors.disabledGrey
  }
}))

/**
 * Sticky bottom button that is typically used to confirm a single action
 * on a page such as 'Done' or 'Save'. In order for this to properly utilize
 * sticky, the parent container of this component must utilize display: flex
 * and flex-direction: column
 * @param {String} props.text - Text to dispaly in the button
 * @param {Function} props.onClick - Function to call when button is pressed
 */
export const VNBottomStickyButton = (props) => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const theme = useTheme()

  const { t } = useTranslation()

  return (
    <Box className={classes.root} p={3}>
      <ButtonBase
        focusRipple
        disabled={props.disabled}
        key="save-event-button"
        className={classes.button}
        classes={{ disabled: classes.disabled }}
        onClick={props.onClick ? props.onClick : () => {}}
      >
        <Box className={classes.buttonContainer} ml={1} mr={1}>
          <Typography className={classes.buttonText}>{props.text}</Typography>
        </Box>
      </ButtonBase>
    </Box>
  )
}
