import React, { useEffect, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { hasTimeElapsed, timeLeft } from '../../VNUtil/VNTimeHelper'
import { setSnackbarSystemDataAlertError } from '../../VNSnackbarSystem/ActionCreators'
import { getRefreshAffiliationsTimer } from '../../VNConfigurations/Selectors'
import { disableBackdropSystem, enableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import { ApiLoadingStatus } from '../../VNApiLoadingSystem/types/ApiLoadingStatus'
import { UserInfo } from '../components/UserInfo'
import { WalletOptions } from '../components/WalletOptions'
import { getCopyright, getExchangeServices } from '../../VNConfigurations/Selectors'
import { getAwardsRules } from '../Selectors'
import { getUser, getHighestRankedAffiliation, getLastTimeAffiliationsRefreshed } from '../../VNUser/Selectors'
import { getPaymentUpdatesDialogOpen } from '../../VNDialogs/Selectors'
import { getUserPaymentMethods, getUserViewedPaymentReminder, getCachedRoute } from '../../VNUser/Selectors'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import { getWebSDKMode, getWebSDKVersion } from '../../VNWebSDK/Selectors'
import { retrieveAllWallet, retrieveLoyaltyUser } from '../ActionCreators'
import { setOpenPaymentUpdatesDialog } from '../../VNDialogs/ActionCreators'
import { logoutUser, refreshUsersAffiliations, setViewedPaymentReminder } from '../../VNUser/ActionCreators'
import { clearOrderTotal } from '../ActionCreators'
import { clearCart } from '../../actions/cart'
import { clearOrderError } from '../../actions/order'
import { setPromoCodes, clearStandAvailability } from '../../VNOrders/ActionCreators'
import { getVaultedCards } from '../../VNWebAccount/Selectors'
import { retrieveVaultedCardsByUser } from '../../VNWebAccount/ActionCreators'
import { resetLastOrderTotalUuidAndMenuUuid } from '../../VNOrderTotal/ActionCreators'

import { VNBadge } from '../../VNDialogs/components/VNBadge'
import { VNGiftCardTransfer } from '../../VNDialogs/components/VNGiftCardTransfer'
import { VNDialog } from '../../VNComponents/VNDialog'
import { VNSettingsList } from '../../VNWebDrawer/components/VNSettingsList' // using the same one that we have in the drawer
import { VNWalletUserInfo } from '../components/VNWalletUserInfo'
import { VNExchangeService } from './VNExchangeService'
import { VNCircleButton } from '../../VNComponents/VNCircleButton'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  bottomHalf: {
    backgroundColor: theme.custom.colors.backgroundGrey,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  bottomLinks: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  virtualCurrency: {
    display: 'flex',
    paddingBottom: 0
  },
  dialogTitle: {
    '& h2': theme.typography.h1
  }
}))

export const VNWallet = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const theme = useTheme()
  const history = useHistory()

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const copyright = useSelector(state => getCopyright(state))
  const highestRankedAffiliation = useSelector(state => getHighestRankedAffiliation(state))
  const awardsRules = useSelector(state => getAwardsRules(state))
  const paymentUpdatesDialogOpen = useSelector(state => getPaymentUpdatesDialogOpen(state))
  const userPaymentMethods = useSelector(state => getUserPaymentMethods(state))
  const viewedPaymentReminder = useSelector(state => getUserViewedPaymentReminder(state))
  const walletApiLoadingStatus = useSelector(state => getLoadingSystemStatus(state, retrieveAllWallet.name))
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const cachedRoute = useSelector(state => getCachedRoute(state))
  const exchangeServices = useSelector(state => getExchangeServices(state))
  const lastTimeUserAffiliationsRefreshed = useSelector(state => getLastTimeAffiliationsRefreshed(state))
  const refreshUsersAffiliationsTime = useSelector(state => getRefreshAffiliationsTimer(state))
  const vaultedCards = useSelector(state => getVaultedCards(state))

  // LOCAL STATE
  const [openDialog, setOpenDialog] = useState(false)
  const [rootStyle, setRootStyle] = useState({})

  // USE EFFECTS

  useEffect(() => {
    if(user.get('provider') !== 'vn_anonymous') {
      setRootStyle({
        backgroundColor: theme.custom.colors.backgroundGrey
      })
    }else {
      setRootStyle({})
    }
    
  }, [])

  useEffect(() => {
    if (history?.location?.state?.openPaymentsDialog && !paymentUpdatesDialogOpen) {
      dispatch(setOpenPaymentUpdatesDialog(true))
    }
  }, [])

  useEffect(() => {
    // Only reset cart if there is no cached route because we want to preserve
    // cart contents if the user is logging in and we want to return them to their
    // point of entry
    if (!cachedRoute) {
      resetCart()
    }
    document.title = t('WALLET')
  }, [])

  // fetch API's on load
  useEffect(() => {
    if (!user.isEmpty() && user.get('provider') !== 'vn_anonymous' && walletApiLoadingStatus.status === ApiLoadingStatus.UNKNOWN) {
      dispatch(retrieveAllWallet())
      dispatch(retrieveVaultedCardsByUser())
    }
  }, [user, dispatch, walletApiLoadingStatus.status])

  // check to see if we need to show the payment reminder to add a credit card to use Scan and Pay
  useEffect(() => {
    if (walletApiLoadingStatus.status === ApiLoadingStatus.SUCCEEDED &&
      userPaymentMethods.isEmpty() &&
      (vaultedCards && !vaultedCards.size) &&
      !paymentUpdatesDialogOpen &&
      !viewedPaymentReminder
    ) {
      setOpenDialog(true)
    }
  }, [setOpenDialog, paymentUpdatesDialogOpen, userPaymentMethods, viewedPaymentReminder, walletApiLoadingStatus.status, vaultedCards])

  const displayUserInfo = () => {
    if (!user.isEmpty() && user.get('provider') !== 'vn_anonymous') {
      return <VNWalletUserInfo highestRankedAffiliation={highestRankedAffiliation}/>
    }

    return (
      <Box pl={3} pb={3}>
        <UserInfo highestRankedAffiliation={highestRankedAffiliation} hasSignOut={false} />
      </Box>
    )
  }

  /**
   * called by the add credit card payment reminder, user has chosen to add a card
   */
  const handleOnDialogAddCard = () => {
    setOpenDialog(false)
    dispatch(setViewedPaymentReminder())
    dispatch(setOpenPaymentUpdatesDialog(true))
  }

  /**
   * called by the add credit card payment reminder, user has chosen to ignore
   */
  const handleOnDialogCancel = () => {
    setOpenDialog(false)
    dispatch(setViewedPaymentReminder())
  }

  /**
   * When user lands on wallet, clear any transactions that may be in place
   */
  const resetCart = () => {
    dispatch(clearCart())
    dispatch(clearOrderError())
    dispatch(setPromoCodes([]))
    dispatch(clearStandAvailability())
    dispatch(clearOrderTotal())
    dispatch(resetLastOrderTotalUuidAndMenuUuid())
  }

  const onSignout = (event) => {
    handleAnySelection(event)
    dispatch(logoutUser())
    history.push('/')
  }

  // called when any list item has been clicked
  const handleAnySelection = (event) => {
    if (event) {
      event.preventDefault()
    }
  }

  /**
   * It should fetch loyalty user and user affiliations
  */
  const onRefreshButtonClicked = () => {

    dispatch(enableBackdropSystem())

    dispatch(retrieveLoyaltyUser())

    // check current timestamp epoch, if its been more than X minutes, refresh user affiliations
    if (hasTimeElapsed(lastTimeUserAffiliationsRefreshed, refreshUsersAffiliationsTime, 'minutes')) {
      dispatch(refreshUsersAffiliations(t))
    } else {
      // a fake timeout to just make it look like its doing something.
      setTimeout(() => {
        dispatch(disableBackdropSystem())
        // use 1 as the lowest number for minute
        const timeRemaining = timeLeft(lastTimeUserAffiliationsRefreshed, refreshUsersAffiliationsTime, 'minutes') || 1
        // dispatch(setSnackbarSystemDataAlertInfo(t('AFFILIATIONS_UPDATED')))
        dispatch(setSnackbarSystemDataAlertError(t('AFFILIATIONS_NOT_REFRESHABLE') + " " + timeRemaining + " " + t('MINUTES')))
      }, 1000)
    }
  }

  const displayLastUpdated = () => {
    if(user.get('provider') === 'vn_anonymous') {
      return 
    }

    let lastupdatedText = `${t('LAST_UPDATED')} `
    if (lastTimeUserAffiliationsRefreshed) {
      const generatedTime = moment(lastTimeUserAffiliationsRefreshed)
      lastupdatedText = `${lastupdatedText}${generatedTime.format('MM/DD/YY')} ${t('AT')} ${generatedTime.format('h:mma')}`
    }


    return (
      <Box className={classes.qrCodeRefresh} onClick={onRefreshButtonClicked} style={{flexDirection: 'row', display: 'flex'}}>
        <Typography style={{paddingRight: 10}}>{lastupdatedText}</Typography>
        <VNCircleButton
          className={classes.circleButton}
          icon={<RefreshIcon style={{'fontSize': '18px'}} />}
        />
      </Box>
    )
  }

  return (
    <Box className={classes.root} style={rootStyle}>
      {displayUserInfo()}
      <Box className={classes.bottomHalf}>
        <WalletOptions awardsRules={awardsRules} />
        <Box pl={3} pt={3} className={classes.bottomLinks}>
          <VNSettingsList hasSignout={user.get('provider') !== 'vn_anonymous'} onSignout={onSignout}/>
          <Box pt={4}>
            <Box pb={2}>
              {displayLastUpdated()}
              <Typography style={theme.typography.subtitle1}>© {moment().year()} {copyright}</Typography>
              {webSDKMode && <Typography style={theme.typography.subtitle1}>v{webSDKVersion}</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
      <VNBadge highestRankedAffiliation={highestRankedAffiliation} />
      {exchangeServices.map((element, index) => {
        return <VNExchangeService provider={element} key={index}/>
      })}
      <VNGiftCardTransfer />
      <VNDialog
        open={openDialog}
        title={t('ENHANCE_EXPERIENCE')}
        subTitle={t('ADD_CREDIT_CARD')}
        titleClass={classes.dialogTitle}
        save={t('ADD_CARD')}
        cancel={t('MAYBE_LATER')}
        onSave={handleOnDialogAddCard}
        onClose={handleOnDialogCancel}
        />
    </Box>
  )
}
