import axios from 'axios'
import * as CONFIG from '../App/configurations/Configs'
import { mockApiFetchMenu } from './Mock'
import { BASE_URL, ORG_NAME, VENUE_UUID } from '../constants'

/**
 * Fetch the details about a specific menu
 * @param {*} token - The JWT Token
 * @param {*} menuId - The menu ID that you want to get details about
 */
export const apiFetchMenu = (token, menuId) => {
  if (CONFIG.DATA.MOCK.VN_REVENUE_CENTERS_apiFetchAllRevenueCenters) {
    return mockApiFetchMenu()
  }

  return axios.get(`${BASE_URL}/menu/${menuId}?organization_name=${ORG_NAME}&venue_uuid=${VENUE_UUID}`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}

/**
 * Fetch menu(s) that a particular barcode belongs to. Can return multiple
 * menus or a singular menu with all of its content
 * @param {string} token - The JWT Token
 * @param {string} barcode - Barcodee of item to fetch
 * @param {string} venueUuid - Venue UUID needed to search
 */
 export const apiFetchSelfCheckoutMenusBarcode = (token, barcode, venueUuid) => {
  return axios.get(`${BASE_URL}/self_checkout_menus?barcode=${barcode}&venue_uuid=${venueUuid}&organization_name=${ORG_NAME}`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}

/**
 * Fetch menu for a particular stand menu uuid
 * @param {string} token - The JWT Token
 * @param {string} standMenuUuid - Stand menu uuid to retrieve based off of barcode item
 * @param {string} venueUuid - Venue UUID needed to search
 */
export const apiFetchSelfCheckoutMenusStand = (token, standMenuUuid, venueUuid) => {
  return axios.get(`${BASE_URL}/self_checkout_menus?stand_menu_uuid=${standMenuUuid}&venue_uuid=${venueUuid}&organization_name=${ORG_NAME}`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}

/**
 * Fetch the menu and stand data that a particular menuItemUuid belongs to. This is as of now
 * currently only utilized for Rich Checkout for cross-venue support in the same VN ecosystem.
 * @param {*} token - The JWT Token
 * @param {*} menuItemUuid - The menu item UUID that you want to retrieve a menu and stand data for
 * @returns
 */
export const apiFetchMenuAndStandFromItemUuuid = (token, menuItemUuid) => {
  return axios.get(`${BASE_URL}/menu/item/${menuItemUuid}?organization_name=${ORG_NAME}&include_stand_data=true`, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}
