/* eslint-disable no-use-before-define */
const pidgeon = require('../pidgeon/index')
const PIDGEON_KEYS = require('../pidgeon/keys')
const components = require('../components/index')
const styles = require('../styles/index')
const langaugeUtils = require('../languages/utils')
const svgs = require('../svgs/index')
const constants = require('../constants')
const { vnReplaceChildren } = require('../utils/dom')
const mode = constants.mode
const cardUI = constants.cardUI

// the language that was configured in the drop in.
let language
let currentMode
let currentStatus
let root
let cards
let selectedCard
let deletedCardSelected

let fieldsValid = {}

let currentCardUIState = cardUI.DEFAULT

const reset = () => {
  deletedCardSelected = null
  currentCardUIState = cardUI.DEFAULT
}

const getCardIcon = (cardType) => {
  let cardIcon
  switch (cardType) {
    case 'visa':
      cardIcon = svgs.visaIcon()
      break
    case 'amex':
    case 'american-express':
      cardIcon = svgs.amexIcon()
      break
    case 'discover':
      cardIcon = svgs.discoverIcon()
      break
    case 'mastercard':
      cardIcon = svgs.mastercardIcon()
      break
    default:
      cardIcon = null
  }
  return cardIcon
}

const inValidateSecureFields = (payload) => {
  Object.keys(payload.errorResponse).forEach((key) => {
    const secureFieldDiv = document.getElementById(`vni4go__securefield__${key}`)
    if (secureFieldDiv) {
      secureFieldDiv.style.border = `2px solid ${styles.COLORS.alert}`

      const textOuterShell = document.getElementById(`vn_card_${key}`)
      if (textOuterShell) {

        textOuterShell.style['margin-left'] = '14px'
        textOuterShell.style.removeProperty('padding-top')
        textOuterShell.style.removeProperty('padding-left')

        const spanText = textOuterShell.firstChild
        if (spanText) {
          spanText.style['font-size'] = '9px'
          spanText.style.color = styles.COLORS.alert
        }
      }
    }
  })
}

const displayCreditCardType = (cardTypes) => {
  const secureFieldDiv = document.getElementById(`vni4go__securefield__number`)
  const CC_ICON_ID = 'vn_cc_icon'
  const ele = document.getElementById(CC_ICON_ID)

  if (cardTypes.options.length === 1) {
    const cardIcon = getCardIcon(cardTypes.options[0].type)
    if (cardIcon && !ele) {
      const div = components.createElement('div', CC_ICON_ID, {'position': 'absolute', 'pointer-events': 'none', 'right': '10px'})
      cardIcon.style.width = '34px'
      cardIcon.style['margin-left'] = '230px'
      cardIcon.style['margin-top'] = '12px'
      div.appendChild(cardIcon)
      secureFieldDiv.prepend(div)
    }
  } else {
    if (ele) {
      ele.parentNode.removeChild(ele)
    }
  }
}

const updateWithNoPaint = (secureFieldFocused) => {
  // used specifically for secure fields
  if (secureFieldFocused.focus === 'in') {
    const secureFieldDiv = document.getElementById(`vni4go__securefield__${secureFieldFocused.type}`)
    if (secureFieldDiv) {
      secureFieldDiv.style.border = `2px solid ${styles.COLORS.blue}`

      const textOuterShell = document.getElementById(`vn_card_${secureFieldFocused.type}`)
      if (textOuterShell) {
        textOuterShell.style['margin-left'] = '14px'
        textOuterShell.style.removeProperty('padding-top')
        textOuterShell.style.removeProperty('padding-left')

        const spanText = textOuterShell.firstChild
        if (spanText) {
          spanText.style['font-size'] = '9px'
          spanText.style.color = styles.COLORS.blue
        }
      }
    }
  } else {

    // the focus is leaving the text input

    const secureFieldDiv = document.getElementById(`vni4go__securefield__${secureFieldFocused.type}`)
    if (secureFieldDiv) {

      // set if the field is valid or not
      fieldsValid[secureFieldFocused.type] = secureFieldFocused.valid

      // need to check if the input passed is validator or not
      if (!secureFieldFocused.valid) {
        secureFieldDiv.style.border = `2px solid ${styles.COLORS.alert}`
      } else {
        secureFieldDiv.style.border = `1px solid ${styles.COLORS.buttonBorderGrey}`
      }

      if (secureFieldFocused.type === 'number') {

        const textOuterShell = document.getElementById('vn_card_number')

        if (textOuterShell) {

          // if there is text that has been typed in
          if (secureFieldFocused.hasValue) {

            const spanText = textOuterShell.firstChild

            if (spanText && secureFieldFocused.valid) {
              spanText.style.color = styles.COLORS.grey
            } else if (spanText && !secureFieldFocused.valid) {
              spanText.style.color = styles.COLORS.alert
            }

          } else {
            textOuterShell.style['padding-top'] = '13px'
            textOuterShell.style['padding-left'] = '14px'
            textOuterShell.style.removeProperty('margin-left')

            const spanText = textOuterShell.firstChild
            if (spanText) {
              spanText.style['font-size'] = '16px'
              spanText.style.color = styles.COLORS.grey
            }
          }
        }
      }
    }
  }
}

const createCardInputFields = () => {
  const currentView = components.createElement('div')

  let numberFieldStyleOverride = {
    'margin-bottom': '16px'
  }

  // create the title
  const ccTitle = components.createTypography(null, langaugeUtils.getLanguageFile(language).add_a_card, { 'padding-bottom': '16px', 'padding-top': '16px' }, 'subtitle1')
  currentView.appendChild(ccTitle)

  const numberField = components.createNumberField(numberFieldStyleOverride)

  const outerStyle = {
    'position': 'absolute',
    'padding-top': '13px',
    'padding-left': '14px',
    'pointer-events': 'none'
  }

  const numberText = components.createTypography('vn_card_number', langaugeUtils.getLanguageFile(language).card_number, outerStyle, 'subtitle2')
  numberField.appendChild(numberText)

  const rowView = components.createElement('div', null, {
    'display': 'flex',
    'flex-direction': 'row'
  })

  let expDateFieldStyleOverride = {
    'margin-bottom': '16px',
    'margin-right': '16px'
  }

  const expDateField = components.createExpDateField(expDateFieldStyleOverride)
  const expDateText = components.createTypography('vn_card_exp', langaugeUtils.getLanguageFile(language).exp_month_year, outerStyle, 'subtitle2')
  expDateField.appendChild(expDateText)

  const cvvField = components.createCVVField()
  const cvvText = components.createTypography('vn_card_cvv', langaugeUtils.getLanguageFile(language).cvv_code, outerStyle, 'subtitle2')
  cvvField.appendChild(cvvText)

  rowView.appendChild(expDateField)
  rowView.appendChild(cvvField)

  currentView.appendChild(numberField)
  currentView.appendChild(rowView)

  // set all the validation to be false
  fieldsValid.number = false
  fieldsValid.exp = false
  fieldsValid.cvv = false

  return currentView
}

const createBaseCard = (deleteMode = false) => {

  let style = {
    'border-radius': '6px',
    'height': '48px',
    'display': 'flex',
    'align-items': 'center',
    'background-color': deleteMode ? styles.COLORS.alert : styles.COLORS.lightGrey
  }

  if (!deleteMode) {
    style.border = `1px solid ${styles.COLORS.buttonBorderGrey}`
  }

  return components.createElement('div', null, style)
}

const createCard = (id, cardType, cardNumber) => {
  const outer = components.createElement('div', null, { 'padding-bottom': '16px' })
  const card = createBaseCard(deletedCardSelected === id)
  outer.appendChild(card)

  let cardIcon

  const onSelectCard = () => {
    console.log(`On Click: ${id}`)
    selectedCard = id
    reset()
    paint(true)
    pidgeon.send(PIDGEON_KEYS.CARD_UPDATE, {
      id: id,
      status: 'SELECTED'
    })
  }

  cardIcon = getCardIcon(cardType)

  if (currentCardUIState === cardUI.DELETE_CARD) {
    const subtractButton = svgs.subtractIcon()
    subtractButton.addEventListener('click', () => {
      console.log(`On Cancel Delete`)
      reset()
      paint()
      pidgeon.send(PIDGEON_KEYS.CHANGE_CARD_UI, {
        state: cardUI.DEFAULT
      })
    })
    card.appendChild(subtractButton)
  } else {
    if (selectedCard === id) {
      const radioChecked = svgs.radioButtonCheckedIcon()
      card.appendChild(radioChecked)
    } else {
      const radioUnchecked = svgs.radioButtonUncheckedIcon()
      radioUnchecked.addEventListener('click', onSelectCard)
      card.appendChild(radioUnchecked)
    }
  }

  const cardDetails = components.createElement('div', null, { 'display': 'flex', 'justify-content': 'center', 'flex-grow': '1' })
  if (cardIcon) {
    cardIcon.style.width = '34px'
    cardIcon.style['padding-right'] = '8px'
    cardDetails.appendChild(cardIcon)
  }

  const typography = components.createTypography(null, cardNumber, null, selectedCard === id ? 'subtitle3' : 'subtitle2', deletedCardSelected === id ? { color: styles.COLORS.lightGrey } : null)
  cardDetails.appendChild(typography)

  if (currentCardUIState !== cardUI.DELETE_CARD) {
    cardDetails.addEventListener('click', onSelectCard)
  }

  card.appendChild(cardDetails)

  if (currentMode === mode.EDIT && currentCardUIState === cardUI.DEFAULT) {
    const deleteButton = svgs.cancelIcon()
    deleteButton.addEventListener('click', () => {
      console.log('Delete a Card Mode Activated')
      deletedCardSelected = id
      currentCardUIState = cardUI.DELETE_CARD
      paint()
      pidgeon.send(PIDGEON_KEYS.CHANGE_CARD_UI, {
        status: cardUI.DELETE_CARD
      })
    })

    card.appendChild(deleteButton)
  }

  if (currentCardUIState === cardUI.DELETE_CARD) {
    const deleteButton = svgs.deleteIcon()
    deleteButton.addEventListener('click', () => {
      console.log(`Delete this card: ${id}`)
      reset()
      paint()
      pidgeon.send(PIDGEON_KEYS.CARD_UPDATE, {
        id: id,
        status: 'DELETED'
      })

      pidgeon.send(PIDGEON_KEYS.CHANGE_CARD_UI, {
        status: cardUI.DEFAULT
      })
    })

    card.appendChild(deleteButton)
  }

  // if we show either of these, then we want to justify the content.
  if (currentMode === mode.EDIT) {
    card.style['justify-content'] = 'space-between'
  }

  card.style['padding-right'] = '14px'
  card.style['padding-left'] = '14px'

  return outer
}

const createAddACard = () => {
  const card = createBaseCard()

  if (currentCardUIState === cardUI.DEFAULT) {
    card.addEventListener('click', () => {
      console.log('Add a Credit Card Clicked')
      currentCardUIState = cardUI.ADD_CARD
      paint()
      pidgeon.send(PIDGEON_KEYS.CHANGE_CARD_UI, {
        status: cardUI.ADD_CARD
      })
    })
  }

  const plusSvg = svgs.plusIcon()
  plusSvg.style.padding = '14px'
  plusSvg.style.position = 'absolute'

  const typography = components.createTypography(null, langaugeUtils.getLanguageFile(language).add_a_credit_card, { 'flex-grow': 1, 'text-align': 'center '}, 'subtitle2')

  card.appendChild(plusSvg)
  card.appendChild(typography)

  return card
}

const paint = (ignoreDefault = false) => {

  let displayCardInputFields = false

  const subDiv = components.createElement('div')

  if (cards && cards.length > 0) {
    cards.forEach(card => {

      if (!ignoreDefault && card.default && card.default === true) {
        selectedCard = card.uniqueId
      }

      const tempCard = createCard(card.uniqueId, card.type, card.lastFour)

      subDiv.appendChild(tempCard)
    })

    if (currentCardUIState !== cardUI.ADD_CARD) {
      // show the add a card button so a user can add more credit cards to their account
      subDiv.appendChild(createAddACard())
    }
  } else {
    displayCardInputFields = true
  }

  if (displayCardInputFields || currentCardUIState === cardUI.ADD_CARD) {
    subDiv.appendChild(components.createHorizontalDivider())

    // show the card input fields
    subDiv.appendChild(createCardInputFields())
  }

  vnReplaceChildren(root, subDiv)
}

const overrideCardUIState = (state) => {
  currentCardUIState = state
}

const getCardUIStatus = () => {
  return currentCardUIState
}

const displayError = (pDisplay, message) => {

  const ERROR_ID = 'vn_error_display'
  const ele = document.getElementById(ERROR_ID)

  if (pDisplay) {

    const title = components.createTypography(null, message, null, 'alert')

    if (ele) {
      vnReplaceChildren(ele, title)
    } else {
      const div = components.createElement('div', ERROR_ID)
      div.appendChild(title)
      root.appendChild(div)
    }
  } else {
    if (ele) {
      ele.parentNode.removeChild(ele)
    }
  }
}

const display = (pCards, pStatus, pMode, pLanguage) => {
  language = pLanguage
  currentMode = pMode
  currentStatus = pStatus
  cards = pCards
  root = components.createElement('div')

  pidgeon.addReceiver(PIDGEON_KEYS.SECURE_FIELD_FOCUS, (payload) => {
    updateWithNoPaint(payload)
    displayError(false)
  })

  pidgeon.addReceiver(PIDGEON_KEYS.SECURE_FIELD_NOT_VALID, (payload) => {
    inValidateSecureFields(payload)
    if (payload.key && payload.key === 'DECLINED') {
      displayError(true, langaugeUtils.getLanguageFile(language).card_declined)
    }
  })

  pidgeon.addReceiver(PIDGEON_KEYS.CARD_TYPE_DETERMINATION, (payload) => {
    displayCreditCardType(payload)
  })

  selectedCard = null

  paint()

  return root
}

module.exports = {
  display: display,
  overrideCardUIState: overrideCardUIState,
  getCardUIStatus: getCardUIStatus
}
