import * as ActionTypes from './ActionTypes'

export function enableBackdropSystem() {
  return (dispatch) => {

    dispatch({
      type: ActionTypes.VNBACKDROPSYSTEM_SET,
      enabled: true
    })
  }
}

export function disableBackdropSystem() {
  return (dispatch) => {

    dispatch({
      type: ActionTypes.VNBACKDROPSYSTEM_SET,
      enabled: false
    })
  }
}