import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'
import { ORG_ENV } from '../constants'

const getInitialState = () => {

  const init = {}
  init[`${ORG_ENV}_public_runtime`] = {
    accent_color: '#794bc4' // VN Default
  }

  return Map({
    configuration: Map(init), 
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VN_SET_CONFIGURATION:
      return state.set('configuration', Map(action.configuration))
    default:
      return state
  }
}

export default reducer
