import VNOrderState from './types/VNOrderState'
import { List } from 'immutable'

export const getSeats = (state) => {
  return state.VNWebAccount.get('seats')
}

export const getPass = (state) => {
  return state.VNWebAccount.get('pass')
}

export const getOrderHistory = (state) => {
  return state.VNWebAccount.get('order_history')
}

export const getExperienceOrderHistory = (state) => {
  return state.VNWebAccount.get('experience_order_history')
}

/**
 * Get only open orders from order history.
 * @param {state} state - The state
 * @param {object} t - The translation object
 */
export const getOpenOrdersFromHistory = (state, t) => {
  const orders = state.VNWebAccount.get('order_history')

  if (!orders.isEmpty()) {
    return List(orders.get('data').filter((order) => {
      if (order.type === 'order') {
        if (VNOrderState.isOpenFnbOrMerchOrder(order.attributes.state)) {
          return order
        }
      } else if (order.product_type === 'Experience') {
        if (VNOrderState.isOpenExperienceOrder(order.state) && VNOrderState.getExperienceRedemptionState(order, t) !== t('EXPIRED')
        ) {
          return order
        }
      } else if (VNOrderState.isAwardedOrTransferredExperienceOrder(order.state)) {
        if (VNOrderState.getAwardedTransferredExperienceRedemptionState(order, t).isOpen) {
          return order
        }
      }
      return null
    }))
  }
  return List([])
}

/**
 * Get only completed orders from order history.
 * @param {state} state - The state
 * @param {object} t - The translaton object
 */
export const getCompletedOrdersFromHistory = (state, t) => {
  const orders = state.VNWebAccount.get('order_history')

  if (!orders.isEmpty()) {
    return List(orders.get('data').filter((order) => {
      if (order.type === 'order') {
        if (VNOrderState.isCompletedFnbOrMerchOrder(order.attributes.state)) {
          return order
        }
      } else if (order.product_type === 'Experience') {
        if (VNOrderState.isCompletedExperienceOrder(order.state) || VNOrderState.getExperienceRedemptionState(order, t) === t('EXPIRED')) {
          return order
        }
      } else if (VNOrderState.isAwardedOrTransferredExperienceOrder(order.state)) {
        if (!VNOrderState.getAwardedTransferredExperienceRedemptionState(order, t).isOpen) {
          return order
        }
      }
      return null
    }))
  }
  return List([])
}

export const getBraintreeClientToken = (state) => {
  return state.VNWebAccount.get('braintree_token')
}

export const getShift4Token = (state) => {
  return state.VNWebAccount.get('shift4_token')
}

export const getShift4LatestTokenization = (state) => {
  return state.VNWebAccount.get('shift4_tokenization')
}

export const getVaultedCards = (state) => {
  return state.VNWebAccount.get('vaulted_cards')
}
