import React from 'react'
import classNames from 'classnames'

import './QuantitySelector.scss'

const QuantitySelector = ({ onIncrement, onDecrement, min, max, value, loading, className }) => {
  const disableDecrement = value <= min
  const disableIncrement = value >= max
  const mergedClassName = classNames('quantity-field', className)
  const numberMergedClassName = classNames('quantity', className)
  return (
    <div className={mergedClassName}>
      <button
        className="decrement"
        disabled={disableDecrement || loading}
        onClick={onDecrement}
      ></button>
      <span className={numberMergedClassName}>{value}</span>
      <button
        className="increment"
        disabled={disableIncrement || loading}
        onClick={onIncrement}
      ></button>
    </div>
  )
}

QuantitySelector.defaultProps = {
  onIncrement: () => {},
  onDecrement: () => {},
  min: 1,
  max: 100,
  value: 1,
  loading: false,
  className: '',

}

export default QuantitySelector
