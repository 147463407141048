import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from "@material-ui/core/Button"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { VNDialogList } from './VNDialogList'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 18,
  },
}));

/**
 *
 * This looks like a select input, but instead of a drop down it displays
 * a dialog popover with a list of options.
 *
 * @param {array} options - flat list of what to populate the dialog list with
 * @param {string} default - what is the initial name we will populate in the select
 * @param {string} optionPrepend - prepend selected option text
 * @param {Boolean} nonInteractive - determines if the user can interact with this button
 *
 */
export const VNSelectWithDialog = (props) => {

  const classes = useStyles();

  // the state for the dialog and the selected option
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(props.default)

  // when the button is clicked
  const handleClick = () => {
    // If no options are pressed, treat this as a button
    if (props.options === undefined) {
      props.onSelection(null)
      return
    }
    setDialogOpen(true)
  };

  // when the dialog list option is selected
  const handleDialogClick = (option) => {
    setDialogOpen(false)
    if (option === 'noop') { return }
    setSelectedOption(option)
    props.onSelection(option)
  }

  const renderSelectedOptionText = () => {
    if (props.placeholder && !selectedOption) {
      return props.placeholder
    }
    if (props.optionPrepend) {
      return `${props.optionPrepend} ${selectedOption ? selectedOption : props.default}`
    }
    return `${props.default ? props.default : selectedOption}`
  }

  return (
    <Fragment>
      <Button
        className={classes.root}
        variant="outlined"
        onClick={!props.nonInteractive ? handleClick : null}
        endIcon={props.options && !props.nonInteractive ? <ExpandMoreIcon /> : null}
      >
        {renderSelectedOptionText()}
      </Button>
      <VNDialogList items={props.options} open={dialogOpen} click={handleDialogClick} />
    </Fragment>
  )
}
