/**
 * Decode a JWT and receive the JSON data
 * @param {String} token - The token value
 * @returns {Object} - The parsed JSON object from the JWT.
 */
const jwtDecode = (token) => {
  const base64Data = token.split('.')[1]
  const decodedValue = atob(base64Data)
  const json = JSON.parse(decodedValue)
  return json
}

export default jwtDecode
