import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { Typography } from '@material-ui/core'
import { VNFullScreenCloseButton } from '../../VNComponents/VNFullScreenCloseButton'
import { getPaymentUpdatesDialogOpen } from '../Selectors'
import { setOpenPaymentUpdatesDialog } from '../ActionCreators'
import { VNBraintree } from '../../VNWallet/components/VNBraintree'
import { VNVirtualCurrencyEdit } from '../../VNWallet/components/VNVirtualCurrencyEdit'
import { sendSDKModalOpen } from '../../VNWebSDK/bridgeCalls/VNWebSDKDataSend'
import { getWebSDKVersion } from '../../VNWebSDK/Selectors'
import { VNShift4 } from '../../VNWallet/components/VNShift4'
import { getPaymentsProvider } from '../../VNConfigurations/Selectors'
import { toTitleCase } from '../../VNUtil/StringHelpers'
import { VNButton } from '../../VNComponents/VNButton'
import { enableBackdropSystem, disableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'
import { setSnackbarSystemDataAlertError } from '../../VNSnackbarSystem/ActionCreators'
import { refreshUsersAffiliations } from '../../VNUser/ActionCreators'
import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'
import { apiFetchAllRevenueCenters } from '../../VNRevenueCenters/Api'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import { preferredCardHolderAward } from '../../VNWallet/ActionCreators'

import semver from 'semver'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelIcon: {
    height: 40,
    width: 40,
    color: theme.custom.colors.black,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
});

/**
 * A users saved payment methods
 * Uses both virtual currency and braintree
 */
export const VNUserPayments = () => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const shift4Ref = useRef()

  // do we show the save card button for Shift4 credit card inputs or not.
  const [showSaveCardButton, setShowSaveCardButton] = useState(false)

  // SELECTORS
  const open = useSelector(state => getPaymentUpdatesDialogOpen(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  const paymentProvider = useSelector(state => getPaymentsProvider(state))
  const revenueCenterAPILoadingStatus = useSelector(state => getLoadingSystemStatus(state, apiFetchAllRevenueCenters.name))

  // HANDLES
  const onClose = (event) => {
    dispatch(setOpenPaymentUpdatesDialog(false))
    document.title = t('WALLET')
  }

  useEffect(() => {
    if (webSDKVersion && semver.gte(webSDKVersion, '2.0.4')) {
      sendSDKModalOpen({ 'payments': open ? 'yes' : 'no' })
    }
    if (open){
      document.title = t('MY_PAYMENTS')
    }
  }, [open, webSDKVersion])

  /**
   * Which payment provider do we want to display? Braintree or Shift4
   * @returns
   */
   const displayPaymentProvider = () => {
     if (revenueCenterAPILoadingStatus.status === ApiLoadingStatus.SUCCEEDED) {
      if (paymentProvider === 'braintree') {
        return <VNBraintree useAddCardButton />
      } else if (paymentProvider === 'shift4') {
        return <VNShift4  ref={shift4Ref}
                          displayApplePay={false}
                          eventsCallback={ (payload) => {
                            if (payload.event === 'SECURE_FIELD_NOT_VALID') {
                              dispatch(disableBackdropSystem())
                            } else if (payload.event === 'CHANGE_CARD_UI' && payload.status === 'ADD_CARD') {
                              setShowSaveCardButton(true)
                            } else if (payload.event === 'VAULT_CARD_UPDATED') {
                              setShowSaveCardButton(false)
                              dispatch(refreshUsersAffiliations(t))
                            } else if (payload.event === 'VAULT_CARD_VAULTED') {
                              setShowSaveCardButton(false)
                              dispatch(preferredCardHolderAward(payload.card?.bin)) // TODO: replace this with webhook
                              dispatch(refreshUsersAffiliations(t))
                            } else if (payload.event === 'CARD_UPDATE') {
                              dispatch(refreshUsersAffiliations(t))
                            } else if (payload.event === 'VAULT_RETRIEVED_ALL_CARDS') {
                              if (payload.cards.length <= 0) {
                                setShowSaveCardButton(true)
                              }
                            }
                          }} />
      }
    }
  }

  /**
   * This is the click handler for when a user clicks the save card button and we start the Shift4 Tokenization process
   */
  const handleShift4PaymentStorage = () => {
    if (shift4Ref.current) {
      dispatch(enableBackdropSystem())
      shift4Ref.current.getTokenization().then((payload) => {
        dispatch(disableBackdropSystem())
      }).catch(e => {
        dispatch(disableBackdropSystem())
        dispatch(setSnackbarSystemDataAlertError(e.message))
      })
    }
  }

  /**
   * Displays the shift4 add card button or not depending on if its ready to show it or not.
   * @returns {React Component} Box with a VNButton
   */
  const displayShift4AddCardButton = () => {
    if (paymentProvider === 'shift4' && showSaveCardButton) {
      return (
        <Box pt={3} pb={3}>
          <VNButton
            text={toTitleCase(t('SAVE_CARD'))}
            onClick={handleShift4PaymentStorage} />
        </Box>
      )
    }
  }

  /**
   * Display Credit Card drop in from braintree
   */
  const displayCreditCards = () => {
    return (
      <Box>
        <Box pb={1}>
          <Typography variant="h1">{t('CREDIT_CARDS')}</Typography>
        </Box>
        {displayPaymentProvider()}
        {displayShift4AddCardButton()}
      </Box>
    )
  }


  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Box className={classes.root}>
        <Box pt={3} pr={3} pl={3} className={classes.top}>
          <Box></Box>
          <VNFullScreenCloseButton onClose={onClose} className={classes.cancelIcon}/>
        </Box>
        <Box pl={3} pr={3}>
          <VNVirtualCurrencyEdit />
          {displayCreditCards()}
        </Box>
      </Box>
    </Dialog>
  )
}
