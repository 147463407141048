import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import queryString from 'query-string'

import { setProductTypeSelected, setServiceTypeSelected } from '../ActionCreators'
import { retrieveAllWallet } from '../../VNWallet/ActionCreators'
import { setOpenExchangeServiceDialog } from '../../VNDialogs/ActionCreators'

import { getUser } from '../../VNUser/Selectors'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import { getBackdropSystemEnabled } from '../../VNBackdropSystem/Selectors'

import { ApiLoadingStatus } from '../../VNApiLoadingSystem/types/ApiLoadingStatus'

/**
 *
 */
export const VNQueryParams = (props) => {

  const dispatch = useDispatch()

  const location = useLocation()

  // SELECTORS

  const user = useSelector(state => getUser(state))
  const walletApiLoadingStatus = useSelector(state => getLoadingSystemStatus(state, retrieveAllWallet.name))
  const backdropSystemEnabled = useSelector(state => getBackdropSystemEnabled(state))

  // LOCAL STATE
  const [openDialogFromQueryString, setopenDialogFromQueryString] = useState(false)

  // USE EFFECTS

  /**
   *
   */
  useEffect(() => {
    let searchString = location.search

    // VNQueryParams will be mounted BEFORE VNRedirectUser
    // VNQueryParams will NOT be mounted again even VNRedirectUser changed history to redirect
    // we need to handle this special case when user is visiting OW through url generated by VNAPI redirect system
    // example - original searchString: ?redirect=?productType=Food
    //                new searchString: productType=Food
    if (location.pathname === '/redirect-user' && searchString?.includes('=?')) {
      searchString = searchString.split('=?')[1]

      if (!searchString) {
        return
      }
    }

    const parsed = queryString.parse(searchString)

    if (parsed) {

      // check for productType
      if (parsed['productType'] && (parsed['productType'] === 'Experience' ||
                                    parsed['productType'] === 'Food' ||
                                    parsed['productType'] === 'Merchandise')) {
        dispatch(setProductTypeSelected(parsed['productType']))
      }

      // check for serviceType
      if (parsed['serviceType'] && (parsed['serviceType'] === 'Delivery' ||
                                    parsed['serviceType'] === 'Pickup')) {

        // hardcoding pickup to concessions since we want to allow for the word Pickup in the
        // queryParams but the serviceType coming from the API is set to Concessions
        if (parsed['serviceType'] === 'Pickup') {
          dispatch(setServiceTypeSelected('Concessions'))
        } else {
          if (parsed['serviceType']) {
            dispatch(setServiceTypeSelected(parsed['serviceType']))
          }
        }
      }
    }

  }, [])

  // if showExchangeServiceDialog is provided in url query string parameters
  // attempt to open exchange service dialog automatically
  useEffect(() => {
    const { showExchangeServiceDialog } = queryString.parse(location.search, { arrayFormat: 'comma' })

    if ((showExchangeServiceDialog === 1 || showExchangeServiceDialog === '1') && !openDialogFromQueryString) {
      const provider = user?.get('provider')

      if ((walletApiLoadingStatus.status === ApiLoadingStatus.SUCCEEDED || walletApiLoadingStatus.status === ApiLoadingStatus.FAILED) && !backdropSystemEnabled && provider && provider !== 'vn_anonymous') {       
        setopenDialogFromQueryString(true)
        dispatch(setOpenExchangeServiceDialog({ open: true, provider: provider }))
      }
    }
  }, [openDialogFromQueryString, user, walletApiLoadingStatus.status, backdropSystemEnabled, location.search])

  return (<span></span>)
}
