import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from "@material-ui/core/styles"
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
    backgroudColor: props => props.backgroudColor
  },
}));

/**
 * An icon with a piece of text centered below it
 * @params
 * Required
 * text: String - The text to display beneath the icon
 * icon: <ICON> - The icon to display above the text
 *
 * Optional
 * click: func - Called when the user clicks on the container that contains both the icon and text
 */
export const VNIconButton = (props) => {

  const classes = useStyles();

  // HANDLES

  // Called when the user clicks the icon or text
  const handleClick = () => {
    if (props.click) {
      props.click()
    }
  }

  return (
    <Box className={props.classes ? props.classes.root : classes.root} onClick={handleClick}>
      {props.icon}
      <Typography style={props.textStyle ? props.textStyle : null}>
        {props.text}
      </Typography>
    </Box>
  )
}
