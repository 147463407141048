import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { getVirtualCurrencyEnabled, getVirtualCurrencyName, getAccentColor } from '../../VNConfigurations/Selectors'
import { useTranslation } from 'react-i18next'
import getContrastYIQ from '../../VNUtil/Color'
import Money from '../../utils/money'
import { getWebSDKMode, getWebSDKAccentColor, getWebSDKBackgroundColor } from '../../VNWebSDK/Selectors'
import { Button, Typography } from '@material-ui/core'
import { history } from '../../App/store/configureStore'
import { getLoyaltyUser } from '../Selectors'
import clsx from 'clsx'
import { VNUserInfo } from './VNUserInfo';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 24,
    paddingTop: 32,
    margin: 15,
    marginBottom: 0,
    borderRadius: 10,
    flexDirection: 'row',
    display: 'flex'
  },
  virtualCurrency: {
    paddingBottom: 0
  },
  circleButton: {
    width: 20,
    backgroundColor: '#FFFFFF !important'
  },
  balanceContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    height: 'fit-content',
    alignSelf: 'flex-end'
  }
}))

/**
 *
 */
export const VNWalletUserInfo = ({highestRankedAffiliation, ...props}) => {

  const classes = useStyles()

  const theme = useTheme()

  const { t } = useTranslation()

  // SELECTORS
  const virtualCurrencyName = useSelector(state => getVirtualCurrencyName(state))
  const isVirtualCurrencyEnabled = useSelector(state => getVirtualCurrencyEnabled(state))
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKAccentColor = useSelector(state => getWebSDKAccentColor(state))
  const accentColor = useSelector(state => getAccentColor(state))
  const loyaltyUser = useSelector(state => getLoyaltyUser(state))
  const webSDKBgColor = useSelector(state => getWebSDKBackgroundColor(state))

  // USE EFFECTS

  const modeBackgroundColor = () => {
    if (webSDKMode && history.location.pathname === '/wallet') {
      return webSDKBgColor
    } else if (!webSDKMode && history.location.pathname === '/wallet') {
      return accentColor
    }

    return 'initial'
  }

  const getModeStyle = () => {
    return {
      backgroundColor: modeBackgroundColor()
    }
  }

  const getModeVirtualCurrencyBalanceStyle = () => {
    return {
      ...theme.custom.h8,
      color: webSDKMode ? webSDKAccentColor : getContrastYIQ(accentColor)
    }
  }

  const balanceButtonStyle = () => {
    return {
      ...theme.custom.masterButton,
      color: webSDKMode ? webSDKAccentColor : getPathBasedColor(),
      borderColor: webSDKMode ? webSDKAccentColor : getPathBasedColor()
    }
  }

  const getModeVirtualCurrencyNameStyle = () => {
    return {
      ...theme.typography.h4,
      color: webSDKMode ? webSDKAccentColor : getContrastYIQ(accentColor)
    }
  }

  const getPathBasedColor = () => {
    const isWallet = history.location.pathname === '/wallet'
    return isWallet ? getContrastYIQ(accentColor) : null
  }

  const onBalanceHistoryClick = () => {
    history.push('/wallet/virtual-currency/activity')
  }

  /**
   * Display Virtual Currency
   */
   const displayVirtualCurrency = () => {
    if (isVirtualCurrencyEnabled && loyaltyUser.has('balance')) {
      return (
        <Box className={classes.virtualCurrency} pb={3}>
          <Typography style={getModeVirtualCurrencyNameStyle()}>{virtualCurrencyName}</Typography>
          <Box pr={1}>
            <Typography style={getModeVirtualCurrencyBalanceStyle()}>{Money.centsToDollars(loyaltyUser.get('balance'), true, false)}</Typography>
          </Box>
        </Box>
      )
    }
  }

  const displayVNWalletData = () => {
    if(!isVirtualCurrencyEnabled) {
      return
    }

    return (
      <Box className={classes.root} style={getModeStyle()} >
        {displayVirtualCurrency()}
        <Box
          className={classes.balanceContainer}
        >
          <Button
            classes={{
              label: clsx(theme.typography.h5)
            }} 
            style={balanceButtonStyle()}
            onClick={onBalanceHistoryClick}
            variant='outlined'
            >{t('BALANCE_HISTORY')}</Button>
        </Box>
      </Box>
    )
  }
  
  return (
    <Box>
      <VNUserInfo alternateStyle={isVirtualCurrencyEnabled} highestRankedAffiliation={highestRankedAffiliation}/>
      {displayVNWalletData()}
    </Box>
  )
}
