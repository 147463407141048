import {
  REDEEM_USER_ITEM_SUCCEEDED,
  REDEEM_USER_ITEM_FAILED,
  RETRIEVE_USER_ITEM_SUCCEEDED
} from '../../actions/experiences/userItem'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case REDEEM_USER_ITEM_SUCCEEDED:
      // Returns a 204. redemptionSuccess is storing the a faux redeemedAt
      // so we don't have to make a fetch order call after this.
      return {
        ...state,
        redemptionSuccess: { [action.id]: Date.now() },
      }

    case REDEEM_USER_ITEM_FAILED:
      return {
        ...state,
        redemptionFailure: { [action.id]: action.payload }
      }

    case RETRIEVE_USER_ITEM_SUCCEEDED:
      return {
        ...state,
        [action.id]: {
          ...action.payload,
          item: {
            ...action.payload.item,
            // Need to copy the global item data back into the item
            // because regular experience orders are structured like this
            global_item: action.payload.global_item
          }
        }
      }

    default:
      return state
  }
}

export default reducer
