import get from 'lodash/get'

export const getUserLocation = (state) => {
  let userLocation = get(state, 'user.location', {})

  return userLocation
}

/**
 * Whether or not the seat selection that the user has chosen
 * is valid
 * @param {Object} state - The state
 * @returns
 */
export const getIsSeatValid = (state) => get(state, 'user.location.valid', true)
