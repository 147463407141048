import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : theme.custom.colors.accent,
    borderRadius: '4px',
    marginTop: 8,
    marginLeft: props => props.marginLeft ? props.marginLeft : 16
  },
  text: {
    textAlign: 'center',
    padding: '2px 8px',
    color: props => props.color ? props.color : theme.custom.button2.color,
    whiteSpace: 'nowrap'
  }
}))

/**
 * Class for custom chip instead of using Material UI. Plan is to add expandable
 * animation with expandable content when time allows.
 * @param {object} props
 * @param {string} props.backgroundColor - Background color of chip
 * @param {string} props.color - Color of text in chip
 * @returns
 */
export const VNExpandableChip = (props) => {

  const classes = useStyles(props)

  return (
    <Box className={classes.root}>
      <Typography className={classes.text} variant="body2">
        {props.text}
      </Typography>
    </Box>
  )
}

export default VNExpandableChip
