import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from '@material-ui/core/InputLabel'

import { isString } from 'lodash'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

/**
 * A select input that drops down a menu for the user to choose from
 * @params
 * Required
 * inputs: [] - An array of objects with a val and a name.
 *
 * Optional
 * default: String - The value of one of the inputs to be the one that is default selected
 */
export const VNSelectInput = (props) => {

  const classes = useStyles();

  // LOCAL STATE

  // The value that is selected
  const [v, setValue] = React.useState(props.default ? props.default : props.inputs[0]?.val);

  useEffect(() => {
    // if the outside default value changes, we want to update the internal selected option
    if (props.default !== v) {
      setValue(props.default)
    }
  }, [props])

  //  HANDLES

  // Called when a drop down item is selected
  const handleChange = (event) => {
    setValue(event.target.value)

    if (props.onSelection) {
      props.onSelection(event.target.value)
    }
  }

  const displayItems = () => {
    if (Array.isArray(props.inputs)) {
      return props.inputs.map((item, index) => (<option key={index} value={item}>{item}</option>))
    } else {
      return props.inputs.map((item, index) => (<MenuItem key={index} value={item.val}>{item.name}</MenuItem>))
    }
  }

  return (
    <FormControl variant={props.variant} className={classes.formControl}>
      <InputLabel>{props.title}</InputLabel>
      <Select
        native
        label={props.title}
        value={v}
        onChange={handleChange}
      >
        {displayItems()}
      </Select>
    </FormControl>
  )
}
