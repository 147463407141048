import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, isPlainObject, keys } from 'lodash'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import Modal from '../components/Modal'
import NavigationBar from '../components/NavigationBar'
import Section from '../components/Section'

import { makeGetOrder } from '../selectors/order'

import TimeZone from '../utils/timezone'

import './ExperienceMetadata.scss'

export class ExperienceMetadata extends Component {
  constructor(props) {
    super(props)

    this.modalRef = React.createRef()
  }

  closeModal = () => {
    const closeModal = get(this, 'modalRef.current.closeModal', () => {})

    closeModal()
  }

  onClickEdit = (userItemId, metadataId) => {
    const { order } = this.props

    this.props.history.replace(`/edit-metadata/${order.id}/${userItemId}/${metadataId}`)
  }

  renderHeader() {
    const { item, userItem, t } = this.props
    const userTimeZone = TimeZone.current()
    const formattedEventDate = moment(userItem.eventDate).tz(userTimeZone).format('MMMM Do, YYYY')
    const countdownTime = get(item, 'globalItem.countdownSeconds', undefined)
    const instructions = `${t('WHEN_YOU_ARE_READY_TO_REDEEM')} ${!countdownTime ? t('CONFIRMATION_SCREEN') : `${countdownTime} ${t('SECOND_CONFIRMATION_SCREEN')}`}.`

    return (
      <>
        <Section className="experience-metadata-header">
          <h2>{item.name}</h2>
          <p>{formattedEventDate}</p>
        </Section>
        <Section className="experience-metadata-instructions">
          <h3 className="title">{t('HOW_DO_I_REDEEM')}?</h3>
          <p className="content">{instructions}</p>
        </Section>
      </>
    )
  }

  renderMetadata() {
    const { userItem, t } = this.props

    if (isEmpty(userItem.metadata)) return null

    return userItem.metadata.map((metadata) => {
      let valueElement = null
      let value = ''

      if (metadata.dataType === 'age') value = moment(metadata.value).format('MMMM Do, YYYY')
      if (metadata.dataType !== 'age') value = metadata.value

      if (isPlainObject(value)) {
        valueElement = (
          <p className="multi-value-field">
            {keys(value).map((key) => (<span key={key}><span className="label">{`${key}:`}</span><span className="value">{value[key]}</span></span>))}
          </p>
        )
      } else {
        valueElement = <p className="field">{value}</p>
      }

      return (
      <Section key={metadata.id}>
        <h3>{metadata.name}</h3>
        {valueElement}
        <button className="edit" onClick={() => this.onClickEdit(userItem.id, metadata.id)}>{t('EDIT')}</button>
      </Section>
    )})
  }

  render() {
    return (
      <Modal
        ref={this.modalRef}
        onCloseModal={() => this.props.history.goBack()}
        className={classNames('experience-metadata-modal')}
      >
        <NavigationBar.Close text="" right transparent twoToned onClick={this.closeModal} />
        {this.renderHeader()}
        {this.renderMetadata()}
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const orderId = get(ownProps, 'match.params.orderId', '')
  const userItemId = get(ownProps, 'match.params.userItemId', '')
  const getOrder = makeGetOrder()

  const order = getOrder(state, orderId)
  const userItem = get(order, 'userItems', []).find((userItem) => userItem.id === userItemId)
  const itemId = get(userItem, 'itemId', '')
  const item = get(order, 'items', []).find((item) => item.id === itemId)

  return {
    order,
    userItem,
    item,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExperienceMetadata))
