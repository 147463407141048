import axios from 'axios'
import * as CONFIG from '../App/configurations/Configs'
import { mockOrderCompleted } from './Mock'
import { BASE_URL, ORG_ENV, ORG_NAME } from '../constants'

export const apiGetCachedPaymentInfo = (url) => {
  return axios.get(url)
}

export const apiGetPaymentToken = (standId, orderUuid, amount, currencyCode, jwt, org) => {
  let endpoint
  if (ORG_ENV === 'dev') {
    endpoint = `https://${org}.principal.dev.api.vndev.net/shift4/preauthorize?stand=${standId}&orderNumber=${orderUuid}&amount=${amount}&currencyCode=${currencyCode}`
  } else if (ORG_ENV === 'prd') {
    endpoint = `https://${org}.principal.prd.api.vnops.net/shift4/preauthorize?stand=${standId}&orderNumber=${orderUuid}&amount=${amount}&currencyCode=${currencyCode}`
  }

  return axios.get(endpoint,
    {
      headers: {
        Authorization: "Bearer " + jwt
      }
    }
  )
}

export const apiPlaceOrder = (orderData, token) => {
  if (CONFIG.DATA.MOCK.VN_HOME_apiPlaceOrder) {
    return mockOrderCompleted()
  } else {
    return axios.post(`${BASE_URL}/orders?organization_name=${ORG_NAME}`, orderData, {
      headers: {
        "Authorization" : "Bearer " + token
      }
    })
  }
}
