import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const SPINNER_SIZE = '50px'
const SPINNER_INNNER_CIRCLE = '40px'
const SPINNER_RADIUS = '80%'
const SPINNER_FILL_RADIUS = '90px'
const SPINNER_COLOR = '#1463F5'
const SPINNER_BG = '#EAF2FF'
const SPINNER_INNER_BG = '#ffffff'

const useStyles = makeStyles(theme => ({
  spinnerBox: {
    position: 'relative',
    backgroundColor: '#ffffff'
  },
  spinnerContainer: {
    height: '60px',
    width: '60px',
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    border: '1px solid #EBEDEF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinner: {
    borderRadius: SPINNER_RADIUS,
    display: 'block',
    height: SPINNER_SIZE,
    width: SPINNER_SIZE,
    position: 'relative',
    animation: `$spin .875s linear 0s infinite normal`,
    background: SPINNER_BG,

    '&::before, &::after': {
      content: `""`,
      display: 'block',
      position: 'absolute'
    },

    '&::before': {
      borderRadius: `0 ${SPINNER_FILL_RADIUS} ${SPINNER_FILL_RADIUS} 0`,
      height: SPINNER_SIZE,
      width: '50%',
      top: 0,
      right: 0,
      zIndex: 1,
      background: `linear-gradient(rgba(234, 242, 255, 0) 0%, rgba(20, 245, 164, 0.5) 35%, ${SPINNER_COLOR}) 100%`
    },

    '&::after': {
      borderRadius: SPINNER_RADIUS,
      height: SPINNER_INNNER_CIRCLE,
      width: SPINNER_INNNER_CIRCLE,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
      background: SPINNER_INNER_BG
    }
  },
  '@keyframes spin': {
    'to': {
      transform: 'rotate(360deg)'
    }
  }
}));

const VNSpinner = () => {
  const classes = useStyles()

  return (
    <Box className={classes.spinnerBox}>
      <div className={classes.spinnerContainer}>
        <span className={classes.spinner}></span>
      </div>
    </Box>
  )
}

export default VNSpinner
