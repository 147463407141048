import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import loading from '../../images/loading.svg';
import { createOrGetUser } from '../../VNUser/ActionCreators'
import { history } from '../../App/store/configureStore'
import { getUser } from '../../VNUser/Selectors'
import { getRedirectUserUuid, getRedirectUserIntegration, getRedirectUserEmail } from '../../VNConfigurations/Selectors'
import { useTranslation } from 'react-i18next'
import { getLoadingSystemStatus } from '../../VNApiLoadingSystem/Selectors'
import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'

const queryString = require('query-string');

/**
 * used to get or create a user that is from our VNAPI redirect system
 */
export const VNRedirectUser = () => {

  const { t } = useTranslation()

  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => getUser(state))
  const redirectUserUuid = useSelector(state => getRedirectUserUuid(state))
  const redirectUserEmail = useSelector(state => getRedirectUserEmail(state))
  const redirectIntegrationPartner = useSelector(state => getRedirectUserIntegration(state))
  const createOrGetUserApiStatus = useSelector(state => getLoadingSystemStatus(state, createOrGetUser.name))

  const [hasRedirected, setHasRedirected] = useState(false)
  const [simplelyRedirect, setSimplelyRedirect] = useState(false)

  // used to parse
  useEffect(() => {
    // parse the query string back from the redirect user system
    // make a call into the VN API to create or get the user
    if (  (createOrGetUserApiStatus.status === ApiLoadingStatus.UNKNOWN || createOrGetUserApiStatus.status === ApiLoadingStatus.SUCCEEDED) &&
          (user.isEmpty() || user.get('provider') === 'vn_anonymous') &&
          redirectUserEmail && redirectUserUuid )
    {
      const parsed = queryString.parse(location.search)
      if (parsed) {

        let userObj = {}

        if (parsed[redirectUserUuid] || parsed[redirectUserEmail]) {
          if (parsed[redirectUserUuid]) {
            userObj.id = parsed[redirectUserUuid]
          }
  
          if (parsed[redirectUserEmail]) {
            userObj.email = parsed[redirectUserEmail]
          }
  
          dispatch(createOrGetUser(null, redirectIntegrationPartner, userObj, t))

        } else {
          // no credentials found in query string, just redirect without createOrGetUser
          setSimplelyRedirect(true)
        }
      }
    }
  }, [user, createOrGetUserApiStatus, redirectUserEmail, redirectUserUuid])

  // history change
  useEffect(() => {

    console.log(`Location.search:  ${location.search}`)

    const parsed = queryString.parse(location.search)

    let rebuiltQS = ''

    for (const key in parsed) {
      if (key !== 'redirect') {

        // test key for spaces and put them back
        const newVal = parsed[key].split(' ').join('+')

        rebuiltQS += `&${key}=${newVal}`
      }
    }

    // check to see if redirect has a ? in the url, becuase if it does, then we want
    // to attach the rebuiltQS with &
    // if we don't have one, then the first one needs to be a ?
    const getFinalUrl = (redirectUrl, queryStrings, fallbackUrl) => {

      if (!redirectUrl) {
        return fallbackUrl
      }

      if (redirectUrl.includes('?')) {
        return `/${redirectUrl}${queryStrings}`
      }

      // strip the front & and replace it with a ?
      queryStrings = queryStrings.substring(1)

      queryStrings = `?${queryStrings}`

      return `/${redirectUrl}${queryStrings}`

    }

    // the call failed or no credentials found in query string, let the anonymous token work fine for them
    if ((createOrGetUserApiStatus.status === ApiLoadingStatus.FAILED || simplelyRedirect) && !hasRedirected) {
      if (simplelyRedirect) {
        setSimplelyRedirect(false)
      }
      setHasRedirected(true)
      history.push(getFinalUrl(parsed['redirect'], rebuiltQS, '/'))
      return
    }

    if (!user.isEmpty() && user.get('provider') !== 'vn_anonymous' && !hasRedirected) {
      setHasRedirected(true)
      history.push(getFinalUrl(parsed['redirect'], rebuiltQS, '/wallet'))
    }
  })

  const style = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  }

  return (
    <div style={style}>
      <img src={loading} alt="loading"/>
    </div>
  )

}
