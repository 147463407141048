import { call, put, all, select, takeLatest } from 'redux-saga/effects'

import Remote from '../remote'
import {
  REMOVE_ITEM_FROM_CART, REMOVE_EXPERIENCE_ITEM_FROM_CART,
  EDIT_QUANTITY_IN_CART, EDIT_EXPERIENCE_QUANTITY_IN_CART,
  EDIT_VARIANT_QUANTITY_IN_CART
} from '../actions/cart'
import { REFRESH_ORDER_TOTAL_REQUESTED } from '../actions/orderTotal'
import { setLoading, setSucceeded, setFailed } from '../actions/remote'
import { getCartMenuItems } from '../selectors/cart'
import { getLatestOrderTotal, getLatestOrderNumber } from '../selectors/orderTotal'
import { orderTotalMapper } from '../mappers/orderTotal'
import { experienceOrderTotalMapper } from '../mappers/experiences/orderTotal'
import { getUser } from '../VNUser/Selectors'
import { getPromoCodes } from '../VNOrders/Selectors'
import { apiGetUserPaymentMethods } from '../VNUser/Api'
import { getUserCardAffiliations } from '../VNUser/Selectors'
import { getPaymentsProvider } from '../VNConfigurations/Selectors'
import { getVirtualCurrencyPromotions } from '../VNWallet/Selectors'
import { getLastOrderTotalUuid, getLastOrderTotaMenulUuid } from '../VNOrderTotal/Selectors'
import { isEmpty } from 'lodash'
import { validate as uuidValidate } from 'uuid'

import {
  experienceOrderTotalSucceeded,
  experienceOrderTotalFailed,
  EXPERIENCE_ORDER_TOTAL_REQUESTED,
  orderTotalSucceeded,
  orderTotalFailed,
  ORDER_TOTAL_REQUESTED,
} from '../actions/orderTotal'

export function* orderTotal(params) {
  const items = yield select(state => getCartMenuItems(state))
  const menuId = yield select(state => state.cart.menuId)
  let existingOrderTotal = yield select(getLatestOrderTotal)
  const existingOrderNumber = yield select(getLatestOrderNumber)
  const paymentsProvider = yield select(getPaymentsProvider)
  const lastOrderTotalUuid = yield select(state => getLastOrderTotalUuid(state))
  const lastOrderTotalMenuUuid = yield select(state => getLastOrderTotaMenulUuid(state))

  if (params.isRefresh) {
    // If isRefresh, existing attrs should be null so that we request a new
    // order uuid
    existingOrderTotal = null
  }

  // Map items. If Rich checkout order total exists, use the params needed
  let orderTotalParams = orderTotalMapper(items, menuId, existingOrderTotal, null, lastOrderTotalUuid, lastOrderTotalMenuUuid, params.isRefresh)

  // get promo codes entered by user
  const promoCodes = yield select(state => getPromoCodes(state))

  // These are passed in individually primarily from the scanner, only set these
  // if there is no rich checkout because we don't want to override them.
  if (isEmpty(existingOrderTotal)) {
    if (params?.payload?.qr_code) {
      orderTotalParams.qr_code = params.payload.qr_code
    }
    if (params?.payload?.order_number) {
      orderTotalParams.order_number = params.payload.order_number
    }
    if (params?.payload?.source_device_uuid) {
      orderTotalParams.source_device_uuid = params.payload.source_device_uuid
    }
  } else {
    let orderNumber = existingOrderTotal?.attributes?.orderNumber || existingOrderNumber
    let existingPromoCodes = []

    // look to see if there were any auto promotions added
    if (existingOrderTotal?.attributes?.promotions.length > 0) {

      existingOrderTotal.attributes.promotions.forEach(existingPromo => {

        // if we had an auto applied promotion, make sure it stays on there.
        if (existingPromo.redemptionMethod === "auto") {
          existingPromoCodes.push({ uuid: existingPromo.uuid })
        }
      })
    }

    orderTotalParams.promotions = existingPromoCodes

    orderTotalParams = {
      ...orderTotalParams,
      ...orderNumber ? { order_number: orderNumber } : {},
    }
  }

  const endpoint = Remote.endpoints.orderTotal
  yield put(setLoading(endpoint))
  const user = yield select(getUser)
  const token = user.get('jwt')

  // add promo codes to the order total call
  if (!promoCodes.isEmpty()) {
    if (!orderTotalParams.promotions) {
      orderTotalParams.promotions = []
    }

    for (let code of promoCodes.values()) {
      // only add the promo code if its unique, do not add dupes
      if (!orderTotalParams.promotions.some(p => p.promo_code?.toUpperCase() === code.toUpperCase())) {
        orderTotalParams.promotions.push({
          promo_code: code
        })
      }
    }
  }

  // add virtual currency promotions
  const vcPromotions = yield select(state => getVirtualCurrencyPromotions(state))

  if (!vcPromotions.isEmpty()) {
    if (!orderTotalParams.promotions) {
      orderTotalParams.promotions = []
    }

    for (const promotion of vcPromotions.values()) {
      if (promotion.id && uuidValidate(promotion.id)) {
        orderTotalParams.promotions.push({ uuid: promotion.id })
      }
    }
  }

  // we only want to use affiliations on the order total, in the query params if its from braintree
  // all the new affiliations, including PCH, is on the JWT only.
  if (paymentsProvider === 'braintree') {
    try {
      yield call(apiGetUserPaymentMethods, user.get('userID'), token)
    } catch(err) {
      // Leaving this catch unimplemented as 404 codes mean no payment methods
      // found for this user
    }

    const cardAffiliations = yield select(state => getUserCardAffiliations(state))

    if (!isEmpty(cardAffiliations)) {
      orderTotalParams.affiliations = cardAffiliations
    }
  }

  try {
    const result = yield call(Remote.orderTotal, orderTotalParams, token)
    yield all([
      put(orderTotalSucceeded(result)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(orderTotalFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* experienceOrderTotal() {
  const items = yield select(state => getCartMenuItems(state))
  let orderTotalParams = experienceOrderTotalMapper(items)

  const endpoint = Remote.endpoints.experienceOrderTotal
  yield put(setLoading(endpoint))
  const user = yield select(getUser);
  const token = user.get('jwt')

  // add promo codes to the order total call
  const promoCodes = yield select(state => getPromoCodes(state))

  if (!promoCodes.isEmpty()) {
    orderTotalParams.promotions = []
    for (let code of promoCodes.values()) {
      orderTotalParams.promotions.push({
        promo_code: code
      })
    }
  }

  try {
    const result = yield call(Remote.experienceOrderTotal, orderTotalParams, token)

    yield all([
      put(experienceOrderTotalSucceeded(result)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(experienceOrderTotalFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchExperienceOrderTotal() {
  yield takeLatest([EXPERIENCE_ORDER_TOTAL_REQUESTED, REMOVE_EXPERIENCE_ITEM_FROM_CART, EDIT_EXPERIENCE_QUANTITY_IN_CART], experienceOrderTotal)
}

export function* watchOrderTotal() {
  yield takeLatest([REMOVE_ITEM_FROM_CART, EDIT_QUANTITY_IN_CART, ORDER_TOTAL_REQUESTED, REFRESH_ORDER_TOTAL_REQUESTED, EDIT_VARIANT_QUANTITY_IN_CART], orderTotal)
}
