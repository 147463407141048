import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl'
import { InputLabel } from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  firstOuter: {
    flexBasis: '70%',
  },
  first: {
    borderRadius: '36px 0px 0px 36px',
    height: 59,
  },
  outlinedInputFirst: theme.typography.h4,
  secondOuter: {
    flexBasis: '30%',
  },
  second: {
    borderRadius: '0px 36px 36px 0px',
    backgroundColor: theme.custom.colors.accent,
  },
  outlinedInputButton: theme.custom.button2,
  input: {
    padding: '20px 15px',
  },
  inputLabel: {
    paddingLeft: 28,
  },
  notched: {
    paddingLeft: "30px!important",
  }
}));

/**
 * @param {object} props
 * @param {func} props.validate -
 * @param {func} props.onChange -
 * @param {func} props.onClick -
 * @param {boolean} props.resetInputOnClick
 * @param {string} props.type -
 * @param {string} props.name -
 * @param {int} props.labelWidth -
 * @param {string} props.label -
 * @param {string} props.buttonText -
 * @param {string} props.defaultValue -
 */
export const VNTextInputWithButton = forwardRef((props, ref) => {

  const classes = useStyles();

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    getInput() {
      return textValue
    },
    clearInput() {
      setTextValue('')
    },
    // validate the input fields
    validate() {
      if (!props.validate()) {
        setInputError(true)
        return false
      } else {
        setInputError(false)
        return true
      }
    }

  }))

  const inputRef = useRef()

  const [textValue, setTextValue] = useState(props.defaultValue ? props.defaultValue : '')

  // error states for the input fields
  const [inputError, setInputError] = useState(false)

  // when the text input has changed
  const handleChange = (event) => {
    setTextValue(event.target.value)
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  };

  // when the button is clicked, send the value in the text field
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(textValue)
    }

    if (props.resetInputOnClick) {
      setTextValue(props.defaultValue ? props.defaultValue : '')
    }
  };

  return (

    <Box className={classes.root}>
      <FormControl className={classes.firstOuter} variant="outlined" error={inputError}>
      <InputLabel className={clsx(classes.inputLabel, classes.outlinedInputFirst)} htmlFor="outlined-adornment-amount">{props.label}</InputLabel>
        <OutlinedInput
          className={classes.first}
          classes={{notchedOutline: classes.notched, input: clsx(classes.input, classes.outlinedInputFirst)}}
          value={textValue}
          inputRef={inputRef}
          onChange={handleChange}
          spellCheck={false}
          name={props.name ? props.name : null}
          type={props.type ? props.type : 'text'}
          placeholder={props.placeholder ? props.placeholder : ''}
          labelWidth={props.labelWidth}
        />
      </FormControl>
      <FormControl className={classes.secondOuter} variant="outlined">
        <OutlinedInput
          className={clsx(classes.second, classes.outlinedInputButton)}
          classes={{ input: classes.input }}
          onClick={handleClick}
          role={"button"}
          readOnly
          value={props.buttonText}
        />
      </FormControl>
    </Box>
  )
})
