import React, { Fragment } from 'react'
import { useTheme, makeStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import Box from '@material-ui/core/Box'
import ListItemText from "@material-ui/core/ListItemText"
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import VNImageFallback from './VNImageFallback'
import Link from '@material-ui/core/Link'

import VNExpandableChip from '../VNComponents/VNExpandableChip'

const useStyles = makeStyles(theme => ({
  root: {

  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  outer_box: {
    width: "100%"
  },
  inner_box: {
    width: "100%"
  },
  largeImage: {
    width: '100%',
    height: 'auto',
    borderRadius: 8,
    marginTop: 4,
  },
  sideImage: {
    width: 132,
    height: 'auto',
    borderRadius: 8,
    marginTop: 4,
  },
  bottomModifier: {
    display: 'inline-grid',
    width: "100%",
    gridTemplateColumns: 'max-content auto auto',
    '& div:nth-child(2)': {
      '&::before': {
        content: '"|"',
        position: 'absolute',
        marginLeft: -14
      },
      marginLeft: 20,
    },
    '& div:nth-child(3)': {
      justifySelf: 'flex-end'
    }
  },
  chip: {
    height: 20,
  },
  notAvailable: {
    opacity: 0.65,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}));

/**
 * This component can be displayed as a list item that contains a variety of titles, subtitles, images, and sub text.
 *
 * Params
 *
 * Required
 *
 * Optional
 * @param {string} title - The title
 * @param {string} subTitle - The sub title
 * @param {string} bottomModifierText - Modifer text at the bottom of the item
 * @param {string} bottomModifierRightText - Modifier text at the bottom right side of the item
 * @param {string} bottomLinkText - Modifier text at the bottom and to the left of bottomModiferText
 * @param {string} sideModifierText - Text that is on the right side of the list item. You would use this instead of side image
 * @param {string} largeImage - A large image to be displayed above everything
 * @param {string} largeImageAlt - (REQUIRED IF largeImage SET) - The alt text of the large image
 * @param {string} sideImage - A small image on the right side of the list item
 * @param {string} sideImageAlt - (REQUIRED IF sideImage SET) - The alt text for the side image
 * @param {Func} onBottomLinkTextClick - (REQUIRED IF bottomLinkText SET) - Called if hte user clicks the bottomLinkText
 * @param {Func} click - Called if the user clicks the list item
 * @param {object} customTitleStyle - Pass in a style object to apply custom styling to it
 */
export const VNDetailedListItem = (props) => {

  const classes = useStyles()

  const theme = useTheme()

  const displayTitle = () => {
    if (props.title) {
      return (
        <ListItemText
          className={props.customTitleStyle}
          primary={props.title}
          disableTypography
          style={props.titleTextVariant ? props.titleTextVariant : theme.typography.h1}
        />
      )
    }
  }

  const displaySubTitle = () => {
    if (props.subTitle) {
      return (
        <ListItemText
          primary={props.subTitle}
          primaryTypographyProps={
            {
              variant: "subtitle1"
            }
          }
        />
      )
    }
  }

  const displayBottomModifierText = () => {
    if (props.bottomModifierText) {
      return (
        <Box pt={1}>
          <ListItemText
            primary={props.bottomModifierText}
            disableTypography
            style={props.bottomModifierTextVariant ? props.bottomModifierTextVariant : theme.typography.overline}
          />
        </Box>
      )
    }
  }

  const displaySecondaryBottomModifierText = () => {
    if (props.secondaryBottomModifierText) {
      return (
        <Box pt={1}>
          <ListItemText
            primary={props.secondaryBottomModifierText}
            disableTypography
            style={props.secondaryBottomModifierTextVariant ? props.secondaryBottomModifierTextVariant : theme.custom.overline2}
          />
        </Box>
      )
    }
  }

  const displayBottomLinkText = () => {
    if (props.bottomLinkText) {
      return (
        <Box pt={1}>
          <Link
            onClick={(e)=>{
              // Stop propagation to have Link that is clickable inside
              // of a parent
              e.stopPropagation()
              if (props.onBottomLinkTextClick) {
                props.onBottomLinkTextClick()
              }
            }}
          >
            <ListItemText
              primary={props.bottomLinkText}
              primaryTypographyProps={
                {
                  variant: "overline"
                }
              }
            />
          </Link>
        </Box>
      )
    }
  }

  const displayBottomModifierRightText = () => {
    if (props.bottomModifierRightText) {
      return (
        <Box pt={1}>
          <ListItemText
            primary={props.bottomModifierRightText}
            primaryTypographyProps={
              {
                variant: "overline"
              }
            }
          />
        </Box>
      )
    }
  }

  const displaySideModifierText = () => {
    if (props.sideModifierText) {
      return (
        <Box>
          <ListItemText
            primary={props.sideModifierText}
            primaryTypographyProps={
              {
                variant: "h2"
              }
            }
          />
        </Box>
      )
    }
  }

  const displayLargeImage = () => {
    if (props.largeImage) {
      return (
        <Box>
          <VNImageFallback className={classes.largeImage} src={props.largeImage} alt={props.largeImageAlt} />
        </Box>
      )
    }
  }

  const displaySideImage = () => {
    if (props.sideImage) {
      return (
        <Box>
          <VNImageFallback className={classes.sideImage} src={props.sideImage} alt={props.sideImageAlt} />
        </Box>
      )
    }
  }

  const displayTopModifier = () => {
    if (props.topModifier) {
      return (
        <Box pb={1}>
          <Chip label={props.topModifier} color={'primary'} className={classes.chip}></Chip>
        </Box>
      )
    }
  }

  // HANDLES

  // called when the user clicks the list item
  const handleClick = () => {
    if (props.click) {
      props.click()
    }
  }

  /**
   * set the is available class on a list item, which sets the opacity down, does NOT disable it
   */
  const getIsAvailableClass = () => {
    if ('isAvailable' in props && props.isAvailable === false) {
      return classes.notAvailable
    }
  }

  /**
   * Rules to display the bottom text modifiers
   */
  const displayBottomModifierTextRules = () => {
    if ('isAvailable' in props && props.isAvailable === false) {
      return displaySecondaryBottomModifierText()
    }

    return (
      <Fragment>
          {displayBottomModifierText()}
          {displayBottomLinkText()}
          {displayBottomModifierRightText()}
      </Fragment>
    )
  }

  const displayWaitTime = () => {
    if (props.waitTime) {
      return (
        <VNExpandableChip
          backgroundColor={theme.custom.colors.red}
          color={theme.custom.colors.white}
          text={props.waitTime}
        />
      )
    }
  }

  return (
    <ListItem
      button
      key={props.text}
      onClick={handleClick}
      className={clsx(classes.listItem, getIsAvailableClass())}
    >
      <Box className={classes.outer_box} pt={1} pb={1}>
        {displayTopModifier()}
        <Box className={classes.inner_box}>
          <Box>
            {displayLargeImage()}
            <Box className={classes.titleContainer}>
              {displayTitle()}
              {displayWaitTime()}
            </Box>
            {displaySubTitle()}
          </Box>
          {displaySideModifierText()}
          {displaySideImage()}
        </Box>
        <Box className={classes.bottomModifier}>
          {displayBottomModifierTextRules()}
        </Box>
      </Box>
    </ListItem>
  )
}
