import React, { Fragment } from 'react'
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Dialog from '@material-ui/core/Dialog'
import List from "@material-ui/core/List";
import { VNDetailedListItem } from './VNDetailedListItem'
import Slide from '@material-ui/core/Slide'
import Box from "@material-ui/core/Box"
import Typography from '@material-ui/core/Typography'
import VNDivider from '../VNComponents/VNDivider'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {

  },
  paper: {
    borderRadius: 10,
  },
  list: {
    width: 230
  },
  listItem: {
    paddingLeft: 32,
    height: 0,
  },
  title: {
    paddingLeft: 32,
    marginTop: 20,
    marginBottom: 10
  }
}));

/**
 * Displays a Dialog List
 * @params
 * Required
 * items: [] - An array of strings to be shown as a list in a dialog popover
 * click: Func - Called when a user selects one of the items from the list
 *
 * Optional
 * divider: Boolean - If you want to display a divider between items, set this to true. Default is false
 */
export const VNDialogList = (props) => {

  const classes = useStyles()

  const theme = useTheme()

  // HANDLES

  // display a divider between list items
  const displayDivider = () => {
    if (props.divider) {
      return (<VNDivider />)
    }
  }

  // called when the item in the list is selected
  const handleClick = (value) => {
    if (props.click) {
      props.click(value)
    }
  }

  const renderTitle = () => {
    const { title } = props

    if (title) {
      return (
        <Box className={classes.title}>
          <Typography variant="h1">
            {title}
          </Typography>
        </Box>
      )
    }

    return null
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onBackdropClick={() => handleClick('noop')}
      classes={
        {
          root: classes.root,
          paper: classes.paper
        }
      }>
      {renderTitle()}
      {props.items &&
        <List className={classes.list}>
          {props.items.map((item) => (
            <Fragment key={item}>
              <VNDetailedListItem titleTextVariant={theme.typography.h2} title={item} click={() => handleClick(item)} customTitleStyle={classes.listItem}/>
              {displayDivider()}
            </Fragment>
          ))}
        </List>
      }
    </Dialog>
  )
}
