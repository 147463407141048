import { MENU_SUCCEEDED } from '../actions/menu'
import { keys } from 'lodash'
import humps from 'humps'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case MENU_SUCCEEDED:
      let events = action.payload.events
      keys(events).forEach((key) => {
        events[key] = humps.camelizeKeys(events[key])
      })

      return {
        ...events,
      }

    default:
      return state
  }
}

export default reducer
