export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART'
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_ORDER_NOW = 'CLEAR_ORDER_NOW'
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART'
export const REMOVE_EXPERIENCE_ITEM_FROM_CART = 'REMOVE_EXPERIENCE_ITEM_FROM_CART'
export const UPDATE_METADATA = 'UPDATE_METADATA'
export const UPDATE_TIP_AMOUNT = 'UPDATE_TIP_AMOUNT'
export const EDIT_QUANTITY_IN_CART = 'EDIT_QUANTITY_IN_CART'
export const EDIT_EXPERIENCE_QUANTITY_IN_CART = 'EDIT_EXPERIENCE_QUANTITY_IN_CART'
export const EDIT_VARIANT_QUANTITY_IN_CART = 'EDIT_VARIANT_QUANTITY_IN_CART'
export const CAN_ADD_TO_CART = 'CAN_ADD_TO_CART'

// NOTE: A sale is only for experience items that have an exclusivity attached.
export function addItemToCart(id, quantity, modifiers = [], orderNow = false, productType, variant, sale) {
  return {
    type: ADD_ITEM_TO_CART,
    payload: {
      id,
      quantity,
      modifiers,
      orderNow,
      productType,
      variant,
      sale
    },
  }
}

/**
 * Edit the quantity of an item with a particular set of modifiers
 * @param {string} id - Item UUID
 * @param {int} quantity - Quantity of this item with the same modifiers set
 * @param {array} modifierIndices - Indices of modifiers in cart.item.modifiers
 * @param {array} modifiers - array of modifiers
 * @param {boolean} isRichCheckoutQR - Whether this is a Rich Checkout from QR Scan or not
 * @returns
 */
export function editQuantityItemInCart(id, quantity, modifierIndices, modifiers, isRichCheckoutQR = false) {
  return {
    type: EDIT_QUANTITY_IN_CART,
    payload: {
      id,
      quantity,
      modifierIndices,
      modifiers,
      isRichCheckoutQR
    }
  }
}
/**
 * Edit the quantity of an item with variants
 * @param {string} itemId - Item UUID
 * @param {string} variantId - Selected variant UUID
 * @param {int} quantity - Quantity of this item
 * @returns
 */
export function editQuantityItemWithVariantsInCart(itemId, variantId, quantity) {
  return {
    type: EDIT_VARIANT_QUANTITY_IN_CART,
    payload: {
      itemId,
      variantId,
      quantity
    }
  }
}

/**
 * Edit the quantity of an item with a particular sale id
 * @param {object} item - Current cart item
 * @param {int} quantity - Quantity of this item with the specified sale id
 * @returns
 */
export function editExperienceQuantityItemInCart(item, quantity) {
  return {
    type: EDIT_EXPERIENCE_QUANTITY_IN_CART,
    payload: {
      item,
      quantity
    }
  }
}

export function setActiveMenu(menuId, standId) {
  return {
    type: SET_ACTIVE_MENU,
    payload: {
      menuId,
      standId,
    },
  }
}

export function clearCart() {
  return {
    type: CLEAR_CART
  }
}

export function clearOrderNow() {
  return {
    type: CLEAR_ORDER_NOW
  }
}

export function removeItemFromCart(id, modifiersIndex, variantId, isRichCheckoutQR = false) {
  return {
    type: REMOVE_ITEM_FROM_CART,
    payload: {
      id,
      modifiersIndex,
      variantId,
      isRichCheckoutQR
    }
  }
}

/**
 * Remove an experience item from the cart
 * @param {string} id - Global experience item UUID
 * @returns
 */
export function removeExperienceItemFromCart(id) {
  return {
    type: REMOVE_EXPERIENCE_ITEM_FROM_CART,
    payload: {
      id
    }
  }
}

export function updateMetadata(itemId, metadata={}) {
  return {
    type: UPDATE_METADATA,
    payload: {
      itemId,
      metadata
    }
  }
}

export function updateGratuity(tipAmount, tipButtonId, isRichCheckoutQR) {
  return {
    type: UPDATE_TIP_AMOUNT,
    payload: {
      tipAmount,
      tipButtonId,
      isRichCheckoutQR
    }
  }
}

/**
 * canAddToCart is used to determine if user can add item to cart / order now
 * It should be `true` when all required modifier selections are satisfied
 * @param {boolean} canAddToCart
 */
export function updateCanAddToCart(canAddToCart) {
  return {
    type: CAN_ADD_TO_CART,
    payload: {
      canAddToCart
    }
  }
}
