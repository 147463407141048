import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { get, keys, isEmpty } from 'lodash'
import classNames from 'classnames'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

import Analytics from "../reporting/analytics"
import Title from '../utils/titleGenerator'
import Remote from '../remote'

import Button from '../components/Button'
import Modal from '../components/Modal'
import QuantitySelector from  '../components/QuantitySelector'

import { addItemToCart, editQuantityItemInCart } from '../actions/cart'
import { loadMenu } from '../actions/menu'
import { loadStands } from '../actions/stand'
import { updateUserAgeVerification } from '../actions/user'

import { makeGetMenu, makeGetServiceFeeItems } from '../selectors/menu'
import { makeGetItem } from '../selectors/item'
import { makeGetCartItem, makeGetAlcoholicItemQuantityInCart, makeGetOtherAlcoholicItemQuantityInCart } from '../selectors/cart'
import { makeGetRemote } from '../selectors/remote'
import { getUserLocation } from '../selectors/user'
import { getStandById } from '../VNRevenueCenters/Selectors'

import { withTranslation } from 'react-i18next'

import { MemoizedVNMenuItemDetails } from '../VNMenu/containers/VNMenuItemDetails'
import ItemSetting from '../VNEnums/ItemSetting'
import { getUserJWT } from '../VNUser/Selectors'

import './ItemModal.scss'

export class ItemModal extends Component {
  constructor(props) {
    super(props)

    this.modalRef = React.createRef()
    this.modifierGroupRefs = []
    this.menuItemDetailsRef = React.createRef()
  }

  state = {
    quantity: this.props.item.orderMinCount || 1,
    hasOverflow: false,
    isModalLoadedPoller: null,
  }

  componentDidMount() {
    const { item, loadMenu, loadStands, menu, menuId, seatNotProvided, userJWT, editItemModifierIndices } = this.props

    if (editItemModifierIndices) {
      this.setState({ quantity: editItemModifierIndices.length })
    }

    if (isEmpty(menu) && userJWT) {
      loadMenu(menuId)
      loadStands()
    }

    if (!isEmpty(item)) Analytics.generateItemViewedEvent(item)

    if (seatNotProvided) this.redirectToSeatSelection()
    this.handleHasOverflow()
  }

  componentDidUpdate(prevProps) {
    const { item, loadMenu, menuId, modifierGroups, seatNotProvided, userJWT } = this.props
    const seatNotProvidedChanged = prevProps.seatNotProvided !== seatNotProvided

    if (isEmpty(prevProps.item) && !isEmpty(item) && userJWT) {
      loadMenu(menuId)
      Analytics.generateItemViewedEvent(item)
    }

    if (seatNotProvidedChanged && seatNotProvided) this.redirectToSeatSelection()

    if (prevProps.modifierGroups !== modifierGroups) { this.handleHasOverflow() }
  }

  pollForModal = (modalElement) => {
    // modal doesn't exist; havent started polling yet
    if (!modalElement && !this.state.isModalLoadedPoller) {
      this.setState({
        isModalLoadedPoller: setInterval(this.handleHasOverflow, 300),
      })
    }

    // modal now exists, clear poller
    if (modalElement && this.state.isModalLoadedPoller) {
      clearInterval(this.state.isModalLoadedPoller)
      this.setState({ isModalLoadedPoller: null })
    }
  }

  handleHasOverflow = () => {
    const modalElement = document.querySelector('.modal')
    let hasOverflow = false

    this.pollForModal(modalElement)

    if (modalElement !== null) hasOverflow = window.innerHeight < modalElement.offsetHeight
    if (hasOverflow) {
      clearAllBodyScrollLocks()
    }

    this.setState({ hasOverflow })
  }

  redirectToSeatSelection = () => {
    const { history, location, deliveryLocationRoute, item } = this.props
    const currentUrl = location.pathname

    history.replace(deliveryLocationRoute, {
      redirect: `${currentUrl}/item/${item.id}`,
    })
  }

  closeModal = () => {
    const closeModal = get(this, 'modalRef.current.closeModal', () => {})

    closeModal()
  }

  addDeliveryFee = () => {
    const { addItemToCart, serviceFeeItems } = this.props
    const deliveryFeeID = keys(serviceFeeItems).find((itemId) => {
      return get(serviceFeeItems[itemId], 'specialType') === 'delivery_fee'
    })

    if (deliveryFeeID) { addItemToCart(deliveryFeeID, 1) }
  }

  addToCart = (orderNow = false) => {
    const { item, cartItemQuantity, addItemToCart, isInCart, isCartEmpty, merchandise } = this.props
    const { quantity } = this.state

    const validationObj = this.menuItemDetailsRef.current.validate()

    if (!validationObj.isValid) return

    const modifiers = validationObj.options.map(node => {
      return {
        menuItemUuid: node.uuid,
        quantity: 1
      }
    })

    if (isCartEmpty) this.addDeliveryFee()

    const productType = merchandise ? 'Merchandise' : 'Food'

    if (item.isAlcohol) {
      const newQuantity = isInCart ? quantity + cartItemQuantity : quantity
      addItemToCart(item.uuid, newQuantity, modifiers, orderNow, productType)
    } else {
      addItemToCart(item.uuid, quantity + cartItemQuantity, modifiers, orderNow, productType)
    }

    this.closeModal()

    if (orderNow) this.props.history.replace('/cart')
  }

  handleAddToCart = (orderNow = false) => {
    const { loading, ageNeedsVerification, verifyUserAge } = this.props

    if (loading) return

    if (!ageNeedsVerification) {
      this.addToCart(orderNow)
    } else {
      verifyUserAge()
    }
  }

  handleFinishEdit = () => {
    const { item, editItemModifierIndices, history, editQuantityItemInCart } = this.props
    const { quantity } = this.state

    const validationObj = this.menuItemDetailsRef.current.validate()

    if (!validationObj.isValid) return

    const modifiers = validationObj.options.map(node => {
      return {
        menuItemUuid: node.uuid,
        // modifierGroupUuid is used to identify which modifier group this modifier belongs to
        // primarily used in VNSingleChoice and VNMultiChoice
        modifierGroupUuid: node.modifierGroupUuid,
        isVariantChoice: node.isVariantChoice,
        quantity: node.quantity || 1
      }
    })

    editQuantityItemInCart(item.uuid, quantity, editItemModifierIndices, modifiers)

    history.replace('/cart')
  }

  decrementCount = () => {
    const { item } = this.props
    const { quantity } = this.state
    const newQuantity = quantity - 1

    if (newQuantity < 1 || (item.orderMinCount && newQuantity < item.orderMinCount)) return

    this.setState({ quantity: newQuantity })
  }

  incrementCount = () => {
    const { quantity } = this.state
    const { maxQuantity } = this.props
    const newQuantity = quantity + 1

    if (newQuantity > maxQuantity) return

    this.setState({ quantity: newQuantity })
  }

  renderQuantityMessage = () => {
    const { menu, item, ageNeedsVerification, t } = this.props

    // This will otherwise crash on page refresh, if the item details modal is open
    if (!menu) return null

    if (isEmpty(menu) && isEmpty(item)) return null

    if (!item.isAlcohol && item.orderMaxAmount === null) return null

    let message = `${t('ONLY_CAPS_START')} ${item.orderMaxAmount} ${t('OF_THIS_ITEM_PER_ORDER_ALLOWED')}.`

    if (item.isAlcohol) {
      message = menu.alcoholLimitMessage
    }

    return <p className={classNames('quantity-message', { confirmed: !ageNeedsVerification }, { unconfirmed: ageNeedsVerification })}>{message}</p>
  }

  renderMenuItemDetails = () => {
    const { item, merchandise, editItemModifierRelationshipKey, editItemModifierIndices } = this.props

    return (
      <MemoizedVNMenuItemDetails
        ref={this.menuItemDetailsRef}
        isMerchandise={merchandise}
        item={item}
        itemModifierRelationshipKey={editItemModifierRelationshipKey}
        itemModifierIndices={editItemModifierIndices}/>
    )
  }

  render() {
    const { quantity, hasOverflow } = this.state
    const { alcoholWarningMessage, maxQuantity, menu, item, ageNeedsVerification, menuRoute, hasModifiers, merchandise, hasMerchImage, loading, t, editItemModifierRelationshipKey } = this.props

    const unavailable = maxQuantity === 0
    const buttonText = unavailable ? t('MAX_QUANTITY_REACHED') : t('ORDER_NOW')
    const menuName = get(menu, 'name', '')
    const itemName = get(item, 'name', '')

    return (
      <Modal
        ref={this.modalRef}
        onCloseModal={() => this.props.history.replace(menuRoute)}
        className={classNames('item-modal', { unconfirmed: ageNeedsVerification, 'has-overflow': hasOverflow, merchandise: merchandise && hasMerchImage })}
      >
        <Helmet>
          <title>{Title.generate(menuName, itemName)}</title>
        </Helmet>
        {this.renderMenuItemDetails()}
        <QuantitySelector
          className={classNames({ 'has-modifiers': hasModifiers }, { 'has-overflow': hasOverflow })}
          onDecrement={this.decrementCount}
          onIncrement={this.incrementCount}
          min={item.orderMinCount || 1}
          max={maxQuantity}
          value={quantity}
          loading={loading}
        />
        <div className="item-footer">
          {ageNeedsVerification && alcoholWarningMessage ? <p className="age-requirement-message">{alcoholWarningMessage}</p> : null}
          <div className="verify-age">
            {ageNeedsVerification && <Button
              className={classNames({ confirmed: !ageNeedsVerification })}
              onClick={this.closeModal}
            >
              Cancel
            </Button>}
            {!unavailable && !ageNeedsVerification && !editItemModifierRelationshipKey && <Button className="add-to-cart" disabled={unavailable} onClick={() => this.handleAddToCart()}>{t('ADD_TO_CART')}</Button>}
            {!editItemModifierRelationshipKey && <Button.Brand disabled={unavailable} onClick={() => this.handleAddToCart(true)}>{!ageNeedsVerification ? buttonText : t('CONFIRM')}</Button.Brand>}
            {editItemModifierRelationshipKey && <Button.Brand disabled={unavailable} onClick={() => this.handleFinishEdit()}>{'UPDATE'}</Button.Brand>}
          </div>
        </div>
        {this.renderQuantityMessage()}
        <p className="page-bottom-spacer" />
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')
  const itemId = get(ownProps, 'match.params.itemId', '')

  const getRemote = makeGetRemote()
  const getMenu = makeGetMenu()
  const getItem = makeGetItem()
  const getCartItem = makeGetCartItem()
  const getServiceFeeItems = makeGetServiceFeeItems()

  const remote = getRemote(state, Remote.endpoints.getMenu)
  const stand = getStandById(state, standId)
  const menu = getMenu(state, menuId)

  const editItem = ownProps.history.location.state?.editItem
  const editItemModifierRelationshipKey = ownProps.history.location.state?.editItemRelationshipKey
  const editItemModifierIndices = ownProps.history.location.state?.editItemModifierIndices
  const item = editItem ? editItem : getItem(state, itemId)
  const cartItem = getCartItem(state, itemId)
  const serviceFeeItems = getServiceFeeItems(state, menuId)
  const modifierGroups = get(item, 'modifierGroups', [])
  const getAlcoholicItemQuantityInCart = makeGetAlcoholicItemQuantityInCart()
  const getOtherAlcoholicItemQuantityInCart = makeGetOtherAlcoholicItemQuantityInCart()
  const alcoholicItemQuantity = editItem ? getOtherAlcoholicItemQuantityInCart(state, editItem.id) : getAlcoholicItemQuantityInCart(state)
  const ageNeedsVerification = get(item, 'isAlcohol', false) && !get(state, 'user.location.ageVerified', false)
  const hasModifiers = !isEmpty(modifierGroups)

  const isCartEmpty = keys(get(state, 'cart.item', {})).length === 0
  const productType = get(stand, 'product_type', '')
  const detailImage = get(item, 'images.detail', null)
  const merchandise = productType === 'Merchandise'
  const hasMerchImage = merchandise && !isEmpty(detailImage)

  const isDelivery = get(menu, 'usageType') === 'delivery'
  const userLocationConfirmed = getUserLocation(state).confirmed
  const seatNotProvided = isDelivery && !userLocationConfirmed
  const deliveryLocationRoute = `/${standId}/menu/${menuId}/delivery-location`
  const menuRoute = `/${standId}/menu/${menuId}`

  let alcoholWarningMessage = stand.alcoholWarningMessage

  if (isEmpty(item)) return {
    item: {},
    cartItemQuantity: 0,
    loading: remote.loading,
    menuId: menuId,
  }

  let maxQuantity = item.orderMaxAmount || ItemSetting.DEFAULT_MAX_AMOUNT
  let cartItemQuantity = item.isAlcohol ? alcoholicItemQuantity : 0

  if (!isEmpty(cartItem)) {
    maxQuantity = maxQuantity - cartItem.quantity
    cartItemQuantity = cartItem.quantity
  }

  if (item.isAlcohol) {
    maxQuantity = menu.alcoholLimit - alcoholicItemQuantity
  }

  // make item unavailable in some conditions
  let itemUnavailable = false
  if (item.orderMaxAmount != null && item.orderMinCount != null) {
    if (item.orderMaxAmount - item.orderMinCount < 0) {
      // misconfigured min/max values
      itemUnavailable = true
    }
    if (item.orderMaxAmount === 0 && item.orderMinCount === 0) {
      itemUnavailable = true
    }
  }
  if (maxQuantity < 0 || itemUnavailable) {
    maxQuantity = 0
  }

  const userJWT = getUserJWT(state)

  return {
    menu,
    menuId,
    item: {
      ...item,
    },
    editItemModifierRelationshipKey,
    editItemModifierIndices,
    maxQuantity,
    cartItemQuantity,
    isCartEmpty,
    isInCart: !isEmpty(cartItem),
    ageNeedsVerification,
    seatNotProvided,
    deliveryLocationRoute,
    menuRoute,
    modifierGroups,
    hasModifiers,
    merchandise,
    hasMerchImage,
    serviceFeeItems,
    loading: remote.loading,
    alcoholWarningMessage,
    userJWT
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    addItemToCart(itemId, quantity, modifiers, orderNow, productType) {
      dispatch(addItemToCart(itemId, quantity, modifiers, orderNow, productType))
    },
    editQuantityItemInCart(itemId, quantity, modifierIndices, modifiers) {
      dispatch(editQuantityItemInCart(itemId, quantity, modifierIndices, modifiers))
    },
    loadMenu: (menuId) => dispatch(loadMenu(menuId)),
    loadStands: () => dispatch(loadStands()),
    verifyUserAge: () => dispatch(updateUserAgeVerification(true))
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ItemModal))
