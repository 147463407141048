import ApiLoadingStatus from './types/ApiLoadingStatus'

/**
 * Get the status for an API endpoint ActionType.
 * If status object has not been populated, will be empty.
 * Status can be: unknown | loading | succeeded | failed
 * @param {object} state - The state
 * @param {string} type - ActionType of API call
 */
export const getLoadingSystemStatus = (state, type) => {
  let statusObj = state.VNApiLoadingSystem.getIn(['loading', type])

  if (statusObj) {
    return statusObj
  }
  
  return {
    type: type,
    status: ApiLoadingStatus.UNKNOWN,
    error: null
  }
}
