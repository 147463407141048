import { isEmpty, isArray, isPlainObject, keys, get } from 'lodash'
import normalize from 'json-api-normalizer'

class JSONAPI {
  static mapKeys = (resource, keyMap) => {
    if (isEmpty(keyMap)) return { ...resource }

    const clonedResource = { ...resource }

    keys(keyMap).forEach((key) => {
      const newKey = keyMap[key]
      clonedResource[newKey] = get(clonedResource, key)

      delete clonedResource[key]
    })

    return clonedResource
  }

  static prepareGlobals = (resource, globalsKeys) => {
    if (isEmpty(globalsKeys)) return { ...resource }

    const globalsKeysObject = {}

    globalsKeys.forEach((key) => {
      globalsKeysObject[key] = resource[key]
    })

    return globalsKeysObject
  }

  static prepareAttributes = (resource, globalsKeys) => {
    if (isEmpty(globalsKeys)) return { ...resource }

    const clonedResource = { ...resource }

    globalsKeys.forEach((key) => {
      delete clonedResource[key]
    })

    return clonedResource
  }

  static reshape = (resource, type, globalsKeys) => {
    const globals = JSONAPI.prepareGlobals(resource, globalsKeys)
    const attributes = JSONAPI.prepareAttributes(resource, globalsKeys)

    return {
      ...globals,
      type,
      attributes: {
        ...attributes,
      }
    }
  }

  static resource = (resource, type, keyMap, globalsKeys) => {
    const mappedKeys = JSONAPI.mapKeys(resource, keyMap)
    const result = JSONAPI.reshape(mappedKeys, type, globalsKeys)

    return result
  }

  static resources = (resources, type, keyMap, globalsKeys) => {
    return resources.map((resource) => JSONAPI.resource(resource, type, keyMap, globalsKeys))
  }

  static parse = (data, type, keyMap, globalsKeys) => {
    const dataEmpty = isEmpty(data)
    const dataArray = isArray(data)
    const dataObject = isPlainObject(data)

    if (dataEmpty && dataArray) return []
    if (dataEmpty && dataObject) return {}
    if (dataEmpty) return data

    if (dataArray) return normalize({ data: JSONAPI.resources(data, type, keyMap, globalsKeys) })

    return normalize({ data: JSONAPI.resource(data, type, keyMap, globalsKeys) })
  }

  static event = (event) => {
    const globalsKeys = ['id']

    return JSONAPI.parse(event, 'event', {}, globalsKeys)
  }
}

export default JSONAPI
