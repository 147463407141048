const PIDGEON_KEYS = {
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHANGE_CARD_UI: 'CHANGE_CARD_UI',
  CARD_UPDATE: 'CARD_UPDATE',
  SECURE_FIELD_FOCUS: 'SECURE_FIELD_FOCUS',
  SECURE_FIELD_NOT_VALID: 'SECURE_FIELD_NOT_VALID',
  VAULT_RETRIEVED_ALL_CARDS: 'VAULT_RETRIEVED_ALL_CARDS',
  VAULT_CARD_VAULTED: 'VAULT_CARD_VAULTED',
  VAULT_CARD_DELETED: 'VAULT_CARD_DELETED',
  VAULT_CARD_UPDATED: 'VAULT_CARD_UPDATED',
  CARD_TYPE_DETERMINATION: 'CARD_TYPE_DETERMINATION',
  START_WALLET_PROCESS: 'START_WALLET_PROCESS',
  WALLET_PAYMENT: 'WALLET_PAYMENT',
  WALLET_CHANGE: 'WALLET_CHANGE',
  VNI4GO_READY: 'VNI4GO_READY'
}

module.exports = PIDGEON_KEYS
