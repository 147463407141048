import React, { Component } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Button from './Button'
import VNImageFallback from '../VNComponents/VNImageFallback'
import { ItemModifierTracker } from '../containers/Cart'

import './Item.scss'

import Money from '../utils/money'
import Skeleton from 'react-loading-skeleton'
import { withTranslation } from 'react-i18next'

const ITEM_TYPE = {
  cell: 'cell',
  detail: 'detail',
  cart: 'cart',
  recommended: 'recommended',
}

export class Item extends Component {
  static defaultProps = {
    type: ITEM_TYPE.cell,
  }

  renderPrice = () => {
    const { price, type, loading, quantityAvailable, t } = this.props
    if (loading) return <span className="price"><Skeleton width='55px' /></span>
    if (!price) return null

    const formatedPrice = type === ITEM_TYPE.detail ? `${price}/${t('EACH')}` : price
    let quantityAvailableLabel = null

    if (quantityAvailable) {
      quantityAvailableLabel = (
        <span className="quantity-available">{`${t('ONLY')} ${quantityAvailable} ${t('LEFT')}`}</span>
      )
    }

    return <span className="price">{formatedPrice}{quantityAvailableLabel}</span>
  }

  renderImageOrButton = () => {
    const { image, name, type, onClick, loading } = this.props

    if (type === ITEM_TYPE.recommended) {
      return <Button.Brand small onClick={onClick}>add item</Button.Brand>
    }

    if (loading && type === ITEM_TYPE.cell) {
      return <div className="image"><Skeleton height='100%' /></div>
    }

    if (type === ITEM_TYPE.cell && image) {
      return <VNImageFallback className="image" src={image} alt={name} />
    }

    return null
  }

  renderDescription = () => {
    const { description, type, loading } = this.props

    if (loading && type !== ITEM_TYPE.recommended) {
      return <span className="description"><Skeleton width='80%' /></span>
    }

    if (!description || type === ITEM_TYPE.recommended) return null

    return <span className="description">{description}</span>
  }

  renderModifierLabels() {
    const { quickpay, modifiers, item } = this.props
    let modifierLabels = []

    if (!isEmpty(modifiers)) {
      new ItemModifierTracker(item, modifiers).iterate((mod, priceIncrement) => {
        let displayName = mod.name

        let price = Money.dollarsToCents(mod.price)
        if (!price) {
          price = priceIncrement
        }

        if (price > 0) {
          const displayPrice = Money.centsToDollars(price, false, true)
          displayName += ` +${displayPrice}`
        }

        modifierLabels.push(displayName)
      })
    }

    if (quickpay) {
      return (
        <span className="modifiers">
          {modifierLabels.map(label => <span key={label}>{label}</span>)}
        </span>
      )
    }

    return <span className="modifiers">{modifierLabels.join(', ')}</span>
  }

  renderCartItem = () => {
    const { quantity, name, modifiers, onRemove, loading, t } = this.props

    return (
      <div className="menu-item cart">
        <div className="column">
          <span className="quantity">{quantity}</span>
        </div>
        <div className="column">
          <div className="row">
            <span className="name">{loading ? <Skeleton width='140px' /> : name}</span>
            {this.renderPrice()}
          </div>
          <div className="row">
            {loading && <span className="modifiers"><Skeleton width='100px' /></span>}
            {!loading && !isEmpty(modifiers) && this.renderModifierLabels()}
            <span className="actions">
              {/* <button>edit</button>
              <span className="divider">•</span> */}
              {onRemove && <button onClick={onRemove}>{t('REMOVE')}</button>}
            </span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { name, type, available = true, className: customClassName, loading } = this.props

    if (type === ITEM_TYPE.cart) {
      return this.renderCartItem()
    }

    const className = classNames('menu-item', type, { unavailable: !available }, customClassName)

    return (
      <div className={className}>
        <div className={classNames('details', { loading })}>
          <span className="name">{loading ? <Skeleton /> : name}</span>
          {this.renderDescription()}
          {this.renderPrice()}
        </div>
        {this.renderImageOrButton()}
      </div>
    )
  }
}

export default withTranslation()(Item)
