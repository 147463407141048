const constants = {
  status: {
    LOADING: 'LOADING',
    PROCESSING: 'PROCESSING',
    READY: 'READY',
    COMPLETE: 'COMPLETE'
  },
  mode: {
    EDIT: 'EDIT',
    SELECT: 'SELECT'
  },
  cardUI: {
    DEFAULT: 'DEFAULT',
    ADD_CARD: 'ADD_CARD',
    DELETE_CARD: 'DELETE_CARD'
  }
}

module.exports = constants
