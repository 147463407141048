import get from 'lodash/get'

export function itemHasVariants(item) {
  return variantsForItem(item).length > 1
}

export function variantsAreSamePrice(item) {
  if (!item) return true

  const variants = variantsForItem(item)

  return variants.every(v => v.priceInCents === variants[0].priceInCents)
}

export function variantsForItem(item) {
  return get(item, 'variants', [])
}
