export const getWebSDKMode = (state) => {
  return state.VNWebSDK.get('webSDKMode')
}

export const getWebSDKPlatform = (state) => {
  return state.VNWebSDK.get('platform')
}

export const getWebSDKVersion = (state) => {
  return state.VNWebSDK.get('version')
}

export const getWebSDKBundleId = (state) => {
  return state.VNWebSDK.get('bundleId')
}

export const getWebSDKDataReceive = (state) => {
  return state.VNWebSDK.get('latestReceive')
}

export const getWebSDKNavMode = (state) => {
  return state.VNWebSDK.get('webSDKNavMode')
}

export const getInitialPathname = (state) => {
  return state.VNWebSDK.get('initialPathname')
}

export const getWebSDKIsExternalPaymentProcessor = (state) => {
  return state.VNWebSDK.get('isExternalPaymentProcessor')
}

export const getWebSDKProductType = (state) => {
  return state.VNWebSDK.get('productType')
}

export const getWebSDKBackgroundColor = (state) => {
  return state.VNWebSDK.get('bgColor')
}

export const getWebSDKAccentColor = (state) => {
  return state.VNWebSDK.get('accentColor')
}

export const getSectionRowSeat = (state) => {
  return state.VNWebSDK.get('sectionRowSeat')
}
