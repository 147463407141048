import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Box, capitalize } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'

import { logoutUser, setCachedRoute } from '../../VNUser/ActionCreators'

import { getUser } from '../../VNUser/Selectors'
import {
  getAuthProviders,
  getProviderIssuer,
  getProviderClientId,
  getProviderVisualPresets,
  getProviderRedirectUri,
  getAuthProvidersDisabledForSDK,
  getSettingsLinksAvailable,
  getSeatGeekTeamSlug,
  getAccentColor
} from '../../VNConfigurations/Selectors'
import { getWebSDKMode, getWebSDKAccentColor } from '../../VNWebSDK/Selectors'

import { history } from '../../App/store/configureStore'
import { isAuthProviderDisabledForVNSDK } from '../Utils'
import { useTranslation } from 'react-i18next'
import getContrastYIQ from '../../VNUtil/Color'
import { generateSeatGeekLoginLink } from '../../VNUtil/AuthProviderLoginLinkHelper'

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 0
  },
}))

/**
 * @param {Boolean} hasSignout display signout button or not
 * @param {Function} onSelection if hasSignout and signout button is clicked, go to props and 
 * @param {Object} highestRankedAffiliation display if there is highest affiliations
 */
export const UserInfo = ({highestRankedAffiliation, onSelection, hasSignOut}) => {

  const classes = useStyles()

  const theme = useTheme()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  // HANDLES

  // called when you want to sign out the user
  const handleSignOutClick = (event) => {
    handleAnySelection(event)
    dispatch(logoutUser())
    history.push('/')
  }

  // called when any list item has been clicked
  const handleAnySelection = (event) => {
    if (event) {
      event.preventDefault()
    }

    // This comes from props
    if (onSelection){
      onSelection()
    }
  }

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const ticketmasterIssuer = useSelector(state => getProviderIssuer(state, 'ticketmaster'))
  const ticketmasterClientId = useSelector(state => getProviderClientId(state, 'ticketmaster'))
  const ticketmasterVisualPresets = useSelector(state => getProviderVisualPresets(state, 'ticketmaster'))
  const ticketmasterRedirectUri = useSelector(state => getProviderRedirectUri(state, 'ticketmaster'))
  const authProviders = useSelector(state => getAuthProviders(state))
  const authProvidersDisabledForSDK = useSelector(state => getAuthProvidersDisabledForSDK(state))
  const settingLinksAvailable = useSelector(state => getSettingsLinksAvailable(state))
  const accentColor = useSelector(state => getAccentColor(state))
  const seatgeekIssuer = useSelector(state => getProviderIssuer(state, 'seatgeek'))
  const seatgeekClientId = useSelector(state => getProviderClientId(state, 'seatgeek'))
  const seatGeekTeamSlug = useSelector(state => getSeatGeekTeamSlug(state))
  const seatGeekTeamSlugInScope = seatGeekTeamSlug?.replace('_', '-')

  // check if this is in web SDK Mode or not
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKAccentColor = useSelector(state => getWebSDKAccentColor(state))

  /**
   * generate the login for ticketmaster who uses 3rd party auth
   */
  const generateTicketmasterLoginLink = () => {
    return `${ticketmasterIssuer}?lang=en-us&scope=openid profile email archtics&client_id=${ticketmasterClientId}&integratorId=venuenext&placementId=homepage&visualPresets=${ticketmasterVisualPresets}&redirect_uri=${encodeURI(ticketmasterRedirectUri)}&state=venuenext`
  }

  const getPathBasedColor = () => {
    const isWallet = history.location.pathname === '/wallet'
    return isWallet ? getContrastYIQ(accentColor) : null
  }

  const getModeNameStyle = () => {
    return {
      ...theme.typography.h1,
      color: webSDKMode ? webSDKAccentColor : getPathBasedColor()
    }
  }

  const getModeAffiliationStyle = () => {
    return {
      ...theme.typography.subtitle1,
      color: webSDKMode ? webSDKAccentColor : getPathBasedColor()
    }
  }

  const getModeLoginStyle = () => {
    return {
      ...theme.typography.h4,
      color: webSDKMode ? webSDKAccentColor : getPathBasedColor()
    }
  }

  const getModeSignOutStyle = () => {
    return {
      ...theme.typography.overline,
      color: webSDKMode ? webSDKAccentColor : getPathBasedColor()
    }
  }

  /**
   * User Display
   */
  const displayUser = () => {
    if (user.isEmpty() || user.get('provider') === 'vn_anonymous') {
      const displayAuthProviders = () => {
        if (settingLinksAvailable.length > 0) {
          return (
            authProviders.map((text) => {

              if (isAuthProviderDisabledForVNSDK(webSDKMode, authProvidersDisabledForSDK, text)) {
                return null
              }

              if (text === 'ticketmaster') {
                return(
                  <ListItem
                    className={classes.listItem}
                    button
                    component="a"
                    key={text}
                    onClick={() => {
                      const pathname = history.location.pathname
                      if (pathname !== '/wallet') {
                        dispatch(setCachedRoute(pathname))
                      }
                      window.location.href = generateTicketmasterLoginLink()
                    }}
                  >
                    <ListItemText disableTypography style={getModeLoginStyle()}>{capitalize(text)} {t('LOG_IN')}</ListItemText>
                  </ListItem>
                )
              } else if (text === 'sms_signup') {
                return(
                  <ListItem
                    className={classes.listItem}
                    button
                    component="a"
                    key='signup'
                    onClick={() => {
                      const pathname = history.location.pathname
                      if (pathname !== '/wallet') {
                        dispatch(setCachedRoute(pathname))
                      }
                      history.push('/profile/signup')
                    }}
                  >
                    <ListItemText disableTypography style={getModeLoginStyle()}>{t('SIGN_UP')}</ListItemText>
                  </ListItem>
                )
              } else if (text === 'sms_signin') {
                return (
                  <ListItem
                    className={classes.listItem}
                    button component="a"
                    onClick={() => {
                      const pathname = history.location.pathname
                      if (pathname !== '/wallet') {
                        dispatch(setCachedRoute(pathname))
                      }
                      history.push('/profile/login')
                    }}
                    key='login'
                  >
                    <ListItemText disableTypography style={getModeLoginStyle()}>{t('LOG_IN')}</ListItemText>
                  </ListItem>
                )
              } else if (text === 'axs') {
                return(
                  <ListItem
                    className={classes.listItem}
                    button
                    component="a"
                    onClick={() => {
                      const pathname = history.location.pathname
                      if (pathname !== '/wallet') {
                        dispatch(setCachedRoute(pathname))
                      }
                      history.push('/profile/axs-login')
                    }}
                    key='axs-signup'>
                    <ListItemText disableTypography style={getModeLoginStyle()}>{capitalize(text)} {t('LOG_IN')}</ListItemText>
                  </ListItem>
                )
              } else if (text === 'seatgeek') {
                return (
                  <ListItem
                    key='seatgeek-login'
                    className={classes.listItem}
                    button
                    component='a'
                    onClick={() => {
                      const pathname = history.location.pathname
                      if (pathname !== '/wallet') {
                        dispatch(setCachedRoute(pathname))
                      }
                      window.location.href = generateSeatGeekLoginLink(seatgeekIssuer, seatGeekTeamSlugInScope, seatgeekClientId, seatGeekTeamSlug)
                    }}
                  >
                    <ListItemText disableTypography style={getModeLoginStyle()}>SeatGeek {t('LOG_IN')}</ListItemText>
                  </ListItem>
                )
              } else {
                return null
              }
            })
          )
        }
      }

      return (
        <Box pt={0}>
          <List component="nav">
            {displayAuthProviders()}
          </List>
        </Box>
      )
    } else if (!user.isEmpty()) {

      let response = []
      response.push(<Box pt={3} key={user.get('userID')}><Typography style={getModeNameStyle()}>{user.get('name') ? user.get('name') : user.get('email')}</Typography></Box>)

      // if there are affiliations, display the highest member level
      if (highestRankedAffiliation) {
        response.push(<Typography style={getModeAffiliationStyle()} key={highestRankedAffiliation.name}>{highestRankedAffiliation.name}</Typography>)
      }

      if (!webSDKMode && hasSignOut) {
        response.push(<Typography onClick={handleSignOutClick} style={getModeSignOutStyle()} key='sign-out'>{t('SIGN_OUT')}</Typography>)
      }

      return response
    }

    return null
  }

  return displayUser()
}
