
/**
 * Loading state of API calls to be used in apiStatusObj
 */
export const ApiLoadingStatusCached = {
  UNKNOWN: 'UNKNOWN',
  LOADING: 'LOADING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
}

export default ApiLoadingStatusCached
