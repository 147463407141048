import humps from  'humps'
import { experienceOrderTotalMapper } from './orderTotal'

export function experienceOrderMapper({ items, bundleId, email, paymentMethodNonce, payments }) {
  const orderObject = {
    ...experienceOrderTotalMapper(items, email),
    paymentMethodNonce,
    payments,
    webOrder: true,
    ...bundleId ? { bundleId: bundleId } : {}
  }

  return humps.decamelizeKeys(orderObject)
}

export default experienceOrderMapper
