import Remote from '../../remote'
import keys from 'lodash/keys'

const shape = {
  loading: false,
  failed: false,
  cached: false,
  errors: undefined,
}

const remote = keys(Remote.endpoints).reduce((accumulator, key) => {
  return {
    ...accumulator,
    [key]: shape,
  }
}, {})


export default { remote }
