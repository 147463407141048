import { MENU_SUCCEEDED } from '../actions/menu'
import humps from 'humps'
import DataMapper from '../utils/dataMapper'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case MENU_SUCCEEDED:
      const items = humps.camelizeKeys(action.payload.items)
      const serviceFeeItems = humps.camelizeKeys(action.payload.service_fee_items)

      return {
        ...state,
        ...DataMapper.objectsById(items),
        ...DataMapper.objectsById(serviceFeeItems),
      }

    default:
      return state
  }
}

export default reducer
