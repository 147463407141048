import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTranslation } from 'react-i18next'
import VNDivider from '../../VNComponents/VNDivider'

const useStyles = makeStyles(theme => ({
  root: {

  },
  chip: {
    height: 20,
    marginLeft: 10,
    marginTop: 3,
  },
  formControl: {
    width: '100%',
  },
  titleBox: {
    display: 'flex'
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  divider: {
    width: 50,
    marginTop: 17,
  },
  modifierText: {
    paddingTop: 9
  },
  radioGroup: {
    marginTop: 17,
  },
  formControlLabel: theme.custom.button3,
}));

/**
 * Single choice component for modifiers or variants in VNMenuItemDetails
 *
 * Exposed Functoins
 * Accessible through ref option. Example: <REF>.current.getChoice()
 * getChoice - Accessible through the ref option. See function notes below on details
 * @param {object} ref - a reference to this component, used to call specific functions on this component from the parent
 * @param {boolean} required - A selection is required
 * @param {string} title - The title of the section
 * @param {string} subTitle - The subtitle of the section
 * @param {function} onChange - A function that is called on each selection
 * @param {Array} options - The choices
 * @param {Array} selectedItems - Already selected items, only comes through when editing from cart
 * @param {string} modifierGroupUuid - The uuid of the choice
 */
export const VNSingleChoice = forwardRef((props, ref) => {

  const classes = useStyles()

  const theme = useTheme()

  const { t } = useTranslation()

  // The value of the choice
  const [value, setValue] = useState('')
  const valueRef = useRef(value)

  // This is the chip color for it being required or optional
  const [chipColor, setChipColor] = useState(props.required ? 'primary' : 'default')

  useEffect(() => {
    if (props.selectedItems) {
      setSelectedItem()
    }
  }, [props.selectedItems])

  // handle default modifiers
  useEffect(() => {
    if (props.options && props.defaults?.length) {
      let defaultValue
      for (let i = 0; i < props.options.length; i++) {
        if (props.defaults.includes(props.options[i].uuid)) {
          defaultValue = i
          break
        }
      }
      if (defaultValue != null) {
        setValue(defaultValue)
        if (props.onChange) {
          props.onChange({
            uuid: props.modifierGroupUuid,
            minIsMet: true
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    valueRef.current = value
  }, [value])

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    // returns the choice value and the status as a object tuple
    // @return { choice : string, status: bool }
    getChoice() {
      const currentValue = valueRef.current

      if (props.required) {
        if (!currentValue) {
          setChipColor('secondary')
          return {
            'choice': undefined,
            'status': false
          }
        } else {
          setChipColor('primary')
        }
      }

      let detailedChoice
      for (let j = 0; j < props.options.length; j++) {
        const option = props.options[j]
        if (currentValue === option.uuid) {
          detailedChoice = {
            modifier_with_change: `${option.change}${option.modifier}`,
            uuid: option.uuid,
            value: option.value,
            priceInCents: option.priceInCents
          }
          break
        }
      }

      return {
        'choice': currentValue  ,
        'detailedChoice': detailedChoice,
        'status': true
      }
    }
  }))

  // called on each radio button selection
  const handleChange = (event) => {
    setValue(event.target.value)

    // used to inform parent component the current status of this choice
    if (props.onChange) {
      props.onChange({
        uuid: props.modifierGroupUuid,
        minIsMet: true
      })
    }
  }

  // retrieve the required or optional text
  const isRequired = () => {
    if (props.required) {
      return t('REQUIRED')
    }

    return t('OPTIONAL')
  }

  const setSelectedItem = () => {
    const { options, selectedItems } = props

    // loop through options to see if any is included in selectedItems
    for (let i = 0; i < options.length; i++) {
      const selected = selectedItems.find(item => {
        if (props.isVariantChoice) {
          return item.menuItemUuid === options[i].uuid
        }
        return item.uuid === options[i].uuid && item.modifierGroupUuid === props.modifierGroupUuid
      })

      if (selected) {
        setValue(i.toString())
        // single choice can only have one selected option, break out of the loop
        break
      }
    }
  }

  // the modifier is the text on the far right for each checkbox
  const displayModifier = (option) => {
    if (option.modifier) {
      return (
        <Typography className={classes.modifierText} style={theme.custom.button3}>
          {option.change}{option.modifier}
        </Typography>
      )
    }
  }

  // display the sub title or not
  const displaySubTitle = () => {
    if (props.subTitle) {
      return (
        <Box pt={1}>
          <Typography style={theme.custom.overline2}>
            {props.subTitle}
          </Typography>
        </Box>
      )
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h2">
          {props.title}
        </Typography>
        <Chip label={isRequired()} color={chipColor} className={classes.chip}></Chip>
      </Box>
      {displaySubTitle()}
      <VNDivider className={classes.divider} />
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup name="singleChoice" className={classes.radioGroup} value={value.toString()} onChange={handleChange}>
            {props.options?.map((option, index) => (
            <Box className={classes.rowBox} key={index}>
              <FormControlLabel classes={{label: classes.formControlLabel}} value={index.toString()} control={<Radio />} label={option.value}  />
              {displayModifier(option)}
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  )
})
