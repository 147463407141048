/**
 * Signifies which mode the navigation should be
 * viewed as.
 */
const WebSDKNavMode = {
  NO_HAMBURGER: 'no_hamburger',     // Ticket master has requested a custom navigation setup, currently only integrator using this
  HIDE_NAV: 'hide_nav',             // Hide navigation completely, usually should default to this when using the SDK
  WEB: 'web',                       // Default is web, shows navigation in original form
  getMode: function(mode) {
    switch(mode) {
      case 'no_hamburger':
        return this.NO_HAMBURGER
      case 'hide_nav':
        return this.HIDE_NAV
      case 'web':
      default:
        return this.WEB
    }
  }
}

export default WebSDKNavMode
