import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import InputAdornment from '@material-ui/core/InputAdornment'

import { useTranslation } from 'react-i18next'

//#region STYLES
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& div[role=dialog]': {
      borderRadius: '8px'
    }
  },
  dialogTitle: {
    paddingTop: '24px',
    paddingBottom: '8px'
  },
  dialogContent: {
    paddingTop: 0
  },
  dialogActions: {
    paddingTop: '16px',
    paddingRight: '16px',
    paddingBottom: '16px'
  }
}));
//#endregion

/**
 * A VN Dialog that pops up and provides an input field with cancel and save buttons.
 * @param {string} title - The title to display
 * @param {string} subTitle - The subtitle to display
 * @param {string} label - The title to display above the input field
 * @param {bool} open - the state of the dialog if it is open or closed
 * @param {bool} showTextfield - If the input textfield should be shown
 * @param {object} rules - 
 * - validation: (val) => {} - called when the dialog wants to validate the input field
 * - showHelperText: (val) => {} - what helper text to show beneath the input field
 * - onBlur: (val) => {} - Called when you blur the input field, returns a value to set the input field
 * @param {string} startAdornment - If there is an adornment to display at the front
 * @param {func} onClose - Called when the dialog wants to close
 * @param {func} onSave - Called when the dialog is ready to save
 * @param {string} type - The type of input field you want: default is 'text'
 * @param {string} cancel - The cancel button text: default is T('CANCEL')
 * @param {string} save - The save button text: default is T('SAVE')
 */
export const VNDialog = (props) => {

  const classes = useStyles()

  const t = useTranslation()

  // INTERNAL STATE

  // the current input of the dialog text
  const [inputVal, setInputVal] = useState('')

  // HANDLES

  // called on each character typed into the input field
  const onChange = (e) => {
    if (props.rules && props.rules.validation) {
      if (props.rules.validation(e.target.value)) {
        setInputVal(e.target.value)
      } 
    } else {
      setInputVal(e.target.value)
    }
  }

  // called when the save button is clicked
  const handleSave = () => {
    if (props.onSave(inputVal)) {
      props.onSave(inputVal)
    }
    if (props.onClose) {
      props.onClose()
    }
  }

  // called when cancel button is clicked
  const handleCancel = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  // called on input blur
  const handleBlur = (event) => {
    if (props.rules && props.rules.onBlur) {
      const newVal = props.rules.onBlur(event.target.value)
      setInputVal(newVal)
    }
  }

  // LOCAL FUNCTIONS

  // Get the limits for the text field
  const showHelperText = () => {
    if (props.rules && props.rules.showHelperText) {
      return props.rules.showHelperText(inputVal)
    }

    return ''
  }

  // returns an error if the validation doesn't pass
  const getInputError = () => {
    if (props.rules && props.rules.validation) {
      return !props.rules.validation(inputVal)
    }
    
    return false
  }

  return (
    <Dialog open={props.open} className={classes.dialog}>
      <DialogTitle classes={{root: props.titleClass}} className={classes.dialogTitle} id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {
          props.subTitle &&
          <DialogContentText>
            {props.subTitle}
          </DialogContentText>
        }
        {
          props.showTextfield &&
          <TextField
            error={getInputError()}
            autoFocus
            multiline
            margin="dense"
            id="vndialog"
            label={props.label}
            type={props.type ? props.type : 'text'}
            fullWidth
            value={inputVal}
            onChange={onChange}
            helperText={showHelperText()}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: <InputAdornment position="start">{props.startAdornment ? props.startAdornment : ''}</InputAdornment>,
            }}
          />
        }
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {
          props.cancel &&
          <Button onClick={handleCancel} color="secondary">
            {props.cancel ? props.cancel : t('CANCEL')}
          </Button>
        }
        <Button onClick={handleSave} color="secondary" disabled={getInputError()}>
          {props.save ? props.save : t('SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
