import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from 'react-i18next'
import useScript from '../../VNUtil/hooks/useScript'
import {  getAxsAxsClientId,
          getAxsClientId,
          getAxsClientSecret,
          getAxsClientDisplayName,
          getAxsPrivacyPolicy,
          getAxsTermsAndConditions } from '../../VNConfigurations/Selectors'
import { createOrGetUser, setCachedRoute } from '../../VNUser/ActionCreators'
import { history } from '../../App/store/configureStore'
import { getUser, getCachedRoute } from '../../VNUser/Selectors'
import { setSnackbarSystemDataAlertError } from '../../VNSnackbarSystem/ActionCreators'
import { setApiLoadingStatus } from '../../VNApiLoadingSystem/ActionCreators'
import { retrieveAllWallet } from '../../VNWallet/ActionCreators'

import ApiLoadingStatus from '../../VNApiLoadingSystem/types/ApiLoadingStatus'

const useStyles = makeStyles(theme => ({
  root: {

  },

}));

export const VNAXSLogin = () => {

  const classes = useStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch()

  // the user from redux
  const user = useSelector(state => getUser(state))
  const axsAxsClientId = useSelector(state => getAxsAxsClientId(state))
  const axsClientId = useSelector(state => getAxsClientId(state))
  const axsClientSecret = useSelector(state => getAxsClientSecret(state))
  const axsClientDisplayName = useSelector(state => getAxsClientDisplayName(state))
  const axsPrivacyPolicy = useSelector(state => getAxsPrivacyPolicy(state))
  const axsTermsAndConditions = useSelector(state => getAxsTermsAndConditions(state))
  const cachedRoute = useSelector(state => getCachedRoute(state))

  // history change
  useEffect(() => {
    if ( !user.isEmpty() && user.has('provider') && user.get('provider') === 'axs' ) {
      if (cachedRoute) {
        dispatch(setApiLoadingStatus(retrieveAllWallet.name, { status: ApiLoadingStatus.UNKNOWN }))

        const cachedRouteToGo = cachedRoute
        dispatch(setCachedRoute(null))

        history.push(cachedRouteToGo, { fromRoute: VNAXSLogin.name })
      } else {
        history.push('/wallet')
      }
    }
  }, [user])

  // add the AXS SDK
  useScript('https://id.axs.com/sdk/sdk.js')

  /*eslint-disable */
  window.AXSIDReady = function() {

    console.log('AXSIDReady')

    //Put all AXD ID related code/functions in here
    const loginHandler = function(err, status, user) {

      let errMsg = t('THERE_WAS_AN_ISSUE_SIGNING_IN')

      if (err) {

        // login failed, we cannot log this user in, throw error
        console.log(err)

        if (typeof err === 'string') {
          errMsg = err
        }

        dispatch(setSnackbarSystemDataAlertError(errMsg))
      } else if (user) {
        axsid.getProfile(function(err, userProfile) {
          if (err) {

            // cannot retrieve profile, we cannot log this user in, throw error
            console.log("Get Profile Error", err)
            if (typeof err === 'string') {
              errMsg = err
            }
            dispatch(setSnackbarSystemDataAlertError(errMsg))
          }

          onProfileFound(JSON.parse(userProfile))

          axsid.hide()
        })
      }
    }

    const params = {
      //place all params inside
      axsClientID: parseInt(axsAxsClientId),
      clientID: axsClientId,
      clientSecret: axsClientSecret,
      containerID: 'axsid_login',
      locale: 'en-US',
      clientDisplayName: axsClientDisplayName,
      privacyPolicyLink: axsPrivacyPolicy,
      termsAndConditionsLink: axsTermsAndConditions,
      loginHandler: loginHandler,
      contextID: '846431876',
      customCSS: '#axsid_main_container{background-color: white; color: #e13838;}',
      isNativeAxsLoginEnabled: true,
      load: 'signin_only'
    }

    // //Initialize Login and Show var axsid = new AXSID(params); axsid.show();
    const axsid = new AXSID(params)
    axsid.show()
  }
  /*eslint-enable */

  const onProfileFound = (profile) => {

    let userId = 0

    if (profile.additionalUserAttributes) {
      for (let i = 0; i < profile.additionalUserAttributes.length; i++) {
        const element = profile.additionalUserAttributes[i]

        // category: 2 = AXS Flash ID (also known as the back office ID)
        // region: 1 = United States of America
        if (element.category && element.category === 2 && element.region && element.region === '1') {
          userId = element.key
          break
        }
      }
    } else {
      // there is no ID to find, we cannot log this user in, throw error
      dispatch(setSnackbarSystemDataAlertError(t('NETWORK_ERROR')))
      return
    }

    if (userId === 0) {
      // no ID was found, we cannot log this user in, throw error
      dispatch(setSnackbarSystemDataAlertError(t('NETWORK_ERROR')))
      return
    }

    const tempUser = {
      email: profile.email,
      id: userId
    }

    dispatch(createOrGetUser(null, 'axs', tempUser, t, null))
  }

  return (
    <Box className={classes.root}>
      <div id="axsid_login"></div>
    </Box>
  )
}

// Override Function.name because build minification mangles function names
// and some functions aren't retaining their names properly which affects
// code in other places, namely Cart.js
Object.defineProperty(VNAXSLogin, 'name', { value: 'VNAXSLogin' })
