import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles(_theme => ({
  searchBar: {
    color: '#333',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

/**
 * A search bar with a search icon and cancel button
 * @param {Object} textFieldProps - (required) {
 *   fullWidth: Boolean,
 *   autoFocus: Boolean,
 *   id: String,
 *   placeholder: String,
 *   variant: String,
 *   InputProps: Object,
 *   value: String (required),
 *   onChange: Function (required)
 * }
 * @param {Function} onCancelButtonClick - A function that is called when the cancel button clicked
 */
export const VNSearchBar = (props) => {

  const classes = useStyles()
  const { t } = useTranslation()

  const fullWidth = !!props.textFieldProps.fullWidth
  const autoFocus = !!props.textFieldProps.autoFocus
  const textFieldId = props.textFieldProps.id
  const placeholder = typeof props.textFieldProps.placeholder === 'string' ? props.textFieldProps.placeholder : 'Search'
  const variant = props.textFieldProps.variant || 'standard'
  const InputProps = !isEmpty(props.textFieldProps.InputProps) ? props.textFieldProps.InputProps : undefined

  return (
    <Toolbar className={clsx(classes.searchBar)}>
      <IconButton
        color='inherit'
        edge='start'
        aria-label={t('SEARCH')}
      >
        <SearchIcon />
      </IconButton>
      <TextField
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        id={textFieldId}
        placeholder={placeholder}
        variant={variant}
        InputProps={InputProps}
        value={props.textFieldProps.value}
        onChange={props.textFieldProps.onChange}
      />
      <IconButton
        color='inherit'
        onClick={props.onCancelButtonClick}
        edge='end'
        aria-label={t('CANCEL')}
      >
        <CancelIcon />
      </IconButton>
    </Toolbar>
  )
}
