import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  outer_box: {
    width: "100%"
  },
}));

/**
 * Used to display a list item that has yet to be loaded from an API call
 */
export const VNSkeletonListItem = (props) => {

  const classes = useStyles();

  return (
    <ListItem disableGutters={true}>
      <Box className={classes.outer_box}>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Box>
    </ListItem>
  )
}
