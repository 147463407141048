import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from '@material-ui/core'

import { retrieveCachedPaymentInfo, retrieveShift4PaymentToken } from '../ActionCreators'
import { enableBackdropSystem, disableBackdropSystem } from '../../VNBackdropSystem/ActionCreators'

import { getCachedInfo, getShift4Token } from '../Selectors'

import { apiPlaceOrder } from '../Api'

import queryString from 'query-string'
import { history } from '../../App/store/configureStore'

import Money from '../../utils/money'

const VNi4go = require('venuenext-i4go-web-drop-in')

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#f0f2f3'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  totalContainer: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 8,
    border: `1px solid ${theme.custom.colors.lightGrey}`
  },
  standContainer: {
    marginTop: 25,
    marginBottom: 25
  },
  dropInContainer: {
    width: '100%'
  }
}))

export const VNOTP = () => {

  const classes = useStyles()

  const location = useLocation()

  const dispatch = useDispatch()

  const queryParams = queryString.parse(location.search)

  // SELECTORS
  const shift4Token = useSelector(state => getShift4Token(state))
  const cachedInfo = useSelector(state => getCachedInfo(state))

  //#region LOCAL STATE
  const [dropInInitialized, setDropInInitialized] = useState(false)
  //#endregion

  // EFFECTS
  useEffect(() => {
    console.log(cachedInfo)
    if (cachedInfo.isEmpty()) {
      dispatch(enableBackdropSystem())
      dispatch(retrieveCachedPaymentInfo(queryParams.r))
    } else {
      console.log(cachedInfo)
      dispatch(retrieveShift4PaymentToken(cachedInfo))
    }
  }, [cachedInfo])

  useEffect(() => {
    if (!dropInInitialized && shift4Token && !cachedInfo.isEmpty()) {
      dispatch(disableBackdropSystem())
      setDropInInitialized(true)

      console.log('****** shift4Token ******')
      console.log(shift4Token)

      const config = {
        token: shift4Token,
        container: 'dropin-container',
        vault: true,
        debug: true,
        tokenizeButton: false,
        edit: true,
        wallet: {
          applePay: {
            currencyCode: cachedInfo.get('currencyCode'),
            countryCode: 'US',
            label: 'ORDER NEXT',
            amount: Money.centsToDollars(cachedInfo.get('total')) || '-1.00' // negative will throw an applepay error
          },
          sdkMode: {
            isApplePayOnly: true,
            enabled: false
          }
        },
        events: (payload) => {
          console.log('Integrator Events')
          console.log(payload)
          if (payload.event === 'WALLET_PAYMENT') {
            const paymentMapping = getPaymentMapping(payload.token)
            placeOrder(paymentMapping)
          }
        }
      }

      VNi4go.dropin.create(config)
    }
  }, [dropInInitialized, shift4Token, cachedInfo])

  const getPaymentMapping = (shift4Payload) => {
    const extendedCardData = shift4Payload.i4go_extendedcarddata
    return {
      payment_type: 'shift4_token',
      amount_in_cents: cachedInfo.get('total'),
      token: shift4Payload.uniqueId ? shift4Payload.uniqueId : shift4Payload.i4go_uniqueid,
      ...extendedCardData ? {
        user_attributes: {
          extended_card_data: extendedCardData
        }
      } : {}
    }
  }

  const placeOrder = (paymentMapping) => {
    let orderParams = cachedInfo.get('orderData')

    if (Array.isArray(orderParams.payments)) {
      orderParams.payments.push(paymentMapping)
    } else {
      orderParams.payments = [paymentMapping]
    }
    dispatch(enableBackdropSystem())

    apiPlaceOrder(orderParams, cachedInfo.get('usertoken')).then(response => {
      // SDK is watching rid query parameter for transaction completion
      // Experience orders come back as response.data.id, whereas F&B or Merch comes back
      // as response.data.data.id
      const isExperience = response.data.id
      history.replace(`?rid=${isExperience ? response.data.id : response.data.data?.id}&e=${isExperience ? 1 : 0}`)
      console.log(response)
      dispatch(disableBackdropSystem())
    }).catch(error => {
      console.log(error.response?.data?.friendly_message)
      history.replace(`?rid=error&e=error&emsg=${error.response?.data?.friendly_message}`)
      dispatch(disableBackdropSystem())
    })
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.infoContainer}>
        <Box className={classes.totalContainer}>
          <Typography variant="h1">
            {`$${Money.centsToDollars(cachedInfo.get('total'))}`}
          </Typography>
        </Box>
        <Box className={classes.standContainer}>
          <Typography variant="h1">
            {cachedInfo.get('standName')}
          </Typography>
        </Box>
        <div className={classes.dropInContainer} id='dropin-container'></div>
      </Box>
    </Box>
  )
}
