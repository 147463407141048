import React, { useEffect } from "react"
import { useSelector } from 'react-redux'
import { getUser } from '../../VNUser/Selectors'
import Section from '../../components/Section'
import './OrderGratuitySection.scss'

const OrderGratuitySection = ({ suggestions, showCustomTipDialog, tipAmount, tipButtonId, updateGratuity, isRichCheckoutQR }) => {

  const user = useSelector(state => getUser(state))
  const userTipButtonId = user?.get('tipButtonId')

  useEffect(() => {
    if (tipButtonId === undefined && tipAmount === undefined) {
      let idx = -1

      if (typeof userTipButtonId === 'number' && userTipButtonId > -1 && userTipButtonId < suggestions.length) {
        // check if user has a preference saved
        idx = userTipButtonId
      } else {
        // check if there is a default tip suggestion
        idx = suggestions.findIndex(s => s.tipSelected)
      }

      if (idx > -1 && suggestions[idx] && suggestions[idx].tipCustom !== true) {
        updateGratuity(suggestions[idx].tipAmountInCents, idx, isRichCheckoutQR)
      }

    } else if (typeof tipButtonId === 'number' && tipButtonId > -1) {
      const actualAmount = suggestions[tipButtonId]?.tipAmountInCents
      if (actualAmount !== tipAmount && typeof actualAmount === 'number') {
        updateGratuity(actualAmount, tipButtonId, isRichCheckoutQR)
      }
    }
  }, [suggestions])

  const createUnselectedButton = (title, amount, custom, key) => {
    return (<span key={key} onClick={ custom ? () => showCustomTipDialog(key) : () => updateGratuity(amount, key, isRichCheckoutQR) }>{title}</span>)
  }

  const createSelectedButton = (title, amount, custom, key) => {
    return(<span key={key} className="selected" onClick={ custom ? () => showCustomTipDialog(key) : null }>{title}</span>)
  }

  const createButton = (title, selected, amount, custom, key) => {
    if (tipButtonId !== undefined) { // page loaded and using it
      if (key === tipButtonId || (tipButtonId === "CUSTOM" && custom) ) {
        return(createSelectedButton(title, amount, custom, key))
      } else {
        return(createUnselectedButton(title, amount, custom, key))
      }
    } else { // on first load, no state rehydration
      if (selected) {
        return(createSelectedButton(title, amount, custom, key))
      } else {
        return(createUnselectedButton(title, amount, custom, key))
      }
    }
  }

  const displaySuggestions = () => {
    return suggestions.map((s, index) => {
      return createButton(s.tipDisplay, s.tipSelected, s.tipAmountInCents, s.tipCustom, index)
    })
  }

  return (
    <Section>
      <div className="order-gratuity-section btn-group">{displaySuggestions()}</div>
    </Section>
  )
}

export default OrderGratuitySection
