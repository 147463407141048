import values from 'lodash/values'
import numeral from 'numeral'

class Analytics {
  // Enhanced Ecommerce Funnel Part 1, viewing an item
  // Google Tag Manager Ecommerce Event track item views
  // https://developers.google.com/tag-manager/enhanced-ecommerce#details
  static generateItemViewedEvent = (item) => {
    window.dataLayer.push({
      'event': 'detail',
      'ecommerce': {
        'detail': {
          'products': [{
            'id': item.uuid,
            'name': item.name,
            'price': item.defaultPriceInCents / 100
          }]
        }
      }
    })
  }

  // Enhanced Ecommerce Funnel Part 2, adding an item to the cart
  // Google Tag Manager Ecommerce Event add item to cart
  // https://developers.google.com/tag-manager/enhanced-ecommerce#add
  static generateAddToCartEvent = (item, quantity) => {
    const itemName = item.variantGroupDisplay || item.name

    window.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'add': {
          'products': [{
            'id': item.uuid,
            'name': itemName,
            'price': item.defaultPriceInCents / 100,
            'quantity': quantity,
            'variant': item.variantName || ''
          }]
        }
      }
    })
  }

  // Enhanced Ecommerce Funnel Part 3.1, viewing the cart for checkout
  // Google Tag Manager Ecommerce Event successful cart load
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkoutstep
  static generateOrderCheckoutEvent = (items) => {
    const products = values(items).map((item) => {
      return {
        'id': item.id,
        'name': item.name,
        'price': item.defaultPriceInCents / 100,
        'quantity': item.quantity,
        'variant': item.variantName || ''
      }
    })

    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 1
          },
          products
        }
      }
    })
  }

  // Enhanced Ecommerce Funnel Part 3.2, entering payment information
  // When a user enters payment information, tell Ecommerce that they have done so
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkoutstep
  static paymentMethodEntered = (data = {}) => {
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 2,
            'option': data.type
          }
        }
      }
    })
  }

  // Enhanced Ecommerce Funnel Part 3.3, completing the purchase
  // Google Tag Manager Event successful order creation
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
  static generateOrderSuccessEvent = (order, lineItems) => {
    const products = values(lineItems).map((lineItem) => {
      const quantity = lineItem.attributes.quantity
      const unitPriceInCents = quantity ? lineItem.attributes.totalAmountInCents / quantity : 0
      return {
        'name': lineItem.variantGroupDisplayName || lineItem.attributes.name,
        'id': lineItem.attributes.menuItemUuid,
        'category': lineItem.attributes.category,
        'brand': order.attributes.standName,
        'price': unitPriceInCents / 100,
        'quantity': quantity,
        'variant': lineItem.variantName || ''
      }
    })

    const fees = numeral(order.attributes.deliveryFee).add(numeral(order.attributes.serviceCharge).value())
    const shipping = fees.format('0.00')

    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': order.id,
            'affiliation': `${order.attributes.standName} - ${order.attributes.usageType}`,
            'revenue': order.attributes.total,
            'tax': order.attributes.tax,
            'shipping': shipping
          },
          products
        }
      }
    })

    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 3
          }
        }
      }
    })
  }

  // Enhanced Ecommerce Funnel Part 3.4, entering email address
  // Google Tag Manager Code - 'Email DataLayer Edited' Trigger
  // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout_option
  static emailAddressEntered = (email) => {
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 4,
            'option': email
          }
        }
      }
    })
  }
}

export default Analytics
