import React, {Fragment} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  button: {
    color: 'white',
    textDecoration: 'underline',
    fontWeight: 800,
  },
  snackbar: {
    background: props => props.cssProps ? props.cssProps.background : 'red',
    fontWeight: 600,
  },
  rootSnackbar: {
    display: 'block',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}));

/**
 *
 * A default VNSnackbar that is set to our VN Style and design.
 * Currently you should use an alert OR an action to display the snackbar.
 *
 * Requires you to set and maintain snackbar data object outside this during implementation
 * Example:
 * setSnackbarData({
 *           open: true,
 *          action: {
 *            title: t('SIGN_UP'),
 *            onClick: () => {
 *              history.push('/profile/signup')
 *            }
 *          },
 *          cssProps: {
 *            background: 'red',
 *          },
 *          message: t('NO_PHONE_USER_EXISTS'),
 *        })
 *
 * const displaySnackbar = () => {
 *  if (snackbarData.hasOwnProperty('open')) {
 *    return(<VNSnackbar  cssProps={snackbarData.cssProps ? snackbarData.cssProps : null}
 *                        alert={snackbarData.alert ? snackbarData.alert : null}
 *                        action={snackbarData.action ? snackbarData.action : null}
 *                        message={snackbarData.message}
 *                        open={snackbarData.open}
 *                        onHandleClose={() => {setSnackbarData({})}} />)
 *  }
 *}
 *
 * @props
 *
 * Required
 * open: Boolean - true to show it, false otherwise
 * message: String - What to display in the snackbar
 * cssProps: Obj -
 *              - background: String: The color you want the snackbar to be if you are using an action
 *
 * Optional
 * autoHideDuration: int | null - (Default: 5000) - how long until the snackbar auto closes, auto hide will be disabled if it is null
 * onHandleClose: func - is callack automatically when the snackbar is closed
 * alert: String - Shows an alert message - Options: 'success', 'error', 'warning', 'info'
 * action: Obj -
 *              - title: The button title to show as the action
 *              - onClick: func: What happens when the action button is clicked?
 */
export const VNSnackbar = (props) => {

  const classes = useStyles(props)


  //  HANDLES

  // onClose event
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (props.onHandleClose) {
      props.onHandleClose()
    }
  }

  const handleExit = () => {
    if (props.onHandleClose) {
      props.onHandleClose()
    }
  }

  // displays an alert message, these do NOT have buttons for actions, just a notice of sorts
  const displayAlert = () => {
    if (props.alert) {
      return (
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={props.alert}>
          {props.message}
        </MuiAlert>
      )
    }
  }

  // displays an actionable message with button that you can click on
  const displayAction = () => {
    return(
      <Fragment>
        <Button className={classes.button} size="small" onClick={() => {
          props.action.onClick()
          handleClose()
        }}>
          {props.action.title}
        </Button>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Fragment>
    )
  }

  return (
      <Snackbar
        classes={{root: classes.rootSnackbar}}
        ContentProps={{
          classes: {
            root: classes.snackbar
          }
        }}
        open={props.open}
        autoHideDuration={(props.autoHideDuration || props.autoHideDuration === null) ? props.autoHideDuration : 5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        action={props.action ? displayAction() : null}
        onClose={handleClose}
        onExited={handleExit}
        message={props.alert ? null : props.message}>
        {displayAlert()}
      </Snackbar>
  )
}
