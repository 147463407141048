import { isEmpty } from 'lodash'
import { Map } from 'immutable'

/**
 * Get the rvcFilterObj that contains noWaitTimes, serviceTypes,
 * locations and categories
 * @param {Object} state - The state
 * @returns {Object} {
 *  noWaitTimes: Boolean,
 *  serviceTypes: Array<String>,
 *  locations: Array<String>,
 *  categories: Array<String>
 * }
 */
export const getRvcFilterObj = (state) => {
  const rvcFilterObj = state.VNFilter.get('rvcFilterObj')
  const queryParamRvcFilterObj = state.VNFilter.get('queryParamRvcFilterObj')

  const serviceTypes = rvcFilterObj.get('serviceTypes')
  const locations = rvcFilterObj.get('locations')
  const categories = rvcFilterObj.get('categories')

  const queryParamServiceTypes = queryParamRvcFilterObj.get('serviceTypes')
  const queryParamLocations = queryParamRvcFilterObj.get('locations')
  const queryParamCategories = queryParamRvcFilterObj.get('categories')

  // [...new Set([...])] is ES6 way of de-duplicating array set
  return Map({
    noWaitTimes: rvcFilterObj.get('noWaitTimes') || queryParamRvcFilterObj.get('noWaitTimes'),
    serviceTypes: [...new Set([
      ...serviceTypes ? serviceTypes : [],
      ...queryParamServiceTypes ? queryParamServiceTypes: []
    ])],
    locations: [...new Set([
      ...locations ? locations: [],
      ...queryParamLocations ? queryParamLocations : []
    ])],
    categories: [...new Set([
      ...categories ? categories: [],
      ...queryParamCategories ? queryParamCategories : []
    ])]
  })
}

/**
 * Get whether or not filters have been set for revenue centers
 * @param {Object} state - The state
 * @returns {Boolean}
 */
export const getRvcFilterEmpty = (state) => {
  const rvcFilterObj = getRvcFilterObj(state)

  const noWaitTimes = rvcFilterObj.get('noWaitTimes')
  const locations = rvcFilterObj.get('locations')
  const categories = rvcFilterObj.get('categories')

  const serviceTypeSelected = state.VNQueryParams.get('serviceTypeSelected')

  return !noWaitTimes && isEmpty(locations) && isEmpty(categories) && serviceTypeSelected === 'All'
}
