import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ItemSetting from '../VNEnums/ItemSetting'
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    width: 180,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    fontSize: 40
  }
}));

/**
 * A Counter that has the count in the middle with a left and right circle buttons on either side.
 * @props
 *
 * Required
 *
 * Optional
 * default : int - Default value of the counter
 * min: int - The minimum amount the counter can be set to
 * max: int - The maximum amount that the counter can be set to. Counter will be disabled if it is zero.
 * incrementor: int - How much the counter changes per button press either up or down
 */
export const VNCounter = forwardRef((props, ref) => {

  const classes = useStyles()

  const theme = useTheme()

  // LOCAL STATE

  // The current count
  const [counter, setCounter] = useState(Number.isFinite(props.default) ? props.default : 1)

  // The minimum value
  const minimum = Number.isFinite(props.min) ? props.min : 1

  // The maximum value
  const maximum = Number.isFinite(props.max) ? props.max : ItemSetting.DEFAULT_MAX_AMOUNT

  // The incrementor
  const incrementor = props.incrementor ? props.incrementor : 1

  // exposed functions to the parent
  useImperativeHandle(ref, () => ({

    // get what the value on the counter is
    getValue() {
      return counter
    }
  }))

  // HANDLES

  // called when each button is pressed
  const handleClick = (change) => {
    const newValue = getNewCounter(change)
    setCounter(newValue)
    if (props.onChange) {
      props.onChange(newValue)
    }

    if (change === 'decrement' && props.onDecrementChange) {
      props.onDecrementChange()
    }
    if (change === 'increment' && props.onIncrementChange) {
      props.onIncrementChange()
    }
  }

  // LOCAL FUNCTIONS

  // retrieve the new value of the counter depending on which button was pushed
  const getNewCounter = (change) => {

    var newValue

    if (change === 'decrement') {
      newValue = counter - incrementor
      if (newValue < minimum) {
        newValue = minimum
      }
      return newValue
    } else if (change === 'increment') {
      newValue = counter + incrementor
      if (newValue > maximum) {
        newValue = maximum
      }
      return newValue
    }
  }

  const getColor = (change) => {
    if (change === "decrement") {
      if (counter === minimum) {
        return "primary"
      }
    } else if (change === "increment") {
      if (counter === "maximum") {
        return "primary"
      }
    }

    return "secondary"
  }

  const isDisabled = (change) => {
    if (maximum === 0) {
      return true
    }
    if (change === "decrement") {
      if (counter === minimum || props.decrementDisabled) {
        return true
      }
    } else if (change === "increment") {
      if (counter === maximum || props.incrementDisabled) {
        return true
      }
    }
    return false
  }

  return (
    <Box className={props.rootClasses || classes.root}>
      <Button
        className={props.buttonClasses || ''}
        disabled={isDisabled("decrement")}
        color={getColor("decrement")}
        onClick={() => handleClick('decrement')}
      >
        <RemoveCircleIcon className={props.iconClasses || classes.icon} />
      </Button>
      <Typography style={props.counterStyles || theme.custom.h8} className={props.counterClasses || classes.counter}>{counter}</Typography>
      <Button
        className={props.buttonClasses || ''}
        disabled={isDisabled("increment")}
        color={getColor("increment")}
        onClick={() => handleClick('increment')}
      >
        <AddCircleIcon className={props.iconClasses || classes.icon} />
      </Button>
    </Box>
  )
})
