import * as ActionTypes from './ActionTypes'

import { setSnackbarSystemDataAlertError, setSnackbarSystemDataAlertInfo, setSnackbarSystemDataAlertSuccess } from '../VNSnackbarSystem/ActionCreators'
import { disableBackdropSystem, enableBackdropSystem } from '../VNBackdropSystem/ActionCreators'
import * as VNWebAccountActionTypes from '../VNWebAccount/ActionTypes'
import * as VNWalletActionTypes from '../VNWallet/ActionTypes'
import { setApiLoadingStatus } from '../VNApiLoadingSystem/ActionCreators'
import {
  apiGetToken,
  apiGetUser,
  apiUpdateUser,
  apiGetUserAffiliations,
  apiLogoutUser,
  apiGetStadiumUser,
  apiGetUserPaymentMethods,
  apiGetCardAffiliations,
  apiRefreshUsersAffiliations,
  apiGetTicketAffiliations,
} from './Api'

import { getUser as getUserSelector, getIsNewLogin } from './Selectors'
import { getUserJWT } from './Selectors'
import { getShift4Token } from '../VNWebAccount/Selectors'
import { getVirtualCurrencyEnabled, getRefreshAffiliationsTimer, getTicketAffiliationsByProviderEnabled } from '../VNConfigurations/Selectors'

import { parseJWT } from '../App/Utils'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'
import { timeLeft } from '../VNUtil/VNTimeHelper'
import moment from 'moment-timezone'

// THUNK
// Go create or retrieve a user given their oauth code (or userID)
// code: code provided from oauth provider - can also be a user UUID
// provider: string - provider name: 'ticketmaster', 'vn_anonymous', 'vn_sms_user', 'vn_external_user', 'axs'
// user: {} - (optional) - Used to create a user based off of this data
// t: func - (optional) - The translator object from a hook
// bearerToken: (optional) - A signed user as a JWT
export function createOrGetUser(code, provider, user, t, bearerToken) {
  return (dispatch, getState) => {

    dispatch(setApiLoadingStatus(
      createOrGetUser.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    if (provider === 'vn_anonymous') {
      dispatch({
        type: ActionTypes.VNUSER_SET_IS_NEW_LOGIN,
        isNewLogin: true
      })
    }

    apiGetToken(code, provider, user, bearerToken).then(response => {
      const parsedJWT = parseJWT(response.data)

      let user = {
        jwt: response.data,
        ...parsedJWT
      }

      return user

    }).then(user => {
      const state = getState()
      const isVirtualCurrencyEnabled = getVirtualCurrencyEnabled(state)

      // automatically turn on VC toggle if
      // 1. there is a provider and the provider is not 'vn_anonymous'
      // 2. virtual currency is enabled for this ordernext instance
      // 3. user has logged in successfully (user.jwt exists)
      // 4. user did NOT turn off VC toggle explicitly ('useVirtualCurrencyFirst === false' means user turned off VC toggle explicitly)
      if (provider && provider !== 'vn_anonymous' && isVirtualCurrencyEnabled && user.jwt && user.useVirtualCurrencyFirst !== false) {
        user.useVirtualCurrencyFirst = true
      }

      dispatch({
        type: ActionTypes.VNUSER_SET_USER,
        user: user
      })

      dispatch(setApiLoadingStatus(
        createOrGetUser.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))

    }).catch(error => {

      dispatch(disableBackdropSystem())

      if (error && error.response) {
        if (error.response.data.error.moreInfo) {
          if (t) {
            dispatch(setSnackbarSystemDataAlertError(t('NEED_NEW_CODE')))
          }
        } else if (error.response.data?.error?.details) {
          // if verification failed from Twilio
          if (t) {
            dispatch(setSnackbarSystemDataAlertError(t('INVALID_CODE')))
          }
        } else if (typeof error.response.data?.error === 'string') {
          // display error message from server
          dispatch(setSnackbarSystemDataAlertError(error.response.data.error))
        } else if (error.response.data?.error?.message) {
          // display error message from server
          dispatch(setSnackbarSystemDataAlertError(error.response.data.error.message))
        } else {
          // no error message found, display a generic error message
          if (t) {
            dispatch(setSnackbarSystemDataAlertError(t('UNKNOWN_ERROR')))
          }
        }
      } else {
        dispatch(setSnackbarSystemDataAlertError(t('NETWORK_ERROR')))
      }

      dispatch(setApiLoadingStatus(
        createOrGetUser.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}

/**
 * Call to VNAPI to get user
 * @param {string} id
 * @param {string} email
 * @param {string} phone
 */
export function getUser(id, email, phone) {
  return (dispatch, getState) => {
    const user = getUserSelector(getState())
    const jwt = getUserJWT(getState())

    let method
    let param

    if (id) {
      method = 'id'
      param = id
    } else if (email) {
      method = 'email'
      param = email
    } else if (phone) {
      method = 'phone'
      param = phone
    } else {
      return
    }

    apiGetUser(jwt, method, param).then(response => {
      dispatch(disableBackdropSystem())
      dispatch({
        type: ActionTypes.VNUSER_SET_USER,
        user: user.merge(response.data).toJSON()
      })
    }).catch(error => {
      dispatch(disableBackdropSystem())
      console.log(error)
    })
  }
}

export function fetchStadiumUser() {
  return (dispatch, getState) => {
    const user = getUserSelector(getState())
    const jwt = getUserJWT(getState())

    apiGetStadiumUser(user.get('userID'), jwt).then(response => {
      dispatch({
        type: ActionTypes.VNUSER_SET_STADIUM_USER,
        stadium_user: response.data
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

/**
 * Get the payment methods that belong to a user from the user service
 */
export function fetchUserPaymentMethods() {
  return (dispatch, getState) => {
    const user = getUserSelector(getState())
    const jwt = getUserJWT(getState())

    apiGetUserPaymentMethods(user.get('userID'), jwt).then(response => {
      dispatch({
        type: ActionTypes.VNUSER_SET_USER_PAYMENT_METHODS,
        payment_methods: response.data
      })
    }).catch(error => {
      // an error on this endpoint means that there are no payment methods.
      // so we clear ours
      dispatch({
        type: ActionTypes.VNUSER_SET_USER_PAYMENT_METHODS,
        payment_methods: {}
      })
    })
  }
}

/**
 * log the user out and reset all state within VNWebAccount
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const jwt = getUserJWT(getState())

    // log the user out on the server, mostly used for Ticketmaster
    // will return ERROR's if the user is trying to log out
    // we really don't care what the response is
    apiLogoutUser(jwt)

    dispatch({
      type: ActionTypes.VNUSER_SET_USER,
      user: {}
    })
    dispatch({
      type: ActionTypes.VNUSER_RESET_AFFILIATIONS_REFRESHED
    })
    dispatch({
      type: ActionTypes.VNUSER_SET_STADIUM_USER,
      stadium_user: {}
    })
    dispatch({
      type: ActionTypes.VNUSER_SET_USER_PAYMENT_METHODS,
      payment_methods: []
    })
    dispatch({
      type: VNWebAccountActionTypes.VNWEBACCOUNT_SET_BRAINTREE_CLIENT_TOKEN,
      token: ''
    })
    dispatch({
      type: VNWebAccountActionTypes.VNWEBACCOUNT_SET_SEATS,
      seats: []
    })
    dispatch({
      type: VNWebAccountActionTypes.VNWEBACCOUNT_SET_PASS,
      pass: {}
    })
    dispatch({
      type: VNWebAccountActionTypes.VNWEBACCOUNT_SET_ORDER_HISTORY,
      orders: {}
    })
    dispatch({
      type: VNWalletActionTypes.VNWALLET_LOYALTY_USER,
      loyalty_user: {}
    })
    dispatch({
      type: VNWalletActionTypes.VNWALLET_VIRTUAL_CURRENCY_ACTIVITY,
      virtual_currency_activity: []
    })
    dispatch({
      type: VNWalletActionTypes.VNWALLET_AWARDS_RULES,
      awards_rules: []
    })
    dispatch({
      type: VNWalletActionTypes.VNWALLET_GET_QR_NONCES,
      nonces: []
    })
    dispatch({
      type: VNWalletActionTypes.VNWALLET_GET_ORDER_TOTAL,
      order: {}
    })
    dispatch({
      type: VNWalletActionTypes.VNWALLET_SET_VIRTUAL_CURRENCY_TRANSFER_EMAIL_ACCEPT,
      email: ''
    })
  }
}

/**
 * Call to VNAPI for updating user
 * @param {object} data - JSON object of user information
 * @param {UseTranslationResponse} t - Translate function
 */
export const updateUser = (data, t) => {
  return(dispatch, getState) => {
    const user = getUserSelector(getState())
    const jwt = user.get('jwt')
    const mergedUserData = user.merge(data)

    // Check if user is deleting email, if so we need to
    // send existing email to VNAPI so that it has a Sort Key
    // to delete the appropriate user entry row.
    // If email doesn't exist, send ID as Sort Key to conform to backend
    // composite key search
    let jsonData = mergedUserData.toJSON()
    if (!jsonData.email) {
      jsonData.old_sk_value = user.get('email')
    } else if (!user.has('email')) {
      jsonData.old_sk_value = user.get('userID')
    } else {
      jsonData.old_sk_value = user.get('email')
    }

    dispatch(setApiLoadingStatus(
      updateUser.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    apiUpdateUser(jwt, jsonData).then(response => {
      dispatch(disableBackdropSystem())
      if (t) {
        dispatch(setSnackbarSystemDataAlertInfo(t('USER_UPDATE_SUCCESS')))
      }
      dispatch({
        type: ActionTypes.VNUSER_SET_USER,
        user: response.data
      })
      dispatch(setApiLoadingStatus(
        updateUser.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    }).catch(error => {
      dispatch(disableBackdropSystem())
      console.log(error)
      if (typeof error.response.data?.error === 'string') {
        // display error message from server
        dispatch(setSnackbarSystemDataAlertError(error.response.data.error))
      } else if (error.response.data?.error?.message) {
        // display error message from server
        dispatch(setSnackbarSystemDataAlertError(error.response.data.error.message))
      }
      dispatch(setApiLoadingStatus(
        updateUser.name,
        { status: ApiLoadingStatus.FAILED }
      ))
    })
  }
}

/**
 * Call the VNAPI and retrieve the detailed affiliations that are associated with this user
 * Note: not all affiliations have details that are stored in VNAPI (PCH)
 */
export const fetchUserAffiliations = () => {
  return(dispatch, getState) => {
    const state = getState()
    const jwt = getUserJWT(state)
    const user = getUserSelector(state)
    const provider = user.get('provider')

    apiGetUserAffiliations(jwt, provider).then(response => {
      dispatch({
        type: ActionTypes.VNUSER_SET_USER_DETAILED_AFFILIATIONS,
        affiliations: response
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

/**
 * Call the VNAPI to refresh any affiliations on the user with the provider
 * @param {Object} t - Translation object
 * @param {Boolean} ignoreSnackbar - true, if we want to ignore the call to show the snackbar message
 * @param {Boolean} ignoreDisableBackdropSystem - true, if we want to ignore the call to disable the backdrop system
 * @returns
 */
export const refreshUsersAffiliations = (t, ignoreSnackbar = false, ignoreDisableBackdropSystem = false) => {
  return async (dispatch, getState) => {

    dispatch(setApiLoadingStatus(
      refreshUsersAffiliations.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    const state = getState()
    const user = getUserSelector(state)
    const provider = user.get('provider')
    const jwt = getUserJWT(state)
    const shift4Token = getShift4Token(state)
    const refreshUsersAffiliationsTime = getRefreshAffiliationsTimer(state)
    const getTicketAffiliationsEnabled = getTicketAffiliationsByProviderEnabled(state, provider)
    const isNewLogin = getIsNewLogin(state)

    try {
      let refresh

      // THE INTENTION HERE IS TO MINIMIZE API CALLS TO AUTH PROVIDERS
      // if this is a new login and user auth provider is found on the list
      // we should clear isNewLogin and skip apiRefreshUsersAffiliations call
      // since user affiliations have been checked during apiGetToken call
      if (isNewLogin) {
        dispatch({
          type: ActionTypes.VNUSER_SET_IS_NEW_LOGIN,
          isNewLogin: undefined
        })
      } else {
        try {
          refresh = await apiRefreshUsersAffiliations(jwt, shift4Token)

          const parsedJWT = parseJWT(refresh.data.jwt)

          const dbUser = {
            jwt: refresh.data.jwt,
            ...parsedJWT
          }

          const mergedUser = Object.assign({}, user.toJSON(), dbUser)

          dispatch({
            type: ActionTypes.VNUSER_SET_USER,
            user: mergedUser
          })
        } catch (e) {
          console.log(e)
        }
      }

      if (getTicketAffiliationsEnabled) {
        // ticket affiliations will be added on platform, nothing needs to be done here
        // user will receive these affiliations in apiGetUserAffiliations below
        try {
          await apiGetTicketAffiliations(jwt, provider)
        } catch (e) {
          console.log(e)
        }
      }

      try {
        // use refreshed JWT if it exists
        const jwtToUse = refresh?.data?.jwt || jwt
        const affiliations = await apiGetUserAffiliations(jwtToUse, provider)

        dispatch({
          type: ActionTypes.VNUSER_SET_USER_DETAILED_AFFILIATIONS,
          affiliations: affiliations
        })
      } catch (e) {
        console.log(e)
      }

      dispatch({
        type: ActionTypes.VNUSER_SET_AFFILIATIONS_REFRESHED
      })

      dispatch(setApiLoadingStatus(
        refreshUsersAffiliations.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))

      if (!ignoreDisableBackdropSystem) {
        dispatch(disableBackdropSystem())
      }

      if (!ignoreSnackbar) {

        // a provider had an issue attempting to update their affiliations
        if (refresh.data.error) {
          const timeRemaining = timeLeft(moment(), refreshUsersAffiliationsTime, 'minutes')
          dispatch(setSnackbarSystemDataAlertError(t('AFFILIATIONS_PROFILE_NOT_REFRESHABLE') + " " + timeRemaining + " " + t('MINUTES')))
        } else {
          dispatch(setSnackbarSystemDataAlertSuccess(t('AFFILIATIONS_UPDATED')))
        }
      }

    } catch (error) {
      dispatch(setApiLoadingStatus(
        refreshUsersAffiliations.name,
        { status: ApiLoadingStatus.FAILED }
      ))
      dispatch(disableBackdropSystem())
      console.log(error)
    }
  }
}

export const getCardAffiliations = (cardBin) => {
  return async (dispatch, getState) => {
    const jwt = getUserJWT(getState())

    try {
      const response = await apiGetCardAffiliations(jwt, cardBin)

      dispatch({
        type: ActionTypes.VNUSER_SET_CREDIT_CARD_AFFILIATIONS,
        cardBin: cardBin,
        affiliations: response.data.affiliations
      })
    } catch (error) {
      console.log(error)
    }
  }
}

/**
 * Called after createExperienceOrderSucceeded in order saga to keep track
 * of anonymous user experience purchases. This shouldn't really be used
 * for anything other than that.
 * @param {object} order - Order object from order saga
 */
export const setAnonymousExperienceOrderHistory = (user, order) => ({
  type: ActionTypes.VNUSER_SET_ANON_EXPERIENCE_ORDER_HISTORY,
  user: user,
  order: order
})

/**
 * Called when the user has viewed the payment reminder dialog so it doesn't popup again.
 */
export const setViewedPaymentReminder = () => {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.VNUSER_SET_VIEWED_PAYMENT_REMINDER,
      reminder: true
    })
  }
}

/**
 * Set the anonymous user data which consists of First/Last name and
 * email. Primarily used to cache the Cart form data if the user is anonymous,
 * and to auto fill out the form in subsequent anonymous user Cart viewings.
 * Will not be used if the user is not a vn_anonymous_user
 * @param {object} data
 * @param {string} data.first - First name
 * @param {string} data.last - Last name
 * @param {string} data.email - Email address
 */
export const setAnonymousUserData = (data) => {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.VNUSER_SET_ANONYMOUS_USER_DATA,
      data: data
    })
  }
}

/**
 * Set a cached pathname/route. This is utilized when the user logs in and we want
 * to redirect them to the page they were on before they logged in. Also used to prevent
 * clearing of the cart when this flow happens.
 * @param {string} pathname
 * @returns
 */
 export const setCachedRoute = (pathname) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNUSER_SET_CACHED_ROUTE,
      route: pathname
    })
  }
}

/**
 * Set a flag to indicate that if user data is loaded
 * @param {Boolean} loaded - If the user is loaded
 */
export const setUserLoaded = (loaded) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNUSER_SET_USER_LOADED,
      loaded: loaded
    })
  }
}

/**
 * Contact VNAPI to check for affiliations on tickets
 * Then contact Stadium to get affiliations
 */
export const getTicketAffiliations = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const user = getUserSelector(state)
    const provider = user.get('provider')
    const jwt = user.get('jwt')

    dispatch(enableBackdropSystem())

    dispatch(setApiLoadingStatus(
      getTicketAffiliations.name,
      { status: ApiLoadingStatus.LOADING }
    ))

    try {
      // response is not needed here
      // VNPAI would send a request to create affiliations in Stadium
      await apiGetTicketAffiliations(jwt, provider)

      try {
        // check for affiliations in Stadium
        const affiliations = await apiGetUserAffiliations(jwt, provider)
  
        dispatch({
          type: ActionTypes.VNUSER_SET_USER_DETAILED_AFFILIATIONS,
          affiliations: affiliations
        })
      } catch (e) {
        console.log(e)
      }

      dispatch(setApiLoadingStatus(
        getTicketAffiliations.name,
        { status: ApiLoadingStatus.SUCCEEDED }
      ))
    } catch (e) {
      dispatch(setApiLoadingStatus(
        getTicketAffiliations.name,
        { status: ApiLoadingStatus.FAILED }
      ))
      console.log(e)
    }

    dispatch(disableBackdropSystem())
  }
}
