import { createSelector } from 'reselect'
import { getItems } from './item'
import { flatten, get, groupBy, keys } from 'lodash'

import { getItemsInMenu} from '../VNMenu/Selectors'

export const getCartItems = state => get(state, 'cart.item', {})

export const getCartItem = (state, id) => get(state, 'cart.item', {})[id] || {}

export const getItemModifierRelationships = (state) => get(state, 'cart.itemModifierRelationships', {})

export const getCartMenuId = (state) => get(state, 'cart.menuId', undefined)

export const getIsCartEmpty = state => keys(getCartItems(state)).length === 0

export const getCanAddToCart = state => !!state.cart?.canAddToCart

export const makeGetCartItem = () => (
  createSelector([getCartItem], (item) => item)
)

const makeVariantCartItems = (cartItem, menuItem, variants) => {
  const combined = flatten(get(cartItem, 'modifiers', []))
  const grouped = groupBy(combined, 'menuItemUuid')
  let newCartItems = {}

  keys(grouped).map((key) => {
    const itemInCart = grouped[key][0]
    const quantity = grouped[key].length
    const variant = variants.find(v => v.menuItemUuid === itemInCart.menuItemUuid)
    const variantName = { name: menuItem.variantGroupDisplayName }
    const variantPrice = { defaultPriceInCents: variant.priceInCents }

    const attrs = {
      ...menuItem,
      ...variantName,
      ...variantPrice,
      id: itemInCart.menuItemUuid,
      isVariant: true,
      modifiers: [],
      quantity: quantity,
      variantName: variant.name,
      variantParentId: menuItem.id,
    }

    return newCartItems[key] = attrs
  })

  return newCartItems
}

const makeExperienceItem = (cartItem, menuItem) => {
  const eventItem = cartItem.variant
  const id = eventItem.menuItemUuid
  const name = { name: get(menuItem, 'name', '') }
  const price = { defaultPriceInCents: eventItem.price * 100 }

  // If sale is not null, that means we have an exclusivity attached
  // to this experience item, which means retrieval of the sales id
  // will be different
  const sale = get(cartItem, 'sale', null)

  const attrs = {
    ...menuItem,
    ...name,
    ...price,
    id: id,
    isExperience: true,
    merchantId: eventItem.merchantId,
    metadata: cartItem.metadata,
    modifiers: [],
    quantity: cartItem.quantity,
    saleId: sale ? sale.id : eventItem.saleId,
  }

  return { [id]: attrs }
}

export const getCartMenuItems = (state) => {
  const cartItems = getCartItems(state)
  const cartItemIds = keys(cartItems)
  const activeMenuId = getCartMenuId(state)
  const menuItems = getItemsInMenu(state, activeMenuId)

  const items = cartItemIds.reduce((accumulator, id) => {
    const cartItem = cartItems[id]
    const menuItem = menuItems?.find(menuItem => menuItem.id === id)
    const variants = get(menuItem, 'variants', [])

    if (cartItem.productType === "Experience") return makeExperienceItem(cartItem, menuItem)

    let variantItems = null
    let modifiers = []

    // variants[0]?.name is used to check if an item has only one variant
    // item with only one variant still needs to be transformed through makeVariantCartItems
    if (variants.length > 1 || variants[0]?.name) {
      variantItems = makeVariantCartItems(cartItem, menuItem, variants)
    } else {
      let allItemModifiers = {}

      get(menuItem, 'modifierGroups', []).forEach((group) => {
        const container = group.modifierPropertiesContainer
        container.items.forEach((item) => {
          allItemModifiers[item.id] = item
        })
      })

      modifiers = get(cartItem, 'modifiers', []).map((modifierGroup) => {
        return modifierGroup.map(modifier => {
          const modToReturn = { ...allItemModifiers[modifier.menuItemUuid] }
          modToReturn.quantity = modifier.quantity
          // modifierGroupUuid is used to identify which modifier group this modifier belongs to
          if (modifier.modifierGroupUuid) {
            modToReturn.modifierGroupUuid = modifier.modifierGroupUuid
          }
          return modToReturn
        })
      })
    }

    const itemsToAdd = variantItems || { [id]: { ...menuItem, ...cartItems[id], modifiers } }

    return {
      ...accumulator, ...itemsToAdd
    }
  }, {})

  return items
}

export const makeGetCartMenuItems = () => {
  return createSelector([getCartMenuItems], (menuItems) => menuItems)
}

const getCartProperties = createSelector(
  [getCartMenuItems],
  (cartMenuItems) => {
    const cartItemIds = keys(cartMenuItems)

    return cartItemIds.reduce((accumulator, id) => {
      const item = cartMenuItems[id]

      if (get(item, 'specialType') === 'delivery_fee') {
        return { subtotal: accumulator.subtotal, quantity: accumulator.quantity }
      }

      let modifierTotal = 0

      item.modifiers.map((modGroup) => {
        return modGroup.map((mod) => modifierTotal += mod.defaultPriceInCents * mod.quantity)
      })

      return {
        subtotal: accumulator.subtotal + modifierTotal + item.quantity * item.defaultPriceInCents,
        quantity: accumulator.quantity + item.quantity,
      }
    }, {subtotal: 0, quantity: 0})
  }
)

export const makeGetCartProperties = () => {
  return createSelector([getCartProperties], (cartMenuItems) => cartMenuItems)
}

export const getAlcoholicItemQuantityInCart = createSelector(
  [getCartMenuItems],
  (items) => {
    const quantity = keys(items).reduce((accumulator, itemId) => {
      const item = items[itemId]
      const isAlcohol = item.isAlcohol && item.quantity > 0

      return isAlcohol ? accumulator + item.quantity : accumulator
    }, 0)

    return quantity
  }
)

// When editing items in cart, we don't want to take the item-in-question's quantity into
// account when calculating the max quantity that the user can select. Used in this situation only
export const getOtherAlcoholicItemQuantityInCart = (state, ignoreItemId) => createSelector(
  [getCartMenuItems],
  (items) => {
    const quantity = keys(items).reduce((accumulator, itemId) => {
      const item = items[itemId]
      const isAlcohol = item.isAlcohol && item.quantity > 0

      if (ignoreItemId === itemId) return accumulator ? accumulator : 0

      return isAlcohol ? accumulator + item.quantity : accumulator
    }, 0)

    return quantity
  }
)(state)

export const makeGetAlcoholicItemQuantityInCart = () => {
  return createSelector([getAlcoholicItemQuantityInCart], (quantity) => quantity)
}

export const makeGetOtherAlcoholicItemQuantityInCart = () => {
  return createSelector([getOtherAlcoholicItemQuantityInCart], (quantity) => quantity)
}

const getDeliveryFee = createSelector(
  [getCartMenuItems],
  (items) => {
    const deliveryFeeId = keys(items).find((itemId) => {
      return get(items[itemId], 'specialType') === 'delivery_fee'
    })

    return items[deliveryFeeId]
  }
)

export const makeGetDeliveryFee = () => {
  return createSelector([getDeliveryFee], (item) => item)
}
