/**
 * Get the base user from the reducer, use this to get everything else on user
 * @param {*} state
 */
export const getUser = (state) => {
  return state.VNUser.get('user')
}

export const getUserLoaded = (state) => {
  return state.VNUser.get('user_loaded')
}

export const getStadiumUser = (state) => {
  return state.VNUser.get('stadium_user')
}

export const getUserPaymentMethods = (state) => {
  return state.VNUser.get('payment_methods')
}

export const getUserViewedPaymentReminder = (state) => {
  return state.VNUser.get('payment_reminder_view')
}

/**
 * Get the users JWT
 * @param {*} state
 */
export const getUserJWT = (state) => {
  const user = getUser(state)
  return user.get('jwt')
}

/**
 * Has the user set to use virtual currency as their first payment option or not
 * @param {*} state
 */
export const getUseVirtualCurrencyFirst = (state) => {
  const user = getUser(state)
  return user.has('useVirtualCurrencyFirst') ? user.get('useVirtualCurrencyFirst') : false
}

/**
 * Highest ranked affiliation is 1-N.
 * This is used specifically for the detailed affiliations that belong on the user WITHIN VNAPI ONLY.
 * PCH for an example, will NOT be in this array, since that affiliation is from the Users Service.
 * So PCH can never be part of the ranking system.
 * @param {Object} state - The Redux state
 * @returns A detailed affiliation object
 */
export const getHighestRankedAffiliation = (state) => {
  const affiliations = state.VNUser.getIn(['user', 'detailed_affiliations'])

  if (!affiliations?.length) {
    return ''
  }

  let affiliation

  for (let [key, value] of affiliations.entries()) {
    if (key === 0) {
      affiliation = value
    } else if (value.rank < affiliation.rank) {
      affiliation = value
    }
  }

  return affiliation
}

/**
 * Get the experience order history for anonymous users
 * @param {*} state - The state
 */
export const getAnonymousExperienceOrderHistory = (state) => {
  const user = state.VNUser.get('user')
  if (user.get('provider') === 'vn_anonymous') {
    return state.VNUser.getIn(['anon_experience_order_history', user.get('userID')])
  }

  return null
}

/**
 * Get the anonymous user data which consists of First/Last name and
 * email. Primarily used to cache the Cart form data if the user is anonymous,
 * and to auto fill out the form in subsequent anonymous user Cart viewings.
 * Will not be used if the user is not a vn_anonymous_user
 * @param {object} state
 * @returns {object}
 */
export const getAnonymousUserData = (state) => {
  return state.VNUser.get('anonymous_user_data')
}

/**
 * Get the user card affiliations retrieved from payment_methods. These are not
 * to be confused as tiered affiliations, as tiered affiliations are objects, whereas
 * card affiliations are string UUIDs
 * This is specifically used for Braintree cards
 * This is NOT used by the Shift4 Vault
 * @param {object} state
 * @returns
 */
export const getUserCardAffiliations = (state) => {
  return state.VNUser.getIn(['payment_methods', 'affiliations'])
}

export const getCachedRoute = (state) => {
  return state.VNUser.get('cached_route')
}

/**
 * What was the last epoch that the affiliations of the user refreshed on
 * @param {*} state
 * @returns
 */
export const getLastTimeAffiliationsRefreshed = (state) => {
  return state.VNUser.get('lastTimeAffiliationsRefreshed')
}

export const getIsNewLogin = (state) => {
  return state.VNUser.get('isNewLogin')
}
