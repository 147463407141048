import {
  CLEAR_ORDER_TOTAL,
  EXPERIENCE_ORDER_TOTAL_SUCCEEDED,
  EXPERIENCE_ORDER_TOTAL_FAILED,
  ORDER_TOTAL_SUCCEEDED,
  ORDER_TOTAL_FAILED,
} from '../actions/orderTotal'

import humps from 'humps'
import values from 'lodash/values'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ORDER_TOTAL:
      return {
        ...state,
        latest: {},
        latestOrderNumber: '',
        error: {},
      }

    case EXPERIENCE_ORDER_TOTAL_SUCCEEDED:
      return {
        ...state,
        latest: humps.camelizeKeys(action.payload),
        latestLineItem: [],
        error: {},
      }

    case EXPERIENCE_ORDER_TOTAL_FAILED:
      return {
        ...state,
        latest: {},
        error: action.payload,
      }

    case ORDER_TOTAL_SUCCEEDED:
      // we only ever need the latest response, so we're going to store it under 'latest'
      const totalObject = values(action.orderTotal)[0]

      const newState = {
        ...state,
        latest: {
          ...totalObject,
          attributes: {
            ...totalObject.attributes,
            // Need to set qrCode to null here because we never want to send it in
            // with subsequent order totals calls. We only send it in when we need it,
            // doesn't need to be saved in redux state.
            qrCode: null
          }
        },
        latestLineItem: Object.values(action.orderLineItem),
        error: {},
      }

      // only update latestOrderNumber if orderNumber exists in the response
      if (totalObject.attributes.orderNumber) {
        newState.latestOrderNumber = totalObject.attributes.orderNumber
      }

      return newState

    case ORDER_TOTAL_FAILED:
      return {
        ...state,
        latest: {},
        error: action.payload,
      }

    default:
      return state
  }
}

export default reducer
