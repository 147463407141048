import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import isEmpty from 'lodash/isEmpty'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import 'moment/locale/es'

import Title from '../utils/titleGenerator'

import { getVenue } from '../selectors/venue'
import { getLocale } from '../selectors/internationalization'
import { get } from 'lodash'


export class LocaleAndTitle extends Component {
  componentDidMount() {
    this.changeLanguageAndAddResource()
  }

  componentDidUpdate(prevProps) {
    const { displayName } = this.props.venue

    // the check needs to happen here to see if locale changed so we only call this function when necessary
    if (prevProps.locale !== this.props.locale) {
      this.changeLanguageAndAddResource()
    }

    if (prevProps.venue.displayName !== displayName && isEmpty(Title.siteName)) {
      Title.siteName = this.props.venue.displayName
    }
  }

  // change the language
  changeLanguageAndAddResource = () => {
    const { locale, i18n } = this.props
    let resource = {}
    
    i18n.changeLanguage(locale)
    resource = require(`../App/languages/${i18n.language}`)
    resource = get(resource, 'default.translation')
    i18n.addResourceBundle(i18n.language, 'translation', resource, true, true)

    // set the moment to use a global locale
    switch (locale) {
      case 'en-GB':
      case 'en-IE':
      case 'en-US':
        moment.locale('en')
        break
      case 'es-ES':
        moment.locale('es')
        break
      default:
        moment.locale('en')
        break
    }
  }

  render() {

    return (
      <span></span>
    )
  }
}

const mapStateToProps = (state) => {
  const venue = getVenue(state)
  const locale = getLocale(state)
  return { venue, locale }
}

export default compose(withTranslation(), connect(mapStateToProps, {}))(LocaleAndTitle)
