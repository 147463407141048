/**
 *
 * @param {*} state
 */
 export const getProductTypeSelected = (state) => {
  return state.VNQueryParams.get('productTypeSelected')
}

/**
 *
 * @param {*} state
 */
export const getServiceTypeSelected = (state) => {
  return state.VNQueryParams.get('serviceTypeSelected')
}
