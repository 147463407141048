/**
 * Web SDK Native platform or web
 */
const WebSDKPlatform = {
  IOS: 'ios',
  ANDROID: 'android',
  WEB: 'web',
  getPlatform: function(platform) {
    switch(platform) {
      case 'ios':
        return this.IOS
      case 'android':
        return this.ANDROID
      case 'web':
      default:
        return this.WEB
    }
  }
}

export default WebSDKPlatform
