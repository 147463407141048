import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'timers': Map({}),
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNCOUNTDOWNTIMER_SET_TIME:
      return state.setIn(['timers', action.id], action.time)
    default:
      return state
  }
}

export default reducer
