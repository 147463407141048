import get from 'lodash/get'

export function itemHasVariants(item) {
  return variantsForItem(item).length > 1
}

export function variantsAreSamePrice(item) {
  if (!item) return true

  const variants = variantsForItem(item)

  return variants.every(v => v.price_in_cents === variants[0].price_in_cents)
}

export function variantsForItem(item) {
  return get(item, 'variants', [])
}

export const calculateCartDetails = (items) => {
  let priceInCents = 0
  let quantity = 0

  for (let [, item] of Object.entries(items)) {

    priceInCents += (item.quantity * item.priceInCents)
    quantity += item.quantity

    // each item has its own array modifiers, so even if its the same, they are seperated
    // eslint-disable-next-line no-loop-func
    item.modifiers.forEach(modifiersArray => {
      modifiersArray.forEach(modifier => {
        if (modifier.priceInCents) {
          priceInCents += modifier.priceInCents
        }
      })
    })
  }

  return {
    priceInCents: priceInCents,
    quantity: quantity
  }
}

/**
 * Determine if a menu is available or not
 * @param {Object} menu - A menu object
 * @returns A boolean value
 */
export const getIsMenuAvailable = (menu) => {
  if (menu?.hasOwnProperty('is_available')) {
    if ((!menu.is_available) || (!menu.order_ahead && !menu.order_now)) {
      return false
    }
  }

  return true
}
