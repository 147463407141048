import { STANDS_SUCCEEDED } from '../actions/stand'

const defaultState = {}

function reducer(state = defaultState, action) {
  if (action.type !== STANDS_SUCCEEDED) return state

  return {
    ...state,
    ...action.payload.meta,
  }
}

export default reducer
