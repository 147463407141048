import { VNREVENUECENTERS_SET_STANDS } from '../VNRevenueCenters/ActionTypes'

import { get } from 'lodash'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case VNREVENUECENTERS_SET_STANDS:
      const locale = get(action, 'val[0].locale', 'en-US')

      return {
        locale
      }

    default:
      return state
  }
}

export default reducer
