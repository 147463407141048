import * as ActionTypes from './ActionTypes'
import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'loading': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNAPILOADINGSYSTEMCACHED_SET_STATUS:
      return state.setIn(['loading', action.apiStatusObj.type], action.apiStatusObj)
    default:
      return state
  }
}

export default reducer
