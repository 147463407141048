import axios from 'axios'
import * as CONFIG from '../App/configurations/Configs'
import { 
  mockApiGetLoyaltyUser, 
  mockApiFetchAwardsRules, 
  mockApiPostVirtualCurrencyTransfer, 
  mockApiFetchVirtualCurrencyActivity, 
  mockGetDiscountChargeNonces,
  mockGetDiscountChargeNonceData,
  mockApiPostCancelVirtualCurrencyTransfer,
  mockApiGetExchangeServiceBalance,
  mockApiGetExchangeServiceGroupData
} from './Mock'
import { BASE_LOYALTY_URL, INSTANCE_NAME, PAULIE_URL, ORG_NAME, API_ENDPOINT, VENUE_UUID, BASE_URL } from '../constants'

/**
 * Get the loyalty user from the Loyalty Service
 * @param {string} token - The JWT token
 */
export const apiGetLoyaltyUser = (token) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiGetLoyaltyUser) {
    return mockApiGetLoyaltyUser()
  } else { 
    return axios.get(`${BASE_LOYALTY_URL}/vnapi/v1/loyalty_user?organization_name=${ORG_NAME}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * Fetch Balance based on account and service
 * @param {string} token - The JWT Token
 * @param {string} provider the provider i.e. ticketsdotcom
 * @param {string} account the account needed i.e. ticket number
 * @param {string} venueID The UUID of current venue (required when provider is ticketsdotcom)
 */
export const apiLoadExchangeServiceBalance = (token, provider, account, venueID) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiGetExchangeServiceBalance) {
    return mockApiGetExchangeServiceBalance()
  }
  let qs = `instance=${INSTANCE_NAME}&provider=${provider}&account=${account}`
  if (venueID) {
    qs += `&venueID=${venueID}`
  }
  // 6N82-DWPMBBEJ this account works
  return axios.get(`${API_ENDPOINT}/exchange/balance?${qs}`,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * confirm money transfered from service
 * @param {string} token - The JWT Token 
 * @param {string} exchangeServiceJwt jwt from balance call used for transfer
 * @param {string} provider the provider i.e. ticketsdotcom
 * @param {string} account the account needed i.e. ticket number
 * @param {string} venueID The UUID of current venue (required when provider is ticketsdotcom)
 */
export const apiPostExchangeServiceTransfer = (token, exchangeServiceJwt, provider, account, venueID, amount) => {
  return axios.post(`${API_ENDPOINT}/exchange/transfer`,
    {
      provider: provider,
      account: account,
      venueID: venueID,
      amount: amount,
      signedEventPayload: exchangeServiceJwt
    },
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  )
}

/**
 * Get the virtual currency activity from the Loyalty Service
 * @param {string} token 
 */
export const apiFetchVirtualCurrencyActivity = (token) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiFetchVirtualCurrencyActivity) {
    return mockApiFetchVirtualCurrencyActivity()
  } else {
    return axios.get(`${BASE_LOYALTY_URL}/vnapi/v1/transactions?organization_name=${ORG_NAME}`, {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  }
}

/**
 * Post a virtual currency transfer 
 * @param {string} token - The JWT Token
 * @param {Object} data - The POST body
 * @param {float} data.amount - The amount of currency to send
 * @param {string} data.recipient_email - The email to transfer currency to 
 * @param {string} data.description - Optional note to send to user
 */
export const apiPostVirtualCurrencyTransfer = (token, data) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiPostVirtualCurrencyTransfer) {
    return mockApiPostVirtualCurrencyTransfer()
  } else {
    return axios.post(`${BASE_LOYALTY_URL}/vnapi/v1/transfer?organization_name=${ORG_NAME}`, data, {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  }
}

/**
 * Get the loyalty Awards Rules from the Loyalty Service
 * @param {string} token - The JWT token
 */
export const apiFetchAwardsRules = (token) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiFetchAwardRules) {
    return mockApiFetchAwardsRules()
  } else {
    return axios.get(`${BASE_LOYALTY_URL}/vnapi/v1/award_rules?organization_name=${ORG_NAME}`, {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  }
}

/**
 * Fetch the available payment types nonces
 */
export const apiFetchDiscountChargeNonces = (token) => {
  if (CONFIG.DATA.MOCK.VN_SCANANDPAY_apiGetDiscountChargeNonces) {
    return mockGetDiscountChargeNonces()
  } else { 
    // TODO: Example request with headers, need to determine how to 
    // properly fill out header information. THIS API CALL DOES NOT WORK AS IS
    return axios.post(`${PAULIE_URL}/discount_charge_nonces/vend_payment_types?organization_name=${ORG_NAME}`, {}, {
      headers: {
        "Authorization" : "Bearer " + token
      }
    })
  }
}

/**
 * Given a user JWT and a nonce, fetch nonce data
 * @param {String} token - The user JWT
 * @param {String} nonce - The nonce
 */
export const apiGetDiscountChargeNonceData = (token, nonce) => {
  if (CONFIG.DATA.MOCK.VN_QRCODEPAY_apiGetDiscountChargeNonceData) {
    return mockGetDiscountChargeNonceData()
  } else {
    return axios.get(`${BASE_URL}/discount_charge_nonces/${nonce}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  }
}

/**
 * Cancel an active virtual currency transfer using vnapi
 * @param {string} token - The JWT token
 * @param {string} schedulerID - The transfer_uuid to cancel
 */
export const apiPostExchangeServiceAdjustment = (token, schedulerID) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiPostCancelVirtualCurrencyTransfer) {
    return mockApiPostCancelVirtualCurrencyTransfer()
  } else {
    
    return axios.post(`${API_ENDPOINT}/exchange/adjustment`, {
      'schedulerID': schedulerID,
      'venueID': VENUE_UUID
    }, {
      headers: {
        "Authorization" : "Bearer " + token
      }
    })
  }
}

/**
 * retrieve all events and event options for a user | Assisted Load
 * @param {string} token - The JWT token
 * @param {string} provider - the provider being used for exchange service, IE ticketsdotcom
 */
export const apiGetExchangeServiceGroupData = (token, provider) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiGetExchangeServiceGroupData) {
    return mockApiGetExchangeServiceGroupData()
  } else {
    return axios.get(`${API_ENDPOINT}/exchange/groups?venueID=${VENUE_UUID}&provider=${provider}`, {
      headers: {
        "Authorization" : "Bearer " + token
      }
    })
  }
}

/**
 * transfer multiple accounts at once | Assisted Load
 * @param {string} token - The JWT token
 * @param {string} provider - the provider being used for exchange service, IE ticketsdotcom
 * @param {string} assistedLoadJwt jwt coming from groups api call
 * @param {array} accounts - the accounts due for transfer
 * @param {string} language - Two letter language code - en
 */
export const apiPostExchangeServiceTransferMultiple = (token, provider, assistedLoadJwt, accounts, language) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiGetExchangeServiceGroupData) {
    return mockApiGetExchangeServiceGroupData()
  } else {
    return axios.post(`${API_ENDPOINT}/exchange/transfer/multiple`, {
      provider: provider,
      accounts: accounts,
      venueID: VENUE_UUID,
      language: language,
      signedGroupsResponse: assistedLoadJwt
    }, {
      headers: {
        "Authorization" : "Bearer " + token
      }
    })
  }
}


/**
 * Cancel an active virtual currency transfer
 * @param {string} token - The JWT token
 * @param {string} transferUuid - The transfer_uuid to cancel
 */
export const apiPostCancelVirtualCurrencyTransfer = (token, transferUuid) => {
  if (CONFIG.DATA.MOCK.VN_WALLET_apiPostCancelVirtualCurrencyTransfer) {
    return mockApiPostCancelVirtualCurrencyTransfer()
  } else {
    return axios.post(`${BASE_LOYALTY_URL}/vnapi/v1/transfer/cancel?organization_name=${ORG_NAME}`, {
      'transfer_uuid': transferUuid
    }, {
      headers: {
        "Authorization" : "Bearer " + token
      }
    })
  }
}

/**
 * Accept an active virtual currency transfer
 * @param {string} token - The JWT token
 * @param {string} transferUuid - The transfer_uuid to accept
 */
export const apiPostAcceptVirtualCurrencyTransfer = (token, transferUuid) => {
  return axios.post(`${BASE_LOYALTY_URL}/vnapi/v1/transfer/accept?organization_name=${ORG_NAME}`, {
    'transfer_uuid': transferUuid
  }, {
    headers: {
      "Authorization" : "Bearer " + token
    }
  })
}

/**
 * Get virtual currency account balance for the user
 * @param {string} token - JWT to send in Authorization header
 */
 export const apiGetVCAccountBalance = (token) => {
  return axios.get(`${API_ENDPOINT}/virtualcurrency/account/balance`, {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
}

/**
 * TODO: replace this with webhook
 * Send data to loyalty server for preferred card holder award
 * @param {string} token - User JWT token
 * @param {string} userId - User ID
 * @param {string} org - User organization name
 * @param {string} bin - The bin number of a vaulted card from S4Payments SDK on the VAULT_CARD_VAULTED event
 * @returns {object} - axios response
 */
export const apiPreferredCardHolderAward = (token, userId, org, bin) => {
  return axios.post(
    `${BASE_LOYALTY_URL}/vnapi/v1/user_activities`,
    {
      "organization_name": org,
      "activity_name": "credit_card",  // "activity_name" should always be "credit_card"
      "users": [
        {
          "uuid": userId,
          "bin": bin
        }
      ]
    },
    {
      headers: {
        "Authorization" : "Bearer " + token
      }
    }
  )
}

/**
 * Get the balance of a gift card
 * @param {String} provider - which provider: GIVEX is the only one so far: 4/4/22
 * @param {String} cardNumber - Gift card number
 * @param {String} language - Two letter language code - en
 * @param {String} pin - Gift card pin number
 * @returns 
 */
export const apiGetGiftCardBalance = (provider, cardNumber, language, pin) => {
  const qs = `provider=${provider}&cardNumber=${cardNumber}&language=${language}&pin=${pin}&instance=${INSTANCE_NAME}`
  return axios.get(`${API_ENDPOINT}/giftcards/balance?${qs}`)
}

/**
 * Transfer a gift card balance, amount, to a specific users virtual currency
 * @param {String} provider - which provider: GIVEX is the only one so far: 4/4/22
 * @param {String} cardNumber - Gift card number
 * @param {String} language - Two letter language code - en
 * @param {String} pin - Gift card pin number
 * @param {Int} amount - What amount to transfer to your virtual currency
 * @param {String} userId - The user ID that you want to transfer the balance to
 * @returns 
 */
export const apiTransferGiftCardBalanceToUser = (provider, cardNumber, language, pin, amount, userId) => {
  const data = {
    userID: userId,
    provider: provider,
    amount: amount,
    cardNumber: cardNumber,
    language: language,
    pin: pin,
    instance: INSTANCE_NAME
  }

  return axios.post(`${API_ENDPOINT}/giftcards/transfer`, data)
}