import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { get, head, isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'
import humps from 'humps'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'

import Money from '../utils/money'
import TimeZone from '../utils/timezone'
import Title from '../utils/titleGenerator'

import { makeGetRemote } from '../selectors/remote'
import { withTranslation } from 'react-i18next'

import ExperienceMetadata from './ExperienceMetadata'
import Item from '../components/Item'
import NavigationBar from '../components/NavigationBar'
import OrderTotalSection from '../components/cart/OrderTotalSection'
import Remote from '../remote'
import Section from '../components/Section'
import ExperienceCard from '../components/ExperienceCard'
import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'
import ScrollToTop from '../containers/ScrollToTop'

import { clearCart } from '../actions/cart'
import { clearLatestOrder, getExperienceOrder } from '../actions/order'
import { redeemUserItem } from '../actions/experiences/userItem'
import { setWebSDKDataReceive } from '../VNWebSDK/ActionCreators'

import { VNUserOrders } from '../VNWebAccount/containers/VNUserOrders'

import { setApiLoadingStatus } from '../VNApiLoadingSystem/ActionCreators'
import { getWebSDKMode, getInitialPathname } from '../VNWebSDK/Selectors'
import { retrieveAllWallet } from '../VNWallet/ActionCreators'
import ApiLoadingStatus from '../VNApiLoadingSystem/types/ApiLoadingStatus'
import { getUser } from '../VNUser/Selectors'
import { getAwardedExperienceUserItem } from '../selectors/userItem'

import './OrderSummary.scss'
import './ExperienceOrderSummary.scss'

export class ExperienceOrderSummary extends Component {
  state = {
    alertDialog: defaultAlertDialogState,
  }

  componentDidMount() {
    const { getExperienceOrder, orderId, history, resetWalletApiStatus, user, initialPathname, clearWebSDKDataReceive } = this.props

    // Putting this here because the QR Code Scanner on iOS continuously scans even after
    // the initial scan, which is causing caching of barcode data which erroenously is detected
    // by VNScanner is a fresh scan, causing automatic scans to happen when they shouldn't
    clearWebSDKDataReceive()

    getExperienceOrder(orderId)
    this.props.clearCart()

    // History returns a function to unlisten
    this.backListener = history.listen((location, action) => {
      if (action === 'POP') {
        // Reset so virtual currency refreshes when going back to wallet
        resetWalletApiStatus()

        if (user.get('provider') === 'vn_anonymous') {
          history.replace(initialPathname)
        } else {
          history.push('/profile/orders')
        }
        // Unlisten when complete, for some odd reason does not work
        // when placing this in componentWillUnmount
        this.backListener()
      }
    })
  }

  componentWillUnmount() {
    this.props.clearLatestOrder()
  }

  redeem = (userItemId) => {
    const { redeemUserItem, order } = this.props

    redeemUserItem(userItemId, order.id)
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  renderDatum = (label, value) => {
    return(
      <div className='extra-datum'>
        <span>{label}</span>
        <span>{value}</span>
      </div>
    )
  }

  renderExtraData = () => {
    const { order, userItem, t } = this.props

    if (isEmpty(order)) return(<Section><Skeleton/></Section>)

    return (
      <Section className='extra-data'>
        {this.renderDatum(t('EVENT_DATE'), userItem.eventDate)}
        {this.renderDatum(t('EMAIL_MY_RECEIPT'), order.userEmail)}
        {this.renderDatum(`${t('ORDER')} #`, order.orderNumber)}
      </Section>
    )
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  redeemConfirm = (userItemId) => {
    const alert = {
      onConfirm: () => this.redeem(userItemId),
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('YOU_ARE_ABOUT_TO_REDEEM'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  renderHeader = () => {
    const { item, order, userItems, remote, t, awardedUserItem } = this.props

    const _userItems = awardedUserItem? [humps.camelizeKeys(awardedUserItem)] : userItems
    const _item = awardedUserItem ? humps.camelizeKeys(awardedUserItem.item) : item

    if (isEmpty(_item)) return(<Section><Skeleton/></Section>)

    return (
      <Section className="cards">
        <ScrollToTop />
        {_userItems.map((userItem) => {
          const { expiresAt, redemptionStartsAt } = _item
          const userTimeZone = TimeZone.current()

          const redeemDay = moment(redemptionStartsAt).tz(userTimeZone).format('MMMM Do, YYYY')
          const redeemTime = moment(redemptionStartsAt).tz(userTimeZone).format('h:mm a')
          const expireDay = moment(expiresAt).tz(userTimeZone).format('MMMM Do, YYYY')
          const expireTime = moment(expiresAt).tz(userTimeZone).format('h:mm a')
          const isExpired = moment().isAfter(moment(expiresAt))
          const timeFrame = redeemDay === expireDay
            ? `${t('ON')} ${redeemDay} ${t('AT')} ${redeemTime}.`
            : `${t('BETWEEN')} ${redeemDay} ${t('AT')} ${redeemTime} ${t('AND')} ${expireDay} ${t('AT')} ${expireTime}.`

          const instructionsId = humps.camelize(userItem.id)
          let customInstructions = order.instructions?.[instructionsId]?.description ?? ''
          if (Array.isArray(userItem.item?.images) && userItem.item.images.length) {
            // instruction text for awarded/transferred user items can be found here
            const instruction = userItem.item.images.find(image => image?.imageType?.toLowerCase() === 'instruction')
            if (instruction?.description) {
              customInstructions = instruction.description
            }
          }

          const formattedEventDate = moment(userItem.eventDate || userItem.event_date).tz(userTimeZone).format('MMMM Do, YYYY')
          let countdownTime = get(_item, 'globalItem.countdownSeconds', undefined)

          let instructionsTitle = t('HOW_DO_I_REDEEM_CAPS')
          let defaultInstructions = `${t('WHEN_YOU_ARE_READY_TO_REDEEM')} ${!countdownTime ? t('CONFIRMATION_SCREEN') : `${countdownTime} ${t('SECOND_CONFIRMATION_SCREEN')}`}.`

          switch (_item.redemptionType) {
            case 'countdown_timer':
              break
            case 'barcode': {
              defaultInstructions = t('SCAN_THIS_QRCODE')
              break
            }
            default: {
              instructionsTitle = `${t('WHAT_HAPPENS_NEXT')}?`
              defaultInstructions = t('KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS')
              break
            }
          }

          const now = moment(new Date())
          const end = moment(userItem.usedAt)
          const duration = moment.duration(now.diff(end))
          const timeSinceRedemption = duration.asSeconds()
          const showCountdown = timeSinceRedemption <= countdownTime

          countdownTime = !countdownTime && !showCountdown ? countdownTime : countdownTime - timeSinceRedemption

          let redemptionState = 'notReady'

          let header = {
            title: _item.name,
            content: formattedEventDate,
          }

          let body = {
            title: instructionsTitle,
            content: customInstructions || defaultInstructions,
          }

          let footer = {
            title: '',
            content: `${t('THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM')} ${timeFrame}`,
          }

          redemptionState = userItem.redeemable ? 'ready' : 'notReady'

          if (isExpired) {
            redemptionState = 'expired'

            body.title = ''
            body.content = `${t('THIS_EXPERIENCE_EXPIRED_ON')} ${expireDay} ${t('AT')} ${expireTime}.`
            footer.content = ''
          }

          if (userItem.usedAt !== null) {
            const usedAt = moment(userItem.usedAt).tz(userTimeZone)

            redemptionState = 'redeemed'

            body.title = ''
            body.content = `${t('THIS_EXPERIENCE_WAS_REDEEMED_ON')} ${usedAt.format('MMMM Do, YYYY')} ${t('AT')} ${usedAt.format('h:mm a')}.`
            footer.content = ''
          }

          if (remote.loading) {
            redemptionState = 'redeeming'
          }

          switch (_item.redemptionType) {
            case 'countdown_timer':
              break
            case 'barcode': {
              footer.content = ''
              break
            }
            default: {
              redemptionState = 'notRedeemable'

              footer.title = t('ORDER_NUMBER')
              footer.content = order.orderNumber
              break
            }
          }

          return (
            <ExperienceCard
              key={userItem.id}
              header={header}
              body={body}
              footer={footer}
              barcode={userItem.barcode}
              didClickRedeem={() => {
                if (redemptionState === 'ready') this.redeemConfirm(userItem.id)
              }}
              didClickInfo={() => {
                this.props.history.push(`/experience_orders/${order.id}/${userItem.id}`)
              }}
              metadata={userItem.metadata}
              redemptionState={redemptionState}
              redemptionType={_item.redemptionType}
              startTime={(userItem.usedAt !== null && userItem.usedAt) ? userItem.usedAt : ""}
              countdownDuration={countdownTime}
              showCountdown={showCountdown}
              awardedUserItem={awardedUserItem}
            />
          )
        })}
      </Section>
    )
  }

  renderLineItems = () => {
    const { lineItem, order } = this.props

    if (isEmpty(order)) {
      return(
        <Section className='line-items'>
          <div><Skeleton/><Skeleton/></div>
        </Section>
      )
    }

    return (
      <Section className='line-items'>
        <Item
          type="cart"
          key={lineItem.id}
          name={lineItem.name}
          quantity={order.quantity}
          price={Money.formatDollars(lineItem.price)}
        />
      </Section>
    )
  }

  renderNavigation = () => {
    const { location, webSDKMode, t } = this.props

    if (!webSDKMode) {
      return <NavigationBar.Close
      text={t('ORDER_DETAILS')}
      right
      twoToned
      transparent
      linkTo={
        location.state && location.state.previousScreen === VNUserOrders.name ?
        location.state.navigateTo :
        '/'
      }/>
    }

    return <NavigationBar.None
      text={t('ORDER_DETAILS')}
      right
      twoToned
      transparent
      linkTo={'/wallet'}
    />
  }

  render() {
    const { alertDialog } = this.state
    const { order, t, awardedUserItem } = this.props
    const orderNumber = order.orderNumber || t('LOADING')

    const userTimeZone = TimeZone.current()
    const formattedOrderDate = moment(order.createdAt).tz(userTimeZone).format('MMMM Do, YYYY')
    const orderDate = `${awardedUserItem ? t('AWARDED_ON') : t('PLACED_ON')} ${formattedOrderDate}`

    return (
      <div className="order-summary experiences">
        <Helmet>
          <title>{Title.generate(`${t('RECEIPT')} ${orderNumber}`)}</title>
        </Helmet>

        {this.renderNavigation()}
        <p className="placed-on-date">{orderDate}</p>
        {this.renderHeader()}
        {!awardedUserItem && this.renderLineItems()}
        {!awardedUserItem && <OrderTotalSection
          finalTotal={order.total}
          itemQuantityLabel={t('SUBTOTAL')}
          tax={0}
          loading={isEmpty(order)}
          showTip={false}
        />}
        {!awardedUserItem && this.renderExtraData()}
        <Route path={`/experience_orders/:orderId/:userItemId`} component={ExperienceMetadata} />
        {alertDialog.show && <AlertDialog {...alertDialog} />}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const orderId = get(ownProps, 'match.params.orderId', '')
  const order = get(state, `order.${orderId}`, {})

  const getRemote = makeGetRemote()
  const remote = getRemote(state, Remote.endpoints.redeemUserItem)

  // Can only buy one event item per order for now
  const item = head(get(order, 'items')) || {}
  const userItem = head(get(order, 'userItems')) || {}

  const lineItem = head(get(order, 'lineItems')) || {}
  const userItems = get(order, 'userItems') || []
  // Sent from VNUserOrders, SHOULD only be a single user item
  // since batch awarding of multiple experiences is currently
  // not possible
  const awardedUserItem = ownProps.location?.state?.awardedUserItem
  // awardedUserItem is passed in from VNUserOrders, the OR here is to grab the refreshed user item
  // after it has been redeeemed in order to display things such as countdown timers
  const actualAwardedUserItem = getAwardedExperienceUserItem(state, awardedUserItem?.id) || awardedUserItem

  const webSDKMode = getWebSDKMode(state)
  const user = getUser(state)
  const initialPathname = getInitialPathname(state)

  return {
    item,
    lineItem,
    order,
    orderId,
    remote,
    userItems,
    userItem,
    awardedUserItem: actualAwardedUserItem,
    webSDKMode,
    user,
    initialPathname,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    clearCart: () => dispatch(clearCart()),
    clearLatestOrder: () => dispatch(clearLatestOrder()),
    getExperienceOrder: (id) => dispatch(getExperienceOrder(id)),
    redeemUserItem: (userItemId, orderId) => dispatch(redeemUserItem(userItemId, orderId)),
    resetWalletApiStatus: () => dispatch(setApiLoadingStatus(retrieveAllWallet.name, { status: ApiLoadingStatus.UNKNOWN })),
    clearWebSDKDataReceive: () => dispatch(setWebSDKDataReceive({}))
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExperienceOrderSummary))
