import moment from 'moment-timezone'

/**
 * Get a list of promo codes from state
 * @param {*} state
 */
export const getPromoCodes = (state) => {
  return state.VNOrders.get('promo_codes')
}

/**
 * Get the last promo code the user entered
 * @param {*} state
 */
export const getLastEnteredPromoCode = (state) => {
  return state.VNOrders.get('last_entered_promo_code')
}

/**
 * Get the current stand availability, primarily for pick up times
 * @param {*} state
 * @param {string} standId - The UUID of the stand
 * @param {menuId} menuId - The UUID of the specific menu belonging to stand
 */
export const getPickupAvailability = (state, standId, menuId) => {
  if (state.VNOrders.has('stand_availability')) {
    const pickupAvailability = state.VNOrders.get('stand_availability').get(standId) || null
    if (pickupAvailability) {
      const todayAvailability = pickupAvailability[moment().format('YYYY-MM-DD')]
      if (todayAvailability) {
        return todayAvailability.reduce((accumulator, item) => {
          if (item.stand_menu_uuids.includes(menuId)) {
            accumulator.push(item.slot)
          }
          return accumulator
        }, [])
      }
    }
  }
  return []
}

/**
 * Get the details of a specific order that is our redux state.
 * @param {*} state - The state
 * @param {*} orderId - The order ID
 */
export const getOrder = (state, orderId) => {
  return state.VNOrders.getIn(['orders', orderId])
}
