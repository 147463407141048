import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../VNUser/Selectors'
import { createOrGetUser } from '../../VNUser/ActionCreators'
import { useTranslation } from 'react-i18next'

export const VNAnonymousAccount = () => {

  const dispatch = useDispatch()

  const { t } = useTranslation()

  // the user from redux
  const user = useSelector(state => getUser(state))

  useEffect(() => {
    if (user.isEmpty()) {
      dispatch(createOrGetUser('', 'vn_anonymous', null, t))
    }
  }, [user, dispatch, t])

  return (
    <span></span>
  )
}
