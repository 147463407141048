import { all, fork } from 'redux-saga/effects'
import { watchFetchMenu, watchFetchStandFromMenu } from './menu'
import { watchFetchStands } from './stand'
import {
  watchCreateExperienceOrder,
  watchCreateOrder,
  watchGetExperienceOrder,
  watchGetOrder,
  watchUpdateOrder,
  watchUpdateOrderMetadata
} from './order'
import { watchExperienceOrderTotal, watchOrderTotal } from './orderTotal'
import { watchFetchSections, watchFetchRows, watchFetchSeats, watchFetchMenusForSeat } from './seat'
import { watchRedeemUserItem, watchRedeemUserItemAndGetOrder, watchRetrieveUserItem } from './experiences/userItem'



function* watchAll() {
  yield all([
    fork(watchFetchMenu),
    fork(watchFetchStandFromMenu),
    fork(watchFetchStands),
    fork(watchCreateExperienceOrder),
    fork(watchCreateOrder),
    fork(watchUpdateOrder),
    fork(watchGetOrder),
    fork(watchGetExperienceOrder),
    fork(watchExperienceOrderTotal),
    fork(watchOrderTotal),
    fork(watchFetchSections),
    fork(watchFetchRows),
    fork(watchFetchSeats),
    fork(watchFetchMenusForSeat),
    fork(watchRedeemUserItem),
    fork(watchRedeemUserItemAndGetOrder),
    fork(watchUpdateOrderMetadata),
    fork(watchRetrieveUserItem)
  ])
}

export default watchAll
