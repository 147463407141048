const styles = require('../styles/index')
const stylesUtils = require('../styles/utils')
const langaugeUtils = require('../languages/utils')

const baseStyle = {
  border: '1px solid #bfbfbf',
  height: '44px',
  margin: '4px 0 0',
  padding: '0 8px'
}

const createElement = (type, id, style, attributes) => {
  const div = document.createElement(type)

  if (id) {
    div.setAttribute('id', id)
  }

  if (attributes) {
    for (const [key, value] of Object.entries(attributes)) {
      div.setAttribute(key, value)
    }
  }

  if (style) {
    stylesUtils.attachStyles(div, style)
  }

  return div
}

const createTypography = (id, text, outerStyle, styleType, styleOverride) => {
  const div = createElement('div', id, outerStyle)

  const span = createElement('span')

  if (styleType) {
    const selectedStyle = styles.TYPOGRAPHY[styleType]
    if (selectedStyle) {
      stylesUtils.attachStyles(span, selectedStyle)
    }
  }

  if (styleOverride) {
    stylesUtils.attachStyles(span, styleOverride)
  }

  span.textContent = text

  div.appendChild(span)
  return div
}

const createNumberField = (styleOverride) => {
  const style = {
    'border-radius': '6px',
    'height': '48px',
    'background-color': styles.COLORS.lightGrey,
    'border': `1px solid ${styles.COLORS.buttonBorderGrey}`,
    'overflow': 'hidden',
    'position': 'relative'
  }

  const field = createElement('div', 'vni4go__securefield__number', style)

  if (styleOverride) {
    stylesUtils.attachStyles(field, styleOverride)
  }

  return field
}

const createExpDateField = (styleOverride) => {
  const style = {
    'border-radius': '6px',
    'height': '48px',
    'background-color': styles.COLORS.lightGrey,
    'border': `1px solid ${styles.COLORS.buttonBorderGrey}`,
    'overflow': 'hidden'
  }
  const field = createElement('div', 'vni4go__securefield__exp', style)

  if (styleOverride) {
    stylesUtils.attachStyles(field, styleOverride)
  }

  return field
}

const createCVVField = (styleOverride) => {
  const style = {
    'border-radius': '6px',
    'height': '48px',
    'background-color': styles.COLORS.lightGrey,
    'border': `1px solid ${styles.COLORS.buttonBorderGrey}`,
    'overflow': 'hidden'
  }
  const field = createElement('div', 'vni4go__securefield__cvv', style)

  if (styleOverride) {
    stylesUtils.attachStyles(field, styleOverride)
  }

  return field
}

const createHorizontalDivider = () => {
  const style = {
    'border': 'none',
    'height': '1px',
    'margin': 0,
    'background-color': 'rgba(0, 0, 0, 0.12)'
  }

  return createElement('hr', null, style)
}

module.exports = {
  createElement: createElement,
  createNumberField: createNumberField,
  createExpDateField: createExpDateField,
  createCVVField: createCVVField,
  createTypography: createTypography,
  createHorizontalDivider: createHorizontalDivider
}
