import * as ActionTypes from './ActionTypes'
import { getPromoCodes } from './Selectors'
import {
  apiFetchOrder,
  apiFetchStandAvailability } from './Api'
import { getUser } from '../VNUser/Selectors'
import ApiLoadingStatusCached from '../VNApiLoadingSystemCached/types/ApiLoadingStatusCached'
import { setApiLoadingStatus }from '../VNApiLoadingSystemCached/ActionCreators'

import moment from 'moment-timezone'

/**
 * Set the promo codes in redux state
 * @param {Array} codes - Set all the promo codes back to state
 */
export function setPromoCodes(codes) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNORDERS_SET_PROMO_CODES,
      codes: codes
    })
  }
}

/**
 * Add a promo code to state
 * @param {String} code - The promo code to add
 */
export function addPromoCode(code) {
  return (dispatch, getState) => {
    const oldPromoCodes = getPromoCodes(getState())
    const newPromoCodes = oldPromoCodes.push(code)
    dispatch({
      type: ActionTypes.VNORDERS_SET_PROMO_CODES,
      codes: newPromoCodes
    })
    dispatch({
      type: ActionTypes.VNORDERS_SET_LAST_PROMO_CODE,
      code: code
    })
  }
}

/**
 * Remove a promo code to state
 * @param {String} code - The promo code to remove
 */
export function removePromoCode(code) {
  return (dispatch, getState) => {
    const oldPromoCodes = getPromoCodes(getState())
    const newPromoCodes = oldPromoCodes.filter(item => item !== code)
    dispatch({
      type: ActionTypes.VNORDERS_SET_PROMO_CODES,
      codes: newPromoCodes
    })
    dispatch({
      type: ActionTypes.VNORDERS_SET_LAST_PROMO_CODE,
      code: code
    })
  }
}

/**
 * Reset the last promo code entered to a blank state
 */
export function resetLastPromoCode() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNORDERS_SET_LAST_PROMO_CODE,
      code: ''
    })
  }
}

/**
 * Get the current stand availability times, mostly used for
 * getting the pickup time slots available if stand is configured
 * as orderAhead = true
 * @param {string} standId - The UUID of the stand
 */
export function fetchStandAvailability(standId) {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')
    apiFetchStandAvailability(jwt, standId, moment().format('YYYY-MM-D')).then(response => {
      dispatch({
        type: ActionTypes.VNORDERS_SET_STAND_AVAILABILITY,
        stand_availability: response.data.availability
      })
    }).catch(error => {
      console.log(error)
    })
  }
}

/**
 * Clear stand availability as to keep data fresh
 */
export function clearStandAvailability() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNORDERS_SET_STAND_AVAILABILITY,
      stand_availability: {}
    })
  }
}

/**
 * Get the order details about a specific order and have it save to redux.
 * @param {String} orderId - The order ID you want to get details about
 */
export const fetchOrderDetails = (orderId) => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    const jwt = user.get('jwt')

    apiFetchOrder(jwt, orderId).then(response => {

      // set the API Loading Status in our Cached System
      dispatch(setApiLoadingStatus(
        fetchOrderDetails.name,
        {
          status: ApiLoadingStatusCached.SUCCEEDED,
          lastRequestTimestamp: moment()
        }
      ))

      dispatch({
        type: ActionTypes.VNORDERS_SET_ORDER,
        order: response.data
      })

    }).catch(error => {
      console.log(error)

      // set the API Loading Status in our Cached System
      dispatch(setApiLoadingStatus(
        fetchOrderDetails.name,
        {
          status: ApiLoadingStatusCached.FAILED,
          error: error.message,
          lastRequestTimestamp: moment()
        }
      ))

      dispatch({
        type: ActionTypes.VNORDERS_SET_ORDER,
        order: {
          error: true,
          errorMessage: error,
          data: {
            id: orderId
          }
        }
      })
    })
  }
}

/**
 * We need to send order details to VNWebSDKAnalyticsMiddleware separately
 * when Apply Pay is used during checkout
 * 
 * @param {Object} order - The order data to send to SDK analytics
 */
export function sendOrderDetailsToSDKAnalytics(order) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VNORDERS_SEND_ORDER_DETAILS_TO_SDK_ANALYTICS,
      payload: order
    })
  }
}
