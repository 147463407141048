import React, { Fragment } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import PersonIcon from '@material-ui/icons/Person'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { Typography } from '@material-ui/core'
import { getUser } from '../../VNUser/Selectors'

import { ReactComponent as SendIcon } from '../../assets/icons/send.svg'
import { ReactComponent as BenefitsIcon } from '../../assets/icons/VNWallet/benefits.svg'
import { ReactComponent as BadgeIcon } from '../../assets/icons/VNWallet/badge.svg'
import { ReactComponent as PaymentsIcon } from '../../assets/icons/VNWallet/payments.svg'
import { ReactComponent as TicketsIcon } from '../../assets/icons/VNWallet/ticket.svg'
import {ReactComponent as OrdersIcon} from '../../assets/icons/VNWallet/orders.svg'
import QRCodeIcon from '../../assets/icons/qr-code'
import BarcodeIcon from '../../assets/icons/VNWallet/barcode'

import {  getVirtualCurrencyEnabled,
          getWalletTextMyBadge,
          getWalletTextScanAndPay,
          getWalletTextQRCodePay,
          getWalletTextGiftCard,
          getVirtualCurrencyName,
          getWalletMode,
          getWalletDisableOptions,
          getVirtualCurrencyWalletHide, 
          getExchangeServices,
          getExchangeServiceTitle} from '../../VNConfigurations/Selectors'
import { history } from '../../App/store/configureStore'
import { setOpenBadgeDialog, setOpenPaymentUpdatesDialog, setOpenGiftCardTransferDialog, setOpenExchangeServiceDialog } from '../../VNDialogs/ActionCreators'
import { VNWallet } from '../containers/VNWallet'
import { getWebSDKIsExternalPaymentProcessor } from '../../VNWebSDK/Selectors'
import { getWebSDKVersion, getWebSDKMode } from '../../VNWebSDK/Selectors'

import WalletMode from '../../VNEnums/WalletMode'
import { useTranslation } from 'react-i18next'
import semver from 'semver'

const DISABLE_OPTIONS = {
  SHOP: 'shop',
  INFO: 'info',
  ORDERS: 'orders',
  GIFTCARDS: 'giftcards',
  BADGE: 'badge'
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    columnGap: '15px',
    rowGap: '15px',
    gridAutoRows: '1fr',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr));',
  },
  option: {
    backgroundColor: theme.custom.colors.white,
    borderColor: theme.custom.colors.borderGrey,
    borderRadius: 6,
    border: 'solid 1px',
    paddingLeft: 16,
    paddingBottom: 13,
    paddingTop: 56,
  },
  inner_option: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    height: '100%',
    paddingRight: 8
  },
  icon: {
    height: 16,
    width: 16,
  }
}))

// this is a seperate component because its state dependenent on another state being set in wallet options
function ExchangeServiceInlineComponent(providerName) {
  return useSelector(state => getExchangeServiceTitle(state, providerName))
}

export const WalletOptions = (props) => {

  const classes = useStyles()

  const theme = useTheme()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  // SELECTORS
  const user = useSelector(state => getUser(state))
  const virtualCurrencyName = useSelector(state => getVirtualCurrencyName(state))
  const exchangeServices = useSelector(state => getExchangeServices(state))

  const isVirtualCurrencyEnabled = useSelector(state => getVirtualCurrencyEnabled(state))
  const walletModeConfig = useSelector(state => getWalletMode(state))
  const virtualCurrencyWalletHiddenOptions = useSelector(state => getVirtualCurrencyWalletHide(state))
  const walletDisableOptions = useSelector(state => getWalletDisableOptions(state))
  const walletMode = WalletMode.getMode(walletModeConfig)
  const isExternalPaymentProcessor = useSelector(state => getWebSDKIsExternalPaymentProcessor(state))
  const customizedWalletTextMyBadge = useSelector(state => getWalletTextMyBadge(state))
  const customizedWalletTextScanAndPay = useSelector(state => getWalletTextScanAndPay(state))
  const customizedWalletTextQRCodePay = useSelector(state => getWalletTextQRCodePay(state))
  const customizedWalletTextGiftCard = useSelector(state => getWalletTextGiftCard(state))

  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  /**
   * Is called when a button option is pressed
   * @param {string} route - What route you wish the history.push to go to
   * @param {func} action - An ActionCreator function
   * @param {*} actionParam - Any parameter you want to pass to your action creator function when it is dispatched
   */
  const handleButtonClick = (route, action, actionParam) => {
    if (route) {
      history.push(route, { fromRoute: VNWallet.name })
    } else if (action) {
      dispatch(action(actionParam))
    }
  }

  /**
   * Gets either the customized text if it exists or the default one.
   * @returns Returns the text for Scan and Pay
   */
  const getScanAndPayText = () => {
    return customizedWalletTextScanAndPay ? customizedWalletTextScanAndPay : t('SCAN_AND_PAY')
  }

  /**
   * Gets either the customized text if it exists or the default one.
   * @returns Returns the text for Gift Cards
   */
   const getLoadGiftCardText = () => {
    return customizedWalletTextGiftCard ? customizedWalletTextGiftCard : t('LOAD_GIFT_CARD')
  }

  /**
   * Gets either the customized text if it exists or the default one.
   * @returns Returns the text for My Badge
   */
  const getMyBadgeText = () => {
    return customizedWalletTextMyBadge ? customizedWalletTextMyBadge : t('MY_BADGE')
  }

  /**
   * Gets either the customized text if it exists or the default one.
   * @returns Returns the text for QR Code Pay
   */
   const getQRCodePayText = () => {
    return customizedWalletTextQRCodePay ? customizedWalletTextQRCodePay : t('QR_CODE_PAY')
  }

  /**
   * Create a button with a single line of text
   * @param {String} text - The text to display
   * @param {Component} icon - The icon in component format <ICON />, must have the icon class on it
   * @param {String} route - What route you wish the history.push to
   * @param {func} action - An ActionCreator function
   * @param {*} actionParam - Any parameter you want to pass to your action creator function when it is dispatched
   */
  const makeSingleLineButton = (text, icon, route, action, actionParam) => {
    return (
      <Box key={text} className={classes.option} onClick={() => { handleButtonClick(route, action, actionParam) } }>
        <Box className={classes.inner_option}>
          {icon}
          <Box pt={1}>
            <Typography style={theme.custom.subtitle3}>{text}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  /**
   * Create a button with a two lines of text
   * @param {String} topText - The top text to display
   * @param {String} bottomText - The bottom text to display
   * @param {Component} icon - The icon in component format <ICON />, must have the icon class on it
   * @param {String} route - What route you wish the history.push to
   * @param {func} action - An ActionCreator function
   * @param {*} actionParam - Any parameter you want to pass to your action creator function when it is dispatched
   */
  const makeDoubleLineButton = (topText, bottomText, icon, route, action, actionParam) => {
    return (
      <Box className={classes.option} onClick={() => { handleButtonClick(route, action, actionParam) } }>
        <Box className={classes.inner_option}>
          {icon}
          <Box pt={1}>
            <Typography style={theme.custom.subtitle3}>{topText}</Typography>
            <Typography style={theme.custom.subtitle3}>{bottomText}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  const displayBadge = () => {
    if (!user.isEmpty() && (walletDisableOptions && !walletDisableOptions.includes(DISABLE_OPTIONS.BADGE))) {
      return makeSingleLineButton(getMyBadgeText(), <BadgeIcon className={classes.icon} />, null, setOpenBadgeDialog, true)
    }
  }

  const displayExchangeServices = (providerName) => {
    return makeSingleLineButton(ExchangeServiceInlineComponent(providerName), <TicketsIcon className={classes.icon} />, null, setOpenExchangeServiceDialog, {open: true, provider: providerName})
  }

  /**
   * Display all the options depending on various rules
   */
  const displayOptions = () => {

    const displayShop = () => {
      if (walletDisableOptions && !walletDisableOptions.includes(DISABLE_OPTIONS.SHOP)) {
        return makeSingleLineButton(t('SHOP'), <ShoppingCartIcon className={classes.icon} />, '/')
      }
    }

    const displayOrders = () => {
      if (walletDisableOptions && !walletDisableOptions.includes(DISABLE_OPTIONS.ORDERS)) {
        return makeSingleLineButton(t('ORDERS'), <OrdersIcon className={classes.icon} />, '/profile/orders')
      }
    }

    const displayMyInfo = () => {
      if (walletDisableOptions && !walletDisableOptions.includes(DISABLE_OPTIONS.INFO)) {
        return makeSingleLineButton(t('MY_INFO'), <PersonIcon className={classes.icon} />, '/profile/settings')
      }
    }

    // user doesn't exist, only show the bare minimum
    if (user.isEmpty() || user.get('provider') === 'vn_anonymous') {
      return displayShop()
    }

    const displayLoyalty = () => {
      if (props.awardsRules && !props.awardsRules.isEmpty()) {
        return makeSingleLineButton(t('BENEFITS_AND_REWARDS'), <BenefitsIcon className={classes.icon} />, '/wallet/virtual-currency/awards-rules')
      }
    }

    /**
     * only display scan and pay if there are options in the wallet mode in VNConfig
     */
    const displayScanAndPay = () => {
      if (walletModeConfig.length > 0) {
        if (webSDKMode) {
          if ((webSDKVersion && semver.lt(webSDKVersion, '2.0.5')) || !webSDKVersion) {
            return (
              makeSingleLineButton(getScanAndPayText(), <BarcodeIcon className={classes.icon}/>, '/wallet/scan-and-pay')
            )
          }
        }

        if (walletMode === WalletMode.BOTH) {
          return (
            <Fragment>
              {makeSingleLineButton(getScanAndPayText(), <BarcodeIcon className={classes.icon}/>, '/wallet/scanner')}
              {makeSingleLineButton(getQRCodePayText(), <QRCodeIcon className={classes.icon}/>, '/wallet/qrcode')}
            </Fragment>
          )
        } else if (walletMode === WalletMode.QR_SCANNER_ONLY) {
          return (
            makeSingleLineButton(getScanAndPayText(), <BarcodeIcon className={classes.icon}/>, '/wallet/scanner')
          )
        } else if (walletMode === WalletMode.QR_CODE_ONLY) {
          return (
            makeSingleLineButton(getQRCodePayText(), <QRCodeIcon className={classes.icon}/>, '/wallet/qrcode')
          )
        }
      }
    }

    const displayPayments = () => {
      if (!isExternalPaymentProcessor) {
        return (makeSingleLineButton(t('PAYMENTS'), <PaymentsIcon className={classes.icon} />, null, setOpenPaymentUpdatesDialog, true))
      }

      return null
    }

    const displayVirtualCurrencyTransfer = () => {
      if (virtualCurrencyWalletHiddenOptions && !virtualCurrencyWalletHiddenOptions.includes('transfer')) {
        return makeDoubleLineButton(t('SEND'), virtualCurrencyName, <SendIcon className={classes.icon} />, '/wallet/virtual-currency/transfer')
      }
    }

    const displayVirtualCurrencyActivity = () => {
      if (virtualCurrencyWalletHiddenOptions && !virtualCurrencyWalletHiddenOptions.includes('activity')) {
        return makeDoubleLineButton(virtualCurrencyName, t('VIRTUAL_CURRENCY_ACTIVITY'), <AccountBalanceWalletIcon className={classes.icon} />, '/wallet/virtual-currency/activity')
      }
    }

    const displayGiftCard = () => {
      if (walletDisableOptions && !walletDisableOptions.includes(DISABLE_OPTIONS.GIFTCARDS)) {
        return makeSingleLineButton(getLoadGiftCardText(), <CardGiftcardIcon className={classes.icon} />, null, setOpenGiftCardTransferDialog, true)
      }
    }

    // User is authenticated and has a loyalty user for virtual currency
    if (!user.isEmpty() && isVirtualCurrencyEnabled) {
      return (
        <Fragment>
          {displayShop()}
          {displayOrders()}
          {displayScanAndPay()}
          {displayPayments()}
          {displayLoyalty()}
          {displayVirtualCurrencyTransfer()}
          {exchangeServices.map(provider => {
            return displayExchangeServices(provider)
          })}
          {displayGiftCard()}
          {displayVirtualCurrencyActivity()}
          {displayBadge()}
          {displayMyInfo()}
        </Fragment>
      )
    }

    // User is Authenticated, but NO virtual currency
    return (
      <Fragment>
        {displayShop()}
        {displayOrders()}
        {displayScanAndPay()}
        {displayPayments()}
        {displayBadge()}
        {displayMyInfo()}
      </Fragment>
    )

  }

  return (
    <Box className={classes.grid} p={2}>
      {displayOptions()}
    </Box>
  )
}
