import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import IconButton from '@material-ui/core/IconButton'
import { InputLabel } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 28,
  },
  iconButton: {
    padding: 10,
  },
  inputLabel: {
    paddingLeft: 15,
  },
  input: {
    paddingLeft: 25,
  },
  notched: {
    paddingLeft: "20px!important",
  },
  inputLabelTheme: theme.typography.h4,
}));

/**
 * 
 * A custom rounded text input
 * 
 * @param {string} [default] - What is the initial name we will populate in the select
 * @param {string} [label] = The label name you wish to display
 * @param {string} [labelWidth] - The width of this label, it is NOT calculated dynamically
 * @param {Icon} [endAdornment] - An end adornment to display
 * @param {string} [type] - The input type (default: text)
 * @param {func} [onChange] - Function to call on value change
 * @callback [onChangeLimiter] - Function callback to limit text input
 */
export const VNRoundedTextInput = forwardRef((props, ref) => {

  // Need this effect to allow for text to be
  // changed multiple times if component is rerendered
  useEffect(() => {
    if (props.default) {
      setTextValue(props.default)
    }
  }, [props.default])

  const classes = useStyles();

    // exposed functions to the parent
    useImperativeHandle(ref, () => ({
      getInput() {
        return textValue
      },
      clearInput() {
        setTextValue('')
      },
      validate() {
        if (props.validation) {
          if (!props.validation(textValue)) {
            setError(true)
            return false
          } else {
            setError(false)
            return true
          }
        }
        return true
      }
    }))

  // the state text input that has been entered
  const [textValue, setTextValue] = useState(props.default ? props.default : '')

  const [error, setError] = useState(false)

  // when the text input has changed
  const handleChange = (event) => {
    if (props.onChangeLimiter) {
      if (!props.onChangeLimiter(event.target.value)) {
        return
      }
    }
    setTextValue(event.target.value)
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  }

  // display the end adornment or not
  const displayEndAdornment = () => {
    if (props.endAdornment) {
      return (
        <IconButton type="submit" className={classes.iconButton}>
          {props.endAdornment}
        </IconButton>
      )
    }
  }

  return (
    <Fragment>
      <FormControl fullWidth variant="outlined" error={error} required={props.required ? props.required : false}>
        <InputLabel className={clsx(classes.inputLabel, classes.inputLabelTheme)} htmlFor="outlined-adornment-amount">{props.label}</InputLabel>
        <OutlinedInput
          name={props.name ? props.name : null}
          className={classes.root}
          classes={{notchedOutline: classes.notched, input: clsx(classes.input, classes.inputLabelTheme)}}
          value={textValue}
          onChange={handleChange}
          endAdornment={displayEndAdornment()}
          labelWidth={props.labelWidth}
          type={props.type ? props.type : 'text'}
        />
      </FormControl>
    </Fragment>
  )
})
