
/**
 * a basic validation check to see there is text present
 * @param {String} val - String value sent from field
 */
export const nameValidation = (val) => {

  if (val.length <= 0) {
    return false
  }

  return true
}

/**
 * email field validation conforming to RFC 5322
 * @param {String} val - String value sent from field
 */
export const emailValidation = (val) => {
  
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (val.length <= 0 || !regex.test(val)) {
    return false
  }

  return true
}

/**
 * Email field validation conforming to RFC 5322
 * Allow empty fields in the event the field needs to be validated
 * but is not required by the form to submit
 * @param {String} val - String value sent from field
 */
export const emailValidationAllowEmpty = (val) => {

  if (val.length === 0) {
    return true
  } 

  return emailValidation(val)
}

/**
 * Phone number validation
 * @param {String} val - String value sent from field
 */
export const phoneNumberValidation = (val) => {

  const regex = /^(?:\d{8}(?:\d{2}(?:\d{2})?)?|\(\+?\d{2,3}\)\s?(?:\d{4}[\s*.-]?\d{4}|\d{3}[\s*.-]?\d{3}|\d{2}([\s*.-]?)\d{2}\1\d{2}(?:\1\d{2})?))$/gm;
  if (val.length <= 0 || !regex.test(val)) {
    return false
  }

  return true
}

/**
 * Phone number validation
 * Allow empty fields in the event the field needs to be validated
 * but is not required by the form to submit
 * @param {String} val - String value sent from field
 */
export const phoneNumberValidationAllowEmpty = (val) => {

  if (val.length === 0) {
    return true
  }
  
  return phoneNumberValidation(val)
}

