import React, { Component } from  'react'
import { connect } from 'react-redux'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import classNames from 'classnames'
import { get, head, isFunction, keys, findIndex, values, isString } from 'lodash'
import moment from 'moment-timezone'
import { withTranslation } from 'react-i18next'

import { updateMetadata, clearCart } from '../actions/cart'

import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'
import Button from '../components/Button'
import ErrorMessage from '../components/ErrorMessage'

import { getMetadataScreens } from '../selectors/experience'
import { makeGetCartMenuItems } from '../selectors/cart'

import './ExperienceCheckout.scss'

export class ExperienceCheckout extends Component {
  constructor(props) {
    super(props)

    this.autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy')
  }

  state = {
    alertDialog: defaultAlertDialogState,
    allMetadata: {},
    errorMessage: null,
    itemNumber: 1,
    screenNumber: 0,
  }

  componentDidMount() {
    const { screens, editMode, initialValue, startingItemNumber } = this.props

    this.setState({ itemNumber: startingItemNumber || 1 })

    if (!editMode) return

    const field = head(screens['1'])
    this.setState({ [field.fieldKey]: initialValue })
  }

  componentDidUpdate (prevProps) {
    const { startingItemNumber } = this.props

    if (prevProps.startingItemNumber !== startingItemNumber) {
      this.setState({ itemNumber: startingItemNumber })
    }
  }

  updateNextSteps = () => {
    const { itemNumber, screenNumber } = this.state
    const { screens, onFormCompletion } = this.props

    // subtracting 1 because one key will be `startingItemNumber`
    const itemCount = keys(screens).length - 1
    const isLastItem = itemNumber === itemCount
    const screenCount = this.currentItemScreens().length
    const isLastScreen = screenNumber + 1 === screenCount

    if (isLastItem && isLastScreen) onFormCompletion()

    if (isLastScreen) {
      this.setState({ itemNumber: itemNumber + 1, screenNumber: 0, errorMessage: null })
    } else {
      this.setState({ screenNumber: screenNumber + 1, errorMessage: null })
    }
  }

  currentItemScreens = () => {
    const { itemNumber } = this.state
    const { screens } = this.props

    return screens[itemNumber] || []
  }

  currentScreen = () => {
    const { screenNumber } = this.state
    return this.currentItemScreens()[screenNumber]
  }

  didTapNextButton = (event) => {
    const { editMode, onFormCompletion, t } = this.props
    event.preventDefault()

    const datum = this.currentScreen()
    const value = this.state[datum.fieldKey]

    if (datum.fieldType === 'age' ) {
      if (!value) {
        this.setState({ errorMessage: t('PLEASE_FILL_IN_THE_ABOVE') })
        return
      } else if (!this.ageIsValid(value)) {
        this.setState({ errorMessage: datum.restriction.errorMessage })
        return
      }
    } else if (datum.fieldType === 'string' && !value) {
      this.setState({ errorMessage: t('PLEASE_FILL_IN_THE_ABOVE') })
      return
    } else if (datum.fieldType === 'seat_picker' && (!value || !value.section)) {
      this.setState({ errorMessage: t('PLEASE_ADD_YOUR_SEAT') })
      return
    }

    const { itemNumber } = this.state
    const { updateMetadata, itemId } = this.props
    const itemMetadata = this.prepMetadata(datum, value)

    if (editMode) {
      // if editing, we are only updating one at a time I hope.
      updateMetadata(itemId, itemMetadata[0])
      if (isFunction(onFormCompletion)) onFormCompletion()
    } else {
      updateMetadata(itemId, { [itemNumber]: itemMetadata })
      this.updateNextSteps()
    }
  }

  fieldDidChange = (key, event) => {
    const screen = this.currentScreen()
    const newValue = event.target.value
    const maxCharacterCount = screen.maxCharacterCount

    if (maxCharacterCount !== 0 && get(newValue, 'length', 0) > maxCharacterCount) return

    if (screen.fieldType === 'seat_picker') {
      const id = event.currentTarget.id.toLowerCase()
      const seating = this.state[key] ? { ...this.state[key] } : {}
      seating[id] = newValue

      this.setState({ [key]: seating })
      return
    }

    this.setState({ [key]: newValue })
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  prepMetadata = (datum, value) => {
    const { allMetadata, itemNumber } = this.state
    const metadata = {
      id: datum.fieldKey,
      name: datum.fieldLabel,
      value: value,
    }

    if (datum.fieldType === 'age') {
      metadata.value = moment(metadata.value, 'MM/DD/YYYY').format('DD/MM/YYYY')
    }

    let itemMetadata = allMetadata[itemNumber] || []
    var idx = findIndex(itemMetadata, { id: datum.fieldKey })
    idx < 0 ? itemMetadata.push(metadata) : itemMetadata.splice(idx, 1, metadata)

    this.setState({ allMetadata: { ...allMetadata, [itemNumber]: itemMetadata } })
    return itemMetadata
  }

  calculateTitle = () => {
    const screen = this.currentScreen()
    const { metadataLength } = screen
    const { screenNumber } = this.state
    const { t } = this.props

    let title = ''

    if (metadataLength === screenNumber + 1) {
      title = t('ONE_LAST_THING')
    } else {
      title = screenNumber + 1 === metadataLength - 1 ? t('ALMOST_DONE') : t('WE_NEED_SOME_MORE_INFO')
    }

    return title.charAt(0).toUpperCase() + title.substring(1)
  }

  renderHeader() {
    const { screenNumber, itemNumber } = this.state
    const { cartItem, t } = this.props

    const itemScreens = this.currentItemScreens()
    const screen = this.currentScreen()

    if (!screen) return null

    return (
      <div className="experience-checkout-navigation-bar">
        <h1>{this.calculateTitle()} <span onClick={this.goBack} className="close-icon"></span></h1>
        <p className="description">{t('EDIT_THESE_AFTER_PLACING_ORDER')}</p>
        {cartItem && cartItem.quantity !== 1 && (
          <h3 className="item-number">{`${cartItem.name} - ${itemNumber} of ${cartItem.quantity}`}</h3>
        )}
        <div className="steps">
          {itemScreens.length > 1 && itemScreens.map((_, index) => {
            const className = classNames('step', { active: index <= screenNumber })

            return <span key={index} className={className}></span>
          })}
        </div>
      </div>
    )
  }

  renderForm = () => {
    const screen = this.currentScreen()

    if (!screen) return null

    let valueLength = 0

    if (screen.fieldType === 'string' && this.state[screen.fieldKey]) {
      valueLength = this.state[screen.fieldKey].length
    }

    const fieldProps = {
      autoComplete: 'on',
      name: screen.fieldKey,
      id: screen.fieldKey,
      onChange: this.fieldDidChange.bind(this, screen.fieldKey),
      placeholder: screen.fieldPlaceholder,
      type: screen.fieldType === 'age' ? 'date' : 'text',
      value: this.state[screen.fieldKey] || '',
    }

    let field = (
      <textarea {...fieldProps} />
    )

    if (screen.fieldType === 'age') {
      field = (
        <MaskedInput
          name={screen.fieldKey}
          id={screen.fieldKey}
          className='date-masked-input'
          keepCharPositions={true}
          mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          pipe={this.autoCorrectedDatePipe}
          guide={true}
          placeholderChar={'_'}
          placeholder='MM/DD/YYYY'
          onChange={(event) => this.fieldDidChange(screen.fieldKey, event)}
          type={'telephone'}
          value={this.state[screen.fieldKey] || ''}
        />
      )
    }

    if (screen.fieldType === 'seat_picker') {
      const seatsFieldProps = ['Section', 'Row', 'Seat'].map(type => {
        const fieldValue = this.state[screen.fieldKey] ?
          this.state[screen.fieldKey][type.toLowerCase()] : null
        return {
          autoComplete: 'on',
          name: type,
          id: type,
          onChange: this.fieldDidChange.bind(this, screen.fieldKey),
          placeholder: `${this.props.t('ENTER_YOUR')} ${type}`,
          type: 'text',
          value: fieldValue || '',
        }
      })

      return (
        <div>
          <h2 className='seat-picker-label'>{screen.fieldLabel}</h2>
          {seatsFieldProps.map((field, key) => (
            <div className='form-row seat-picker-field' key={key}>
              <label htmlFor={field.name}>{field.name}</label>
              <input {...field} />
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className='form-row'>
        <label className='metadata-label' htmlFor={screen.fieldKey}>{screen.fieldLabel}</label>
        {screen.fieldType === 'string' && screen.maxCharacterCount > 0 && <span className="character-max">{`${screen.maxCharacterCount - valueLength} Character Max`}</span>}
        {field}
      </div>
    )
  }

  ageIsValid = (birthdate) => {
    const screen = this.currentScreen()

    if (!screen.restriction || !birthdate) return

    const date = moment(birthdate, 'MM/DD/YYYY')

    if (!date.isValid() || moment().isBefore(date)) return

    const age = moment().diff(date, 'years')
    const minVal = isString(screen.restriction.minValue) ? moment().diff(screen.restriction.minValue, 'years') : screen.restriction.minValue
    const val = isString(screen.restriction.value) ? moment().diff(screen.restriction.value, 'years') : screen.restriction.value
    let isValid = true
    if (screen.restriction.operator === ">") {
      if (val >= age) isValid = false
    } else if (screen.restriction.operator === "<") {
      if (val <= age) isValid = false
    } else {
      if (!(minVal >= age && age >= val)) isValid = false
    }
    return isValid
  }

  render() {
    const { screenNumber, errorMessage, itemNumber, alertDialog } = this.state
    const { screens, cartItem, loading } = this.props
    const quantity = get(cartItem, 'quantity', 1)

    return (
      <div className="experience-checkout">
        {this.renderHeader()}
        <form className="form-container">
            {this.renderForm()}
            <Button.Brand
              className='top'
              disabled={loading}
              onClick={this.didTapNextButton}
            >
              {itemNumber === quantity && screenNumber === screens[itemNumber].length - 1 ? this.props.t('DONE') : this.props.t('NEXT')}
            </Button.Brand>
            <br/>
            {errorMessage && <ErrorMessage error={errorMessage} />}
            {alertDialog.show && <AlertDialog {...alertDialog} />}
          </form>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {

  const itemId = get(ownProps, 'match.params.itemId', '')
  const menuId = get(state, 'cart.menuId', '')
  const standId = get(state, 'cart.standId', '')
  const getCartMenuItems = makeGetCartMenuItems()

  const cartItems = getCartMenuItems(state)
  const cartItem = values(cartItems).find((item) => get(item, 'uuid') === itemId)
  const screens = getMetadataScreens(state, menuId, itemId)
  const startingItemNumber = screens.startingItemNumber

  console.log(screens)

  return {
    cartItem,
    itemId,
    menuId,
    screens,
    standId,
    startingItemNumber,
  }
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    clearCart: () => dispatch(clearCart()),
    updateMetadata: (itemId, metadata) => dispatch(updateMetadata(itemId, metadata)),
    onFormCompletion: () => ownProps.history.replace('/cart')
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExperienceCheckout))

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withPaymentMappingHook(Cart)))
