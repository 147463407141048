import React, { Fragment, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles"
import QueryParamsHandler from './QueryParamsHandler'
import { VNUserPayments } from '../VNDialogs/components/VNUserPayments'
import { VNWebDrawer } from '../VNWebDrawer/containers/VNWebDrawer';
import { VNConfigurations } from '../VNConfigurations/containers/VNConfigurations'
import { VNAnonymousAccount } from '../VNWebAccount/containers/VNAnonymousAccount'
import { VNRedirectUser } from '../VNWebAccount/containers/VNRedirectUser'
import { defaultTheme } from '../App/themes/VNDefaultTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import './App.scss'
import LocaleAndTitle from './LocaleAndTitle'
import { VNWebSDKDeeplinkBridge } from '../VNWebSDK/containers/VNWebSDKDeeplinkBridge'
import { VNWebSDKDataBridge } from '../VNWebSDK/containers/VNWebSDKDataBridge'
import WebSDKNavMode from '../VNEnums/WebSDKNavMode'
import { VNSnackbarSystem } from '../VNSnackbarSystem/containers/VNSnackbarSystem'
import { VNBackdropSystem } from '../VNBackdropSystem/containers/VNBackdropSystem'
import { VNRevenueCenters } from '../VNRevenueCenters/containers/VNRevenueCenters'
import { VNQueryParams } from '../VNQueryParams/containers/VNQueryParams'
import { VNOTP } from '../VNOTP/containers/VNOTP'
import VNHooks from '../VNHooks/components/VNHooks'
import Cart from './Cart'
import { VNRevenueCenterFilter } from '../VNRevenueCenters/containers/VNRevenueCenterFilter'
import { getLastTimeAffiliationsRefreshed, getUserJWT, getUser } from '../VNUser/Selectors'
import { refreshUsersAffiliations } from '../VNUser/ActionCreators'
import * as UserActionTypes from '../VNUser/ActionTypes'
import { hasTimeElapsed } from '../VNUtil/VNTimeHelper'
import { setInitialPathname } from '../VNWebSDK/ActionCreators'
import { useTranslation } from 'react-i18next'
import { getAccentColor } from '../VNConfigurations/Selectors'
import {
  getWebSDKMode,
  getWebSDKNavMode,
  getInitialPathname,
  getWebSDKBackgroundColor
} from '../VNWebSDK/Selectors'
import { isEmpty } from 'lodash'
import ExperienceCheckout from './ExperienceCheckout'
import ExperienceOrderSummary from './ExperienceOrderSummary'
import EditMetadata from './EditMetadata'

import loadable from '@loadable/component'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 56,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    flex: '1 1 auto',
    display: 'flex',
    position: 'relative',
    '& > div.MuiBox-root': {
      width: '100%'
    }
  },
}));

export const App = (props) => {

  const classes = useStyles()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const user = useSelector(state => getUser(state))
  const userJWT = useSelector(state => getUserJWT(state))
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKNavMode = useSelector(state => getWebSDKNavMode(state))
  const webSDKBgColor = useSelector(state => getWebSDKBackgroundColor(state))
  const accentColor = useSelector(state => getAccentColor(state))
  const initialPathname = useSelector(state => getInitialPathname(state))
  const lastTimeUserAffiliationsRefreshed = useSelector(state => getLastTimeAffiliationsRefreshed(state))

  useEffect(() => {
    dispatch(setInitialPathname(props.history.location.pathname + props.history.location.search))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // check current timestamp epoch, if its been more than 12 hours, refresh user affiliations
  useEffect(() => {
    if (!isEmpty(userJWT) && user.get('provider') !== 'vn_anonymous' && hasTimeElapsed(lastTimeUserAffiliationsRefreshed, 12, 'hours')) {
      // dispatch VNUSER_SET_AFFILIATIONS_REFRESHED here so that refreshUsersAffiliations won't run multiple times
      dispatch({ type: UserActionTypes.VNUSER_SET_AFFILIATIONS_REFRESHED })
      dispatch(refreshUsersAffiliations(t, true))
    }

  }, [lastTimeUserAffiliationsRefreshed, userJWT, user])

  const isInitialPathname = () => {
    return props.location.pathname === initialPathname
  }

  const displayWebDrawer = () => {
    if (webSDKNavMode === WebSDKNavMode.WEB){
      return (<VNWebDrawer />)
    }
  }

  const modeMargin = () => {
    let margin = '0'

    if (!webSDKMode) {
      if (webSDKNavMode === WebSDKNavMode.WEB) {
        margin = '56px'
      }

      if (props.history.location.pathname === '/wallet') {
        margin = '0'
      }
    }

    return margin
  }

  const modeBackgroundColor = () => {
    if (webSDKMode && props.history.location.pathname === '/wallet') {
      return webSDKBgColor
    } else if (!webSDKMode && props.history.location.pathname === '/wallet') {
      return accentColor
    }

    return 'initial'
  }

  const getModeStyle = () => {
    return {
      marginTop: modeMargin(),
      backgroundColor: modeBackgroundColor()
    }
  }

  // checking to see what mode we are in
  const checkMode = () => {
    if (props.location.pathname === '/websdk') {
      return <Route exact path="/websdk" render={(props) => <VNWebSDKDeeplinkBridge {...props} />} />
    } else {
      const VNScanner = loadable(() => import('../VNWallet/containers/VNScanner'), {
        resolveComponent: (components) => components.VNScanner,
      })
      const VNMenu = loadable(() => import('../VNMenu/containers/VNMenu'),  {
        resolveComponent: (components) => components.VNMenu,
      })
      const VNMenuItem = loadable(() => import('../VNMenu/containers/VNMenuItem'),  {
        resolveComponent: (components) => components.VNMenuItem,
      })
      const VNPreorderMenu = loadable(() => import('../VNMenu/containers/VNPreOrderMenu'),  {
        resolveComponent: (components) => components.VNPreorderMenu,
      })
      const VNWallet = loadable(() => import('../VNWallet/containers/VNWallet'),  {
        resolveComponent: (components) => components.VNWallet,
      })
      const VNExperienceItem = loadable(() => import('../VNMenu/containers/VNExperienceItem'),  {
        resolveComponent: (components) => components.VNExperienceItem,
      })

      const VNReceipt = loadable(() => import('../VNOrders/containers/VNReceipt'),  {
        resolveComponent: (components) => components.VNReceipt,
      })
      const VNWebAccount = loadable(() => import('../VNWebAccount/containers/VNWebAccount'),  {
        resolveComponent: (components) => components.VNWebAccount,
      })
      const VNUserSignUp = loadable(() => import('../VNWebAccount/containers/VNUserSignUp'),  {
        resolveComponent: (components) => components.VNUserSignUp,
      })
      const VNConfirmCode = loadable(() => import('../VNWebAccount/containers/VNConfirmCode'),  {
        resolveComponent: (components) => components.VNConfirmCode,
      })
      const VNUserLogin = loadable(() => import('../VNWebAccount/containers/VNUserLogin'),  {
        resolveComponent: (components) => components.VNUserLogin,
      })
      const VNAXSLogin = loadable(() => import('../VNWebAccount/containers/VNAXSLogin'),  {
        resolveComponent: (components) => components.VNAXSLogin,
      })
      const VNUserOrders = loadable(() => import('../VNWebAccount/containers/VNUserOrders'),  {
        resolveComponent: (components) => components.VNUserOrders,
      })
      const VNUserSettings = loadable(() => import('../VNWebAccount/containers/VNUserSettings'),  {
        resolveComponent: (components) => components.VNUserSettings,
      })
      const VNTMCallback = loadable(() => import('../VNWebAccount/containers/VNTMCallback'),  {
        resolveComponent: (components) => components.VNTMCallback,
      })
      const VNSeatGeekCallback = loadable(() => import('../VNWebAccount/containers/VNSeatGeekCallback'),  {
        resolveComponent: (components) => components.VNSeatGeekCallback,
      })
      const VNScanAndPay = loadable(() => import('../VNWallet/containers/VNScanAndPay'),  {
        resolveComponent: (components) => components.VNScanAndPay,
      })
      const VNRichCheckoutQRCode = loadable(() => import('../VNWallet/containers/VNRichCheckoutQRCode'),  {
        resolveComponent: (components) => components.VNRichCheckoutQRCode,
      })
      const VNAwardsRules = loadable(() => import('../VNWallet/containers/VNAwardsRules'),  {
        resolveComponent: (components) => components.VNAwardsRules,
      })
      const VNVirtualCurrencyTransfer = loadable(() => import('../VNWallet/containers/VNVirtualCurrencyTransfer'),  {
        resolveComponent: (components) => components.VNVirtualCurrencyTransfer,
      })
      const VNVirtualCurrencyActivity = loadable(() => import('../VNWallet/containers/VNVirtualCurrencyActivity'),  {
        resolveComponent: (components) => components.VNVirtualCurrencyActivity,
      })
      const VNRichCheckoutCameraOrder = loadable(() => import('../VNOrders/containers/VNRichCheckoutCameraOrder'),  {
        resolveComponent: (components) => components.VNRichCheckoutCameraOrder,
      })

      return(
        <Fragment>
          <VNConfigurations />
          <VNAnonymousAccount />
          <LocaleAndTitle />
          {displayWebDrawer()}
          <VNWebSDKDataBridge className={classes.container} style={getModeStyle()} location={props.location}>
            <Route path="/" component={QueryParamsHandler} />
            <Route path="/" component={VNQueryParams} />
            <Route path="/" component={VNHooks} />
            <Route path="/" exact component={VNRevenueCenters} />
            <Route path="/filter" component={VNRevenueCenterFilter} />
            <Switch>
              <Route path="/:standId/menu/:menuId/item/:itemId" component={VNMenuItem} />
              <Route path="/:standId/menu/:menuId" component={VNMenu} />
            </Switch>
            <Switch>
              <Route path="/:standId/experiences/:menuId/item/:itemId" component={VNExperienceItem} />
              <Route path="/:standId/experiences/:menuId" component={VNMenu} />
            </Switch>
            <Switch>
              <Route path="/:standId/preorder/:menuId/item/:itemId" component={VNMenuItem} />
              <Route path="/:standId/preorder/:menuId" component={VNMenu} />
            </Switch>
            <Route path="/cart" component={Cart} />
            <Route path="/experiences/checkout/:itemId" component={ExperienceCheckout} />
            <Route path="/experience_orders/:orderId" component={ExperienceOrderSummary} />
            <Route path="/edit-metadata/:orderId/:userItemId/:metadataId" exact component={EditMetadata} />
            <Route path="/orders/:orderId" render={(props) => <VNReceipt {...props} />} />
            <Route exact path="/profile" render={(props) => <VNWebAccount {...props} />} />
            <Route path="/profile/signup" render={(props) => <VNUserSignUp {...props} />} />
            <Route path="/profile/confirm-code" render={(props) => <VNConfirmCode {...props} />} />
            <Route path="/profile/login" render={(props) => <VNUserLogin {...props} />} />
            <Route path="/profile/axs-login" render={(props) => <VNAXSLogin {...props} /> } />
            {/* <Route path="/profile/passes" render={(props) => <VNUserPasses {...props} />} /> */}
            <Route path="/profile/orders" render={(props) => <VNUserOrders {...props} />} />
            <Route path="/profile/settings" render={(props) => <VNUserSettings {...props} />} />
            <Route path="/tm-auth-callback" render={(props) => <VNTMCallback {...props} />} />
            <Route path="/oauth/seatgeek" render={(props) => <VNSeatGeekCallback {...props} />} />
            <Route exact path="/wallet" render={(props) => <VNWallet {...props} />} />
            <Route path="/wallet/scan-and-pay" render={(props) => <VNScanAndPay {...props} />} />
            <Route exact path="/wallet/scanner" render={(props) => <VNScanner {...props} />} />
            <Route exact path="/wallet/scanner/:standId/menu/:menuId" render={(props) => <VNScanner {...props} />} />
            <Route path="/wallet/qrcode" render={(props) => <VNRichCheckoutQRCode {...props} />} />
            <Route path="/wallet/virtual-currency/awards-rules" render={(props) => <VNAwardsRules {...props} />} />
            <Route path="/wallet/virtual-currency/transfer" render={(props) => <VNVirtualCurrencyTransfer {...props} />} />
            <Route path="/wallet/virtual-currency/activity" render={(props) => <VNVirtualCurrencyActivity {...props} />} />
            <Route path="/redirect-user" render={(props) => <VNRedirectUser {...props} />} />
            {/* //vn/order/pay (double slash) is needed because that is how QR codes are currrently spitting out their links */}
            <Route path="//vn/order/pay" render={(props) => <VNRichCheckoutCameraOrder {...props} />} />
            {/* /vn/order/pay is also included because Android devices santize URLs and remove the above double slash */}
            <Route path="/vn/order/pay" render={(props) => <VNRichCheckoutCameraOrder {...props} />} />
            <Route path="/payment" render={(props) => <VNOTP {...props} />} />
          </VNWebSDKDataBridge>
        </Fragment>
      )
    }
  }

  return (
    <div className="root">
      <ThemeProvider theme={accentColor ? defaultTheme(accentColor) : defaultTheme('#794bc4')}>
        {checkMode()}
        <VNUserPayments />
        <VNSnackbarSystem />
        <VNBackdropSystem />
      </ThemeProvider>
    </div>
  )
}
