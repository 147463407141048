export const mockApiFetchMenu = () => {
  return new Promise((resolve) => {
    resolve({ data:
      {
        "menu": {
          "uuid": "cde84c5d-6b0a-4d1b-a446-6c910076f8bd",
          "name": "Express Pickup Test - Express Pickup",
          "has_alcohol": true,
          "alcohol_limit": 2,
          "crowdness": null,
          "description": "Skip the line and pre-order your meal, at no added cost.  We'll prepare it fresh once you arrive at the stand.",
          "max_order_amount_in_dollars": 500,
          "is_available": true,
          "alcohol_limit_message": "Only 2 beverages per person are allowed.",
          "uses_qr_code": false,
          "service_type": "Concessions",
          "short_description": null,
          "long_description": null,
          "usage_type": "pickup",
          "service_type_display_name": "Express Pickup",
          "stand_name": "Express Pickup Test",
          "stand_uuid": "6369f382-4e0d-45ec-921d-f5a90338fe5f",
          "order_ahead": false,
          "type": "SingleStandMenu",
          "sales_event_type_name": null,
          "subtype": "day_of_event",
          "wait_time_in_minutes": 26,
          "product_type": "Food",
          "preorder": true,
          "service_types": [{
            "name": "Concessions",
            "display_name": "Express Pickup"
          }],
          "stand_images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stands-tout-6369f382-4e0d-45ec-921d-f5a90338fe5f.png",
            "list": null
          },
          "section": null,
          "tax_rate": "0.07",
          "images": {
            "detail": "https://d2bekohrrq5iy7.cloudfront.net/arena/dev/stand-menus-tout-cde84c5d-6b0a-4d1b-a446-6c910076f8bd.png",
            "list": null
          },
          "order_now": true,
          "tip_suggestions": []
        },
        "events": {},
        "categories": [{
          "item_category_uuid": "0871c55f-3d77-4fcb-83cf-d549767b3c7c",
          "category_type": null,
          "name": "Food 2345 name",
          "modifier": false,
          "color": "#87FD8F",
          "major_category": true
        }, {
          "item_category_uuid": "ea004276-0209-4a41-a75e-a02f07dc37aa",
          "category_type": null,
          "name": "DELICIOUS FOOD",
          "modifier": false,
          "color": "#0080FF",
          "major_category": false
        }, {
          "item_category_uuid": "21435b3c-d983-4cfd-9c67-88d571eeb687",
          "category_type": null,
          "name": "Test Food",
          "modifier": false,
          "color": "#6d4c41",
          "major_category": true
        }, {
          "item_category_uuid": "aca3e8b6-1f20-4147-a2a7-564428149712",
          "category_type": null,
          "name": "Nick",
          "modifier": false,
          "color": "#39bd00",
          "major_category": false
        }, {
          "item_category_uuid": "4adda202-f081-4c25-939e-4bc1a50f4a33",
          "category_type": null,
          "name": "Type of Milk123",
          "modifier": false,
          "color": "#dddeee",
          "major_category": false
        }, {
          "item_category_uuid": "ece086d8-3e63-4e10-a115-ec5de2a689a8",
          "category_type": null,
          "name": "Beverage",
          "modifier": false,
          "color": "#f45d22",
          "major_category": true
        }, {
          "item_category_uuid": "e24a4ef7-6067-469b-9313-34b4810fde17",
          "category_type": null,
          "name": "NON-ALCOHOLIC BEVERAGES",
          "modifier": false,
          "color": "",
          "major_category": false
        }, {
          "item_category_uuid": "e0d2bc62-fc12-49ff-9370-ee41d84bd25d",
          "category_type": null,
          "name": "Amber's Test Category 123",
          "modifier": false,
          "color": "#17bf64",
          "major_category": true
        }, {
          "item_category_uuid": "ef82188b-e781-4f0c-9adc-38a613d27f1e",
          "category_type": null,
          "name": "Beer",
          "modifier": false,
          "color": "#e040fb",
          "major_category": false
        }, {
          "item_category_uuid": "c3f59891-f203-4352-ac10-cf1f82eee5b2",
          "category_type": "DIETARY RESTRICTION",
          "name": "VEGETARIAN",
          "modifier": false,
          "color": "#e21e5c",
          "major_category": false
        }, {
          "item_category_uuid": "71f93265-4ccb-43b9-a7d8-6aadfa4b57ff",
          "category_type": null,
          "name": "Food",
          "modifier": false,
          "color": "#1da1f2",
          "major_category": false
        }, {
          "item_category_uuid": "d344b666-3dbe-4652-9ac6-5b1c14c48b5f",
          "category_type": null,
          "name": "Hot Dog Modifiers",
          "modifier": false,
          "color": "#794bc4",
          "major_category": false
        }, {
          "item_category_uuid": "7e22a94e-0c4f-44c0-8576-00ebc7d7065a",
          "category_type": null,
          "name": "test 4",
          "modifier": false,
          "color": "#f45d22",
          "major_category": true
        }, {
          "item_category_uuid": "4130cdad-d934-438d-850b-07179c8981ac",
          "category_type": null,
          "name": "test 3",
          "modifier": false,
          "color": "#6d4c41",
          "major_category": true
        }, {
          "item_category_uuid": "a36d7d8e-e1d9-4285-bde1-ade0c953797e",
          "category_type": null,
          "name": "name 2",
          "modifier": false,
          "color": "#ffad1f",
          "major_category": true
        }, {
          "item_category_uuid": "d0d3d0c2-6106-47b0-b710-c9a27ecb9a37",
          "category_type": null,
          "name": "Report 1 TEST 2",
          "modifier": false,
          "color": "#17bf64",
          "major_category": true
        }, {
          "item_category_uuid": "40a914dc-5d84-4f83-a5c7-25cedd117495",
          "category_type": null,
          "name": "Selleo product type",
          "modifier": false,
          "color": "",
          "major_category": false
        }, {
          "item_category_uuid": "f2772c6a-7341-4824-a983-e974d5cd19e5",
          "category_type": null,
          "name": "test ",
          "modifier": false,
          "color": "#e21e5c",
          "major_category": true
        }, {
          "item_category_uuid": "59a662a2-999d-446a-a2ad-8510b1879ac1",
          "category_type": null,
          "name": "Reporting test 7890",
          "modifier": false,
          "color": "#1da1f2",
          "major_category": true
        }, {
          "item_category_uuid": "d86b60fc-e2dd-477f-980f-4b4a0af016ad",
          "category_type": null,
          "name": "Dessert Cart",
          "modifier": false,
          "color": "#99FFFF",
          "major_category": false
        }, {
          "item_category_uuid": "9d95c7d1-51cc-44e4-af4b-a820412a481f",
          "category_type": null,
          "name": "Food",
          "modifier": false,
          "color": "#660066",
          "major_category": false
        }, {
          "item_category_uuid": "10b93a5b-57a0-4d5f-b73f-9cdb66d1677b",
          "category_type": null,
          "name": "Food",
          "modifier": false,
          "color": "#660066",
          "major_category": true
        }, {
          "item_category_uuid": "535ca036-0b19-4209-b888-16d67d3e02c7",
          "category_type": null,
          "name": "Dessert Cart",
          "modifier": false,
          "color": "#99FFFF",
          "major_category": true
        }, {
          "item_category_uuid": "1fdb3d6d-de99-4875-a952-4a5e146f8b89",
          "category_type": null,
          "name": "Food",
          "modifier": false,
          "color": "#660066",
          "major_category": true
        }, {
          "item_category_uuid": "87fb0e59-ed73-49b0-bd4d-cd029332fb13",
          "category_type": null,
          "name": "Dessert Cart",
          "modifier": false,
          "color": "#99FFFF",
          "major_category": true
        }, {
          "item_category_uuid": "7bff170b-860c-4d8b-8297-bc3ed43efe84",
          "category_type": null,
          "name": "Food",
          "modifier": false,
          "color": "#660066",
          "major_category": true
        }, {
          "item_category_uuid": "5588c7bf-42e8-4f89-87ad-2aa3a00dbf8f",
          "category_type": null,
          "name": "Dessert Cart",
          "modifier": false,
          "color": "#99FFFF",
          "major_category": true
        }],
        "modifier_categories": [{
          "item_category_uuid": "ab6b2688-b497-4548-afeb-d62be09ec6c9",
          "category_type": null,
          "name": "Nacho Modifier ",
          "modifier": true,
          "color": "#f45d22",
          "major_category": false
        }, {
          "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
          "category_type": null,
          "name": "Add-Ons 2",
          "modifier": true,
          "color": "#17bf64",
          "major_category": false
        }, {
          "item_category_uuid": "a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6",
          "category_type": null,
          "name": "Beer Size",
          "modifier": true,
          "color": "#e040fb",
          "major_category": false
        }, {
          "item_category_uuid": "f56a87de-11fa-4b90-94a2-1584e33dceef",
          "category_type": null,
          "name": "Hot Drink Add Ons",
          "modifier": true,
          "color": "",
          "major_category": false
        }, {
          "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
          "category_type": null,
          "name": "Bun",
          "modifier": true,
          "color": "#6d4c41",
          "major_category": false
        }, {
          "item_category_uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
          "category_type": null,
          "name": "Add-Ons",
          "modifier": true,
          "color": "#ffad1f",
          "major_category": false
        }],
        "items": [{
          "name": "Hot Diggity Dog",
          "description": "Unlimited Toppings",
          "marketing_description": "Unlimited Toppings",
          "category": "Report 1 TEST 2",
          "default_price_in_cents": 106,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "523fcca8-fd36-4d8f-a9e2-6df8af200ea3",
            "inventory_state": "available",
            "price_in_cents": 106,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "VN001",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "d0d3d0c2-6106-47b0-b710-c9a27ecb9a37",
          "item_category_uuids": ["0871c55f-3d77-4fcb-83cf-d549767b3c7c", "40a914dc-5d84-4f83-a5c7-25cedd117495", "4130cdad-d934-438d-850b-07179c8981ac", "a36d7d8e-e1d9-4285-bde1-ade0c953797e", "aca3e8b6-1f20-4147-a2a7-564428149712", "d0d3d0c2-6106-47b0-b710-c9a27ecb9a37", "d344b666-3dbe-4652-9ac6-5b1c14c48b5f", "ea004276-0209-4a41-a75e-a02f07dc37aa"],
          "item_categories": [{
            "uuid": "0871c55f-3d77-4fcb-83cf-d549767b3c7c",
            "sort_order": 3
          }, {
            "uuid": "40a914dc-5d84-4f83-a5c7-25cedd117495",
            "sort_order": 3
          }, {
            "uuid": "4130cdad-d934-438d-850b-07179c8981ac",
            "sort_order": 3
          }, {
            "uuid": "a36d7d8e-e1d9-4285-bde1-ade0c953797e",
            "sort_order": 3
          }, {
            "uuid": "aca3e8b6-1f20-4147-a2a7-564428149712",
            "sort_order": 3
          }, {
            "uuid": "d0d3d0c2-6106-47b0-b710-c9a27ecb9a37",
            "sort_order": 3
          }, {
            "uuid": "d344b666-3dbe-4652-9ac6-5b1c14c48b5f",
            "sort_order": 3
          }, {
            "uuid": "ea004276-0209-4a41-a75e-a02f07dc37aa",
            "sort_order": 3
          }],
          "id": "523fcca8-fd36-4d8f-a9e2-6df8af200ea3",
          "uuid": "523fcca8-fd36-4d8f-a9e2-6df8af200ea3",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": true,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_detail/VN001.png",
            "list": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_item/VN001.png"
          },
          "modifier_groups": [{
            "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
            "name": "Bun",
            "min": 1,
            "max": 5,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["d6587c5b-7bb1-4f43-bbbd-e2330d650b4f"],
              "sort_order": 3,
              "maximum_modifier_count": 5,
              "minimum_modifier_count": 1,
              "items": [{
                "name": "White Bun",
                "description": "",
                "marketing_description": "",
                "category": "Bun",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "WHITEBUN",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e"],
                "item_categories": [{
                  "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                  "sort_order": 4
                }],
                "id": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                "uuid": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Sourdough Bun",
                "description": null,
                "marketing_description": null,
                "category": "Bun",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "SDBUN",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e"],
                "item_categories": [{
                  "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                  "sort_order": 5
                }],
                "id": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                "uuid": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }, {
            "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
            "name": "Add-Ons",
            "min": 1,
            "max": 5,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8"],
              "sort_order": 3,
              "maximum_modifier_count": 5,
              "minimum_modifier_count": 1,
              "items": [{
                "name": "Jalapenos",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9010001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 32
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 32
                }],
                "id": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "mayonnaise",
                "description": "",
                "marketing_description": "",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9013002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 33
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 33
                }],
                "id": "3934e795-81ec-45ce-b95e-0113f9175326",
                "uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": true,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Pepper Jack Cheese",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9016001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 34
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 34
                }],
                "id": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }, {
            "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
            "name": "Add-Ons 2",
            "min": 1,
            "max": 5,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": [],
              "sort_order": 3,
              "maximum_modifier_count": 5,
              "minimum_modifier_count": 1,
              "items": [{
                "name": "Top Secret Spicy Sauce",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "8562dafd-c03d-4216-b64b-3a8feb2a7bf3",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9020002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 3
                }],
                "id": "8562dafd-c03d-4216-b64b-3a8feb2a7bf3",
                "uuid": "8562dafd-c03d-4216-b64b-3a8feb2a7bf3",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Jalapenos",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9010001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 32
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 32
                }],
                "id": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "mayonnaise",
                "description": "",
                "marketing_description": "",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9013002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 33
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 33
                }],
                "id": "3934e795-81ec-45ce-b95e-0113f9175326",
                "uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": true,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Pepper Jack Cheese",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9016001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 34
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 34
                }],
                "id": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Red Hot Chips",
                "description": "Red Hot Chips",
                "marketing_description": "Red Hot Chips",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "d862452a-66ba-47f3-bd9e-2c776c6ec76c",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9856",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": null
                }],
                "id": "d862452a-66ba-47f3-bd9e-2c776c6ec76c",
                "uuid": "d862452a-66ba-47f3-bd9e-2c776c6ec76c",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }]
        }, {
          "name": "Tasty Chicken Tenders",
          "description": "long desc",
          "marketing_description": "Tasty and tender chicken that comes with your choice of dipping sauce!",
          "category": "Amber's Test Category 123",
          "default_price_in_cents": 151,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "cf0a3962-f7b6-40f8-ba05-d34ac74f9906",
            "inventory_state": "available",
            "price_in_cents": 151,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "SKU20",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "e0d2bc62-fc12-49ff-9370-ee41d84bd25d",
          "item_category_uuids": ["10b93a5b-57a0-4d5f-b73f-9cdb66d1677b", "1fdb3d6d-de99-4875-a952-4a5e146f8b89", "7bff170b-860c-4d8b-8297-bc3ed43efe84", "9d95c7d1-51cc-44e4-af4b-a820412a481f", "e0d2bc62-fc12-49ff-9370-ee41d84bd25d", "ea004276-0209-4a41-a75e-a02f07dc37aa"],
          "item_categories": [{
            "uuid": "10b93a5b-57a0-4d5f-b73f-9cdb66d1677b",
            "sort_order": 7
          }, {
            "uuid": "1fdb3d6d-de99-4875-a952-4a5e146f8b89",
            "sort_order": 7
          }, {
            "uuid": "7bff170b-860c-4d8b-8297-bc3ed43efe84",
            "sort_order": 7
          }, {
            "uuid": "9d95c7d1-51cc-44e4-af4b-a820412a481f",
            "sort_order": 7
          }, {
            "uuid": "e0d2bc62-fc12-49ff-9370-ee41d84bd25d",
            "sort_order": 7
          }, {
            "uuid": "ea004276-0209-4a41-a75e-a02f07dc37aa",
            "sort_order": 7
          }],
          "id": "cf0a3962-f7b6-40f8-ba05-d34ac74f9906",
          "uuid": "cf0a3962-f7b6-40f8-ba05-d34ac74f9906",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_detail/SKU20.png",
            "list": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_item/SKU20.png"
          },
          "modifier_groups": [{
            "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
            "name": "Add-Ons",
            "min": 0,
            "max": 0,
            "requires_selection": false,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8"],
              "sort_order": 7,
              "maximum_modifier_count": 0,
              "minimum_modifier_count": 0,
              "items": [{
                "name": "Jalapenos",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9010001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 32
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 32
                }],
                "id": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "mayonnaise",
                "description": "",
                "marketing_description": "",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9013002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 33
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 33
                }],
                "id": "3934e795-81ec-45ce-b95e-0113f9175326",
                "uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": true,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Pepper Jack Cheese",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9016001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 34
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 34
                }],
                "id": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }]
        }, {
          "name": "Chocolate Paradis Cake (Slice)",
          "description": "The best cake ever!",
          "marketing_description": "The best cake ever!",
          "category": "Dessert Cart",
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "21382f61-4e0d-48fc-9771-12b49019430e",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "SKU648",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": 5,
          "order_min_count": 2,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "87fb0e59-ed73-49b0-bd4d-cd029332fb13",
          "item_category_uuids": ["535ca036-0b19-4209-b888-16d67d3e02c7", "5588c7bf-42e8-4f89-87ad-2aa3a00dbf8f", "87fb0e59-ed73-49b0-bd4d-cd029332fb13", "d86b60fc-e2dd-477f-980f-4b4a0af016ad", "ea004276-0209-4a41-a75e-a02f07dc37aa"],
          "item_categories": [{
            "uuid": "535ca036-0b19-4209-b888-16d67d3e02c7",
            "sort_order": 13
          }, {
            "uuid": "5588c7bf-42e8-4f89-87ad-2aa3a00dbf8f",
            "sort_order": 13
          }, {
            "uuid": "87fb0e59-ed73-49b0-bd4d-cd029332fb13",
            "sort_order": 13
          }, {
            "uuid": "d86b60fc-e2dd-477f-980f-4b4a0af016ad",
            "sort_order": 13
          }, {
            "uuid": "ea004276-0209-4a41-a75e-a02f07dc37aa",
            "sort_order": 13
          }],
          "id": "21382f61-4e0d-48fc-9771-12b49019430e",
          "uuid": "21382f61-4e0d-48fc-9771-12b49019430e",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": true,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_detail/SKU648.png",
            "list": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_item/SKU648.png"
          },
          "modifier_groups": []
        }, {
          "name": "Pork-Sandwich",
          "description": "",
          "marketing_description": "",
          "category": "Food 2345 name",
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "4ed28b55-88fb-471f-afa1-d2387ea040d7",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "111",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "0871c55f-3d77-4fcb-83cf-d549767b3c7c",
          "item_category_uuids": ["0871c55f-3d77-4fcb-83cf-d549767b3c7c", "71f93265-4ccb-43b9-a7d8-6aadfa4b57ff", "ea004276-0209-4a41-a75e-a02f07dc37aa"],
          "item_categories": [{
            "uuid": "0871c55f-3d77-4fcb-83cf-d549767b3c7c",
            "sort_order": null
          }, {
            "uuid": "71f93265-4ccb-43b9-a7d8-6aadfa4b57ff",
            "sort_order": 11
          }, {
            "uuid": "ea004276-0209-4a41-a75e-a02f07dc37aa",
            "sort_order": 14
          }],
          "id": "4ed28b55-88fb-471f-afa1-d2387ea040d7",
          "uuid": "4ed28b55-88fb-471f-afa1-d2387ea040d7",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_detail/111.png",
            "list": null
          },
          "modifier_groups": [{
            "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
            "name": "Add-Ons",
            "min": 2,
            "max": null,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8"],
              "sort_order": null,
              "maximum_modifier_count": null,
              "minimum_modifier_count": 2,
              "items": [{
                "name": "Jalapenos",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9010001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 32
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 32
                }],
                "id": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "mayonnaise",
                "description": "",
                "marketing_description": "",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9013002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 33
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 33
                }],
                "id": "3934e795-81ec-45ce-b95e-0113f9175326",
                "uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": true,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Pepper Jack Cheese",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9016001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 34
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 34
                }],
                "id": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }]
        }, {
          "name": "Skim Milk",
          "description": null,
          "marketing_description": null,
          "category": null,
          "default_price_in_cents": 0,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "b3b2e255-779d-4efa-b1d3-9c5dc51caa2b",
            "inventory_state": "available",
            "price_in_cents": 0,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "skim_milk",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": ["4adda202-f081-4c25-939e-4bc1a50f4a33"],
          "item_categories": [{
            "uuid": "4adda202-f081-4c25-939e-4bc1a50f4a33",
            "sort_order": 0
          }],
          "id": "b3b2e255-779d-4efa-b1d3-9c5dc51caa2b",
          "uuid": "b3b2e255-779d-4efa-b1d3-9c5dc51caa2b",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.0",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }, {
          "name": "Fat Free Milk",
          "description": null,
          "marketing_description": null,
          "category": "test ",
          "default_price_in_cents": 0,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "10f59241-d142-442d-8c5d-fa015cd55675",
            "inventory_state": "available",
            "price_in_cents": 0,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "fat_free_milk",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "f2772c6a-7341-4824-a983-e974d5cd19e5",
          "item_category_uuids": ["4adda202-f081-4c25-939e-4bc1a50f4a33", "f2772c6a-7341-4824-a983-e974d5cd19e5"],
          "item_categories": [{
            "uuid": "4adda202-f081-4c25-939e-4bc1a50f4a33",
            "sort_order": 1
          }, {
            "uuid": "f2772c6a-7341-4824-a983-e974d5cd19e5",
            "sort_order": 1
          }],
          "id": "10f59241-d142-442d-8c5d-fa015cd55675",
          "uuid": "10f59241-d142-442d-8c5d-fa015cd55675",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.0",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }, {
          "name": "Whole Milk",
          "description": null,
          "marketing_description": null,
          "category": null,
          "default_price_in_cents": 0,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "8ca766ea-0526-4064-ba78-823316e91cf0",
            "inventory_state": "available",
            "price_in_cents": 0,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "whole_milk",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": ["4adda202-f081-4c25-939e-4bc1a50f4a33"],
          "item_categories": [{
            "uuid": "4adda202-f081-4c25-939e-4bc1a50f4a33",
            "sort_order": 2
          }],
          "id": "8ca766ea-0526-4064-ba78-823316e91cf0",
          "uuid": "8ca766ea-0526-4064-ba78-823316e91cf0",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.0",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }, {
          "name": "Free Item",
          "description": null,
          "marketing_description": null,
          "category": null,
          "default_price_in_cents": 0,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "812aa8f5-eb5c-4324-9764-055c4b8b5023",
            "inventory_state": "available",
            "price_in_cents": 0,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "free",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": ["e24a4ef7-6067-469b-9313-34b4810fde17"],
          "item_categories": [{
            "uuid": "e24a4ef7-6067-469b-9313-34b4810fde17",
            "sort_order": 52
          }],
          "id": "812aa8f5-eb5c-4324-9764-055c4b8b5023",
          "uuid": "812aa8f5-eb5c-4324-9764-055c4b8b5023",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_detail/free.png",
            "list": "https://d1hgunbs8ulpw7.cloudfront.net/arena/dev_item/free.png"
          },
          "modifier_groups": []
        }, {
          "name": "rgve",
          "description": "sdvv",
          "marketing_description": "sdvv",
          "category": null,
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "e27bbbcb-c15b-40ee-8bf7-6a2ac69ee076",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "swedv",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": ["ef82188b-e781-4f0c-9adc-38a613d27f1e"],
          "item_categories": [{
            "uuid": "ef82188b-e781-4f0c-9adc-38a613d27f1e",
            "sort_order": 69
          }],
          "id": "e27bbbcb-c15b-40ee-8bf7-6a2ac69ee076",
          "uuid": "e27bbbcb-c15b-40ee-8bf7-6a2ac69ee076",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": true,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }, {
          "name": "Test Entree Plate",
          "description": null,
          "marketing_description": null,
          "category": "Test Food",
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "94e57e93-6033-4712-823a-fb916a592e8f",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "909090909",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "21435b3c-d983-4cfd-9c67-88d571eeb687",
          "item_category_uuids": ["21435b3c-d983-4cfd-9c67-88d571eeb687", "c3f59891-f203-4352-ac10-cf1f82eee5b2"],
          "item_categories": [{
            "uuid": "21435b3c-d983-4cfd-9c67-88d571eeb687",
            "sort_order": null
          }, {
            "uuid": "c3f59891-f203-4352-ac10-cf1f82eee5b2",
            "sort_order": null
          }],
          "id": "94e57e93-6033-4712-823a-fb916a592e8f",
          "uuid": "94e57e93-6033-4712-823a-fb916a592e8f",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": true,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }, {
          "name": "Coffee",
          "description": null,
          "marketing_description": null,
          "category": "Beverage",
          "default_price_in_cents": 250,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "fbfa88dd-7a88-4c8b-9535-f8ac236a2cb4",
            "inventory_state": "available",
            "price_in_cents": 250,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "hot_coffee",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "ece086d8-3e63-4e10-a115-ec5de2a689a8",
          "item_category_uuids": ["c3f59891-f203-4352-ac10-cf1f82eee5b2", "ece086d8-3e63-4e10-a115-ec5de2a689a8"],
          "item_categories": [{
            "uuid": "c3f59891-f203-4352-ac10-cf1f82eee5b2",
            "sort_order": null
          }, {
            "uuid": "ece086d8-3e63-4e10-a115-ec5de2a689a8",
            "sort_order": null
          }],
          "id": "fbfa88dd-7a88-4c8b-9535-f8ac236a2cb4",
          "uuid": "fbfa88dd-7a88-4c8b-9535-f8ac236a2cb4",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.0",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": [{
            "uuid": "f56a87de-11fa-4b90-94a2-1584e33dceef",
            "name": "Hot Drink Add Ons",
            "min": 0,
            "max": null,
            "requires_selection": false,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": [],
              "sort_order": 5,
              "maximum_modifier_count": null,
              "minimum_modifier_count": 0,
              "items": [{
                "name": "Sweet \u0026 Low",
                "description": null,
                "marketing_description": null,
                "category": "Hot Drink Add Ons",
                "default_price_in_cents": 0,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "24b4799e-21a0-431c-a7c8-73a8cd3df785",
                  "inventory_state": "available",
                  "price_in_cents": 0,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "sweet_and_low",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": 4,
                "order_min_count": 1,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "f56a87de-11fa-4b90-94a2-1584e33dceef",
                "item_category_uuids": ["0871c55f-3d77-4fcb-83cf-d549767b3c7c", "7e22a94e-0c4f-44c0-8576-00ebc7d7065a", "f56a87de-11fa-4b90-94a2-1584e33dceef"],
                "item_categories": [{
                  "uuid": "0871c55f-3d77-4fcb-83cf-d549767b3c7c",
                  "sort_order": 7
                }, {
                  "uuid": "7e22a94e-0c4f-44c0-8576-00ebc7d7065a",
                  "sort_order": 7
                }, {
                  "uuid": "f56a87de-11fa-4b90-94a2-1584e33dceef",
                  "sort_order": 7
                }],
                "id": "24b4799e-21a0-431c-a7c8-73a8cd3df785",
                "uuid": "24b4799e-21a0-431c-a7c8-73a8cd3df785",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.0",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Sugar Packet",
                "description": null,
                "marketing_description": null,
                "category": "Hot Drink Add Ons",
                "default_price_in_cents": 0,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5f08a3b8-1714-4f02-8de0-4bf160cb173f",
                  "inventory_state": "available",
                  "price_in_cents": 0,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "sugar_packet",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "f56a87de-11fa-4b90-94a2-1584e33dceef",
                "item_category_uuids": ["f56a87de-11fa-4b90-94a2-1584e33dceef"],
                "item_categories": [{
                  "uuid": "f56a87de-11fa-4b90-94a2-1584e33dceef",
                  "sort_order": null
                }],
                "id": "5f08a3b8-1714-4f02-8de0-4bf160cb173f",
                "uuid": "5f08a3b8-1714-4f02-8de0-4bf160cb173f",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.0",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }, {
            "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
            "name": "Add-Ons",
            "min": 1,
            "max": null,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8"],
              "sort_order": 6,
              "maximum_modifier_count": null,
              "minimum_modifier_count": 1,
              "items": [{
                "name": "Jalapenos",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9010001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 32
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 32
                }],
                "id": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "mayonnaise",
                "description": "",
                "marketing_description": "",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9013002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 33
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 33
                }],
                "id": "3934e795-81ec-45ce-b95e-0113f9175326",
                "uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": true,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Pepper Jack Cheese",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9016001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 34
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 34
                }],
                "id": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }, {
            "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
            "name": "Add-Ons 2",
            "min": 1,
            "max": 4,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": [],
              "sort_order": 4,
              "maximum_modifier_count": 4,
              "minimum_modifier_count": 1,
              "items": [{
                "name": "Top Secret Spicy Sauce",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "8562dafd-c03d-4216-b64b-3a8feb2a7bf3",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9020002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 3
                }],
                "id": "8562dafd-c03d-4216-b64b-3a8feb2a7bf3",
                "uuid": "8562dafd-c03d-4216-b64b-3a8feb2a7bf3",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Jalapenos",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9010001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 32
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 32
                }],
                "id": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "uuid": "5b8a623d-5766-4d88-ac0c-f18df5b9a345",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "mayonnaise",
                "description": "",
                "marketing_description": "",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9013002",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 33
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 33
                }],
                "id": "3934e795-81ec-45ce-b95e-0113f9175326",
                "uuid": "3934e795-81ec-45ce-b95e-0113f9175326",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": true,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Pepper Jack Cheese",
                "description": null,
                "marketing_description": null,
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9016001",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["658a939d-a2a9-458c-9c10-9ab19c02df7d", "d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "658a939d-a2a9-458c-9c10-9ab19c02df7d",
                  "sort_order": 34
                }, {
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": 34
                }],
                "id": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "uuid": "aa2f9af5-3afc-40fe-85a4-d0c7c7400cb8",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Red Hot Chips",
                "description": "Red Hot Chips",
                "marketing_description": "Red Hot Chips",
                "category": "Add-Ons 2",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "d862452a-66ba-47f3-bd9e-2c776c6ec76c",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "9856",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                "item_category_uuids": ["d59c9fcb-1666-4792-b618-ac4b15511488"],
                "item_categories": [{
                  "uuid": "d59c9fcb-1666-4792-b618-ac4b15511488",
                  "sort_order": null
                }],
                "id": "d862452a-66ba-47f3-bd9e-2c776c6ec76c",
                "uuid": "d862452a-66ba-47f3-bd9e-2c776c6ec76c",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }, {
            "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
            "name": "Bun",
            "min": 0,
            "max": 3,
            "requires_selection": false,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["d6587c5b-7bb1-4f43-bbbd-e2330d650b4f"],
              "sort_order": 3,
              "maximum_modifier_count": 3,
              "minimum_modifier_count": 0,
              "items": [{
                "name": "White Bun",
                "description": "",
                "marketing_description": "",
                "category": "Bun",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "WHITEBUN",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e"],
                "item_categories": [{
                  "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                  "sort_order": 4
                }],
                "id": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                "uuid": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Sourdough Bun",
                "description": null,
                "marketing_description": null,
                "category": "Bun",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "SDBUN",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e"],
                "item_categories": [{
                  "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                  "sort_order": 5
                }],
                "id": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                "uuid": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }, {
            "uuid": "a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6",
            "name": "Beer Size",
            "min": 1,
            "max": 1,
            "requires_selection": true,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": [],
              "sort_order": 2,
              "maximum_modifier_count": 1,
              "minimum_modifier_count": 1,
              "items": [{
                "name": "Pint",
                "description": null,
                "marketing_description": null,
                "category": "Beer Size",
                "default_price_in_cents": 0,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "792cdb82-66c6-444c-938f-e4362ed00990",
                  "inventory_state": "available",
                  "price_in_cents": 0,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "stella_pint",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6",
                "item_category_uuids": ["a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6"],
                "item_categories": [{
                  "uuid": "a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6",
                  "sort_order": 1
                }],
                "id": "792cdb82-66c6-444c-938f-e4362ed00990",
                "uuid": "792cdb82-66c6-444c-938f-e4362ed00990",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.0",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "22oz",
                "description": null,
                "marketing_description": null,
                "category": "Beer Size",
                "default_price_in_cents": 200,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "1ac0ed16-3f24-4c53-8521-549e34df0ddf",
                  "inventory_state": "available",
                  "price_in_cents": 200,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "stella_22oz",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6",
                "item_category_uuids": ["a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6"],
                "item_categories": [{
                  "uuid": "a3014ad4-d6d1-4cfe-bfde-4088b6bda9c6",
                  "sort_order": 2
                }],
                "id": "1ac0ed16-3f24-4c53-8521-549e34df0ddf",
                "uuid": "1ac0ed16-3f24-4c53-8521-549e34df0ddf",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.0",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }]
        }, {
          "name": "Hotdog Auth Failure",
          "description": null,
          "marketing_description": null,
          "category": null,
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "fdc7488a-403e-4538-81f9-6e3b4af1ebc1",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "hotdogauthfailure",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": ["71f93265-4ccb-43b9-a7d8-6aadfa4b57ff"],
          "item_categories": [{
            "uuid": "71f93265-4ccb-43b9-a7d8-6aadfa4b57ff",
            "sort_order": 14
          }],
          "id": "fdc7488a-403e-4538-81f9-6e3b4af1ebc1",
          "uuid": "fdc7488a-403e-4538-81f9-6e3b4af1ebc1",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": true,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }, {
          "name": "Pretzel Bun",
          "description": null,
          "marketing_description": null,
          "category": "Reporting test 7890",
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "d6587c5b-7bb1-4f43-bbbd-e2330d650b4f",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "PRETZELBUN",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": "59a662a2-999d-446a-a2ad-8510b1879ac1",
          "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e", "59a662a2-999d-446a-a2ad-8510b1879ac1"],
          "item_categories": [{
            "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
            "sort_order": 3
          }, {
            "uuid": "59a662a2-999d-446a-a2ad-8510b1879ac1",
            "sort_order": 28
          }],
          "id": "d6587c5b-7bb1-4f43-bbbd-e2330d650b4f",
          "uuid": "d6587c5b-7bb1-4f43-bbbd-e2330d650b4f",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": true,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": [{
            "uuid": "ab6b2688-b497-4548-afeb-d62be09ec6c9",
            "name": "Nacho Modifier ",
            "min": null,
            "max": null,
            "requires_selection": null,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": [],
              "sort_order": 28,
              "maximum_modifier_count": null,
              "minimum_modifier_count": null,
              "items": [{
                "name": "testONE display name",
                "description": "",
                "marketing_description": "",
                "category": "Nacho Modifier ",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "d2a2e85f-f724-4f22-b9e4-6863df66ab58",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "bdf",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": "delivery_fee",
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "ab6b2688-b497-4548-afeb-d62be09ec6c9",
                "item_category_uuids": ["ab6b2688-b497-4548-afeb-d62be09ec6c9"],
                "item_categories": [{
                  "uuid": "ab6b2688-b497-4548-afeb-d62be09ec6c9",
                  "sort_order": null
                }],
                "id": "d2a2e85f-f724-4f22-b9e4-6863df66ab58",
                "uuid": "d2a2e85f-f724-4f22-b9e4-6863df66ab58",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }]
        }, {
          "name": "Cheeseburger",
          "description": null,
          "marketing_description": null,
          "category": null,
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "b52f1a7d-c138-4276-b71a-a822ead954c2",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "124567800",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": [],
          "item_categories": [],
          "id": "b52f1a7d-c138-4276-b71a-a822ead954c2",
          "uuid": "b52f1a7d-c138-4276-b71a-a822ead954c2",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": [{
            "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
            "name": "Bun",
            "min": null,
            "max": null,
            "requires_selection": null,
            "modifier_properties_container": {
              "secondary_price_threshold_count": null,
              "featured": [],
              "toggleable": [],
              "defaults": ["d6587c5b-7bb1-4f43-bbbd-e2330d650b4f"],
              "sort_order": null,
              "maximum_modifier_count": null,
              "minimum_modifier_count": null,
              "items": [{
                "name": "White Bun",
                "description": "",
                "marketing_description": "",
                "category": "Bun",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "WHITEBUN",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e"],
                "item_categories": [{
                  "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                  "sort_order": 4
                }],
                "id": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                "uuid": "11cea9be-c3b8-468c-826a-6bd6ce49d2ff",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }, {
                "name": "Sourdough Bun",
                "description": null,
                "marketing_description": null,
                "category": "Bun",
                "default_price_in_cents": 100,
                "ingredients": "",
                "is_alcohol": false,
                "variants": [{
                  "name": null,
                  "menu_item_uuid": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                  "inventory_state": "available",
                  "price_in_cents": 100,
                  "price_after_threshold_in_cents": 0,
                  "default_quantity": 0,
                  "modifier": false,
                  "product_sku": "SDBUN",
                  "linked_menu_item_uuid": null
                }],
                "is_modifier": true,
                "modifier": true,
                "order_max_amount": null,
                "order_min_count": null,
                "special_type": null,
                "variant_group_name": null,
                "variant_group_display_name": null,
                "variant_selection_text": null,
                "item_category_uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                "item_category_uuids": ["33aa4951-3818-4aa5-8fb8-70eb3493d00e"],
                "item_categories": [{
                  "uuid": "33aa4951-3818-4aa5-8fb8-70eb3493d00e",
                  "sort_order": 5
                }],
                "id": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                "uuid": "13da1c05-4cf9-455b-868e-09db4ebf58c0",
                "metadata": [],
                "can_enter_notes": false,
                "taxable": false,
                "rate": "0.1",
                "barcode": null,
                "stand_menu_uuid": null,
                "images": {
                  "detail": null,
                  "list": null
                },
                "modifier_groups": []
              }]
            }
          }]
        }, {
          "name": "Pickle",
          "description": null,
          "marketing_description": null,
          "category": null,
          "default_price_in_cents": 100,
          "ingredients": "",
          "is_alcohol": false,
          "variants": [{
            "name": null,
            "menu_item_uuid": "e389cb5e-9ebb-4c49-aca5-fe376092c5cb",
            "inventory_state": "available",
            "price_in_cents": 100,
            "price_after_threshold_in_cents": 0,
            "default_quantity": 0,
            "modifier": false,
            "product_sku": "PICKLE",
            "linked_menu_item_uuid": null
          }],
          "is_modifier": false,
          "modifier": false,
          "order_max_amount": null,
          "order_min_count": null,
          "special_type": null,
          "variant_group_name": null,
          "variant_group_display_name": null,
          "variant_selection_text": null,
          "item_category_uuid": null,
          "item_category_uuids": [],
          "item_categories": [],
          "id": "e389cb5e-9ebb-4c49-aca5-fe376092c5cb",
          "uuid": "e389cb5e-9ebb-4c49-aca5-fe376092c5cb",
          "metadata": [],
          "can_enter_notes": false,
          "taxable": false,
          "rate": "0.1",
          "barcode": null,
          "stand_menu_uuid": null,
          "images": {
            "detail": null,
            "list": null
          },
          "modifier_groups": []
        }],
        "service_fee_items": []
      }
    })
  })
}
