import * as ActionTypes from './ActionTypes'
import { VNSTAND_TRUNCATED_STAND_FROM_MENU } from '../VNStand/ActionTypes'
import { Map, List } from 'immutable'

const getInitialState = () => {
  return Map({
    'stands': List([]),
    'menus': List([]),
    'stands_by_id': Map({}),
    'menus_by_stands': Map({}),
    'sales_events': Map({}),
    'cross_venue_stands_by_id': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNREVENUECENTERS_SET_STANDS:
      return state.set('stands', List(action.val))
    case ActionTypes.VNREVENUECENTERS_SET_STANDS_BY_ID:
      return state.set('stands_by_id', Map(action.val))
    case ActionTypes.VNREVENUECENTERS_SET_MENUS:
      return state.set('menus', List(action.val))
    case ActionTypes.VNREVENUECENTERS_SET_MENUS_BY_STANDS:
      return state.set('menus_by_stands', Map(action.val))
    case VNSTAND_TRUNCATED_STAND_FROM_MENU:
      let existingMenusByStands = state.get('menus_by_stands').toObject()
      let truncatedStandUuid = action.menu.stand_uuid

      if (existingMenusByStands[truncatedStandUuid]) {
        existingMenusByStands[truncatedStandUuid].push(action.menu)
        return state.set('menus_by_stands', Map(existingMenusByStands))
      }

      existingMenusByStands[truncatedStandUuid] = [action.menu]

      return state.set('menus_by_stands', Map(existingMenusByStands))
    case ActionTypes.VNREVENUECENTERS_SET_SALES_EVENTS:
      return state.set('sales_events', Map(action.val))
    case ActionTypes.VNREVENUECENTERS_SET_CROSS_VENUE_STAND:
      return state.set('cross_venue_stands_by_id', action.stands)

    default:
      return state
  }
}

export default reducer
