import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    background: theme.custom.colors.accent,
    width: '100%',
    borderRadius: 6,
    height: 58
  },
  buttonText: {
    fontFamily: theme.custom.font,
    fontSize: theme.custom.button2.fontSize,
    fontWeight: theme.custom.button2.fontWeight,
    lineHeight: theme.custom.button2.lineHeight,
    color: theme.custom.button2.color
  },
  itemCount: {
    background: 'rgba(0, 0, 0, 0.25)',
    borderRadius: 3,
    width: 20
  }
}))

/**
 * @param {string} onClick function to handle button click
 * @param {string} count the count on left-hand side of the button
 * @param {string} amount the amount displayed on the right-hand side of the button
 * @param {string} title the text displayed in the middle of the button
 * @param {string} disabled whether the button is disabled or not.
 */
export const VNButtonSideData = (props) => {

  const classes = useStyles()

  const { t } = useTranslation()
  const theme = useTheme()

  // SELECTORS

  // USE EFFECTS

  return (
    <Box>
      <ButtonBase
        focusRipple
        key="checkout-button"
        className={classes.button}
        style={{background: props?.disabled && theme.palette.disabled.main}}
        onClick={props.onClick ? props.onClick : () => {}}
        disabled={props?.disabled ? true : false}
      >
        <Box className={classes.buttonContainer} ml={1} mr={1}>
          <Box px={0.2} py={0.5} className={classes.itemCount}>
            <Typography className={classes.buttonText}>
              {props.count}
            </Typography>
          </Box>
          <Typography className={classes.buttonText}>
            {props.title}
          </Typography>
          <Typography className={classes.buttonText}>{props.amount}</Typography>
        </Box>
      </ButtonBase>
    </Box>
  )
}
