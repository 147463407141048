import * as ActionTypes from './ActionTypes'

import WebSDKPlatform from '../VNEnums/WebSDKPlatform'
import WebSDKNavMode from '../VNEnums/WebSDKNavMode'

import { Map } from 'immutable'

const getInitialState = () => {
  return Map({
    'webSDKMode': false,
    'platform': WebSDKPlatform.WEB,
    'version': "",
    'bundleId': "",
    'latestReceive': Map({}),
    'webSDKNavMode': WebSDKNavMode.WEB,
    'initialPathname': "",
    'isExternalPaymentProcessor': false,
    'productType': "",
    'bgColor': "",
    'accentColor': "",
    'sectionRowSeat': Map({})
  })
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ActionTypes.VNWEBSDK_SET_SDKMODE:
      return state.set('webSDKMode', action.mode)
    case ActionTypes.VNWEBSDK_SET_PLATFORM:
      return state.set('platform', action.platform)
    case ActionTypes.VNWEBSDK_SET_VERSION:
      return state.set('version', action.version)
    case ActionTypes.VNWEBSDK_SET_BUNDLE_ID:
      return state.set('bundleId', action.bundleId)
    case ActionTypes.VNWEBSDK_SET_DATA_RECIEVE:
      return state.set('latestReceive', Map(action.latestReceive))
    case ActionTypes.VNWEBSDK_SET_NAV_MODE:
      return state.set('webSDKNavMode', action.navMode)
    case ActionTypes.VNWEBSDK_SET_INITIAL_PATHNAME:
      return state.set('initialPathname', action.pathName)
    case ActionTypes.VNWEBSDK_SET_IS_EXTERNAL_PAYMENT_PROCESSOR:
      return state.set('isExternalPaymentProcessor', action.isExternalPaymentProcessor)
    case ActionTypes.VNWEBSDK_SET_PRODUCT_TYPE:
      return state.set('productType', action.productType)
    case ActionTypes.VNWEBSDK_SET_BG_COLOR:
      return state.set('bgColor', action.bgColor)
    case ActionTypes.VNWEBSDK_SET_ACCENT_COLOR:
      return state.set('accentColor', action.accentColor)
    case ActionTypes.VNWEBSDK_SET_SECTIONROWSEAT:
      return state.set('sectionRowSeat', Map(action.sectionRowSeat))
    default:
      return state
  }
}

export default reducer
