import humps from  'humps'
import { get, keys } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import queryString from 'query-string'

export function orderTotalMapper(items, menuId, existingOrderTotal, preorderSaleEvent, lastOrderTotalUuid, lastOrderTotalMenuUuid, isRefresh) {
  const itemsArray = keys(items).filter((key) => {
    // Items are only real cart items if they contain the specialType key.
    // specialType can be null, just needs to have the key itself
    return 'specialType' in items[key]
  }).map((key) => {
    const uuid = items[key].id
    const qty = items[key].quantity

    const mods = items[key].modifiers.map((modGroup) => {
      return modGroup.map((mod) => {
        return {
          menu_item_uuid: mod.id,
          quantity: get(mod, 'quantity', 1),
        }
      })
    })

    return {
      menu_item_uuid: uuid,
      quantity: qty,
      modifiers: mods
    }
  })

  // If Rich Checkout, there is already an order total present in
  // state.orderTotal.latest. Forward the required information
  // to keep the transaction as rich checkout.
  const existingAttrs = humps.camelizeKeys(existingOrderTotal?.attributes)

  let qr = null

  if (existingAttrs) {
    let queryParams = queryString.parse(existingAttrs.qrCode, { decode: false })
    qr = queryParams.p
  }

  let orderTotalUuid

  if (existingAttrs?.uuid) {
    orderTotalUuid = existingAttrs.uuid
  } else if (lastOrderTotalUuid && lastOrderTotalMenuUuid && (lastOrderTotalMenuUuid === menuId)) {
    orderTotalUuid = lastOrderTotalUuid
  }

  // if this is a refresh, we need a new order total uuid
  if (!orderTotalUuid || isRefresh) {
    orderTotalUuid = uuidv4()
  }

  const orderTotalObject = {
    uuid: orderTotalUuid,
    orderMenuItems: itemsArray,
    standMenuUuid: menuId,
    ...existingAttrs?.orderNumber ? { orderNumber: existingAttrs.orderNumber } : {},
    ...existingAttrs?.sourceDeviceUuid ? { sourceDeviceUuid: existingAttrs.sourceDeviceUuid } : {},
    ...qr ? { qrCode: qr } : {},
    ...preorderSaleEvent ? { sales_event_id: preorderSaleEvent.id } : {}
  }

  return humps.decamelizeKeys(orderTotalObject)
}

export default orderTotalMapper
