import React, { Fragment } from "react"
import Box from "@material-ui/core/Box"
import { get, includes, keys, isEmpty } from 'lodash'

import { CalendarToday } from "@material-ui/icons"

import Money from '../../utils/money'
import TaxRules from '../../utils/taxRules'
import ErrorMessage from '../ErrorMessage'
import OrderSummaryItem from './OrderSummaryItem'
import Section from '../../components/Section'
import { withTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { VNDialog } from '../../VNComponents/VNDialog'

import './OrderTotalSection.scss'
import moment from "moment"

export class OrderTotalSection extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isDisclaimerModalOpen: false
    }
  }

  displayValue = (centsOrDollars) => {
    if (includes([0, '0.00', '0', undefined, null], centsOrDollars)) return '-'

    return Number.isInteger(centsOrDollars)
      ? Money.centsToDollars(centsOrDollars, true, true)
      : Money.formatDollars(centsOrDollars, true, true)
  }

  inCents = (centsOrDollars) => {
    if (!centsOrDollars) return 0

    return Number.isInteger(centsOrDollars, true, true)
      ? centsOrDollars
      : Money.dollarsToCents(centsOrDollars, true, true)
  }

  showError = () => keys(this.props.error).length > 0

  subtotal = () => {
    const { deliveryFee, finalTotal, serviceCharges, tax, tip } = this.props

    return this.inCents(finalTotal) - this.inCents(tax) - this.inCents(deliveryFee) - this.inCents(serviceCharges) - this.inCents(tip)
  }

  render() {
    const { deliveryFee, error, finalTotal, lineItems, serviceCharges, tax, loading, tip, isExperience, t, showTip, page, promotions, subtotal, stand, orderTotal } = this.props

    // gather taxes from all items and combine amounts for each tax
    // 1. loop through items
    // 2. loop through item taxes
    // 3. if tax already exists in allItemTaxRules - add up tax amount
    //    else - copy tax object to allItemTaxRules
    const allItemTaxRules = TaxRules.allItemTaxRules(orderTotal?.latestLineItem, t('TAX'))
    const serviceChargeItems = orderTotal?.latestLineItem?.filter(item => item.attributes?.specialType === 'service_charge' || item.attributes?.specialType === 'taxable_service_charge') ?? []
    const deliveryItem = orderTotal?.latestLineItem?.filter(item => item.attributes?.specialType === 'delivery_fee')
    let deliveryLabel = t('DELIVERY_FEE')
    if (deliveryItem && deliveryItem.length > 0) {
      if (deliveryItem[0].attributes.name){
        deliveryLabel = deliveryItem[0].attributes.name
      }
    }

    const serviceChargeLegal = stand?.venue?.serviceChargeLegal

    const displayDiscountAmount = () => {
      if (!isEmpty(promotions)) {
        let discountTotal = 0
        promotions.forEach(value => {
          discountTotal += value.discountAmountInCents
        })
        return (
          <OrderSummaryItem label={t('DISCOUNT')} value={Money.centsToDollars(discountTotal, true, true)} loading={loading} />
        )
      }

      return null
    }

    const displayTipAmount = () => {
      if (!isExperience && showTip) {
        if (page === 'OrderSummary' && tip <= 0) {
          return null
        }
        return(<OrderSummaryItem label={t('TIP')} value={this.displayValue(tip)} loading={loading} />)
      }
    }

    /**
     * only display taxes if there are taxes to apply.
     */
    const displayTaxes = () => {
      if (tax > 0) {
        const taxNames = Object.keys(allItemTaxRules)
        if (taxNames.length) {
          return taxNames.map((taxName) => {
            const tax = allItemTaxRules[taxName]
            return <OrderSummaryItem
              key={tax.taxRuleUuid}
              label={taxName || t('TAXES')}
              value={this.displayValue(tax.taxAmountInCents)}
              loading={loading}
            />
          })
        }
        else {
          return (<OrderSummaryItem label={t('TAXES')} value={this.displayValue(tax)} loading={loading} />)
        }
      }
    }

    /**
     * Multiple service charges could have same name
     * We need to combine them by name, add up amount of each fee
     * And just show one line for each name
     * @param {*} fees - Array of service charges
     * @returns {array} - Array of combined fees
     */
    const combineServiceChargesByName = (fees) => {
      const combineByName = {}

      for (const fee of fees) {
        // IMPORTANT: This does NOT happen in response of orders request (VNReceipt)
        // In response of totals request, the totalAmountInCents includes tax
        // For display purpose we need to deduct tax from it since tax is already included in item tax amount
        let feeAmount = fee.attributes.totalAmountInCents - (fee.attributes.taxAmountInCents || 0)
        if (fee.attributes.discountAmountInCents) {
          const discountAmountInCents = parseInt(fee.attributes.discountAmountInCents)
          if (!isNaN(discountAmountInCents) && discountAmountInCents) {
            feeAmount = feeAmount - discountAmountInCents
          }
        }
        const feeName = fee.attributes.name || t('SERVICE_CHARGES')

        if (combineByName[feeName]) {
          combineByName[feeName].groupTotalAmount += feeAmount
        } else {
          combineByName[feeName] = {
            id: fee.id,
            name: feeName,
            groupTotalAmount: feeAmount
          }
        }
      }

      return Object.values(combineByName)
    }

    /**
     * only display service charges if the total amount of service charges is greater than zero
     */
    const displayServiceCharges = () => {
      if (this.inCents(serviceCharges) > 0) {
        const combinedServiceCharges = combineServiceChargesByName(serviceChargeItems)

        if (combinedServiceCharges.length === 1) {
          return (
            <OrderSummaryItem
              label={combinedServiceCharges[0].name}
              value={this.displayValue(combinedServiceCharges[0].groupTotalAmount)}
              loading={loading}
              disclaimerContent={stand?.venue?.serviceChargeLegal}
            />
          )
        } else if (combinedServiceCharges.length > 1) {
          // there are multiple service charges, need to display a different layout
          return (
            <Box className="serviceChargesBox">
              {!!serviceChargeLegal && (
                <Fragment>
                  <InfoOutlinedIcon onClick={() => this.setState({ isDisclaimerModalOpen: true })} />
                  <VNDialog
                    open={this.state.isDisclaimerModalOpen}
                    subTitle={serviceChargeLegal}
                    save={t('OK')}
                    onSave={() => this.setState({ isDisclaimerModalOpen: false })}
                  />
                </Fragment>
              )}
              {combinedServiceCharges.map(fee => (
                <OrderSummaryItem
                  key={fee.id}
                  label={fee.name}
                  value={this.displayValue(fee.groupTotalAmount)}
                  loading={loading}
                />
              ))}
            </Box>
          )
        }
      }
    }

    const displayPreorderCancel = () => {
      const { preorderEvent } = this.props

      if (preorderEvent) {
        const startDateTime = moment(preorderEvent.start_time)

        return (
          <Box className="cancelPreorderBox">
            <CalendarToday fontSize="small" />
            {` ${t('CANCEL_BEFORE')} ${startDateTime.format('LL')}`}
          </Box>
        )
      }
    }

    return (
      <Section className="total">
        {this.showError() && (
          <ErrorMessage type="getOrderTotal" error={error} style={{marginBottom:'24px'}}/>
        )}

        {lineItems && keys(lineItems).map((id) => {
          const lineItem = lineItems[id]
          if (!!get(lineItem, 'attributes.specialType')) return null

          return (
            <OrderSummaryItem
              key={id}
              label={`${get(lineItem, 'attributes.quantity')} - ${get(lineItem, 'attributes.name')}`}
              value={this.displayValue(get(lineItem, 'attributes.totalAmountInCents'))}
              loading={loading}
            />
          )
        })}

        <OrderSummaryItem
          label={this.props.itemQuantityLabel}
          value={this.displayValue(subtotal)}
          loading={loading}
        />
        {this.inCents(deliveryFee) > 0 &&
          <OrderSummaryItem label={deliveryLabel} value={this.displayValue(deliveryFee)} loading={loading} />
        }
        {displayServiceCharges()}
        {displayTaxes()}
        {displayDiscountAmount()}
        {displayTipAmount()}
        <OrderSummaryItem label={t('TOTAL')} value={this.displayValue(finalTotal)} loading={loading} />
        {displayPreorderCancel()}
      </Section>
    )
  }
}

export default withTranslation()(OrderTotalSection)
