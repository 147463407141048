import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { clearCart } from '../actions/cart'
import { clearOrderError, clearLatestOrder} from '../actions/order'
import { updateUserAgeVerification, updateUserLocationConfirmed } from '../actions/user'

const useResetCart = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch([
    '/'
  ])

  const resetCart = () => {
    dispatch(clearCart())
    dispatch(clearOrderError())
    dispatch(clearLatestOrder())
    dispatch(updateUserAgeVerification(false))
    dispatch(updateUserLocationConfirmed(false))
  }

  useEffect(() => {
    if (match && match.isExact) {
      resetCart()
    }
  }, [match])
}

export default useResetCart
