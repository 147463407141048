import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  circle: {
    borderRadius: '100%',
    padding: 0,
    minWidth: 0,
  }
}));

/**
 * A circular button
 * @props
 * 
 * Required
 * - size : int - the diameter of the circle
 * - icon : <ICON> - What icon you want to be displayed in the middle of the circle
 * - click : Func - When this button is clicked, this function will be called
 * Optional
 */
export const VNCircleButton = (props) => {

  const classes = useStyles();

  const handleClick = () => {
    if (props.click) {
      props.click()
    }
  }

  return (
    <Button 
      variant={props.variant ? props.variant : "outlined"} 
      className={clsx(classes.circle, props.className ? props.className : '')} 
      onClick={handleClick}
    >{props.icon}</Button>
  )
}