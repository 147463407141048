import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { useSelector } from 'react-redux'
import semver from 'semver'
import { getWebSDKMode, getWebSDKVersion } from '../VNWebSDK/Selectors'

export const VNFullScreenCloseButton = (props) => {
  const webSDKMode = useSelector(state => getWebSDKMode(state))
  const webSDKVersion = useSelector(state => getWebSDKVersion(state))
  

  const displayCloseButton = () => {
    if (!webSDKMode) {
      return (<HighlightOffRoundedIcon className={props.className} onClick={props.onClose} />)
    } else {
      if ((webSDKVersion && semver.lt(webSDKVersion, '2.0.4')) || !webSDKVersion) {
        return (<HighlightOffRoundedIcon className={props.className} onClick={props.onClose} />)
      }
      return null
    }
  }

  return (
    displayCloseButton()
  )
}
